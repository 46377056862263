import { ChangeEvent, FC, Fragment, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getReportSection, createReportSection, updateReportSection } from 'app/store/ReportSections/ReportSections.async';
// Selectors
import { selectTagsAsOptions } from 'app/store/Accounts/Accounts.selectors';
import { selectLoading, selectReportSection } from 'app/store/ReportSections/ReportSections.selectors';
// Actions
import { ReportSectionsActions } from 'app/store/ReportSections/ReportSections.slice';
// Mui
import { 
  Box, Grid, FormHelperText, Tooltip, Typography, Alert
} from '@mui/material';
// Icons
import {
  FileCopy as FileCopyIcon,
  Check as CheckIcon
} from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import Dialog from 'app/components/Dialog';
import QuillEditor from 'app/components/QuillEditor';
import { Input, Select, IconButton, Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useCopyToClipboard from 'app/hooks/useCopyToClipboard';
// Validations
import { isRequired } from 'app/utilities/Validations';

interface IFormData {
  type: string;
  tag: string;
  name: string;
  variableName: string;
  content: string;
};

const defaultValues:IFormData = {
  type: '',
  tag: '',
  name: '',
  variableName: '{{ }}',
  content: ''
}

type Props = {
  open: boolean;
  onClose: () => void;
  reportSectionId?: number;
}

const ReportSectionFormDialog:FC<Props> = ({
  // Props
  open, onClose, reportSectionId
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const tagOptions = useAppSelector(selectTagsAsOptions);
  const reportSection = useAppSelector(selectReportSection);
  const loading = useAppSelector(selectLoading);

  const [ asyncCopyTextToClipboardFn, copied ] = useCopyToClipboard();

  const [ statusMessage, setStatusMessage ] = useState<string | undefined>(undefined)
  const [ variableUpdated, setVariabledUpdated ] = useState(false);
  const [ closed, setClosed ] = useState(false);

  const generateReportSectionData = (data:IFormData) => {
    const { variableName, tag, content, ...otherData } = data;
    const nextData:any = {
      ...otherData,
      variableName: variableName.replace('{{ ', '').replace(' }}', ''),
      insuranceCaseId: caseId
    }
    if ( otherData.type === 'freeText' ) nextData['content'] = content;
    if ( otherData.type === 'tag' ) nextData['tag'] = tag;
    if ( reportSectionId ) nextData['version'] = reportSection.version;
    return nextData;
  }

  const { handleSubmit, control, watch, formState:{ errors }, reset, setValue, getValues } = useForm<IFormData>({
    defaultValues
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const nextData = generateReportSectionData(data);

    setClosed(true);

    try {
      if ( reportSectionId ){
        await asyncUpdateReportSection(reportSectionId, nextData);
      } else {
        await dispatch(createReportSection(nextData)).unwrap();
      }
      onClose();
    } catch(error){}
  });

  const onSubmitWithoutClosing = async (data:IFormData) => {
    const nextData = generateReportSectionData(data);

    setClosed(false);

    if ( !reportSectionId ) return;

    await asyncUpdateReportSection(reportSectionId, nextData);
  }

  useEffect(() => {
    if ( reportSectionId ){
      dispatch(getReportSection(reportSectionId));
    }
    return () => {
      dispatch(ReportSectionsActions.setInitialField('reportSection'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !reportSection ) return;

    reset({
      type: reportSection.type,
      tag: reportSection.tag || '',
      name: reportSection.name,
      variableName: `{{ ${reportSection.variableName} }}`,
      content: reportSection.content
    });
    // eslint-disable-next-line
  }, [reportSection]);

  const handleClose = () => {
    setStatusMessage(undefined);
  }

  const handleConfirm = async (data:IFormData) => {
    const nextData = generateReportSectionData(data);

    if ( !reportSectionId ) return;

    try {
      await asyncUpdateReportSection(reportSectionId, { ...nextData, overrideChanges: true });
      handleClose();
      if ( closed ) onClose();
    } catch(error){
      console.log(error);
    }
  }

  const asyncUpdateReportSection = async (reportSectionId:number, data:any):Promise<boolean> => {
    try {
      await dispatch(updateReportSection({ reportSectionId, data })).unwrap();
      return Promise.resolve(true);
    } catch(error:any){
      const { errors, status } = error;
      if ( status && status === 409 ) setStatusMessage(errors[0].message);
      return Promise.reject(false);
    }
  }

  const handleClick = useCallback(async () => {
    try {
      const value = getValues('variableName') || '';
      await asyncCopyTextToClipboardFn(value, false);
    } catch(error){
      console.log(error);
    }
    // eslint-disable-next-line
  }, [getValues('variableName')]);

  const handleChange = (onChange: (...event: any[]) => void) => (event:ChangeEvent<HTMLInputElement>) => {
    if ( !variableUpdated ){
      const { name, value } = event.target;

      if ( name === 'variableName' ) setVariabledUpdated(true);
      if ( name === 'type' ) reset({ ...defaultValues, type: value });
      if ( name === 'tag' ){
        setValue('name', value);
        setValue('variableName', `{{ ${value.replace(/\s/g, '_').toUpperCase()} }}`)
      }
      if ( name === 'name' ){
        setValue('variableName', `{{ ${value.replace(/\s/g, '_').toUpperCase()} }}`)
      }
    }

    onChange(event)
  }

  const watchType = watch('type');
  const watchVariableName = watch('variableName');
  const watchName = watch('name');
  const watchContent = watch('content');
  const watchTag = watch('tag');

  const isDisabled = watchVariableName === `{{ ${reportSection?.variableName} }}`
    && watchName === reportSection?.name
    && ((watchType === 'freeText' && watchContent === reportSection?.content)
    || (watchType === 'tag' && watchTag === reportSection?.tag));

  const actions = (
    <Fragment>
      <Button
        name="Cancel report section dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      {reportSectionId ? (
        <Fragment>
          <LoadingButton
            name="Save report section dialog"
            loading={loading}
            onClick={handleSubmit(onSubmitWithoutClosing)}
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >{t('labels.save')}</LoadingButton>
          <LoadingButton
            name="Save and close report section dialog"
            loading={loading}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >{t('labels.saveAndClose')}</LoadingButton>
        </Fragment>
      ) : (
        <LoadingButton
          name="Create report section dialog"
          loading={loading}
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >{t('labels.create')}</LoadingButton>
      )}
    </Fragment>
  );

  const openConfirmationDialog = Boolean(statusMessage);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        title={reportSectionId
          ? t('dialogs.reportSectionTemplateForm.updateReportSection')
          : t('dialogs.reportSectionTemplateForm.createReportSection')
        }
        actions={actions}
      >
        <form noValidate>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Controller
                control={control} name="type"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={t('labels.type')}
                    onChange={handleChange(field.onChange)}
                    options={[
                      { id: '', name: t('dialogs.reportSectionTemplateForm.chooseType') },
                      { id: 'tag', name: t('dialogs.reportSectionTemplateForm.tag') },
                      { id: 'freeText', name: t('dialogs.reportSectionTemplateForm.freeText') }
                    ]}
                    required={true}
                    disabled={reportSection?.id ? true : false}
                    error={Boolean(errors.type)}
                    helperText={errors.type?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormHelperText>
                {watchType === 'freeText' ? (
                  t('dialogs.reportSectionTemplateForm.freeTextHint')
                ) : watchType === 'tag' ? (
                  t('dialogs.reportSectionTemplateForm.tagHint')
                ) : null}
              </FormHelperText>
            </Grid>
          </Grid>

          <Grid container spacing={4} marginBottom={4}>
            {watchType === 'tag' ? (
              <Grid item xs={12} sm={2}>
                <Controller
                  control={control} name="tag"
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('dialogs.reportSectionTemplateForm.tag')}
                      onChange={handleChange(field.onChange)}
                      options={[
                        { id: '', name: t('dialogs.reportSectionTemplateForm.chooseTag') },
                        ...(tagOptions || [])
                      ]}
                      required={true}
                      error={Boolean(errors.tag)}
                      helperText={errors.tag?.message || ''}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={watchType === 'tag' ? 5 : 6}>
              <Controller
                control={control} name="name"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.name')}
                    onChange={handleChange(field.onChange)}
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={watchType === 'tag' ? 5 : 6}>
              <Controller
                control={control} name="variableName"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('dialogs.reportSectionTemplateForm.variableName')}
                    onChange={handleChange(field.onChange)}
                    required={true}
                    error={Boolean(errors.variableName)}
                    helperText={errors.variableName?.message || ''}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={copied ? t('labels.copied') : 'Copy variable name'}>
                          <IconButton
                            name="Copy variable name"
                            onClick={handleClick}
                          >{copied
                            ? <CheckIcon color="success" fontSize="small" />
                            : <FileCopyIcon />
                          }</IconButton>
                        </Tooltip>
                      )
                    }}
                  />
                )}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <FormHelperText error={Boolean(errors.variableName)}>
                    {t('dialogs.reportSectionTemplateForm.variableShouldStartWith')} '{'{{ \' and end with \' }}'}'  (i.e. '{'{{ \' NEW_VARIABLE \' }}'})
                  </FormHelperText>
                  <FormHelperText error={Boolean(errors.variableName)}>
                    {t('dialogs.reportSectionTemplateForm.onlyCapitalLettersAndUnderscoresAllowed')}
                  </FormHelperText>
                </Box>
                <FormHelperText>{watchVariableName.length}/50</FormHelperText>
              </Box>
            </Grid>
          </Grid>
          {watchType === 'freeText' ? (
            <Controller
              control={control} name="content"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Fragment>
                  <QuillEditor
                    {...field}
                    id="report-section-quill-editor"
                    label={t('labels.content')}
                    required={true}
                    error={Boolean(errors.content)}
                    helperText={errors.content?.message || ''}
                    height={480}
                  />
                </Fragment>
              )}
            />
          ) : null}
        </form>
      </Dialog>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={handleClose}
          title={t('dialogs.reportSectionTemplateForm.confirmationDialogTitle')}
          content={
            <Fragment>
              {statusMessage ? (
                <Alert sx={{ mb: 4 }} color="info">{statusMessage}</Alert>
              ) : null}
              <Typography variant="body1">{t('dialogs.reportSectionTemplateForm.confirmationDialogContent')}</Typography>
            </Fragment>
          }
          loading={loading}
          onConfirm={handleSubmit(handleConfirm)}
          confirmLabel={t('dialogs.reportSectionTemplateForm.confirmationDialogConfirmButton')}
          closeLabel={t('dialogs.reportSectionTemplateForm.confirmationDialogCloseButton')}
        />
      ) : null}
    </Fragment>
  );
}

export default ReportSectionFormDialog;
