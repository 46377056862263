import i18n from "i18n";

const Messages = {
  Update: i18n.t('notifications.accounts.update')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Update = i18n.t('notifications.companyAccounts.update');
});