import { createApi } from "@reduxjs/toolkit/query/react";
// Models
import IInsuranceCase from "app/models/Case";
// Utilities
import baseQuery from "app/utilities/BaseQuery";

interface ISearchCasesData {
  [key:string]: IInsuranceCase[]
};

const casesApi = createApi({
  reducerPath: 'insuranceCases.api',
  baseQuery,
  endpoints: (builder) => ({
    getSearchCases: builder.query<any, any>({
      async queryFn(props:any, _queryApi, _extraOptions, fetchWithBQ){
        // Added `caseId` to remove same cases which was created from client portal
        const { params, caseId } = props;
        let data:ISearchCasesData = {};
        for ( let key of Object.keys(params) ){
          let searchParams = new URLSearchParams();
          searchParams.append(key, params[key]);
          try {
            let response = await fetchWithBQ(`/insuranceCases?${searchParams}`);
            if ( response.error ) throw Error(`Error on cases search by ${key}`);
            const cases = response.data as IInsuranceCase[];
            const filteredCases = caseId
              ? cases.filter((insuranceCase:IInsuranceCase) => insuranceCase.id !== caseId)
              : cases;
            if ( filteredCases.length ) data[key] = cases;
          } catch(error){}
        }
        return { data };
      }
    })
  })
});

export const { useLazyGetSearchCasesQuery } = casesApi;

export default casesApi;
