import { FC, memo } from 'react';
// Material
import { Popover } from '@mui/material';
// 
import PopoverContent from 'app/components/PopoverContent';

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  episode: any;
};

const CalendarPopover:FC<Props> = ({
  // Props
  anchorEl, open, onClose, episode
}) => {
  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <PopoverContent
        onClose={onClose}
        episode={episode}
      />
    </Popover>
  )
}

export default memo(CalendarPopover);
