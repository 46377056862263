import { FC } from 'react';
import CanvasJSReact from 'app/libraries/canvasjs.react';
import { ICurrentAccount } from 'app/models/Account';
import { useAppSelector } from 'app/hooks/useStore';
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
  data: any;
}

const MonthlyChart:FC<Props> = ({
  // Props
  data
}) => {
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);

  const valueFormatString = (currentAccount?.settings?.dateFormat || 'MM/DD/YYYY').toUpperCase();

  const options = {
    legend: {
      verticalAlign: 'top'
    },
    axisY: {
      tickColor: '#d3d3d3',
      gridColor: '#d3d3d3'
    },
    axisX: {
      valueFormatString
    },
    data: [data]
  };

  return (<CanvasJSChart options={options} />);
}

export default MonthlyChart;
