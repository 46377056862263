import dayjs from 'dayjs';

export const convertTime24to12 = (time24h:string) => {
  const [ hour, minute ] = time24h.split(':');
  return dayjs().hour(Number(hour)).minute(Number(minute)).format('hh:mm a');
  // let [ hour, minute ]:any = time24h.split(':');
  // let ampm = 'am';
  // console.log(time24h);
  // if ( Number(hour) >= 12 ) ampm = 'pm';
  // if ( Number(hour) > 12){
  //   hour = hour - 12;
  //   hour = hour < 9 ? '0' + hour : hour;
  // }
  // return `${hour}:${minute} ${ampm}`;
};

export const convertTime12to24 = (time12h:string|null) => {
  if ( !time12h ) return null;
  const [ time, meridian ] = time12h.split(' ');
  let [ hour, minute ]:Array<any> = time.split(':');
  if ( !Number(hour) && !Number(minute) ) return null;
  if ( hour === '12' ) hour = '00';
  if ( meridian === 'pm' ) hour = parseInt(hour, 10) + 12;
  return `${hour}:${minute}`;
};

export const uuidv4 = () => {
  // eslint-disable-next-line
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const toSize = (size:number) => {
  const sizes = ['Bytes', 'Kb', 'Mb', 'G', 'T'];
  if (size === 0) return '0 Byte';
  const i = Number(Math.floor(Math.log(size)/Math.log(1024)));
  return Math.round(size/Math.pow(1024, i)) + ' ' + sizes[i];
}

export const formatPhoneNumber = (value:string | undefined) => {
  if ( !value ) return null;
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(1|38)?(\d{3})(\d{3})(\d{4})$/);
  if ( match ){
    const intlCode = (match[1] ? `+${match[1]} ` : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}