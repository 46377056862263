import { FC, Fragment, createRef } from 'react';
// Mui
import { Box } from '@mui/material';
// 
import WorkspaceToolbar from './WorkspaceToolbar';
import PagesFilter from './PagesFilter';
import PagesList from './PagesList';

const WorkspacePage:FC = () => {
  const listRef = createRef<any>();

  return (
    <Fragment>
      <WorkspaceToolbar listRef={listRef} />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <PagesFilter />
        <PagesList ref={listRef} />
      </Box>
    </Fragment>
  )
}

export default WorkspacePage;
