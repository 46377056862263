import dayjs, { Dayjs } from 'dayjs';
// import utc from 'dayjs/plugin/utc';

export const parseDateTime = (dateTime:string, format = 'MMMM DD, YYYY hh:mm a') => {
  dayjs(dateTime).format(format)
}

export const parseDate = (date:string):Dayjs => {
  const [ year, month, day ] = date.split('-');
  return dayjs().year(Number(year)).month(Number(month) - 1).date(Number(day));
}

export const parseTime = (time:string) => {
  const [ hour, minute ] = time.split(':');
  return dayjs().hour(Number(hour)).minute(Number(minute));
}

export const convertDateToString = (date:Dayjs, format:string = 'YYYY-MM-DD'):string | null => {
  if ( !date || !date.isValid() ) return null;
  return date.format(format);
}

export const convertTimeToString = (date:Dayjs, format:string = 'HH:mm'):string | null => {
  if ( !date || !date.isValid() ) return null;
  return date.local().format(format);
}

export const dateToString = (date:Dayjs | null, format = 'YYYY-MM-DD'):string => {
  if ( !date ) return '';
  return date.format(format);
}

export const timeToString = (time:Dayjs | null, format = 'HH:mm'):string => {
  if ( !time ) return '';
  return time.format(format)
}

export const setPreEvent = ({
  eventDate,
  eventTime,
  currentDate,
  currentTime
}:{
  eventDate:string | null,
  eventTime:string | null,
  currentDate:Dayjs | null,
  currentTime:Dayjs | null
}):boolean => {
  if ( !eventDate || !currentDate ) return false;

  let eventDateTime = dayjs(eventDate).startOf('hour').hour(0);
  let currentDateTime = currentDate.startOf('hour').hour(0);

  if ( !currentDateTime.isSame(eventDateTime) ) return currentDateTime.isBefore(eventDateTime);
  if ( !eventTime || !currentTime?.isValid() ) return false;

  const [ eventHours, eventMinutes ] = eventTime.split(':').map(Number);
  eventDateTime = eventDateTime.hour(eventHours).minute(eventMinutes);
  const [ currentHours, currentMinutes ] = currentTime.format('HH:mm').split(':').map(Number);
  currentDateTime = currentDateTime.hour(currentHours).minute(currentMinutes);

  return currentDateTime.isBefore(eventDateTime);
}
