import { FC, ReactNode } from 'react';
// Mui
import { Fab as MuiFab, FabProps as MuiFabProps } from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = MuiFabProps & {
  name: string;
  children: ReactNode;
}

const Fab:FC<Props> = ({
  // Props
  name, children, ...otherProps
}) => {
  return (
    <MuiFab
      {...otherProps}
      {...generateAttrForTesting('action', 'fab', name)}
    >{children}</MuiFab>
  )
}

export default Fab;
