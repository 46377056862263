import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectIsWorkspacePageLastOpened, selectIsFilePageLastOpened } from 'app/store/TODO2_app/app.selectors';
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectPageEntity, selectIsPageDisabled } from 'app/store/page/page.selectors';
// 
import PageCardDeleted from './PageCardDeleted';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import PageCardFooter from './PageCardFooter';
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
import PageCardColors from './PageCardColors';

type Props = {
  episodeId?: number;
  documentId: number;
  pageNum: number;
  height?: number;
  index?: number;
}

const PageCard:React.FC<Props> = ({
  // Props
  episodeId, documentId, pageNum, index, height = 450
}) => {
  const { pathname } = useLocation<{ pathname:string }>();
  const { caseId } = useParams<{ caseId:string }>();

  const isFilesPage = pathname.includes('/documents');
  const isWorkspacePage = pathname.includes('/workspace');
  const isDuplicatesPage = pathname.includes('/duplicates');
  const isSearchPage = pathname.includes('/search');

  const props = { episodeId, index, isFilesPage, isWorkspacePage, isDuplicatesPage, isSearchPage };

  // State
  const workspaceType = useSelector(selectWorkspaceType);
  const isWorkspacePageLastOpened = useSelector((state:any) => selectIsWorkspacePageLastOpened(state, { insuranceCaseId: Number(caseId), documentId, pageNum }));
  const isFilePageLastOpened = useSelector((state:any) => selectIsFilePageLastOpened(state, { insuranceCaseId: Number(caseId), documentId, pageNum }));

  const documentEntity = useSelector((state:any) => selectDocumentEntity(state, { documentId }));
  const page:IPage | undefined = useSelector((state:any) => selectPageEntity(state, { documentId, pageNum }));
  const isPageDisabled = useSelector((state:any) => selectIsPageDisabled(state, { documentId, pageNum }));

  if ( !page ) return null;
  return (
    <div
      react-page-num={typeof index !== 'undefined' ? index + 1 : pageNum}
      react-last-workspace-opened-page={Boolean(isWorkspacePage && isWorkspacePageLastOpened).toString()}
      react-last-file-opened-page={Boolean(isFilesPage && isFilePageLastOpened).toString()}
      className={`
        card
        ${isPageDisabled ? 'disabled' : ''}
        ${workspaceType === 'pages' && isWorkspacePage && isWorkspacePageLastOpened ? 'highlighted' : ''}
        ${isFilesPage && isFilePageLastOpened ? 'highlighted' : ''}
      `}
      data-document-id={documentId}
      data-document-name={documentEntity?.name}
      data-document-page-number={pageNum}
      data-page-staple-id={page.staple ? page.staple.id : ''}
      data-page-staple-order={page.staple ? page.staple.order : ''}
      style={{
        height
      }}
    >
      {!page.deleted ? (
        <React.Fragment>
          <PageCardColors documentId={page.documentId} pageNum={page.pageNum} colors={page.colors} />
          <CardHeader page={page} {...props} />
          <CardBody page={page} {...props} />
          <PageCardFooter page={page} {...props} />
        </React.Fragment>
      ) : <PageCardDeleted />}
    </div>
  )
};

export default PageCard;