import { FC, Fragment, useContext } from 'react';
// Models
import { IBillingPackageItem } from 'app/models/BillingPackage';
// Mui
import { Paper, Typography } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Contexts
import PackageServicesContext from 'app/context/PackageServicesContext';
// 
import PackageServicesListItem from './PackageServicesListItem';
// i18next
import { useTranslation } from 'react-i18next';

const PackageServicesList:FC = () => {
  const { t } = useTranslation('common');

  const { items } = useContext(PackageServicesContext);

  if ( !items ) return <Loader />;
  if ( !items.length ) return <Message text={t('pages.accountManagerPages.subscriptionPackagesPage.noServices')} />;
  return (
    <Fragment>
      <Typography sx={{
        fontWeight: 700,
        mb: 4
      }} variant="subtitle1">{t('labels.services')}</Typography>
      <Paper sx={{ p: 4 }}>
        {items.map((item:IBillingPackageItem) => (
          <PackageServicesListItem
            key={`Service item ${item.serviceId}`}
            packageItem={item}
          />
        ))}
      </Paper>
    </Fragment>
  )
}

export default PackageServicesList;
