import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Async
import { createEpisode, updateEpisode, deleteEpisode } from 'app/store/Episodes/Episodes.async';
// Selectors
import { selectInsuranceCase } from "app/store/Cases/Cases.selectors";
// Services
import Mixpanel from "app/services/Mixpanel.service";

const EpisodesMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.Episodes) ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    if ( type === createEpisode.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseEpisodeAdd, {
        caseId: Number(insuranceCase.id),
        docId: payload.documentId,
        pageNum: payload.pageNum
      });
    }

    if ( type === updateEpisode.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseEpisodeUpdate, {
        caseId: Number(insuranceCase.id),
        docId: payload.documentId,
        pageNum: payload.pageNum
      });
    }

    if ( type === deleteEpisode.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseEpisodeDelete, {
        caseId: Number(insuranceCase.id)
      });
    }
  }

  return next(action);
}

export default EpisodesMiddleware;
