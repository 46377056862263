const extractTLD = () => {
  const { hostname } = window.location;
  const tldMatch = hostname.match(/\.[a-z]+$/i);
  if ( !tldMatch ) return '.com';
  return tldMatch[0].toLowerCase();
}

const getProdMixpanelTokenByTLD = () => {
  const tld = extractTLD();
  switch (tld){
    case '.eu':
      return 'c684467581ee62458bc26bd4e9c8dec2';
    default:
      return 'e634d8f96f55036e7cf353c1a7e65cc4'
  };
}

const config = {
  'development': {
    production: false,
    domain: extractTLD(),

    apiUrl: `https://cases-api-qa.casechronology${extractTLD()}/api`,
    messageApiUrl: `https://chat-api-qa.casechronology${extractTLD()}/api`,
    chatAIApiUrl: `https://ai-chat-api-qa.casechronology${extractTLD()}/api`,

    clientPortalUrl: `http://clients-local.casechronology${extractTLD()}:3001`,
    cdnUrl: `https://cases-cdn-qa.casechronology${extractTLD()}`,
    quickBooksUrl: `https://app.sandbox.qbo.intuit${extractTLD()}/app`,
    socketUrl: `wss://n-qa.casechronology${extractTLD()}`,

    billingPublicApiKey: 'pkapi_cert_5biYwOYd6BUbWpkGa6',
    sentryDsn: 'https://ba855b6345ea26cb7d563fc42a693d16@o4506229343584256.ingest.sentry.io/4506229345484804',

    mixpanelToken: '33dae47c41cf222f39cb7af59d93a206'
  },
  'qa': {
    production: false,
    domain: extractTLD(),
  
    apiUrl: `https://cases-api-qa.casechronology${extractTLD()}/api`,
    messageApiUrl: `https://chat-api-qa.casechronology${extractTLD()}/api`,
    chatAIApiUrl: `https://ai-chat-api-qa.casechronology${extractTLD()}/api`,

    clientPortalUrl: `https://clients-qa.casechronology${extractTLD()}`,
    cdnUrl: `https://cases-cdn-qa.casechronology${extractTLD()}`,
    quickBooksUrl: `https://app.sandbox.qbo.intuit${extractTLD()}/app`,
    socketUrl: `wss://n-qa.casechronology${extractTLD()}`,

    billingPublicApiKey: 'pkapi_cert_5biYwOYd6BUbWpkGa6',
    sentryDsn: 'https://ba855b6345ea26cb7d563fc42a693d16@o4506229343584256.ingest.sentry.io/4506229345484804',

    mixpanelToken: '33dae47c41cf222f39cb7af59d93a206'
  },
  'production': {
    production: true,
    domain: extractTLD(),

    apiUrl: `https://cases-api.casechronology${extractTLD()}/api`,
    messageApiUrl: `https://chat-api.casechronology${extractTLD()}/api`,
    chatAIApiUrl: `https://ai-chat-api.casechronology${extractTLD()}/api`,

    clientPortalUrl: `https://clients.casechronology${extractTLD()}`,
    cdnUrl: `https://cases-cdn.casechronology${extractTLD()}`,
    quickBooksUrl: `https://app.qbo.intuit${extractTLD()}/app`,
    socketUrl: `wss://n.casechronology${extractTLD()}`,

    billingPublicApiKey: 'pkapi_prod_VFHZauJvpQvLEcU7Il',
    sentryDsn: 'https://ba855b6345ea26cb7d563fc42a693d16@o4506229343584256.ingest.sentry.io/4506229345484804',

    mixpanelToken: getProdMixpanelTokenByTLD()
  }
}

const stage = process.env.REACT_APP_STAGE || 'development';

export default config[stage];