import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAvailableLanguageAsOptions } from 'app/store/AppUI/AppUI.selectors';
import {
  selectDateFormatAsOptions, selectTimeFormatAsOptions, selectRecordsLanguageAsOptions, selectTimeZoneAsOptions
} from 'app/store/Settings/Settings.selectors';
// Component
import { Select } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';

const LocalizationTabContent:FC = () => {
  const { t } = useTranslation('common');
  // State
  const languageOptions = useAppSelector(selectAvailableLanguageAsOptions);
  const dateFormatOptions = useAppSelector(selectDateFormatAsOptions);
  const timeFormatOptions = useAppSelector(selectTimeFormatAsOptions);
  const recordsLanguageOptions = useAppSelector(selectRecordsLanguageAsOptions);
  const timeZoneOptions = useAppSelector(selectTimeZoneAsOptions);

  const { control, formState:{ errors } } = useFormContext();

  return (
    <Fragment>
      <Controller
        control={control} name="settings.timeZone" defaultValue="America/New_York"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.timeZone')}
            required
            error={Boolean((errors.settings as any)?.timeZone)}
            helperText={(errors.settings as any)?.timeZone?.message || ''}
            options={timeZoneOptions}
          />
        )}
      />
      <Controller
        control={control} name="settings.dateFormat"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.dateFormat')}
            required
            error={Boolean((errors.settings as any)?.dateFormat)}
            helperText={(errors.settings as any)?.dateFormat?.message || ''}
            options={dateFormatOptions}
          />
        )}
      />
      <Controller
        control={control} name="settings.timeFormat"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.timeFormat')}
            required
            error={Boolean((errors.settings as any)?.timeFormat)}
            helperText={(errors.settings as any)?.timeFormat?.message || ''}
            options={timeFormatOptions}
          />
        )}
      />
      {/* OCR language */}
      <Controller
        control={control} name="settings.recordsLanguage"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.ocrLanguage')}
            required
            error={Boolean((errors.settings as any)?.recordsLanguage)}
            helperText={(errors.settings as any)?.recordsLanguage?.message || ''}
            options={recordsLanguageOptions}
          />
        )}
      />
      {/* Language */}
      <Controller
        control={control} name="settings.language"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.language')}
            error={Boolean((errors.settings as any)?.language)}
            helperText={(errors.settings as any)?.language?.message || ''}
            required
            options={languageOptions}
          />
        )}
      />
    </Fragment>
  )
}

export default LocalizationTabContent;
