import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getInvoices } from 'app/store/Invoices/Invoices.async';
// Actions
import { InvoicesActions } from 'app/store/Invoices/Invoices.slice';
// Mui
import { Box, Container } from '@mui/material';
// 
import InvoicesQuickbooks from './InvoicesQuickbooks';
import InvoicesFab from './InvoicesFab';
import InvoicesList from './InvoicesList';
// 
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

const InvoicesPage:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInvoices({ insuranceCaseId: caseId }));

    Mixpanel.track(MixpanelTracks.CaseInvoicesView, { id: Number(caseId) });

    return () => {
      dispatch(InvoicesActions.setInitialField('invoices'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="md">
          <InvoicesQuickbooks />
          <InvoicesList />
        </Container>
      </Box>
      <InvoicesFab />
    </Box>
  )
}

export default InvoicesPage;
