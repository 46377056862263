import i18n from "i18n";

const Messages = {
  Update: i18n.t('notifications.billingInvoices.update'),
  Pay: i18n.t('notifications.billingInvoices.pay')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Update = i18n.t('notifications.billingInvoices.update');
  Messages.Pay = i18n.t('notifications.billingInvoices.pay');
});