import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
import { TemplateType, TemplateDataType, TemplateLayout } from 'app/types/Template';
// Models
import ITemplate, { ITemplateVariable } from "app/models/Template";
// Async
import {
  getGlobalTemplates,
  getTemplates, getTemplate, createTemplate, updateTemplate, deleteTemplate,
  getVariables
} from './Templates.async';

interface IState {
  globalTemplates: ITemplate[] | null;

  templates: ITemplate[] | null;
  template: ITemplate | null;
  variables: ITemplateVariable[] | null;

  wizardForm: {
    info: {
      type: TemplateType | null;
      dataType: TemplateDataType | null;
      layout: TemplateLayout | null;
    }
  },
  filter: {
    search: string;
  },
  loading: boolean;
};

const initialState:IState = {
  globalTemplates: null,

  templates: null,
  template: null,
  variables: null,

  wizardForm: {
    info: {
      type: null,
      dataType: null,
      layout: null,
    }
  },
  filter: {
    search: ''
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.Templates,
  initialState,
  reducers: {
    setWizardFormInfo: (state:IState, action:PayloadAction<{
      type:IState['wizardForm']['info']['type'],
      dataType:IState['wizardForm']['info']['dataType'],
      layout:IState['wizardForm']['info']['layout']
    }>) => {
      console.log(action.payload);
      state.wizardForm.info = {
        ...state.wizardForm.info,
        ...action.payload
      };
    },
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get global templates
    builder.addCase(getGlobalTemplates.pending, (state) => {
      state.globalTemplates = null;
    });
    builder.addCase(getGlobalTemplates.fulfilled, (state, action:PayloadAction<ITemplate[]>) => {
      state.globalTemplates = action.payload;
    });
    // Get templates
    builder.addCase(getTemplates.pending, (state) => {
      state.templates = null;
    });
    builder.addCase(getTemplates.fulfilled, (state, action:PayloadAction<ITemplate[]>) => {
      state.templates = action.payload;
    });
    // Get template
    builder.addCase(getTemplate.pending, (state) => {
      state.template = null;
    });
    builder.addCase(getTemplate.fulfilled, (state, action:PayloadAction<ITemplate>) => {
      state.template = action.payload;
    });
    // Create template
    builder.addCase(createTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTemplate.fulfilled, (state, action:PayloadAction<ITemplate>) => {
      if ( state.templates ){
        state.templates = [...state.templates, action.payload];
      }
      state.template = action.payload;
    });
    // Update template
    builder.addCase(updateTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTemplate.fulfilled, (state, action:PayloadAction<ITemplate>) => {
      if ( state.template && state.template.id === action.payload.id ){
        state.template = action.payload;
      }
      if ( state.templates ){
        state.templates = state.templates.map((template:ITemplate) => {
          if ( template.id === action.payload.id ) return action.payload;
          return template;
        })
      }
    });
    // Delete template
    builder.addCase(deleteTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTemplate.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.templates ){
        state.templates = state.templates.filter((template:ITemplate) => {
          return template.id !== action.payload
        });
      }
    });
    // Get template variables
    builder.addCase(getVariables.pending, (state) => {
      state.variables = null;
    });
    builder.addCase(getVariables.fulfilled, (state, action:PayloadAction<ITemplateVariable[]>) => {
      state.variables = action.payload;
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const TemplatesActions = slice.actions;

export default slice.reducer;
