import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createCaseShare } from 'app/store/CaseShares/CaseShares.async';
// Selectors
import { selectLoading } from 'app/store/CaseShares/CaseShares.selectors';
import {
  selectDocumentsEntities,
  selectDocumentsAsOptions
} from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
import Autocomplete from 'app/components/Mui/AutocompleteV2';
// Utility
import { isRequired, isEmailValid } from 'app/utilities/Validations';

interface IFormData {
  insuranceCaseId?: number;
  documentIds: number[] | undefined;
  options?: string[] | undefined;
  notificationEmail: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
}

const ShareFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const documentEntities = useAppSelector(selectDocumentsEntities);
  const loading:boolean = useAppSelector(selectLoading);

  const documentsOptions:any[] | null = useAppSelector(selectDocumentsAsOptions);
  const optionsOptions:any[] = [
    { id: 'episode.labels', name: t('labels.episodeLabels') },
    { id: 'episode.notes', name: t('labels.episodeNotes') },
    { id: 'episodeFacts', name: t('labels.episodeFacts') },
    { id: 'page.tag', name: t('labels.pageTag') },
    { id: 'page.colors', name: t('labels.pageColors') },
    { id: 'page.highlights', name: t('labels.pageHighlights') },
    { id: 'page.inWorkspace', name: t('labels.pageInWorkspace') },
    { id: 'document.labels', name: t('labels.documentLabels') }
  ];

  const { control, handleSubmit, formState: { errors }, watch, setValue, clearErrors } = useForm<IFormData>({
    defaultValues: {
      documentIds: undefined,
      options: undefined,
      notificationEmail: ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      const { options, ...otherData } = data;
      const nextData:IFormData = {
        ...otherData,
        insuranceCaseId: Number(caseId)
      };
      if ( options && options.length ) nextData['options'] = options;
      await dispatch(createCaseShare(nextData)).unwrap();
      onClose();
    } catch(error){}
  });

  const handleSelectAllOptions = () => {
    setValue('options', optionsOptions.map((option) => option.id));
  }

  const handleClick = () => {
    setValue('documentIds', documentsOptions.map((option:IOption) => Number(option.id)));
    if ( errors.documentIds ) clearErrors('documentIds');
  }

  const watchDocumentIds = watch('documentIds');

  const totalNumberOfPages = [...(watchDocumentIds || [])].reduce((acc:number, cur:number) => {
    const documentEntity = documentEntities[cur];
    return acc + (documentEntity.numberOfPages || 0);
  }, 0);

  const actions = (
    <Fragment>
      <Button
        name="Cancel share case dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Submit share case dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.share')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.adminPages.sharedCasesPage.caseShare')}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
        <Controller
          control={control} name="options"
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={optionsOptions}
              InputProps={{
                label: t('labels.options'),
                name: field.name
              }}
              multiple={true}
              limitTags={3}
              filterSelectedOptions
            />
          )}
        />
        <Button
          name="Select all options"
          sx={{ mt: 6.5 }}
          onClick={handleSelectAllOptions}
          variant="contained"
        >{t('labels.all')}</Button>
      </Box>

      {/* Documents */}
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
        <Controller
          control={control} name="documentIds"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={documentsOptions}
              InputProps={{
                label: t('labels.documents'),
                name: field.name,
                required: true,
                error: Boolean(errors.documentIds),
                helperText: (errors.documentIds as any)?.message || '',
              }}
              groupBy={(option) => option.props.collection.name}
              renderGroup={(params) => (
                <Box key={params.key}>
                  <Box sx={{
                    bgcolor: 'rgb(238,238,238)',
                    color: 'rgba(0,0,0,0.87)',
                    fontWeight: 700,
                    py: 2,
                    px: 4
                  }}>{params.group}</Box>
                  {params.children}
                </Box>
              )}
              multiple={true}
              limitTags={3}
              filterSelectedOptions
            />
          )}
        />
        <Button
          name="Select all documents"
          sx={{ mt: 6.5 }}
          onClick={handleClick}
          variant="contained"
        >{t('labels.all')}</Button>
      </Box>

      <FormHelperText>{t('labels.totalPages')}: {totalNumberOfPages}</FormHelperText>

      {/* Notification e-mail */}
      <Controller
        control={control} name="notificationEmail"
        rules={{ required: isRequired, pattern: isEmailValid }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('pages.adminPages.sharedCasesPage.notificationEmail')} type="email"
            required
            error={Boolean(errors.notificationEmail)}
            helperText={errors.notificationEmail ? errors.notificationEmail.message : ''}
          />
        )}
      />
      <FormHelperText>{t('pages.adminPages.sharedCasesPage.formHelperText')}</FormHelperText>
    </Dialog>
  )
}

export default ShareFormDialog;
