import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';

export const selectSnackbars = (state:RootState) => state[Reducers.AppUiNotifications].snackbars;
export const selectSystemMessages = (state:RootState) => state[Reducers.AppUiNotifications].systemMessages;
export const selectPushAlerts = (state:RootState) => state[Reducers.AppUiNotifications].pushAlerts;

type IPushAlerts = RootState[Reducers.AppUiNotifications]['pushAlerts'];

export const selectPushAlert = createSelector(
  [
    selectPushAlerts,
    (_:any, props:{
      category:keyof IPushAlerts;
      type:keyof IPushAlerts[keyof IPushAlerts]
    }) => props,
  ],
  (pushAlerts:IPushAlerts, { category, type }) => {
    return pushAlerts[category][type]
  }
);
