import { FC } from 'react';
// Models
import ISubscription from 'app/models/Subscription';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCurrentAccountBilling } from 'app/store/Accounts/Accounts.selectors';
// Components
import Dialog from 'app/components/Dialog';
import Subscription from 'app/components/Subscription';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
}

const MySubscriptionDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  // State
  const currentAccountBilling:ISubscription | null = useAppSelector(selectCurrentAccountBilling);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.adminPages.companyAccountPage.accountSubscription')}
    >
      <Subscription accountBilling={currentAccountBilling} />
    </Dialog>
  )
}

export default MySubscriptionDialog;
