// import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';

export const selectUsers = (state:RootState) => state[Reducers.UsersManagement].users;
export const selectParams = (state:RootState) => state[Reducers.UsersManagement].params;
export const selectHasMore = (state:RootState) => state[Reducers.UsersManagement].hasMore;
export const selectGettingMore = (state:RootState) => state[Reducers.UsersManagement].gettingMore;
export const selectLoading = (state:RootState) => state[Reducers.UsersManagement].loading;
