enum AuditOperations {
  CaseLabelRemoving = 'caseLabelRemoving',
  CaseLabelAdding = 'caseLabelAdding',

  DocumentLabelRemoving = 'documentLabelRemoving',
  DocumentLabelAdding = 'documentLabelAdding',

  CaseShared = 'caseShared',
  ReportShared = 'reportShared',
  ExportWorkspacePagesToPDF = 'exportWorkspacePagesToPDF',
  ExportPagesToPDF = 'exportPagesToPDF',
  CoverLetterRemoval = 'coverLetterRemoval',

  CaseNotesUpdate = 'caseNotesUpdate'
};

export default AuditOperations;
