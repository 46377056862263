import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
import AdditionalToolsNames from 'app/types/AdditionalToolsNames';
// Service
import AdditionalToolsService from 'app/services/AdditionalToolsService';

interface IState {
  columns: string;
  compare: string[];
  gridResizer: number;

  showRecordsSidebar: boolean;

  showFilesToolbar: boolean;
  showWorkspaceToolbar: boolean;

  showFilesAdditionalTools: boolean;
  showWorkspaceAdditionalTools: boolean;

  additionalTools: {
    files: {
      shown: AdditionalToolsNames[],
      hidden: AdditionalToolsNames[]
    },
    workspace: {
      shown: AdditionalToolsNames[],
      hidden: AdditionalToolsNames[]
    }
  };

  // ToDO
  workspaceType: 'pages' | 'episodes';
};

const initialState:IState = {
  columns: 'columns_auto',
  compare: [],
  gridResizer: 70,

  showRecordsSidebar: true,

  showFilesToolbar: AdditionalToolsService.getToolbarShown('files'),
  showWorkspaceToolbar: AdditionalToolsService.getToolbarShown('workspace'),

  showFilesAdditionalTools: AdditionalToolsService.getAdditionalToolsShown('files'),
  showWorkspaceAdditionalTools: AdditionalToolsService.getAdditionalToolsShown('workspace'),

  additionalTools: {
    files: AdditionalToolsService.getTools('files'),
    workspace: AdditionalToolsService.getTools('workspace')
  },

  // ToDO
  workspaceType: 'pages'
};

const slice = createSlice({
  name: Reducers.Ui,
  initialState,
  reducers: {
    setColumns: (state, action:PayloadAction<string>) => {
      state.columns = action.payload;
    },
    addToCompare: (state, action:PayloadAction<string>) => {
      state.compare = [...state.compare, action.payload];
    },
    removeFromCompare: (state, action:PayloadAction<string>) => {
      state.compare = state.compare.filter((item:string) => item !== action.payload);
    },
    toggleRecordsSidebar: (state) => {
      state.showRecordsSidebar = !state.showRecordsSidebar;
    },
    // Additional tools
    toggleFilesToolbar: (state) => {
      state.showFilesToolbar = !state.showFilesToolbar;
      AdditionalToolsService.updateToolbarShown('files', state.showFilesToolbar);
    },
    toggleWorkspaceToolbar: (state) => {
      state.showWorkspaceToolbar = !state.showWorkspaceToolbar;
      AdditionalToolsService.updateToolbarShown('workspace', state.showWorkspaceToolbar);
    },

    toggleFilesAdditionalTools: (state) => {
      state.showFilesAdditionalTools = !state.showFilesAdditionalTools;
      AdditionalToolsService.updateAdditionalToolsShown('files', state.showFilesAdditionalTools);
    },
    toggleWorkspaceAdditionalTools: (state) => {
      state.showWorkspaceAdditionalTools = !state.showWorkspaceAdditionalTools;
      AdditionalToolsService.updateAdditionalToolsShown('workspace', state.showWorkspaceAdditionalTools);
    },

    updateAdditionalTools: (state, action:PayloadAction<{ toolsType:'files' | 'workspace', toolName:AdditionalToolsNames }>) => {
      const { toolsType, toolName } = action.payload;

      const tools = state.additionalTools[toolsType];

      const shown = tools.shown.includes(toolName)
        ? tools.shown.filter((t:any) => t !== toolName)
        : [...tools.shown, toolName]
      ;
      const hidden = tools.hidden.includes(toolName)
        ? tools.hidden.filter((t:any) => t !== toolName)
        : [...tools.hidden, toolName]
      ;

      const nextTools = { shown, hidden };

      state.additionalTools[toolsType] = nextTools as any;

      AdditionalToolsService.updateTools(toolsType, nextTools);
    },

    setInitial: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },

    // ToDO
    setWorkspaceType: (state, action:PayloadAction<'pages' | 'episodes'>) => {
      state.workspaceType = action.payload;
    }
  }
});

export const {
  setColumns,
  addToCompare, removeFromCompare,
  toggleRecordsSidebar,
  toggleFilesToolbar, toggleWorkspaceToolbar,
  toggleFilesAdditionalTools, toggleWorkspaceAdditionalTools,
  updateAdditionalTools,
  setInitial,
  // ToDO
  setWorkspaceType
} = slice.actions;

export default slice.reducer;
