import { FC } from 'react';
// Models
import ITemplate from 'app/models/Template';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectNotImportedGlobalTemplates } from 'app/store/Templates/Templates.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import GlobalTemplatesListItem from './GlobalTemplatesListItem';
// i18next
import { useTranslation } from 'react-i18next';

const GlobalTemplatesList:FC = () => {
  const { t } = useTranslation('common');

  const templates:ITemplate[] | null = useAppSelector(selectNotImportedGlobalTemplates);

  if ( !templates ) return <Loader />;
  if ( !templates.length ) return <Message text={t('pages.staffPages.caseDetailsPage.noGlobalTemplates')} />;
  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      overflowY: 'auto',
      p: 4
    }}>
      {templates.map((template:ITemplate) => (
        <GlobalTemplatesListItem
          key={`Global template item ${template.id}`}
          template={template}
        />
      ))}
    </Box>
  )
}

export default GlobalTemplatesList;
