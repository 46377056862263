import { createSelector } from '@reduxjs/toolkit';
// Service
import { generatePageId } from 'app/store/page/page.service';
import { RootState } from 'app/store';

export const selectHighlightModeEnabled = (state:RootState) => state.v3_app.highlightModeEnabled;
export const selectLastOpenedWorkspacePages = (state:RootState) => state.v3_app.lastOpenedWorkspacePages;
export const selectLastOpenedFilePages = (state:RootState) => state.v3_app.lastOpenedFilePages;

export const selectIsWorkspacePageLastOpened = createSelector(
  [
    selectLastOpenedWorkspacePages,
    (_:any, props:{ insuranceCaseId:number, documentId:number, pageNum:number }) => props
  ],
  (lastOpenedWorkspacePages:{ [key:number]:string }, { insuranceCaseId, documentId, pageNum }) => {
    const insuranceCasePage = lastOpenedWorkspacePages[insuranceCaseId];
    return insuranceCasePage === generatePageId(documentId, pageNum)
  }
);

export const selectIsFilePageLastOpened = createSelector(
  [
    selectLastOpenedFilePages,
    (_:any, props:{ insuranceCaseId:number, documentId:number, pageNum:number }) => props
  ],
  (lastOpenedFilePages:{ [key:number]:string }, { insuranceCaseId, documentId, pageNum }) => {
    const insuranceCasePage = lastOpenedFilePages[insuranceCaseId];
    return insuranceCasePage === generatePageId(documentId, pageNum)
  }
);
