import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Theme, Box } from '@mui/material';

type Props = {
  percents: number;
  roundedPercents: number;
  roundedRefinePercents: number;
};

const MessageRelevantText:FC<Props> = ({
  // Props
  percents, roundedPercents, roundedRefinePercents
}) => {
  const { t } = useTranslation('common');

  if ( !percents ) return null;
  return (
    <Box
      sx={{
        border: (theme:Theme) => `2px solid ${theme.palette.primary.main}`,
        bgcolor: (theme:Theme) => theme.palette.primary.light,
        borderRadius: '0 0 8px 8px',
        fontSize: 11,
        px: 4,
        py: 2
      }}
    >{`${t('pages.staffPages.caseDetailsPage.basedOnRelevantDocument', { percents: roundedPercents })}`} {percents !== 100 ? t('pages.staffPages.caseDetailsPage.refineHint', { moreDocumentPagesPercents: roundedRefinePercents }) : ''}</Box>
  )
}

export default MessageRelevantText;
