import { FC } from 'react';

import { Box } from '@mui/material';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Components
import CaseInfo from 'app/components/CaseInfo';

const CaseInfoBar:FC = () => {
  const insuranceCase = useAppSelector(selectInsuranceCase);

  if ( !insuranceCase ) return null;
  return (
    <Box sx={{ px: 6, py: 2, borderBottom: '1px solid rgba(0,0,0,0.08)' }}>
      <CaseInfo direction="row" />
    </Box>
  )
}

export default CaseInfoBar;
