import { FC } from 'react';
import { useParams, NavLink } from 'react-router-dom';
// Models
import { RootState } from 'app/store';
import IConversation from 'app/models/Conversation';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectUnseenConversation } from 'app/store/Conversations/Conversations.selectors';
// Mui
import { ListItemButton, ListItemText } from '@mui/material';
// Utilities
import { parseDateFromUtcToLocal } from '_utilities/datetime.utility';

type Props = {
  conversation: IConversation;
}

const ConversationsListItem:FC<Props> = ({
  // Props
  conversation
}) => {
  const { caseId } = useParams<{ caseId:string }>();

  // State
  const unseenConversation:IConversation | null = useAppSelector((state:RootState) => selectUnseenConversation(state, {
    conversationId: conversation.id
  }));

  const fontWeight = conversation.me && conversation.me.numberOfUnseenMessages > 0 ? 'bold' : 'normal';

  const lastMessageText = unseenConversation?.lastMessage?.text
    ? unseenConversation.lastMessage.text
    : conversation?.lastMessage?.text
      ? conversation.lastMessage.text
      : null
  ;

  const lastMessageCreatedOn = unseenConversation?.lastMessage?.createdOn
    ? unseenConversation.lastMessage.createdOn
    : conversation?.lastMessage?.createdOn
      ? conversation.lastMessage.createdOn
      : null
  ;

  return (
    <ListItemButton
      sx={{
        borderLeft: `4px solid transparent`,
        borderBottom: '1px solid rgba(0,0,0,0.08)'
      }}
      component={NavLink}
      to={`/admin/cases/${caseId}/messages/${conversation.id}`}
      activeStyle={{
        backgroundColor: 'rgba(0,0,0,0.04)',
        borderLeftColor: 'rgba(100,100,230,1)'
      }}
    >
      <ListItemText
        primary={<b style={{ fontWeight }}>{conversation.name}</b>}
        secondary={
          lastMessageText ? (
            <span style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <b style={{
                fontWeight,
                flexGrow: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '2px'
              }}>{lastMessageText}</b>
              {lastMessageCreatedOn ? (
                <b style={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  whiteSpace: 'nowrap'
                }}>{parseDateFromUtcToLocal(lastMessageCreatedOn)}</b>
              ) : null}
            </span>
          ) : null
        }
      />
    </ListItemButton>
  )
}

export default ConversationsListItem;
