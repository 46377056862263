import { FC, Fragment } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selector
import { selectCompare } from 'app/store/ui/uiSelectors';
// Mui
import { IconButton, Tooltip, Badge } from '@mui/material';
// Dialogs
import CompareDialog from 'app/dialogs/CompareDialog';
// Components
import Icon from 'app/components/Icon';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

const Compare:FC = () => {
  const { t } = useTranslation('common');
  // State
  const compare:string[] = useAppSelector(selectCompare);

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Tooltip title={t('labels.compare')}>
        <Badge aria-label={t('labels.compare')} badgeContent={compare.length} color="primary">
          <IconButton
            onClick={toggle}
            color={compare.length ? 'primary' : 'default'}
          ><Icon icon="compare" /></IconButton>
        </Badge>
      </Tooltip>
      {open ? (
        <CompareDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default Compare;
