import { FC, Fragment, useLayoutEffect, useRef } from 'react';
// Mui
import { Box } from '@mui/material';
// 
import PopoverContent from './PopoverContent';

type Props = {
  onClose: () => void;
  selectedBar: any;
  showDailyButton?: boolean;
}

const ChartPopover:FC<Props> = ({
  // Props
  onClose, selectedBar, showDailyButton = false
}) => {
  const episodes = selectedBar.dataPoint.events;

  const popoverRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if ( popoverRef && popoverRef.current ){
      const { chart, y, x } = selectedBar;
      if ( x + 480 > chart.width + 8 ){
        popoverRef.current.style.left = `auto`;
        popoverRef.current.style.right = `${chart.width - x + 8}px`;
      } else {
        popoverRef.current.style.left = `${x + 8}px`;
        popoverRef.current.style.right = `auto`;
      }
      if ( !showDailyButton ){
        popoverRef.current.style.bottom = `${chart.height - y + 8}px`;
      } else {
        popoverRef.current.style.top = `${y}px`;
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Box
        sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}
        onClick={onClose}
      ></Box>
      <div ref={popoverRef} style={{
        position: 'absolute',
        maxHeight: '480px',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '2px 2px 4px rgba(0,0,0,0.35)',
        overflowY: 'auto',
        zIndex: 1001
      }}>
        {episodes.map((episode:any) => (
          <PopoverContent
            key={`chart-popover-item-${episode.id}`}
            episode={episode}
            onClose={onClose}
            showDailyButton={showDailyButton}
          />
        ))}
      </div>
    </Fragment>
  )
}

export default ChartPopover;
