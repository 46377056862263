import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IAuditRecord from "app/models/AuditRecord";
// Async
import { getAuditRecords, createAuditRecord } from './Audit.async';

interface IState {
  auditRecords: IAuditRecord[] | null;
  loading: boolean;
  search: string;
};

const initialState:IState = {
  auditRecords: null,
  loading: false,
  search: ''
};

const slice = createSlice({
  name: Reducers.Audit,
  initialState,
  reducers: {
    setSearch: (state, action:PayloadAction<string>) => {
      state.search = action.payload;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers(builder) {
    // Get assignments
    builder.addCase(getAuditRecords.pending, (state) => {
      state.auditRecords = null;
    })
    builder.addCase(getAuditRecords.fulfilled, (state, action:PayloadAction<{ total:number, data:IAuditRecord[] }>) => {
      state.auditRecords = action.payload.data;
    })
    // Create assignments
    builder.addCase(createAuditRecord.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(createAuditRecord.fulfilled, (state, action:PayloadAction<IAuditRecord>) => {
      if ( state.auditRecords ){
        state.auditRecords = [...state.auditRecords, action.payload];
      }
    })
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const AuditActions = slice.actions;

export default slice.reducer;
