import { FC } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilesSidebarOpen, selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// Mui
import { Paper } from '@mui/material';
// Constants
import { SIDEBAR_WIDTH } from 'app/App.constants';
// 
import BatchesFilter from './BatchesFilter';
import CollectionTabs from './CollectionTabs';
import BatchesList from './BatchesList';
import CollectionTotalPages from './CollectionTotalPages';
import SidebarToggle from './SidebarToggle';

const DocumentsSidebar:FC = () => {
  // State
  const open = useAppSelector(selectFilesSidebarOpen);
  const trialMode = useAppSelector(selectTrialMode);

  if ( trialMode ) return null;
  return (
    <Paper
      sx={{
        borderTop: 0,
        borderLeft: 0,
        borderBottom: 0,
        width: SIDEBAR_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        position: 'relative',
        marginLeft: open ? 0 : `-${SIDEBAR_WIDTH}px`
      }}
      variant="outlined"
      square
    >
      <BatchesFilter />
      <CollectionTabs />
      <BatchesList />
      <CollectionTotalPages />
      <SidebarToggle />
    </Paper>
  )
}

export default DocumentsSidebar;
