
// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./EpisodeFacts.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/episodeFacts';

export const getFacts = asyncThunkHandler(
  `${Reducers.EpisodeFacts}/Get facts`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const createFact = asyncThunkHandler(
  `${Reducers.EpisodeFacts}/Create fact`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

export const updateFact = asyncThunkHandler(
  `${Reducers.EpisodeFacts}/Update fact`,
  async ({ factId, data }:{ factId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${factId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

export const deleteFact = asyncThunkHandler(
  `${Reducers.EpisodeFacts}/Delete fact`,
  async (factId:number, { fulfillWithValue, dispatch }) => {
    await $delete(`${_url}/${factId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(factId);
  }
);
