import { FC, Fragment } from 'react';
// Models
import IBillingOrder, { IBillingOrderLine } from 'app/models/BillingOrder';
// Mui
import {
  Theme,
  Divider,
  Box, Paper, Typography,
  Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
// 
import OrderLineItem from './OrderLineItem';
import useToggle from 'app/hooks/useToggle';
import OrderFormDialog from './OrderFormDialog';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  order: IBillingOrder;
}

const OrdersListItem:FC<Props> = ({
  // Props
  order
}) => {
  const { t } = useTranslation('common');

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();

  const isStatusOpen = order.status === 'open';

  return (
    <Fragment>
      <Paper sx={{ display: 'flex', p: 4 }} variant="outlined">
        <Box sx={{ flexGrow: 1, pr: isStatusOpen ? 2 : 0 }}>
          <Typography
            sx={{
              color: (theme:Theme) => order.status === 'cancelled'
                    ? theme.palette.error.main
                    : order.status === 'completed'
                      ? theme.palette.success.main
                      : theme.palette.info.main
              ,
              textTransform: 'capitalize'
            }}
            variant="caption"
          >{order.status}</Typography>
          {/* <Typography variant="subtitle1">{order.case.name}</Typography> */}
          {(order as any).description ? (
            <Typography variant="body2" color="CaptionText">{(order as any).description}</Typography>
          ) : null}
          {order.lines && order.lines.length ? (
            <Fragment>
              <Divider sx={{ my: 2 }} />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('labels.name')}</TableCell>
                    <TableCell>{t('labels.description')}</TableCell>
                    <TableCell>{t('labels.qty')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.lines.map((line:IBillingOrderLine) => (
                    <OrderLineItem
                      key={`${order.id}-line-item-${line.id}`}
                      line={line}
                    />
                  ))}
                </TableBody>
              </Table>
            </Fragment>
          ) : null}
        </Box>
        {isStatusOpen ? (
          <Box sx={{ mt: -2, mr: -2 }}>
            <IconButton
              name={`Order dropdown ${order.id}`}
              aria-label="Order item dropdown button"
              onClick={openMenu}
            ><MoreVertIcon /></IconButton>
            <Menu>
              <MenuItem
                name={`edit-${order.id}`}
                onClick={toggle}
              >{t('labels.edit')}</MenuItem>
            </Menu>
          </Box>
        ) : null}
      </Paper>
      {open ? (
        <OrderFormDialog
          open={open}
          onClose={toggle}
          order={order}
        />
      ) : null}
    </Fragment>
  )
}

export default OrdersListItem;
