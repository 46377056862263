import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';

interface IState {
  abortControllers: AbortController[];
  activeRequests: number;
  aborted: boolean;
}

const initialState:IState = {
  abortControllers: [],
  activeRequests: 0,
  aborted: false,
};

const requestSlice = createSlice({
  name: Reducers.Requests,
  initialState,
  reducers: {
    addAbortController(state, action:PayloadAction<AbortController>) {
      state.abortControllers.push(action.payload);
    },
    clearAbortControllers(state) {
      state.abortControllers.forEach(controller => controller.abort());
      state.abortControllers = [];
      state.aborted = true;
    },
    incrementActiveRequests(state) {
      state.activeRequests += 1;
    },
    decrementActiveRequests(state) {
      state.activeRequests -= 1;
      if ( state.activeRequests === 0 && !state.aborted ){
        state.abortControllers = [];
        state.aborted = false;
      }
    },
  },
});

export const RequestsActions = requestSlice.actions;

export default requestSlice.reducer;