import AdditionalTools from 'app/types/AdditionalToolsNames';

const STORAGE_KEY = 'cases-portal'

export default class AdditionalToolsService {
  private static readonly TOOLBAR_SHOWN_KEY:string = `${STORAGE_KEY}:toolbar_shown`
  private static readonly ADDITIONAL_TOOLS_SHOWN_KEY:string = `${STORAGE_KEY}:additional_tools_shown`;
  private static readonly ADIITIONAL_TOOLS:string = `${STORAGE_KEY}:tools`;
  private static readonly INITIAL_TOOLS:any = {
    files: {
      shown: [
        AdditionalTools.GoToLastOpenedPage,
        AdditionalTools.GoToPage,
        AdditionalTools.NumberOfColumns,
        AdditionalTools.Tags,
        AdditionalTools.Colors,
        AdditionalTools.HaveDuplicateInWorkspace
      ],
      hidden: []
    },
    workspace: {
      shown: [
        AdditionalTools.GoToPostEvent,
        AdditionalTools.GoToLastOpenedPage,
        AdditionalTools.GoToPage,
        AdditionalTools.NumberOfColumns,
        AdditionalTools.EpisodeTypes,
        AdditionalTools.EpisodeLabels,
        AdditionalTools.EpisodeAuthors,
        AdditionalTools.Tags,
        AdditionalTools.Colors,
        AdditionalTools.AdditionalRecordsOnly
      ],
      hidden: []
    }
  };

  public static getToolbarShown(toolsType:'files' | 'workspace'){
    const shown = localStorage.getItem(`${this.TOOLBAR_SHOWN_KEY}:${toolsType}`) || undefined;
    return typeof shown !== 'undefined' ? shown === 'false' ? false : true : false;
  }

  public static updateToolbarShown(toolsType:'files' | 'workspace', shown:boolean){
    localStorage.setItem(`${this.TOOLBAR_SHOWN_KEY}:${toolsType}`, JSON.stringify(shown));
  }

  // Get additional tools shown
  public static getAdditionalToolsShown(toolsType:'files' | 'workspace'){
    const shown = localStorage.getItem(`${this.ADDITIONAL_TOOLS_SHOWN_KEY}:${toolsType}`) || undefined;
    return typeof shown !== 'undefined' ? shown === 'false' ? false : true : false;
  }

  public static updateAdditionalToolsShown(toolsType:'files' | 'workspace', shown:boolean){
    localStorage.setItem(`${this.ADDITIONAL_TOOLS_SHOWN_KEY}:${toolsType}`, JSON.stringify(shown));
  }

  public static getTools(toolsType:'files' | 'workspace'){
    const tools = localStorage.getItem(`${this.ADIITIONAL_TOOLS}:${toolsType}`);
    return tools ? JSON.parse(tools) : this.INITIAL_TOOLS[toolsType];
  }

  public static updateTools(toolsType:'files' | 'workspace', tools:any){
    localStorage.setItem(`${this.ADIITIONAL_TOOLS}:${toolsType}`, JSON.stringify(tools));
  }
}