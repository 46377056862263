import { Middleware } from '@reduxjs/toolkit'
// Types
import Reducers from 'app/types/Reducers';
// Async
import {
  createInsuranceCase, updateInsuranceCase, patchInsuranceCase, deleteInsuranceCase,
  archiveInsuranceCase, unarchiveInsuranceCase,
  acceptInsuranceCase, rejectInsuranceCase,
  downloadCalendarFile
} from 'app/store/Cases/Cases.async';
// Services
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

const CasesMiddleware:Middleware = () => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.Cases) ){
    if ( type === createInsuranceCase.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseCreated, { caseId: payload.id });
    }
    if ( type === updateInsuranceCase.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseDetailsUpdated, { caseId: payload.id });
    }
    if ( type === patchInsuranceCase.fulfilled.type ){
      if ( payload.notes ){
        Mixpanel.track(MixpanelTracks.CaseNotesUpdated, { caseId: payload.id });
      }
      if ( payload.labels ){
        Mixpanel.track(MixpanelTracks.CaseLabelsUpdated, { caseId: payload.id });
      }
    }
    if ( type === deleteInsuranceCase.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseDeleted, { caseId: payload });
    }
    if ( type === archiveInsuranceCase.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseArchived, { caseId: payload });
    }
    if ( type === unarchiveInsuranceCase.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseUnarchived, { caseId: payload });
    }
    if ( type === acceptInsuranceCase.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseApproved, { caseId: payload });
    }
    if ( type === rejectInsuranceCase.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseRejected, { caseId: payload });
    }
    if ( type === downloadCalendarFile.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseCalendarExported, { caseId: payload });
    }
  }

  return next(action);
}

export default CasesMiddleware;
