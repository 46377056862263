import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AIChatPromptsActions } from 'app/store/AIChatPrompts/AIChatPrompts.slice';
// Selectors
import { selectPrompts, selectFilter } from 'app/store/AIChatPrompts/AIChatPrompts.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const AIChatPromptsFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const prompts = useAppSelector(selectPrompts);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  // eslint-disable-next-line
  const debounceFilter = useCallback(debounce(500, (field:keyof typeof filter, value:any) => {
    dispatch(AIChatPromptsActions.setFilter({ field, value }))
  }), []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    debounceFilter(name as keyof typeof filter, value);
  }

  if ( prompts && !prompts.length ) return null;
  return (
    <Box sx={{ display: 'flex', mb: 4 }}>
      <Input
        label={t('labels.search')} id="search" name="search"
        placeholder="Preset name"
        value={stateFilter.search}
        onChange={handleChange}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default AIChatPromptsFilter;
