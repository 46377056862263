
import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import { IAnnotation } from 'app/models/LabellingTool';

export const selectAnnotations = (state:RootState) => state[Reducers.LabellingToolAnnotations].annotations;
export const selectLoading = (state:RootState) => state[Reducers.LabellingToolAnnotations].loading;

export const selectAnnotationInJob = createSelector(
  [
    selectAnnotations,
    (_, props:{ jobId:number, label:string }) => props
  ],
  ( annotations:IAnnotation[] | null, { jobId, label } ) => {
    if ( !annotations ) return null;
    return annotations.find((annotation:IAnnotation) => annotation.jobId === jobId && annotation.label === label) || null;
  }
);
