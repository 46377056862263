import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";
// Async
import { getMyPreferences, updateMyPreferences } from './currentUserAsync';

interface IState {
  preferences: any;
  selectedDate: Dayjs | null;
  filter: {
    showAll: boolean | undefined;
    labels: string[];
    authors: any[];
    inWorkspace: boolean;
  };
  loading: boolean;
}

const initialState:IState = {
  preferences: null,
  selectedDate: null,
  filter: {
    showAll: undefined,
    labels: [],
    authors: [],
    inWorkspace: true
  },
  loading: false
}

const currentUser = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setSelectedDate: (state, action:PayloadAction<Dayjs | null>) => {
      state.selectedDate = action.payload;
    },
    setFilter: (state, action:PayloadAction<{ field:string, value:string[] | boolean }>) => {
      (state.filter as any)[action.payload.field] = action.payload.value
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get my preferences
    builder.addCase(getMyPreferences.pending, (state) => {
      state.preferences = null
    })
    builder.addCase(getMyPreferences.fulfilled, (state, action:PayloadAction<any>) => {
      state.preferences = action.payload;
    })
    builder.addCase(getMyPreferences.rejected, (state) => {
      state.preferences = {};
    })
    // Update my preferences
    builder.addCase(updateMyPreferences.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(updateMyPreferences.fulfilled, (state, action:PayloadAction<any>) => {
      state.preferences = action.payload;
    })

    builder.addMatcher(
      (action) => action.type.includes('/rejected') || action.type.includes('/fulfilled'),
      (state) => {
        state.loading = false;
      }
    )
  }
});

export const { setSelectedDate, setFilter, setInitialField } = currentUser.actions;

export default currentUser.reducer;
