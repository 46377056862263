// Mui
import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  TabTypeMap as MuiTabTypeMap
} from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

const Tab = <
  D extends React.ElementType = MuiTabTypeMap['defaultComponent'],
  P = {}
>(props:MuiTabProps<D, P>) => {
  return (
    <MuiTab
      {...props}
      {...generateAttrForTesting('action', 'button-tab', props.value)}
    />
  )
}

export default Tab;
