import IEpisode from "app/models/Episode";
import { FC, ReactNode, createContext, useContext, useState } from "react";

type ContextType = {
  episodes: IEpisode[];
  onCreate: (episode:IEpisode) => void;
  onUpdate: (episode:IEpisode) => void;
  onPatch: (data:Partial<IEpisode>) => void;
  onDelete: (episodeId:number) => void;
};

const Context = createContext<ContextType | undefined>(undefined);

export const usePageEpisodesContext = ():ContextType => {
  const context = useContext(Context);
  if ( !context ) throw new Error('usePageEpisodesContext must be used within a PageEpisodesProvider');
  return context;
}

type PropsType = {
  episodes: IEpisode[];
  children: ReactNode;
};

const PageEpisodesProvider:FC<PropsType> = ({
  // Props
  episodes, children
}) => {
  const [ stateEpisodes, setStateEpisodes ] = useState<IEpisode[]>(episodes);

  const handleCreate = (episode:IEpisode) => {
    setStateEpisodes((prevState) => ([...prevState, episode]));
  }

  const handleUpdate = (episode:IEpisode) => {
    setStateEpisodes((prevState) => prevState.map((ep:IEpisode) => {
      if ( ep.id === episode.id ) return episode;
      return ep;
    }));
  }

  const handlePatch = (data:Partial<IEpisode>) => {
    setStateEpisodes((prevState) => prevState.map((ep:IEpisode) => {
      if ( ep.id === data.id ) return {...ep, ...data};
      return ep;
    }));
  }

  const handleDelete = (episodeId:number) => {
    setStateEpisodes((prevState) => prevState.filter((ep:IEpisode) => ep.id !== episodeId));
  }

  return (
    <Context.Provider value={{
      episodes: stateEpisodes,
      onCreate: handleCreate,
      onUpdate: handleUpdate,
      onPatch: handlePatch,
      onDelete: handleDelete,
    }}>{children}</Context.Provider>
  )
}

export default PageEpisodesProvider;