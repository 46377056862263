import { FC, useEffect } from 'react';
import { forceVisible } from 'react-lazyload';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { setWorkspaceType } from 'app/store/ui/uiSlice';
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectFilter } from 'app/store/page/page.selectors';
// Components
import { Switch } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const AdditionalRecordsOnly:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const filter = useAppSelector((state:any) => selectFilter(state));

  useEffect(() => {
    return () => {
      dispatch(setWorkspaceType('pages'));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (_:any, checked:boolean) => {
    dispatch(PageActions.setFilter('additional', checked));
    forceVisible();
  }

  if ( trialMode ) return null;
  return (
    <Switch
      label={t('layouts.workspaceTools.additionalBatchesOnly')} value={filter.additional} name="additional"
      onChange={handleChange}
    />
  )
}

export default AdditionalRecordsOnly;
