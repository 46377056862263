import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import FileFormats from 'app/types/FileFormats';
// Models
import { RootState } from 'app/store';
import IGeneralSettings, { CaseStatusTransitionRules } from 'app/models/Settings';
import IFileFormat from 'app/models/FileFormat';
// Constants
import { LANGUAGES } from 'app/App.constants';

export const selectGeneralSettings = (state:RootState) => state[Reducers.Settings].generalSettings;
export const selectLoading = (state:RootState) => state[Reducers.Settings].loading;

export const selectDateFormatAsOptions = createSelector(
  [ selectGeneralSettings ],
  ( generalSettings:IGeneralSettings | null ) => {
    if ( !generalSettings || !generalSettings.dateFormats ) return null;
    return generalSettings.dateFormats.map((dateFormat:string) => ({
      id: dateFormat,
      name: dateFormat
    }))
  }
);

export const selectTimeFormatAsOptions = createSelector(
  [ selectGeneralSettings ],
  ( generalSettings:IGeneralSettings | null ) => {
    if ( !generalSettings || !generalSettings.timeFormats ) return null;
    return generalSettings.timeFormats.map((timeFormat:string) => ({
      id: timeFormat,
      name: timeFormat === 'HH:mm'
        ? '24-hour ( e.g. 16:30 )'
        : '12-hour ( e.g. 4:30 pm )'
    }))
  }
);

export const selectRecordsLanguageAsOptions = createSelector(
  [ selectGeneralSettings ],
  ( generalSettings:IGeneralSettings | null ) => {
    if ( !generalSettings || !generalSettings.recordsLanguages ) return null;
    return generalSettings.recordsLanguages.map((language:string) => ({
      id: language,
      name: (LANGUAGES as Record<string, string>)[language]
    }))
  }
);

export const selectTimeZoneAsOptions = createSelector(
  [ selectGeneralSettings ],
  ( generalSettings:IGeneralSettings | null ) => {
    if ( !generalSettings || !generalSettings.timeZones ) return null;
    return generalSettings.timeZones.map((timeZone:{ name:string, value:string }) => ({
      id: timeZone.value,
      name: timeZone.name
    }))
  }
);

export const selectCaseStatusTransitionRules = createSelector(
  [ selectGeneralSettings ],
  ( generalSettings:IGeneralSettings | null ) => {
    if ( !generalSettings || !generalSettings.caseStatusTransitionRules ) return null;
    return generalSettings.caseStatusTransitionRules
      .filter((rule:CaseStatusTransitionRules) => rule.status === 'open')
      .map((rule:CaseStatusTransitionRules) => ({
        id: `${rule.status}|${rule.triggerEvent}`,
        name: rule.triggerEvent
      }));
  }
);

export const selectFileFormatOcrEligible = createSelector(
  [
    selectGeneralSettings,
    (_, props:{ fileFormat:FileFormats }) => props
  ],
  ( generalSettings:IGeneralSettings | null, { fileFormat }) => {
    if ( !generalSettings || !fileFormat ) return true;
    const foundedFileFormat = generalSettings.fileFormats.find(({ format }:IFileFormat) => format === fileFormat);
    return foundedFileFormat?.ocrEligible || true;
  }
)
