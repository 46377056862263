import { forwardRef } from 'react';
// Mui
import {
  Chip as MuiChip,
  ChipProps as MuiChipProps
} from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = MuiChipProps & {
  name: string;
  active?: boolean;
};

const Checkbox = forwardRef<any, Props>(({
  // Props
  name, active, ...otherProps
}, ref) => {
  return (
    <MuiChip
      {...otherProps}
      {...generateAttrForTesting('action', 'chip', name)}
      {...generateAttrForTesting('active', 'chip', active)}
    />
  )
})

export default Checkbox;
