import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './CaseReports.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete, resolvePath } from 'app/utilities/HttpClient';
import { downloadFile } from "app/utilities/Utilities";

const _url:string = '/reports';

// Get reports
export const getReports = asyncThunkHandler(
  `${Reducers.CaseReports}/Get reports`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const createReport = asyncThunkHandler(
  `${Reducers.CaseReports}/Create report`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    const response:Response = await $post(_url, data);

    const report = await response.json();

    const openPdf = () => dispatch(previewReport(report.id));

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: `${report.name} ${Messages.Create}`,
      onOpen: openPdf
    }));

    return fulfillWithValue(report);
  }
);

export const updateReport = asyncThunkHandler(
  `${Reducers.CaseReports}/Update report`,
  async ({ reportId, data }:{ reportId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${reportId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

export const deleteReport = asyncThunkHandler(
  `${Reducers.CaseReports}/Delete report`,
  async (reportId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${reportId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(reportId);
  }
);

export const shareReport = asyncThunkHandler(
  `${Reducers.CaseReports}/Share report`,
  async ({ reportId, data }:{ reportId:number, data:any }, { dispatch }) => {
    const response:Response = await $post(`${_url}/${reportId}/actions/share`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Share
    }));
    return response.json();
  }
);

export const downloadReportFile = asyncThunkHandler(
  `${Reducers.CaseReports}/Donwload report file`,
  async ({ reportId, params }:{ reportId:number, params:any }, { fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/${reportId}/file`, params);

    downloadFile(response, { name: `report${reportId}`, ext: 'docx' });

    return fulfillWithValue('Report file downloaded');
  }
);

export const uploadReportFile = asyncThunkHandler(
  `${Reducers.CaseReports}/Upload report file`,
  async ({ reportId, data }:{ reportId:number, data:any }, { fulfillWithValue }) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if ( data[key] ) formData.append(key, data[key]);
    });
    await $post(`${_url}/${reportId}/files`, formData);
    return fulfillWithValue('Report file uploaded');
  }
);

export const previewReport = createAsyncThunk(
  `${Reducers.CaseReports}/Preview report`,
  async (reportId:number) => {
    const reportUrl = await resolvePath(`${_url}/${reportId}/file`, { format: 'pdf', preview: 'true' });
    window.open(reportUrl, '_blank');
  }
);
