// Types
import Reducers from 'app/types/Reducers';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './DMSCollections.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/dms/collections';

// Get collections
export const getCollections = asyncThunkHandler(
  `${Reducers.DMSCollections}/Get collections`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);
// Get collection
export const getCollection = asyncThunkHandler(
  `${Reducers.DMSCollections}/Get collection`,
  async (collectionId:number) => {
    const response:Response = await $get(`${_url}/${collectionId}`);
    return response.json();
  }
);
// Create collection
export const createCollection = asyncThunkHandler(
  `${Reducers.DMSCollections}/Create collection`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);
// Update collection
export const updateCollection = asyncThunkHandler(
  `${Reducers.DMSCollections}/Update collection`,
  async ({ collectionId, data }:{ collectionId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${collectionId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);
// Delete collection
export const deleteCollection = asyncThunkHandler(
  `${Reducers.DMSCollections}/Delete collection`,
  async (collectionId:number, { fulfillWithValue, dispatch }) => {
    await $delete(`${_url}/${collectionId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(collectionId);
  }
);