import { FC, Fragment, useMemo, useState } from 'react';
// Types
import { TemplateDataType, TemplateLayout } from 'app/types/Template';
// Models
import { ITemplateVariable } from 'app/models/Template';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectGroupedTemplateVariables } from 'app/store/Templates/Templates.selectors';
// Mui
import { Paper, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
// Utilities
import { camelCaseToNormalText } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (variableTag:string) => void;
  columnId?: string;
  id: string;
  dataType?: TemplateDataType;
  layout?: TemplateLayout;
}

const VariablesDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm, columnId, id, dataType, layout
}) => {
  
  const { t } = useTranslation('common');
  // State
  const groupedVariables = useAppSelector(selectGroupedTemplateVariables) as Record<string, ITemplateVariable[]>;
  
  const nextGroupedVariables = useMemo(() => {
    // ToDO: recordsFile is deprecated
    const skippedEpisodeKeys = ['recordsFile'];

    console.log(groupedVariables);

    const result:Record<string, ITemplateVariable[]> = {
      general: groupedVariables.general,
      case: groupedVariables.case,
      report: groupedVariables.report
    };
    if (
      (id && id === 'content' && layout === TemplateLayout.Custom) ||
      (columnId && columnId === 'data' && layout === TemplateLayout.Table)
    ){
      if ( dataType === TemplateDataType.Episode ){
        Object.keys(groupedVariables).forEach((key) => {
          if ( !skippedEpisodeKeys.includes(key) ){
            result[key] = groupedVariables[key];
          }
        });
      } else {
        result[dataType as string] = groupedVariables[dataType as string];
      }
    }
    return result;
  }, [columnId, id, dataType, layout, groupedVariables]);

  const [ selectedVariableTag, setSelectedVariableTag ] = useState<string | null>(null);

  const handleClick = (variableTag:string) => () => {
    setSelectedVariableTag(variableTag);
  }

  const handleSelectClick = () => {
    if ( !selectedVariableTag ) return;

    onConfirm(selectedVariableTag);
  }

  const actions = (
    <Fragment>
      <Button
        name="Close variable dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <Button
        name="Select variable dialog"
        disabled={!selectedVariableTag}
        onClick={handleSelectClick}
        variant="contained"
        color="primary"
      >{t('components.quillEditor.insert')}</Button>
    </Fragment>
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('components.quillEditor.variables')}
      actions={actions}

      ContentProps={{
        dividers: true,
        sx: {
          p: 0
        }
      }}
    >
      <Paper sx={{ mx: 6, my: 4 }}>
        <List>
          {Object.keys(nextGroupedVariables).map((key:string) => {
            const variables = nextGroupedVariables[key] as ITemplateVariable[];
            return (
              <Fragment key={`variable group ${key}`}>
                <ListSubheader sx={{
                  bgcolor: 'rgb(238, 238, 238)',
                  color: 'rgba(0,0,0,0.6)',
                  textTransform: 'capitalize'
                }}>{camelCaseToNormalText(key)}</ListSubheader>
                {variables.map((variable:ITemplateVariable) => (
                  <ListItemButton
                    key={`variable item ${variable.name}`}
                    onClick={handleClick(variable.tag)}
                    selected={variable.tag === selectedVariableTag}
                  >
                    <ListItemText
                      primary={variable.description}
                      secondary={variable.tag}
                    />
                  </ListItemButton>
                ))}
              </Fragment>
            )
          })}
        </List>
      </Paper>
    </Dialog>
  )
}

export default VariablesDialog;
