import { FC, useEffect, useState } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectParams } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Pagination } from '@mui/material';

const InvoicesPagination:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const invoicesTotal:number = 0; // useAppSelector(selectInvoicesTotal);
  const params = useAppSelector(selectParams);

  const [ currentPage, setCurrentPage ] = useState(1);

  useEffect(() => {
    if ( params.offset === 0 ) setCurrentPage(1);
    // eslint-disable-next-line
  }, [params.offset]);

  const handleChange = (_:any, value:number) => {
    setCurrentPage(value);
    const offset = (value - 1) * params.limit;
    dispatch(BillingInvoicesActions.setParams({ offset }));
  }

  const pageCount = Math.ceil(invoicesTotal/params.limit);

  if ( pageCount <= 1 ) return null;
  return (
    <Pagination
      sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: 4
      }}
      count={pageCount}
      page={currentPage}
      color="primary"
      onChange={handleChange}
    />
  )
}

export default InvoicesPagination;
