// ToDO
import config from "config";
// End: ToDO
import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./DMSBatches.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $patch, $delete } from 'app/utilities/HttpClient';
// Services
import LocalStorageService from "app/services/LocalStorage.service";

const _url:string = '/dms/batches';

// Get batches
export const getBatches = asyncThunkHandler(
  `${Reducers.DMSBatches}/Get batches`,
  async (params:any, { signal }) => {
    const response:Response = await $get(_url, params, {
      signal
    });
    return response.json();
  }
);

// Get batch
export const getBatch = asyncThunkHandler(
  `${Reducers.DMSBatches}/Get batch`,
  async (batchId:string) => {
    const response:Response = await $get(`${_url}/${batchId}`);
    return response.json();
  }
);

export const createBatch = asyncThunkHandler(
  `${Reducers.DMSBatches}/Create batch`,
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await $post(_url, data);
    if ( !response.ok ){
      if ( response.status === 504 ){
        // Throw an error to evoke reject action without calling `rejectWithoutValue`
        // `rejectWihtoutValue` needed to throw an text error in snackbar
        throw Error(`Getting response with status ${response.status}`);
      }
      return rejectWithValue((await response.json()) as any);
    }
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

export const validateBatch = asyncThunkHandler(
  `${Reducers.DMSBatches}/Validate batch`,
  async (data:any) => {
    const response:Response = await $post(`${_url}/validations`, data);
    return response.json();
  }
);

// Patch batch
export const patchBatch = asyncThunkHandler(
  `${Reducers.DMSBatches}/Patch batch`,
  async ({ batchId, data }:{ batchId:string, data:any }, { dispatch, fulfillWithValue }) => {
    await $patch(`${_url}/${batchId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return fulfillWithValue({ ...data, id: batchId });
  }
);

export const downloadCoverLetter = createAsyncThunk(
  `${Reducers.DMSBatches}/Get cover letter`,
  async (batchId:string, { fulfillWithValue }) => {
    window.open(`${config.apiUrl}${_url}/${batchId}/coverLetter?X-Auth-Token=${LocalStorageService.getAccessToken()}`);
    return fulfillWithValue('Cover letter was downloaded');
  }
);

export const deleteCoverLetter = asyncThunkHandler(
  `${Reducers.DMSBatches}/Delete cover letter`,
  async (batchId:string, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${batchId}/coverLetter`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.DeleteCoverLetter
    }));
    return fulfillWithValue(batchId);
  }
);