import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getReports } from 'app/store/CaseReports/CaseReports.async';
// Mui
import { Box, Container } from '@mui/material';
// 
import ReportsList from './ReportsList';
import ReportsFab from './ReportsFab';
// Services
import MixpanelTracks from 'app/types/MixpanelTracks';
import Mixpanel from 'app/services/Mixpanel.service';

const ReportsPage:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const insuranceCaseId = Number(caseId);

    Mixpanel.track(MixpanelTracks.CaseReportsView, { id: insuranceCaseId });

    dispatch(getReports({ insuranceCaseId }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <ReportsList />
        </Container>
      </Box>
      <ReportsFab />
    </Box>
  )
}

export default ReportsPage;
