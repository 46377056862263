import React from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
import IUser from 'app/models/User';
import { IDocumentExport } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { resendDownloadLink } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.async';
// Selectors
import { selectLoading, selectDocumentsExportsUser } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.selectors';
// MaterialUI
import {
  Box, Paper,
  Divider, Typography, Tooltip
} from '@mui/material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import Icon from 'app/components/Icon';
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
import useLongDateTme from 'app/hooks/useLongDateTime';
import useToggle from 'app/hooks/useToggle';
// 
import DocumentsExportsFileDialog from './DocumentsExportsFileDialog';

type Props = {
  documentExport: IDocumentExport;
};

const DocumentExportItem:React.FC<Props> = ({
  // Props
  documentExport
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const user:IUser | null = useAppSelector((state:RootState) => selectDocumentsExportsUser(state, { userId: documentExport.modifiedBy }));
  const loading = useAppSelector(selectLoading);

  const { isArchived } = useCaseStatus();
  const longCreatedOn = useLongDateTme({
    dateValue: documentExport.createdOn,
    displayTime: true,
    local: true
  });
  const longDownloadedOn = useLongDateTme({
    dateValue: documentExport.downloadedOn,
    displayTime: true,
    local: true
  });

  const { open, toggle } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = async () => {
    try {
      await dispatch(resendDownloadLink(documentExport.id)).unwrap();
      if ( openConfirmationDialog ) toggleConfirmationDialog();
    } catch(error){}
  }

  return (
    <React.Fragment>
      <Paper sx={{ p: 4, '& + &': { mt: 4 } }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                textTransform: 'capitalize'
              }}
              variant="caption"
            >{t('labels.sharedTo')}</Typography>
            <Typography variant="subtitle1">{documentExport.recipient.email}</Typography>
            <InfoBlock label={t('labels.phone')} value={documentExport.recipient.phone} direction="row" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            {documentExport.status === 'ready' || documentExport.status === 'downloaded' ? (
              <Tooltip title={t('pages.adminPages.exportMaterialsPage.resendDownloadLink')}>
                <IconButton
                  name={`Resend download link ${documentExport.id}`}
                  disabled={isArchived}
                  onClick={toggleConfirmationDialog}
                ><Icon icon="shared" /></IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title={t('pages.adminPages.exportMaterialsPage.filesList')}>
              <IconButton
                name={`Documents list ${documentExport.id}`}
                onClick={toggle}
              ><Icon icon="add_to_workspace" /></IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', gap: 4 }}>
          {user?.name ? (
            <InfoBlock label={t('labels.createdBy')} value={user.name} />
          ) : null}
          {longCreatedOn ? (
            <InfoBlock label={t('labels.createdAt')} value={longCreatedOn} />
          ) : null}
          <InfoBlock label={t('labels.status')} value={documentExport.status} />
          {longDownloadedOn ? (
            <InfoBlock label={t('pages.adminPages.exportMaterialsPage.downloadedOn')} value={longDownloadedOn} />
          ) : null}
        </Box>
      </Paper>
      {open ? (
        <DocumentsExportsFileDialog
          open={open}
          onClose={toggle}
          documentIds={documentExport.documentIds || []}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.adminPages.exportMaterialsPage.resendDownloadLink')}
          content={t('pages.adminPages.exportMaterialsPage.resendDownloadLinkConfirmation')}
          onConfirm={handleConfirm}
        />
      ) : null}
    </React.Fragment>
  );
}

export default DocumentExportItem;
