import { FC, Fragment, useMemo, useEffect, useState } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
// Types
import UserRoles from 'app/types/UserRoles';
import Permissions from 'app/types/Permissions';
// Models
import { ICurrentAccount } from 'app/models/Account';
import IUser, { IMyUser } from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createUser, updateUser, importClientUser } from 'app/store/Users/Users.async';
import { getAccountUsers } from 'app/store/Clients/Clients.async';
// Actions
import { UsersActions } from 'app/store/Users/Users.slice';
// Selectors
import { selectLoading, selectMyUser } from 'app/store/Users/Users.selectors';
import { selectAccountsAsOptions, selectAccountUsersAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectCurrentAccount, selectSubscriptionItemWithCptCodesService } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Typography } from '@mui/material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import Dialog from 'app/components/Dialog';
import Phone from 'app/components/Phone';
import { Button, Input, Select, Autocomplete, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { isRequired, isEmailValid } from 'app/utilities/Validations';
// 
import FormPreferencesTogglers from './FormPreferencesTogglers';
import FormPermissionTogglers from './FormPermissionTogglers';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  role: UserRoles | string;
  name: string;
  email: string;
  phone: string;
  customAttributes?: {
    clientAccountId: number | string | null;
    clientUserId?: number | string | null;
  };
  notificationPreferences: {
    sms: boolean;
    email: boolean;
    push: boolean;
  };
  permissions?: (Permissions)[];
  language?: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  user?: IUser;
}

const UserFormDialog:FC<Props> = ({
  // Props
  user, open, onClose
}) => {
  const { t } = useTranslation('common');

  const label = user?.id ? t('labels.update') : t('labels.create');
  const isUserRoleClientOrPresenter = user?.role === UserRoles.Client || user?.role === UserRoles.Presenter;
  // Dispathc
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);
  const accountsOptions = useAppSelector(selectAccountsAsOptions);
  const accountUsersOptions = useAppSelector(selectAccountUsersAsOptions);
  const loading = useAppSelector(selectLoading);
  const subscriptionItem = useAppSelector(selectSubscriptionItemWithCptCodesService);

  const { open:openConfirmation, toggle:toggleConfirmation } = useToggle();

  const [ formData, setFormData ] = useState<IFormData | null>(null);
  const [ hcpcsCodesStatus, setHcpcsCodesStatus ] = useState<boolean | null>(null);

  const userRolesLabels:Record<string, string> = {
    'AccountManager': t('pages.adminPages.usersPage.accountManager'),
    'Admin': t('pages.adminPages.usersPage.admin'),
    'Client': t('pages.adminPages.usersPage.client'),
    'Presenter': t('pages.adminPages.usersPage.presenter'),
    'Staff': t('pages.adminPages.usersPage.staff'),
  }

  const roleOptions = useMemo(() => {
    return (Object.keys(UserRoles) as Array<keyof typeof UserRoles>)
      .filter((key) => UserRoles[key] !== UserRoles.AccountManager)
      .filter((key) => {
        if ( user?.id && !isUserRoleClientOrPresenter ) return UserRoles[key] === UserRoles.Admin || UserRoles[key] === UserRoles.Staff;
        return key;
      })
      .map((key) => ({ id: UserRoles[key], name: userRolesLabels[key] }));
    // eslint-disable-next-line
  }, []);

  const methods = useForm<IFormData>({
    defaultValues: {
      role: user?.role || '',
      name: user?.name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      customAttributes: {
        clientAccountId: user?.customAttributes?.clientAccountId || null,
        clientUserId: user?.customAttributes?.clientUserId || null
      },
      notificationPreferences: {
        sms: user?.notificationPreferences.sms || false,
        email: user?.notificationPreferences.email || false,
        push: user?.notificationPreferences.push || false
      },
      permissions: user?.id ? (user.permissions || []) : []
    }
  });
  const { register, control, handleSubmit, formState: { errors }, watch, setValue } = methods;

  const onSubmit = handleSubmit((data:IFormData) => {
    const { customAttributes, role, permissions, ...otherData } = data;
    if ( role !== UserRoles.Client ){
      const nextData:IFormData = { ...otherData, role };
      if ( customAttributes && data.role === UserRoles.Presenter ){
        nextData['customAttributes'] = {
          clientAccountId: customAttributes.clientAccountId
        };
      }
      if ( role === UserRoles.Admin || role === UserRoles.Staff ) nextData['permissions'] = permissions;

      if ( currentAccount?.settings.language ) nextData['language'] = currentAccount?.settings.language;

      const oldHcpcsCode = user?.permissions ? user?.permissions.includes(Permissions.HcpcsCodes) : false;
      const newHcpcsCode = nextData.permissions ? nextData.permissions.includes(Permissions.HcpcsCodes) : false;
      const showConfirmationDialog = subscriptionItem?.price && oldHcpcsCode !== newHcpcsCode;

      if ( !user ){
        nextData['notificationPreferences'] = {
          sms: false,
          email: false,
          push: false
        };
        if ( showConfirmationDialog ){
          setFormData(nextData);
          setHcpcsCodesStatus(newHcpcsCode);
          toggleConfirmation();
        } else {
          asyncCreateUser(nextData);
        }
      } else {
        if ( showConfirmationDialog ){
          setFormData(nextData);
          setHcpcsCodesStatus(newHcpcsCode);
          toggleConfirmation();
        } else {
          asyncUpdateUser({ userId: user?.id, data: nextData });
        }
      }
    } else {
      if ( customAttributes ){
        asyncImportClientUser(Number(customAttributes.clientUserId));
      }
    }
  });

  const asyncUpdateUser = async (nextData:{ userId:number, data:IFormData }) => {
    try {
      await dispatch(updateUser(nextData)).unwrap();

      if ( nextData.userId === myUser?.id ){
        dispatch(UsersActions.updateMyUserPermissions(nextData.data.permissions || []));
      }

      onClose();
    } catch(e) {}
  }

  const asyncCreateUser = async (data:IFormData) => {
    try {
      await dispatch(createUser(data)).unwrap();
      onClose();
    } catch(e) {}
  }

  const asyncImportClientUser = async (userId:number) => {
    try {
      await dispatch(importClientUser({ userId })).unwrap();
      onClose();
    } catch(e) {}
  }

  const watchRole = watch('role');
  const watchClientAccountId = watch('customAttributes.clientAccountId');

  // Check user role
  useEffect(() => {
    if ( watchRole === UserRoles.Client || watchRole === UserRoles.Presenter ){
      if ( watchClientAccountId ){
        setValue('customAttributes.clientUserId', null);
        dispatch(getAccountUsers(Number(watchClientAccountId)));
      }
    } else {
      setValue('customAttributes.clientAccountId', null);
      setValue('customAttributes.clientUserId', null);
    }
    // eslint-disable-next-line
  }, [watchRole, watchClientAccountId]);

  const isControlDisabled:boolean = Boolean(isUserRoleClientOrPresenter);

  const isWatchRoleClient = watchRole === UserRoles.Client;
  const isWatchRolePresenter = watchRole === UserRoles.Presenter;

  const handleConfirm = () => {
    if ( !formData ) return;

    toggleConfirmation();

    if ( !user ){
      asyncCreateUser(formData);
    } else {
      asyncUpdateUser({ userId: user?.id, data: formData });
    }
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel user dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`${label} user dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{label}</LoadingButton>
    </Fragment>
  );

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        title={`${label} ${t('pages.adminPages.usersPage.user')}`}
        actions={actions}
      >
        <FormProvider {...methods}>
          <form noValidate>

            {/* Roles */}
            <Controller
              control={control} name="role"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('labels.role')}
                  error={Boolean(errors.role)}
                  helperText={errors.role?.message || ''}
                  required
                  options={roleOptions}
                  disabled={isControlDisabled}
                />
              )}
            />

            {!isWatchRoleClient ? (
              <Fragment>
                {/* Name */}
                <Controller
                  control={control} name="name"
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label={t('labels.name')}
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message || ''}
                      required
                    />
                  )}
                />

                {!user || !user.platform ? (
                  <Fragment>
                    {/* E-mail */}
                    <Controller
                      control={control} name="email"
                      rules={{ required: isRequired, pattern: isEmailValid }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('labels.email')} type="email"
                          error={Boolean(errors.email)}
                          helperText={errors.email?.message || ''}
                          required
                        />
                      )}
                    />
          
                    {/* Phone */}
                    <Controller
                      control={control} name="phone"
                      rules={{ required: isRequired }}
                      render={({ field }) => (
                        <Phone
                          {...field}
                          label={t('labels.cellPhone')}
                          error={Boolean(errors.phone)}
                          helperText={errors.phone?.message || ''}
                          required={true}
                        />
                      )}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <input {...register('email')} type="hidden" />
                    <input {...register('phone')} type="hidden" />
                  </Fragment>
                )}
              </Fragment>
            ) : null}

            {isWatchRoleClient || isWatchRolePresenter ? (
              <Controller
                control={control} name="customAttributes.clientAccountId"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    label={t('labels.clientAccount')}
                    options={accountsOptions || []}
                    required={true}
                    error={Boolean(errors.customAttributes?.clientAccountId)}
                    helperText={errors.customAttributes?.clientAccountId
                      ? errors.customAttributes?.clientAccountId.message
                      : ''
                    }
                    renderOptions={(props, option) => (
                      <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                        <Typography variant="subtitle1">{option.name}</Typography>
                        {option.subname ? (
                          <Typography variant="caption">{option.subname}</Typography>
                        ) : null}
                      </li>
                    )}
                  />
                )}
              />
            ) : null}

            {isWatchRoleClient ? (
              <Controller
                control={control} name="customAttributes.clientUserId"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    label={t('pages.adminPages.usersPage.clientUser')}
                    options={accountUsersOptions || []}
                    required={true}
                    error={Boolean(errors.customAttributes?.clientUserId)}
                    helperText={errors.customAttributes?.clientUserId
                      ? errors.customAttributes?.clientUserId.message
                      : ''
                    }
                  />
                )}
              />
            ) : null}

            <FormPreferencesTogglers user={user} />
            <FormPermissionTogglers />
          </form>
        </FormProvider>
      </Dialog>
      {openConfirmation ? (
        <ConfirmationDialog
          open={openConfirmation}
          onClose={toggleConfirmation}
          onConfirm={handleConfirm}
          title={t('pages.adminPages.usersPage.userHcpcsCodeLicense')}
          content={
            hcpcsCodesStatus
            ? t('pages.adminPages.usersPage.userHcpcsCodeLicenseContent1', { subscriptionItemPrice: subscriptionItem?.price || 0 })
            : t('pages.adminPages.usersPage.userHcpcsCodeLicenseContent2')
          }
        />
      ) : null}
    </Fragment>
  )
}

export default UserFormDialog;
