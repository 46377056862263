import i18n from "i18n";

const Messages = {
  Delete: i18n.t('notifications.caseAlerts.delete'),
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Delete = i18n.t('notifications.caseAlerts.delete');
});