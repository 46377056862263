import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IPresenterCase } from "app/models/Case";
// Async
import { getInsuranceCases, getSettings, exportPagesToPDF } from './Presenter.async';

interface IState {
  insuranceCases: IPresenterCase[] | null;
  filter: {
    search: string;
  };
  settings: {
    pageTags: string[];
  };
  loading: boolean;
};

const initialState:IState = {
  insuranceCases: null,
  filter: {
    search: ''
  },
  settings: {
    pageTags: []
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.Presenter,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get insurance cases
    builder.addCase(getInsuranceCases.pending, (state) => {
      state.insuranceCases = null;
    });
    builder.addCase(getInsuranceCases.fulfilled, (state, action:PayloadAction<IPresenterCase[]>) => {
      state.insuranceCases = action.payload;
    });
    // Get settings
    builder.addCase(getSettings.fulfilled, (state, action:PayloadAction<any>) => {
      state.settings = action.payload;
    });
    // Export pages to pdf
    builder.addCase(exportPagesToPDF.pending, (state) => {
      state.loading = true;
    });
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const PresenterActions = slice.actions;

export default slice.reducer;
