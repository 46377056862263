import { Fragment, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createInvoice, updateInvoice, getServiceItems } from 'app/store/Invoices/Invoices.async';
// Selectors
import { selectIntegrations } from 'app/store/Integrations/Integrations.selectors';
import { selectLoading, selectSortedServiceItems } from 'app/store/Invoices/Invoices.selectors';
// Mui
import {
  Divider, Grid, InputAdornment,
  Table, TableBody, TableCell, TableHead, TableRow,
  Tooltip
} from '@mui/material';
// Icons
import { Delete as DeleteIcon } from '@mui/icons-material';
// Components
import Dialog from 'app/components/Dialog';
import { DesktopDatePicker } from 'app/components/Mui';
import { Input, Select, IconButton, Button, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired, isGreaterZero, isDateValid } from 'app/utilities/Validations';
import { convertDateToString, parseDate } from 'app/utilities/DateTime';

type Props = {
  open: boolean;
  onClose: () => void;
  invoice?: any;
}

const InvoiceFormDialog:FC<Props> = ({
  // Props
  invoice, open, onClose
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const integrations = useAppSelector(selectIntegrations);
  const loading = useAppSelector(selectLoading);
  const serviceItems = useAppSelector(selectSortedServiceItems);

  const { register, control, handleSubmit, formState:{ errors }, setValue } = useForm<any>({
    defaultValues: {
      invoiceNumber: invoice?.invoiceNumber || '',
      description: invoice?.description || '',
      dueDate: invoice?.dueDate ? parseDate(invoice.dueDate) : null,
      customFields: {
        customerQuickBooksId: invoice?.customFields?.customerQuickBooksId || ''
      },
      lines: invoice?.lines || [
        { serviceItemId: '', name: '', description: '', quantity: 0, price: 0, amount: 0 }
      ]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lines'
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { invoiceNumber, description, dueDate, customFields, lines } = data;
      const nextData:any = { amount: 0, lines:[] };
      if ( invoiceNumber ) nextData['invoiceNumber'] = invoiceNumber;
      if ( description ) nextData['description'] = description;
      if ( dueDate ) nextData['dueDate'] = convertDateToString(dueDate);
      if ( customFields?.customerQuickBooksId ) nextData['customFields'] = { customerQuickBooksId: customFields.customerQuickBooksId };
      for ( let line of lines ){
        const { id, serviceItemId, ...otherLine } = line;
        const quantity = Number(line.quantity);
        const price = Number(line.price);
        const nextLine = {
          ...otherLine,
          serviceItemId: Number(serviceItemId),
          quantity,
          price,
          amount: Number((quantity * price).toFixed(2))
        }
        nextData['amount'] = nextData['amount'] + nextLine.amount;
        nextData['lines'] = [...nextData['lines'], nextLine];
      }
      if ( invoice && invoice.id ){
        await dispatch(updateInvoice({ id: invoice.id, data: nextData })).unwrap();
      } else {
        nextData['insuranceCaseId'] = Number(caseId);
        await dispatch(createInvoice(nextData)).unwrap();
      }
      onClose();
    } catch(error){
      console.log(error);
    }
  });

  useEffect(() => {
    dispatch(getServiceItems({}));
    // eslint-disable-next-line
  }, []);

  const handleAddLine = () => {
    append({ serviceItemId: '', name: '', description: '', quantity: 0, price: 0, amount: 0 });
  }

  const handleRemoveLine = (index:number) => () => {
    remove(index);
  }

  const handleChangeServiceItemId = (index:any, onChange:any) => (event:any) => {
    const { value } = event.target
    const foundedServiceItem = serviceItems && value ? serviceItems.find((s) => s.id === Number(value)) : null;
    if ( foundedServiceItem ){
      setValue(`lines.${index}.name`, foundedServiceItem.name || '');
      setValue(`lines.${index}.description`, foundedServiceItem.description || '');
      setValue(`lines.${index}.price`, foundedServiceItem.price || '');
    }
    onChange(event);
  }

  const getLineError = (field:any, index:any) => {
    if ( !errors || !errors.lines ) return '';
    const lineError = (errors as any).lines[index];
    return lineError?.[field]?.message || '';
  }

  const label = invoice ? t('labels.update') : t('labels.create');
  const integration = integrations && integrations.length !== 0 ? integrations[0] : null;
  const serviceItemsOptions = serviceItems ? serviceItems.map(s => ({ id: s.id, name: s.name })) : [];

  const actions = (
    <Fragment>
      <Button
        name="Cancel team dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`${label} team dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{label}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      title={`${label} ${t('labels.invoice')}`}
      actions={actions}
    >
      <form noValidate>
        <Controller
          control={control} name="description"
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.description')}
              size="small"
              multiline
              rows={5}
            />
          )}
        />

        <Grid container spacing={1}>
          <Grid item xs={12} sm={integration && integration.id ? 4 : 6 }>
            <Controller
              control={control} name="dueDate"
              rules={{ validate: isDateValid }}
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  label={t('labels.invoiceDueDate')}
                  inputProps={{
                    error: Boolean(errors.dueDate),
                    helperText: (errors as any).dueDate?.message || '',
                    size: 'small',
                    margin: 'dense',
                    InputLabelProps: { shrink: true }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={integration && integration.id ? 4 : 6 }>
            <Controller
              control={control} name="invoiceNumber"
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.invoiceNumber')}
                  size="small"
                  margin="dense"
                />
              )}
            />
          </Grid>
          {(integration && integration.id) ? (
            <Grid item xs={12} sm={4}>
              <Controller
                control={control} name="customFields.customerQuickBooksId"
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('dialogs.invoiceForm.customerQuickBooksId')}
                    size="small"
                    margin="dense"
                  />
                )}
              />
            </Grid>
          ) : null}
        </Grid>

        <Divider sx={{ my: 2 }} />

        {!serviceItems ? (
          <h1>{t('labels.loading')}</h1>
        ) : (
          <>
            <Table size="small" style={{ marginBottom: '8px' }}>
              <TableHead>
                <TableRow>
                  {fields.length > 1 ? (
                    <TableCell style={{ width: '64px' }}></TableCell>
                  ) : null}
                  <TableCell style={{ width: '256px' }}>{t('labels.service')}</TableCell>
                  <TableCell>{t('labels.name')}</TableCell>
                  <TableCell>{t('labels.description')}</TableCell>
                  <TableCell style={{ width: '128px' }}>{t('labels.qty')}</TableCell>
                  <TableCell style={{ width: '128px' }}>{t('labels.rate')}</TableCell>
                  {/* <TableCell>Total</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>

                {fields.map((field:any, index:number) => (
                  <TableRow key={`line item ${field.id}`} style={{ verticalAlign: 'top' }}>
                    {fields.length > 1 ? (
                      <TableCell scope="row">
                        <Tooltip title={t('dialogs.invoiceForm.deleteRow')}>
                          <IconButton name={`Delete line ${field.id}`} onClick={handleRemoveLine(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : null}
                    <TableCell style={{ maxWidth: '256px' }} component="th" scope="row">
                      <input {...register(`lines.${index}.id`)} type="hidden" />
                      <Controller
                        control={control} name={`lines.${index}.serviceItemId`} defaultValue={field.serviceItemId || ''}
                        rules={{ required: isRequired }}
                        render={({ field:{ onChange, ...otherField } }) => (
                          <Select
                            {...otherField}
                            label={t('labels.service')}
                            size="small"
                            onChange={handleChangeServiceItemId(index, onChange)}
                            options={
                              [
                                { id: '', name: t('dialogs.invoiceForm.chooseService') },
                                ...serviceItemsOptions
                              ]
                            }
                            required
                            error={Boolean(getLineError('serviceItemId', index))}
                            helperText={getLineError('serviceItemId', index) || ''}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Controller
                        control={control} name={`lines.${index}.name`} defaultValue={field.name || ''}
                        rules={{ required: isRequired }}
                        render={({ field:f }) => (
                          <Input
                            {...f}
                            label={t('labels.name')}
                            required
                            error={Boolean(getLineError('name', index))}
                            helperText={getLineError('name', index) || ''}
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Controller
                        control={control} name={`lines.${index}.description`} defaultValue={field.description || ''}
                        render={({ field:f }) => (
                          <Input
                            {...f}
                            label={t('labels.description')}
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Controller
                        control={control} name={`lines.${index}.quantity`} defaultValue={field.quantity || ''}
                        rules={{ pattern: isGreaterZero }}
                        render={({ field:f }) => (
                          <Input
                            {...f}
                            label={t('labels.qty')}
                            size="small"
                            error={Boolean(getLineError('quantity', index))}
                            helperText={getLineError('quantity', index) || ''}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Controller
                        control={control} name={`lines.${index}.price`} defaultValue={field.price || ''}
                        render={({ field:f }) => (
                          <Input
                            {...f}
                            label={t('labels.rate')} type="number"
                            size="small"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>

            <Button
              name="Add row"
              onClick={handleAddLine}
              color="primary"
              variant="outlined"
              size="small"
            >{t('dialogs.invoiceForm.addRow')}</Button>
          </>
        )}
      </form>
    </Dialog>
  )
}

export default InvoiceFormDialog;
