import { ChangeEvent, FC, useState, useCallback, useEffect } from 'react';
import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPrompts } from 'app/store/AIChatPrompts/AIChatPrompts.async';
// Actions
import { AIChatPromptsActions } from 'app/store/AIChatPrompts/AIChatPrompts.slice';
// Selectors
import { selectFilteredPrompts, selectFilter } from 'app/store/AIChatPrompts/AIChatPrompts.selectors';
// Mui
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import Labels from 'app/components/Labels';
import DataLoading from 'app/components/DataLoading';
import { Input } from 'app/components/Mui';
// ToDO: Should be moved from components
import MixpanelTracks from "app/types/MixpanelTracks";
import Mixpanel from "app/services/Mixpanel.service";
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectConversation } from 'app/store/AIChat/AIChat.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
import { ICurrentAccount } from 'app/models/Account';
import { IMyUser } from 'app/models/User';
import IInsuranceCase from 'app/models/Case';
import { IConversation } from 'app/models/ChatAI';

type Props = {
  open: boolean;
  onClose: (promptValue?:string) => void;
}

const AiChatPromptDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount = useAppSelector(selectCurrentAccount) as ICurrentAccount;
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const conversation = useAppSelector(selectConversation) as IConversation;

  const prompts = useAppSelector(selectFilteredPrompts);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  // eslint-disable-next-line
  const debounceFilter = useCallback(debounce(500, (field:keyof typeof filter, value:any) => {
    dispatch(AIChatPromptsActions.setFilter({ field, value }))
  }), []);

  useEffect(() => {
    dispatch(getPrompts({ teamId: insuranceCase.team.id }));

    return () => {
      dispatch(AIChatPromptsActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = () => onClose();

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    debounceFilter(name as keyof typeof filter, value);
  }

  const handleClick = (aiChatPrompt:IPrompt) => () => {
    onClose(aiChatPrompt.content);

    Mixpanel.track(MixpanelTracks.AIChatConversationPromptUsed, {
      accountId: currentAccount.id,
      userId: myUser.id,
      caseId: insuranceCase.id,
      conversationId: conversation.id,
      promptId: aiChatPrompt.id,
    });
  }

  const truncateContent = (content:string) => {
    const maxChars = 100;
    const maxRows = 2;

    let lines = content.split('\n');
    let result = [];
    let currentTextLength = 0;

    for ( let i = 0; i < lines.length; i++ ){
      let line = lines[i];
      if ( currentTextLength + line.length > maxChars ){
        let remainingChars = maxChars - currentTextLength;
        result.push(line.slice(0, remainingChars) + ' ...');
        break;
      } else {
        result.push(line);
        currentTextLength += line.length;
      }
      if ( result.length === maxRows ){
        result[maxRows - 1] = result[maxRows - 1] + ' ...';
        break;
      }
    }

    return result.join('\n');
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title="Select prompt"
    >
      <Box sx={{ display: 'flex', mb: 4 }}>
        <Input
          label={t('labels.search')} id="search" name="search"
          placeholder="Preset name"
          value={stateFilter.search}
          onChange={handleChange}
          size="small"
          margin="none"
        />
      </Box>
      <DataLoading
        data={prompts}
        text={t("pages.adminPages.aiChatPromptsPage.noDataText")}
      >{(prompts) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {prompts.map((aiChatPrompt) => (
            <Card key={`prompt item ${aiChatPrompt.id}`}>
              <CardActionArea onClick={handleClick(aiChatPrompt)}>
                <CardContent>
                  <Typography variant="subtitle1">{aiChatPrompt.title}</Typography>
                  <Typography sx={{ whiteSpace: 'pre-line' }} color="GrayText" variant="body2">{truncateContent(aiChatPrompt.content)}</Typography>
                  {aiChatPrompt.labels ? (
                    <Box sx={{ pt: 2 }}>
                      <Labels
                        id={`prompt item ${aiChatPrompt.id} labels`}
                        labels={aiChatPrompt.labels}
                      />
                    </Box>
                  ) : null}
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      )}</DataLoading>
    </Dialog>
  )
}

export default AiChatPromptDialog;
