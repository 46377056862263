import { FC, Fragment } from 'react';
// Models
import { RootState } from 'app/store';
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectStaplePageIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Components
import PagePdf from 'app/components/PagePdf';

type Props = {
  page: IPage | IPresenterPage;
};

const TabContentPdf:FC<Props> = ({
  // Props
  page
}) => {
  const staplePageIds:string[] | null = useAppSelector((state:RootState) => selectStaplePageIds(state, {
    documentId: page.documentId,
    stapleId: page.staple?.id || null
  }));

  return (
    <Fragment>
      {!staplePageIds || !staplePageIds.length ? (
        <PagePdf
          documentId={page.documentId}
          pageNum={page.pageNum}
        />
      ) : (
        staplePageIds.map((pageId:string, index:number) => {
          const [ documentId, pageNum ] = pageId.split(':').map(Number);
          return (
            <PagePdf
              key={`page-pdf-item-staple-${index}`}
              documentId={documentId}
              pageNum={pageNum}
            />
          )
        })
      )}
    </Fragment>
  )
}

export default TabContentPdf;
