import { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Mui
import { Box } from '@mui/material';
// Layouts
import FilesSidebar from 'app/layouts/DocumentsSidebar';
// Components
import { Message } from 'app/components/Utilities';
// 
// import DocumentPagesPage from 'app/pages/Staff/CaseDetailsPage/DocumentPagesPage';
import DocumentDetailsPage from 'app/pages/Staff/CaseDetailsPage/DocumentDetailsPage';
import DocumentPageDetailsPage from 'app/pages/Staff/CaseDetailsPage/DocumentPageDetailsPage';

const DocumentsRouting:FC = () => {
  const { t } = useTranslation('common');

  const { path } = useRouteMatch();

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      overflow: 'hidden'
    }}>
      <FilesSidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Switch>
          <Route exact path={path} component={() => (
            <Message text={t('pages.adminPages.caseDetailsPage.noRecordSelected')} />
          )}/>
          <Route exact path={`${path}/:documentId`} component={DocumentDetailsPage} />
          <Route path={`${path}/:documentId/:pageNum`} component={DocumentPageDetailsPage} />
        </Switch>
      </Box>
    </Box>
  );
}

export default DocumentsRouting;
