import { FC, Fragment } from 'react';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import { Theme, Box, Typography, Skeleton, Divider } from '@mui/material';
// Components
import { Bull } from 'app/components/Utilities';
import useFormattedDate from 'app/hooks/useFormattedDate';
import useFormattedTime from 'app/hooks/useFormattedTime';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  direction?: 'row' | 'column';
  statusVisible?: boolean;
}

const CaseInfo:FC<Props> = ({
  // Props
  direction = 'column', statusVisible = true
}) => {
  const { t } = useTranslation('common');
  // State
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);

  const formattedEventDate = useFormattedDate(insuranceCase?.eventDate);
  const formattedEventTime = useFormattedTime(insuranceCase?.eventTime);
  const formattedDateOfBirth = useFormattedDate(insuranceCase?.claimant?.dateOfBirth);

  const status =
    insuranceCase?.processing?.status === 'pending'
    ? 'processing'
    : insuranceCase?.processing?.status === 'processing'
      ? insuranceCase?.processing?.phase === 'ocr'
        ? 'processing'
        : 'finalizing'
      : insuranceCase?.processing?.status
  ;

  const statusLabels:Record<string, string> = {
    failed: t('components.caseInfo.failed'),
    processing: t('components.caseInfo.processing'),
    ready: t('components.caseInfo.ready'),
    finalizing: t('components.caseInfo.finalizing')
  }

  const eventDateElem = formattedEventDate ? (
    <Typography variant="subtitle2">
      {t('components.caseInfo.event')}:&nbsp;
      <Box
        component="span"
        sx={{
          color: (theme:Theme) => theme.palette.text.secondary
        }}
      >{formattedEventDate}{formattedEventTime ? ` ${formattedEventTime}` : null}</Box>
    </Typography>
  ) : null;
  const dateOfBirthElem = formattedDateOfBirth ? (
    <Typography variant="subtitle2">
      {t('labels.dob')}:&nbsp;
      <Box
        component="span"
        sx={{
          color: (theme:Theme) => theme.palette.text.secondary
        }}
      >{formattedDateOfBirth}</Box>
    </Typography>
  ) : null;

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: direction, overflow: 'hidden' }}>
      {insuranceCase ? (
        <Fragment>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden'
            }}
            title={insuranceCase.name}
          >
            <Typography
              sx={{
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >{insuranceCase.name}</Typography>
            {statusVisible && status ? (
              <Typography
                sx={{
                  ml: 2,
                  color: (theme:Theme) => theme.palette.primary.main,
                  textTransform: 'capitalize'
                }}
                variant="subtitle2"
              >{statusLabels[status]}</Typography>
            ) : null}
          </Box>
          {direction === 'row' ? (
            <Divider sx={{ height: 24, mx: 3 }} orientation="vertical" />
          ) : null}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {eventDateElem}
            {eventDateElem && dateOfBirthElem ? <Bull /> : null}
            {dateOfBirthElem}
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Typography display="block"><Skeleton width="60%" /></Typography>
          <Typography display="block"><Skeleton /></Typography>
        </Fragment>
      )}
    </Box>
  )
}

export default CaseInfo;
