import { createTheme } from '@mui/material';

const primaryMain = 'rgba(100,100,230,1)';
const primaryLight = 'rgba(100,100,230,0.15)';
const secondaryMain = 'rgba(77,77,77,1)';
const divider = 'rgba(0,0,0,0.08)';

export default createTheme({
  palette: {
    primary: {
      light: primaryLight,
      main: primaryMain
    },
    secondary: {
      main: secondaryMain
    },
    divider
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#f9f9f9'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true
      },
      styleOverrides: {
        root: {
          paddingRight: 16,
          paddingLeft: 16
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      // styleOverrides: {
      //   root: ({ ownerState }) => ({
      //     ...(ownerState.variant === 'text' && {
      //       color: 'rgba(0,0,0,0.6)',
      //       '&:hover': {
      //         backgroundColor: primaryLight,
      //         color: primaryMain
      //       }
      //     })
      //   })
      // }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          width: 32,
          height: 32,
          padding: 2
        },
        root: ({ ownerState }) => ({
          borderRadius: 4,
          color: 'rgba(0,0,0,0.6)',
          ...((ownerState.color === 'primary') && {
            backgroundColor: primaryLight,
            color: primaryMain
          }),
          ...((ownerState.color === 'default' || ownerState.color === 'primary') && {
            '&:hover': {
              backgroundColor: primaryLight,
              color: primaryMain
            }
          })
        })
      }
    },
    MuiToolbar: {
      defaultProps: {
        disableGutters: true
      },
      styleOverrides: {
        root: {
          height: 48,
          backgroundColor: 'white',
          borderBottom: `1px solid ${divider}`,
          paddingRight: 16,
          paddingLeft: 16
        },
      }
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          borderColor: divider
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: divider
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderTopColor: divider,
          borderBottomColor: divider
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: primaryLight,
            color: primaryMain
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderColor: primaryMain,
          color: primaryMain,
          '&.Mui-selected': {
            backgroundColor: primaryMain,
            color: 'white'
          },
          '&:hover': {
            backgroundColor: primaryLight,
            color: primaryMain
          }
        }
      }
    },
    // MuiSkeleton: {
    //   styleOverrides: {
    //     root: ({ ownerState }) => ({
    //       '&::after': {
    //         background: `linear-gradient(90deg, ${alpha(ownerState.color || primaryMain, 0.1)}, ${alpha(ownerState.color || primaryMain, 0.5)})`, // Customize the animation
    //       },
    //     })
    //   }
    // }
  },
  spacing: (factor:number) => `${factor * 0.25}rem`
});