import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Models
import IAnnotation from 'app/models/Annotation';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Selectors
import { selectDocument } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Paper, Toolbar } from '@mui/material';
// Compoentns
import DocumentName from 'app/components/DocumentName';
import { InfoBlock, Loader } from 'app/components/Utilities';

const DocumentCustomPage:FC = () => {
  const { documentId } = useParams<{ documentId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const document = useAppSelector(selectDocument);

  useEffect(() => {
    if ( !documentId ) return;

    dispatch(getDocument(Number(documentId)));
    // eslint-disable-next-line
  }, [documentId]);

  if ( !document ) return <Loader />
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Toolbar sx={{ py: 2, height: 'initial' }}>
        <Box sx={{ flexGrow: 1 }}>
          <DocumentName />
          {document.annotations && document.annotations.length ?
            document.annotations.map((annotation:IAnnotation, index) => (
              <InfoBlock
                key={`annotation item ${index}`}
                label={annotation.name}
                value={annotation.value || 'N/A'}
                direction="row"
              />
            ))
          : null}
        </Box>
      </Toolbar>
      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto',
        py: 4,
        px: 6,
      }}>
        <Paper
          sx={{
            py: 4,
            px: 6,
            'whiteSpace': 'break-spaces'
          }}
          className="ql-editor preview"
          dangerouslySetInnerHTML={{ __html: document.content || '' }}
        />
      </Box>
    </Box>
  )
}

export default DocumentCustomPage;