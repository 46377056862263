import config from '../../config';

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// Servces
import LocalStorageService from 'app/services/LocalStorage.service';

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiUrl,
  prepareHeaders: (headers:Headers) => {
    if ( !LocalStorageService.isAccessTokenExpired() ){
      const accessToken = LocalStorageService.getAccessToken();
      const accessTokenType = LocalStorageService.getAccessTokenType();
      headers.set('Authorization', `${accessTokenType} ${accessToken}`);
    }
    return headers;
  }
});

export default baseQuery;
