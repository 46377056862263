import { FC, Fragment } from 'react'
// Models
import IReport from 'app/models/Report';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSortedReports } from 'app/store/CaseReports/CaseReports.selectors';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import ReportsListItem from './ReportsListItem';
// i18next
import { useTranslation } from 'react-i18next';

const ReportsList:FC = () => {
  const { t } = useTranslation('common');

  const reports:IReport[] | null = useAppSelector(selectSortedReports)

  if ( !reports ) return <Loader />;
  if ( !reports.length ) return <Message text={t('pages.staffPages.caseDetailsPage.noReports')} />;
  return (
    <Fragment>
      {reports.map((report:IReport) => (
        <ReportsListItem
          key={`case-report-item-${report.id}`}
          report={report}
        />
      ))}
    </Fragment>
  )
}

export default ReportsList;
