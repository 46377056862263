import { memo, FC, useEffect, Fragment } from 'react';
// Models
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPage } from 'app/store/DMSDocumentPages/DMSDocumentPages.async';
// Actions
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
// Selectors
import { selectGridResizerWidth } from 'app/store/AppUI/AppUI.selectors';
import { selectPage } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
import Dialog from 'app/components/Dialog';
import GridResizer from 'app/components/GridResizer';
import CardPageTabs from 'app/components/CardPageTabs';
import ColorPicker from 'app/components/ColorPicker';
import PageEpisodes from 'app/components/PageEpisodes';
// 
import DialogTitle from './DialogTitle';

type Props = {
  open: boolean;
  onClose: () => void;
  documentId: number;
  pageNum: number;
}

const CardPageDialog:FC<Props> = ({
  // Props
  open, onClose, documentId, pageNum
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const gridResizerWidth:number = useAppSelector(selectGridResizerWidth);
  const page:IPage | IPresenterPage | null = useAppSelector(selectPage);

  useEffect(() => {
    dispatch(getPage({ documentId, pageNum }));
    return () => {
      dispatch(DMSDocumentPagesActions.setInitialField('page'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      title={!page ? <Loader /> : <DialogTitle page={page} />}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', gap: 4, height: '100%', position: 'relative' }}>
        <Box sx={{ width: `${gridResizerWidth}%`, display: 'flex', overflow: 'hidden' }}>
          <CardPageTabs />
        </Box>
        <GridResizer />
        <Box sx={{ width: `${100 - gridResizerWidth}%`, overflowY: 'auto' }}>
          {!page ? <Loader /> : (
            <Fragment>
              {page.colors && page.colors.length ? (
                <Box sx={{ mb: 4 }}>
                  <ColorPicker activeColors={page?.colors || []} />
                </Box>
              ) : null}
              <PageEpisodes />
            </Fragment>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}

export default memo(CardPageDialog);
