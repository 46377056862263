// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from "app/store/AppUINotifications/AppUINotifications.slice";
// Messages
import Messages from './DMSDocumentsExports.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post } from 'app/utilities/HttpClient';

const _url:string = '/dms/documentsExports';

// Get records export
export const getDocumentsExports = asyncThunkHandler(
  `${Reducers.DMSDocumentsExports}/Get documents exports`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Create records export
export const createDocumentExport = asyncThunkHandler(
  `${Reducers.DMSDocumentsExports}/Create document export`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Resend download link
export const resendDownloadLink = asyncThunkHandler(
  `${Reducers.DMSDocumentsExports}/Resend download link`,
  async (documentExportId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${documentExportId}/actions/resendDownloadLink`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Send
    }));
    return response.json();
  }
);
