// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./BillingInvoices.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put } from 'app/utilities/HttpClient';
import { downloadFile } from "app/utilities/Utilities";

const _url:string = '/billing/invoices';

// Get invoices
export const getInvoices = asyncThunkHandler(
  `${Reducers.BillingInvoices}/Get invoices`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);
// Get invoices summary - AM only
export const getInvoicesSummary = asyncThunkHandler(
  `${Reducers.BillingInvoices}/Get invoices summary`,
  async (params:any) => {
    const response:Response = await $get(`${_url}/summary`, params);
    return response.json();
  }
);
// Update invoice
export const updateInvoice = asyncThunkHandler(
  `${Reducers.BillingInvoices}/Update invoice`,
  async ({ invoiceId, data }:{ invoiceId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${invoiceId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);
// Pay invoice
export const payInvoice = asyncThunkHandler(
  `${Reducers.BillingInvoices}/Pay invoice`,
  async ({ invoiceId, data }:{ invoiceId:number, data:any }, { dispatch }) => {
    const response:Response = await $post(`${_url}/${invoiceId}/actions/pay`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Pay
    }));
    return response.json();
  }
);
// Export invoices
export const exportInvoicesToPDF = asyncThunkHandler(
  `${Reducers.BillingInvoices}/Export invoices to pdf`,
  async (ids:number[], { fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/exports/pdf?ids=${ids.join(',')}`);

    downloadFile(response, { name: `invoice`, ext: 'pdf' });

    return fulfillWithValue('Invoices was exported');
  }
);
