import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptions } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import Autocomplete from 'app/components/Mui/AutocompleteV2';

const DocumentsSettings:FC = () => {
  const { t } = useTranslation('common');
  // State
  const collectionOptions = useAppSelector(selectCollectionsAsOptions);

  const { control } = useFormContext();

  return (
    <Controller
      control={control} name="document.collectionIds" defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={collectionOptions || []}
          onChange={(nextValue:any) => {
            // TODO: Need to check, why CustomAutocomplete can`t handle correct with `undefined` value
            field.onChange(nextValue && nextValue.length ? nextValue : []);
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
              <Typography variant="subtitle1">{option.name}</Typography>
              {option.props.description ? (
                <Typography variant="caption">{option.props.description}</Typography>
              ) : null}
            </li>
          )}
          InputProps={{
            label: t('dialogs.templateForm.documentCollections'), 
            name: field.name,
          }}
          multiple={true}
          filterSelectedOptions
        />
      )}
    />
  )
}

export default DocumentsSettings;
