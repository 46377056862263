import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { debounce } from 'throttle-debounce';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getAuthor, getAuthors } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Actions
import { EpisodeAuthorsActions } from 'app/store/EpisodeAuthors/EpisodeAuthors.slice';
// Selectors
import { selectAuthor, selectAuthorsAsOptions } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// Mui
import { Box, Autocomplete, AutocompleteChangeReason } from '@mui/material';
// Icons
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
// Components
import { Input } from 'app/components/Mui';
// Utilities
import { trimValue } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  isDisabled: boolean;
}

const EpisodeFormAuthorType:FC<Props> = ({
  // Props
  isDisabled
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const author = useAppSelector(selectAuthor);
  const authorsOptions = useAppSelector(selectAuthorsAsOptions);

  const { control, setValue, watch } = useFormContext();

  const [ typesOptions, setTypesOptions ] = useState<string[]>([]);

  // eslint-disable-next-line
  const debounceGetAuthor = useCallback(debounce(500, (newValue:string) => {
    dispatch(getAuthors({ namePrefix: newValue }));
  }), []);

  const authorId = watch('authorId');

  useEffect(() => {
    return () => {
      dispatch(EpisodeAuthorsActions.setInitialField('author'));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if ( !authorId || authorId === -1 ) return;

    dispatch(getAuthor(authorId));

    // eslint-disable-next-line
  }, [authorId]);

  useEffect(() => {
    if ( !author ) return;

    setTypesOptions(author.episodeTypes || []);
    setValue('author', { id: author.id, name: author.name });
    // eslint-disable-next-line
  }, [author]);

  const handleChange = (_:any, newValue:any, reason:AutocompleteChangeReason) => {
    if ( newValue && typeof newValue !== 'string' ){
      const { id, name, props } = newValue;
      setValue('author', { id, name });
      setValue('authorId', id);
      if ( props.episodeTypes && props.episodeTypes.length ){
        setValue('type', props.episodeTypes[0]);
        setTypesOptions(props.episodeTypes);
      }
    } else {
      setTypesOptions([]);
      setValue('author', null);
    }
    if ( reason === 'clear' && !newValue ){
      setValue('authorId', null);
    }
  }

  const handleInputChange = (_:any, newValue:string, reason:any) => {
    if ( reason !== 'input' ) return;

    const trimedValue = trimValue(newValue);

    if ( !trimedValue ) return;

    setValue('author', { id: -1, name: newValue });
    setValue('authorId', -1 );
    setTypesOptions([]);

    debounceGetAuthor(newValue);
  }

  const handleChangeType = (_:any, newValue:any) => {
    setValue('type', newValue);
  }

  const handleInputChangeType = (_:any, newValue:string, reason:any) => {
    if ( reason !== 'input' ) return;

    const trimedValue = trimValue(newValue);

    if ( !trimedValue ) return;

    setValue('type', newValue);
  }

  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
      <Controller
        control={control} name="author" defaultValue={null}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disabled={isDisabled}
            options={authorsOptions || []}
            onChange={handleChange}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <Input
                {...params}
                label={t('labels.author')} name="author"
                size="small"
                margin="none"
              />
            )}
            renderOption={(props, option:IOption) => (
              <li {...props} key={option.id}>
                {option.props.verified ? (
                  <CheckCircleIcon sx={{ mr: 2 }} color="success" />
                ) : null}
                {option.name}
              </li>
            )}
            freeSolo
            fullWidth
            getOptionLabel={(option:IOption | string) => {
              if ( typeof option !== 'string' ) return option.name || '';
              return option;
            }}
          />
        )}
      />
      <Controller
        control={control} name="type" defaultValue={null}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disabled={isDisabled}
            options={typesOptions}
            onChange={handleChangeType}
            onInputChange={handleInputChangeType}
            renderInput={(params) => (
              <Input
                {...params}
                label={t('labels.type')}
                size="small"
                margin="none"
              />
            )}
            freeSolo
            fullWidth
          />
        )}
      />
    </Box>
  );
}

export default EpisodeFormAuthorType;