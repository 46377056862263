import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IEpisodeFact from 'app/models/EpisodeFact';
import ISchedule from 'app/models/Schedule';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getVaRates } from 'app/store/Medical/Medical.async';
// Actions
import { EpisodeFactsActions } from 'app/store/EpisodeFacts/EpisodeFacts.slice';
// Selectors
import { selectSelectedEpisodeId } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Box, Tooltip, Typography } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
// Components
import { InfoBlock } from 'app/components/Utilities';
import { Button } from 'app/components/Mui';
// Context
import { useEpisodeFactsContext } from 'app/context/EpisodeFacts.context';

type Props = {
  schedule: ISchedule;
}

const ScheduleItem:FC<Props> = ({
  // Props
  schedule
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const selectedEpisodeId = useAppSelector(selectSelectedEpisodeId) as number;

  // Context
  const { values } = useEpisodeFactsContext()

  const handleClick = async () => {
    try {
      const rates = await dispatch(getVaRates({ hcpcsCode: schedule.hcpcsCode.code })).unwrap();

      const fact:Omit<IEpisodeFact, 'id'> = {
        episodeId: selectedEpisodeId,
        type: 'charge',
        data: {
          hcpcsCode: schedule.hcpcsCode.code,
          shortDescription: schedule.hcpcsCode.shortDescription,
          modifier: schedule.modifier,
          price: 0,
          medicareRate,
          vaRate: rates && rates.length ? rates[0].facilityAmount : 0,
          year: values.year,
          carrierNumber: values.carrierNumber
        }
      };

      dispatch(EpisodeFactsActions.createFact(fact));
      // setCreation(true);
    } catch(error){}
  }

  const facilityAmount = schedule.facilityAmount || 0;
  const facilityLimitingChargeAmount = schedule.facilityLimitingChargeAmount || 0;
  const nonFacilityAmount = schedule.nonFacilityAmount || 0;
  const nonFacilityLimitingChargeAmount = schedule.nonFacilityLimitingChargeAmount || 0;
  const medicareRate = Math.max(facilityAmount, facilityLimitingChargeAmount, nonFacilityAmount, nonFacilityLimitingChargeAmount);

  return (
    <Box sx={{ display: 'flex', '& + &': { mt: 4 } }}>
      <Box sx={{ flexGrow: 1, pr: 2 }}>
        <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
          {schedule.hcpcsCode.shortDescription}
          {schedule.hcpcsCode.mediumDescription ? (
            <Tooltip title={schedule.hcpcsCode.mediumDescription}>
              <InfoIcon color="primary" fontSize="small" sx={{ ml: 2 }} />
            </Tooltip>
          ) : null}
        </Typography>
        <InfoBlock label={t('dialogs.episodeFacts.specificLocality')} value={schedule.carrierNumber} direction="row" />
        <InfoBlock label={t('dialogs.episodeFacts.code')} value={schedule.hcpcsCode.code} direction="row" />
        <InfoBlock label={t('dialogs.episodeFacts.medicareRate')} value={`$${medicareRate}`} direction="row" />
        {schedule.modifier ? (
          <InfoBlock label={t('dialogs.episodeFacts.modifier')} value={schedule.modifier} direction="row" />
        ) : null}
      </Box>
      <Box sx={{ pl: 2 }}>
        <Button
          name={`Add fact from schedule ${schedule.hcpcsCode}`}
          onClick={handleClick}
          variant="outlined"
          size="small"
        >{t('labels.add')}</Button>
      </Box>
    </Box>
  )
}

export default ScheduleItem;
