import { Fragment } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// Mui
import { Box } from '@mui/material';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
import useToggle from 'app/hooks/useToggle';
// 
import ReportSectionFormDialog from './ReportSectionFormDialog';
import ReportSectionImportDialog from './ReportSectionImportDialog';

const ReportSectionsFab = () => {
  const { t } = useTranslation('common');

  const { isArchived } = useCaseStatus();

  const { open, toggle } = useToggle();
  const { open:openReportSectionImportDialog, toggle:toggleReportSectionImportDialog } = useToggle();

  return (
    <Fragment>
      <Box
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16,
          display: 'flex',
          gap: 4
        }}
      >
        <Fab
          aria-label="Create report section"
          name="Create report section"
          disabled={isArchived}
          onClick={toggle}
          variant="extended"
          color="primary"
        >{t('pages.adminPages.caseDetailsPage.createReportSection')}</Fab>
        <Fab
          aria-label="Import report section"
          name="Import report section"
          disabled={isArchived}
          onClick={toggleReportSectionImportDialog}
          variant="extended"
          color="primary"
        >{t('labels.import')}</Fab>
      </Box>
      {/* Dialogs */}
      {open ? (
        <ReportSectionFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
      {openReportSectionImportDialog ? (
        <ReportSectionImportDialog
          open={openReportSectionImportDialog}
          onClose={toggleReportSectionImportDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default ReportSectionsFab;
