import { ChangeEvent, FC, useState, useEffect } from 'react';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { EpisodeAuthorsActions } from 'app/store/EpisodeAuthors/EpisodeAuthors.slice';
// Selectors
import { selectAuthors, selectFilter } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Input, Select } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// i18next
import { useTranslation } from 'react-i18next';

const AuthorRegistryFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const authors = useAppSelector(selectAuthors);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const debounceSearch = useDebounce(stateFilter.search);
  const debounceVerified = useDebounce(stateFilter.verified);
  const debounceOrganization = useDebounce(stateFilter.organization);

  useEffect(() => {
    const nextFilters:any = { offset: 0 };
    if ( debounceSearch !== filter.search ){
      nextFilters['search'] = debounceSearch;
    }
    if ( debounceVerified !== filter.verified ){
      nextFilters['verified'] = debounceVerified;
    }
    if ( debounceOrganization !== filter.organization ){
      nextFilters['organization'] = debounceOrganization;
    }
    dispatch(EpisodeAuthorsActions.setFilter(nextFilters));
    // eslint-disable-next-line
  }, [debounceSearch, debounceVerified, debounceOrganization]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleClear = () => {
    setStateFilter((prevState) => ({...prevState, search: ''}));
  }

  const verifiedOptions = [
    { id: 'all', name: t('labels.all') },
    { id: 'true', name: t('labels.verified') },
    { id: 'false', name: t('labels.unverified') }
  ];

  const organizationOptions = [
    { id: 'all', name: t('labels.all') },
    { id: 'true', name: t('labels.yes') },
    { id: 'false', name: t('labels.no') }
  ];

  if ( !authors || !authors.length ) return null;
  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
      <Input
        label={t('labels.search')} name="search"
        value={stateFilter.search}
        onChange={handleChange}
        onClear={handleClear}
        size="small"
        margin="none"
      />
      <Select
        sx={{ maxWidth: 128 }}
        label={t('labels.verified')} name="verified"
        value={stateFilter.verified}
        options={verifiedOptions}
        onChange={handleChange}
        size="small"
        margin="none"
      />
      <Select
        sx={{ maxWidth: 128 }}
        label={t('labels.organization')} name="organization"
        value={stateFilter.organization}
        options={organizationOptions}
        onChange={handleChange}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default AuthorRegistryFilter;
