import { FC, useEffect } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectStats, selectMonthlyStatsTotal } from 'app/store/Analytics/Analytics.selectors';
// Utilities
import { Loader, Message, InfoBlock } from 'app/components/Utilities';
// Mui
import { Box, Grid, Typography } from '@mui/material';
// Utilities
import { toNumberWithCommas, toSize } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

type Props = {
  title?: string;
  title2?: string;
  onGetStats: () => void;
}

const AnalyticsStats:FC<Props> = ({
  // Props
  title = i18n.t('components.analytics.accountSummary'), title2 = i18n.t('components.analytics.selectedRangeSummary'), onGetStats
}) => {
  const { t } = useTranslation('common');
  // State
  const stats:any = useAppSelector(selectStats);
  const monthlyStatsTotal:any = useAppSelector(selectMonthlyStatsTotal);

  useEffect(() => {
    onGetStats();
    // eslint-disable-next-line
  }, []);

  if ( !stats ) return <Loader />;
  if ( Object.keys(stats).length === 0 ) return <Message text={`${t('labels.no')} ${title}`} />;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle2" marginBottom={2}>{title}</Typography>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 4,
          mb: 4
        }}>
          {stats.numberOfAccounts ? (
            <InfoBlock label={t('components.analytics.numberOfAccounts')} value={stats.numberOfAccounts} />
          ) : null}
          <InfoBlock label={t('components.analytics.numberOfCases')} value={toNumberWithCommas(stats.numberOfInsuranceCases, false)} />
          <InfoBlock label={t('components.analytics.numberOfDocumentPages')} value={toNumberWithCommas(stats.numberOfDocumentPages, false)} />
          <InfoBlock label={t('components.analytics.storageSize')} value={toSize(stats.storageSize)} />
        </Box>
      </Grid>
      {monthlyStatsTotal ? (
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2" marginBottom={2}>{title2}</Typography>
          <Box display="flex" gap={4} marginBottom={4}>
            <InfoBlock label={t('components.analytics.numberOfCases')} value={toNumberWithCommas(monthlyStatsTotal.numberOfInsuranceCases, false)} />
            <InfoBlock label={t('components.analytics.numberOfDocumentPages')} value={toNumberWithCommas(monthlyStatsTotal.numberOfDocumentPages, false)} />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default AnalyticsStats;
