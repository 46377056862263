import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
import IPage from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Chip } from '@mui/material';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import CardPageTagsDialog from 'app/dialogs/CardPageTagsDialog';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  page: IPage;
}

const CardPageHeadTag:FC<Props> = ({
  // Props
  page
}) => {
  const { t } = useTranslation('common');
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const trialMode:boolean = useAppSelector(selectTrialMode);

  const { open, toggle } = useToggle();

  const handleOpenDialog = () => {
    if ( trialMode ) return;
    toggle();
  }

  const isMyRoleClientOrPresenter = myUser?.role === UserRoles.Client || myUser?.role === UserRoles.Presenter;

  const isDisabled = trialMode || myUser?.role === UserRoles.Presenter;

  if ( page.staple && page.staple.order > 1 ) return null;
  if ( !page.tag && isMyRoleClientOrPresenter ) return null;
  return (
    <Fragment>
      <div>
        <Chip
          sx={{
            color: !page.tag ? 'rgba(0,0,0,0.54)' : 'initial',
            fontWeight: 500
          }}
          label={page.tag || t('components.cardPage.setTag')}
          size="small"
          onClick={handleOpenDialog}
          disabled={isDisabled}
        />
      </div>
      {open ? (
        <CardPageTagsDialog
          open={open}
          onClose={toggle}
          page={page}
        />
      ) : null}
    </Fragment>
  )
}

export default CardPageHeadTag;