import React from 'react';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectPage, selectPageStaples } from 'app/store/page/page.selectors';
import { selectEpisodeEntity } from 'app/store/Episodes/Episodes.selectors';
// 
import CardPageTabsImageItem from './CardPageTabsImageItem';

const CardPageTabsImage:React.FC = () => {
  // State
  const page:IPage | null = useSelector(selectPage);
  const staplePages:Array<IPage> | null = useSelector(selectPageStaples);
  const mainStaplePageEpisode = useSelector((state:any) => selectEpisodeEntity(state, {
    episodeId: page?.parentEpisodeId && page?.staple?.order === 1 ? page.parentEpisodeId : undefined
  }));

  if ( !page ) return null;
  return (
    <div>
      {staplePages ? (
        staplePages.map((p:IPage) => (
          <CardPageTabsImageItem
            key={`card-page-tabs-image-item-${p.documentId}-${p.pageNum}`}
            mainStaplePageEpisode={mainStaplePageEpisode}
            page={p}
          />
        ))
      ) : (
        <CardPageTabsImageItem page={page} />
      )}
    </div>
  )
}

export default CardPageTabsImage;
