import React from 'react';
import { forceVisible } from 'react-lazyload';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectFilter } from 'app/store/page/page.selectors';
// Components
import ColorPicker from 'app/components/ColorPicker';

const Colors:React.FC = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const filter = useSelector(selectFilter);

  const { colors } = filter;

  const handleClickColor = (newColor:string) => {
    const idx = filter.colors.indexOf(newColor);
    const nextColors = idx !== -1 ? filter.colors.filter((_:any, i:number) => i !== idx) : [...filter.colors, newColor];
    dispatch(PageActions.setFilter('colors', nextColors));
    forceVisible();
  }

  return (
    <ColorPicker
      id="Documents color filter"
      activeColors={colors}
      noColorVisible={true}
      onClick={handleClickColor}
    />
  )
}

export default Colors;
