import { FC, Fragment, forwardRef } from 'react';
import { Box } from '@mui/material';

import $Editor from './Editor';
import $Toolbar from './Toolbar';

export const Editor = $Editor;
export const Toolbar = $Toolbar;

type Props = {
  id: string;
  label: string;
  name: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (value:string) => void;
  onFocus?: () => void;

  height?: number;

  isVariableVisible?: boolean;
  isTabStopVisible?: boolean;
  isPageBreakVisible?: boolean
  isFullScreenVisible?: boolean;
}

const QuillEditor:FC<Props> = forwardRef(({
  isVariableVisible, isTabStopVisible, isPageBreakVisible, isFullScreenVisible, ...otherProps
}, ref) => {
  return (
    <Fragment>
      <Box sx={{ mb: otherProps.disabled ? 0 : 4 }}>
        <Toolbar
          id={otherProps.id}
          disabled={otherProps.disabled}
          isVariableVisible={isVariableVisible}
          isTabStopVisible={isTabStopVisible}
          isPageBreakVisible={isPageBreakVisible}
          isFullScreenVisible={isFullScreenVisible}
        />
      </Box>
      <Editor {...otherProps} />
    </Fragment>
  )
});

export default QuillEditor;