import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
// Types
import AccountStatuses from 'app/types/AccountStatuses';
// Models
import IManagementAccount from 'app/models/ManagementAccount';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AccountsManagementActions } from 'app/store/AccountsManagement/AccountsManagement.slice';
// Selectors
import { selectAccounts, selectFilter } from 'app/store/AccountsManagement/AccountsManagement.selectors';
// Mui
import { Theme, Chip, Box } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Utilities
import { generateAttrForTesting, getAccountStatusLabel } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

const AccountsFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accounts:IManagementAccount[] | null = useAppSelector(selectAccounts)
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const debounceFilter = useCallback(debounce(1000, (field:keyof typeof filter, value:string | AccountStatuses[]) => {
    dispatch(AccountsManagementActions.setFilter({ field, value }));
    // eslint-disable-next-line
  }), []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({...prevState, [name]: value}));
    debounceFilter('search', value);
  }

  const handleClick = (status:AccountStatuses) => {
    const nextStatuses:AccountStatuses[] = stateFilter.statuses.includes(status)
      ? stateFilter.statuses.filter((s:AccountStatuses) => s !== status)
      : [...stateFilter.statuses, status]
    ;
    setStateFilter((prevState:any) => ({ ...prevState, statuses: nextStatuses }));
    debounceFilter('statuses', nextStatuses)
  }

  if ( !accounts || accounts.length === 0 ) return null;
  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Input
          label={t('labels.search')} id="filterSearch" name="search"
          value={stateFilter.search}
          onChange={handleChange}
          size="small"
          margin="none"
        />
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {(Object.values(AccountStatuses) as AccountStatuses[]).map((status) => {
          const isActive = stateFilter.statuses.includes(status)
          return (
            <Chip
              {...generateAttrForTesting('action', 'chip', status)}
              key={`filter-status-item-${status}`}
              sx={{
                fontWeight: 500,
                bgcolor: (theme:Theme) => isActive ? theme.palette.primary.light : 'rgba(0,0,0,0.08)',
                color: (theme:Theme) => isActive ? theme.palette.primary.main : 'rgba(0,0,0,0.87)',
                '&:hover': {
                  bgcolor: (theme:Theme) => isActive ? theme.palette.primary.light : 'rgba(0,0,0,0.12)'
                },
                '&:active': {
                  bgcolor: (theme:Theme) => isActive ? theme.palette.primary.light : 'rgba(0,0,0,0.12)'
                },
                textTransform: 'capitalize'
              }}
              label={getAccountStatusLabel(status)}
              onClick={() => handleClick(status)}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default AccountsFilter;
