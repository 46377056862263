import { FC } from 'react';
// Models
import IEpisode from 'app/models/Episode';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
// Context
import { usePageEpisodesContext } from 'app/context/PageEpisodes.context';
// 
import EpisodeItem from './EpisodeItem';

const EpisodeList:FC = () => {
  const { episodes } = usePageEpisodesContext();

  if ( !episodes ) return <Loader />;
  if ( !episodes.length ) return null;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {episodes.map((episode:IEpisode) => (
        <EpisodeItem
          key={`episode item ${episode.id}`}
          episode={episode}
        />
      ))}
    </Box>
  )
}

export default EpisodeList;
