import { FC, Fragment } from 'react';
// MUI
import { ListItem, ListItemText, Typography } from '@mui/material';
// Utilities
import useLongDateTme from 'app/hooks/useLongDateTime';

type Props = {
  share: any;
}

const ShareItem:FC<Props> = ({
  // Props
  share
}) => {
  const longCreatedOn = useLongDateTme({
    dateValue: share.createdOn,
    displayTime: true,
    local: true
  })

  return (
    <ListItem>
      <ListItemText
        primary={
          <Fragment>
            {share.notificationEmail}
            {longCreatedOn ? (
              <Typography component="span" display="block" variant="subtitle2">{longCreatedOn}</Typography>
            ) : null}
          </Fragment>
        }
        secondary={share.status}
      />
    </ListItem>
  )
}

export default ShareItem;
