import React from 'react';
// Components
import PageImage from '@root/components/PageImage';
import { IPage } from '@root/models/Page';

import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
  page: IPage;
  isLeft: boolean;
  onClose: () => void;
  isFilesPage: boolean;
};

const PageCardPreview:React.FC<Props> = ({
  // Props
  page, isLeft, onClose, isFilesPage
}) => {
  return (
    <div className={`card-page-preview ${!isLeft ? 'left' : ''}`}>
      <IconButton
        sx={{
          position: 'absolute',
          top: 0, right: 0,
          m: 4,
          bgcolor: 'white',
          border: '1px solid rgba(0,0,0,0.08)',
          zIndex: 1
        }}
        onClick={onClose}
      ><CloseIcon /></IconButton>
      <div style={{ overflow: 'hidden' }}>
        <PageImage
          documentId={page.documentId}
          pageNum={page.pageNum}
          highlights={isFilesPage && page.highlights ? page.highlights : undefined}
        />
      </div>
    </div>
  )
}

export default PageCardPreview;
