import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IState, ICollection } from "./DMSCollections.models";
// Async
import {
  getCollections, createCollection, updateCollection, deleteCollection
} from './DMSCollections.async';

const initialState:IState = {
  ids: null,
  entities: {},
  loading: false
};

const slice = createSlice({
  name: Reducers.DMSCollections,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get collections
    builder.addCase(getCollections.pending, (state) => {
      state.ids = initialState['ids'];
      state.entities = initialState['entities'];
    });
    builder.addCase(getCollections.fulfilled, (state, action:PayloadAction<ICollection[]>) => {
      const entities = action.payload.reduce((acc:Record<number, ICollection>, cur:ICollection) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      state.ids = Object.keys(entities).map(Number);
      state.entities = entities;
    });
    // Create collection
    builder.addCase(createCollection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCollection.fulfilled, (state, action:PayloadAction<ICollection>) => {
      if ( state.ids ){
        const collection = action.payload;
        state.ids = [...state.ids, collection.id];
        state.entities = {
          ...state.entities,
          [collection.id]: collection
        };
      }
    });
    // Update collection
    builder.addCase(updateCollection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCollection.fulfilled, (state, action:PayloadAction<ICollection>) => {
      const collection = action.payload;
      if ( state.entities[collection.id] ){
        state.entities[collection.id] = collection;
      }
    });
    // Delete team
    builder.addCase(deleteCollection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCollection.fulfilled, (state, action:{ payload:number }) => {
      if ( state.ids ){
        state.ids = state.ids.filter((id:number) => id !== action.payload);
      }
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const DMSCollectionsActions = slice.actions;

export default slice.reducer;
