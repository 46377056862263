import React from 'react';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectAllPages, selectPrevWorkspacePage, selectNextWorkspacePage } from 'app/store/page/page.selectors';
// MaterialUI
// Core
import IconButton from '@mui/material/IconButton';
// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type Props = {
  page: IPage;
  isWorkspacePage: boolean;
}

const PageCardBodyWorkspaceArrows:React.FC<Props> = ({
  // Props
  page, isWorkspacePage
}) => {
  const stapleId = page.staple && page.staple.id ? page.staple.id : null;
  // Dispatch
  const dispatch = useDispatch();
  // State
  const pages:Array<IPage> | null = useSelector(selectAllPages);
  const prevWorkspacePage:IPage | null = useSelector((state) => selectPrevWorkspacePage(state, {
    documentId: page.documentId,
    pageNum: page.pageNum
  }));
  const nextWorkspacePage:IPage | null = useSelector((state) => selectNextWorkspacePage(state, {
    documentId: page.documentId,
    pageNum: page.pageNum
  }));

  const handleSwapWorkspacePageWithPrev = () => {
    let pagesToUpdate:any = [];
    if ( prevWorkspacePage ){
      if ( stapleId ){
        pagesToUpdate = pages
          ? pages
              .filter((page:IPage) => page.staple && page.staple.id === stapleId)
              .map((page:IPage) => switchPageReportOrder(page, prevWorkspacePage.workspaceOrder))
          : []
        ;
      } else {
        pagesToUpdate = [switchPageReportOrder(page, prevWorkspacePage.workspaceOrder)];
      }
      if ( prevWorkspacePage.staple && prevWorkspacePage.staple.id ){
        const prevStapleId:string = prevWorkspacePage.staple.id;
        const staplePages = pages
          ? pages
              .filter((page:IPage) => page.staple && page.staple.id === prevStapleId)
              .map((p:IPage) => switchPageReportOrder(p, page.workspaceOrder))
          : []
        ;
        pagesToUpdate = [...pagesToUpdate, ...staplePages];
      } else {
        pagesToUpdate = [...pagesToUpdate, switchPageReportOrder(prevWorkspacePage, page.workspaceOrder)];
      }
      dispatch(PageActions.patchPages(pagesToUpdate));
    }
  }
  const handleSwapWorkspacePageWithNext = () => {
    let pagesToUpdate:any = [];
    if ( nextWorkspacePage ){
      if ( stapleId ){
        pagesToUpdate = pages
          ? pages
              .filter((page:IPage) => page.staple && page.staple.id === stapleId)
              .map((page:IPage) => switchPageReportOrder(page, nextWorkspacePage.workspaceOrder))
          : []
        ;
      } else {
        pagesToUpdate = [switchPageReportOrder(page, nextWorkspacePage.workspaceOrder)];
      }
      if ( nextWorkspacePage.staple && nextWorkspacePage.staple.id ){
        const nextStapleId:string = nextWorkspacePage.staple.id;
        const staplePages = pages
          ? pages
              .filter((page:IPage) => page.staple && page.staple.id === nextStapleId)
              .map((p:IPage) => switchPageReportOrder(p, page.workspaceOrder))
          : []
        ;
        pagesToUpdate = [...pagesToUpdate, ...staplePages];
      } else {
        pagesToUpdate = [...pagesToUpdate, switchPageReportOrder(nextWorkspacePage, page.workspaceOrder)];
      }
      dispatch(PageActions.patchPages(pagesToUpdate));
    }
  }

  return (
    <React.Fragment>
      <div className="card-direction left">
        {(isWorkspacePage && prevWorkspacePage) ? (
          <IconButton
            className="card-direction-button"
            color="primary"
            size="small"
            onClick={handleSwapWorkspacePageWithPrev}
          ><ChevronLeftIcon /></IconButton>
        ) : null}
      </div>
      <div className="card-direction right">
        {(isWorkspacePage && nextWorkspacePage) ? (
          <IconButton
            className="card-direction-button"
            color="primary"
            size="small"
            onClick={handleSwapWorkspacePageWithNext}
          ><ChevronRightIcon /></IconButton>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default PageCardBodyWorkspaceArrows;

const switchPageReportOrder = (page:IPage, workspaceOrder:string|undefined) => ({
  documentId: page.documentId,
  pageNum: page.pageNum,
  version: page.version,
  workspaceOrder
});
