import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from "app/types/Reducers";
// Models
import { RootState } from "app/store";

export const selectSchedules = (state:RootState) => state[Reducers.Medical].schedules;
export const selectCarriers = (state:RootState) => state[Reducers.Medical].carriers;
export const selectRates = (state:RootState) => state[Reducers.Medical].rates;
export const selectHcpcsCode = (state:RootState) => state[Reducers.Medical].hcpcsCode;

export const selectSearchHcpcsCode = (state:RootState) => state[Reducers.Medical].searchHcpcsCode;

export const selectHcpcsCodeFetching = (state:RootState) => state[Reducers.Medical].hcpcsCodeFetching;

export const selectCarriersAsOptions = createSelector(
  [ selectCarriers ],
  (carriers:any) => {
    if ( !carriers ) return null;
    return carriers.map((carrier:any) => ({
      id: carrier.carrierNumber,
      name: carrier.name,
      subname: carrier.state || ''
    }));
  }
);
