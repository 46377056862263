import { FC } from 'react';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';

type Props = {
  open: boolean;
  onClose: () => void;
  content: string;
}

const TermsPreviewDialog:FC<Props> = ({
  // Props
  open, onClose, content
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullScreen
      // <Box /> needed to keep DialogTitle with clsoe button
      title={<Box />}>
    <Box
      sx={{ whiteSpace: 'break-spaces', padding: 0 }}
      className="ql-editor preview"
      dangerouslySetInnerHTML={{__html: content }}
    /></Dialog>
  )
}

export default TermsPreviewDialog;
