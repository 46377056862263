import { FC } from 'react';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  documentId: number;
  pageNum: number;
  index?: number;
}

const CardPageLazyPlaceholder:FC<Props> = ({
  // Props
  documentId, pageNum, index
}) => {
  const { t } = useTranslation('common');
  return (
    <div
      className="cp-placeholder"
      data-document-id={documentId}
      data-page-num={pageNum}
    >{t('components.cardPage.page')} {typeof index !== 'undefined' ? index + 1 : pageNum}</div>
  )
}

export default CardPageLazyPlaceholder;
