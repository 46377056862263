import { FC, Fragment } from 'react';
// 
import FullChart from './FullChart';
import DailyChart from './DailyChart';
import { useAppSelector } from 'app/hooks/useStore';
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';

const Timeline:FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);

  const timeFormat = currentAccount?.settings.timeFormat
    ? currentAccount?.settings.timeFormat.includes('a')
      ? 'hh:mm tt'
      : 'HH:mm'
    : 'hh:mm tt'
  ;

  const initialDataPointWidth = 5;
  const initialOptions = {
    toggleVisible: false,
    zoomOutVisible: false,
    dataPointWidth: initialDataPointWidth,
    toolTip: {
      enabled: false,
    },
    axisX:{
      valueFormatString: timeFormat
    },
    axisY: {
      interval: 1,
      includeZero: true,
      tickColor: '#d3d3d3',
      gridColor: '#d3d3d3'
    }
  };

  return (
    <Fragment>
      <FullChart initialDataPointWidth={initialDataPointWidth} initialOptions={initialOptions} />
      <DailyChart initialOptions={initialOptions} />
    </Fragment>
  )
}

export default Timeline;
