import { Action } from 'redux';

// Types
export enum AppTypes {
  ToggleHighlightMode = '@@@app/Toggle highlight mode',
  SetLastOpenedWorkspacePage = '@@@app/Set last opened workspace page',
  SetLastOpenedFilePage = '@@@app/Set last opened file page'
};

// Actions types
// Toggle hightlight mode
interface ToggleHighlightMode extends Action<typeof AppTypes.ToggleHighlightMode> {};
// Set last opened workspace page
interface SetLastOpenedWorkspacePage extends Action<typeof AppTypes.SetLastOpenedWorkspacePage> {
  payload: { insuranceCaseId:number, documentId:number, pageNum:number }
};

interface SetLastOpenedFilePage extends Action<typeof AppTypes.SetLastOpenedFilePage> {
  payload: { insuranceCaseId:number, documentId:number, pageNum:number }
};

export type AppActionTypes =
  ToggleHighlightMode |
  SetLastOpenedWorkspacePage | SetLastOpenedFilePage
;