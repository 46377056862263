import { forwardRef } from 'react';
import { Dayjs } from 'dayjs';
// Models
import { ICurrentAccount } from 'app/models/Account';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { TextField, TextFieldProps } from '@mui/material';
// X
import {
  DesktopDatePicker as MuiDesktopDatePicker,
  DesktopDatePickerProps as MuiDesktopDatePickerProps
} from '@mui/x-date-pickers';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type DesktopDatePickerProps = {
  name: string;
  inputProps?: TextFieldProps;
} & Omit<MuiDesktopDatePickerProps<Dayjs, Dayjs>, 'renderInput'>

const DesktopDatePicker = forwardRef<HTMLDivElement, DesktopDatePickerProps>(({
  name, inputProps = {}, ...otherProps
}, ref) => {
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount)

  const inputFormat = (currentAccount?.settings?.dateFormat || 'MM/DD/YYYY').toUpperCase();

  return (
    <MuiDesktopDatePicker
      {...otherProps}
      ref={ref}
      inputFormat={inputFormat}
      renderInput={(params) => (
        <TextField
          {...params}
          {...generateAttrForTesting('control', 'input', name)}
          {...inputProps}
          name={name}
          fullWidth={typeof inputProps.fullWidth === 'undefined' ? true : inputProps.fullWidth}
          margin={inputProps.margin || 'normal'}
        />
      )}
    />
  );
});

export default DesktopDatePicker;
