import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Models
import { RootState } from 'app/store';
import IOption from 'app/models/Option';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptionsByFileType } from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectFileError } from 'app/store/DMSBatches/DMSBatches.selectors';
// Mui
import { Box, Typography, FormHelperText, Theme } from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { Select, IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
// Utilities
import { isRequired } from 'app/utilities/Validations';
import { toSize } from 'app/utilities/Utilities';
// 
import FileCommentDialog from './FileCommentDialog';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  fields: any[];
  field: any;
  index: number;
  onRemove: () => void;
}

const FileItem:FC<Props> = ({
  // Props
  fields, field, index, onRemove
}) => {
  const { t } = useTranslation('common');
  // State
  const collectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType(state, {
    fileFormat: field.fileFormat
  }));
  const fileError = useAppSelector((state:RootState) => selectFileError(state, { index }));

  const { control, register, formState:{ errors }, setValue, watch } = useFormContext();

  const { open, toggle } = useToggle();
  const { Menu, MenuItem, openMenu } = useMenu();

  const handleClick = () => {
    if ( !field.file ) return;
    const url = URL.createObjectURL(field.file);
    window.open(url, '_blank');
  }

  const handleClose = (comment:string) => {
    if ( open ) toggle();
    setValue(`files.${index}.comment`, comment || '');
  }

  const getCollectionIdError = () => {
    if ( !errors || !errors.files ) return '';
    const error = (errors.files as any)[index];
    return error?.collectionId?.message || '';
  }

  // For some reasong `field` doesn`t get update after `handleClose` updated comment via setValue
  const watchComment = watch(`files.${index}.comment`);

  const fileErrorMessage = fileError
    ? fileError.errorType === 'sameName'
      ? 'Document with same document name already selected'
      : fileError.errorType === 'sameChecksum'
        ? `Same document is already selected as ${fileError.duplicateFileIndexes.map((i:number) => fields[i].file.name).join(', ')}`
        : fileError.errorMessage
    : null
  ;

  return (
    <Fragment>
      <Box sx={{
        borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        mt: 2,
        pt: 2
      }}>
        <input {...register(`files.${index}.type`) as any} type="hidden" value={field.type} />
        <input {...register(`files.${index}.comment`) as any} type="hidden" value={field.comment} />

        <Box sx={{ display: 'flex', alignItems: 'flex-Start', gap: 2, py: 2 }}>
          <Box sx={{ alignSelf: 'center', flex: 1, overflow: 'hidden' }}>
            <Typography variant="subtitle1" title={field.file.name}>{truncateName(field.file.name)}</Typography>
            {field.file.size ? (
              <Typography color="CaptionText" variant="body2">{toSize(field.file.size)}</Typography>
            ) : null}
            {watchComment ? (
              <Typography color="CaptionText" variant="body2">{`${t('labels.comment')}: ${watchComment}`}</Typography>
            ) : null}
            {fileErrorMessage ? (
              <FormHelperText error>{fileErrorMessage}</FormHelperText>
            ) : null}
          </Box>
          {collectionOptions ? (
            <Controller
              control={control} name={`files.${index}.collectionId`} defaultValue={field.collectionId}
              rules={{ required: isRequired }}
              render={({ field:f }) => (
                <Select
                  {...f}
                  sx={{ maxWidth: 200 }}
                  label={t('labels.documentCollection')}
                  options={collectionOptions}
                  required
                  error={Boolean(getCollectionIdError())}
                  helperText={getCollectionIdError()}
                  renderOptions={(option:IOption, index:number) => (
                    <MenuItem
                      name={`option item ${index} ${option.id}`}
                      sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                      key={`option item ${index} ${option.id}`}
                      value={option.id.toString()}
                    >
                      <Typography variant="subtitle1">{option.name}</Typography>
                      {option.props?.description ? (
                        <Typography sx={{ maxWidth: 320, whiteSpace: 'normal' }} variant="caption">{option.props.description}</Typography>
                      ) : null}
                    </MenuItem>
                  )}
                  margin="none"
                  size="small"
                />
              )}
            />
          ) : (
            <FormHelperText
              sx={{ alignSelf: 'center' }}
              error={true}
            >{t('dialogs.batchCreate.fileFormatNotSupported')}</FormHelperText>
          )}
          <IconButton
            name={`File context menu ${field.id}`}
            aria-label="File context menu"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            {field.file.type && field.file.type.includes('pdf') ? (
              <MenuItem
                name={`Preview file ${field.id}`}
                onClick={handleClick}
              >{t('labels.preview')}</MenuItem>
            ) : null}
            <MenuItem
              name={`Comment file ${field.id}`}
              onClick={toggle}
            >{t('labels.comment')}</MenuItem>
            <MenuItem
              name={`Remove file ${field.id}`}
              sx={{
                color: (theme:Theme) => theme.palette.error.main
              }}
              onClick={onRemove}
            >{t('labels.delete')}</MenuItem>
          </Menu>
        </Box>
      </Box>
      {open ? (
        <FileCommentDialog
          open={open}
          onClose={handleClose}
          comment={watchComment}
        />
      ) : null}
    </Fragment>
  )
}

export default FileItem;

const truncateName = (filename:string, maxLength = 60) => {
  if ( filename.length <= maxLength ){
    return filename;
  } else {
    const extensionIndex = filename.lastIndexOf('.');
    const extension = filename.slice(extensionIndex - 4);
    const truncatedName = filename.slice(0, maxLength - extension.length - 2);
    return truncatedName + '....' + extension;
  }
}
