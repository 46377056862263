import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getTermsAndConditions } from 'app/store/Terms/Terms.async';
import { acceptTermsAndConditions } from 'app/store/Users/Users.async';
// Selectors
import { selectLoading, selectCurrentTermsAndConditions, selectPendingTermsAndConditionsByScope } from 'app/store/Users/Users.selectors';
// Mui
import {
  Box, Container, Paper
} from '@mui/material';
// Components
import { LoadingButton } from 'app/components/Mui';

const AiChatTermsAndConditions:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);
  const pendingTermsAndConditions = useAppSelector((state:RootState) => selectPendingTermsAndConditionsByScope(state, {
    scope: 'aiChat'
  }));
  const currentTermsAndConditions = useAppSelector((state:RootState) => selectCurrentTermsAndConditions(state, {
    scope: 'aiChat'
  }));

  useEffect(() => {
    if ( !pendingTermsAndConditions || !pendingTermsAndConditions.length ) return;
    const ids = pendingTermsAndConditions.map((pedingTerms:any) => pedingTerms.id);
    const params = { ids: ids.join(','), owners: 'self,platform' };
    dispatch(getTermsAndConditions(params));
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    if ( !currentTermsAndConditions ) return;
    dispatch(acceptTermsAndConditions(currentTermsAndConditions.id));
  }

  if ( !currentTermsAndConditions ) return null;
  return (
    <Fragment>
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Container sx={{ display: 'flex', flexDirection: 'column', py: 4 }} maxWidth="md">
          <Paper
            sx={{ flexGrow: 1, p: 4, overflowY: 'auto', whiteSpace: 'break-spaces' }}
            className="ql-editor preview"
            dangerouslySetInnerHTML={{ __html: currentTermsAndConditions.content }}
          />
        </Container>
      </Box>
      <Box sx={{ py: 4, textAlign: 'center' }}>
        <LoadingButton
          name="Accept terms and conditions"
          loading={loading}
          onClick={handleClick}
          color="primary"
          variant="contained"
        >{t('pages.termsAndConditionsPage.accept')}</LoadingButton>
      </Box>
    </Fragment>
  )
}

export default AiChatTermsAndConditions;
