import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getUsers } from 'app/store/Users/Users.async';
import { getDocumentsExports } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.async';
// Actions
import { DMSDocumentsExportsActions } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.slice';
// Mui
import { Box, Container } from '@mui/material';
// 
import DocumentsExportsList from './DocumentsExportsList';
import DocumentsExportsFab from './DocumentsExportsFab';

const DocumentsExportsPage:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUsers({ role: 'admin' }));
    dispatch(getDocumentsExports({ insuranceCaseId: Number(caseId) }));

    return () => {
      dispatch(DMSDocumentsExportsActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
      <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
        <DocumentsExportsList />
      </Container>
      <DocumentsExportsFab />
    </Box>
  )
}

export default DocumentsExportsPage;
