import { CalendarColors } from 'app/App.constants';

// Types
import CalendarLegends from 'app/types/CalendarLegends';

export const parseQueryByName = (search, name) => {
  if ( !search ) return '';
  return new URLSearchParams(search).get(name);
}

export const getVisitColor = visitType => {
  if ( !visitType || visitType === CalendarLegends.Unassigned ) return '#ff0000';
  const hash = _hashString(visitType);
  const colorIndex = _floorMod(hash, CalendarColors.length);
  return colorIndex >= 0 ? CalendarColors[colorIndex] : CalendarColors[0];
}

const _floorMod = (x,y) => {
  var r = Math.floor(x / y);
  return x - r * y;
}

const _hashString = value => {
  let hash = 0;
  if (value.length === 0) return hash;
  for (let i = 0; i < value.length; i++) {
    let char = value.charCodeAt(i);
    hash = ((hash<<5)-hash)+char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}