import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IBillingService from "app/models/BillingService";
// Async
import { getServices, createService, updateService, deleteService } from './BillingServices.async';

interface IState {
  ids: number[] | null;
  entities: { [key:number]:IBillingService };
  loading: boolean;
};

const initialState:IState = {
  ids: null,
  entities: {},
  loading: false
};

const slice = createSlice({
  name: Reducers.BillingServices,
  initialState,
  reducers: {
    
  },
  extraReducers(builder) {
    // Get services
    builder.addCase(getServices.pending, (state) => {
      state.ids = null;
      state.entities = {};
    });
    builder.addCase(getServices.fulfilled, (state, action:PayloadAction<IBillingService[]>) => {
      const ids = action.payload.map((service:IBillingService) => service.id);
      const entities = action.payload.reduce((acc:{ [key:number]:IBillingService }, cur:IBillingService) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      state.ids = ids;
      state.entities = entities;
    });
    // Create service
    builder.addCase(createService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createService.fulfilled, (state, action:PayloadAction<IBillingService>) => {
      if ( state.ids ){
        const service = action.payload;
        state.ids = [...state.ids, service.id];
        state.entities[service.id] = service;
      }
    });
    // Update service
    builder.addCase(updateService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateService.fulfilled, (state, action:PayloadAction<IBillingService>) => {
      state.entities[action.payload.id] = action.payload;
    });
    // Delete service
    builder.addCase(deleteService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteService.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.ids ){
        state.ids = state.ids.filter((id:number) => id !== action.payload);
      }
    });

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  },
});

export default slice.reducer;
