import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import ITeam from 'app/models/Team';
import IConversation, { IMember } from 'app/models/Conversation';
// Selectors
import { selectTeam } from 'app/store/Teams/Teams.selectors';
import { selectAccountUsers } from 'app/store/Clients/Clients.selectors';

export const selectUnseenConversationsIds = (state:RootState) => state[Reducers.Conversations].unseenConversations.ids;
export const selectUnseenConversationsEntities = (state:RootState) => state[Reducers.Conversations].unseenConversations.entities;

export const selectConversations = (state:RootState) => state[Reducers.Conversations].conversations;
export const selectConversation = (state:RootState) => state[Reducers.Conversations].conversation;
export const selectLoading = (state:RootState) => state[Reducers.Conversations].loading;

export const selectUnseenConversation = createSelector(
  [
    selectUnseenConversationsEntities,
    (_, props:{ conversationId:number }) => props
  ],
  ( entities:Record<number, IConversation>, { conversationId } ) => entities[conversationId] || null
);

export const selectTotalUnseenMessages = createSelector(
  [
    selectUnseenConversationsIds,
    selectUnseenConversationsEntities
  ],
  ( ids:number[] | null, entities:Record<number, IConversation> ) => {
    if ( !ids ) return 0;
    return ids.reduce((acc:number, cur:number) => {
      const conversation = entities[cur];
      return conversation?.me?.numberOfUnseenMessages ? acc + conversation.me.numberOfUnseenMessages : acc;
    }, 0);
  }
);

export const selectSortedConversations = createSelector(
  [
    selectUnseenConversationsIds,
    selectUnseenConversationsEntities,
    selectConversations
  ],
  ( unseenIds:number[] | null, unseenEntities:Record<number, IConversation>, conversations:IConversation[] | null ) => {
    if ( !conversations ) return null;
    return conversations
      .map((conversation:any) => {
        if ( unseenIds && unseenIds.includes(conversation.id) ){
          const unseenConversation = unseenEntities[conversation.id];
          if ( unseenConversation ) return {...conversation, ...unseenConversation};
        }
        return conversation;
      })
      .sort((a:any, b:any) => {
        const aDate = a.lastMessage ? new Date(a.lastMessage.createdOn).getTime() : 0;
        const bDate = b.lastMessage ? new Date(b.lastMessage.createdOn).getTime() : 0;
        return bDate - aDate;
      });
  }
);

export const selectMembersAsOptions = createSelector(
  [
    selectTeam,
    selectAccountUsers,
    selectConversation
  ],
  ( team:ITeam | null, users:any, conversation:IConversation | null ) => {
    const result = [];
    const membersIds:string[] = conversation?.members
      ? conversation.members.map((member:IMember) => member.id)
      : []
    ;
    if ( team?.members && team.members.length ){
      for ( let member of team.members ){
        const id = `cases.mdc.com:${member.id}`;
        if ( membersIds.includes(id) ) continue;
        result.push({ id, name: member.name });
      }
    }
    if ( users && users.length ){
      for ( let member of users ){
        const id = `clients.mdc.com:${member.id}`;
        if ( membersIds.includes(id) ) continue;
        result.push({ id, name: member.name })
      }
    }
    return result;
  }
);
