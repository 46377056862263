import { FC } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAdditionalToolsShown } from 'app/store/ui/uiSelectors';
// Mui
import { Box, Container } from '@mui/material';
// Components
import ColumnSwitcher from 'app/components/ColumnSwitcher';
// 
import EpisodeTypes from './EpisodeTypes';
import EpisodeLabels from './EpisodeLabels';
import EpisodeAuthors from './EpisodeAuthors';
import Tags from './Tags';
import Colors from './Colors';
import AdditionalRecordsOnly from './AdditionalRecordsOnly';
import AdditionalToolsNames from 'app/types/AdditionalToolsNames';

const WorkspaceFilter:FC = () => {
  // State
  const tools = useAppSelector((state:RootState) => selectAdditionalToolsShown(state, {
    toolsType: 'workspace'
  }));

  const emptyTools = tools.filter((tool:AdditionalToolsNames) => 
    tool !== AdditionalToolsNames.GoToPostEvent &&
    tool !== AdditionalToolsNames.GoToPage &&
    tool !== AdditionalToolsNames.GoToLastOpenedPage
  );

  if ( !emptyTools.length ) return <Box sx={{ pt: 4 }} />;
  return (
    <Container sx={{ pt: 4, mb: 4 }} maxWidth={false}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: 4, rowGap: 2 }}>
        {tools.includes(AdditionalToolsNames.EpisodeTypes) ? (
          <EpisodeTypes />
        ) : null}
        {tools.includes(AdditionalToolsNames.EpisodeLabels) ? (
          <EpisodeLabels />
        ) : null}
        {tools.includes(AdditionalToolsNames.EpisodeAuthors) ? (
          <EpisodeAuthors />
        ) : null}
        {tools.includes(AdditionalToolsNames.NumberOfColumns) ? (
          <ColumnSwitcher />
        ) : null}
        {tools.includes(AdditionalToolsNames.Tags) ? (
          <Box><Tags /></Box>
        ) : null}
        {tools.includes(AdditionalToolsNames.Colors) ? (
          <Box><Colors /></Box>
        ) : null}
        {tools.includes(AdditionalToolsNames.AdditionalRecordsOnly) ? (
          <AdditionalRecordsOnly />
        ) : null}
      </Box>
    </Container>
  )
}

export default WorkspaceFilter;
