import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('America/New_York')

export const parseDate = (date:string):Dayjs => {
  const [ year, month, day ] = date.split('-');
  return dayjs().year(Number(year)).month(Number(month) - 1).date(Number(day));
}

export const parseTime = (time:string, format = 'hh:mm a'):string => {
  const [ hour, minute ] = time.split(':');
  return dayjs().hour(Number(hour)).minute(Number(minute)).format(format);
}

export const parseDateFromUtcToLocal = (date:string, format = 'MMMM DD, YYYY hh:mm a') => {
  return dayjs.utc(date).local().format(format);
}

export const convertDateToString = (date:Dayjs, format:string = 'YYYY-MM-DD'):string => {
  if ( !date || !date.isValid() ) return '';
  return date.format(format);
};

export const convertTime12to24Format = (time12:string):string => {
  const [ time, modifier ] = time12.split(' ');
  let [ hours, minutes ] = time.split(':');
  if ( hours === '12' ) hours = '00';
  if ( modifier === 'PM' || modifier === 'pm' ){
    hours = `${parseInt(hours, 10) + 12}`.toString();
  }
  return `${hours}:${minutes}`;
}

export const checkIsPagePreEvent = (eventDate:string, eventTime:string, pageDate:Dayjs|string, pageTime:string) => {
  if ( !eventDate || !pageDate ) return false;
  let caseDateTime = parseDate(eventDate);
  caseDateTime = caseDateTime.hour(0).minute(0).second(0).millisecond(0);
  let pageDateTime = typeof pageDate === 'string' ? parseDate(pageDate) : pageDate;
  pageDateTime = pageDateTime.hour(0).minute(0).second(0).millisecond(0);
  if ( pageDateTime.isSame(caseDateTime) ){
    if ( !eventTime || !pageTime ) return false;
    if ( eventTime && pageTime ){
      const [ eventHours, eventMinutes ] = eventTime.split(':');
      caseDateTime = caseDateTime.hour(Number(eventHours)).minute(Number(eventMinutes));
      const time = pageTime.indexOf('pm') !== -1 || pageTime.indexOf('am') !== -1 ? convertTime12to24Format(pageTime) : pageTime;
      const [ pageHours, pageMinutes ] = time.split(':');
      pageDateTime = pageDateTime.hour(Number(pageHours)).minute(Number(pageMinutes));
      return pageDateTime.isBefore(caseDateTime);
    } else {
      return false;
    }
  } else {
    return pageDateTime.isBefore(caseDateTime);
  }
}

export const checkIsDateInPast = (date:string) => {
  return dayjs(date).isBefore(dayjs(), 'day');
}
