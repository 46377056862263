import config from '../../../../config';

import { FC, Fragment } from 'react';
// Models
import IClientAccount from 'app/models/ClientAccount';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPatientPortalEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Paper, Box, Typography } from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock, MailTo } from 'app/components/Utilities';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// Services
import CookieService from 'app/services/Cookie.service';
// 
import ClientAccountUpdateDialog from './ClientAccountUpdateDialog';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  account: IClientAccount;
}

const AccountsListItem:FC<Props> = ({
  // Props
  account
}) => {
  const { t } = useTranslation('common');

  const patientPortalEnabled = useAppSelector(selectPatientPortalEnabled);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();

  const handleClick = () => {
    CookieService.setCredential(account.id);
    window.open(config.clientPortalUrl, '_blank');
    // window.open('http://clients-local.casechronology.com:3001', '_blank');
  }

  const address = [];
  if ( account.address?.city ) address.push(account.address.city);
  if ( account.address?.line ) address.push(account.address.line);
  if ( account.address?.state ) address.push(account.address.state);
  if ( account.address?.zipCode ) address.push(account.address.zipCode);

  return (
    <Fragment>
      <Paper sx={{ display: 'flex', p: 4 }} variant="outlined">
        <Box sx={{ flexGrow: 1, pr: 2 }} >
          <Typography variant="subtitle1">{account.company}</Typography>
          {address.length ? (
            <Typography color="CaptionText" variant="body2">{address.join(', ')}</Typography>
          ) : null}
          {account.email ? (
            <InfoBlock label={t('labels.email')} value={<MailTo email={account.email} />} direction="row" />
          ) : null}
          {account.phone ? (
            <InfoBlock label={t('labels.phone')} value={account.phone} direction="row" />
          ) : null}
          {account.quickBooksId ? (
            <InfoBlock label={t('labels.quickbooksId')} value={account.quickBooksId} direction="row" />
          ) : null}
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Client account dropdown ${account.id}`}
            aria-label="Client account item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`Log in to client portal ${account.id}`}
              onClick={handleClick}
            >{t('pages.staffPages.clientAccountsPage.loginToClientPortal')}</MenuItem>
            {patientPortalEnabled ? (
              <MenuItem
                name={`Edit client account ${account.id}`}
                onClick={toggle}
              >{t('labels.edit')}</MenuItem>
            ) : null}
          </Menu>
        </Box>
      </Paper>
      {/* Dialogs */}
      {open ? (
        <ClientAccountUpdateDialog
          open={open}
          onClose={toggle}
          account={account}
        />
      ) : null}
    </Fragment>
  )
}

export default AccountsListItem;
