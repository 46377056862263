import AuditOperations from '_types/AuditOperations';

export const generateAuditLabelData = (operation:AuditOperations, data:{
  insuranceCaseId: number;
  userName: string;
  type: 'case' | 'document';
  removed?: boolean;
  labels: string[];
  fileName?: string;
}) => {
  // {USER_NAME} removed labels "{LABEL1_NAME}", "{LABEL2_NAME}" from case
  // {USER_NAME} added labels "{LABEL1_NAME}", "{LABEL2_NAME}" to case

  // {USER_NAME} removed label "{LABEL1_NAME}" from document "{DOCUMENT_FILE_NAME}"
  // {USER_NAME} added label "{LABEL1_NAME}" to document "{DOCUMENT_FILE_NAME}"

  const { insuranceCaseId, userName, type, removed = false, labels, fileName } = data;

  const operationText = `${userName} ${removed ? 'removed' : 'added'}`;
  const labelText:string = `${labels.length > 1 ? 'labels' : 'label'} ${labels.map(l => `"${l}"`).join(', ')}`;
  const fileText = `${removed ? 'from' : 'to'} ${type}${fileName ? ` ${fileName}` : ''}`

  const description = `${operationText} ${labelText} ${fileText}`;

  return {
    insuranceCaseId,
    operation,
    description
  };
}

export const generateAuditCaseShareData = (operation:AuditOperations, data:{
  insuranceCaseId:number,
  userName:string,
  userEmail:string,
  shared:boolean
}) => {
  // {USER_NAME} shared case with client/presenter user "{USER_EMAIL}"
  // {USER_NAME} stopped case sharing with "{USER_EMAIL}"
  const { insuranceCaseId, userName, userEmail, shared } = data;
  const description = `${userName} ${shared
    ? 'shared case with client/presenter user'
    : 'stopped case sharing with'
  } ${userEmail}`;
  return {
    insuranceCaseId,
    operation,
    description
  };
}

export const generateAuditReportShareData = (operation:AuditOperations, data:{
  insuranceCaseId:number,
  userName:string,
  reportName:string,
  clientUserEmails:Array<{ id:number, email:string }>,
  deleted:boolean
}) => {
  // {USER_NAME} shared report "{REPORT_NAME}" to client users "{CLIENT_USER_EMAIL_1}", "{CLIENT_USER_EMAIL_2}"
  // {USER_NAME} deleted report "{REPORT_NAME}" shared with client users "{CLIENT_USER_EMAIL_1}", "{CLIENT_USER_EMAIL_2}"
  const { insuranceCaseId, userName, reportName, clientUserEmails, deleted } = data;
  const description = `${userName} ${deleted
      ? 'deleted'
      : 'shared'
  } report "${reportName}" ${deleted
      ? 'shared with'
      : 'to'
  } client users ${
    clientUserEmails && clientUserEmails.length > 0
    ? clientUserEmails.map((user:{ id:number, email:string }) => user.email).join(', ')
    : ''
  }`;
  return {
    insuranceCaseId,
    operation,
    description
  };
}