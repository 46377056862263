// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./AccountsManagement.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/management/accounts';

// Get accounts
export const getAccounts = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Get accounts`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);

// Get account
export const getAccount = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Get account`,
  async (accountId:number) => {
    const response:Response = await $get(`${_url}/${accountId}`);
    return response.json();
  }
);

// Create account
export const createAccount = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Create account`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update account
export const updateAccount = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Update account`,
  async ({ accountId, data }:{ accountId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${accountId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Delete account
export const deleteAccount = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Delete account`,
  async (accountId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${accountId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(accountId);
  }
);

// Suspend account
export const suspendAccount = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Suspend account`,
  async (accountId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${accountId}/actions/suspend`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Suspend
    }));
    return response.json();
  }
);

// Disable account
export const disableAccount = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Disable account`,
  async (accountId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${accountId}/actions/disable`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Disable
    }));
    return response.json();
  }
);

// Suspend account
export const activateAccount = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Activate account`,
  async (accountId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${accountId}/actions/activate`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Active
    }));
    return response.json();
  }
);

// Get account billing details
export const getAccountBilling = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Get account billing details`,
  async (accountId:number) => {
    const response:Response = await $get(`${_url}/${accountId}/billing`);
    return response.json();
  }
);

// Update account billing subscription
export const updateAccountBillingSubscription = asyncThunkHandler(
  `${Reducers.AccountsManagement}/Update account billing subscription`,
  async ({ accountId, data }:{ accountId:number, data:any }, { dispatch, fulfillWithValue }) => {
    await $put(`${_url}/${accountId}/billing/subscription`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.UpdateSubscription
    }));
    return fulfillWithValue('Account billing subscription updated');
  }
);
