enum AdditionalToolsNames {
  GoToLastOpenedPage = 'go_to_last_opened_page',
  GoToPage = 'go_to_page',
  GoToPostEvent = 'go_to_post_event',
  NumberOfColumns = 'number_of_columns',
  Tags = 'tags',
  Colors = 'colors',
  EpisodeTypes = 'episode_types',
  EpisodeLabels = 'episode_labels',
  EpisodeAuthors = 'episode_authors',
  HaveDuplicateInWorkspace = 'have_duplicate_in_workspace',
  AdditionalRecordsOnly = 'additional_records_only'
};

export default AdditionalToolsNames;