import { Fragment, FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectEntities as selectCollectionEntities } from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectCollectionDocuments } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.selectors';
// Mui
import {
  Theme,
  Box, Paper,
  Tabs, Tab,
  List, ListItem, ListItemText,
  Typography
} from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClose: () => void;
  documentIds?: number[];
}

const RecordsExportFilesDialog:FC<Props> = ({
  // Props
  open, onClose, documentIds = []
}) => {
  const { t } = useTranslation('common');
  // State
  const collectionEntities = useAppSelector(selectCollectionEntities);
  const collectionDocuments:Record<number, IDocument[]> = useAppSelector((state:RootState) => selectCollectionDocuments(state, {
    documentIds
  }));

  const collectionTotalPages = useMemo(() => {
    let result:Record<number, number> = {}
    for ( let key in collectionDocuments ){
      result[key] = 0;
      for ( let document of collectionDocuments[key] ){
        if ( document.numberOfPages ){
          result[key] = result[key] + document.numberOfPages;
        }
      }
    }
    return result;
  }, [collectionDocuments]);

  const [ tab, setTab ] = useState(0);

  const handleChangeTab = (_:any, nextTab:number) => setTab(nextTab); 

  const actions = (
    <Button
      name="Close records export file dialog"
      onClick={onClose}
    >{t('labels.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.adminPages.exportMaterialsPage.filesList')}
      actions={actions}
    >
      <Box sx={{
        borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        px: 4, mb: 4
      }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          centered
        >
          {Object.keys(collectionDocuments).map((key:any, index:number) => {
            const collectionEntity = collectionEntities[key];
            return (
              <Tab
                key={`collection tab item ${key} ${index}`}
                aria-label={collectionEntity.name}
                label={collectionEntity.name}
              />
            )
          })}
        </Tabs>
      </Box>

      {Object.keys(collectionDocuments).map((key:any, index:number) => {
        const documents = collectionDocuments[key];
        if ( tab !== index ) return null;
        return (
          <Fragment>
            <Typography sx={{ py: 4 }} variant="subtitle1">{t('labels.totalPages')}: {collectionTotalPages[key]}</Typography>
            <Paper variant="outlined">
              <List key={`collection documents ${key} ${index}`}>
                {documents.map((document:IDocument) => (
                  <ListItem key={`document item ${document.id}`}>
                    <ListItemText
                      primary={document.name}
                      secondary={`${document.numberOfPages} ${t('labels.pages')}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Fragment>
        )
      })}
    </Dialog>
  )
}

export default RecordsExportFilesDialog;
