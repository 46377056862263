import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.material.create'),
  Send: i18n.t('notifications.material.send')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.material.create');
  Messages.Send = i18n.t('notifications.material.send');
});