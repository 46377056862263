import { FC, Fragment } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getCurrentStats, getCurrentMonthlyStats } from 'app/store/Analytics/Analytics.async';
// Mui
import { Box, Divider, Toolbar, Typography, Container, Paper } from '@mui/material';
// Components
import { AnalyticsStats, AnalyticsMonthlyStats } from 'app/components/Analytics';

const AnalyticsPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();

  const handleGetStats = () => {
    dispatch(getCurrentStats({}));
  }

  const handleGetMonthlyStats = (params:any) => {
    dispatch(getCurrentMonthlyStats(params));
  }

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">Usage Report</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="md">
          <Paper sx={{ p: 4 }} variant="outlined">
            <AnalyticsStats
              title="Account summary"
              onGetStats={handleGetStats}
            />
            <Divider sx={{ my: 4 }} />
            <AnalyticsMonthlyStats
              title="Selected range summary"
              onGetMonthlyStats={handleGetMonthlyStats}
            />
          </Paper>
        </Container>
      </Box>
    </Fragment>
  )
}

export default AnalyticsPage;
