import { FC, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Models
import IMatch from 'app/models/Match';
// Components
import Header from 'app/components/Header';
// Pages
import InsuranceCasesPage from './InsuranceCasesPage';
import InsuranceCaseRouting from './InsuranceCase.routing';

type Props = {
  match: IMatch;
}

const PresenterRouting:FC<Props> = ({
  // Props
  match
}) => {
  return (
    <Fragment>
      <Header />
      <Switch>
        <Route exact path={`${match.path}/cases`} component={InsuranceCasesPage} />
        <Route path={`${match.path}/cases/:insuranceCaseId`} component={InsuranceCaseRouting} />
        <Redirect from={match.path} to={`${match.path}/cases`} />
      </Switch>
    </Fragment>
  )
}

export default PresenterRouting;
