import { FC } from 'react';
// Mui
import { Toolbar, Box } from '@mui/material';
// Components
import CaseInfo from 'app/components/CaseInfo';
// 
import CaseDetailsDropdown from './CaseDetailsDropdown';
import CaseDetailsActions from './CaseDetailsActions';
import CaseDetailsNavs from './CaseDetailsNavs';

const CaseDetailsToolbar:FC = () => {
  return (
    <Toolbar>
      <Box sx={{
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        width: 384,
        minHeight: { xs: 56, sm: 63 },
        borderRight: '1px solid rgba(0,0,0,0.08)',
        pr: 1
      }}>
        <CaseInfo />
        <CaseDetailsDropdown />
      </Box>
      <Box sx={{ flexGrow: 1, pl: 4, overflow: 'hidden' }}>
        <CaseDetailsNavs />
      </Box>
      <CaseDetailsActions />
    </Toolbar>
  )
}

export default CaseDetailsToolbar;
