import { FC, Fragment } from 'react'
// Models
import { RootState } from 'app/store';
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPage, selectStaplePageIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import { FileDownloadOutlined as FileDownloadOutlinedIcon } from '@mui/icons-material';
// Components
import CaseInfo from 'app/components/CaseInfo';
import DocumentName from 'app/components/DocumentName';
import { IconButton } from 'app/components/Mui';
import { Bull } from 'app/components/Utilities';
// Dialogs
import ExportPagesToPDFDialog from 'app/dialogs/ExportPagesToPDFDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import CardPageNavigation from './CardPageNavigation';

type Props = {
  page: IPresenterPage;
}

const DialogTitle:FC<Props> = ({
  // Props
  page
}) => {
  // State
  const staplePageIds:string[] | null = useAppSelector((state:RootState) => selectStaplePageIds(state, {
    documentId: page.documentId,
    stapleId: page.staple?.id || null
  }));

  const { open, toggle } = useToggle();

  if ( !page ) return null;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <CaseInfo direction="row" statusVisible={false} />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 400
          }}>
            <Box>
              <DocumentName documentId={page.documentId} position="relative" />
            </Box>
            <Bull />
            <Tooltip title="Export page(s) to PDF">
              <IconButton
                name="Export page(s) to PDF"
                aria-label="Export page(s) to PDF button"
                onClick={toggle}
                size="small"
              ><FileDownloadOutlinedIcon /></IconButton>
            </Tooltip>
          </Box>
        </Box>
        <CardPageNavigation />
      </Box>
      {open ? (
        <ExportPagesToPDFDialog
          open={open}
          onClose={toggle}
          pageIds={staplePageIds || [`${page.documentId}:${page.pageNum}`]}
        />
      ) : null}
    </Fragment>
  )
}

export default DialogTitle;
