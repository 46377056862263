import { FC, Fragment } from 'react';
// Models
import { RootState } from 'app/store';
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectStaplePageIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import PageImage from 'app/components/PageImage';

type Props = {
  page: IPage | IPresenterPage;
};

const TabContentImage:FC<Props> = ({
  // Props
  page
}) => {
  const staplePageIds:string[] | null = useAppSelector((state:RootState) => selectStaplePageIds(state, {
    documentId: page.documentId,
    stapleId: page.staple?.id || null
  }));

  return (
    <Fragment>
      {!staplePageIds || !staplePageIds.length ? (
        <Box
          sx={{
            border: '1px solid rgba(0,0,0,0.08)',
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          <PageImage
            documentId={page.documentId}
            pageNum={page.pageNum}
            showPageNum={true}
          />
        </Box>
      ) : (
        staplePageIds.map((pageId:string, index:number) => {
          const [ documentId, pageNum ] = pageId.split(':').map(Number);
          return (
            <Box
              key={`page-pdf-item-staple-${index}`}
              sx={{
                border: '1px solid rgba(0,0,0,0.08)',
                borderRadius: 1,
                '& + &': {
                  marginTop: 4
                },
                overflow: 'hidden'
              }}
            >
              <PageImage
                documentId={documentId}
                pageNum={pageNum}
                showPageNum={true}
              />
            </Box>
          )
        })
      )}
    </Fragment>
  )
}

export default TabContentImage;
