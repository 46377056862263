import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IState, IDocumentExport } from './DMSDocumentsExports.models';
// Async
import { getDocumentsExports, createDocumentExport, resendDownloadLink } from './DMSDocumentsExports.async';

const initialState:IState = {
  documentsExports: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.DMSDocumentsExports,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get records export
    builder.addCase(getDocumentsExports.pending, (state) => {
      state.documentsExports = null;
    });
    builder.addCase(getDocumentsExports.fulfilled, (state, action:PayloadAction<IDocumentExport[]>) => {
      state.documentsExports = action.payload;
    });
    // Create records export
    builder.addCase(createDocumentExport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDocumentExport.fulfilled, (state, action:PayloadAction<IDocumentExport>) => {
      if ( state.documentsExports ){
        state.documentsExports = [action.payload, ...state.documentsExports];
      }
    });
    // Resend download link
    builder.addCase(resendDownloadLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resendDownloadLink.fulfilled, (state, action:PayloadAction<IDocumentExport>) => {
      if ( state.documentsExports ){
        state.documentsExports = state.documentsExports.map((documentExport:IDocumentExport) => {
          if ( documentExport.id === action.payload.id ) return action.payload;
          return documentExport;
        })
      }
    });

    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

// Actions
export const DMSDocumentsExportsActions = slice.actions;

export default slice.reducer;
