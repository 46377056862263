import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { replace } from "connected-react-router";
import { setUser } from '@sentry/react';
// Types
import BodyErrors from 'app/types/BodyErrors';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
// Async
import { login, logout } from 'app/store/Auth/Auth.async';
import { getMyUser } from 'app/store/Users/Users.async';
// Actions
import { AuthActions } from 'app/store/Auth/Auth.slice';
// Services
import IdempotencyKeyService from 'app/services/IdempotencyKey.service';
import WebSocketService from 'app/services/WebSocketService';
import LocalStorageService from 'app/services/LocalStorage.service';
import CookieService from 'app/services/Cookie.service';
// Utiliies
import { sortByName } from "app/utilities/SortBy";

import Mixpanel from 'app/services/Mixpanel.service';
import { parseToken } from 'app/utilities/Utilities';

// ToDO: To pass data between `pending` and `rejected` action.type
let loginData:any = null;

const AuthMiddleware:Middleware = ({ dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload, meta } = action;

  // Login user
  if ( type === login.pending.type ){
    loginData = {...(loginData || {}), ...meta.arg};
  }
  if ( type === login.fulfilled.type ){
    loginData = null;

    CookieService.clearCookie();
    LocalStorageService.setAuthCredential(payload);

    // Parse tokenAccess token, to get userId and pass it before login
    const tokenData = parseToken(payload.accessToken);
    const userId = tokenData.sub ? Number(tokenData.sub) : null;

    if ( userId ) Mixpanel.identify(userId);
    Mixpanel.track(MixpanelTracks.LogIn);

    dispatch(getMyUser({}));
  }
  if ( type === login.rejected.type ){
    if ( !loginData ) throw Error('Canno`t find login data or object is empty');

    const { error } = payload;

    const { username, password, accountId } = loginData;

    if ( error === BodyErrors.MfaRequired ){
      const { id:challengeId, ...otherBody } = payload.challenge;
      const nextData = { ...otherBody, challengeId, username, password };
      dispatch(AuthActions.setMultiFactoryData(nextData));
      dispatch(replace('/verification'));
      // return next(action);
    } else if ( error === BodyErrors.AccountRequired ){
      const { accounts, message } = payload;
      dispatch(AuthActions.setMultiAccountsData({
        accounts: [...accounts].sort((a, b) => sortByName(a, b, 'company')),
        message
      }));
      // return next(action);
    } else if ( error === BodyErrors.PasswordChangeRequired ){
      const { passwordChangeToken } = payload;
      const searchParams = new URLSearchParams();
      searchParams.append('token', passwordChangeToken);
      searchParams.append('email', username);
      if ( accountId ) searchParams.append('accountId', accountId);
      dispatch(replace(`/reset-password?${searchParams}`));
      // return next(action);
    } else {
      CookieService.clearCookie();
    }
  }

  // Logout user
  if ( type === logout.fulfilled.type ){
    IdempotencyKeyService.clearDuplicateKeys();
    WebSocketService.close();
    LocalStorageService.clearAuthCredential();

    setUser(null);

    Mixpanel.reset();
  }

  return next(action);
}

export default AuthMiddleware;
