enum Colors {
  Red = 'red',
  Purple = 'purple',
  DeepPurple = 'deepPurple',
  Indigo = 'indigo',
  Blue = 'blue',
  LightBlue = 'lightBlue',
  Cyan = 'cyan',
  Teal = 'teal',
  Green = 'green',
  Amber = 'amber',
  DeepOrange = 'deepOrange',
  Brown = 'brown',
  BlueGrey = 'blueGrey'
}

export default Colors;
