import config from '../../../../config';

import { FC, Fragment, useState } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { exportInvoicesToPDF } from 'app/store/BillingInvoices/BillingInvoices.async';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectTotalSentInvoices, selectBatchInvoicesAmount, selectBatchInvoicesIds } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import { Button, LoadingButton } from 'app/components/Mui';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';
// 
import BillingCheckFormDialog from './ACHPaymentFormDialog';
import BillingCardFormDialog from './CreditCardPaymentFormDialog';
// i18next
import { useTranslation } from 'react-i18next';

const BillingInvoicesBatchPayment:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const totalSentInvoices = useAppSelector(selectTotalSentInvoices);
  const batchInvoicesIds = useAppSelector(selectBatchInvoicesIds);
  const batchInvoicesAmount = useAppSelector(selectBatchInvoicesAmount);

  const [ dialogCheckOpen, setDialogCheckOpen ] = useState<boolean>(false);
  const [ dialogCardOpen, setDialogCardOpen ] = useState<boolean>(false);

  const [ loading, setLoading ] = useState(false);

  const toggleDialogCheck = () => setDialogCheckOpen((prevState:boolean) => !prevState);
  const toggleDialogCard = () => setDialogCardOpen((prevState:boolean) => !prevState);

  const handleExportInvoicesToPDF = async () => {
    setLoading(true);
    try {
      await dispatch(exportInvoicesToPDF(batchInvoicesIds)).unwrap();
      dispatch(BillingInvoicesActions.setInitialField('batchInvoicesIds'));
    } catch(error){} finally {
      setLoading(false);
    }
  }

  const handleClearAll = () => {
    dispatch(BillingInvoicesActions.setInitialField('batchInvoicesIds'));
  }
  const handleSelectAllVisible = () => {
    dispatch(BillingInvoicesActions.selectAllSentBatchInvoicesIds());
  }

  if ( !totalSentInvoices ) return null;
  return (
    <Fragment>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        mb: 4
      }}>
        {batchInvoicesIds.length ? (
          <Fragment>
            {config.domain === '.com' ? (
              <Button
                name="Bulk ACH payment"
                onClick={toggleDialogCheck}
                variant="contained"
                size="small"
              >{t('pages.adminPages.billingInvoicesPage.achPayment')}</Button>
            ) : null}
            <Button
              name="Bulk pay with credit card"
              onClick={toggleDialogCard}
              variant="contained"
              size="small"
            >{t('pages.adminPages.billingInvoicesPage.payWithCreditCard')}</Button>
            <LoadingButton
              name="Bulk export invoices to PDF"
              loading={loading}
              onClick={handleExportInvoicesToPDF}
              variant="contained"
              color="primary"
              size="small"
            >{t('pages.adminPages.billingInvoicesPage.exportInvoicesToPdf')}</LoadingButton>
          </Fragment>
        ) : null}
        <Box sx={{ flexGrow: 1 }} />
        {totalSentInvoices === batchInvoicesIds.length ? (
          <Button
            name="Clear all"
            onClick={handleClearAll}
            variant="outlined"
            size="small"
          >{t('labels.clearAll')}</Button>
        ) : (
          <Button
            name="Select all visible"
            onClick={handleSelectAllVisible}
            size="small"
            color="primary"
            variant="contained"
          >{t('pages.adminPages.billingInvoicesPage.selectAllVisible')}</Button>
        )}
      </Box>
      {batchInvoicesAmount ? (
        <Typography sx={{ mb: 4, display: 'block' }} variant="subtitle2">
          {t('pages.adminPages.billingInvoicesPage.batchInvoicesAmount', { batchInvoicesCount: batchInvoicesIds.length, batchInvoicesAmount: toNumberWithCommas(batchInvoicesAmount) })}
        </Typography>
      ) : null}
      {dialogCheckOpen ? (
        <BillingCheckFormDialog
          open={dialogCheckOpen}
          onClose={toggleDialogCheck}
        />
      ) : null}
      {dialogCardOpen ? (
        <BillingCardFormDialog
          open={dialogCardOpen}
          onClose={toggleDialogCard}
        />
      ) : null}
    </Fragment>
  )
}

export default BillingInvoicesBatchPayment;
