import { FC, Fragment, useCallback, useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
// Types
import FileTypes from 'app/types/FileTypes';
// Models
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
import { DMSUploadsActions } from 'app/store/DMSUploads/DMSUploads.slice';
// Selectors
import { selectCollections, selectAcceptMimeTypes } from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectFilesErrors } from 'app/store/DMSBatches/DMSBatches.selectors';
// Mui
import { Theme, Box, FormHelperText } from '@mui/material';
// Compoennts
import { Message } from 'app/components/Utilities';
// Utilities
import { getFileExtFromFileName } from 'app/utilities/Utilities';
// Constants
import { SUPPORTED_FILE_FORMATS, MAX_FILE_SIZE, MAX_FILE_NAME_LENGTH } from 'app/App.constants';
//
import FilePicker from './FilePicker';
import FileItem from './FileItem';

const Files:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const collections:ICollection[] | null = useAppSelector(selectCollections);
  const acceptMimeTypes = useAppSelector(selectAcceptMimeTypes);
  const filesErrors = useAppSelector(selectFilesErrors);

  const { control, register, formState:{ errors } } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files'
  });

  useEffect(() => {
    const encountered:any = {};

    for ( let i = 0; i < fields.length; i++ ){
      const { file } = fields[i] as any;
      if ( file.size && file.size > MAX_FILE_SIZE ){
        dispatch(DMSBatchesActions.addFileError({
          index: i,
          errorType: 'size'
        }));
        continue;
      }

      if ( encountered[file.name] ){
        encountered[file.name].push(i);
        if (encountered[file.name].length === 1) {
          dispatch(DMSBatchesActions.addFileError({
            index: i,
            duplicateFileIndexes: [encountered[file.name][0]],
            errorType: 'sameName'
          }));
          continue;
        } else {
          const duplicateFileIndexes = encountered[file.name].filter((index:number) => index !== i);
          dispatch(DMSBatchesActions.addFileError({
            index: i,
            duplicateFileIndexes,
            errorType: 'sameName'
          }));
          continue;
        }
      } else {
        encountered[file.name] = [i];
      }

      if ( file.name && file.name.length > MAX_FILE_NAME_LENGTH ){
        dispatch(DMSBatchesActions.addFileError({
          index: i,
          errorType: 'lengthName'
        }));
        continue;
      }
      dispatch(DMSBatchesActions.addFileError({ index: i, errorType: null }));
    }
    // eslint-disable-next-line
  }, [fields]);

  const onDrop = useCallback((files:any) => {
    for ( let file of files ){
      handleAdd(file);
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: acceptMimeTypes as any,
    onDrop,
    noClick: true
  });

  const handleAdd = (file:File) => {
    const fileFormat = getFileExtFromFileName(file.name);

    const newFile:any = {
      file,
      type: FileTypes.Document,
      fileFormat,
      comment: '',
      collectionId: ''
    };

    if ( !SUPPORTED_FILE_FORMATS.includes(fileFormat) ) return;

    if ( fileFormat && collections && collections.length ){
      let collectionId:string = '';
      for ( let collection of collections ){
        if (
          !collection.fileFormats ||
          !collection.fileFormats.length ||
          collection.fileFormats.includes(fileFormat)
        ){
          if ( !collectionId ){
            collectionId = collection.id.toString();
          } else {
            collectionId = '';
            break;
          }
        }
      }
      newFile['collectionId'] = collectionId;
    }

    append(newFile)
  }

  const handleRemove = (index:number) => () => {
    remove(index);

    dispatch(DMSBatchesActions.removeFileError(index));
    dispatch(DMSUploadsActions.removePreSignedUrl(index));
  }

  return (
    <Fragment>
      <input {...register('files', { required: true }) as any} type="hidden" />

      <Box
        {...getRootProps()}
        sx={{
          position: 'relative',
          minHeight: '560px',
          maxHeight: '720px',
          overflowY: 'auto',
          width: '100%',
          border: (theme:Theme) => isDragActive
            ? `1px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.divider}`
          ,
          borderRadius: 1,
          p: 4,
          my: 4
        }}
      >
        {/* @ts-ignore */}
        <input {...getInputProps()} />

        <FilePicker onOpen={open} onDrop={onDrop} />

        {filesErrors && filesErrors.some((err) => err) ? (
          <FormHelperText error>Some documents  couldn’t be uploaded. Check specific issues below.</FormHelperText>
        ) : null}

        {fields && fields.length ? (
          fields.map((field, index) => (
            <FileItem
              key={`file item ${field.id}`}
              fields={fields}
              field={field}
              index={index}
              onRemove={handleRemove(index)}
            />
          ))
        ) : (
          <Box sx={{ width: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Message text="Drag and drop your files or folders here" />
          </Box>
        )}
      </Box>
      {Boolean(errors.files) && !fields.length ? (
        <FormHelperText sx={{ mt: -2, mb: 4 }} error>{t('dialogs.batchCreate.filesRequired')}</FormHelperText>
      ) : null}
    </Fragment>
  )
}

export default Files;
