import { FC } from 'react';
// Models
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollections } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
// 
import CollectionItem from './CollectionItem';

const CollectionsList:FC = () => {
  const { t } = useTranslation('common');
  // State
  const collections:ICollection[] | null = useAppSelector(selectCollections);

  if ( !collections ) return <Loader />;
  if ( !collections.length ) return <Message text={t('pages.adminPages.collectionsPage.collections')} />;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {collections.map((collection:ICollection) => (
        <CollectionItem
          key={`collection item ${collection.id}`}
          collection={collection}
        />
      ))}
    </Box>
  )
}

export default CollectionsList;
