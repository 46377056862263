// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get } from 'app/utilities/HttpClient';

const _url:string = '/settings';

// Get general
export const getGeneralSettings = asyncThunkHandler(
  `${Reducers.Settings}/Get general settings`,
  async () => {
    const response:Response = await $get(`${_url}/general`);
    return response.json();
  }
);
