import { FC, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Mui
import { Box, Container } from '@mui/material';
// 
import TemplatesFilter from './TemplatesFilter';
import TemplatesList from './TemplatesList';
import TemplatesFab from './TemplatesFab';
import TemplatesSidebar from './TemplatesSidebar';
import { TemplatesActions } from 'app/store/Templates/Templates.slice';

const TemplatesPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(TemplatesActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'auto' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 22 }} maxWidth="sm">
          <TemplatesFilter />
          <TemplatesList />
        </Container>
        <TemplatesFab />
      </Box>
      <TemplatesSidebar />
    </Box>
  )
}

export default TemplatesPage;
