import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.billingOrders.create'),
  Update: i18n.t('notifications.billingOrders.update'),
  Complete: i18n.t('notifications.billingOrders.complete'),
  Cancel: i18n.t('notifications.billingOrders.cancel')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.billingOrders.create');
  Messages.Update = i18n.t('notifications.billingOrders.update');
  Messages.Complete = i18n.t('notifications.billingOrders.complete');
  Messages.Cancel = i18n.t('notifications.billingOrders.cancel');
});