// Types
import Reducers from 'app/types/Reducers';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './ExportTemplates.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';
import { downloadFile } from "app/utilities/Utilities";

const _url:string = '/exportTemplates';

// Get export templates
export const getExportTemplates = asyncThunkHandler(
  `${Reducers.ExportTemplates}/Get export templates`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);

// Get export template
export const getExportTemplate = asyncThunkHandler(
  `${Reducers.ExportTemplates}/Get export template`,
  async (exportTemplateId:number) => {
    const response:Response = await $get(`${_url}/${exportTemplateId}`);
    return response.json();
  }
);

// Create export template
export const createExportTemplate = asyncThunkHandler(
  `${Reducers.ExportTemplates}/Create export template`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));

    return response.json();
  }
);

// Update export template
export const updateExportTemplate = asyncThunkHandler(
  `${Reducers.ExportTemplates}/Update export template`,
  async ({ exportTemplateId, data }:{ exportTemplateId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${exportTemplateId}`, data);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));

    return response.json();
  }
);

// Delete export template
export const deleteExportTemplate = asyncThunkHandler(
  `${Reducers.ExportTemplates}/Delete export template`,
  async (exportTemplateId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${exportTemplateId}`);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));

    return fulfillWithValue(exportTemplateId);
  }
);

// Download template file
export const downloadExportTemplateFile = asyncThunkHandler(
  `${Reducers.ExportTemplates}/Download template file`,
  async (exportTemplateId:number, { fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/${exportTemplateId}/file`);

    downloadFile(response, { name: `template${exportTemplateId}`, ext: 'docx' });

    return fulfillWithValue(exportTemplateId);
  }
);

// Upload template file
export const uploadExportTemplateFile = asyncThunkHandler(
  `${Reducers.ExportTemplates}/Upload template file`,
  async ({ exportTemplateId, data }:{ exportTemplateId:number, data:any }, { dispatch, fulfillWithValue }) => {
    await $put(`${_url}/${exportTemplateId}/file`, data);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Upload
    }));

    return fulfillWithValue('Export template was uploaded');
  }
);

