import { ChangeEvent, FC, Fragment, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selectors
import { selectAccountCasesParams } from 'app/store/Cases/Cases.selectors';
import { selectCurrentAccount, selectInvoicingEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Box, Tooltip, MenuItem, Typography } from '@mui/material';
// Icons
import { Tune as TuneIcon } from '@mui/icons-material';
// Components
import { IconButton, Input, Select } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Service
import LocalStorageService from 'app/services/LocalStorage.service';
// 
import FilterPreferencesDialog from './FilterPreferencesDialog';

type Props = {
  currentStatus: CaseStatuses
}

const CasesFilter:FC<Props> = ({
  // Props
  currentStatus
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount = useAppSelector(selectCurrentAccount);
  const invoicingEnabled = useAppSelector(selectInvoicingEnabled);
  const params:any = useAppSelector(selectAccountCasesParams);

  const { open, toggle } = useToggle();

  // eslint-disable-next-line
  const debounceParams = useCallback(debounce(500, (name:string, value:any) => {
    const nextParams = { ...params, offset: 0, [name]: value };

    if ( currentAccount && (name === 'sort' || name === 'sort.priority.collectionIds') ){
      LocalStorageService.setCasesSorting(currentAccount.id, {
        'sort': nextParams['sort'],
        'sort.priority.collectionIds': nextParams['sort.priority.collectionIds']
      });
    }

    dispatch(CasesActions.setParams(nextParams));
    // eslint-disable-next-line
  }), [currentAccount, params]);

  const [ search, setSearch ] = useState('');

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if ( name === 'search' ) setSearch(value);
    debounceParams(name, value);
  }

  const handleClearSearch = () => {
    const nextParams = { ...params, search: '' };
    setSearch('');
    dispatch(CasesActions.setParams(nextParams));
  }

  const handleClose = (nextCollectionIds?:string) => {
    if ( typeof nextCollectionIds !== 'undefined' ){
      debounceParams('sort.priority.collectionIds', nextCollectionIds);
    }
    toggle();
  }

  const isSortingShown = currentStatus !== CaseStatuses.Waiting

  return (
    <Fragment>
      <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
        <Input
          label={t('pages.staffPages.casesPage.searchCase')} id="search" name="search" value={search}
          fullWidth size="small" margin="none"
          onChange={handleChange}
          onClear={handleClearSearch}
        />
        {currentStatus !== CaseStatuses.Pending && currentStatus !== CaseStatuses.Rejected ? (
          <Fragment>
            {invoicingEnabled ? (
              <Select
                sx={{
                  maxWidth: 160
                }}
                label={t('pages.staffPages.casesPage.invoiceStatus')} id="invoice.status" name="invoice.status" value={params['invoice.status']}
                fullWidth size="small" margin="none"
                onChange={handleChange}
                options={[
                  { id: 'all', name: t('labels.all') },
                  { id: 'draft', name: t('pages.staffPages.casesPage.notSent') },
                  { id: 'sent', name: t('pages.staffPages.casesPage.notPaid') }
                ]}
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : null}
            {isSortingShown ? (
              <Fragment>
                <Select
                  label={t('pages.staffPages.casesPage.sortBy')} id="sort" name="sort" value={params['sort']}
                  fullWidth size="small" margin="none"
                  onChange={handleChange}
                  options={[
                    {
                      id: 'document.createdOn desc',
                      name: 'Most recent document',
                      props: {
                        description: 'Cases with most recent documents will be shown first'
                      }
                    },
                    {
                      id: 'createdOn desc',
                      name: 'Most recent case',
                      props: {
                        description: 'Recently created cases will be shown first'
                      }
                    }
                  ]}
                  renderOptions={(option:IOption, index:number) => (
                    <MenuItem
                      sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                      key={`option item ${index} ${option.id}`}
                      value={option.id.toString()}
                    >
                      <Typography variant="subtitle1">{option.name}</Typography>
                      <Typography sx={{ maxWidth: 320, whiteSpace: 'normal' }} variant="caption">{option.props.description}</Typography>
                    </MenuItem>
                  )}
                />
                <Tooltip title="Sort preferences">
                  <IconButton
                    name="Settings"
                    onClick={toggle}
                  ><TuneIcon /></IconButton>
                </Tooltip>
              </Fragment>
            ) : null}
          </Fragment>
        ) : null}
      </Box>
      {open ? (
        <FilterPreferencesDialog
          open={open}
          onClose={handleClose}
        />
      ) : null}
    </Fragment>
  )
}

export default CasesFilter;
