import { FC, Fragment } from 'react';
// Models
import ISubscription from 'app/models/Subscription';
// Mui
import { Box, Divider, Paper, Typography } from '@mui/material';
// Components
import { InfoBlock, Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  accountBilling: ISubscription | null;
}

const Subscription:FC<Props> = ({
  // Props
  accountBilling
}) => {
  const { t } = useTranslation('common');

  if ( !accountBilling ) return <Loader />;
  if ( !accountBilling.subscription ) return <Message text={t('components.subscription.noAccountBillingSubscription')} />;

  const { subscriptionPackage, items = [] } = accountBilling.subscription;

  return (
    <Fragment>
      <Typography sx={{ mb: 2 }}>{t('components.subscription.package')}</Typography>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 4
        }}
        variant="outlined"
      >
        <Typography variant="subtitle1">{subscriptionPackage.name}</Typography>
        <Typography variant="body2" color="GrayText">{subscriptionPackage.description}</Typography>
      </Paper>
      {items.length ? (
        <Box sx={{ pt: 4 }}>
          <Typography sx={{ mb: 2 }}>{t('labels.services')}</Typography>
          {items.map((item:any, index:number) => (
            <Paper
              key={`service-item-${index}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                '& + &': { mt: 4 }
              }}
              variant="outlined"
            >
              <Typography variant="subtitle1">{item.service.name}</Typography>
              <Typography variant="body2" color="GrayText">{item.service.description}</Typography>
              <Divider sx={{ my: 2 }} />
              <Box display="flex" gap={4}>
                <InfoBlock label={t('labels.price')} value={`$${item.price}${item.service?.unit ? ` / ${item.service?.unit}` : ''}`} />
                {item.numberOfFreeOfChargeUnits ? (
                  <InfoBlock label={t('components.subscription.numberOfFreeOfChargeUnits')} value={item.numberOfFreeOfChargeUnits} />
                ) : null}
                {item.numberOfFreeOfChargeUnitsLeft ? (
                  <InfoBlock label={t('components.subscription.numberOfFreeOfChargeUnitsLeft')} value={item.numberOfFreeOfChargeUnitsLeft} />
                ) : null}
              </Box>
            </Paper>
          ))}
        </Box>
      ) : null}
    </Fragment>
  )
}

export default Subscription;
