import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import IUser from 'app/models/User';
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Selectors
import { selectUsers } from 'app/store/Users/Users.selectors';
import { selectDocumentsEntities } from 'app/store/DMSDocuments/DMSDocuments.selectors';

export const selectDocumentsExports = (state:RootState) => state[Reducers.DMSDocumentsExports].documentsExports;
export const selectLoading = (state:RootState) => state[Reducers.DMSDocumentsExports].loading;

export const selectDocumentsExportsUser = createSelector(
  [
    selectUsers,
    (_, props:{ userId:number }) => props
  ],
  (users:IUser[] | null, { userId }) => {
    if ( !users ) return null;
    return users.find((user:IUser) => user.id === userId) || null
  }
);

export const selectCollectionDocuments = createSelector(
  [
    selectDocumentsEntities,
    (_, props:{ documentIds:number[] }) => props
  ],
  ( documentEntities:Record<number, IDocument>, { documentIds }) => {
    const result:Record<number, IDocument[]> = {};
    for ( let documentId of documentIds ){
      const documentEntity = documentEntities[documentId];
      if ( !documentEntity ) continue;
      if ( result[documentEntity.collectionId] ){
        result[documentEntity.collectionId] = [...result[documentEntity.collectionId], documentEntity];
      } else {
        result[documentEntity.collectionId] = [documentEntity];
      }
    }
    return result;
  }
);
