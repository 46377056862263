import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Async
import { createFact, updateFact, deleteFact } from 'app/store/EpisodeFacts/EpisodeFacts.async';
// Selectors
import { selectInsuranceCase } from "app/store/Cases/Cases.selectors";
// Services
import Mixpanel from "app/services/Mixpanel.service";

const EpisodeFactsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.EpisodeFacts) ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    if ( type === createFact.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseEpisodeFactAdded, {
        caseId: Number(insuranceCase.id),
        factId: payload.id
      });
    }

    if ( type === updateFact.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseEpisodeFactUpdated, {
        caseId: Number(insuranceCase.id),
        factId: payload.id
      });
    }

    if ( type === deleteFact.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseEpisodeFactDeleted, {
        caseId: Number(insuranceCase.id),
        factId: payload
      });
    }
  }

  return next(action);
}

export default EpisodeFactsMiddleware;
