import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
import IEpisodeAuthor from "app/models/EpisodeAuthor";
// Async
import { createAuthor, updateAuthor } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Services
import Mixpanel from "app/services/Mixpanel.service";

const EpisodeAuthorsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type === createAuthor.fulfilled.type ){
    const author = payload as IEpisodeAuthor;

    Mixpanel.track(MixpanelTracks.AuthorCreate, {
      authorId: author.id
    });
  }

  if ( type === updateAuthor.fulfilled.type ){
    const author = payload as IEpisodeAuthor;

    Mixpanel.track(MixpanelTracks.AuthorUpdate, {
      authorId: author.id
    });
  }

  return next(action);
}

export default EpisodeAuthorsMiddleware;
