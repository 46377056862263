// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./BillingOrders.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put } from 'app/utilities/HttpClient';

const _url:string = '/billing/orders';

// Get orders
export const getOrders = asyncThunkHandler(
  `${Reducers.BillingOrders}/Get orders`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Create order
export const createOrder = asyncThunkHandler(
  `${Reducers.BillingOrders}/Create order`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update order
export const updateOrder = asyncThunkHandler(
  `${Reducers.BillingOrders}/Update order`,
  async ({ orderId, data }:{ orderId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${orderId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Complete order
export const completeOrder = asyncThunkHandler(
  `${Reducers.BillingOrders}/Complete order`,
  async (orderId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${orderId}/actions/complete`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Complete
    }));
    return response.json();
  }
);

// Cancel order
export const cancelOrder = asyncThunkHandler(
  `${Reducers.BillingOrders}/Cancel order`,
  async (orderId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${orderId}/actions/cancel`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Cancel
    }));
    return response.json();
  }
);
