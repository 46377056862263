import React from 'react';
// Mui
import {
  InputAdornment,
  TextField, TextFieldProps
} from '@mui/material';
// Icons
import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Clear as ClearIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

type InputProps = TextFieldProps & {
  onClear?: () => void;
};

const Input = React.forwardRef<any, InputProps>(({ onClear, ...otherProps }, ref) => {
  const { open, toggle } = useToggle();

  const extendedProps:TextFieldProps = {
    ...generateAttrForTesting('control', 'input', otherProps.name)
  };
  if ( otherProps.type === 'password' ){
    extendedProps['type'] = open ? 'text' : 'password';
    extendedProps['autoComplete'] = otherProps.autoComplete
      ? otherProps.type === 'password'
        ? isChrome ? 'new-password' : otherProps.autoComplete
        : otherProps.autoComplete
      : ''
    ;
    extendedProps['InputProps'] = {
      ...otherProps.InputProps,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            sx={{ mr: 1 }}
            name="Toggle password"
            onClick={toggle}
            edge="end"
            aria-label={open ? 'Hide password' : 'Show password'}
          >{open ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
        </InputAdornment>
      )
    }
  }
  if ( onClear ) {
    extendedProps['InputProps'] = {
      ...extendedProps['InputProps'],
      endAdornment: otherProps.value ? (
        <InputAdornment position="end">
          <IconButton
            // sx={{ mr: 1 }}
            name="Clear"
            aria-label="Clear"
            onClick={onClear}
            size="small"
          ><ClearIcon fontSize="small" /></IconButton>
        </InputAdornment>
      ) : null
    }
  }
  if ( otherProps.disabled ) {
    extendedProps['InputLabelProps'] = {
      ...otherProps['InputLabelProps'],
      shrink: true,
    }
  }

  return (
    <TextField
      {...otherProps}
      {...extendedProps}
      ref={ref}
      fullWidth={typeof otherProps.fullWidth === 'undefined' ? true : otherProps.fullWidth}
      margin={otherProps.margin || 'normal'}
    />
  );
});

export default Input;
