import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Async
import {
  getReportSections,
  getReportSection,
  createReportSection, updateReportSection, deleteReportSection
} from './ReportSections.async';

interface IState {
  reportSections: any[] | null;
  reportSection: any;
  loading: boolean;
}

const initialState:IState = {
  reportSections: null,
  reportSection: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.ReportSections,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get report sections
    builder.addCase(getReportSections.pending, (state) => {
      state.reportSections = null;
    });
    builder.addCase(getReportSections.fulfilled, (state, action:PayloadAction<any[]>) => {
      state.reportSections = action.payload;
    });
    // Get report section
    builder.addCase(getReportSection.pending, (state) => {
      state.reportSection = null;
    });
    builder.addCase(getReportSection.fulfilled, (state, action:PayloadAction<any>) => {
      state.reportSection = action.payload;
    });
    // Create report section
    builder.addCase(createReportSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createReportSection.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.reportSections ){
        state.reportSections = [...state.reportSections, action.payload]
      }
    });
    // Update report section
    builder.addCase(updateReportSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateReportSection.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.reportSections ){
        state.reportSections = state.reportSections.map((reportSection:any) => {
          if ( reportSection.id === action.payload.id ) return action.payload;
          return reportSection;
        })
      }
      if ( state.reportSection && state.reportSection.id === action.payload.id ){
        state.reportSection = action.payload;
      }
    });
    // Delete report section
    builder.addCase(deleteReportSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteReportSection.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.reportSections ){
        state.reportSections = state.reportSections.filter((reportSection:any) => {
          return reportSection.id !== action.payload
        })
      }
    });

    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

// Actions
export const ReportSectionsActions = slice.actions;

export default slice.reducer;
