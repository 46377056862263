import config from 'config';

import store from 'app/store';
// Actions
import { AppUIStatusActions } from 'app/store/AppUIStatus/AppUIStatus.slice';

declare global {
  interface Window {
    triggerFakeRequest: (status:number) => void;
  }
}

const fakeRequest = (status:number): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if ( status === 200 ) return resolve();
      return reject(new Error(`Fake request with status: ${status}`));
    }, 500);
  });
};

const triggerFakeRequest = async (status:number) => {
  store.dispatch(AppUIStatusActions.setRequestStatusCode(null));
  try {
    await fakeRequest(status);
  } catch (error) {
    console.error('Error occurred:', error);
    // Dispatch the error to the Redux store
    store.dispatch(AppUIStatusActions.setRequestStatusCode(status));
  }
}

if ( !config.production ){
  window.triggerFakeRequest = triggerFakeRequest;
}

