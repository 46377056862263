import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import Reducers from "app/types/Reducers";
// Async
import {
  getInvoices, createInvoice, updateInvoice, deleteInvoice,
  payInvoice, pushInvoice, sendInvoice,
  getServiceItems
} from './Invoices.async';

interface IState {
  invoices: any;
  serviceItems: any;
  loading: boolean;
};

const initialState:IState = {
  invoices: null,
  serviceItems: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.Invoices,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get invoices
    builder.addCase(getInvoices.pending, (state) => {
      state.invoices = null;
    });
    builder.addCase(getInvoices.fulfilled, (state, action:PayloadAction<any>) => {
      state.invoices = action.payload;
    });
    // Create invoice
    builder.addCase(createInvoice.pending, (state) => {
      state.loading = true
    });
    builder.addCase(createInvoice.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.invoices ){
        state.invoices = [...state.invoices, action.payload];
      }
    });
    // Update invoice
    builder.addCase(updateInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.map((invoice:any) => {
          if ( invoice.id === action.payload.id ) return action.payload;
          return invoice;
        })
      }
    });
    // Delete invoice
    builder.addCase(deleteInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteInvoice.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.filter((invoice:any) => {
          return invoice.id !== action.payload
        })
      }
    });
    // Send invoice
    builder.addCase(sendInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendInvoice.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.map((invoice:any) => {
          if ( invoice.id === action.payload.id ) return action.payload;
          return invoice;
        })
      }
    });
    // Pay invoice
    builder.addCase(payInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(payInvoice.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.map((invoice:any) => {
          if ( invoice.id === action.payload.id ) return action.payload;
          return invoice;
        })
      }
    });
    // Push invoice
    builder.addCase(pushInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(pushInvoice.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.invoices ){
        state.invoices = state.invoices.map((invoice:any) => {
          if ( invoice.id === action.payload.id ) return action.payload;
          return invoice;
        })
      }
    });
    // Get invoices
    builder.addCase(getServiceItems.pending, (state) => {
      state.serviceItems = null;
    });
    builder.addCase(getServiceItems.fulfilled, (state, action:PayloadAction<any>) => {
      state.serviceItems = action.payload;
    });
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false;
      }
    )
  }
});

export const InvoicesActions = slice.actions;

export default slice.reducer;
