import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IState, IBatch, IFileError } from "./DMSBatches.models";
import { IDocument } from "app/store/DMSDocuments/DMSDocuments.models";
// Async
import { getBatches, createBatch, validateBatch, patchBatch, deleteCoverLetter } from './DMSBatches.async';
// Constants
import { MAX_FILE_NAME_LENGTH } from "app/App.constants";

const initialState:IState = {
  ids: null,
  entities: {},
  filter: {
    collectionId: undefined,
    search: ''
  },
  loading: false,
  // ToDO
  filesErrors: []
};

const slice = createSlice({
  name: Reducers.DMSBatches,
  initialState,
  reducers: {
    // Add batch
    addBatch: (state, action:PayloadAction<IBatch>) => {
      const batch = action.payload;
      if ( state.ids && !state.ids.includes(batch.id) ){
        state.ids = [...state.ids, batch.id];
      }
      const batchEntity = state.entities[batch.id];
      const documentIds = batch.documents
        ? batch.documents.map((document:IDocument) => document.id)
        : []
      ;
      if ( batchEntity ){
        state.entities[batchEntity.id] = {
          ...batchEntity,
          documentIds: [ ...batchEntity.documentIds, ...documentIds ]
        }
      } else {
        state.entities[batch.id] = { ...batch, documentIds };
      }
    },
    addFileError: (state, action:PayloadAction<{ index:number, errorType:IFileError['errorType'] | null; duplicateFileIndexes?:number[] }>) => {
      const { index, errorType, duplicateFileIndexes = [] } = action.payload;
      state.filesErrors[index] = !errorType
        ? null
        : {
            errorType,
            errorMessage: errorType === 'sameName'
              ? 'Document with same document name already added'
              : errorType === 'lengthName'
                ? `The document name is too long. Rename the Document with less than ${MAX_FILE_NAME_LENGTH} characters`
                : errorType === 'size'
                  ? 'Document exceeds max document size'
                  : 'Undefined error'
            ,
            duplicateFileIndexes,
            ui: true
          };
    },
    removeFileError: (state, action:PayloadAction<number>) => {
      state.filesErrors = state.filesErrors.filter((_:any, i:number) => i !== action.payload);
    },
    // Default
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get document batches
    builder.addCase(getBatches.pending, (state) => {
      state.ids = null;
      state.entities = {};
    });
    builder.addCase(getBatches.fulfilled, (state, action:PayloadAction<IBatch[]>) => {
      const entities = action.payload.reduce((acc:Record<string, IBatch>, cur:IBatch) => {
        // acc[cur.id] = cur;
        // Should be removed once it will be fixed on BE
        acc[cur.id] = {
          ...cur,
          documentIds: cur.documents
            ? cur.documents.map((document:IDocument) => document.id)
            : []
        };
        return acc;
      }, {});

      state.ids = Object.keys(entities);
      state.entities = entities;
    });
    // Create batch
    builder.addCase(createBatch.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(createBatch.fulfilled, (state, action:PayloadAction<IBatch>) => {
      if ( state.ids ){
        const batch = action.payload;
        state.ids = [...state.ids, batch.id];
        // state.entities[batch.id] = batch;
        // Should be removed once it will be fixed on BE
        state.entities[batch.id] = {
          ...batch,
          documentIds: batch.documents
            ? batch.documents.map((document:IDocument) => document.id)
            : []
        }
      }
    })
    // Validate batch
    builder.addCase(validateBatch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(validateBatch.fulfilled, (state, action:PayloadAction<(IFileError | null)[]>) => {
      state.filesErrors = action.payload;
    });
    // Patch document batches
    builder.addCase(patchBatch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchBatch.fulfilled, (state, action:PayloadAction<any>) => {
      const { id, ...otherData } = action.payload;
      const batch = state.entities[id];
      state.entities[id] = {...batch, ...otherData};
    });
    // Delete conver letter
    builder.addCase(deleteCoverLetter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCoverLetter.fulfilled, (state, action:PayloadAction<string>) => {
      const id = action.payload;
      const batch = state.entities[id];
      const { coverLetterFile, ...otherBatchData } = batch;
      state.entities[id] = otherBatchData;
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  }
});

// Actions
export const DMSBatchesActions = slice.actions;

export default slice.reducer;