import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";

interface IState {
  dialogName: string | null;
  dialogProps: any;
};

const initialState:IState = {
  dialogName: null,
  dialogProps: {}
};

const slice = createSlice({
  name: Reducers.AppUIDialog,
  initialState,
  reducers: {
    showDialog: (state:IState, action:PayloadAction<{ dialogName:string, dialogProps:any }>) => {
      const { dialogName, dialogProps } = action.payload;
      state.dialogName = dialogName;
      state.dialogProps = dialogProps;
    },
    hideDialog: (state:IState) => {
      state.dialogName = initialState['dialogName'];
      state.dialogProps = initialState['dialogProps'];
    },

    setInitial: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  }
});

export const AppUiDialogActions = slice.actions;

export default slice.reducer;
