import { ChangeEvent, FC, Fragment, useState } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMultiAccountsData } from 'app/store/Auth/Auth.selectors';
// Mui
import {
  RadioGroup, FormControlLabel, Radio,
  Typography
} from '@mui/material';
// Component
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';


type Props = {
  open: boolean;
  onClose: (accountId?:number) => void;
};

const MultiAccountsDialog:FC<Props> = ({
  // Props
  open, onClose,
}) => {
  const { t } = useTranslation('common');
  // State
  const multiAccountData = useAppSelector(selectMultiAccountsData);

  const [ accountId, setAccountId ] = useState<number | null>(null);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setAccountId(Number(event.target.value));
  };

  const handleClose = () => onClose();

  const handleUse = () => {
    if ( !accountId ) return;

    onClose(accountId);
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel multi accounts dialog"
        onClick={handleClose}
      >{t('labels.close')}</Button>
      <Button
        name="Use multi accounts dialog"
        disabled={!accountId}
        onClick={handleUse}
        variant="contained"
        color="primary"
      >{t('dialogs.multiAccounts.use')}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title={t('dialogs.multiAccounts.chooseYourCompany')}
      actions={actions}
    >
      {multiAccountData.message ? (
        <Typography variant="subtitle1">{multiAccountData.message}</Typography>
      ) : null}
      <RadioGroup
        name="accountId"
        value={accountId}
        onChange={handleChange}
      >
        {multiAccountData.accounts.map((account:any) => (
          <FormControlLabel
            key={`Account item ${account.id}`}
            value={account.id}
            control={<Radio color="primary" />}
            label={account.company}
          />
        ))}
      </RadioGroup>
    </Dialog>
  )
}

export default MultiAccountsDialog;
