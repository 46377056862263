import React from 'react';
// Models
import { ITerms } from 'app/store/Terms/Terms.models';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTermsAndConditions } from 'app/store/Terms/Terms.selectors';
// Mui
import { Paper, List } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import TermsListItem from './TermsListItem';
// i18next
import { useTranslation } from 'react-i18next';

const TermsList:React.FC = () => {
  const { t } = useTranslation('common');
  // State
  const termsAndConditions:ITerms[] | null = useAppSelector(selectTermsAndConditions);

  if ( !termsAndConditions ) return <Loader />;
  if ( !termsAndConditions.length ) return <Message text={t('pages.adminPages.termsPage.noTermsAndConditions')} />;
  return (
    <Paper variant="outlined">
      <List>
        {termsAndConditions.map((terms:ITerms) => (
          <TermsListItem
            key={`term and condition item ${terms.id}`}
            terms={terms}
          />
        ))}
      </List>
    </Paper>
  )
}

export default TermsList;
