import { FC, Fragment } from 'react';
// Models
import ILabel from 'app/models/Label';
import { IJob } from 'app/models/LabellingTool'
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectJobs } from 'app/store/LabellingToolJobs/LabellingToolJobs.selectors';
// Mui
import { Paper, List, ListSubheader } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities'
// 
import LabellingJobsListItem from './LabellingJobsListItem';
// i18next
import { useTranslation } from 'react-i18next';

const LabellingJobsList:FC = () => {
  const { t } = useTranslation('common');
  // State
  const jobs:IJob[] | null = useAppSelector(selectJobs);

  if ( !jobs ) return <Loader />;
  if ( !jobs.length ) return <Message text={t('layouts.labellingToolsSidebar.noJobsFound')} />;
  return (
    <Fragment>
      {jobs.map((job:IJob) => (
        <Paper
          key={`Job list ${job.id}`}
          sx={{
            '& + &': { mt: 4 }
          }}
        >
          <List
            subheader={
              <ListSubheader
                sx={{
                  bgcolor: 'rgba(238,238,238)',
                  color: 'rgba(0,0,0,0.6)'
                }}
              >{job.name}</ListSubheader>
            }
          >
            {job.labels.map((label:ILabel) => (
              <LabellingJobsListItem
                key={`Job list ${job.id} label item ${label.name}`}
                jobId={job.id}
                label={label}
              />
            ))}
          </List>
        </Paper>
      ))}
    </Fragment>
  )
}

export default LabellingJobsList;
