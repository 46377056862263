import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAcceptMimeTypes } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Button } from 'app/components/Mui';

type Props = {
  onOpen: () => void;
  onDrop: (files:any) => void;
}

const FilePicker:FC<Props> = ({
  // Props
  onOpen, onDrop
}) => {
  const { t } = useTranslation('common');

  // State
  const acceptMimeTypes = useAppSelector(selectAcceptMimeTypes);

  const { getRootProps, getInputProps, open:openDropzone } = useDropzone({
    noDragEventsBubbling: true,
    accept: acceptMimeTypes as any,
    onDrop,
    useFsAccessApi: false,
    noClick: true
  });

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
      <Button
        name="Add files"
        onClick={onOpen}
        color="primary"
        variant="contained"
      >{t('dialogs.batchCreate.addFiles')}</Button>
      <Box {...getRootProps()}>
        {/* @ts-ignore */}
        <input {...getInputProps({ directory: 'true', webkitdirectory: 'true' })} />
        <Button
          name="Add folders"
          onClick={openDropzone}
          color="primary"
          variant="contained"
        >Add folder(s)</Button>
      </Box>
    </Box>
  )
}

export default FilePicker;
