import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';
// Models
import IConversation, { IMember } from "app/models/Conversation";
// Async
import {
  getUnseenConversations,
  getConversations, getConversation,
  createConversation, updateConversation,
  inviteMember, kickMember, markAsRead
} from './Conversations.async';

interface IState {
  unseenConversations: {
    ids: number[] | null;
    entities: Record<number, IConversation>;
  };

  conversations: IConversation[] | null;
  conversation: IConversation | null;
  loading: boolean;
};

const initialState:IState = {
  unseenConversations: {
    ids: null,
    entities: {}
  },

  conversations: null,
  conversation: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.Conversations,
  initialState,
  reducers: {
    updateLastMessage: (state, action:PayloadAction<{ conversationId:number, lastMessage:{ text:string, createdOn:string } }>) => {
      const { conversationId, lastMessage } = action.payload;
      if ( state.conversations ){
        state.conversations = state.conversations.map((conversation:IConversation) => {
          if ( conversation.id === conversationId ) return { ...conversation, lastMessage };
          return conversation;
        });
      }
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder){
    // Get unseen conversations
    builder.addCase(getUnseenConversations.pending, (state) => {
      state.unseenConversations.ids = null;
      state.unseenConversations.entities = {};
    });
    builder.addCase(getUnseenConversations.fulfilled, (state, action:PayloadAction<IConversation[]>) => {
      const entities = action.payload.reduce((acc:Record<number, IConversation>, cur:IConversation) => {
        acc[cur.id] = cur;
        return acc;
      }, {});

      state.unseenConversations.ids = Object.keys(entities).map(Number);
      state.unseenConversations.entities = entities;

      if ( state.conversation && entities[state.conversation.id] ){
        state.conversation = {...state.conversation, me: entities[state.conversation.id].me };
      }
    });
    // Get conversations
    builder.addCase(getConversations.pending, (state) => {
      state.conversations = null;
    });
    builder.addCase(getConversations.fulfilled, (state, action:PayloadAction<IConversation[]>) => {
      state.conversations = action.payload;
    });
    // Get conversation
    builder.addCase(getConversation.pending, (state) => {
      state.conversation = null;
    });
    builder.addCase(getConversation.fulfilled, (state, action:PayloadAction<IConversation>) => {
      state.conversation = action.payload;
    });
    // Create conversation
    builder.addCase(createConversation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createConversation.fulfilled, (state, action:PayloadAction<IConversation>) => {
      if ( state.conversations ){
        state.conversations = [...state.conversations, action.payload];
      }
    });
    // Update conversation
    builder.addCase(updateConversation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateConversation.fulfilled, (state, action:PayloadAction<IConversation>) => {
      if ( state.conversations ){
        state.conversations = state.conversations.map((conversation:IConversation) => {
          if ( conversation.id === action.payload.id ) return action.payload;
          return conversation;
        })
      }
      if ( state.conversation && state.conversation.id === action.payload.id ){
        state.conversation = action.payload;
      }
    });

    // Invite member
    builder.addCase(inviteMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(inviteMember.fulfilled, (state, action:PayloadAction<IMember>) => {
      if ( state.conversation ){
        state.conversation = {
          ...state.conversation,
          members: [
            ...state.conversation.members || [],
            action.payload
          ]
        }
      }
    });
    // Kick member
    builder.addCase(kickMember.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.conversation ){
        state.conversation = {
          ...state.conversation,
          members: state.conversation.members
            ? state.conversation.members.filter((member:IMember) => member.id !== action.payload)
            : state.conversation.members
        }
      }
    });
    // Mark as read
    builder.addCase(markAsRead.fulfilled, (state, action:PayloadAction<{ conversationId:number, body:any }>) => {
      const { conversationId, body } = action.payload;
      if ( state.unseenConversations.ids ){
        state.unseenConversations.ids = state.unseenConversations.ids.filter((id:number) => id !== conversationId);
      }
      if ( state.conversations ){
        state.conversations = state.conversations.map((conversation:IConversation) => {
          if ( conversation.id === conversationId ) return {
            ...conversation,
            me: { ...conversation.me, ...body }
          };
          return conversation;
        });
      }
      if ( state.conversation && state.conversation.id === conversationId ){
        state.conversation = {
          ...state.conversation,
          me: {
            ...state.conversation.me,
            ...body
          }
        };
      }
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false;
      }
    );
  }
});

export const ConversationsActions = slice.actions;

export default slice.reducer;
