import { FC } from 'react';
// Models
import { IMessage } from 'app/models/ChatAI';
// Mui
import { Box } from '@mui/material';
// 
import MessageProvider from './Message.context';
import MessageText from './MessageText';
import RefineMessage from './RefineMessage';

type Props = {
  messages: IMessage[];
}

const MessageItem:FC<Props> = ({
  // Props
  messages
}) => {
  const [ message, ...refineMessages ] = messages;

  const isHumanMessage = message.type === 'human';

  return (
    <MessageProvider refineMessages={refineMessages}>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}>
        {isHumanMessage ? (
          <Box sx={{
            mb: refineMessages.length ? 4 : 0
          }}><MessageText message={message} /></Box>
        ) : null}
        {refineMessages.length ? (
          <RefineMessage />
        ) : !isHumanMessage ? (
          <RefineMessage message={message} />
        ) : null}
      </Box>
    </MessageProvider>
  )
}

export default MessageItem;
