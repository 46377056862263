import { FC, memo } from 'react';
import dayjs from 'dayjs';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectCalendarDataByDateString } from 'app/store/currentUser/currentUserSelectors';
// Service
import { IDay } from 'app/services/CalendarService';
// Mui
import { Box } from '@mui/material';
// 
import CalendarDayEpisode from './CalendarDayEpisode';

type Props = {
  day: IDay;
}

const CalendarDay:FC<Props> = ({
  // Props
  day
}) => {
  const today = dayjs();
  // State
  const episodes = useSelector((state:any) => selectCalendarDataByDateString(state, { dateString: day.dateString }));

  const dayIsToday = dayjs(day.dateString).isSame(today, 'day');

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '14.28%',
      minHeight: '16.66%',
      borderLeft: '1px solid #DCDCDC',
      borderTop: '1px solid #DCDCDC',
      bgcolor: day.isCurrentMonth ? 'white' : '#fafafa',
      '&:nth-of-type(1)': {
        borderLeft: 'none'
      },
      '&:nth-of-type(7n+8)': {
        borderLeft: 'none'
      }
    }}>
      <Box sx={{ textAlign: 'left', padding: '4px 4px 0' }}>
        <Box
          component="span"
          sx={{
            display: day.isCurrentMonth ? 'inline-block' : 'none',
            width: '24px',
            height: '24px',
            bgcolor: dayIsToday ? '#6464E6' : 'transparent',
            borderRadius: '50%',
            color: dayIsToday ? 'white' : 'initial',
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'center',
            lineHeight: '24px'
          }}
        >{day.day}</Box>
      </Box>
      <Box sx={{
        display: day.isCurrentMonth ? 'block' : 'none',
        flexGrow: 1,
        position: 'relative'
      }}>
        {episodes && episodes.length ? (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <Box sx={{
              position: 'relative',
              top: 0, left: 0,
              width: '100%', height: '100%',
              padding: '4px',
              overflowY: 'auto'
            }}>
              {episodes.map((episode:any) => (
                <CalendarDayEpisode
                  key={`${day.dateString}-episode-item-${episode.id}`}
                  episode={episode}
                />
              ))}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default memo(CalendarDay);
