// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./BillingServices.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/billing/services';

// Get services
export const getServices = asyncThunkHandler(
  `${Reducers.BillingServices}/Get services`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);

// Create service
export const createService = asyncThunkHandler(
  `${Reducers.BillingServices}/Create service`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update service
export const updateService = asyncThunkHandler(
  `${Reducers.BillingServices}/Update service`,
  async ({ serviceId, data }:{ serviceId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${serviceId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Delete service
export const deleteService = asyncThunkHandler(
  `${Reducers.BillingServices}/Delete service`,
  async (serviceId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${serviceId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(serviceId);
  }
);
