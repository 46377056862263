import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// Models
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deletePrompt } from 'app/store/AIChatPrompts/AIChatPrompts.async';
// Selectors
import { selectLoading } from 'app/store/AIChatPrompts/AIChatPrompts.selectors';
// Mui
import {
  Theme, Box, Typography,
  ListItem, ListItemText
} from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import Labels from 'app/components/Labels';
import { IconButton } from 'app/components/Mui';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
import AIChatPromptFormDialog from 'app/dialogs/AIChatPromptFormDialog';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';

type Props = {
  aiChatPrompt: IPrompt;
}

const PromptItem:FC<Props> = ({
  // Props
  aiChatPrompt
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deletePrompt(aiChatPrompt.id));
  }

  return (
    <Fragment>
      <ListItem
        sx={{
          '& + &': {
            borderTop: '1px solid rgba(0,0,0,0.08)'
          }
        }}
        secondaryAction={
          <Fragment>
            <IconButton
              name={`Prompt dropdown ${aiChatPrompt.id}`}
              aria-label="Prompt item dropdown button"
              onClick={openMenu}
            ><MoreVertIcon /></IconButton>
            <Menu>
              <MenuItem
                name={`Edit prompt ${aiChatPrompt.id}`}
                onClick={toggle}
              >{t('labels.edit')}</MenuItem>
              <MenuItem
                name={`Delete prompt ${aiChatPrompt.id}`}
                sx={{
                  color: (theme:Theme) => theme.palette.error.main
                }}
                onClick={toggleConfirmationDialog}
              >{t('labels.delete')}</MenuItem>
            </Menu>
          </Fragment>
        }
      >
        <ListItemText primary={
          <Fragment>
            <Typography
              display="block"
              variant="subtitle2"
              component="span"
            >{aiChatPrompt.title}</Typography>
            {aiChatPrompt.labels ? (
              <Box sx={{ pt: 2 }}>
                <Labels id={`prompt item labels ${aiChatPrompt.id}`} labels={aiChatPrompt.labels} />
              </Box>
            ) : null}
          </Fragment>
        } />
      </ListItem>
      {open ? (
        <AIChatPromptFormDialog
          open={open}
          onClose={toggle}
          aiChatPrompt={aiChatPrompt}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.adminPages.aiChatPromptsPage.confirmationDialog.title')}
          content={(
            <Trans
              t={t} i18nKey="pages.adminPages.aiChatPromptsPage.confirmationDialog.content"
              components={{ strong: <strong /> }}
              values={{ promptTitle: aiChatPrompt.title }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default PromptItem
