import { forwardRef, useState, useEffect, ReactNode, HTMLAttributes } from 'react';
// Models
import IOption, { IOption_V2 } from 'app/models/Option';
// Mui
import {
  Autocomplete as MuiAutocomplete,
  TextField, TextFieldProps
} from '@mui/material';

type Props = Omit<TextFieldProps, 'onChange' | 'onInputChan'> & {
  options: IOption[];
  onChange: (nextValue:number | string | null) => void;
  renderOptions?: (props:HTMLAttributes<HTMLLIElement>, option:IOption) => ReactNode;
};

export const Autocomplete = forwardRef<any, Props>(({
  value, options, onChange, renderOptions = (props, option) => (
    <li {...props} key={option.id}>{option.name}</li>
  ), ...props
}, ref) => {
  const [ selectedOption, setSelectedOption ] = useState<IOption | null>(null);

  useEffect(() => {
    return () => {
      setSelectedOption(null);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( value && options ){
      const foundedOption:IOption | undefined = options.find((option:IOption) => option.id === value);
      setSelectedOption(foundedOption || null);
    } else {
      setSelectedOption(null);
    }
    // eslint-disable-next-line
  }, [value, options]);

  const handleChange = (_:any, nextOption:IOption | null) => {
    setSelectedOption(nextOption);
    onChange(nextOption?.id || null);
  }

  return (
    <MuiAutocomplete
      ref={ref}
      fullWidth={props.fullWidth || true}
      value={selectedOption}
      options={options}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          fullWidth
          margin={props.margin || 'normal'}
        />
      )}
      getOptionLabel={(option) => option.name}
      renderOption={renderOptions}
      freeSolo={false}
      disabled={props.disabled || false}
    />
  )
});

// Multiple autocomplete
type MultipleAutocompleteProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: (string | number)[];
  options: IOption_V2[] | null;
  onChange: (nextValue:(string | number)[]) => void;
};

export const MultipleAutocomplete = forwardRef<any, MultipleAutocompleteProps>(({
  value, options, onChange, ...props
}, ref) => {
  const [ selectedOption, setSelectedOption ] = useState<IOption_V2[]>([]);

  useEffect(() => {
    return () => {
      setSelectedOption([]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( value.length && options ){
      const filteredOptions = options.filter((option:IOption_V2) => value.includes(option.value));
      setSelectedOption(filteredOptions);
    } else {
      setSelectedOption([]);
    }
    // eslint-disable-next-line
  }, [value, options]);

  const handleChange = (_:any, nextOptions:IOption_V2[] | undefined) => {
    setSelectedOption(nextOptions || []);
    onChange(nextOptions ? nextOptions.map((option:IOption_V2) => option.value) : []);
  }

  return (
    <MuiAutocomplete
      ref={ref}
      multiple={true}
      value={selectedOption}
      options={options || []}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          fullWidth
          margin={props.margin || 'normal'}
          disabled={!options}
        />
      )}
      getOptionLabel={(option) => option.label}
      freeSolo={false}
      filterSelectedOptions={true}
    />
  )
});
