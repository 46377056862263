import { FC } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAdditionalToolsShown } from 'app/store/ui/uiSelectors';
// Mui
import { Box, Container } from '@mui/material';
// Components
import ColumnSwitcher from 'app/components/ColumnSwitcher';
// 
import Tags from './Tags';
import Colors from './Colors';
import HaveDuplicateInWorkspace from './HaveDuplicateInWorkspace';

const FilesFilter:FC = () => {
  // State
  const toolsShown = useAppSelector((state:RootState) => selectAdditionalToolsShown(state, {
    toolsType: 'files'
  }));

  const emptyList = toolsShown.filter((t:string) => t !== 'go_to_page' && t !== 'go_to_last_opened_page');

  if ( !emptyList.length ) return <Box sx={{ pt: 4 }} />;
  return (
    <Container sx={{ pt: 4, mb: 4 }} maxWidth={false}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: 4, rowGap: 2 }}>
        {toolsShown.includes('number_of_columns') ? (
          <ColumnSwitcher />
        ) : null}
        {toolsShown.includes('tags') ? (
          <Box><Tags /></Box>
        ) : null}
        {toolsShown.includes('colors') ? (
          <Box><Colors /></Box>
        ) : null}
        {toolsShown.includes('have_duplicate_in_workspace') ? (
          <HaveDuplicateInWorkspace />
        ) : null}
      </Box>
    </Container>
  )
}

export default FilesFilter;
