import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';
// Models
import IClientAccount from 'app/models/ClientAccount';
import IClientAccountUser from 'app/models/ClientAccountUser';
// Async
import { getAccounts, createAccount, updateAccount, getAccountUsers } from './Clients.async';

interface IState {
  accounts: IClientAccount[] | null;
  accountUsers: IClientAccountUser[] | null;
  filter: {
    search: string;
  }
  loading: boolean;
}

const initialState:IState = {
  accounts: null,
  accountUsers: null,
  filter: {
    search: ''
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.Clients,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get client accounts
    builder.addCase(getAccounts.pending, (state) => {
      state.accounts = null;
    })
    builder.addCase(getAccounts.fulfilled, (state, action:PayloadAction<IClientAccount[]>) => {
      state.accounts = action.payload;
    })
    // Create client account
    builder.addCase(createAccount.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createAccount.fulfilled, (state, action:PayloadAction<IClientAccount>) => {
      if ( state.accounts ){
        state.accounts.push(action.payload);
      }
    })
    // Update client account
    builder.addCase(updateAccount.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateAccount.fulfilled, (state, action:PayloadAction<IClientAccount>) => {
      if ( state.accounts ){
        state.accounts = state.accounts.map((account:IClientAccount) => {
          if ( account.id === action.payload.id ) return action.payload;
          return account;
        })
      }
    })
    // Fetch client account users
    builder.addCase(getAccountUsers.pending, (state) => {
      state.accountUsers = null;
    });
    builder.addCase(getAccountUsers.fulfilled, (state, action:PayloadAction<IClientAccountUser[]>) => {
      state.accountUsers = action.payload;
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const ClientsActions = slice.actions;

export default slice.reducer;