import { FC, Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getExportTemplates } from 'app/store/ExportTemplates/ExportTemplates.async';
import { getTeams } from 'app/store/Teams/Teams.async';
// Selectors
import { selectHcpcsCodesEnabled, selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectExportTemplatesAsOptions } from 'app/store/ExportTemplates/ExportTemplates.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
// Mui
import { Box, FormHelperText, Typography, Divider } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Autocomplete';
import { Input, Select, DesktopDatePicker, DesktopTimePicker } from 'app/components/Mui';
// Utilieis
import { isRequired, isDateValid, isTimeValid, isMaxLength } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?:boolean;
  onSetError: (error:boolean) => void;
}

const CaseTabContent:FC<Props> = ({ disabled = false, onSetError }) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const exportTemplatesOptions:IOption[] | null = useAppSelector(selectExportTemplatesAsOptions);
  const teamsOptions:any = useAppSelector(selectTeamsAsOptions);
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);

  const { control, formState: { errors, isSubmitted }, watch } = useFormContext();

  useEffect(() => {
    dispatch(getExportTemplates({}));
    dispatch(getTeams({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !isSubmitted ) return;
    const { claimant, claimNumber, opposingDetails, ...otherErrors } = errors as any;
    onSetError(Boolean(Object.keys(otherErrors).length));
    // eslint-disable-next-line
  }, [isSubmitted, Object.keys(errors)]);

  const watchEventDate = watch('eventDate');

  return (
    <Fragment>
      {/* Name */}
      <Controller
        control={control} name="name"
        rules={{
          required: isRequired,
          maxLength: isMaxLength(100)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.caseName')}
            disabled={disabled}
            required
            error={Boolean(errors.name)}
            helperText={(errors.name?.message as string) || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watch('name').length} / 100`}</FormHelperText>
              )
            }}
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 4 }} >
        <Controller
          control={control} name="eventDate"
          rules={{
            required: isRequired,
            validate: isDateValid
          }}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('labels.eventDate')}
              inputProps={{
                required: true,
                error: Boolean(errors.eventDate),
                helperText: (errors as any).eventDate?.message || ''
              }}
            />
          )}
        />
        {watchEventDate ? (
          <Controller
            control={control} name="eventTime"
            rules={{
              validate: {
                pattern: isTimeValid
              }
            }}
            render={({ field }) => (
              <DesktopTimePicker
                {...field}
                label={t('labels.eventTime')}
                inputProps={{
                  error: Boolean(errors.eventTime),
                  helperText: (errors as any).eventTime?.message || ''
                }}
              />
            )}
          />
        ) : null}
      </Box>
      <Divider sx={{ mt: 4, mb: 2 }} />
      {legacyReportTemplatesEnabled ? (
        <Controller
          control={control} name="reportTemplateId"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.reportTemplate')}
              options={exportTemplatesOptions}
              disabled={disabled}
              required
              error={Boolean(errors.reportTemplateId)}
              helperText={(errors.reportTemplateId?.message as string) || ''}
            />
          )}
        />
      ) : null}
      {/* Team */}
      <Controller
        control={control} name="teamId"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            label={t('dialogs.caseDetails.teams')}
            options={teamsOptions || []}
            required={true}
            error={Boolean(errors.teamId)}
            helperText={(errors.teamId?.message as string) || ''}
            renderOptions={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                <Typography variant="subtitle1">{option.name}</Typography>
                {option.subname ? (
                  <Typography variant="caption">{option.subname}</Typography>
                ) : null}
              </li>
            )}
            disabled={disabled}
          />
        )}
      />
      <FormHelperText>{t('dialogs.caseDetails.teamsHint')}</FormHelperText>
      <Box sx={{ display: 'flex', gap: 4 }}>
        {/* Examination date */}
        <Controller
          control={control} name="examinationDate"
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('labels.examinationDate')}
              disabled={disabled}
            />
          )}
        />
        {/* Trial date */}
        <Controller
          control={control} name="trialDate"
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('dialogs.caseDetails.trialDate')}
              disabled={disabled}
            />
          )}
        />
      </Box>
      <Divider sx={{ mt: 4, mb: 2 }} />

      {hcpcsCodesEnabled ? (
        <Controller
          control={control} name="settings.medicareOverchargeThreshold"
          rules={{ required: hcpcsCodesEnabled ? isRequired : false }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.medicareOverchargeThreshold')} type="number"
              error={Boolean((errors.settings as any)?.medicareOverchargeThreshold)}
              helperText={(errors.settings as any)?.medicareOverchargeThreshold?.message || ''}
              required={hcpcsCodesEnabled}
              disabled={disabled}
            />
          )}
        />
      ) : null}
      <FormHelperText>{t('labels.medicareOverchargeThresholdHint')}</FormHelperText>
    </Fragment>
  )
}

export default CaseTabContent;