enum MixpanelTracks {
  LogIn = 'LogIn',
  LogOut = 'LogOut',
  // Case
  CaseListView = 'CaseListView',
  CaseCreated = 'CaseCreated',
  CaseApproved = 'CaseApproved',
  CaseRejected = 'CaseRejected',
  CaseArchived = 'CaseArchived',
  CaseUnarchived = 'CaseUnarchived',
  CaseDeleted = 'CaseDeleted',
  CaseLabelsUpdated = 'CaseLabelsUpdated',
  CaseNotesUpdated = 'CaseNotesUpdated',
  CaseDetailsUpdated = 'CaseDetailsUpdated',
  // Case report
  CaseReportSectionAdded = 'CaseReportSectionAdded',
  CaseReportSectionUpdated = 'CaseReportSectionUpdated',
  CaseReportSectionDeleted = 'CaseReportSectionDeleted',

  CaseSearchExecute = 'CaseSearchExecute',

  CaseReportCreated = 'CaseReportCreated',
  CaseReportShared = 'CaseReportShared',
  CaseReportRemoved = 'CaseReportRemoved',

  CaseTimelineView = 'CaseTimelineView',
  CaseTimelineFilter = 'CaseTimelineFilter',
  CaseTimelineExported = 'CaseTimelineExported',
  CaseTimelineLegendView = 'CaseTimelineLegendView',

  CaseCalendarFilter = 'CaseCalendarFilter',
  CaseCalendarExported = 'CaseCalendarExported',

  CaseSinglePageOpen = 'CaseSinglePageOpen',

  CaseSharesView = 'CaseSharesView',
  CaseShareCreated = 'CaseShareCreated',
  CaseShareAccessView = 'CaseShareAccessView',
  CaseShareAccessAdded = 'CaseShareAccessAdded',
  CaseShareAccessRevoked = 'CaseShareAccessRevoked',

  CaseDocumentsExports = 'CaseDocumentsExports',

  PagesCompareView = 'PagesCompareView',

  CaseDocumentFilter = 'CaseDocumentFilter',
  CaseWorkspaceFilter = 'CaseWorkspaceFilter',

  CaseView = 'CaseView',
  CaseFilesView = 'CaseFilesView',
  CaseWorkspaceView = 'CaseWorkspaceView',
  CaseDuplicatesView = 'CaseDuplicatesView',
  CaseCalendarView = 'CaseCalendarView',
  CaseReportsView = 'CaseReportsView',
  CaseReportSectionsView = 'CaseReportSectionsView',
  CaseMessagesView = 'CaseMessagesView',
  CaseInvoicesView = 'CaseInvoicesView',
  CaseSearchView = 'CaseSearchView',
  CaseMessagesCreateConversationAction = 'CaseMessagesCreateConversationAction',
  CaseMessagesSendMessageAction = 'CaseMessagesSendMessageAction',
  CaseDocumentOpen = 'CaseDocumentOpen',
  CasePageOpen = 'CasePageOpen',
  CasePageUpdate = 'CasePageUpdate',
  CasePagesUpdate = 'CasePagesUpdate',
  CaseEpisodeAdd = 'CaseEpisodeAdd',
  CaseEpisodeUpdate = 'CaseEpisodeUpdate',
  CaseEpisodeDelete = 'CaseEpisodeDelete',
  CasePageRotate = 'CasePageRotate',

  AuthorCreate = 'AuthorCreate',
  AuthorDelete = 'AuthorDelete',
  AuthorUpdate = 'AuthorUpdate',

  CaseReportSectionTemplateAdd = 'CaseReportSectionTemplateAdd',
  CaseReportSectionTemplateImport = 'CaseReportSectionTemplateImport',
  CaseReportSectionTemplateEdit = 'CaseReportSectionTemplateEdit',
  CaseReportSectionTemplateDelete = 'CaseReportSectionTemplateDelete',

  CaseEpisodeFactAdded = 'CaseEpisodeFactAdded',
  CaseEpisodeFactUpdated = 'CaseEpisodeFactUpdated',
  CaseEpisodeFactDeleted = 'CaseEpisodeFactDeleted',
  CaseEpisodeFactCodeLookup = 'CaseEpisodeFactCodeLookup',

  OutdatedPageUpdateAttempt = 'OutdatedPageUpdateAttempt',

  PageMarkedNotADUplicate = 'PageMarkedNotADUplicate',
  ClusterMarkedNotADuplicate = 'ClusterMarkedNotADuplicate',

  // AI Chat
  AIChatSettingsUpdate = 'AIChatSettingsUpdate',
  AIChatConversationCreate = 'AIChatConversationCreate',
  AIChatConversationUpdate = 'AIChatConversationUpdate',
  AIChatConversationResponseInfoPopup = 'AIChatConversationResponseInfoPopup',
  AIChatConversationResponsePagePreview = 'AIChatConversationResponsePagePreview',
  AIChatConversationResponseRefinement = 'AIChatConversationResponseRefinement',

  // AI Chat Prompts
  AIChatPromptAdded = 'AIChatPromptAdded',
  AIChatPromptUpdated = 'AIChatPromptUpdated',
  AIChatConversationPromptSaved = 'AIChatConversationPromptSaved',
  AIChatConversationPromptUsed = 'AIChatConversationPromptUsed'
};

export default MixpanelTracks;
