import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import Permissions from 'app/types/Permissions'
import UserRoles from 'app/types/UserRoles';
// Models
import { RootState } from 'app/store';
import { TScope, ITerms, IPendingTerms } from 'app/store/Terms/Terms.models';
import IUser, { IMyUser } from 'app/models/User';
// Seletctors
import { selectTermsAndConditions } from 'app/store/Terms/Terms.selectors';
import { selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectMyUser = (state:RootState) => state[Reducers.Users].myUser;
export const selectUsers = (state:RootState) => state[Reducers.Users].users;
export const selectFilter = (state:RootState) => state[Reducers.Users].filter;
export const selectLoading = (state:RootState) => state[Reducers.Users].loading;

export const selectAIChatPendingTermsAndConditions = createSelector(
  [ selectMyUser ],
  ( myUser ) => {
    if ( !myUser || !myUser.pendingTermsAndConditions ) return [];
    return myUser.pendingTermsAndConditions.filter((pendingTerms:IPendingTerms) => pendingTerms.scope === 'aiChat');
  }
);

export const selectPendingTermsAndConditionsByScope = createSelector(
  [
    selectMyUser,
    (_, props:{ scope:TScope }) => props
  ],
  ( myUser:IMyUser | null, { scope }) => {
    if ( !myUser || !myUser.pendingTermsAndConditions ) return [];
    return myUser.pendingTermsAndConditions.filter((pendingTerms:IPendingTerms) => pendingTerms.scope === scope);
  }
);

export const selectCurrentTermsAndConditions = createSelector(
  [
    selectPendingTermsAndConditionsByScope,
    selectTermsAndConditions
  ],
  ( pendingTermsAndConditions:IPendingTerms[] | null, termsAndConditions:ITerms[] | null) => {
    if (
      !pendingTermsAndConditions || !pendingTermsAndConditions.length ||
      !termsAndConditions || !termsAndConditions.length
    ) return null;

    const [ pendingTerms ] = pendingTermsAndConditions;

    return termsAndConditions.find((term:ITerms) => term.id === pendingTerms.id) || null;
  }
);

export const selectMyUserPermission = createSelector(
  [
    selectMyUser,
    (_, props:{ permission:string }) => props
  ],
  ( myUser:IMyUser | null, { permission } ) => {
    if ( !myUser ) return null;
    return myUser.permissions
      ? myUser.permissions.filter((p) => p.includes(permission))
      : null
    ;
  }
);

export const selectMyUserHasPermission = createSelector(
  [
    selectHcpcsCodesEnabled,
    selectMyUser,
    (_, props:{ permission:Permissions }) => props
  ],
  ( currentAccountHcpcsCodesEnabled:boolean, myUser:IMyUser | null, { permission }) => {
    if ( !currentAccountHcpcsCodesEnabled && permission === Permissions.HcpcsCodes ) return false;
    if ( !myUser ) return false;
    return myUser.permissions ? myUser.permissions.some((p) => p.includes(permission)) : false
  }
);

export const selectUsersEmails = createSelector(
  [ selectUsers ],
  (users:IUser[] | null) => {
    if ( !users ) return null;
    return users.map((user:IUser) => user.email);
  }
);

export const selectUsersAsOptions = createSelector(
  [ selectUsers ],
  (users:IUser[] | null) => {
    if ( !users ) return null;
    return users.map((user:IUser) => ({
      id: user.id,
      name: user.name,
      subname: user.email,
      role: user.role,
    })).sort((a, b) => sortByName(a, b, 'name'));
  }
);

export const selectFilteredUsers = createSelector(
  [
    selectUsers,
    selectFilter
  ],
  (users:IUser[] | null, { search, role, platform }) => {
    if ( !users ) return null;

    if ( !search && role === 'all' && platform === 'all' ) return users;

    const platformValue = platform === 'all' ? true : platform === 'true';

    return users.filter(user => {
      const hasSearch = !search ? true : (
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        (!user.platform && user.email.toLowerCase().includes(search.toLowerCase()))
      );
      const hasRole = role === 'all' ? true : user.role === role;
      const hasPlatform = platform === 'all' ? true : user.platform === platformValue
      ;
      return hasSearch && hasRole && hasPlatform;
    });
  }
);

export const selectUsersForSharing = createSelector(
  [
    selectUsers,
    (_, props:{ role:UserRoles, clientAccountId:number }) => props
  ],
  (users:IUser[] | null, { role, clientAccountId }) => {
    if ( !users ) return null;

    return users.filter((user:IUser) => user.role === role && user.customAttributes && user.customAttributes.clientAccountId === clientAccountId)
  }
);
