import { FC, useState, useEffect } from 'react';
// Mui
import { Box, Fab } from '@mui/material';
// Icons
import UpIcon from '@mui/icons-material/KeyboardArrowUp';

type Props = {
  listRef: any;
}

let timeout:any = null;

const FilesScroll:FC<Props> = ({
  // Props
  listRef
}) => {
  const [ show, setShow ] = useState(false);

  const handleScroll = () => {
    const container:any = document.querySelector('.scrollListContainer');
    setShow(_ => container.scrollTop > 240 ? true : false)
  }

  useEffect(() => {
    let container:any = null;

    timeout = setTimeout(() => {
      if ( listRef.current ){
        container = document.querySelector('.scrollListContainer');
        container.addEventListener('scroll', handleScroll);
      }
    }, 0)

    return () => {
      if ( container ) container.removeEventListener('scroll', handleScroll);
      clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [listRef.current]);

  const handleClick = () => {
    const container:any = document.querySelector('.scrollListContainer');
    if ( !container ) return;
    container.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      {show ? (
        <Box sx={{
          position: 'fixed',
          right: '16px', bottom: '80px',
          zIndex: 1299
        }}>
          <Fab
            sx={{
              opacity: 0.6,
              transition: 'all 0.3s',
              '&:hover': {
                opacity: 1,
              }
            }}
            onClick={handleClick}
            color="primary"
            size="medium"
          ><UpIcon /></Fab>
        </Box>
      ) : null}
    </>
  )
}

export default FilesScroll;
