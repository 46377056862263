import { FC } from 'react';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectFilteredDuplicates } from 'app/store/Cases/Cases.selectors';
// MaterialUI
import Container from '@mui/material/Container';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import DuplicatesClusterList from './DuplicatesClusterList';
// i18next
import { useTranslation } from 'react-i18next';

const DuplicatesCluster:FC = () => {
  const { t } = useTranslation('common');
  // State
  const filteredDuplicates = useSelector(selectFilteredDuplicates);

  if ( !filteredDuplicates ) return <Loader />;
  if ( !Object.keys(filteredDuplicates).length ) return <Message text={t('pages.adminPages.duplicatesPage.noPagesInDuplicates')} />;
  return (
    <Container sx={{ overflowY: 'auto' }} maxWidth={false} id="scrollContainer">
      {Object.keys(filteredDuplicates).map((key, index:number) => (
        <div
          key={`duplicate-cluster-item-${index}`}
          style={{
            backgroundColor: `${index%2 ? '#ededed' : 'transparent'}`,
            padding: '16px 24px',
            marginRight: '-24px',
            marginLeft: '-24px'
          }}
        >
          <DuplicatesClusterList cluster={filteredDuplicates[key]} clusterKey={key} />
        </div>
      ))}
    </Container>
  )
}

export default DuplicatesCluster;