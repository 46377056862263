import { FC, useEffect } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPendingTermsAndConditionsByScope } from 'app/store/Users/Users.selectors';
import { selectDocuments } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Actions
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
// 
import AIChatSidebar from './AIChatSidebar';
import AIChatRouting from './AIChatRouting';
import AiChatTermsAndConditions from './AiChatTermsAndConditions';

const AIChatPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const documents = useAppSelector(selectDocuments);
  const pendingTermsAndConditions = useAppSelector((state:RootState) => selectPendingTermsAndConditionsByScope(state, {
    scope: 'aiChat'
  }));

  useEffect(() => {
    return () => {
      dispatch(AIChatActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  if ( !documents ) return <Loader />;
  if ( pendingTermsAndConditions && pendingTermsAndConditions.length ) return <AiChatTermsAndConditions />;
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
      <AIChatSidebar />
      <AIChatRouting />
    </Box>
  )
}

export default AIChatPage;
