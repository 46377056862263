import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
// Types
import { TemplateDataType, TemplateLayout, TemplateType } from 'app/types/Template';
// Mui
import { Box } from '@mui/material';
// Icons
import {
  VerticalSplitOutlined as VerticalSplitOutlinedIcon,
  ViewQuiltOutlined as ViewQuiltOutlinedIcon,
  BarChart as BarChartIcon
} from '@mui/icons-material';
// Components
import { Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

const EditorPlaceholder:FC = () => {
  const { t } = useTranslation('common');

  const { watch } = useFormContext();

  const watchType = watch('type');
  const watchDataType = watch('dataType');
  const watchLayout = watch('layout');

  const isTypeText = watchType === TemplateType.Text;
  const isDataTypeEpisode = watchDataType === TemplateDataType.Episode;
  const isLayoutChart = watchLayout === TemplateLayout.Chart;
  const isLayoutTable = watchLayout === TemplateLayout.Table;
  const isLayoutCustom = watchLayout === TemplateLayout.Custom;

  const isEpisodeCustom = isDataTypeEpisode && isLayoutCustom;

  const icon = 
    isLayoutChart
    ? <BarChartIcon
        sx={{
          fontSize: 88,
          color: 'rgba(0,0,0,0.54)'
        }}
      />
    : isLayoutTable
      ? <ViewQuiltOutlinedIcon
          sx={{
            fontSize: 88,
            color: 'rgba(0,0,0,0.54)'
          }}
        />
      : <VerticalSplitOutlinedIcon
          sx={{
            fontSize: 88,
            color: 'rgba(0,0,0,0.54)'
          }}
        />
  ;
  const text = 
    isLayoutChart
    ? t('dialogs.templateForm.editorPlaceholderLayoutTable', { watchDataType })
    : isLayoutTable
      ? t('dialogs.templateForm.editorPlaceholderLayoutTable', { watchDataType })
      : isDataTypeEpisode
        ? t('dialogs.templateForm.templateAppliesToEveryEpisode')
        : t('dialogs.templateForm.templateAppliesToEveryRecordsFile')
  ;

  if ( isTypeText || isEpisodeCustom ) return null;
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 1280,
        mx: 'auto'
      }}
    ><Message text={text} icon={icon} /></Box>
  )
}

export default EditorPlaceholder;
