import { FC, Fragment, useMemo, useState } from 'react';
// Types
import { TemplateDataType, TemplateLayout } from 'app/types/Template';
// Models
import { ITemplateVariable } from 'app/models/Template';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAppURLVariables } from 'app/store/Templates/Templates.selectors';
// Mui
import { Paper, List, ListItemButton, ListItemText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
// Constants
import { APP_URL_LABELS } from 'app/App.constants';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (variableName:string) => void;
  columnId?: string;
  id: string;
  dataType?: TemplateDataType;
  layout?: TemplateLayout;
}

const LinkVariablesDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm, columnId, id, dataType, layout
}) => {
  // State
  const variables = useAppSelector(selectAppURLVariables) as ITemplateVariable[];

  const nextVariables = useMemo(() => {
    const isEpisodeCustom = dataType === TemplateDataType.Episode && layout === TemplateLayout.Custom;
    const isEpisodeTable = dataType === TemplateDataType.Episode && layout === TemplateLayout.Table;
    return variables.filter((variable:ITemplateVariable) => {
      if (
        !variable.name.includes('appURL:page') &&
        !variable.name.includes('appURL:episode')
      ) return variable;

      return (id && id === 'content' && isEpisodeCustom) || (columnId && columnId === 'data' && isEpisodeTable);
    });
    // eslint-disable-next-line
  }, [columnId, id, dataType, layout, variables]);

  const [ selectedVariableName, setSelectedVariableName ] = useState<string | null>(null);

  const handleClick = (variableName:string) => () => {
    setSelectedVariableName(variableName);
  }

  const handleSelectClick = () => {
    if ( !selectedVariableName ) return;

    onConfirm(selectedVariableName);
  }

  const actions = (
    <Fragment>
      <Button
        name="Close variable dialog"
        onClick={onClose}
      >Close</Button>
      <Button
        name="Select variable dialog"
        disabled={!selectedVariableName}
        onClick={handleSelectClick}
        variant="contained"
        color="primary"
      >Insert</Button>
    </Fragment>
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="App links"
      actions={actions}

      ContentProps={{
        dividers: true,
        sx: { p: 0 }
      }}
    >
      <Paper sx={{ mx: 6, my: 4 }}>
        <List>
          {nextVariables.map((variable:ITemplateVariable) => (
            <ListItemButton
              key={`variable item ${variable.name}`}
              onClick={handleClick(variable.name)}
              selected={variable.name === selectedVariableName}
            ><ListItemText primary={APP_URL_LABELS[variable.name as keyof typeof APP_URL_LABELS]} /></ListItemButton>
          ))}
        </List>
      </Paper>
    </Dialog>
  )
}

export default LinkVariablesDialog;
