import i18n from "i18n";

const EpisodeAuthorsMessages = {
  Create: i18n.t('notifications.episodeAuthors.create'),
  Update: i18n.t('notifications.episodeAuthors.update'),
  Delete: i18n.t('notifications.episodeAuthors.delete'),
  Deduplicate: i18n.t('notifications.episodeAuthors.deduplicate'),
};

export default EpisodeAuthorsMessages;

i18n.on('languageChanged', () => {
  EpisodeAuthorsMessages.Create = i18n.t('notifications.episodeAuthors.create');
  EpisodeAuthorsMessages.Update = i18n.t('notifications.episodeAuthors.update');
  EpisodeAuthorsMessages.Delete = i18n.t('notifications.episodeAuthors.delete');
  EpisodeAuthorsMessages.Deduplicate = i18n.t('notifications.episodeAuthors.deduplicate');
});