import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IEpisodeFact from 'app/models/EpisodeFact';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getHcpcsCode } from 'app/store/Medical/Medical.async';
// Actions
import { EpisodeFactsActions } from 'app/store/EpisodeFacts/EpisodeFacts.slice';
// Selectors
import { selectSelectedEpisodeId } from 'app/store/Episodes/Episodes.selectors';
import { selectSearchHcpcsCode, selectHcpcsCode, selectHcpcsCodeFetching } from 'app/store/Medical/Medical.selectors';
// Mui
import { Box, Theme, Typography, Tooltip } from '@mui/material';
// Icons
import { Info as InfoIcon } from '@mui/icons-material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Context
import { useEpisodeFactsContext } from 'app/context/EpisodeFacts.context';

const ScheduleNoResult:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const selectedEpisodeId = useAppSelector(selectSelectedEpisodeId) as number;
  const searchHcpcsCode:any = useAppSelector(selectSearchHcpcsCode);
  const hcpcsCode:any = useAppSelector(selectHcpcsCode);
  const hcpcsCodeFetching = useAppSelector(selectHcpcsCodeFetching);

  // Context
  const { values } = useEpisodeFactsContext();

  useEffect(() => {
    if ( searchHcpcsCode ) dispatch(getHcpcsCode(searchHcpcsCode));
    // eslint-disable-next-line
  }, [searchHcpcsCode]);

  const handleClick = () => {
    if ( !searchHcpcsCode ) return;

    const fact:Omit<IEpisodeFact, 'id'> = {
      episodeId: selectedEpisodeId,
      type: 'charge',
      data: {
        hcpcsCode: hcpcsCode ? hcpcsCode.code : searchHcpcsCode,
        shortDescription: hcpcsCode ? hcpcsCode.shortDescription : '',
        price: 0,
        year: values.year,
        carrierNumber: values.carrierNumber
      }
    };

    dispatch(EpisodeFactsActions.createFact(fact));
  }

  const shortDescription = hcpcsCode?.shortDescription || '';
  const mediumDescription = hcpcsCode?.mediumDescription || '';

  if ( !searchHcpcsCode ) return <Message text={t('dialogs.episodeFacts.noResults')} />;
  if ( hcpcsCodeFetching ) return <Loader />;
  if ( hcpcsCode ) return (
    <Typography sx={{ py: 4, textAlign: 'center' }}>
      {t('dialogs.episodeFacts.noMedicareFeeFoundFor')} {searchHcpcsCode}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        {shortDescription ? ` ${shortDescription} ` : ''}
        {
          mediumDescription ? (
            <Tooltip title={mediumDescription}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          ) : null
        }
      </Box>
      <Box
        sx={{
          color: (theme:Theme) => theme.palette.primary.main,
          fontWeight: 700,
          ml: 1,
          cursor: 'pointer'
        }}
        component="span"
        onClick={handleClick}
      >{t('dialogs.episodeFacts.addAnyway')}</Box>
    </Typography>
  )
  return (
    <Typography sx={{ py: 4, textAlign: 'center' }}>
      {t('dialogs.episodeFacts.noResults')},
      <Box
        sx={{
          color: (theme:Theme) => theme.palette.primary.main,
          fontWeight: 700,
          ml: 1,
          cursor: 'pointer'
        }}
        component="span"
        onClick={handleClick}
      >{t('dialogs.episodeFacts.addAnyway')}</Box>
    </Typography>
  )
}

export default ScheduleNoResult;
