import { ChangeEvent, FC, useEffect, useState } from 'react';
// Models
// import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectParams } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import { Input, Select, Autocomplete } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// i18next
import { useTranslation } from 'react-i18next';

const BillingInvoicesFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountsAsOptions = useAppSelector(selectAccountsAsOptions)
  const params:any = useAppSelector(selectParams);

  const [ stateParams, setStateParams ] = useState({
    accountId: params.accountId,
    search: params.search,
    status: params.status
  });

  const debounceAccountId = useDebounce(stateParams.accountId);
  const debounceSearch = useDebounce(stateParams.search);
  const debounceStatus = useDebounce(stateParams.status);

  useEffect(() => {
    const nextParams:any = {};

    if ( debounceAccountId !== params.accountId ) nextParams['accountId'] = debounceAccountId;
    if ( debounceSearch !== params.search ) nextParams['search'] = debounceSearch;
    if ( debounceStatus !== params.status ) nextParams['status'] = debounceStatus;
    if ( Object.keys(nextParams).length ){
      nextParams['offset'] = 0;
      nextParams['limit'] = params.limit;
      dispatch(BillingInvoicesActions.setParams(nextParams));
    }
    // eslint-disable-next-line
  }, [debounceAccountId, debounceSearch, debounceStatus]);

  const handleChangeAccount = (value:any) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      accountId: value
    }));
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateParams((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  }
  
  const handleClearSearch = () => {
    setStateParams((prevState:any) => ({
      ...prevState,
      search: ''
    }));
  }

  const statusOptions = [
    { id: 'all', name: t('labels.all') },
    { id: 'paid', name: t('labels.paid') },
    { id: 'sent', name: t('labels.outstanding') }
  ];

  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
      <Autocomplete
        label={t('labels.clientAccount')} value={stateParams.accountId}
        onChange={handleChangeAccount}
        margin="none"
        size="small"
        options={accountsAsOptions || []}
        renderOptions={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
            <Typography variant="subtitle1">{option.name}</Typography>
            {option.props?.fullAddress ? (
              <Typography variant="caption">{option.props.fullAddress}</Typography>
            ) : null}
          </li>
        )}
      />
      <Input
        label={t('labels.search')} name="search" value={stateParams.search}
        placeholder={t('pages.adminPages.billingInvoicesPage.billingInvoicesSearchPlaceholder')}
        onChange={handleChange}
        onClear={handleClearSearch}
        margin="none"
        size="small"
      />
      <Select
        label={t('labels.status')} id="status" name="status" value={stateParams.status}
        margin="none" size="small"
        onChange={handleChange}
        options={statusOptions}
      />
    </Box>
  )
}

export default BillingInvoicesFilter;
