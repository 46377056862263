// Types
import { AppTypes, AppActionTypes } from './app.types';

export default class AppActions {
  // Toggle highlight mode
  public static toggleHighlightMode = ():AppActionTypes => ({
    type: AppTypes.ToggleHighlightMode
  });
  // Set last opened workspace page
  public static setLastOpenedWorkspacePage = (insuranceCaseId:number, documentId:number, pageNum:number):AppActionTypes => ({
    type: AppTypes.SetLastOpenedWorkspacePage, payload: { insuranceCaseId, documentId, pageNum }
  });
  public static setLastOpenedFilePage = (insuranceCaseId:number, documentId:number, pageNum:number):AppActionTypes => ({
    type: AppTypes.SetLastOpenedFilePage, payload: { insuranceCaseId, documentId, pageNum }
  });
}