import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Models
import { IConversation } from 'app/models/ChatAI';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getConversations } from 'app/store/AIChat/AIChat.async';
// Selectors
import { selectCaseAiChatEnabled } from 'app/store/Cases/Cases.selectors';
import { selectConversations } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { List } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import ConversationItem from './ConversationItem';

const ConversationList = () => {
  const { t } = useTranslation('common');
  const { caseId } = useParams<{ caseId:string; }>()

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const caseAiChatEnabled = useAppSelector(selectCaseAiChatEnabled);
  const conversations:IConversation[] | null = useAppSelector(selectConversations);

  useEffect(() => {
    dispatch(getConversations({ caseId: Number(caseId) }));
    // eslint-disable-next-line
  }, []);

  if ( !caseAiChatEnabled ) return null;
  if ( !conversations ) return <Loader />;
  if ( !conversations.length ) return <Message text={t('pages.staffPages.caseDetailsPage.noConversationsCreated')} />;
  return (
    <List>
      {conversations.map((conversation:IConversation) => (
        <ConversationItem
          key={`conversation item ${conversation.id}`}
          conversation={conversation}
        />
      ))}
    </List>
  )
}

export default ConversationList;
