import { MouseEvent, FC, Fragment, useState } from 'react';
// Models
import { IPresenterPage } from 'app/models/Page';
// Dialogs
import CardPageDialog from 'app/dialogs/CardPageDialog';
// Components
import PageImage from 'app/components/PageImage';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import CardPageBodyPreview from './CardPageBodyPreview';

let timeout:any = null;

type Props = {
  page: IPresenterPage;
}

const CardPageBody:FC<Props> = ({
  // Props
  page
}) => {
  const { open, toggle } = useToggle();

  const [ showPreview, setShowPreview ] = useState(false);
  const [ isLeft, setIsLeft ] = useState(false);

  const handleMouseOver = (event:MouseEvent<HTMLDivElement>) => {
    const { clientX } = event;
    const { clientWidth } = document.body;
    if ( clientX <= (clientWidth/2) ) setIsLeft(true);
    timeout = setTimeout(() => {
      setShowPreview(true);
    }, 1000);
  }

  const handleMouseOut = () => {
    setShowPreview(false);
    setIsLeft(false);
    clearTimeout(timeout);
  }

  return (
    <Fragment>
      <div
        className="cp-body"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={toggle}
      >
        <PageImage documentId={page.documentId} pageNum={page.pageNum} />
      </div>
      {open ? (
        <CardPageDialog
          open={open}
          onClose={toggle}
          documentId={page.documentId}
          pageNum={page.pageNum}
        />
      ) : null}
      {showPreview ? (
        <CardPageBodyPreview
          documentId={page.documentId}
          pageNum={page.pageNum}
          isLeft={isLeft}
          onClose={handleMouseOut}
        />
      ) : null}
    </Fragment>
  );
}

export default CardPageBody;
