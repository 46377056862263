
// import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';

export const selectJobs = (state:RootState) => state[Reducers.LabellingToolJobs].jobs;
export const selectJob = (state:RootState) => state[Reducers.LabellingToolJobs].job;
export const selectLoading = (state:RootState) => state[Reducers.LabellingToolJobs].loading;
