import { FC } from 'react';
import { useParams } from 'react-router-dom';
// Models
import { RootState } from 'app/store';
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Skeleton, Typography } from '@mui/material';

type Props = {
  documentId?: number;
  position?: 'relative' | 'absolute';
}

const DocumentName:FC<Props> = ({
  // Props
  documentId, position = 'absolute'
}) => {
  const { documentId:id } = useParams<{ documentId:string }>();
  // State
  const documentEntity = useAppSelector((state:RootState) => selectDocumentEntity(state, {
    documentId: documentId || Number(id)
  })) as IDocument;

  if ( !documentEntity || !documentEntity.name ) return <Box sx={{ flexGrow: 1 }}><Skeleton width={360} height={28} /></Box>;
  return (
    <Box sx={{ flexGrow: 1, position: 'relative', height: 28 }}>
      <Typography
        sx={{
          position,
          left: 0, top: 0,
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: 'rgba(0,0,0,0.6)',
          fontWeight: 400
        }}
        variant="subtitle1"
      >{documentEntity.name}</Typography>
    </Box>
  )
}

export default DocumentName;
