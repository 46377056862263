import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.dmsDocuments.create'),
  Update: i18n.t('notifications.dmsDocuments.update'),
  Delete: i18n.t('notifications.dmsDocuments.delete'),
  Process: i18n.t('notifications.dmsDocuments.process')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.dmsDocuments.create');
  Messages.Update = i18n.t('notifications.dmsDocuments.update');
  Messages.Delete = i18n.t('notifications.dmsDocuments.delete');
  Messages.Process = i18n.t('notifications.dmsDocuments.process');
});