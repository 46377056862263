import { FC } from 'react';
// Types
import Colors from 'app/types/Colors';
// Mui

type Props = {
  pageId: string;
  colors: Colors[] | undefined;
}

const CardPageColors:FC<Props> = ({
  // Props
  pageId, colors
}) => {
  if ( !colors || !colors.length ) return null;
  return (
    <div className="cp-colors">
      {colors.map((color:Colors) => (
        <span
          key={`${pageId}-page-color-item-${color}`}
          className={`cp-colors-item bg-${color}`}
        ></span>
      ))}
    </div>
  )
}

export default CardPageColors;