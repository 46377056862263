import { FC, useEffect } from 'react';
// Model
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Selectors
import { selectPushAlert } from 'app/store/AppUINotifications/AppUINotifications.selectors';
// Mui
import { Alert } from '@mui/material';

type Props = {
  caseId?: number;
  type: 'labels' | 'notes';
}

const CasePushAlert:FC<Props> = ({
  // Props
  caseId, type
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const alert = useAppSelector((state:RootState) => selectPushAlert(state, {
    category: 'case',
    type
  }));

  useEffect(() => {
    return () => {
      if ( alert ) dispatch(AppUiNotificationsActions.removePushAlert({
        category: 'case',
        type
      }));
    }
    // eslint-disable-next-line
  }, [alert]);

  const handleClick = () => {
    dispatch(AppUiNotificationsActions.removePushAlert({
      category: 'case',
      type
    }));
  }

  if ( !alert || caseId !== alert.caseId ) return null;
  return (
    <Alert
      sx={{ mb: 4 }}
      color="warning"
      onClose={handleClick}
    >{alert.message}</Alert>
  )
}

export default CasePushAlert;
