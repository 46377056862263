import { FC } from 'react';
import { useLocation } from 'react-router-dom';
// i18next
import { Trans, useTranslation } from 'react-i18next';
// Mui
import { Alert } from '@mui/material';
// Utilities
import { getSearchParam } from 'app/utilities/Utilities';

const ShareAlert:FC = () => {
  const { t } = useTranslation('common');

  const { search } = useLocation();

  const clientAccountName = getSearchParam(search, 'acc');
  const caseName = getSearchParam(search, 'case');

  return (
    <Alert sx={{ mb: 2 }} color="info">
      <Trans
        t={t}
        i18nKey="pages.staffPages.casesPage.caseFormTokenAlert"
        components={{ b: <b /> }}
        values={{ clientAccountName, caseName }}
      />
    </Alert>
  )
}

export default ShareAlert;
