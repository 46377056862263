import { FC, Fragment } from 'react';
// Hooks
import useCaseState from 'app/hooks/useCaseStatus';
import useToggle from 'app/hooks/useToggle';
// Dialog
import DocumentExportCreateFormDialog from './DocumentExportCreateFormDialog';
// Components
import { Fab } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks/useStore';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import CaseStatuses from 'app/types/CaseStatuses';

const DocumentsExportsFab:FC = () => {
  const { t } = useTranslation('common');

  const insuranceCase = useAppSelector(selectInsuranceCase);

  const { isArchived } = useCaseState();
  const { open, toggle } = useToggle();

  const caseStatusOpen = insuranceCase?.status === CaseStatuses.Open;
  const caseStatusArchived = insuranceCase?.status === CaseStatuses.Archived;

  const noProcessingOrReady = !insuranceCase?.processing || insuranceCase.processing.status === 'ready';

  const documentsExportsEnabled = (caseStatusOpen || caseStatusArchived) && noProcessingOrReady;

  return (
    <Fragment>
      <Fab
        aria-label="Create records export button"
        name="Create records export"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16,
          display: 'flex',
          gap: 4
        }}
        disabled={isArchived || !documentsExportsEnabled}
        onClick={toggle}
        variant="extended"
        color="primary"
      >{t('pages.adminPages.exportMaterialsPage.exportCaseDocuments')}</Fab>
      {open ? (
        <DocumentExportCreateFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default DocumentsExportsFab;
