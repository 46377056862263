
// Types
import Reducers from 'app/types/Reducers';
// Async
import { getInsuranceCase } from "app/store/Cases/Cases.async";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { CasesActions } from "app/store/Cases/Cases.slice";
// Messages
import Messages from './CaseShares.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post } from 'app/utilities/HttpClient';

const _url:string = '/caseShares';

export const getCaseShares = asyncThunkHandler(
  `${Reducers.CaseShares}/Get case shares`,
  async (insuranceCaseId:number) => {
    const response:Response = await $get(_url, { insuranceCaseId });
    return response.json();
  }
);

export const createCaseShare = asyncThunkHandler(
  `${Reducers.CaseShares}/Create case shares`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Share
    }));

    return response.json();
  }
);

export const acceptCaseShare = asyncThunkHandler(
  `${Reducers.CaseShares}/Accept case share`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    const response:Response = await $post(`${_url}/actions/accept`, data);
    const body = await response.json();
    if ( body?.destinationInsuranceCaseId ){
      const insuranceCase = await dispatch(getInsuranceCase(body.destinationInsuranceCaseId)).unwrap();
      dispatch(CasesActions.setAcceptInsuranceCase(insuranceCase));
    }

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Accept
    }));

    return fulfillWithValue('Case was accepted');
  }
);

export const getCaseShareDetails = asyncThunkHandler(
  `${Reducers.CaseShares}/Get case share details`,
  async (token:string) => {
    const response:Response = await $get(`${_url}/inbound/${token}`);
    return response.json();
  }
);
