import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IState from './AppUIStatus.models';

const initialState:IState = {
  requestStatusCode: null
};

const slice = createSlice({
  name: Reducers.AppUIStatus,
  initialState,
  reducers: {
    setRequestStatusCode: (state:IState, action:PayloadAction<number | null>) => {
      state.requestStatusCode = action.payload;
    },
    setInitial: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  }
});

export const AppUIStatusActions = slice.actions;

export default slice.reducer;
