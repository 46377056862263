import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
// Mui
import { ListItem, ListItemText, Typography }  from '@mui/material';
// Icons
import { Button } from 'app/components/Mui';
import useFormattedDate from 'app/hooks/useFormattedDate';
import useFormattedTime from 'app/hooks/useFormattedTime';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  episode: any;
}

const EpisodesListItem:FC<Props> = ({
  // Props
  episode
}) => {
  const { t } = useTranslation('common');

  const formattedDate = useFormattedDate(episode?.date);
  const formattedTime = useFormattedTime(episode?.time);

  return (
    <ListItem
      secondaryAction={
        <Button
          name={`Go to page ${episode.id}`}
          component={Link}
          to={`/admin/cases/${episode?.insuranceCase?.id}/documents/${episode.documentId}/${episode.pageNum}?episodeId=${episode.id}`}
          target="_blank"
        >{t('labels.goToPage')}</Button>
      }
    >
      <ListItemText
        primary={
          <Typography display="block" variant="body2" component="span">
            <b>{t('labels.case')}:</b>&nbsp;{episode?.insuranceCase?.name}
          </Typography>
        }
        secondary={
          <Fragment>
            {formattedDate ? (
              <Typography display="block" variant="body2" component="span">
                <b>{t('labels.date')}:</b>&nbsp;{formattedDate}{formattedTime ? ` ${formattedTime}` : ''}
              </Typography>
            ) : null}
            {episode.type ? (
              <Typography variant="caption" component="span">
                <b>{t('labels.type')}:</b>&nbsp;{episode.type}
              </Typography>
            ) : null}
          </Fragment>
        }
      />
    </ListItem>
  )
}

export default EpisodesListItem;
