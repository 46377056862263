import { ChangeEvent, FC, ReactNode, createContext, useContext, useState } from "react";
// Models
import IOption from "app/models/Option";
// Services
import LocalStorageService from 'app/services/LocalStorage.service';
import CalendarService from "app/services/CalendarService";

type ContextType = {
  values: IValues;
  errors: IErrors;
  onChange: (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | { name:keyof IValues, value:string }) => void;
  onSubmit: (cb:(values:IValues) => void) => void;
  yearsOptions: IOption[];
};

const Context = createContext<ContextType | undefined>(undefined);

export const useEpisodeFactsContext = ():ContextType => {
  const context = useContext(Context);
  if ( !context ) throw new Error('useEpisodeFactsContext must be used within a PageEpisodesProvider');
  return context;
}

interface IValues {
  search: string;
  year: string,
  carrierNumber: string;
};

interface IErrors {
  search: boolean;
  year: boolean,
  carrierNumber: boolean;
};

type PropsType = {
  year: number | null;
  children: ReactNode;
};

const MIN_YEAR = 2021;

const EpisodeFactsProvider:FC<PropsType> = ({
  // Props
  year, children
}) => {
  const initialValues:IValues = {
    search: '',
    year: $getInitialYear(year),
    carrierNumber: LocalStorageService.getScheduleSearchCarrierNumber()
  };

  const initialErrors:IErrors = {
    search: false,
    year: false,
    carrierNumber: false
  };

  const [ values, setValues ] = useState<IValues>(initialValues);
  const [ errors, setErrors ] = useState<IErrors>(initialErrors);

  const yearsOptions = CalendarService.generateYearOptions(MIN_YEAR, new Date().getFullYear())

  const handleChange = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | { name:keyof IValues, value:string }) => {
    const name = 'target' in event ? event.target.name : event.name;
    const value = 'target' in event ? event.target.value : event.value;
    setValues((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if ( name === 'carrierNumber' ){
      LocalStorageService.setScheduleSearchCarrierNumber(value);
    }
  }

  const handleSubmit = (cb:(values:IValues) => void) => {
    const nextErrors = {...initialErrors};

    Object.keys(values).forEach((k) => {
      if ( !values[k as keyof IValues] ) nextErrors[k as keyof IErrors] = true;
    });

    setErrors(nextErrors);

    if ( Object.values(nextErrors).some(e => e) )return;

    cb(values);
  }

  return (
    <Context.Provider value={{
      values,
      errors,
      onChange: handleChange,
      onSubmit: handleSubmit,
      yearsOptions
    }}>{children}</Context.Provider>
  )
}

export default EpisodeFactsProvider;

const $getInitialYear = (year:number | null):string => {
  const years = CalendarService.generateYears(MIN_YEAR, new Date().getFullYear());

  if ( !year ) return years[years.length - 1].toString();

  return `${years.includes(year) ? year : $getNearesYear(years, year)}`
}

const $getNearesYear = (years:number[], year:number):number => {
  return years.reduce((closest, current) => {
    return Math.abs(current - year) < Math.abs(closest - year) ? current : closest;
  }, 0);
};
