// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./CaseAlerts.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $delete } from 'app/utilities/HttpClient';

const _url:string = '/caseAlerts';

// Get alerts
export const getAlerts = asyncThunkHandler(
  `${Reducers.CaseAlerts}/Get alerts`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Resolve alert
export const resolveAlert = asyncThunkHandler(
  `${Reducers.CaseAlerts}/Resolve alert`,
  async (alertId:number) => {
    const response:Response = await $post(`${_url}/${alertId}/actions/resolve`);
    return response.json();
  }
);

// Delete alert
export const deleteAlert = asyncThunkHandler(
  `${Reducers.CaseAlerts}/Delete alert`,
  async (alertId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${alertId}`);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));

    return fulfillWithValue(alertId);
  }
);

