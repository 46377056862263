import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// Mui
import { Divider, Paper, Typography, Box, Tooltip } from '@mui/material';
// Components
import Icon from 'app/components/Icon';
import { InfoBlock } from 'app/components/Utilities';
import { IPresenterCase } from 'app/models/Case';
// Hooks
import useFormattedDate from 'app/hooks/useFormattedDate';
import useLongDateTme from 'app/hooks/useLongDateTime';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  insuranceCase: IPresenterCase;
}

const InsuranceCasesListItem:FC<Props> = ({
  // Props
  insuranceCase
}) => {
  const { t } = useTranslation('common');

  const { url } = useRouteMatch<{ url:string }>();

  const formattedDateOfBirth = useFormattedDate(insuranceCase.claimant?.dateOfBirth);
  const longCreatedOn = useLongDateTme({
    dateValue: insuranceCase.createdOn,
    displayTime: true,
    local: true
  });

  return (
    <Paper sx={{ p: 4 }} variant="outlined">
      <Typography sx={{
        flexGrow: 1,
        '& > a': {
          textDecoration: 'none',
          color: 'rgba(0, 0, 0, 0.87)',
        },
        mb: 2
      }} variant="h5">
        <Link to={`${url}/${insuranceCase.id}/workspace`}>{insuranceCase.name}</Link>
      </Typography>
      <Box sx={{ display: 'flex', gap: 4 }}>
        {formattedDateOfBirth ? (
          <Tooltip title={t('labels.claimantDateOfBirth')}>
            <Box display="flex" alignItems="center">
              <Icon sx={{ mr: 2 }} icon="event" />
              <Typography variant="subtitle2">{t('labels.dob')}&nbsp;{formattedDateOfBirth}</Typography>
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: 'flex', gap: 4 }}>
        {longCreatedOn ? (
          <InfoBlock label={t('labels.createdAt')} value={longCreatedOn} />
        ) : null}
        {insuranceCase.claimant?.name ? (
          <InfoBlock label={t('pages.presenterPages.insuranceCasesPage.patientName')} value={insuranceCase.claimant.name} />
        ) : null}
      </Box>
    </Paper>
  )
}

export default InsuranceCasesListItem;
