import { FC, useMemo } from 'react';
// Mui
import { 
  SxProps, Theme,
  Box, Typography
} from '@mui/material';

export type IconVariants = 'shared' | 'client' | 'add' | 'add_to_workspace' | 'additional' | 'arrow_drop_down' |
  'arrow_upward' | 'batch' | 'billing' | 'case_number' | 'chevron_left' | 'chevron_right' |
  'close' | 'color' | 'columns_2' | 'columns_3' | 'columns_4' | 'columns_auto' |
  'compare' | 'delete' | 'done' | 'edit' | 'event' | 'headset_mic' | 'highlight' |
  'load_data_to_daily_chart' | 'more_vert' | 'notes' | 'notifications_none' | 'preview' |
  'rotate_right' | 'select_all' | 'staple_delete' | 'staple_edit' | 'stapled' |
  'swap_horiz' | 'team'
;

export type IconProps = {
  icon: IconVariants;
  label?: string;
  size?: 'small' | 'default';
  rootSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
};

const Icon:FC<IconProps> = ({
  // Props
  icon, size = 'default', label = '', rootSx = {}, sx = {}
}) => {
  const sizing = size === 'default' ? '24px' : '16px';
  const defaultRootSx = useMemo(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 2
  }), []);
  const defaultSx = useMemo(() => ({
    width: sizing,
    height: sizing,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: sizing
  }), [sizing]);

  return (
    <Box sx={{ ...defaultRootSx, ...rootSx }}>
      <Box sx={{ ...defaultSx, ...sx }}><i className={`icon icon-${icon}`}></i></Box>
      {label ? (
        <Typography variant="body2">{label}</Typography>
      ) : null}
    </Box>
  )
}

export default Icon;
