import { FC, Fragment } from 'react';
// i18next
import { Trans, useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteReportSectionTemplate } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.async';
// Selectors
import { selectLoading } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.selectors';
// Mui
import {
  Divider,
  ListItem, ListItemText, ListItemSecondaryAction
} from '@mui/material';
// Icon
import {
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Compoents
import { IconButton } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import ReportSectionTemplateFormDialog from './ReportSectionTemplateFormDialog';

type Props = {
  reportSectionTemplate:any;
}

const ReportSectionTemplateItem:FC<Props> = ({
  // Props
  reportSectionTemplate
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();

  const { open, toggle } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deleteReportSectionTemplate(reportSectionTemplate.id));
  }

  return (
    <Fragment>
      <ListItem>
        <ListItemText primary={reportSectionTemplate.name} />
        <ListItemSecondaryAction>
          <IconButton
            name={`Report section template dropdown ${reportSectionTemplate.id}`}
            aria-label="Report section template item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {/* Menu */}
      <Menu>
        <MenuItem
          name={`Edit report section template ${reportSectionTemplate.id}`}
          onClick={toggle}
        >{t('labels.edit')}</MenuItem>
        <Divider />
        <MenuItem
          name={`Delete report section template ${reportSectionTemplate.id}`}
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={toggleConfirmationDialog}
        >{t('labels.delete')}</MenuItem>
      </Menu>
      {/* Form dialog */}
      {open ? (
        <ReportSectionTemplateFormDialog
          open={open}
          onClose={toggle}
          reportSectionTemplate={reportSectionTemplate}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.adminPages.reportSectionTemplatesPage.deleteReportSectionTemplate')}
          content={(
            <Trans
              t={t} i18nKey="pages.adminPages.reportSectionTemplatesPage.deleteReportSectionTemplateConfirmation"
              components={{ strong: <strong /> }}
              values={{ templateName: reportSectionTemplate.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default ReportSectionTemplateItem;
