import { ChangeEvent, FC, Fragment, useState } from 'react';
// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Async
import { uploadReportFile } from 'app/store/CaseReports/CaseReports.async';
// Selectors
import { selectLoading } from 'app/store/CaseReports/CaseReports.selectors';
// Mui
import { Box, Typography, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  reportId: number;
}

const ReportUploadDialog:FC<Props> = ({
  // Props
  open, onClose, reportId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const [ values, setValues ] = useState<{
    wordFile: File | null,
    pdfFile: File | null,
  }>({
    wordFile: null,
    pdfFile: null
  });
  const [ hasError, setHasError ] = useState(false);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    setValues((prevState:any) => ({
      ...prevState,
      [name]: files ? files[0] : null
    }));
  }

  const handleUploadReportFile = async () => {
    if ( !values.pdfFile && !values.wordFile ){
      setHasError(true);
      return;
    }

    setHasError(false);

    try {
      const data:any = {};
      if ( values.pdfFile ) data['pdfFile'] = values.pdfFile;
      if ( values.wordFile ) data['wordFile'] = values.wordFile;
      await dispatch(uploadReportFile({ reportId, data })).unwrap();
      onClose();
    } catch(error){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel report dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Upload report dialog"
        loading={loading}
        onClick={handleUploadReportFile}
        variant="contained"
        color="primary"
      >{t('labels.upload')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.staffPages.caseDetailsPage.uploadReportFile')}
      actions={actions}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 4 }}>
        <Button
          name="Choose word file"
          variant="contained"
          component="label"
        >
          {t('pages.staffPages.caseDetailsPage.chooseWordFile')}
          <input
            id="wordFile"
            name="wordFile"
            type="file"
            accept=".doc, .docx"
            onChange={handleChange}
            hidden
          />
        </Button>
        {(values.wordFile && values.wordFile.name) ? (
          <Typography display="block" variant="subtitle2" color="primary">{values.wordFile.name}</Typography>
        ) : null}
        <Button
          name="Choose pdf file"
          variant="contained"
          component="label"
        >
          {t('pages.staffPages.caseDetailsPage.choosePdfFile')}
          <input
            id="pdfFile"
            name="pdfFile"
            type="file"
            accept="application/pdf"
            onChange={handleChange}
            hidden
          />
        </Button>
      </Box>
      {(values.pdfFile && values.pdfFile.name) ? (
        <Typography display="block" variant="subtitle2" color="primary">{values.pdfFile.name}</Typography>
      ) : null}

      {hasError ? (
        <FormHelperText sx={{ mt: 2 }} error>{t('pages.staffPages.caseDetailsPage.oneFileShouldBeChosen')}</FormHelperText>
      ) : null}
    </Dialog>
  )
}

export default ReportUploadDialog;
