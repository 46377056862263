import { FC } from 'react'
// Types
import { TemplateDataType, TemplateLayout, TemplateType } from 'app/types/Template';
// Mui
import { Box, FormLabel } from '@mui/material';
// Icons
import {
  Subject as SubjectIcon,
  SourceOutlined as SourceOutlinedIcon,
  ViewQuiltOutlined as ViewQuiltOutlinedIcon
} from '@mui/icons-material';
// Utilities
import { getTemplateDataTypeLabel, getTemplateLayoutLabel, getTemplateTypeLabel } from 'app/utilities/Utilities';

type Props = {
  type: TemplateType | null;
  dataType?: TemplateDataType | null;
  layout?: TemplateLayout | null;
}

const TemplateTypeInfo:FC<Props> = ({
  // Props
  type, dataType, layout
}) => {
  if ( !type ) return null;
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      textTransform: 'capitalize'
    }}>
      <FormLabel sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
        <SubjectIcon sx={{ mr: 2 }} fontSize="small" />
        {getTemplateTypeLabel(type)}
      </FormLabel>
      {dataType ? (
        <FormLabel sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
          <SourceOutlinedIcon sx={{ mr: 2 }} fontSize="small" />
          {getTemplateDataTypeLabel(dataType)}
        </FormLabel>
      ) : null}
      {layout ? (
        <FormLabel sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
          <ViewQuiltOutlinedIcon sx={{ mr: 2 }} fontSize="small" />
          {getTemplateLayoutLabel(layout)}
        </FormLabel>
      ) : null}
    </Box>
  )
}

export default TemplateTypeInfo;
