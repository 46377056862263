import { FC, useState } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Mui
import { Box, Button, IconButton, Tooltip } from '@mui/material';
// Icons
import {
  ContentPasteSearch as ContentPasteSearchIcon,
  ChevronLeftOutlined as ChevronLeftOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon
} from '@mui/icons-material';
// Utilities
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { ICurrentAccount } from 'app/models/Account';
import dayjs from 'dayjs';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

const MAX_DATES:number = 4;

type Props = {
  dates: string[] | null;
  onClick: (nextDate:string) => void;
  showMoreVisible?: boolean;
  isActionActive?: boolean;
  isDisabled?: boolean;
}

const PageDates:FC<Props> = ({
  // Props
  dates, onClick, showMoreVisible = true, isActionActive = true, isDisabled = false
}) => {
  const { t } = useTranslation('common');

  const hasMoreThanMaxDates:boolean = dates ? dates.length > MAX_DATES : false;
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);

  const [ showMore, setShowMore ] = useState<boolean>(!hasMoreThanMaxDates);

  const handleClick = (nextDate:string) => ()  => {
    if ( !isActionActive || trialMode ) return;
    onClick(nextDate);
  }

  const handleToggleShowMore = () => setShowMore(!showMore);

  const inputDate = (currentAccount?.settings?.dateFormat || 'MM/DD/YYYY').toUpperCase();

  const renderDates = () => {
    if ( !dates || !dates.length ) return null;
    if ( !showMoreVisible ) return dates;
    return showMore ? dates : dates.slice(0, MAX_DATES);
  }

  if ( trialMode && !dates?.length ) return null;
  if ( !dates || !dates.length ) return null;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
      <Tooltip title={t('components.pageDates.datesFoundOnPage')}>
        <ContentPasteSearchIcon sx={{ color: 'rgba(0,0,0,0.54)', mr: 2 }} />
      </Tooltip>
      {renderDates() ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {renderDates()?.map((date:any, index:number) => (
            <Button
              key={`Page date ${date.date || date} ${index}`}
              disabled={isDisabled}
              onClick={handleClick(date.date || date)}
              variant="outlined"
            >{dayjs(date.date || date).format(inputDate)}</Button>
          ))}
          {showMoreVisible && hasMoreThanMaxDates ? (
            <IconButton
              onClick={handleToggleShowMore}
            >{showMore ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}</IconButton>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}

export default PageDates;
