import { FC, Fragment, useEffect } from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
// Models
import IMatch from 'app/models/Match';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { setCloudfrontCookies } from 'app/store/Content/Сontent.async';
import { getInsuranceCaseById, getDocuments, getPages, getEpisodes } from 'app/store/Presenter/Presenter.async';
// Pages
import WorkspacePage from './WorkspacePage';
import CalendarPage from './CalendarPage';
import DocumentsRouting from './Documents.routing';
// 
import InsuranceCaseToolbar from './InsuranceCaseToolbar';

type Props = {
  match: IMatch;
};

const InsuranceCaseRouting:FC<Props> = ({
  // Props
  match
}) => {
  const { insuranceCaseId } = useParams<{ insuranceCaseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    const params = { insuranceCaseId: Number(insuranceCaseId) };
    dispatch(setCloudfrontCookies(params));
    dispatch(getInsuranceCaseById(Number(insuranceCaseId)));
    dispatch(getDocuments(params));
    dispatch(getPages(params));
    dispatch(getEpisodes({...params, fields: 'author'}));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <InsuranceCaseToolbar />
      <Switch>
        <Route path={`${match.path}/workspace`} component={WorkspacePage} />
        <Route path={`${match.path}/calendar`} component={CalendarPage} />
        <Route path={`${match.path}/documents`} component={DocumentsRouting} />
        <Redirect from={match.path} to={`${match.path}/workspace`} />
      </Switch>
    </Fragment>
  )
}

export default InsuranceCaseRouting;
