import { FC } from 'react';
// Models
import ILabel from 'app/models/Label';
// Selectors
import { selectAnnotationInJob } from 'app/store/LabellingToolAnnotations/LabellingToolAnnotations.selectors';
import { selectPage } from 'app/store/page/page.selectors';
// Mui
import { ListItem, ListItemText, Checkbox } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
import { RootState } from 'app/store';

import { createAnnotation, deleteAnnotation } from 'app/store/LabellingToolAnnotations/LabellingToolAnnotations.async';
import { IAnnotation } from 'app/models/LabellingTool';

type Props = {
  jobId: number;
  label: ILabel;
}

const LabellingJobsListItem:FC<Props> = ({
  // Props
  jobId, label
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const annotation:IAnnotation | null = useAppSelector((state:RootState) => selectAnnotationInJob(state, {
    jobId,
    label: label.name
  }));
  // ToDO
  const page:any = useAppSelector((state:any) => selectPage(state));
  // End: ToDO

  const handleChange = (_:any, checked:boolean) => {
    if ( !page ) return;

    console.log(checked);

    if ( checked ){
      const sourceUrn = `urn:cc:page:${page.documentId}_${page.pageNum}`;
      dispatch(createAnnotation({
        jobId,
        sourceUrn,
        label: label.name
      }));
    } else {
      if ( annotation ) dispatch(deleteAnnotation(annotation.id));
    }
  }

  const isChecked = Boolean(annotation);

  return (
    <ListItem
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={handleChange}
          checked={isChecked}
        />
      }
    ><ListItemText primary={label.name} /></ListItem>
  )
}

export default LabellingJobsListItem;
