import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import ICaseAssignment from "app/models/CaseAssignment";
// Types
import Reducers from "app/types/Reducers";
// Async
import { getAssignments, createAssignment, deleteAssignment } from './CaseAssignments.async';

interface IState {
  assignments: ICaseAssignment[] | null;
  loading: boolean;
};

const initialState:IState = {
  assignments: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.CaseAssignments,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder) {
    // Get assignments
    builder.addCase(getAssignments.pending, (state) => {
      state.assignments = null;
    })
    builder.addCase(getAssignments.fulfilled, (state, action:PayloadAction<ICaseAssignment[]>) => {
      state.assignments = action.payload;
    })
    // Create assignments
    builder.addCase(createAssignment.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(createAssignment.fulfilled, (state, action:PayloadAction<ICaseAssignment[]>) => {
      if ( state.assignments ){
        state.assignments = [...state.assignments, ...action.payload];
      }
    })
    // Delete assignments
    builder.addCase(deleteAssignment.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(deleteAssignment.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.assignments ){
        state.assignments = state.assignments.filter((assignment:ICaseAssignment) => assignment.userId !== action.payload)
      }
    })
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const CaseAssignmentsActions = slice.actions;

export default slice.reducer;
