import { FC } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiDialogActions } from 'app/store/AppUIDialog/AppUIDialog.slice';
// Selectors
import { selectDialogName, selectDialogProps } from 'app/store/AppUIDialog/AppUIDialog.selectors';
// Dialogs
import CaseLabelsDialog from 'app/dialogs/CaseLabelsDialog';
import CaseNotesDialog from 'app/dialogs/CaseNotesDialog';
import CardPageDialog from '@root/dialogs/CardPageDialog';

const Dialogs:Record<string, FC<any>> = {
  'CaseLabelsDialog': CaseLabelsDialog,
  'CaseNotesDialog': CaseNotesDialog,
  'PageDetailsDialog': CardPageDialog
};

const DialogHandler:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const dialogName = useAppSelector(selectDialogName);
  const dialogProps = useAppSelector(selectDialogProps);

  if ( !dialogName ) return null;

  const open = Boolean(dialogName);

  const handleClose = () => {
    dispatch(AppUiDialogActions.hideDialog());
  }
  const Dialog = Dialogs[dialogName];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      {...dialogProps}
    />
  )
}

export default DialogHandler;
