import { FC, Fragment } from 'react';
// Models
import ITemplate from 'app/models/Template';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteTemplate } from 'app/store/Templates/Templates.async';
// Selectors
import { selectLoading } from 'app/store/Templates/Templates.selectors';
// Mui
import {
  Theme,
  Paper, Box, Typography
} from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import TemplateFormDialog from 'app/dialogs/TemplateFormDialog';
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
import TemplateTypeInfo from 'app/components/TemplateTypeInfo';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  template: ITemplate;
};

const TemplatesListItem:FC<Props> = ({
  // Props
  template
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();

  const { open:openFormDialog, toggle:toggleFormDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = async () => {
    try {
      await dispatch(deleteTemplate(template.id)).unwrap();
    } catch(error){}
  }

  return (
    <Fragment>
      <Paper
        sx={{
          display: 'flex',
          p: 4
        }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h6">{template.name}</Typography>
          <TemplateTypeInfo
            type={template.type}
            dataType={template.dataType}
            layout={template.layout}
          />
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Template dropdown ${template.id}`}
            aria-label="Template item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              key={`Edit template ${template.id}`}
              name={`Edit template ${template.id}`}
              onClick={toggleFormDialog}
            >{t('labels.edit')}</MenuItem>
            <MenuItem
              name={`Delete user ${template.id}`}
              sx={{
                color: (theme:Theme) => theme.palette.error.main
              }}
              onClick={toggleConfirmationDialog}
            >{t('labels.delete')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {openFormDialog ? (
        <TemplateFormDialog
          open={openFormDialog}
          onClose={toggleFormDialog}
          templateId={template.id}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          loading={loading}
          title={t('pages.adminPages.templatesPage.deleteTemplate')}
          content={(
            <Trans
              t={t} i18nKey="pages.adminPages.templatesPage.deleteTemplateConfirmation"
              components={{ strong: <strong /> }}
              values={{ templateName: template.name }}
            />
          )}
        />
      ) : null}
    </Fragment>
  )
}

export default TemplatesListItem;
