import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';
import GrantTypes from 'app/types/GrantTypes';
// Async
import { getMyUser } from 'app/store/Users/Users.async';
// Actions
import { AppUiNotificationsActions } from "app/store/AppUINotifications/AppUINotifications.slice";
import { AccountsActions } from "app/store/Accounts/Accounts.slice";
import { AuthActions } from 'app/store/Auth/Auth.slice';
import { UsersActions } from 'app/store/Users/Users.slice';
import { PresenterActions } from "app/store/Presenter/Presenter.slice";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $post } from 'app/utilities/HttpClient';
// Service
import LocalStorageService from 'app/services/LocalStorage.service';
import CookieService from 'app/services/Cookie.service';

const _url:string = `/auth`;

export const checkAccessToken = createAsyncThunk(
  `${Reducers.Auth}/Check access token`,
  async (_, { dispatch, rejectWithValue }) => {
    const cookieToken = CookieService.getToken();

    if ( cookieToken ){
      LocalStorageService.clearAuthCredential();

      dispatch(login({
        grantType: GrantTypes.TokenExchange,
        subjectToken: cookieToken
      }))
    } else {
      if ( LocalStorageService.isAccessTokenExpired() && !LocalStorageService.getRefreshToken() ){
        LocalStorageService.clearAuthCredential();

        dispatch(AuthActions.setAuthenticated(false));

        return rejectWithValue(undefined);
      }

      dispatch(getMyUser({}));
    }
  }
)

export const login = asyncThunkHandler(
  `${Reducers.Auth}/Login`,
  async (data:any) => {
    const response:Response = await $post(`${_url}/token`, data);
    return response.json();
  }
);

export const logout = createAsyncThunk(
  `${Reducers.Auth}/Logout`,
  async (_, { fulfillWithValue, dispatch }) => {
    dispatch(AppUiNotificationsActions.resetState());
    dispatch(AuthActions.setAuthenticated(false));
    dispatch(AccountsActions.resetState());
    dispatch(UsersActions.resetState());
    dispatch(PresenterActions.resetState());

    return fulfillWithValue(true);
  }
);
