import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { useAppSelector } from './useStore';

const useCaseStatus = () => {
  const insuranceCase = useAppSelector(selectInsuranceCase);
  
  const isArchived = insuranceCase?.status === 'archived';

  return { isArchived };
}

export default useCaseStatus;
