import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, Typography } from '@mui/material';
// Icons
import { WifiOff as WifiOffIcon } from '@mui/icons-material';
// Components
import { Button } from 'app/components/Mui';

const NoInternetContent:FC = () => {
  const { t } = useTranslation('common');

  const handleClick = () => {
    window.location.reload();
  }

  return (
    <Fragment>
      <Box
        sx={{
          width: 128,
          height: 128,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: '50%',
          bgcolor: 'white',
          mx: 'auto',
          mb: 4
        }}
      ><WifiOffIcon color="disabled" fontSize="large" /></Box>
      <Typography sx={{ mb: 4 }} variant="h5">{t('notifications.noInternetConnection.title')}</Typography>
      <Typography sx={{ mb: 4 }} variant="body1">{t('notifications.noInternetConnection.content')}</Typography>
      <Button
        name="Reload"
        onClick={handleClick}
        variant="contained"
      >Reload</Button>
    </Fragment>
  )
}

export default NoInternetContent;
