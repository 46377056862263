import { FC, Fragment, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Models
import { IMessage, IMessagePart } from 'app/models/ChatAI';
// Mui
import { Tooltip } from '@mui/material';
// Icons
import {
  Check as CheckIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useCopyToClipboard from 'app/hooks/useCopyToClipboard';

type IRef = IMessagePart['references'][0];
type IMetadata = IRef['metadata'];

type Props = {
  message: IMessage;
}

const MessageCopyAction:FC<Props> = ({
  // Props
  message
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  const [ asyncCopyTextToClipboardFn, copied ] = useCopyToClipboard();

  const renderLink = (index:number, metadata:IMetadata) => (
    `<a href="${window.location.origin}/admin/cases/${caseId}/documents/${metadata.documentId}/${metadata.page}">[${index}]</a>`
  );

  const text = useMemo(() => {
    if (!message.parts || !message.parts.length) return '';
    let i = 0;
    return message.parts.map((part: IMessagePart) => {
      if (part.references && part.references.length) {
        let referencesHtml = part.references.map((ref: IRef) => {
          i++;
          return renderLink(i, ref.metadata);
        }).join('');
        return `${part.text}${referencesHtml}`;
      } else {
        return part.text;
      }
    }).join('');
    // eslint-disable-next-line
  }, [message]);

  const handleClick = useCallback(async () => {
    try {
      await asyncCopyTextToClipboardFn(text, false);
    } catch(error){
      console.log(error);
    }
    // eslint-disable-next-line
  }, [text]);

  return (
    <Fragment>
      <Tooltip title={copied ? t('labels.copied') : t('labels.copy')}>
        <IconButton
          name={`Copy ${message.id}`}
          onClick={handleClick}
          size="small"
        >{copied ? (
          <CheckIcon color="success" fontSize="small" />
        ) : (
          <ContentCopyIcon fontSize="small" />
        )}</IconButton>
      </Tooltip>
    </Fragment>
  )
}

export default MessageCopyAction;
