import { FC, useEffect, useMemo } from 'react'
// Models
import { RootState } from 'app/store';
import { IJob } from 'app/models/LabellingTool';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getJobs } from 'app/store/LabellingToolJobs/LabellingToolJobs.async';
import { getAnnotations } from 'app/store/LabellingToolAnnotations/LabellingToolAnnotations.async';
// Selectors
import { selectLabellingToolsSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
import { selectMyUserPermission } from 'app/store/Users/Users.selectors';
import { selectJobs } from 'app/store/LabellingToolJobs/LabellingToolJobs.selectors';
// ToDO
import { selectGridResizerWidth } from 'app/store/AppUI/AppUI.selectors';
import { selectPage } from 'app/store/page/page.selectors';
// EndToDO
// Mui
import { Paper } from '@mui/material';
//
import LabellingJobsList from './LabellingJobsList';
import LabellingToggle from './LabellingToggle';

const LabellingToolsSidebar:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const open = useAppSelector(selectLabellingToolsSidebarOpen);
  const myUserPermission:string[] | null = useAppSelector((state:RootState) => selectMyUserPermission(state, {
    permission: 'labellingtool:job'
  }));
  // ToDO
  const gridResizerWidth:number = useAppSelector(selectGridResizerWidth);
  const page:any = useAppSelector((state:any) => selectPage(state));
  // End: ToDO
  const jobs:IJob[] | null = useAppSelector(selectJobs);

  const width = useMemo(() => {
    return 100 - gridResizerWidth - 0.5;
  }, [gridResizerWidth]);

  const ids = useMemo(() => {
    if ( !myUserPermission || !myUserPermission.length ) return null;
    return myUserPermission.reduce((acc:number[], cur:string) => {
      const [,,jobId] = cur.split(':');
      if ( jobId ) acc = [...acc, Number(jobId)];
      return acc;
    }, []);
  }, [myUserPermission])

  useEffect(() => {
    if ( ids && !jobs ){
      dispatch(getJobs({ ids }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( ids && page ){
      const sourceUrn = `urn:cc:page:${page.documentId}_${page.pageNum}`;
      dispatch(getAnnotations({ jobIds: ids, sourceUrn }));
    }
    // eslint-disable-next-line
  }, [])

  if ( !myUserPermission || !myUserPermission.length ) return null;
  return (
    <Paper
      sx={{
        borderTop: 0,
        borderRight: 0,
        borderBottom: 0,
        width: `${width}%`,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        position: 'absolute',
        top: 0, right: 0, bottom: 0,
        margin: -4,
        p: 4,
        zIndex: 2,
        transform: `translateX(${open ? 0 : `100%`})`
      }}
      variant="outlined"
      square
    >
      <LabellingJobsList />
      <LabellingToggle />
    </Paper>
  )
}

export default LabellingToolsSidebar;
