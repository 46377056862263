// import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';

export const selectMultiFactoryData = (state:RootState) => state[Reducers.Auth].multiFactory;
export const selectMultiAccountsData = (state:RootState) => state[Reducers.Auth].multiAccounts;
export const selectAuthenticated = (state:RootState) => state[Reducers.Auth].authenticated;
export const selectLoading = (state:RootState) => state[Reducers.Auth].loading;
