import { FC } from 'react';
// Model
import ILabel from 'app/models/Label';
// Mui
import { Box, Chip } from '@mui/material';

type Props = {
  id: number | string;
  labels: ILabel[] | undefined;
  onRemove?: (index:number) => void;
};

const Labels:FC<Props> = ({
  // Props
  id, labels, onRemove
}) => {
  if ( !labels || !labels.length ) return null;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      {labels.map((label:ILabel, index:number) => (
        <Chip
          key={`${id}-label-item-${index}`}
          className={label.color}
          sx={{
            borderRadius: '4px !important',
            py: '8px !important',
            px: '12px !important',
            '& .MuiChip-label': {
              padding: '0 !important',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px'
            },
            '& .MuiChip-deleteIcon': {
              margin: '0 -4px 0 8px'
            }
          }}
          label={label.name}
          onDelete={typeof onRemove !== 'undefined' ? () => onRemove(index) : undefined}
        />
      ))}
    </Box>
  )
}

export default Labels;