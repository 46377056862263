import React, { Fragment } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectStapleMode, selectStapleById, selectPageDuplicateInWorkspace } from 'app/store/page/page.selectors';
// Dialogs
import PageCompareDialog from '@root/dialogs/PageCompareDialog';

import { selectIsPageAdditional } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Dialogs
import CardPageDateDialog from 'app/dialogs/CardPageDateDialog';
import CardPageTagsDialog from 'app/dialogs/CardPageTagsDialog';
// Components
import { Box, Tooltip, Chip } from '@mui/material';
// Selectors
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
import { selectEpisodeEntity } from 'app/store/Episodes/Episodes.selectors';
import { selectHasPageAllEpisodesAnyNotes } from 'app/store/Episodes/Episodes.selectors';
// Utilities
import useCaseStatus from 'app/hooks/useCaseStatus';
import useToggle from 'app/hooks/useToggle';

// Components
import Icon from 'app/components/Icon';

import useFormattedDate from 'app/hooks/useFormattedDate';
import useFormattedTime from 'app/hooks/useFormattedTime';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

const CardHeader = ({
  // Props
  page, ...props
}) => {
  const { t } = useTranslation('common');

  const stapleId = page.staple && page.staple.id ? page.staple.id : null;

  const { documentId, pageNum, staple = null } = page;
  const { episodeId, index, isFilesPage, isWorkspacePage, isSearchPage } = props;

  // State
  const trialMode = useSelector(selectTrialMode);
  const workspaceType = useSelector(selectWorkspaceType);
  const staplePages = useSelector((state) => selectStapleById(state, { stapleId }));
  const stapleMode = useSelector(selectStapleMode);
  const pageDuplicate = useSelector((state) => selectPageDuplicateInWorkspace(state, { documentId, pageNum }));
  // ToDO
  const isPageAdditional = useSelector(state => selectIsPageAdditional(state, { documentId }));

  const episodeEntity = useSelector((state) => selectEpisodeEntity(state, { episodeId }));
  const parentEpisodeEntity = useSelector((state) => selectEpisodeEntity(state, { episodeId: page.parentEpisodeId }));
  // const episode = useSelector((state) => getPageEpisode(state, { episodeId }));
  // const parentEpisode = useSelector((state) => getPageParentEpisode(state, { parentEpisodeId: page.parentEpisodeId || null }));
  const hasNotes = useSelector((state) => selectHasPageAllEpisodesAnyNotes(state, { documentId, pageNum, stapleId: staple?.id }));

  const { isArchived } = useCaseStatus();

  const formattedParentDate = useFormattedDate(parentEpisodeEntity?.date);
  const formattedParentTime = useFormattedTime(parentEpisodeEntity?.time);

  const formattedDate = useFormattedDate(episodeEntity?.date);
  const formattedTime = useFormattedTime(episodeEntity?.time);

  const [ dialogCompareOpen, setDialogCompareOpen ] = React.useState(false);

  const toggleDialogCompare = () => {
    if ( trialMode ) return;
    setDialogCompareOpen((prevState) => !prevState);
  }

  const { open, toggle } = useToggle();
  const { open:openCardPageDateDialog, toggle:toggleCardPageDateDialog } = useToggle();

  return (
    <React.Fragment>
      <div className="cp-head">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
          <span
            className="cp-text flex-grow-1"
          >{workspaceType === 'pages' ? t('components.cardPage.page') : t('components.cardPage.episode')} {typeof index !== 'undefined' ? index + 1 : pageNum}</span>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            {workspaceType === 'pages' && page.staple ? (
              <Tooltip
                title={`${t('components.cardPage.pagesInStaple', { pages: staplePages?.length || 0 })}`}
                aria-label={undefined}
              ><Box><Icon icon="stapled" size="small" /></Box></Tooltip>
            ) : null}
            {!trialMode ? (
              <React.Fragment>
                {isPageAdditional && isWorkspacePage ? (
                  <Tooltip title={t('labels.additional')} aria-label={undefined}>
                    <Box><Icon icon="additional" size="small" /></Box>
                  </Tooltip>
                ) : null}
                {hasNotes ? (
                  <Tooltip title={t('labels.notes')} aria-label={undefined}>
                    <Box><Icon icon="notes" size="small" /></Box>
                  </Tooltip>
                ) : null}
                {((isFilesPage || isSearchPage) && pageDuplicate) ? (
                  <Tooltip title={t('components.cardPage.showDuplicates')} aria-label={undefined}>
                    <Box onClick={toggleDialogCompare}>
                      <Icon icon="columns_2" size="small" />
                    </Box>
                  </Tooltip>
                ) : null}
              </React.Fragment>
            ) : null}
          </Box>
        </Box>
        {episodeEntity ? (
          <Fragment>
            {formattedDate ? (
              <Box sx={{ mb: 1 }}>
                <span className="cp-text">
                  {formattedDate}{formattedTime ? ` ${formattedTime}` : null}
                </span>
              </Box>
            ) : null}
            {!trialMode && page.tag ? (
              <Chip
                sx={{
                  fontWeight: 500
                }}
                label={page.tag}
                size="small"
                onClick={undefined}
              />
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            {(!staple || staple.order === 1) ? (
              <Fragment>
                <Box sx={{ mb: 1 }}>
                  <span
                    className="cp-text"
                    onClick={() => {
                      if ( trialMode ) return false;
                      if ( stapleMode ) return false;
                      if ( isArchived ) return false;
                      toggleCardPageDateDialog();
                    }}
                  >{
                    formattedParentDate ? (
                      <Fragment>
                        {formattedParentDate}{formattedParentTime ? ` ${formattedParentTime}` : null}
                      </Fragment>
                    ) : t('components.cardPage.setDate')
                  }</span>
                </Box>
                {!trialMode ? (
                  <Chip
                    sx={{
                      color: !page.tag ? 'rgba(0,0,0,0.54)' : 'initial',
                      fontWeight: 500
                    }}
                    label={page.tag || t('components.cardPage.setTag')}
                    size="small"
                    onClick={() => {
                      if ( stapleMode ) return false;
                      if ( isArchived ) return false;
                      toggle();
                    }}
                  />
                ) : null}
              </Fragment>
            ) : null}
          </Fragment>
        )}
      </div>
      {/* Page tag dialog */}
      {open ? (
        <CardPageTagsDialog
          open={open}
          onClose={toggle}
          page={page}
        />
      ) : null}
      {openCardPageDateDialog ? (
        <CardPageDateDialog
          open={openCardPageDateDialog}
          onClose={toggleCardPageDateDialog}
          page={page}
        />
      ) : null}
      {dialogCompareOpen ? (
        <PageCompareDialog
          open={dialogCompareOpen}
          onClose={toggleDialogCompare}
          page={page}
          pageDuplicate={pageDuplicate}
        />
      ) : null}
    </React.Fragment>
  )
}

export default CardHeader;
