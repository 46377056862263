import { FC, Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Mui
import { Divider, Typography } from '@mui/material';
// Material
import { Input } from 'app/components/Mui';
import Phone from 'app/components/Phone';
// Utility
import { isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  opposingDetails: {
    opposingCounsel: {
      name: string;
      email: string;
      phone: string;
    },
    caseNumber: string;
    courtJurisdiction: string;
    notes: string;
  };
}

type Props = {
  disabled?:boolean;
  onSetError: (error:boolean) => void;
}

const OpposingDetailsTabContent:FC<Props> = ({ disabled = false, onSetError }) => {
  const { t } = useTranslation('common');

  const { control, formState: { errors, isSubmitted } } = useFormContext<IFormData>();

  useEffect(() => {
    if ( !isSubmitted ) return;
    onSetError(Boolean(Object.keys(errors.opposingDetails || {}).length));
    // eslint-disable-next-line
  }, [isSubmitted, Object.keys(errors)]);

  return (
    <Fragment>
      {/* Case number */}
      <Controller
        control={control} name="opposingDetails.caseNumber"
        render={({ field }) => (
          <Input {...field} label={t('dialogs.caseDetails.caseNumber')} disabled={disabled} />
        )}
      />
      {/* Court jurisdiction */}
      <Controller
        control={control} name="opposingDetails.courtJurisdiction"
        render={({ field }) => (
          <Input {...field} label={t('dialogs.caseDetails.courtJurisdiction')} disabled={disabled} />
        )}
      />
      <Controller
        control={control} name="opposingDetails.notes"
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.notes')}
            multiline
            rows={8}
            disabled={disabled}
          />
        )}
      />
      <Divider sx={{ my: '16px' }} />
      <Typography mb="8px">{t('dialogs.caseDetails.opposingCounsel')}</Typography>
      {/* Name */}
      <Controller
        control={control} name="opposingDetails.opposingCounsel.name"
        render={({ field }) => (
          <Input {...field} label={t('labels.name')} disabled={disabled} />
        )}
      />
      {/* E-mail */}
      <Controller
        control={control} name="opposingDetails.opposingCounsel.email"
        rules={{ pattern: isEmailValid }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.email')} type="email"
            error={Boolean(errors.opposingDetails?.opposingCounsel?.email)}
            helperText={(errors.opposingDetails?.opposingCounsel?.email?.message as string) || ''}
            disabled={disabled}
          />
        )}
      />
      {/* Phone */}
      <Controller
        control={control} name="opposingDetails.opposingCounsel.phone"
        render={({ field }) => (
          <Phone {...field} label={t('labels.cellPhone')} disabled={disabled} />
        )}
      />
    </Fragment>
  )
}

export default OpposingDetailsTabContent;