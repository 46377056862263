import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptionsByFileType } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Box, Typography, MenuItem } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
import IOption from 'app/models/Option';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  field: any;
  index: number;
}

const DocumentItem:FC<Props> = ({
  // Props
  field, index
}) => {
  const { t } = useTranslation('common');
  // State
  const collectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType(state, {
    fileFormat: field.fileFormat,
    ocred: field.ocred
  }));

  const { register, control, formState:{ errors } } = useFormContext();

  const getCollectionIdError = () => {
    if ( !errors || !errors.documentPlacements ) return '';
    const documentPlacementError = (errors.documentPlacements as any)[index];
    if ( !documentPlacementError ) return '';
    return documentPlacementError.collectionId?.message || '';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        py: 2
      }}
    >
      <input {...register(`documentPlacements.${index}.name`) as any} type="hidden" />

      <Typography sx={{ flex: 1 }}>{field.name}</Typography>
      <Controller
        control={control} name={`documentPlacements.${index}.collectionId`} defaultValue={field.collectionId}
        rules={{ required: isRequired }}
        render={({ field:f }) => (
          <Select
            {...f}
            sx={{ maxWidth: 200 }}
            label={t('labels.documentCollection')}
            options={collectionOptions}
            required
            error={Boolean(getCollectionIdError())}
            helperText={getCollectionIdError()}
            renderOptions={(option:IOption, index:number) => (
              <MenuItem
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                key={`option item ${index} ${option.id}`}
                value={option.id.toString()}
              >
                <Typography variant="subtitle1">{option.name}</Typography>
                <Typography sx={{ maxWidth: 320, whiteSpace: 'normal' }} variant="caption">{option.props.description}</Typography>
              </MenuItem>
            )}
            margin="none"
            size="small"
          />
        )}
      />
    </Box>
  )
};

export default DocumentItem;
