import { FC, Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// Types
import GrantTypes from 'app/types/GrantTypes';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { login } from 'app/store/Auth/Auth.async';
import { updatePassword } from 'app/store/Users/Users.async';
// Selectors
import { selectLoading } from 'app/store/Users/Users.selectors';
// Mui
import { Box, Container, Paper } from '@mui/material';
// Components
import Brand from 'app/components/Brand';
import LanguagePicker from 'app/components/LanguagePicker';
import PasswordHints from 'app/components/PasswordHints';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  token: string;
  username: string;
  newPassword: string;
  reNewPassword: string;
}

const ResetPasswordView:FC = () => {
  const { t } = useTranslation('common');

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const urlEmail = params.get('email') || '';
  const urlToken = params.get('token') || '';
  const accountId = params.get('accountId') || '';

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { register, control, handleSubmit, formState:{ errors }, watch } = useForm<IFormData>({
    defaultValues: {
      token: urlToken,
      username: urlEmail,
      newPassword: '',
      reNewPassword: ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      const { token, username, reNewPassword:password } = data;
      const updatePasswordData = { token, password };
      const loginData:any = { grantType: GrantTypes.Password, username, password };
      if ( accountId ) loginData['accountId'] = Number(accountId);
      await dispatch(updatePassword(updatePasswordData)).unwrap();
      await dispatch(login(loginData));
    } catch(error){}
  });

  return (
    <Fragment>
      <Container sx={{ pt: 8 }} maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Brand />
        </Box>
        <Paper
          sx={{ p: 8 }}
          variant="outlined"
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          <input {...register('token') as any} type="hidden" />
          <input {...register('username') as any} type="hidden" />
          {/* New password */}
          <Controller
            control={control} name="newPassword"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.newPassword')} id="newPassword" type="password" autoComplete="current-password"
                required
                error={Boolean(errors.newPassword)}
                helperText={errors.newPassword?.message || ''}
              />
            )}
          />
          {/* Confirm new password */}
          <Controller
            control={control} name="reNewPassword"
            rules={{
              required: isRequired,
              validate: (value:string) => value === watch('newPassword') || t('validations.passwordDoesntMatch')
            }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.confirmNewPassword')} id="reNewPassword" type="password" autoComplete="current-password"
                required
                error={Boolean(errors.reNewPassword)}
                helperText={errors.reNewPassword?.message || ''}
              />
            )}
          />
          <PasswordHints />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
            <LoadingButton
              name="Update password form"
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >{t('pages.updatePasswordPage.updatePassword')}</LoadingButton>
            <Button
              name="Go to login"
              component={Link}
              to="/"
              fullWidth
            >{t('pages.updatePasswordPage.goToLogin')}</Button>
          </Box>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <LanguagePicker />
        </Box>
      </Container>
    </Fragment>
  )
}

export default ResetPasswordView;