import React, { Fragment, useEffect } from 'react';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
// Types
import Statuses from '@root/types/Statuses';
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage } from '@root/models/Page';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectIsLoading, selectStapleById, selectStapleMode, selectIsPageInCompare, selectStatus } from 'app/store/page/page.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Service
import { removePageFromStaple, generateWorkspaceOrder, isSamePages } from 'app/store/page/page.service';
// MaterialUI
import Tooltip from '@mui/material/Tooltip';
// Dialogs
import PageColorsDialog from 'app/dialogs/CardPageColorsDialog';
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Components
import Icon from 'app/components/Icon';
// 
import PageCardFooterStaple from './PageCardFooterStaple';
import PageCardFooterDuplicate from './PageCardFooterDuplicate';
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
import { useParams } from 'react-router-dom';
import { IconButton } from 'app/components/Mui';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';
// 
import CardPageActionsCheck from './CardPageActionsCheck';
import useToggle from 'app/hooks/useToggle';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';

type Props = {
  page: IPage;
  isWorkspacePage: boolean;
  isDuplicatesPage: boolean;
  isSearchPage: boolean;
}

const PageCardFooter:React.FC<Props> = ({
  // Props
  page, isWorkspacePage, isDuplicatesPage, isSearchPage
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  const stapleId = page.staple && page.staple.order === 1 ? page.staple.id : null;
  // Dispatch
  const dispatch = useAppDispatch();

  const { isArchived } = useCaseStatus();

  // State
  const trialMode = useAppSelector(selectTrialMode);
  const workspaceType = useAppSelector(selectWorkspaceType);
  const isLoading:boolean = useAppSelector(selectIsLoading);
  const status:Statuses = useAppSelector(selectStatus);
  const staplePages:Array<IPage> | null = useAppSelector((state:any) => selectStapleById(state, { stapleId }))
  const stapleMode = useAppSelector(selectStapleMode);
  const isPageInCompare = useAppSelector((state:any) => selectIsPageInCompare(state, {
    documentId: page.documentId,
    pageNum: page.pageNum,
    stapleId
  }))
  // ToDO
  const episodes:any = useAppSelector(selectEpisodesAll);

  const [ isButtonDisabled, setIsButtonDisabled ] = React.useState(false);

  const { open:openPageColorsDialog, toggle:togglePageColorsDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  useEffect(() => {
    setIsButtonDisabled(status !== 'Initial');
    // eslint-disable-next-line
  }, [status]);

  const handleTogglePageWorkspace = async () => {
    setIsButtonDisabled(true);

    const inWorkspace = !page.inWorkspace;
    const message = inWorkspace ? pageMessages.addPageToWorkspace : pageMessages.removePageFromWorkspace;
    const workspaceOrder = inWorkspace ? generateWorkspaceOrder(
      episodes, {
        parentEpisodeId: page.parentEpisodeId,
        workspaceOrder: page.workspaceOrder || null
      }
    ) : null;
    if ( staplePages && page.staple && page.staple.order === 1 ){
      const pagesToUpdate = staplePages.map((page:IPage) => ({
        caseId,
        documentId: page.documentId,
        pageNum: page.pageNum,
        version: page.version,
        inWorkspace,
        workspaceOrder
      }));
      dispatch(PageActions.patchPages(pagesToUpdate, message));
    } else {
      dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
        caseId,
        version: page.version,
        inWorkspace,
        workspaceOrder
      }, message))
    }
  }

  const handleTogglePageCompare = () => {
    if ( isPageInCompare ){
      dispatch(PageActions.removePageFromCompare(
        page.documentId,
        page.pageNum,
        stapleId
      ));
    } else {
      dispatch(PageActions.addPageToCompare(
        page.documentId,
        page.pageNum,
        stapleId
      ));
    }
  }

  const handleStapleModeEdit = () => {
    if ( page.staple && page.staple.id ){
      dispatch(PageActions.setStapleModeEdit(page.documentId, page.staple.id, isWorkspacePage));
    }
  }

  const handleConfirm = async () => {
    if ( !staplePages ) return;

    const parentStaplePage = staplePages[0];

    let parentEpisodeId:any = parentStaplePage.parentEpisodeId;
    if ( parentEpisodeId ){
      const episode = episodes.find((episode:any) => episode.id === parentEpisodeId);
      if ( episode && episode.pageNum !== parentStaplePage.pageNum ){
        parentEpisodeId = null;
      }
    }

    const pagesToUpdate = staplePages.map((page:IPage, index:number) => removePageFromStaple(page, index, episodes)).map((page:IPage) => {
      if ( isSamePages(page, parentStaplePage) ) return { ...page, parentEpisodeId };
      return page;
    });

    dispatch(PageActions.patchPages(pagesToUpdate, pageMessages.removeStaple));

    if ( isPageInCompare ){
      dispatch(PageActions.removePageFromCompare(
        page.documentId,
        page.pageNum,
        stapleId
      ));
    }
  }

  return (
    <React.Fragment>
      <div className="cp-foot">
        {stapleMode ? (
          <PageCardFooterStaple page={page} />
        ) : (
          <React.Fragment>
            {workspaceType === 'pages' ? (
              !trialMode && (!page.staple || page.staple.order === 1) ? (
                <Tooltip title={page.workspaceOrder ? t('components.cardPage.removePageFromWorkspace') : t('components.cardPage.addPageToWorkspace')}>
                  <span>
                    <IconButton
                      name={page.workspaceOrder ? 'Remove page from workspace' : 'Add page to workspace'}
                      aria-label={page.workspaceOrder ? 'Remove page from workspace' : 'Add page to workspace'}
                      disabled={isArchived || isButtonDisabled}
                      onClick={handleTogglePageWorkspace}
                      color={page.workspaceOrder ? 'primary' : 'default'}
                      size="small"
                    ><Icon icon="add_to_workspace" /></IconButton>
                  </span>
                </Tooltip>
              ) : null
            ) : null}
            <Tooltip title={isPageInCompare ? t('components.cardPage.removePageFromCompare') : t('components.cardPage.addPageToCompare')}>
              <IconButton
                name={isPageInCompare ? 'Remove page from compare' : 'Add page to compare'}
                aria-label={isPageInCompare ? 'Remove page from compare' : 'Add page to compare'}
                onClick={handleTogglePageCompare}
                color={isPageInCompare ? 'primary' : 'default'}
                size="small"
              ><Icon icon="compare" /></IconButton>
            </Tooltip>
            {workspaceType === 'pages' ? (
              <React.Fragment>
                {!trialMode && (!page.staple || page.staple.order === 1) ? (
                  <Tooltip title={t('components.cardPage.pageColor')}>
                    <span>
                      <IconButton
                        name="Page colors"
                        aria-label="Page colors"
                        disabled={isArchived || isButtonDisabled}
                        onClick={togglePageColorsDialog}
                        size="small"
                      ><Icon icon="color" /></IconButton>
                    </span>
                  </Tooltip>
                ) : null}
                {(isDuplicatesPage || isSearchPage) ? (
                  <React.Fragment>
                    {isDuplicatesPage ? <PageCardFooterDuplicate documentId={page.documentId} pageNum={page.pageNum} /> : null}
                  </React.Fragment>
                ) : (
                  !trialMode && page.staple && page.staple.order === 1 ? (
                    <Fragment>
                      <Tooltip title={t('components.cardPage.editStaple')}>
                        <span>
                          <IconButton
                            name="Edit staple"
                            aria-label="Edit staple"
                            disabled={isArchived || isButtonDisabled}
                            onClick={handleStapleModeEdit}
                            size="small"
                          ><Icon icon="staple_edit" /></IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={t('components.cardPage.deleteStaple')}>
                        <span>
                          <IconButton
                            name="Delete staple"
                            aria-label="Delete staple"
                            disabled={isArchived || isButtonDisabled}
                            onClick={toggleConfirmationDialog}
                            size="small"
                          ><Icon icon="staple_delete" /></IconButton>
                        </span>
                      </Tooltip>
                    </Fragment>
                  ) : null
                )}
                <CardPageActionsCheck page={page} />
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
      </div>
      {openPageColorsDialog ? (
        <PageColorsDialog
          open={openPageColorsDialog}
          onClose={togglePageColorsDialog}
          page={page}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={isLoading}
          status={status}
          title={t('components.cardPage.deleteStapleFromPages')}
          content={t('components.cardPage.deleteStapleConfirmation')}
          onConfirm={handleConfirm}
        />
      ) : null}
    </React.Fragment>
  )
}

export default PageCardFooter;
