import { FC } from 'react';
// Mui
import { Toolbar, Box, Typography } from '@mui/material';
// Components
import { Button } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const BillingInvoicesToolbar:FC = () => {
  const { t } = useTranslation('common');

  return (
    <Toolbar variant="dense">
      <Typography variant="h6" sx={{ flexGrow: 1 }}>{t('pages.adminPages.billingInvoicesPage.billingInvoices')}</Typography>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Button
          name="Privacy policy"
          href="https://www.casechronology.com/privacy-policy"
          target="_blank"
          color="primary"
          size="small"
        >{t('pages.adminPages.billingInvoicesPage.privacyPolicy')}</Button>
        <Button
          name="Security policy"
          href="https://www.casechronology.com/security-policy"
          target="_blank"
          color="primary"
          size="small"
        >{t('pages.adminPages.billingInvoicesPage.securityPolicy')}</Button>
        <Button
          name="Refund/Cancellation policy"
          href="https://www.casechronology.com/refund-cancellation"
          target="_blank"
          color="primary"
          size="small"
        >{t('pages.adminPages.billingInvoicesPage.refundPolicy')}</Button>
      </Box>
    </Toolbar>
  )
}

export default BillingInvoicesToolbar;
