import config from '../../config';
// Types
import SocketMessage from 'app/types/SocketMessages';
// Service
import LocalStorageService from "./LocalStorage.service";

class WebSocketService {
  private _socket: WebSocket | null;
  private _retryCount: number;
  private _messageListeners: ((message:SocketMessage) => void)[] = [];

  constructor() {
    this._socket = null;
    this._retryCount = 0;
  }

  public connect(): void {
    console.log('Creating WebSocket connection');

    this._socket = new WebSocket(`${config.socketUrl}?X-Auth-Token=${LocalStorageService.getAccessToken()}`);

    this._socket.onopen = () => {
      console.log('WebSocket connected');
      this._retryCount = 0; // Reset retry count on successful connection
    };

    this._socket.onmessage = (event:MessageEvent) => {
      console.log('Message received:', event.data);

      const message:SocketMessage = JSON.parse(event.data);

      // Notify all message listeners
      this._messageListeners.forEach(listener => listener(message));
    };

    this._socket.onclose = (event:CloseEvent) => {
      if ( !LocalStorageService.getAccessToken() ){
        this._socket = null;
        this._retryCount = 0;
        this._messageListeners = [];
        return;
      }

      console.log(`WebSocket has abnormal close with status: ${event.code}`);
      // Attempt to reconnect after a delay
      const retryDelay = Math.min(1000 * Math.pow(2, this._retryCount), 30000); // Exponential backoff with maximum delay of 30 seconds

      console.log(`Retrying in ${retryDelay / 1000} seconds`);

      setTimeout(() => this.connect(), retryDelay);

      this._retryCount++;
    };
  }

  public addMessageListener(listener:(message:SocketMessage) => void): void {
    this._messageListeners.push(listener);
  }

  public close():void {
    if ( this._socket ) this._socket.close();
  }
}

const wsService = new WebSocketService();
export default wsService;
