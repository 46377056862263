import { FC, Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Models
import { IPage } from '@root/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// TODO
import PageActions from 'app/store/page/page.actions';
import { selectStapleById, selectIsLoading, selectStatus } from 'app/store/page/page.selectors';
import { pageMessages } from 'app/store/page/page.messages';
// End ToDO
// Components
import Dialog from 'app/components/Dialog';
import TagPicker from 'app/components/TagPicker';
import { Button, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  page?: IPage;
  pages?: IPage[];

  dialogTitle?: string;
};

const CardPageTagsDialog:FC<Props> = ({
  // Props
  open, onClose, page, pages, dialogTitle
}) => {
  const { t } = useTranslation('common');

  const stapleId = page?.staple && page?.staple.order === 1 ? page.staple.id : null;

  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const staplePages:Array<IPage> | null = useAppSelector((state:any) => selectStapleById(state, { stapleId }))
  const isLoading:boolean = useAppSelector((state:any) => selectIsLoading(state));
  const status = useAppSelector((state:any) => selectStatus(state));

  useEffect(() => {
    if ( status === 'Success' ) onClose();
    // eslint-disable-next-line
  }, [status]);

  const [ tags, setTags ] = useState<(string | null)[]>(page?.tag ? [page.tag] : []);

  const handleClick = (tag:string | null) => {
    const nextTags = tags.includes(tag)
      ? tags.filter((t:string | null) => t !== tag)
      : [tag]
    ;
    setTags(nextTags);
  }

  const handleSave = async () => {
    const tag = tags.length ? tags.join('') : null;
    const message = tag ? pageMessages.updatePageTag : pageMessages.removePageTag;
    if ( pages && pages.length ){
      const pagesToUpdate = pages.map((page:IPage) => ({
        caseId,
        documentId: page.documentId,
        pageNum: page.pageNum,
        version: page.version,
        tag
      }));
      dispatch(PageActions.patchPages(pagesToUpdate, message));
    } else {
      if ( staplePages && staplePages.length ){
        const pagesToUpdate = staplePages.map((page:IPage) => ({
          caseId,
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          tag
        }));
        dispatch(PageActions.patchPages(pagesToUpdate, message));
      } else {
        if ( page ){
          dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
            caseId,
            version: page.version,
            tag
          }, message))
        }
      }
    }
  }

  const isButtonDisabled = Boolean((pages && pages.length) && !tags.length);

  const actions = (
    <Fragment>
      <Button
        name="Cancel page tag dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Save page tag dialog"
        disabled={isButtonDisabled}
        onClick={handleSave}
        loading={isLoading}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={dialogTitle || t('dialogs.cardPageTags.setPageTag')}
      actions={actions}
    >
      <TagPicker
        id="cardPageTagDialog"
        activeTags={tags}
        onClick={handleClick}
      />
    </Dialog>
  )
}

export default CardPageTagsDialog;