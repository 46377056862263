import React from 'react';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
// Models
import { IPage } from '@root/models/Page';
// State
import { useDispatch, useSelector } from 'react-redux';
// Selectors
import { selectLastOpenedFilePages } from 'app/store/TODO2_app/app.selectors';
import { selectFilteredDocumentPages } from 'app/store/page/page.selectors';
// MaterialUI
import Button from '@mui/material/Button';
import { selectColumnValue } from 'app/store/ui/uiSelectors';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  listRef: any;
};

let timeout:any;

const CARD_WIDTH = 300;

const GoToLastOpenedPage:React.FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation('common');

  const { caseId, documentId } = useParams<{ caseId:string, documentId:string }>();

  // Dispatch
  const dispatch = useDispatch();
  // State
  const lastOpenedFilePages:{ [key:number]:string } = useSelector(selectLastOpenedFilePages);
  const columnValue:string = useSelector(selectColumnValue);
  const pages:IPage[] | null = useSelector((state:any) => selectFilteredDocumentPages(state, { documentId: Number(documentId) })) || [];

  const handleGoToLastOpenedFilePage = () => {
    clearTimeout(timeout);

    const pageId = lastOpenedFilePages[Number(caseId)];

    if ( pageId ){
      const [ docId, pageNum ] = pageId.split(':').map(Number);

      let timeoutTime = 0;

      if ( docId !== Number(documentId) ){
        dispatch(push(`/admin/cases/${caseId}/documents/${docId}?pageNum=${pageNum}`));
        timeoutTime = 1000;
      }

      timeout = setTimeout(() => {
        if ( listRef.current && pages ){
          const foundedPageIndex = pages.findIndex((page:any) => page.pageNum === Number(pageNum));
          if ( typeof foundedPageIndex !== 'undefined' ){
            const width = listRef.current.props.width;
            const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
            const rowIndex = Math.floor(foundedPageIndex/itemsPerRow);
            listRef.current.scrollToItem(rowIndex, 'center');
          }
        }
      }, timeoutTime);
    }
  }

  if ( !lastOpenedFilePages[Number(caseId)] ) return null;
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={handleGoToLastOpenedFilePage}
    >{t('labels.goToLastOpened')}</Button>
  )
}

export default GoToLastOpenedPage;
