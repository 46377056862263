import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IJob } from 'app/models/LabellingTool';
// Async
import { getJobs, getJob } from './LabellingToolJobs.async';

interface IState {
  jobs: IJob[] | null;
  job: IJob | null;
  loading: boolean;
};

const initialState:IState = {
  jobs: null,
  job: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.LabellingToolJobs,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get jobs
    builder.addCase(getJobs.pending, (state) => {
      state.jobs = null;
    })
    builder.addCase(getJobs.fulfilled, (state, action:PayloadAction<IJob[]>) => {
      state.jobs = action.payload;
    })
    // Get job
    builder.addCase(getJob.pending, (state) => {
      state.job = null;
    })
    builder.addCase(getJob.fulfilled, (state, action:PayloadAction<IJob>) => {
      state.job = action.payload;
    })
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const LabellingToolJobsActions = slice.actions;

export default slice.reducer;