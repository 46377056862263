import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Models
import { RootState } from 'app/store';
import IConversation from 'app/models/Conversation';
import IMessage from 'app/models/Message';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { markAsRead } from 'app/store/Conversations/Conversations.async';
import { createMessage } from 'app/store/Messages/Messages.async';
// Selectors
import { selectUnseenConversation } from 'app/store/Conversations/Conversations.selectors';
import { selectLoading } from 'app/store/Messages/Messages.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Input, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const MessagesForm:FC = () => {
  const { t } = useTranslation('common');

  const { conversationId } = useParams<{ conversationId:string }>();

  const conversationIdToNum = Number(conversationId);

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const unseenConversation:IConversation | null = useAppSelector((state:RootState) => selectUnseenConversation(state, {
    conversationId: conversationIdToNum
  }));
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      text: ''
    }
  });

  const onSubmit = handleSubmit(async (data:any) => {
    if ( !data.text.trim() ) return;

    try {
      const message:IMessage = await dispatch(createMessage({
        ...data,
        conversationId: conversationIdToNum,
      })).unwrap();

      // If is unread message, mark as read after send message
      if ( unseenConversation ){
        dispatch(markAsRead({
          conversationId: conversationIdToNum,
          data: { messageId: message.id }
        }))
      }

      reset({ text: '' });
    } catch(error){}
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        bgcolor: 'white',
        p: 4
      }}
      component="form"
      onSubmit={onSubmit}
      noValidate
      autoComplete="off"
    >
      <Controller
        control={control} name="text"
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.message')}
            margin="none"
            size="small"
            disabled={loading}
          />
        )}
      />
      <LoadingButton
        name="Send message"
        loading={loading}
        type="submit"
        variant="contained"
        color="primary"
      >{t('labels.send')}</LoadingButton>
    </Box>
  )
}

export default MessagesForm;
