import { FC } from 'react';
import { Redirect, Route } from "react-router-dom";
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Hooks
import useRedirectPath from 'app/hooks/useRedirectPath';

type Props = {
  roles: UserRoles[];
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute:FC<Props> = ({
  // Props
  roles, component:Component, ...rest
}) => {
  // State
  const authenticated:boolean | undefined = useAppSelector(selectAuthenticated);
  const myUser = useAppSelector(selectMyUser) as IMyUser;

  const [ , termsAndConditionsPath, redirectPath ] = useRedirectPath();

  if ( authenticated ){
    if ( termsAndConditionsPath && (rest as any).path !== termsAndConditionsPath ) return <Redirect to={termsAndConditionsPath} />
    if ( roles.includes(myUser.role) ) return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to={redirectPath as string} />
};

export default PrivateRoute;
