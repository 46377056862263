import { FC, useRef, useState, useEffect } from 'react';
// MUI
import { Box, Skeleton, Theme } from "@mui/material";

type Props = {
  relative?: boolean;
}

const MessageSkeleton:FC<Props> = ({
  // Props
  relative = false
}) => {
  return (
    <Box
      sx={{
        position: relative ? 'relative' : 'absolute',
        top: 0, left: 0,
        width: '100%',
        height: relative ? 340 : '100%',
        bgcolor: (theme:Theme) => theme.palette.primary.main,
        borderRadius: 2,
        p: 4,
        overflow: 'hidden'
      }}
    ><SkeletonBuilder /></Box>
  )
}

export default MessageSkeleton;

const SkeletonBuilder:FC = () => {
  const skeletonHeight = 28; // Fixed height for each Skeleton
  
  const boxRef = useRef<HTMLDivElement | null>(null);

  const [ skeletonCount, setSkeletonCount ] = useState<number>(0);

  useEffect(() => {
    if ( !boxRef.current ) return;
    const parentHeight = boxRef.current.clientHeight;
    const count = Math.ceil(parentHeight / skeletonHeight);
    setSkeletonCount(count);
    // eslint-disable-next-line
  }, []);

  const skeletonArray = Array.from({ length: skeletonCount }, () => ({
    width: `${Math.floor(Math.random() * 51) + 50}%`, // Random width between 50% and 100%
  }));

  return (
    <Box ref={boxRef} sx={{ width: '100%', height: '100%' }}>
      {skeletonArray.map((skeleton, index) => (
        <Skeleton key={index} width={skeleton.width} height={skeletonHeight} />
      ))}
    </Box>
  )
}
