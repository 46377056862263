import { createApi } from "@reduxjs/toolkit/query/react";
// Utilities
import baseQuery from "app/utilities/BaseQuery";

const dmsDocumentsApi = createApi({
  reducerPath: 'documentManagementService/documents.api',
  baseQuery,
  endpoints: (builder) => ({
    getDocument: builder.query({
      query: (documentId:number | undefined) => `/dms/documents/${documentId}`
    })
  })
});

export const { useGetDocumentQuery, useLazyGetDocumentQuery } = dmsDocumentsApi;

export default dmsDocumentsApi;
