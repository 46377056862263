import { useAppSelector } from 'app/hooks/useStore';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { FC } from 'react';

type Props = {
  documentId: number;
  pageNum: number;
  colors?: string[];
}

const PageCardColors:FC<Props> = ({
  // Props
  documentId, pageNum, colors
}) => {
  const trialMode = useAppSelector(selectTrialMode);

  if ( trialMode || !colors ) return null;
  return (
    <div className="cp-colors">
      {colors.map((color:string) => (
        <span
          key={`page ${documentId}:${pageNum} colors ${color}`}
          className={`cp-colors-item bg-${color}`}
        />
      ))}
    </div>
  )
}

export default PageCardColors;
