import { FC, ReactNode, createContext, useContext, useState, useCallback, useEffect } from "react";
// Model
import { IMessage } from "app/models/ChatAI";

type ContextProps = {
  refineMessages: IMessage[];
  activeIndex: number;
  onPrev: () => void;
  onNext: () => void;
}

const Context = createContext<ContextProps>(null!);

export const useMessageContext = () => {
  const context = useContext(Context);
  if ( !context ) throw new Error('useMessageContext must be used within a ListProvider');
  return context;
}

type Props = {
  refineMessages: IMessage[];
  children: ReactNode;
}

const MessageProvider:FC<Props> = ({
  // Props
  refineMessages, children
}) => {
  const [ activeIndex, setActiveIndex ] = useState(0);

  const handlePrev = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? refineMessages.length - 1 : prevIndex - 1));
    // eslint-disable-next-line
  }, [refineMessages]);

  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex === refineMessages.length - 1 ? 0 : prevIndex + 1));
    // eslint-disable-next-line
  }, [refineMessages]);

  useEffect(() => {
    if (
      refineMessages.length <= 1 ||
      activeIndex === refineMessages.length - 1
    ) return;
    setActiveIndex(refineMessages.length - 1);
    // eslint-disable-next-line
  }, [refineMessages.length]);

  return (
    <Context.Provider value={{
      refineMessages,
      activeIndex,
      onPrev: handlePrev,
      onNext: handleNext
    }}>{children}</Context.Provider>
  );
}

export default MessageProvider;
