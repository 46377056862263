import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IState, { ITerms } from "./Terms.models";
// Async
import {
  getTermsAndConditions, createTerms, updateTerms, deleteTerms,
  publishTerms, archiveTerms
} from './Terms.async';

const initialState:IState = {
  termsAndConditions: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.Terms,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get teams
    builder.addCase(getTermsAndConditions.pending, (state) => {
      state.termsAndConditions = null;
    });
    builder.addCase(getTermsAndConditions.fulfilled, (state, action:PayloadAction<ITerms[]>) => {
      state.termsAndConditions = action.payload;
    });
    // Create team
    builder.addCase(createTerms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTerms.fulfilled, (state, action:PayloadAction<ITerms>) => {
      if ( state.termsAndConditions ){
        state.termsAndConditions = [...state.termsAndConditions, action.payload]
      }
    });
    // Update team
    builder.addCase(updateTerms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTerms.fulfilled, (state, action:PayloadAction<ITerms>) => {
      if ( state.termsAndConditions ){
        state.termsAndConditions = state.termsAndConditions.map((terms:ITerms) => {
          if ( terms.id === action.payload.id ) return action.payload;
          return terms;
        });
      }
    });
    // Delete team
    builder.addCase(deleteTerms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTerms.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.termsAndConditions ){
        state.termsAndConditions = state.termsAndConditions.filter((terms:ITerms) => terms.id !== action.payload);
      }
    });
    // Publish team
    builder.addCase(publishTerms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(publishTerms.fulfilled, (state, action:PayloadAction<ITerms>) => {
      if ( state.termsAndConditions ){
        state.termsAndConditions = state.termsAndConditions.map((terms:ITerms) => {
          if ( terms.id === action.payload.id ) return action.payload;
          return terms;
        });
      }
    });
    // Publish team
    builder.addCase(archiveTerms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveTerms.fulfilled, (state, action:PayloadAction<ITerms>) => {
      if ( state.termsAndConditions ){
        state.termsAndConditions = state.termsAndConditions.map((terms:ITerms) => {
          if ( terms.id === action.payload.id ) return action.payload;
          return terms;
        });
      }
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const TermsActions = slice.actions;

export default slice.reducer;