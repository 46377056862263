import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Async
import { createAssignment, deleteAssignment } from 'app/store/CaseAssignments/CaseAssignments.async';
// Selectors
import { selectInsuranceCase } from "app/store/Cases/Cases.selectors";
// Services
import Mixpanel from "app/services/Mixpanel.service";

const CaseAssignmentsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type } = action;

  if ( type === createAssignment.fulfilled.type ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    Mixpanel.track(MixpanelTracks.CaseShareAccessAdded, { caseId: insuranceCase.id });
  }

  if ( type === deleteAssignment.fulfilled.type ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    Mixpanel.track(MixpanelTracks.CaseShareAccessRevoked, { caseId: insuranceCase.id });
  }

  return next(action);
}

export default CaseAssignmentsMiddleware;
