import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
import Colors from 'app/types/Colors';
// Models
import IPage, { IPresenterPage } from 'app/models/Page';
// Async
import { getDocumentPages, getPage, getSearchPages, patchPage, patchPages } from './DMSDocumentPages.async';
// Utilities
import { uuidv4 } from 'app/utilities/Utilities';
import PageService from "app/services/PageService";

interface IEntity {
  [key:number]: Record<number, IPage | IPresenterPage>
};

interface IState {
  page: IPage | IPresenterPage | null;
  pages: {
    ids: string[] | null;
    entities: IEntity;
    filter: {
      episodeTypes: string[];
      episodeLabels: string[];
      episodeAuthors: string[];
      tags: string[];
      colors: (Colors | string)[];
      haveDuplicateInWorkspace: boolean;
    };
  };
  searchPages: {
    list: any[];
    params: {
      keywords: string[];
      match: 'all' | 'any';
      documentIds: number[];
      date: string;
      chronologyDateRange: {
        start: string;
        end: string;
      },
      inWorkspace: string;
    }
    fetching: boolean;
  },
  stapleMode: {
    // enabled: boolean;
    type: 'create' | 'edit' | null;
    id: string | null;
    pagesIds: string[];
    disabledPagesIds: string[];
  },
  error: { status: number; props?: any; } | null;
  loading: boolean;

  // ToDO
  forceReload: boolean;
}

const initialState:IState = {
  page: null,
  pages: {
    ids: null,
    entities: {},
    filter: {
      episodeTypes: [],
      episodeLabels: [],
      episodeAuthors: [],
      tags: [],
      colors: [],
      haveDuplicateInWorkspace: false
    }
  },
  searchPages: {
    list: [],
    params: {
      keywords: [],
      match: 'all',
      documentIds: [],
      date: '',
      chronologyDateRange: {
        start: '',
        end: ''
      },
      inWorkspace: ''
    },
    fetching: false
  },
  stapleMode: {
    // enabled: false,
    type: null,
    id: null,
    pagesIds: [],
    disabledPagesIds: []
  },
  error: null,
  loading: false,

  // ToDO
  forceReload: false
};

const slice = createSlice({
  name: Reducers.DMSDocumentPages,
  initialState,
  reducers: {
    setPages: (state, action:PayloadAction<(IPage | IPresenterPage)[]>) => {
      const ids = action.payload.map((page:(IPage | IPresenterPage)) => PageService.toPageId(page.documentId, page.pageNum));
      const entities = action.payload.reduce((acc:IEntity, cur:(IPage | IPresenterPage)) => {
        if ( acc[cur.documentId] ){
          acc[cur.documentId][cur.pageNum] = cur;
        } else {
          acc[cur.documentId] = { [cur.pageNum]:cur };
        }
        return acc;
      }, {});
      state.pages.ids = ids;
      state.pages.entities = entities;
    },
    setPagesFilter: (state, action:PayloadAction<{ field:keyof IState['pages']['filter'], value:any }>) => {
      const { field, value } = action.payload;
      (state.pages.filter as any)[field] = value;
    },
    // Staple
    setStapleModeCreate: (state, action:PayloadAction<number>) => {
      const documentId = action.payload;

      const documentPages = state.pages.entities[documentId];
      const pages = Object.values(documentPages) as IPage[];

      state.stapleMode.type = 'create';
      state.stapleMode.id = uuidv4();
      state.stapleMode.disabledPagesIds = pages
        .filter((page:IPage) => page.staple)
        .map((page:IPage) => PageService.toPageId(page.documentId, page.pageNum))
    },
    setStapleModeEdit: (state, action:PayloadAction<{ documentId:number, stapleId:string }>) => {
      const { documentId, stapleId } = action.payload;

      state.stapleMode.type = 'edit';
      state.stapleMode.id = stapleId;
    },
    setError: (state, action:PayloadAction<{ status:number, props?:any }>) => {
      state.error = action.payload;
    },

    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState,

    setForceReload: (state, action:PayloadAction<boolean>) => {
      state.forceReload = action.payload;
    }
  },
  extraReducers: (builder) => {
    // getDocumentPages
    // Get page
    // builder.addCase(getPage.pending, (state) => {
    //   state.page = null;
    // });
    builder.addCase(getDocumentPages.fulfilled, (state, action:PayloadAction<IPage[]>) => {
      for ( let page of action.payload ){
        if ( !state.pages.entities[page.documentId] ){
          state.pages.entities[page.documentId] = { [page.pageNum]: page }
        } else {
          state.pages.entities[page.documentId][page.pageNum] = page;
        }
      }
    });
    // Get page
    builder.addCase(getPage.pending, (state) => {
      state.page = null;
    });
    builder.addCase(getPage.fulfilled, (state, action:PayloadAction<IPage>) => {
      state.page = action.payload;
    });
    // Get search pages
    builder.addCase(getSearchPages.pending, (state, payload:any) => {
      const { arg } = payload.meta;
      state.searchPages.list = [];
      state.searchPages.fetching = true;
      state.searchPages.params = arg;
    });
    builder.addCase(getSearchPages.fulfilled, (state, action:PayloadAction<any[]>) => {
      state.searchPages.list = action.payload;
      state.searchPages.fetching = false;
    });
    // Patch page
    builder.addCase(patchPage.pending, (state) => {
      state.loading = true
    });
    builder.addCase(patchPage.fulfilled, (state, action:PayloadAction<IPage>) => {
      const page = action.payload;
      state.pages.entities[page.documentId][page.pageNum] = page;
    });
    // Patch pages
    builder.addCase(patchPages.pending, () => {

    });
    builder.addCase(patchPages.fulfilled, (state, action:PayloadAction<IPage[]>) => {
      for ( let page of action.payload ){
        state.pages.entities[page.documentId][page.pageNum] = page;
      }
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false;
      }
    )
  }
});

export const DMSDocumentPagesActions = slice.actions;

export default slice.reducer;
