import config from "../../../config";
// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put } from 'app/utilities/HttpClient';

const _url:string = `${config.messageApiUrl}/conversations`;

export const getUnseenConversations = asyncThunkHandler(
  `${Reducers.Conversations}/Get unseen conversations`,
  async () => {
    const response:Response = await $get(_url, { limit: 10000, unseen: true });
    return response.json();
  }
);

export const getConversations = asyncThunkHandler(
  `${Reducers.Conversations}/Get conversations`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const getConversation = asyncThunkHandler(
  `${Reducers.Conversations}/Get conversation`,
  async (conversationId:number) => {
    const response:Response = await $get(`${_url}/${conversationId}`);
    return response.json();
  }
);

export const createConversation = asyncThunkHandler(
  `${Reducers.Conversations}/Create conversation`,
  async (data:any) => {
    const response:Response = await $post(_url, data);
    return response.json();
  }
);

export const updateConversation = asyncThunkHandler(
  `${Reducers.Conversations}/Update conversation`,
  async ({ conversationId, data }:{ conversationId:number, data:any }) => {
    const response:Response = await $put(`${_url}/${conversationId}`, data);
    return response.json();
  }
);

export const inviteMember = asyncThunkHandler(
  `${Reducers.Conversations}/Invite member`,
  async ({ conversationId, data }:{ conversationId:number, data:any }, { fulfillWithValue }) => {
    await $post(`${_url}/${conversationId}/actions/invite`, data);
    return fulfillWithValue(data.user);
  }
);

export const kickMember = asyncThunkHandler(
  `${Reducers.Conversations}/Kick member`,
  async ({ conversationId, data }:{ conversationId:number, data:any }, { fulfillWithValue }) => {
    await $post(`${_url}/${conversationId}/actions/kick`, data);
    return fulfillWithValue(data.userId);
  }
);

export const leaveConversation = asyncThunkHandler(
  `${Reducers.Conversations}/Leave convestation`,
  async (conversationId:number) => {
    const response:Response = await $post(`${_url}/${conversationId}/actions/leave`);
    return response.json();
  }
);

export const markAsRead = asyncThunkHandler(
  `${Reducers.Conversations}/Mark as read`,
  async ({ conversationId, data }:{ conversationId:number, data:any }, { fulfillWithValue }) => {
    const response:Response = await $post(`${_url}/${conversationId}/actions/mark`, data);
    const body = await response.json();
    return fulfillWithValue({ conversationId, body });
  }
);
