import config from '../../../../config';
import { FC, useState, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { batchChargeInvoices, chargeInvoice } from 'app/store/BillingCharges/BillingCharges.async';
// Selectors
import { selectBatchInvoicesIds } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// import { selectLoading } from 'app/store/BillingCharges/BillingCharges.selectors';
// Mui
import { Alert } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

interface IError {
  code: string;
  message: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  invoiceId?: number;
};

const CreditCardPaymentFormDialog:FC<Props> = ({
  // Props
  open, onClose, invoiceId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const batchInvoicesIds = useAppSelector(selectBatchInvoicesIds);
  // const loading = useAppSelector(selectLoading);

  const [ loaded, setLoaded ] = useState(false);
  const [ errors, setErrors ] = useState<IError[]>([]);

  useEffect(() => {
    loadHeartlandScript(() => {
      setLoaded(true);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( loaded ){
      (window as any).GlobalPayments.configure({
        publicApiKey: config.billingPublicApiKey
      });
      // Create Form
      const cardForm = (window as any).GlobalPayments.creditCard.form('#credit-card');
      cardForm.on('token-success', (response:any) => {
        setErrors([]);
        if ( response.paymentReference ){
          const card = { token: response.paymentReference };
          if ( invoiceId ){
            asyncChargeInvoice({ card, invoiceId });
          } else {
            asyncBatchChargeInvoice({ card, invoiceIds: batchInvoicesIds });
          }
        }
      });
      cardForm.on('token-error', (response:any) => {
        if ( response.error ) setErrors(response.reasons);
      });
    }
    // eslint-disable-next-line
  }, [loaded]);

  const asyncChargeInvoice = async (data:any) => {
    try {
      await dispatch(chargeInvoice(data)).unwrap();
      onClose();
    } catch(error){}
  }

  const asyncBatchChargeInvoice = async (data:any) => {
    try {
      await dispatch(batchChargeInvoices(data)).unwrap();
      onClose();
    } catch(error){}
  }

  const actions = (
    <Button
      name="Cancel credit card payment dialog"
      onClick={onClose}
    >{t('labels.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.adminPages.billingInvoicesPage.payWithCard')}
      actions={actions}
    >
      {errors && errors.length > 0 ? (
        errors.map((error:IError, index:number) => (
          <Alert key={`error-item-${index}`} severity="error">{error.message}</Alert>
        ))
      ) : null}
      {loaded ? (
        <form id="payment-form" action="/Payment/Charge" method="get">
          <div id="credit-card"></div>
        </form>
      ) : null}
    </Dialog>
  )
}

const loadHeartlandScript = (callback:() => void) => {
  const existingScript = document.getElementById('react_heartland');
  if ( !existingScript ){
    const script = document.createElement('script');
    script.src = 'https://api2.heartlandportico.com/SecureSubmit.v1/token/gp-1.0.1/globalpayments.js';
    script.id = 'react_heartland';
    document.body.appendChild(script);
    script.onload = () => { 
      if ( callback ) callback();
    };
  }
  if ( existingScript && callback ) callback();
}

export default CreditCardPaymentFormDialog;
