import { FC } from 'react';
import { Droppable } from 'react-beautiful-dnd';
// Mui
import { Box, Container, Theme, alpha } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// 
import SelectedListItem from './SelectedListItem';
// i18next
import { useTranslation } from 'react-i18next';

const SelectedList:FC = () => {
  const { t } = useTranslation('common');
  const { selectedId, lists } = useReportPresetContext();

  const selectedIds = lists.selected;

  const content = !selectedIds
    ? <Loader />
    : !selectedIds.length
      ? <Message text={t('dialogs.reportPresetForm.useTemplatesFromList')} />
      : <Container sx={{ display: 'flex', flexDirection: 'column', gap: 4 }} maxWidth="md">
          {selectedIds.map((templateId:number, index) => (
            <SelectedListItem
              key={`template item ${templateId}`}
              index={index}
              templateId={templateId}
            />
          ))}
        </Container>
  ;

  return (
    <Droppable droppableId={selectedId} direction="vertical">
      {(provided, snapshot) => (
        <Box
          {...provided.droppableProps}
          ref={provided.innerRef}
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: (theme:Theme) => snapshot.draggingOverWith ? alpha(theme.palette.primary.light, 0.05) : 'initial',
            p: 4
          }}
        >
          {content}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

export default SelectedList;
