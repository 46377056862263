// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./BillingPackages.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/billing/subscriptionPackages';

// Get packages
export const getPackages = asyncThunkHandler(
  `${Reducers.BillingPackages}/Get packages`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);

// Get package
export const getPackage = asyncThunkHandler(
  `${Reducers.BillingPackages}/Get package`,
  async (packageId:number) => {
    const response:Response = await $get(`${_url}/${packageId}`);
    return response.json();
  }
);

// Create package
export const createPackage = asyncThunkHandler(
  `${Reducers.BillingPackages}/Create package`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update package
export const updatePackage = asyncThunkHandler(
  `${Reducers.BillingPackages}/Update package`,
  async ({ packageId, data }:{ packageId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${packageId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Delete package
export const deletePackage = asyncThunkHandler(
  `${Reducers.BillingPackages}/Delete package`,
  async (packageId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${packageId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(packageId);
  }
);
