import { FC } from 'react';
// Models
import IBillingOrder from 'app/models/BillingOrder';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectOrders } from 'app/store/BillingOrders/BillingOrders.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import OrdersListItem from './OrdersListItem';
// i18next
import { useTranslation } from 'react-i18next';

const OrdersList:FC = () => {
  const { t } = useTranslation('common');
  // State
  const orders:IBillingOrder[] | null = useAppSelector(selectOrders);

  if ( !orders ) return <Loader />;
  if ( !orders.length ) return <Message text={t('pages.adminPages.ordersPage.noOrders')} />;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {orders.map((order:IBillingOrder) => (
        <OrdersListItem
          key={`order-item-${order.id}`}
          order={order}
        />
      ))}
    </Box>
  )
}

export default OrdersList;
