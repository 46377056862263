import { ChangeEvent, FC, Fragment, useState } from 'react';
// Types
import Colors from 'app/types/Colors';
// Models
import ILabel from 'app/models/Label';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { patchInsuranceCase } from 'app/store/Cases/Cases.async';
// Selectors
import { selectLoading } from 'app/store/Cases/Cases.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
import ColorPicker from 'app/components/ColorPicker';
import Labels from 'app/components/Labels';
import CasePushAlert from 'app/components/CasePushAlert';

// ToDO
import AuditOperations from '_types/AuditOperations';
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { generateAuditLabelData } from '_services/audit.service';
import { IMyUser } from 'app/models/User';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// End: ToDO
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCaseId: number;
  version: number;
  labels?: ILabel[];
};

const CaseLabelsDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCaseId, version, labels = []
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const loading = useAppSelector(selectLoading);

  const [ name, setName ] = useState('');
  const [ color, setColor ] = useState<Colors|string>(Colors.Red);
  const [ stateLabels, setStateLabels ] = useState<ILabel[]>(labels);
  const [ addedLabels, setAddedLabels ] = useState<ILabel[]>([]);
  const [ removedLabels, setRemovedLabels ] = useState<ILabel[]>([]);

  const handleSave = async () => {
    try {
      const nextData = { labels: stateLabels, version }
      await dispatch(patchInsuranceCase({ insuranceCaseId, data: nextData })).unwrap();

      if ( addedLabels.length || removedLabels.length ){
        const auditLabelData:any = {
          insuranceCaseId,
          userName: myUser?.name || '',
          type: 'case'
        };
        if ( addedLabels.length ){
          const addedLabelsName = addedLabels.map((label:ILabel) => label.name);
          auditLabelData['labels'] = addedLabelsName;
          const auditData = generateAuditLabelData(AuditOperations.CaseLabelAdding, auditLabelData);
          dispatch(createAuditRecord(auditData));
        }
        if ( removedLabels.length ){
          const removedLabelsName = removedLabels.map((label:ILabel) => label.name);
          auditLabelData['labels'] = removedLabelsName;
          auditLabelData['removed'] = true;
          const auditData = generateAuditLabelData(AuditOperations.CaseLabelRemoving, auditLabelData);
          dispatch(createAuditRecord(auditData));
        }
      }
      onClose();
    } catch(error){}
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleAddLabel = () => {
    if ( !name || !color ) return;

    const label = { name, color };

    const foundedIndex = removedLabels.findIndex((l:ILabel) => l.name === label.name);

    if ( foundedIndex !== -1 ){
      setRemovedLabels((prevState:ILabel[]) => prevState.filter((_:any, idx) => idx !== foundedIndex));
    } else {
      setAddedLabels([...addedLabels, label]);
    }

    setStateLabels((prevState:ILabel[]) => [...prevState, label])

    setName('');
    setColor(Colors.Red);
  }

  const handleRemoveLabel = (index:number) => {
    const label = stateLabels[index];

    const foundedIndex = addedLabels.findIndex((l:ILabel) => l.name === label.name);

    if ( foundedIndex !== -1 ){
      setAddedLabels((prevState:ILabel[]) => prevState.filter((_:any, idx) => idx !== foundedIndex));
    } else {
      setRemovedLabels([...removedLabels, label]);
    }

    setStateLabels((prevState:ILabel[]) => prevState.filter((_:any, idx) => idx !== index));
  }

  const handleClickColor = (nextColor:Colors|string) => setColor(nextColor);

  const actions = (
    <Fragment>
      <Button
        name="Cancel case labels dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Save case labels dialog"
        onClick={handleSave}
        loading={loading}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('dialogs.caseLabels.caseLabels')}
      actions={actions}
    >
      <CasePushAlert caseId={insuranceCaseId} type="labels" />
      <Box sx={{ display: 'flex', gap: '8px', mb: '16px' }}>
        <Box flexGrow={1}>
          <Input
            label={t('labels.name')} value={name}
            onChange={handleChange}
            margin="none"
          />
        </Box>
        <Box pt="10px">
          <Button
            name="Add label"
            disabled={!name}
            onClick={handleAddLabel}
            variant="contained"
            color="primary"
          >{t('labels.add')}</Button>
        </Box>
      </Box>
      <Box mb="16px">
        <ColorPicker
          id={insuranceCaseId}
          activeColors={[color]}
          onClick={handleClickColor}
        />
      </Box>
      <Labels
        id={insuranceCaseId}
        labels={stateLabels}
        onRemove={handleRemoveLabel}
      />
    </Dialog>
  )
}

export default CaseLabelsDialog;