// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './Invoices.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/invoices';

export const getInvoices = asyncThunkHandler(
  `${Reducers.Invoices}/Get invoices`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const createInvoice = asyncThunkHandler(
  `${Reducers.Invoices}/Create invoice`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

export const updateInvoice = asyncThunkHandler(
  `${Reducers.Invoices}/Update invoice`,
  async ({ id, data }:{ id:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${id}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

export const deleteInvoice = asyncThunkHandler(
  `${Reducers.Invoices}/Delete invoice`,
  async ({ id, data }:{ id:number; data:any }, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${id}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(id);
  }
);

export const payInvoice = asyncThunkHandler(
  `${Reducers.Invoices}/Pay invoice`,
  async ({ id, data }:{ id:number; data:any }, { dispatch }) => {
    const response:Response = await $post(`${_url}/${id}/actions/pay`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Pay
    }));
    return response.json();
  }
);

export const sendInvoice = asyncThunkHandler(
  `${Reducers.Invoices}/Send invoice`,
  async ({ id, data }:{ id:number; data:any }, { dispatch }) => {
    const response:Response = await $post(`${_url}/${id}/actions/send`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Send
    }));
    return response.json();
  }
);

export const pushInvoice = asyncThunkHandler(
  `${Reducers.Invoices}/Push invoice`,
  async ({ id, data }:{ id:number; data:any }, { dispatch }) => {
    const response:Response = await $post(`${_url}/${id}/actions/push`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Push
    }));
    return response.json();
  }
);

export const getServiceItems = asyncThunkHandler(
  `${Reducers.Invoices}/Get service items`,
  async () => {
    const response:Response = await $get(`${_url}/serviceItems`);
    return response.json();
  }
);
