import Quill from 'quill';
// Constants
import { TAB_STOP_VALUES } from 'app/App.constants';

const Parchment = Quill.import('parchment');

const TabStopAttribute = new Parchment.Attributor.Attribute('tabstop', 'data-tabstop', {
  scope: Parchment.Scope.BLOCK,
  whitelist: TAB_STOP_VALUES
});

Quill.register(TabStopAttribute, true);