import dayjs from 'dayjs';
// Types
import StapleModes from "@root/types/StapleModes";
import StaplePageActions from "@root/types/StaplePageActions";
// Models
import { IPage, IPageWithAction } from "@root/models/Page";

export const generateStapleGroup = (stapleMode:StapleModes|null, stapleId:string, pages:Array<IPageWithAction>, episodes:Array<any>) => {
  const isEditAndAnyPageWithAdd = stapleMode === StapleModes.Edit && pages.some((p:IPageWithAction) => p.action === StaplePageActions.Added);

  const sortedPages = [...pages].sort((a:IPageWithAction, b:IPageWithAction) => sortByPageAction(a) - sortByPageAction(b));

  const parentStaplePage:IPageWithAction | undefined = stapleMode && stapleMode === StapleModes.Create
    ? sortedPages[0]
    : pages.find((page:IPageWithAction) => page.staple && page.staple.id === stapleId && page.staple.order === 1)
  ;

  const lenghtOfNonRemovedPages = sortedPages.filter(
    (page:IPageWithAction) =>  page.action !== StaplePageActions.Removed
  ).length;

  if ( lenghtOfNonRemovedPages === 1 || parentStaplePage && parentStaplePage.action === StaplePageActions.Removed ){
    let noParentEpisode = false;
    if ( parentStaplePage?.parentEpisodeId ){
      const foundedEpisode = episodes.find((episode:any) => episode.id === parentStaplePage.parentEpisodeId);
      if ( foundedEpisode && foundedEpisode.pageNum !== parentStaplePage.pageNum ){
        noParentEpisode = true
      }
    }

    return sortedPages.map((page:IPageWithAction, index:number) => removePageFromStaple(page, index, episodes, parentStaplePage?.pageNum === page.pageNum ? noParentEpisode : false));
  }

  const maxOrderIndex:number = sortedPages.reduce((acc:number, cur:IPage) => {
    const stapleOrder = cur.staple && cur.staple.order ? cur.staple.order : 0;
    return acc < stapleOrder ? stapleOrder : acc;
  }, 0);

  let inWorkspace:boolean = parentStaplePage?.inWorkspace || false;
  let workspaceOrder:string|null = parentStaplePage?.workspaceOrder || null;

  if ( stapleMode && stapleMode === StapleModes.Create ){
    inWorkspace = true;
    workspaceOrder = generateWorkspaceOrder(episodes, {
      parentEpisodeId: parentStaplePage?.parentEpisodeId || null,
      workspaceOrder: parentStaplePage?.workspaceOrder || null
    });
  }

  const result:Array<any> = [];
  sortedPages.forEach((page:IPageWithAction, index:number) => {
    if ( page.action === StaplePageActions.Removed ){
      result.push(removePageFromStaple(page, index, episodes));
    } else {
      if ( isEditAndAnyPageWithAdd && !page.action ){
        result.push(page);
      }
      if ( page.action === StaplePageActions.Added ){
        const newPageData:any = {
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          staple: {
            id: stapleId,
            order: maxOrderIndex + index + 1
          },
          inWorkspace,
          workspaceOrder
        };
        if ( index > 0 ){
          if ( page.parentEpisodeId ) newPageData['parentEpisodeId'] = null;
          if ( parentStaplePage && parentStaplePage.tag ) newPageData['tag'] = parentStaplePage.tag;
          if ( parentStaplePage && parentStaplePage.colors ) newPageData['colors'] = parentStaplePage.colors;
        } else {
          if ( page.parentEpisodeId ) newPageData['parentEpisodeId'] = page.parentEpisodeId;
        }
        result.push(newPageData);
      }
    }
  });
  // Remove parentEpisodeId from main staple page
  if ( parentStaplePage && parentStaplePage.parentEpisodeId ){
    const foundedEpisode:any = episodes.find((episode:any) => episode.id === parentStaplePage.parentEpisodeId);
    if ( foundedEpisode ){
      const foundedPage:IPageWithAction = result.find((page:IPageWithAction) => page.pageNum === foundedEpisode.pageNum && !page.staple);
      if ( foundedPage ){
        result.push({
          documentId: parentStaplePage.documentId,
          pageNum: parentStaplePage.pageNum,
          version: parentStaplePage.version,
          parentEpisodeId: null
        });
      }
    }
  }

  return result;
};

export const generateWorkspaceOrder = (
  episodes:Array<any>|null,
  params: {
    parentEpisodeId?:number|null,
    workspaceOrder?:string|null,
    date?:string,
    time?:string,
    deleted?:boolean,
    index?:number|null
  }
):string => {
  const { parentEpisodeId, workspaceOrder, date, time, deleted = false, index = null } = params;
  let PAGE_DATE:string = date ? date.replace(/-/gi, '') : '00000000';
  let PAGE_TIME:string = time ? time.replace(/:/gi, '') : '0000';
  const mls = dayjs().millisecond();
  let TIME_STAMP:string = dayjs().millisecond(index ? mls + index : mls).utc().format('YYYYMMDDHHmmssSSS');

  if ( !deleted && workspaceOrder ) TIME_STAMP = workspaceOrder.substring(12, 29);

  if ( episodes && parentEpisodeId ){
    const foundedEpisode = episodes.find((episode:any) => episode.id === parentEpisodeId);
    if ( foundedEpisode ){
      if ( foundedEpisode.date ) PAGE_DATE = foundedEpisode.date.replace(/-/gi, '');
      if ( foundedEpisode.time ) PAGE_TIME = foundedEpisode.time.replace(/:/gi, '');
    }
  }
  return `${PAGE_DATE}${PAGE_TIME}${TIME_STAMP}`;
}

export const removePageFromStaple = (page:IPage|IPageWithAction, index:number|null, episodes:Array<any> | null = null, noParentEpisode:boolean = false) => {
  const nextPage:any = {
    documentId: page.documentId,
    pageNum: page.pageNum,
    version: page.version,
    staple: null
  };
  if ( noParentEpisode ) nextPage['parentEpisodeId'] = null;
  if ( page.inWorkspace && page.workspaceOrder ){
    nextPage['workspaceOrder'] = generateWorkspaceOrder(episodes, {
      parentEpisodeId: noParentEpisode ? null : page.parentEpisodeId,
      deleted: true,
      index
    });
  }
  return nextPage;
}

export const generatePageId = (documentId:number, pageNum:number, stapleId?:string|null) => {
  let pageId:string = `${documentId}:${pageNum}`;
  if ( stapleId ) pageId = `${pageId}:${stapleId}`;
  return pageId;
};

export const parsePageId = (pageId:string) => {
  const [ documentId, pageNum, stapleId, stapleVersion ] = pageId.split(':');
  return {
    documentId: Number(documentId),
    pageNum: Number(pageNum),
    stapleId: stapleId ? `${stapleId}${stapleVersion ? `:${stapleVersion}` : ''}` : ''
  };
}

export const isSamePages = (page1:IPage, page2:IPage) => {
  return page1.documentId === page2.documentId && page1.pageNum === page2.pageNum;
};

const sortByPageAction = (page:IPageWithAction) => {
  return page.action === StaplePageActions.Removed ? 1 : 0;
};
