import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
// Models
import IMatch from 'app/models/Match';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPagesIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Components
import { Loader } from 'app/components/Utilities';
// 
import PageDetailsPage from './PageDetails.page';

type Props = {
  match: IMatch;
}

const DocumentsRouting:FC<Props> = ({
  // Props
  match
}) => {
  // State
  const pageIds:string[] | null = useAppSelector(selectPagesIds)

  if ( !pageIds ) return <Loader />
  return (
    <Switch>
      <Route path={`${match.path}/:documentId/:pageNum`} component={PageDetailsPage} />
    </Switch>
  )
}

export default DocumentsRouting;
