import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IExportTemplate from "app/models/ReportTemplate";
// Async
import {
  getExportTemplates, getExportTemplate, createExportTemplate, updateExportTemplate, deleteExportTemplate,
  downloadExportTemplateFile, uploadExportTemplateFile
} from './ExportTemplates.async';

interface IState {
  exportTemplates: IExportTemplate[] | null;
  exportTemplate: IExportTemplate | null;
  loading: boolean;
}

const initialState:IState = {
  exportTemplates: null,
  exportTemplate: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.ExportTemplates,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get export templates
    builder.addCase(getExportTemplates.pending, (state) => {
      state.exportTemplates = null;
    });
    builder.addCase(getExportTemplates.fulfilled, (state, action:PayloadAction<IExportTemplate[]>) => {
      state.exportTemplates = action.payload;
    });
    // Get export template
    builder.addCase(getExportTemplate.pending, (state) => {
      state.exportTemplate = null;
    });
    builder.addCase(getExportTemplate.fulfilled, (state, action:PayloadAction<IExportTemplate>) => {
      state.exportTemplate = action.payload;
    });
    // Create export template
    builder.addCase(createExportTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createExportTemplate.fulfilled, (state, action:PayloadAction<IExportTemplate>) => {
      if ( state.exportTemplates ){
        state.exportTemplates = [...state.exportTemplates, action.payload]
      }
    });
    // Update export template
    builder.addCase(updateExportTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateExportTemplate.fulfilled, (state, action:PayloadAction<IExportTemplate>) => {
      if ( state.exportTemplates ){
        state.exportTemplates = state.exportTemplates.map((exportTemplate:IExportTemplate) => {
          if ( exportTemplate.id === action.payload.id ) return action.payload;
          return exportTemplate;
        })
      }
    });
    // Delete export template
    builder.addCase(deleteExportTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteExportTemplate.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.exportTemplates ){
        state.exportTemplates = state.exportTemplates.filter((exportTemplate:IExportTemplate) => exportTemplate.id !== action.payload)
      }
    });
    // Download template file
    builder.addCase(downloadExportTemplateFile.pending, (state) => {
      state.loading = true;
    });
    // Upload template file
    builder.addCase(uploadExportTemplateFile.pending, (state) => {
      state.loading = true;
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  }
});

export const ExportTemplatesActions = slice.actions;

export default slice.reducer;