// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $patch, $delete } from 'app/utilities/HttpClient';

const _url:string = '/integrations';

export const getIntegrations = asyncThunkHandler(
  `${Reducers.Integrations}/Get integrations`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const getIntegration = asyncThunkHandler(
  `${Reducers.Integrations}/Get integration`,
  async (integrationId:number) => {
    const response:Response = await $get(`${_url}/${integrationId}`);
    return response.json();
  }
);

export const createIntegration = asyncThunkHandler(
  `${Reducers.Integrations}/Create integration`,
  async (data:any) => {
    const response:Response = await $post(_url, data);
    return response.json();
  }
);

export const updateIntegration = asyncThunkHandler(
  `${Reducers.Integrations}/Update integration`,
  async ({ integrationId, data }:{ integrationId:string, data:any }) => {
    const response:Response = await $put(`${_url}/${integrationId}`, data);
    return response.json();
  }
);

export const patchIntegration = asyncThunkHandler(
  `${Reducers.Integrations}/Patch integration`,
  async ({ integrationId, data }:{ integrationId:string, data:any }) => {
    const response:Response = await $patch(`${_url}/${integrationId}`, data);
    return response.json();
  }
);

export const deleteIntegration = asyncThunkHandler(
  `${Reducers.Integrations}/Delete integration`,
  async (integrationId:number, { fulfillWithValue }) => {
    await $delete(`${_url}/${integrationId}`);
    return fulfillWithValue(integrationId);
  }
);

export const getQuickbooksOAuth = asyncThunkHandler(
  `${Reducers.Integrations}/Get quickbooks oauth`,
  async (params:any) => {
    const response:Response = await $get(`${_url}/quickBooks/oauth2Url`, params);
    return response.json();
  }
);
