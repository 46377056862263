import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import ISchedule from "app/models/Schedule";
// Async
import { getPhysicianFeeSchedules, getMedicareCarriers, getVaRates, getHcpcsCode } from './Medical.async';

interface IState {
  schedules: ISchedule[] | null;
  carriers: any;
  rates: any;
  hcpcsCode: any;

  searchHcpcsCode: string;

  hcpcsCodeFetching: boolean;
};

const initialState:IState = {
  schedules: [],
  carriers: null,
  rates: null,
  hcpcsCode: null,

  searchHcpcsCode: '',

  hcpcsCodeFetching: false
};

const slice = createSlice({
  name: Reducers.Medical,
  initialState,
  reducers: {
    setSearchHcpcsCode: (state, action:PayloadAction<string>) => {
      state.searchHcpcsCode = action.payload;
    },
    resetSchedules: (state) => {
      state.schedules = initialState.schedules;
    },
    resetHcpcsCode: (state) => {
      state.hcpcsCode = initialState.hcpcsCode;
    }
  },
  extraReducers(builder) {
    // Get schedules
    builder.addCase(getPhysicianFeeSchedules.pending, (state) => {
      state.schedules = null;
    });
    builder.addCase(getPhysicianFeeSchedules.fulfilled, (state, action:PayloadAction<any>) => {
      state.schedules = action.payload;
      if ( !action.payload.length ){
        state.hcpcsCodeFetching = true;
      }
    });
    // Get carriers
    builder.addCase(getMedicareCarriers.pending, (state) => {
      state.carriers = null;
    });
    builder.addCase(getMedicareCarriers.fulfilled, (state, action:PayloadAction<any>) => {
      state.carriers = action.payload;
    });
    // Get va rates
    builder.addCase(getVaRates.pending, (state) => {
      state.rates = null;
    });
    builder.addCase(getVaRates.fulfilled, (state, action:PayloadAction<any>) => {
      state.rates = action.payload;
    });
    // Get hcpcs code
    builder.addCase(getHcpcsCode.pending, (state) => {
      state.hcpcsCode = null;
    });
    builder.addCase(getHcpcsCode.fulfilled, (state, action:PayloadAction<any>) => {
      state.hcpcsCode = action.payload;
      state.hcpcsCodeFetching = false;
    });
    builder.addCase(getHcpcsCode.rejected, (state) => {
      state.hcpcsCodeFetching = false;
    });
  },
});

export const MedicalActions = slice.actions;

export default slice.reducer;