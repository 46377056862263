enum CaseStatuses {
  Pending = 'pending',
  Waiting = 'waiting',
  Open = 'open',
  Archived = 'archived',
  Rejected = 'rejected',
  CreationInProgress = 'creationInProgress'
};

export default CaseStatuses;
