import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, FormHelperText } from '@mui/material';
// Components
import { Input, Button } from 'app/components/Mui';
// 
import AccountTagsItem from './AccountTagItem';

const PageTagsTabContent:FC = () => {
  const { t } = useTranslation('common');

  const { register, watch, setValue } = useFormContext();

  const watchTags = watch('tags') || [];

  const [ tag, setTag ] = useState('');

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
  }

  const handleAdd = () => {
    if ( !tag || watchTags.includes(tag) ) return;
    setValue('tags', [...watchTags, tag]);
    setTag('');
  }

  const handleRemove = (idx:number) => () => {
    setValue('tags', watchTags.filter((_:any, index:number) => index !== idx));
  }

  const error = tag ? watchTags.includes(tag) : false;
  const helperText = tag && watchTags.includes(tag) ? t('pages.adminPages.companyAccountPage.tagHelperText', { tagName: tag }) : '';

  return (
    <Fragment>
      <input {...register('tags') as any} type="hidden" />

      <Box sx={{ display: 'flex', gap: 2, pt: 4 }}>
        <Box flexGrow={1}>
          <Input
            label={t('pages.adminPages.companyAccountPage.tagName')} value={tag}
            onChange={handleChange}
            margin="none"
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${tag.length} / 12`}</FormHelperText>
              )
            }}
            error={error}
            helperText={helperText}
          />
        </Box>
        <Box sx={{ pt: '10px' }}>
          <Button
            name="Add tag"
            disabled={!tag || tag.length > 12}
            onClick={handleAdd}
            variant="contained"
            color="primary"
          >{t('labels.add')}</Button>
        </Box>
      </Box>
      {watchTags.length ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 4, gap: 2 }}>
          {watchTags.map((tag:string, index:number) => (
            <AccountTagsItem
              key={`account-tag-item-${tag}-${index}`}
              tag={tag}
              onDelete={handleRemove(index)}
            />
          ))}
        </Box>
      ) : null}
    </Fragment>
  )
}

export default PageTagsTabContent;
