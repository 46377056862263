import React from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectColumnValue } from 'app/store/ui/uiSelectors';
import { selectSearchPagesFetching, selectSearchPagesList, selectSearchPagesParams } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import PageCard from '@root/components/PageCard';
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
import { CARD_WIDTH, CARD_HEIGHT } from 'app/App.constants';

const SearchList = () => {
  const { t } = useTranslation('common');

  // State
  const columnValue = useAppSelector(selectColumnValue);
  const pages = useAppSelector(selectSearchPagesList);
  const params = useAppSelector(selectSearchPagesParams);
  const fetching = useAppSelector(selectSearchPagesFetching);

  const text =
    !params.keywords.length
    ? t('pages.adminPages.caseDetailsPage.fillInSearchCriteria')
    : `${t('pages.adminPages.caseDetailsPage.noPagesFor')}: ${params.keywords.join(', ')}`
  ;

  if ( fetching ) return <Loader />;
  if ( !pages.length ) return <Message text={text} />
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
          <AutoSizer>
            {({ width, height }) => {
              const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
              const itemCount = Math.ceil(pages.length / itemsPerRow);
              const itemSize = columnValue === 'auto' ? CARD_HEIGHT : width/Number(columnValue)*3/2;

              return (
                <List
                  width={width}
                  height={height}
                  itemCount={itemCount}
                  itemSize={itemSize}
                  className="scrollListContainer"
                  initialScrollOffset={0}

                  itemData={{
                    itemsPerRow,
                    pages,
                    itemSize
                  }}
                >
                  {Row}
                </List>
              )
            }}
          </AutoSizer>
        </Box>
      </Box>
    </Box>
  )
}

export default SearchList;

const Row = React.memo(({ index, style, data:{ itemsPerRow, pages, itemSize, forceReload } }) => {
  const items = [];
  const fromIndex = index * itemsPerRow;
  const toIndex = Math.min(fromIndex + itemsPerRow, pages.length);
  for ( let i = fromIndex; i < toIndex; i++ ){
    const page = pages[i];
    items.push(
      <div
        key={`page-item-${page.documentId}-${page.pageNum}-${forceReload}`}
        className="card-page-item"
        style={{
          width: `${(100/itemsPerRow).toFixed(2)}%`,
          marginTop: i < itemsPerRow ? '16px' : 0
        }}
      >
        <PageCard
          scrollContainer=".scrollListContainer"
          documentId={page.documentId}
          pageNum={page.pageNum}
          height={i < itemsPerRow ? itemSize - 32 : itemSize - 16}
        />
      </div>
    );
  }
  return (
    <div
      className="card-page-container"
      style={{ ...style }}
    >{items}</div>
  )
}, areEqual)
