import React, { ReactNode } from 'react';
// Models
import IOption from 'app/models/Option';
// Mui
import {
  TextField, TextFieldProps,
  MenuItem
} from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type SelectProps = TextFieldProps & {
  options: IOption[] | null;
  renderOptions?: (option:IOption, index:number) => ReactNode;
}

const Select = React.forwardRef<any, SelectProps>(({
  options, renderOptions = (option:IOption, index:number) => (
    <MenuItem
      key={`option-item-${index}-${option.id}`}
      value={option.id.toString()}
    >{option.name}</MenuItem>
  ), ...otherProps
}, ref) => {
  const extendedProps:TextFieldProps = {
    ...generateAttrForTesting('control', 'select', otherProps.name)
  };

  if ( !options ) return (
    <TextField
      {...otherProps}
      {...extendedProps}
      ref={ref}
      fullWidth={typeof otherProps.fullWidth === 'undefined' ? true : otherProps.fullWidth}
      margin={otherProps.margin || 'normal'}
      disabled={true}
    />
  );
  return (
    <TextField
      {...otherProps}
      {...extendedProps}
      select
      ref={ref}
      disabled={otherProps.disabled || !options}
      fullWidth={typeof otherProps.fullWidth === 'undefined' ? true : otherProps.fullWidth}
      margin={otherProps.margin || 'normal'}
      SelectProps={{
        MenuProps: {
          transformOrigin: { horizontal: 'right', vertical: 'top' },
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
        },
        renderValue: (value) => {
          if ( !value ) return '';
          const foundedOption = options.find((option) => option.id.toString() === value.toString());
          return foundedOption?.name || '';
        }
      }}
    >{(options || []).map((option:IOption, index:number) => renderOptions(option, index))}</TextField>
  );
});

export default Select;
