import { FC, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Types
import Features, { DatasetHPLFeatures } from 'app/types/Features';
// Models
import IOption from 'app/models/Option';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPackage } from 'app/store/BillingPackages/BillingPackages.async';
import { getGeneralSettings } from 'app/store/Settings/Settings.async';
import { createAccount } from 'app/store/AccountsManagement/AccountsManagement.async';
// Actions
import { BillingPackagesActions } from 'app/store/BillingPackages/BillingPackages.slice';
// Selectors
import { selectAvailableLanguageAsOptions } from 'app/store/AppUI/AppUI.selectors';
import { selectPackagesAsOptions, selectHasPackageCptCodesAccess } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectLoading } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectDateFormatAsOptions, selectRecordsLanguageAsOptions, selectTimeFormatAsOptions, selectTimeZoneAsOptions } from 'app/store/Settings/Settings.selectors';
// Mui
import { Box, FormGroup } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import Phone from 'app/components/Phone';
import { Input, Select, Button, LoadingButton, Switch } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utility
import { isRequired, isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';
import { FONT_FAMILIES, FONT_SIZES } from 'app/App.constants';

interface IFormData {
  company: string;
  email: string;
  phone: string;
  address: {
    city: string;
    state: string;
    zipCode: string;
    line: string;
  },
  owner: {
    name: string;
    email: string;
    phone: string;
  },
  quickBooksId: string;
  subscriptionPackageId: string;
  billingEmail: string;
  // Features
  datasetHPL: DatasetHPLFeatures | undefined;
  features: (Features)[];

  settings: {
    timeZone: string;
    dateFormat: string;
    timeFormat: string;
    recordsLanguage: string;
    language: string;
    reportPreferences: {
      fontFamily: string;
      fontSize: number;
    };
  }
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const AccountCreateFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const languageOptions = useAppSelector(selectAvailableLanguageAsOptions);
  const loading:boolean = useAppSelector(selectLoading);
  const packagesOptions:IOption[] | null = useAppSelector(selectPackagesAsOptions);
  const dateFormatOptions:IOption[] | null = useAppSelector(selectDateFormatAsOptions);
  const timeFormatOptions:IOption[] | null = useAppSelector(selectTimeFormatAsOptions);
  const recordsLanguageOptions:IOption[] | null = useAppSelector(selectRecordsLanguageAsOptions);
  const timeZoneOptions:IOption[] | null = useAppSelector(selectTimeZoneAsOptions)

  const hasPackageCptCodesAccess = useAppSelector(selectHasPackageCptCodesAccess);

  const { register, control, handleSubmit, formState: { errors }, watch } = useForm<IFormData>({
    defaultValues: {
      company: '',
      email: '',
      phone: '',
      address: {
        city: '',
        state: '',
        zipCode: '',
        line: '',
      },
      owner: {
        name: '',
        email: '',
        phone: '',
      },
      quickBooksId: '',
      subscriptionPackageId: '',
      billingEmail: '',
      datasetHPL: Features.DatasetHPL_FL,
      features: [Features.HcpcsCodes, Features.PageClassification],

      settings: {
        timeZone: '',
        recordsLanguage: '',
        dateFormat: '',
        timeFormat: '',
        language: '',
        reportPreferences: {
          fontFamily: FONT_FAMILIES[0],
          fontSize: FONT_SIZES[7]
        }
      }
    }
  });

  const watchSubscriptionPackageId = watch('subscriptionPackageId');

  useEffect(() => {
    dispatch(getGeneralSettings({}));
    return () => {
      dispatch(BillingPackagesActions.setInitialField('package'));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if ( watchSubscriptionPackageId ){
      dispatch(getPackage(Number(watchSubscriptionPackageId)));
    } else {
      dispatch(BillingPackagesActions.setInitialField('package'));
    }
    // eslint-disable-next-line
  }, [watchSubscriptionPackageId])

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      const { address, quickBooksId, billingEmail, features, datasetHPL, ...otherData } = data;
      const nextData:any = {
        ...otherData,
        features: features.length
          ? features.filter((feature:Features) => {
              if ( !hasPackageCptCodesAccess && feature === Features.HcpcsCodes ) return false;
              return true;
            })
          : []
      };
      if ( datasetHPL ) nextData['features'] = [...nextData['features'], datasetHPL]
      if ( quickBooksId ) nextData['quickBooksId'] = quickBooksId;
      if ( billingEmail ) nextData['billingEmail'] = billingEmail;
      if ( Object.values(address).some(v => v) ){
        nextData['address'] = {};
        for ( let key in address ){
          const value = (address as any)[key];
          if ( value ) nextData['address'][key] = value;
        }
      }
      await dispatch(createAccount(nextData)).unwrap();
      onClose();
    } catch(error){}
  });

  const datasetHPLOptions = [
    { id: '', name: 'Choose author matching dataset' },
    { id: Features.DatasetHPL_FL, name: 'FL' },
    { id: Features.DatasetHPL_WA, name: 'WA' },
    { id: Features.DatasetHPL_CA, name: 'CA' },
  ];

  const actions = (
    <Fragment>
      <Button
        name="Cancel create company account dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Create company account dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.accountManagerPages.companyAccountsPage.createCompanyAccount')} 
      actions={actions}
    >
      <form noValidate>
        {/* Hidden fields */}
        <input {...register('settings.reportPreferences.fontFamily') as any} type="hidden" value={FONT_FAMILIES[0]} />
        <input {...register('settings.reportPreferences.fontSize') as any} type="hidden" value={FONT_SIZES[7]} />

        <OutlineBlock
          sx={{ mt: 4 }}
          label={t('labels.company')}
          helperText={t('pages.accountManagerPages.companyAccountsPage.companyHelperText')}
        >
          <Fragment>
            {/* Company */}
            <Controller
              control={control} name="company"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.name')}
                  error={Boolean(errors.company)}
                  helperText={errors.company?.message || ''}
                  required
                />
              )}
            />
            {/* E-mail */}
            <Controller
              control={control} name="email"
              rules={{ required: isRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.contactEmail')}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message || ''}
                  required
                />
              )}
            />
            {/* Phone */}
            <Controller
              control={control} name="phone"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Phone
                  {...field}
                  label={t('labels.contactPhone')}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message || ''}
                  required={true}
                />
              )}
            />
            <Controller
              control={control} name="address.line"
              render={({ field }) => (
                <Input {...field} label={t('labels.address')} />
              )}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Controller
                control={control} name="address.city"
                render={({ field }) => (
                  <Input {...field} label={t('labels.city')} />
                )}
              />
              <Controller
                control={control} name="address.state"
                render={({ field }) => (
                  <Input {...field} label={t('labels.state')} />
                )}
              />
              <Controller
                control={control} name="address.zipCode"
                render={({ field }) => (
                  <Input {...field} label={t('labels.zipCode')} />
                )}
              />
            </Box>
          </Fragment>
        </OutlineBlock>
        <OutlineBlock
          sx={{ mt: 6 }}
          label={t('labels.owner')}
          helperText={t('pages.accountManagerPages.companyAccountsPage.ownerHelperText')}
        >
          <Fragment>
            {/* Name */}
            <Controller
              control={control} name="owner.name"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.name')}
                  error={Boolean(errors.owner?.name)}
                  helperText={errors.owner?.name?.message || ''}
                  required
                />
              )}
            />
            {/* E-mail */}
            <Controller
              control={control} name="owner.email"
              rules={{ required: isRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.email')} type="email"
                  error={Boolean(errors.owner?.email)}
                  helperText={errors.owner?.email?.message || ''}
                  required
                />
              )}
            />
            {/* Phone */}
            <Controller
              control={control} name="owner.phone"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Phone
                  {...field}
                  label={t('labels.cellPhone')}
                  error={Boolean(errors.owner?.phone)}
                  helperText={errors.owner?.phone?.message || ''}
                  required={true}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
        {/* Subscription */}
        <OutlineBlock sx={{ mt: 6 }} label={t('pages.accountManagerPages.companyAccountsPage.subscriptionPackage')}>
          <Fragment>
            {/* Package */}
            <Controller
              control={control} name="subscriptionPackageId"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('pages.accountManagerPages.companyAccountsPage.packages')}
                  error={Boolean(errors.subscriptionPackageId)}
                  helperText={errors.subscriptionPackageId?.message || ''}
                  required
                  options={packagesOptions}
                />
              )}
            />
            {/* Company */}
            <Controller
              control={control} name="quickBooksId"
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.quickbooksId')}
                  helperText={t('pages.accountManagerPages.companyAccountsPage.quickbooksIdHelperText')}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
        {/* Subscription */}
        <OutlineBlock sx={{ mt: 6 }} label={t('pages.accountManagerPages.companyAccountsPage.billing')}>
          {/* E-mail */}
          <Controller
            control={control} name="billingEmail"
            rules={{ pattern: isEmailValid }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.email')}
                error={Boolean(errors.billingEmail)}
                helperText={errors.billingEmail?.message || ''}
              />
            )}
          />
        </OutlineBlock>

        <Controller
          control={control} name="settings.timeZone" defaultValue="America/New_York"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.timeZone')}
              required
              error={Boolean(errors.settings?.timeZone)}
              helperText={errors.settings?.timeZone?.message || ''}
              options={timeZoneOptions}
            />
          )}
        />
        {/* Date format */}
        <Controller
          control={control} name="settings.dateFormat"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.dateFormat')}
              error={Boolean(errors.settings?.dateFormat)}
              helperText={errors.settings?.dateFormat?.message || ''}
              required
              options={dateFormatOptions}
            />
          )}
        />
        {/* Date format */}
        <Controller
          control={control} name="settings.timeFormat"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.timeFormat')}
              error={Boolean(errors.settings?.timeFormat)}
              helperText={errors.settings?.timeFormat?.message || ''}
              required
              options={timeFormatOptions}
            />
          )}
        />
        {/* OCR language */}
        <Controller
          control={control} name="settings.recordsLanguage"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.ocrLanguage')}
              error={Boolean(errors.settings?.recordsLanguage)}
              helperText={errors.settings?.recordsLanguage?.message || ''}
              required
              options={recordsLanguageOptions}
            />
          )}
        />
        {/* Language */}
        <Controller
          control={control} name="settings.language"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.language')}
              error={Boolean(errors.settings?.language)}
              helperText={errors.settings?.language?.message || ''}
              required
              options={languageOptions}
            />
          )}
        />

        <OutlineBlock sx={{ mt: 6 }} label={t('labels.features')}>
          <Fragment>
            <Controller
              control={control} name="features"
              render={({ field:{ onChange, value } }) => {
                const handleChange = (feature:Features) => () => {
                  const hasFeature = (value as (Features)[]).includes(feature);
                  const nextFeatures = hasFeature
                    ? (value as (Features)[]).filter((v) => v !== feature)
                    : [...value, feature]
                  ;
                  onChange(nextFeatures);
                }
                return (
                  <FormGroup>
                    <Switch
                      name="AI Chat"
                      label="Ai Chat"
                      value={value.includes(Features.AIChat)}
                      onChange={handleChange(Features.AIChat)}
                    />
                    {hasPackageCptCodesAccess ? (
                      <Switch
                        name="Cpt codes access"
                        label={t('pages.accountManagerPages.companyAccountsPage.cptCodesAccess')}
                        value={value.includes(Features.HcpcsCodes)}
                        onChange={handleChange(Features.HcpcsCodes)}
                        helperText={t('pages.accountManagerPages.companyAccountsPage.cptCodesAccessHelperText')}
                      />
                    ) : null}
                    <Switch
                      name="Automatic page classification"
                      label={t('pages.accountManagerPages.companyAccountsPage.automaticPageClassification')}
                      value={value.includes(Features.PageClassification)}
                      onChange={handleChange(Features.PageClassification)}
                      helperText={t('pages.accountManagerPages.companyAccountsPage.automaticPageClassificationHelperText')}
                    />
                    <Switch
                      name="Patient portal"
                      label={t('pages.accountManagerPages.companyAccountsPage.patientPortal')}
                      value={value.includes(Features.PatientPortal)}
                      onChange={handleChange(Features.PatientPortal)}
                    />
                  </FormGroup>
                )
              }}
            />
            <Controller
              control={control} name="datasetHPL"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('pages.accountManagerPages.companyAccountsPage.authorMatchingDataset')}
                  options={datasetHPLOptions}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
      </form>
    </Dialog>
  );
}

export default AccountCreateFormDialog;
