import { FC } from 'react';
import { Redirect, Route } from "react-router-dom";
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';
// Hooks
import useRedirectPath from 'app/hooks/useRedirectPath';

type Props = {
  path: string;
  component: React.ComponentType<any>;
}

const PublicRoute:FC<Props> = ({
  // Props
  component:Component, ...rest
}) => {
  // State
  const authenticated:boolean | undefined = useAppSelector(selectAuthenticated);

  const [ , , redirectPath ] = useRedirectPath();

  if ( !authenticated ) return <Route {...rest} render={(props) => <Component {...props} />} />;
  return <Redirect to={redirectPath as string} />;
};

export default PublicRoute;
