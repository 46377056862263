import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.clients.create'),
  Update: i18n.t('notifications.clients.update'),
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.clients.create');
  Messages.Update = i18n.t('notifications.clients.update');
});