import { FC } from 'react';
// Models
import IManagementAccount from 'app/models/ManagementAccount';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import {
  selectInvoicesSummary, selectParams, selectAccountFromParams
} from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Paper, Typography } from '@mui/material';
// Components
import { InfoBlock } from 'app/components/Utilities';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

const InvoicesSummary:FC = () => {
  const { t } = useTranslation('common');
  // State
  const invoicesSummary:any = useAppSelector(selectInvoicesSummary);
  const params = useAppSelector(selectParams);
  const account:IManagementAccount | undefined = useAppSelector(selectAccountFromParams);

  const startDate = params.dateRangeStart
    ? params.dateRangeStart.format('MMM DD, YYYY')
    : null
  ;
  const endDate = params.dateRangeEnd
    ? params.dateRangeEnd.format('MMM DD, YYYY')
    : null
  ;

  if ( !invoicesSummary ) return null;
  return (
    <Paper sx={{ bgcolor: 'transparent', p: 4, mb: 4 }} variant="outlined">
      <Typography variant="subtitle2">
        {t('pages.accountManagerPages.billingInvoicesPage.summaryTitle',
          { startDate, endDate, client: account ? `${account.company} client` : 'all clients' }
        )}
      </Typography>
      <Box display="flex" gap={4}>
        <InfoBlock label={t('pages.accountManagerPages.billingInvoicesPage.numberOfInvoices')} value={toNumberWithCommas(invoicesSummary.numberOfInvoices)} />
        <InfoBlock label={t('pages.accountManagerPages.billingInvoicesPage.numberOfOutstandingInvoices')} value={toNumberWithCommas(invoicesSummary.numberOfOutstandingInvoices)} />
        <InfoBlock label={t('pages.accountManagerPages.billingInvoicesPage.numberOfPaidInvoices')} value={toNumberWithCommas(invoicesSummary.numberOfPaidInvoices)} />
        <InfoBlock label={t('pages.accountManagerPages.billingInvoicesPage.outstandingAmount')} value={`$${toNumberWithCommas(invoicesSummary.outstandingAmount)}`} />
        <InfoBlock label={t('pages.accountManagerPages.billingInvoicesPage.paidAmount')} value={`$${toNumberWithCommas(invoicesSummary.paidAmount)}`} />
      </Box>
    </Paper>
  )
}

export default InvoicesSummary;
