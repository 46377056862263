import config from '../../../config';

import React, { useState } from 'react';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Components
import { Loader } from 'app/components/Utilities';
import { selectSearchPagesParams } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';

import { Box } from '@mui/material';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';

type Props = {
  page: IPage;
  activeTab: number;
}

const CardPageTabsTextItem:React.FC<Props> = ({
  // Props
  page, activeTab
}) => {
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const params = useSelector(selectSearchPagesParams);

  const [ loading, setLoading ] = React.useState<boolean>(false);
  const [ highlightedText, setHighlightedText ] = React.useState<string>('');
  const [ alreadyLoaded, setAlreadyLoaded ] = useState(false);

  React.useEffect(() => {
    async function fetchText(){
      setLoading(true);
      try {
        const response = await fetch(`${config.cdnUrl}/pages/${insuranceCase.id}_${page.documentId}_${page.pageNum}.txt`, {
          credentials: 'include'
        });
        const text = await response.text();
        setHighlightedText(highlightText(text, params.keywords));
        setAlreadyLoaded(true);
      } catch(err){
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    if ( activeTab === 2 && !alreadyLoaded ) fetchText();
    // eslint-disable-next-line
  }, [activeTab]);

  return (
    <Box sx={{
      width: '100%',
      position: 'relative',
      border: '1px solid rgba(0,0,0,0.08)',
      borderRadius: 1,
      pt: '141.4%', // Proporional of a4 paper is 1:1.414
      mt: 4,
      overflow: 'hidden'
    }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0, left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(255,255,255,0.75)',
            zIndex: 99
          }}
        ><Loader /></Box>
      ) : null}
      {highlightedText ? (
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <Box sx={{
            position: 'relative',
            top: 0, left: 0,
            width: '100%',
            height: '100%',
            px: 4, py: 6,
            overflowY: 'auto'
          }}>
            <span className="cp-pageNum">{page.pageNum}</span>
            <pre dangerouslySetInnerHTML={{
              __html: highlightedText
            }} style={{whiteSpace: 'pre-wrap', overflowY: 'auto'}}></pre>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default CardPageTabsTextItem;

const highlightText = (text:string, keywords:Array<string>) => {
  if ( keywords && keywords.length !== 0 ){
    const noDuplicates = [];
    for ( let keyword of keywords ){
      if ( noDuplicates.indexOf(keyword) === -1 ){
        noDuplicates.push(keyword);
      }
    }
    for ( let keyword of noDuplicates ){
      var pattern = new RegExp(`(${keyword})`,'gmi');
      var replaceWith = `<span class="hightlight">$1</span>`;
      text = text.replace(pattern, replaceWith);
    }
  }
  return text;
}
