import { ReactNode } from 'react';
// Mui
import {
  Button as MuiButton,
  ButtonTypeMap as MuiButtonTypeMap,
  ButtonProps as MuiButtonProps
} from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = {
  name: string;
  children: ReactNode;
}

const Button = <
  D extends React.ElementType = MuiButtonTypeMap['defaultComponent'],
  P = {}
>({ name, children, ...otherProps }:MuiButtonProps<D, P> & Props) => {
  return (
    <MuiButton
      {...otherProps}
      {...generateAttrForTesting('action', 'button', name)}
    >{children}</MuiButton>
  )
}

export default Button;
