import config from "../../../config";
// Types
import Reducers from "app/types/Reducers";
// Actions
import { ConversationsActions } from "app/store/Conversations/Conversations.slice";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put } from 'app/utilities/HttpClient';

const _url:string = `${config.messageApiUrl}/messages`;

export const getMessages = asyncThunkHandler(
  `${Reducers.Messages}/Get messages`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const createMessage = asyncThunkHandler(
  `${Reducers.Messages}/Create message`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    const { insuranceCaseId, ...otherData } = data;
    const response:Response = await $post(_url, otherData);
    const message = await response.json();

    dispatch(ConversationsActions.updateLastMessage({
      conversationId: otherData.conversationId,
      lastMessage: {
        text: message.text,
        createdOn: message.createdOn
      }
    }));

    return fulfillWithValue(message);
  }
);

export const updateMessage = asyncThunkHandler(
  `${Reducers.Messages}/Update message`,
  async ({ messageId, data }:{ messageId:number, data:any }) => {
    const response:Response = await $put(`${_url}/${messageId}`, data);
    return response.json();
  }
);
