import { FC } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiActions } from 'app/store/AppUI/AppUI.slice';
// Selectors
import { selectLabellingToolsSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
// Components
import SidebarToggleButton from 'app/components/SidebarToggleButton';

const LabellingToggle:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const open = useAppSelector(selectLabellingToolsSidebarOpen);

  const handleClick = () => {
    dispatch(AppUiActions.toggleLabellingToolsSidebarOpen());
  }

  return (
    <SidebarToggleButton
      name="Labeling sidebar toggle button"
      open={open}
      position="left"
      onClick={handleClick}
    />
  )
}

export default LabellingToggle;
