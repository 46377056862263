import { FC } from 'react';
// Models
import ISchedule from 'app/models/Schedule';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSchedules } from 'app/store/Medical/Medical.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
// 
import ScheduleItem from './ScheduleItem';
import ScheduleNoResult from './ScheduleNoResult';

const SchedulesList:FC = () => {
  // State
  const schedules:ISchedule[] | null = useAppSelector(selectSchedules);

  if ( !schedules ) return <Loader />;
  if ( !schedules.length ) return <ScheduleNoResult />;
  return (
    <Box sx={{ flexGrow: 1, maxHeight: 320, overflowY: 'auto', px: 4 }}>
      {schedules.map((schedule:ISchedule, index:number) => (
        <ScheduleItem
          key={`schedule-item-${index}`}
          schedule={schedule}
        />
      ))}
    </Box>
  )
}

export default SchedulesList;
