import { MiddlewareAPI, Middleware, isFulfilled, isRejected } from '@reduxjs/toolkit'
// Types
import BodyErrors from 'app/types/BodyErrors';
// Models
import { AppDispatch } from 'app/store';
// Async
import { logout } from 'app/store/Auth/Auth.async';
// Actions
import { AppUIStatusActions } from 'app/store/AppUIStatus/AppUIStatus.slice';
import { RequestsActions } from 'app/store/Requests/Requests.slice';
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';
import { isAIChatPathValid } from 'app/utilities/Utilities';

// List of errors on which snackbar should be skipped
const excludedErrors = [
  BodyErrors.UsernamePasswordInvalid
];
const excludedStatuses = [400, 401, 409];

const excludedMethods = ['POST'];

const AbortMiddleware:Middleware = ({ dispatch, getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type.endsWith('/pending') ){
    const controller = new AbortController();
    action.meta.signal = controller.signal;
    dispatch(RequestsActions.addAbortController(controller));
    dispatch(RequestsActions.incrementActiveRequests());
  }

  if ( isFulfilled(action) ){
    dispatch(RequestsActions.decrementActiveRequests());
  }

  if ( isRejected(action) ){
    const state = getState();
    const authenticated = selectAuthenticated(state);

    if ( typeof action.payload !== 'undefined' ){
      const { error, status, errors, path, method } = payload;
      console.log('Abort rejected payload', payload);

      if ( authenticated ){
        // ToDO
        if ( status === 503 && path && method ){
          if ( isAIChatPathValid(path) && excludedMethods.includes(method) ) return next(action);
        }
        // End: ToDO

        if ( excludedErrors.includes(error) || (excludedStatuses.includes(status) && errors) ){
          if ( status === 401 ){
            dispatch(RequestsActions.clearAbortControllers());
            dispatch(logout());
          }
          return next(action);
        }
        dispatch(AppUIStatusActions.setRequestStatusCode(status));
      }
    }

    dispatch(RequestsActions.decrementActiveRequests());
  }

  return next(action);
}

export default AbortMiddleware;
