// Types
import Reducers from "app/types/Reducers";
import CaseStatuses from "app/types/CaseStatuses";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './Cases.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $patch, $delete } from 'app/utilities/HttpClient';
import { downloadFile } from "app/utilities/Utilities";

const _url:string = '/insuranceCases';

// Get pending cases
export const getPendingCases = asyncThunkHandler(
  `${Reducers.Cases}/Get pending cases`,
  async () => {
    const response:Response = await $get(_url, { limit: 1000, status: CaseStatuses.Pending });
    return response.json();
  }
);

export const getConversationCases = asyncThunkHandler(
  `${Reducers.Cases}/Get conversion cases`,
  async () => {
    const response:Response = await $get(_url, { limit: 1000 });
    return response.json();
  }
);

// Get insurance cases
export const getInsuranceCases = asyncThunkHandler(
  `${Reducers.Cases}/Get insurance cases`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Get case
export const getInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Get insurance case`,
  async (insuranceCaseId:number, { signal }) => {
    const response:Response = await $get(`${_url}/${insuranceCaseId}`, {}, {
      signal
    });
    return response.json();
  }
);

// Create case
export const createInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Create insurance case`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update case
export const updateInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Update insurance case`,
  async ({ insuranceCaseId, data }:{ insuranceCaseId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${insuranceCaseId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Patch case
export const patchInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Patch insurance case`,
  async ({ insuranceCaseId, data, message = Messages.Update }:{ insuranceCaseId:number, data:any, message?:string }, { dispatch, fulfillWithValue }) => {
    const response:Response = await $patch(`${_url}/${insuranceCaseId}`, data);
    const body = await response.json();
    if ( message ){
      dispatch(AppUiNotificationsActions.addSnackbar({
        message
      }));
    }
    return fulfillWithValue({ ...data, id: insuranceCaseId, ...body });
  }
);

// Delete case
export const deleteInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Delete insurance case`,
  async (insuranceCaseId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${insuranceCaseId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(insuranceCaseId);
  }
);

export const statusesInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Statuses insurance case`,
  async ({ insuranceCaseId, data }:{ insuranceCaseId:number, data:any }, { dispatch, fulfillWithValue }) => {
    await $put(`${_url}/${insuranceCaseId}/status`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Status
    }));
    return fulfillWithValue(insuranceCaseId);
  }
);

export const archiveInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Archive insurance case`,
  async (insuranceCaseId:number, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/${insuranceCaseId}/actions/archive`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Archive
    }));
    return fulfillWithValue(insuranceCaseId);
  }
);

export const unarchiveInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Unarchive insurance case`,
  async (insuranceCaseId:number, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/${insuranceCaseId}/actions/unarchive`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Unarchive
    }));
    return fulfillWithValue(insuranceCaseId);
  }
);

// Accept case
export const acceptInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Accept insurance case`,
  async ({ insuranceCaseId, data }:{ insuranceCaseId:number, data:any }, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/${insuranceCaseId}/actions/accept`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Accept
    }));
    return fulfillWithValue(insuranceCaseId);
  }
);

// Reject case
export const rejectInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Reject insurance case`,
  async ({ insuranceCaseId, data }:{ insuranceCaseId:number, data:any }, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/${insuranceCaseId}/actions/reject`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Reject
    }));
    return fulfillWithValue(insuranceCaseId);
  }
);

// Transmt case
export const transmitInsuranceCase = asyncThunkHandler(
  `${Reducers.Cases}/Transmit insurance case`,
  async ({ insuranceCaseId, data }:{ insuranceCaseId:number, data:any }, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/${insuranceCaseId}/actions/transmit`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Transmit
    }));
    return fulfillWithValue(insuranceCaseId);
  }
);

// Create case duplicate
export const createCaseDuplicate = asyncThunkHandler(
  `${Reducers.Cases}/Create case duplicate`,
  async ({ caseId, data }:{ caseId:number, data:any }, { dispatch }) => {
    const response:Response = await $post(`${_url}/${caseId}/actions/duplicate`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.CreateDuplicate
    }));
    return response.json();
  }
);

// Get duplicates
export const getDuplicates = asyncThunkHandler(
  `${Reducers.Cases}/Get duplicates`,
  async (insuranceCaseId:number, { signal }) => {
    const response:Response = await $get(`${_url}/${insuranceCaseId}/duplicates`, {}, {
      signal
    });
    return response.json();
  }
);

// Update duplicates
export const updateDuplicates = asyncThunkHandler(
  `${Reducers.Cases}/Update duplicates`,
  async ({ insuranceCaseId, data, message }:{ insuranceCaseId:number, data:any, message?:string }, { dispatch, fulfillWithValue }) => {
    await $put(`${_url}/${insuranceCaseId}/duplicates`, data);
    if ( message ) dispatch(AppUiNotificationsActions.addSnackbar({ message }));
    return fulfillWithValue(data);
  }
);

export const downloadCalendarFile = asyncThunkHandler(
  `${Reducers.Cases}/Download calendar file`,
  async ({ insuranceCaseId, params }:{ insuranceCaseId:number, params:any }, { fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/${insuranceCaseId}/treatmentsCalendar/file`, params);

    downloadFile(response, { name: 'calendar', ext: 'pdf' });

    return fulfillWithValue(insuranceCaseId);
  }
);
