import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Async
import {
  getIntegrations, getIntegration, createIntegration, updateIntegration, patchIntegration, deleteIntegration
} from './Integrations.async';

interface IState {
  integrations: any;
  integration: any;
  loading: boolean;
};

const initialState:IState = {
  integrations: null,
  integration: null,
  loading: false
}

const slice = createSlice({
  name: Reducers.Integrations,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get integrations
    builder.addCase(getIntegrations.pending, (state) => {
      state.integrations = null;
    });
    builder.addCase(getIntegrations.fulfilled, (state, action:PayloadAction<any>) => {
      state.integrations = action.payload;
    });
    // Get integration
    builder.addCase(getIntegration.pending, (state) => {
      state.integration = null;
    });
    builder.addCase(getIntegration.fulfilled, (state, action:PayloadAction<any>) => {
      state.integration = action.payload;
    });
    // Create integration
    builder.addCase(createIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createIntegration.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.integrations ){
        state.integration = [...state.integrations, action.payload];
      }
    });
    // Update integration
    builder.addCase(updateIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateIntegration.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.integrations ){
        state.integration = state.integrations.map((integration:any) => {
          if ( integration.id === action.payload.id ) return action.payload;
          return integration;
        })
      }
    });
    // Patch integration
    builder.addCase(patchIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchIntegration.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.integrations ){
        state.integration = state.integrations.map((integration:any) => {
          if ( integration.id === action.payload.id ) return action.payload;
          return integration;
        })
      }
    });
    // Delete integration
    builder.addCase(deleteIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteIntegration.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.integrations ){
        state.integration = state.integrations.filter((integration:any) => {
          return integration.id !== action.payload
        })
      }
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false;
      }
    )
  }
});

export const IntegrationsActions = slice.actions;

export default slice.reducer;
