import React, { useEffect } from 'react';
//
import DuplicatesFilter from './DuplicatesFilter';
import DuplicatesCluster from './DuplicatesCluster';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import CaseStatuses from 'app/types/CaseStatuses';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { Loader, Message } from 'app/components/Utilities';
import { useTranslation } from 'react-i18next';
import { selectPageIds } from 'app/store/page/page.selectors';

const DuplicatesPage:React.FC = () => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  // State
  const insuranceCase = useSelector(selectInsuranceCase);
  const pageIds:string[] | null = useSelector(selectPageIds)

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseDuplicatesView, { id: Number(caseId) });
    // eslint-disable-next-line
  }, []);

  const isCaseStatusOpen = insuranceCase?.status === CaseStatuses.Open;
  const isCaseStatisArchive = insuranceCase?.status === CaseStatuses.Archived;
  const isCaseProcessingStatusReady = insuranceCase?.processing?.status === 'ready';

  if ( !pageIds ) return <Loader />;
  if ( (isCaseStatusOpen || isCaseStatisArchive) && isCaseProcessingStatusReady ) return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <DuplicatesFilter />
      <DuplicatesCluster />
    </Box>
  );
  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}><Message text={t('pages.adminPages.duplicatesPage.caseProcessing')} /></Box>
  );
}

export default DuplicatesPage;
