import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import { TemplateLayout, TemplateType } from 'app/types/Template';
// Models
import { RootState } from 'app/store';
import ITemplate, { ITemplateVariable } from 'app/models/Template';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectGlobalTemplates = (state:RootState) => state[Reducers.Templates].globalTemplates;

export const selectTemplates = (state:RootState) => state[Reducers.Templates].templates;
export const selectTemplate = (state:RootState) => state[Reducers.Templates].template;
export const selectVariables = (state:RootState) => state[Reducers.Templates].variables;

export const selectWizardFormInfo = (state:RootState) => state[Reducers.Templates].wizardForm.info;

export const selectFilter = (state:RootState) => state[Reducers.Templates].filter;
export const selectLoading = (state:RootState) => state[Reducers.Templates].loading;

export const selectAllTemplates = createSelector(
  [
    selectGlobalTemplates,
    selectTemplates
  ],
  ( globalTemplates:ITemplate[] | null, templates:ITemplate[] | null ) => {
    const templateGlobalIds = [...(templates || [])].reduce((acc:number[], cur:ITemplate) => {
      if ( cur.globalTemplateId ) acc = [...acc, cur.globalTemplateId];
      return acc
    }, []);

    return [
      ...(globalTemplates || []).filter((template:ITemplate) => !templateGlobalIds.includes(template.id)),
      ...(templates || [])
    ].sort((a, b) => sortByName(a, b, 'name'))
  }
);

export const selectAllTextTemplatesAsOptions = createSelector(
  [ selectAllTemplates ],
  ( templates:ITemplate[] ) => {
    return templates
      .filter((template:ITemplate) => template.type === TemplateType.Text)
      .map((template:ITemplate) => ({
        id: template.id,
        name: template.name
      }));
  }
);

export const selectAllNonTableTemplatesAsOptions = createSelector(
  [ selectAllTemplates ],
  ( templates:ITemplate[] ) => {
    return templates
      .filter((template:ITemplate) => template.layout !== TemplateLayout.Table)
      .map((template:ITemplate) => ({
        id: template.id,
        name: template.name
      }));
  }
);

export const selectFilteredAllTemplates = createSelector(
  [
    selectAllTemplates,
    selectFilter
  ],
  ( templates:ITemplate[] | null, filter:any ) => {
    if ( !templates ) return null;
    const { search } = filter;
    if ( !search ) return templates;
    const searchToLower = search.toLowerCase();
    return templates.filter((template:ITemplate) => template.name.toLowerCase().includes(searchToLower));
  }
);

export const selectFilteredAllTemplateIds = createSelector(
  [ selectFilteredAllTemplates ],
  (templates:ITemplate[] | null) => {
    if ( !templates ) return null;
    return templates.map((template:ITemplate) => template.id);
  }
);

export const selectTemplateById = createSelector(
  [
    selectAllTemplates,
    (_, props:{ templateId:number }) => props
  ],
  ( templates:ITemplate[] | null, { templateId } ) => {
    if ( !templates ) return null;
    return templates.find((template:ITemplate) => template.id === templateId) || null
  }
);

// Global templates
export const selectNotImportedGlobalTemplates = createSelector(
  [
    selectGlobalTemplates,
    selectTemplates
  ],
  ( globalTemplates:ITemplate[] | null, templates:ITemplate[] | null ) => {
    if ( !globalTemplates || !templates ) return null;

    const globalTemplateIds = templates.reduce((acc:number[], cur:ITemplate) => {
      if ( cur.globalTemplateId ) acc.push(cur.globalTemplateId);
      return acc;
    }, []);

    return globalTemplates
      .filter((template:ITemplate) => !globalTemplateIds.includes(template.id))
      .sort((a, b) => sortByName(a, b, 'name'))
  }
);

// Regular templates
export const selectFilteredTemplates = createSelector(
  [
    selectTemplates,
    selectFilter
  ],
  (templates:ITemplate[] | null, filter:any) => {
    if ( !templates ) return null;
    const { search } = filter;
    const sortedTemplates = [...templates].sort((a, b) => sortByName(a, b, 'name'))
    if ( !search ) return sortedTemplates;
    const searchToLower = search.toLowerCase();
    return sortedTemplates.filter((template:ITemplate) => template.name.toLowerCase().includes(searchToLower));
  }
);

export const selectFilteredTemplateIds = createSelector(
  [ selectFilteredTemplates ],
  (templates:ITemplate[] | null) => {
    if ( !templates ) return null;
    return templates.map((template:ITemplate) => template.id);
  }
);

// ---------
// Variables
// ---------
export const selectAppURLVariables = createSelector(
  [ selectVariables ],
  ( variables:ITemplateVariable[] | null ) => {
    if ( !variables ) return null;
    return variables.filter((variable:ITemplateVariable) => variable.name.includes('appURL'));
  }
);

export const selectTemplateVariables = createSelector(
  [ selectVariables ],
  ( variables:ITemplateVariable[] | null ) => {
    if ( !variables ) return null;
    return variables.filter((variable:ITemplateVariable) => !variable.name.includes('appURL'));
  }
);

export const selectGroupedTemplateVariables = createSelector(
  [ selectTemplateVariables ],
  ( variables:ITemplateVariable[] | null) => {
    if ( !variables ) return null;
    return variables.reduce((acc:Record<string, ITemplateVariable[]>, cur:ITemplateVariable) => {
      const group = cur.name.split(':')[0];
      if ( acc[group] ){
        acc[group] = [...acc[group], cur];
      } else {
        acc[group] = [cur];
      }
      return acc;
    }, {})
  }
);
