import React, { Fragment } from 'react';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiActions } from 'app/store/AppUI/AppUI.slice';
// Selectors
import { selectLanguage, selectAvailableLanguageAsOptions } from 'app/store/AppUI/AppUI.selectors';
// MUI
import { Language as LanguageIcon } from '@mui/icons-material';
// Components
import { Button } from './Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';

const LanguagePicker:React.FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const languageOptions:IOption[] = useAppSelector(selectAvailableLanguageAsOptions);
  const language:string = useAppSelector(selectLanguage);

  const { Menu, MenuItem, openMenu } = useMenu();

  const handleClick = (nextLanguage:string) => () => {
    dispatch(AppUiActions.setLanguage(nextLanguage));
  }

  return (
    <Fragment>
      <Button
        sx={{ gap: 2 }}
        name="Language picker"
        onClick={openMenu}
        variant="text"
      ><LanguageIcon />{language}</Button>
      <Menu>
        {languageOptions.map((option:IOption) => (
          <MenuItem
            name={`language menu ${option.id}`}
            key={`language menu item ${option.id}`}
            onClick={handleClick(option.id as string)}
          ><span style={{ textTransform: 'uppercase' }}>{option.id}</span>&nbsp;-&nbsp;{option.name}</MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

export default LanguagePicker;
