import { FC, Fragment, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredAllTemplateIds } from 'app/store/Templates/Templates.selectors';
// Mui
import {
  Theme,
  Box, Tabs, Tab, FormHelperText
} from '@mui/material';
// Icons
import { Error as ErrorIcon } from '@mui/icons-material';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// 
import PresetSelector from './PresetSelector';
import AllTemplatesFilter from './AllTemplatesFilter';
import AvailableList from './AvailableList';
import SelectedList from './SelectedList';
import SettingsTabContent from './SettingsTabContent';

const DialogContainer:FC = () => {
  const { t } = useTranslation('common');
  // State
  const filteredAllTemplateIds = useAppSelector(selectFilteredAllTemplateIds);

  const { formState:{ isSubmitted, errors }, setValue, getValues } = useFormContext();

  const { availableId, lists, onUpdateLists } = useReportPresetContext();

  const [ tab, setTab ] = useState(0);

  useEffect(() => {
    if ( !filteredAllTemplateIds ) return;

    const templateIds = getValues('templateIds');

    const availableIds = filteredAllTemplateIds.filter((id:number) => !templateIds.includes(id));

    onUpdateLists({
      available: availableIds,
      selected: templateIds
    });
    // eslint-disable-next-line
  }, [filteredAllTemplateIds]);

  useEffect(() => {
    setValue('templateIds', lists.selected);
    // eslint-disable-next-line
  }, [lists.selected]);

  const handleDragEnd = (result:any) => {
    if ( !result.destination ) return; // Dropped outside a list

    const sourceColumn = result.source.droppableId;
    const destinationColumn = result.destination.droppableId;

    if ( sourceColumn === availableId && destinationColumn === availableId ) return;

    if ( sourceColumn === destinationColumn ){
      // Reordering within the same column
      const sourceTasks = sourceColumn === availableId ? [...lists.available] : [...lists.selected];
      const [movedTask] = sourceTasks.splice(result.source.index, 1);
      sourceTasks.splice(result.destination.index, 0, movedTask);

      if ( sourceColumn === availableId ){
        onUpdateLists({ available: sourceTasks });
      } else {
        onUpdateLists({ selected: sourceTasks });
      }
    } else {
      const sourceTasks = sourceColumn === availableId ? [...lists.available] : [...lists.selected];
      const destinationTasks = destinationColumn === availableId ? [...lists.available] : [...lists.selected];
      const [movedTask] = sourceTasks.splice(result.source.index, 1);
      destinationTasks.splice(result.destination.index, 0, movedTask);

      if (sourceColumn === availableId) {
        onUpdateLists({
          available: sourceTasks,
          selected: destinationTasks
        });
      } else {
        onUpdateLists({
          available: destinationTasks,
          selected: sourceTasks
        });
      }
    }
  };

  const handleChangeTab = (_:any, nextTab:number) => {
    setTab(nextTab);
  }

  const errorTemplates = isSubmitted && !lists.selected.length;
  const errorSettings = (errors.watermark as any)?.text;

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      overflowY: 'auto'
    }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Box
          sx={{
            flexShrink: 0,
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white',
            borderRight: '1px solid rgba(0,0,0,0.08)'
          }}
        >
          <PresetSelector />
          <Box sx={{
            borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
            px: 4
          }}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              centered
            >
              <Tab
                sx={{ flexDirection: 'row' }}
                label={
                  <Fragment>
                    Templates
                    {errorTemplates ? <ErrorIcon sx={{ ml: 2 }} color="error" /> : null}
                  </Fragment>
                }
              />
              <Tab
                sx={{ flexDirection: 'row' }}
                label={
                  <Fragment>
                    Settings
                    {errorSettings ? <ErrorIcon sx={{ ml: 2 }} color="error" /> : null}
                  </Fragment>
                }
              />
            </Tabs>
          </Box>
          <Box sx={{
            display: tab === 0 ? 'flex' : 'none',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden'
          }}>
            <AllTemplatesFilter />
            {errorTemplates ? (
              <FormHelperText sx={{ px: 4, mb: 2 }} error>{t('dialogs.reportPresetForm.templateShouldBeSelected')}</FormHelperText>
            ) : null}
            <AvailableList />
          </Box>
          <Box sx={{ display: tab === 1 ? 'block' : 'none' }}>
            <SettingsTabContent />
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: '#f7f7f7',
            overflowY: 'auto'
          }}
        ><SelectedList /></Box>
      </DragDropContext>
    </Box>
  )
}

export default DialogContainer;
