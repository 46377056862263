import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
// ToDO
import { selectIsWorkspacePageLastOpened, selectIsFilePageLastOpened } from 'app/store/TODO2_app/app.selectors';
// 
import PageCard from './PageCard';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  scrollContainer?: string;
  episodeId?: number;
  documentId: number;
  pageNum: number;
  height?: number;
  index?: number;
}

export const PageCardWrapper:React.FC<Props> = (props) => {
  const { t } = useTranslation('common');

  const { pathname } = useLocation<{ pathname:string }>();
  const { caseId } = useParams<{ caseId:string }>();

  const isWorkspacePage = pathname.includes('/workspace');
  const isFilePage = pathname.includes('/documents');
  // ToDO
  const workspaceType = useSelector(selectWorkspaceType);
  const isWorkspacePageLastOpened = useSelector((state:any) => selectIsWorkspacePageLastOpened(state, { insuranceCaseId: Number(caseId), documentId: props.documentId, pageNum: props.pageNum }));
  const isFilePageLastOpened = useSelector((state:any) => selectIsFilePageLastOpened(state, { insuranceCaseId: Number(caseId), documentId: props.documentId, pageNum: props.pageNum }));

  const pageNum = typeof props.index !== 'undefined' ? props.index + 1 : props.pageNum;

  return (
    <LazyLoad
      classNamePrefix="card-page"
      scrollContainer={props.scrollContainer || '#scrollContainer'}
      height={props.height}
      placeholder={
        <div
          react-page-num={pageNum}
          react-last-workspace-opened-page={Boolean(isWorkspacePage && isWorkspacePageLastOpened).toString()}
          react-last-file-opened-page={Boolean(isFilePage && isFilePageLastOpened).toString()}
          className="card-page-placeholder"
          style={{
            height: props.height
          }}
        >{workspaceType === 'pages' ? t('components.cardPage.page') : t('components.cardPage.episode')} {pageNum}</div>
      }
      debounce={false}
      throttle={250}
    >
      <PageCard {...props} />
    </LazyLoad>
  )
}

export default React.memo(PageCardWrapper);