import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
import { createIntegration, patchIntegration } from 'app/store/Integrations/Integrations.async';

const IntegrationsQuickbooksCallback = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryError = searchParams.get('error') || '';
  const queryCode = searchParams.get('code');
  const queryRealmId = searchParams.get('realmId');
  const queryState = searchParams.get('state');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if ( !queryError ){
      const data = {
        connectData: {
          code: queryCode,
          realmId: queryRealmId,
          state: queryState
        }
      };
      const queryStateParams = new URLSearchParams(queryState);
      const integrationId = queryStateParams.get('integrationId') || null;
      if ( integrationId ){
        // Re-connect
        asyncPatchIntegration(integrationId, data);
      } else {
        // Connect
        data['name'] = 'QuickBooks';
        data['service'] = 'quickBooks';
        asyncCreateIntegration(data);
      }
    }
    // eslint-disable-next-line
  }, []);

  const asyncCreateIntegration = async (data) => {
    try {
      await dispatch(createIntegration(data)).unwrap();
      window.opener.location.reload();
      window.close();
    } catch(error){}
  }

  const asyncPatchIntegration = async (integrationId, data) => {
    try {
      await dispatch(patchIntegration({ integrationId, data })).unwrap();
      window.opener.location.reload();
      window.close();
    } catch(error){}
  }

  return null;
}

export default IntegrationsQuickbooksCallback;
