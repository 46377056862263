import { ChangeEvent, FC, useState, useEffect } from 'react';
// Models
import IUser from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { UsersActions } from 'app/store/Users/Users.slice';
// Selectors
import { selectUsers, selectFilter } from 'app/store/Users/Users.selectors';
// MaterialUI
import { Box } from '@mui/material';
// Components
import { Input, Select } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// i18next
import { useTranslation } from 'react-i18next';

const UsersFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const users:IUser[] | null = useAppSelector(selectUsers);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const debounceSearch = useDebounce(stateFilter.search);
  const debounceRole = useDebounce(stateFilter.role);
  const debouncePlatform = useDebounce(stateFilter.platform);

  useEffect(() => {
    if ( debounceSearch !== filter.search ){
      dispatch(UsersActions.setFilter({ field: 'search', value: debounceSearch }));
    }
    if ( debounceRole !== filter.role ){
      dispatch(UsersActions.setFilter({ field: 'role', value: debounceRole }));
    }
    if ( debouncePlatform !== filter.platform ){
      dispatch(UsersActions.setFilter({ field: 'platform', value: debouncePlatform }));
    }
    // eslint-disable-next-line
  }, [debounceSearch, debounceRole, debouncePlatform]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  }
  
  const handleClearSearch = () => {
    setStateFilter((prevState:any) => ({
      ...prevState,
      search: ''
    }));
  }

  if ( users && !users.length ) return null;
  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
      <Input
        label={t('labels.search')} id="search" name="search"
        placeholder={t('pages.adminPages.usersPage.nameOrEmail')}
        value={stateFilter.search}
        onChange={handleChange}
        onClear={handleClearSearch}
        size="small"
        margin="none"
      />
      <Select
        sx={{ maxWidth: 120 }}
        label={t('labels.role')} id="role" name="role"
        value={stateFilter.role}
        onChange={handleChange}
        options={[
          { id: 'all', name: t('labels.all') },
          { id: 'admin', name: t('pages.adminPages.usersPage.admin') },
          { id: 'staff', name: t('pages.adminPages.usersPage.staff') },
          { id: 'presenter', name: t('pages.adminPages.usersPage.presenter') },
          { id: 'client', name: t('pages.adminPages.usersPage.client') }
        ]}
        size="small"
        margin="none"
      />
      <Select
        sx={{ maxWidth: 160 }}
        label="Platform" id="platform" name="platform"
        value={stateFilter.platform}
        onChange={handleChange}
        options={[
          { id: 'all', name: t('labels.all') },
          { id: 'true', name: 'Platform' },
          { id: 'false', name: 'Non-platform' },
        ]}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default UsersFilter;
