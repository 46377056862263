import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
// Models
import { RootState } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectPageDates } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import PageDates from 'app/components/PageDates';
// Utilities
import { parseDate, setPreEvent } from 'app/utilities/DateTime';
// ToDO
import { IPage } from '@root/models/Page';
import { selectPage } from 'app/store/page/page.selectors';
// End: ToDo

type Props = {
  isDisabled: boolean;
}

const EpisodeFormDates:FC<Props> = ({ isDisabled }) => {
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  // ToDO
  const page = useAppSelector((state) => selectPage(state as any)) as IPage;
  // End: ToDO
  const pageDates = useAppSelector((state:RootState) => selectPageDates(state, {
    documentId: page.documentId,
    pageNum: page.pageNum
  }));

  const { getValues, setValue } = useFormContext();

  const handleClick = (nextDate:string) => {
    const parsedDate = parseDate(nextDate);
    setValue('date', parsedDate);
    const time = getValues('time');
    setValue('preEvent', setPreEvent({
      eventDate: insuranceCase.eventDate,
      eventTime: insuranceCase.eventTime,
      currentDate: parsedDate,
      currentTime: time
    }));
  }

  if ( isDisabled ) return null;
  return (
    <Box sx={{ mb: 4 }}>
      <PageDates dates={pageDates} onClick={handleClick} />
    </Box>
  )
}

export default EpisodeFormDates;
