
import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import IReport from 'app/models/Report';
// Utilities
import { sortByCreationDate } from 'app/utilities/SortBy';

export const selectReports = (state:RootState) => state[Reducers.CaseReports].reports;
export const selectLoading = (state:RootState) => state[Reducers.CaseReports].loading;

export const selectSortedReports = createSelector(
  [ selectReports ],
  ( reports:IReport[] | null ) => {
    if ( !reports ) return null;
    return [...reports].sort(sortByCreationDate);
  }
)
