import { FC } from 'react';
import { Droppable } from 'react-beautiful-dnd';
// Mui
import { alpha, Theme, Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// 
import AvailableListItem from './AvailableListItem';
// i18next
import { useTranslation } from 'react-i18next';

const AvailableList:FC = () => {
  const { t } = useTranslation('common');

  const { availableId, lists } = useReportPresetContext();

  const availableIds = lists.available;

  const content = !availableIds
    ? <Loader />
    : !availableIds.length
      ? <Message text={t('dialogs.reportPresetForm.noTemplatesAvailable')} />
      : <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {availableIds.map((templateId:number, index) => (
            <AvailableListItem
              key={`template item ${templateId}`}
              index={index}
              templateId={templateId}
            />
          ))}
        </Box>
  ;
  return (
    <Droppable droppableId={availableId} direction="vertical">
      {(provided, snapshot) => (
        <Box
          {...provided.droppableProps}
          ref={provided.innerRef}
          sx={{
            flexGrow: 1,
            bgcolor: (theme:Theme) => snapshot.draggingOverWith ? alpha(theme.palette.primary.light, 0.05) : 'initial',
            overflowY: 'auto'
          }}
        >
          {content}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

export default AvailableList;
