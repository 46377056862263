import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IEpisodeAuthor from "app/models/EpisodeAuthor";
// Async
import {
  getAuthor,
  getAuthors, createAuthor, updateAuthor, patchAuthor, deleteAuthor,
  deduplicateAuthors, createAuthorType,
  getAuthorAnalytics
} from "./EpisodeAuthors.async";

interface IState {
  author: IEpisodeAuthor | null;
  authors: IEpisodeAuthor[] | null;
  authorAnalytic: any;
  filter: {
    limit: number;
    offset: number;
    search: string;
    verified: string;
    organization: string;
  };
  error: { status:number, props?:any } | null;
  loading: boolean;
};

const initialState:IState = {
  author: null,
  authors: null,
  authorAnalytic: null,
  filter: {
    limit: 20,
    offset: 0,
    search: '',
    verified: 'all',
    organization: 'all'
  },
  error: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.EpisodeAuthors,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<Partial<IState['filter']>>) => {
      // const { field, value } = action.payload;
      state.filter = { ...state.filter, ...action.payload };
      // (state.filter as any)[field] = value;
    },
    setError: (state, action:PayloadAction<{ status:number, props?:any } | null>) => {
      state.error = action.payload;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder){
    // Get authors
    builder.addCase(getAuthors.pending, (state) => {
      state.authors = null;
    });
    builder.addCase(getAuthors.fulfilled, (state, action:PayloadAction<IEpisodeAuthor[]>) => {
      state.authors = action.payload;
    });
    // Get author
    builder.addCase(getAuthor.pending, (state) => {
      state.author = null;
    });
    builder.addCase(getAuthor.fulfilled, (state, action:PayloadAction<IEpisodeAuthor>) => {
      state.author = action.payload;
    });
    // Create author
    builder.addCase(createAuthor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAuthor.fulfilled, (state, action:PayloadAction<IEpisodeAuthor>) => {
      if ( state.authors ){
        state.authors = [...state.authors, action.payload];
      }
    });
    // Update author
    builder.addCase(updateAuthor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAuthor.fulfilled, (state, action:PayloadAction<IEpisodeAuthor>) => {
      if ( state.authors ){
        state.authors = state.authors.map((author:IEpisodeAuthor) => {
          if ( author.id === action.payload.id ) return action.payload;
          return author;
        })
      }
    });
    // Patch author
    builder.addCase(patchAuthor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchAuthor.fulfilled, (state, action:PayloadAction<IEpisodeAuthor>) => {
      if ( state.authors ){
        state.authors = state.authors.map((author:IEpisodeAuthor) => {
          if ( author.id === action.payload.id ) return action.payload;
          return author;
        })
      }
    });
    // Delete author
    builder.addCase(deleteAuthor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAuthor.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.authors ){
        state.authors = state.authors.filter((author:IEpisodeAuthor) => author.id !== action.payload);
      }
    });
    // Deduplicate author
    builder.addCase(deduplicateAuthors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deduplicateAuthors.fulfilled, (state, action:PayloadAction<{ sourceId:number, author:IEpisodeAuthor }>) => {
      const { sourceId, author } = action.payload;
      if ( state.authors ){
        state.authors = state.authors
          .filter((author:IEpisodeAuthor) => author.id !== sourceId)
          .map((a:IEpisodeAuthor) => {
            if ( a.id === author.id ) return author;
            return a;
          })
      }
    });
    // Create author type
    builder.addCase(createAuthorType.fulfilled, (state, action:PayloadAction<{ id:number, type:string }>) => {
      const { id, type } = action.payload;
      if ( state.author && state.author.id === id ){
        state.author = {...state.author, episodeTypes: [
          ...(state.author.episodeTypes || []),
          type
        ]}
      }
    });
    // Get author analytic
    builder.addCase(getAuthorAnalytics.pending, (state) => {
      state.authorAnalytic = null;
    });
    builder.addCase(getAuthorAnalytics.fulfilled, (state, action:PayloadAction<any>) => {
      state.authorAnalytic = action.payload;
    });

    builder.addMatcher(
      (action) => action.type.includes('/rejected') || action.type.includes('/fulfilled'),
      (state) => {
        state.loading = false;
      }
    )
  }
});

export const EpisodeAuthorsActions = slice.actions;

export default slice.reducer;
