import { ReactNode, forwardRef } from 'react';
// Mui
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = MuiIconButtonProps & {
  name: string;
  children: ReactNode;
}

const IconButton = forwardRef<any, Props>(({
  // Props
  name, children, ...otherProps
}, ref) => {
  return (
    <MuiIconButton
      ref={ref}
      {...otherProps}
      {...generateAttrForTesting('action', 'button', name)}
    >{children}</MuiIconButton>
  )
});

export default IconButton;
