import { FC, useMemo } from 'react';
// Types
import Colors from 'app/types/Colors';
// Mui
import { Theme, Box } from '@mui/material';
// Components
import Icon from 'app/components/Icon';
// Constants
import { NO_COLOR_VALUE } from 'app/App.constants';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = {
  id?: number | string;
  onClick?: (nextColor:Colors|string) => void;
  activeColors?: (Colors|string)[];
  noColorVisible?: boolean;
}

const ColorPicker:FC<Props> = ({
  // Props
  id = 'color-picker', onClick, activeColors = [], noColorVisible = false
}) => {
  const onClickUndefined = typeof onClick === 'undefined';

  const defaultSx = useMemo(() => ({
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    color: 'white',
    '&:hover': {
      cursor: onClickUndefined ? 'default' : 'pointer'
    }
    // eslint-disable-next-line
  }), []);

  const colors = useMemo(() => {
    if ( onClickUndefined ) return activeColors;
    return Object.values(Colors);
  }, [activeColors, onClickUndefined])

  const handleClick = (color:Colors | string) => () => {
    if ( onClickUndefined ) return false;
    onClick(color);
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
      {noColorVisible ? (
        <Box
          {...generateAttrForTesting('action', 'box-color', NO_COLOR_VALUE)}
          sx={{
            ...defaultSx,
            position: 'relative',
            border: (theme:Theme) => `2px solid ${activeColors.includes(NO_COLOR_VALUE) ? theme.palette.primary.main : '#8e8e8e'}`,
            overflow: 'hidden',
            '&:before': {
              content: '""',
              width: 2,
              height: 40,
              position: 'absolute',
              bgcolor: (theme:Theme) => activeColors.includes(NO_COLOR_VALUE) ? theme.palette.primary.main : '#8e8e8e',
              transform: 'rotate(45deg)'
            }
          }}
          onClick={handleClick(NO_COLOR_VALUE)}
        />
      ) : null}
      {colors.map((color:Colors|string) => (
        <Box
          {...generateAttrForTesting('action', 'box-color', color)}
          key={`${id}-color-item-${color}`}
          className={`bg-${color}`}
          sx={defaultSx}
          onClick={handleClick(color)}
        >{activeColors.includes(color) ? <Icon icon="done" /> : null}</Box>
      ))}
    </Box>
  )
}

export default ColorPicker;