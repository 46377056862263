import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
// Types
import { TemplateDataType, TemplateLayout } from 'app/types/Template';
// Mui
import { Box, Toolbar, Typography } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';
// 
import DocumentsSettings from './DocumentsSettings';
import EpisodeTableSettings from './EpisodeTableSettings';
import EpisodeCustomSettings from './EpisodeCustomSettings';

const EditorSettings:FC = () => {
  const { t } = useTranslation('common');

  const { watch } = useFormContext();

  const watchDataType = watch('dataType');
  const watchLayout = watch('layout');

  const isDataTypeDocument = watchDataType === TemplateDataType.Document;
  const isDataTypeEpisode = watchDataType === TemplateDataType.Episode;
  const isLayoutChart = watchLayout === TemplateLayout.Chart;
  const isLayoutTable = watchLayout === TemplateLayout.Table;
  const isLayoutCustom = watchLayout === TemplateLayout.Custom;

  const isDocumentTableOrCustom = isDataTypeDocument && ( isLayoutTable || isLayoutCustom );
  const isEpisodeTable = isDataTypeEpisode && isLayoutTable;
  const isEpisodeChartOrCustom = isDataTypeEpisode && ( isLayoutChart || isLayoutCustom );

  if ( !isDocumentTableOrCustom && !isEpisodeTable && !isEpisodeChartOrCustom ) return null;
  return (
    <Box sx={{
      flexShrink: 0,
      width: 480,
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'white',
      borderLeft: '1px solid rgba(0,0,0,0.08)'
    }}>
      <Toolbar sx={{ px: 6 }}>
        <Typography variant="h6">{t('labels.filters')}</Typography>
      </Toolbar>
      <Box sx={{
        flexGrow: 1,
        py: 4,
        px: 6,
        overflowY: 'auto'
      }}>
        { isDocumentTableOrCustom ? <DocumentsSettings /> : null}
        { isEpisodeTable ? <EpisodeTableSettings /> : null}
        { isEpisodeChartOrCustom ? <EpisodeCustomSettings /> : null}
      </Box>
    </Box>
  );
}

export default memo(EditorSettings);
