import { FC, Fragment } from 'react';
import dayjs from 'dayjs';
// Models
import IInsuranceCase, { IBillingContact, ICustomFieldDefinition } from 'app/models/Case';
import { ICurrentAccount } from 'app/models/Account';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Box, Paper } from '@mui/material';
// Components
import { InfoBlock, MailTo, OutlineBlock } from 'app/components/Utilities';
// 
import useFormattedDate from 'app/hooks/useFormattedDate';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  insuranceCase: IInsuranceCase;
  showClaimant?: boolean;
}

const ClientDetails:FC<Props> = ({
  // Props
  insuranceCase, showClaimant = true
}) => {
  const { t } = useTranslation('common');

  const claimant = insuranceCase.claimant || {};
  const clientAccount = insuranceCase.clientAccount || {};
  const customerContact = insuranceCase.customerContact || {};
  const billingContacts = insuranceCase.billingContacts;
  const customFieldDefinitions = insuranceCase.customFieldDefinitions;
  const customFields = insuranceCase.customFields || {};

  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);

  const formattedDateOfBirth = useFormattedDate(claimant.dateOfBirth);
  const formattedEventDate = useFormattedDate(insuranceCase.eventDate);

  const inputFormat = (currentAccount?.settings?.dateFormat || 'MM/DD/YYYY').toUpperCase();

  const companyFullAddress:string[] = [];
  if ( customerContact.companyAddress ) companyFullAddress.push(customerContact.companyAddress);
  if ( customerContact.companyCity ) companyFullAddress.push(customerContact.companyCity);
  if ( customerContact.companyState ) companyFullAddress.push(customerContact.companyState);
  if ( customerContact.companyZip ) companyFullAddress.push(customerContact.companyZip);

  return (
    <Box sx={{ pt: 4 }}>
      {showClaimant ? (
        <OutlineBlock label={t('labels.claimant')} sx={{ mb: 6 }}>
          <Fragment>
            {claimant.name ? (
              <InfoBlock label={t('labels.name')} value={claimant.name} direction="row" size="large" />
            ) : null}
            {formattedDateOfBirth ? (
              <InfoBlock label={t('labels.dateOfBirth')} value={formattedDateOfBirth} direction="row" size="large" />
            ) : null}
            {formattedEventDate ? (
              <InfoBlock label={t('components.clientDetails.eventDate')} value={formattedEventDate} direction="row" size="large" />
            ) : null}
            {insuranceCase.claimNumber ? (
              <InfoBlock label={t('labels.claimNumber')} value={insuranceCase.claimNumber} direction="row" size="large" />
            ) : null}
          </Fragment>
        </OutlineBlock>
      ) : null}
      {clientAccount.company ? (
        <OutlineBlock label={t('labels.clientAccount')}>
          <InfoBlock label={t('labels.company')} value={clientAccount.company} direction="row" size="large" />
        </OutlineBlock>
      ) : null}
      {Object.values(customerContact || {}).length ? (
        <OutlineBlock label={t('components.clientDetails.reportAddressee')} sx={{ mt: 6 }}>
          <Fragment>
            {customerContact.attorneyName ? (
              <InfoBlock label={t('labels.name')} value={customerContact.attorneyName} direction="row" size="large" />
            ) : null}
            {customerContact.attorneyEmail ? (
              <InfoBlock label={t('labels.email')} value={<MailTo email={customerContact.attorneyEmail} />} direction="row" size="large" />
            ) : null}
            {customerContact.attorneyPhone ? (
              <InfoBlock label={t('labels.phone')} value={customerContact.attorneyPhone} direction="row" size="large" />
            ) : null}
            {customerContact.companyName ? (
              <InfoBlock label={t('components.clientDetails.companyName')} value={customerContact.companyName} direction="row" size="large" />
            ) : null}
            {companyFullAddress.length ? (
              <InfoBlock label={t('components.clientDetails.companyAddress')} value={companyFullAddress.join(', ')} direction="row" size="large" />
            ) : null}
          </Fragment>
        </OutlineBlock>
      ) : null}
      {billingContacts && billingContacts.length ? (
        <OutlineBlock label={t('components.clientDetails.contacts')} sx={{ mt: 6 }}>
          <Fragment>
            {billingContacts.map((billingContact:IBillingContact, index:number) => (
              <Paper
                key={`billing-item-${index}`}
                sx={{ p: 4, '& + &': { mt: 4 } }}
                variant="outlined"
              >
                <InfoBlock label={t('labels.type')} value={billingContact.type} direction="row" size="large" />
                <InfoBlock label={t('labels.name')} value={billingContact.name} direction="row" size="large" />
                <InfoBlock label={t('labels.email')} value={<MailTo email={billingContact.email} />} direction="row" size="large" />
                <InfoBlock label={t('labels.phone')} value={billingContact.phone} direction="row" size="large" />
              </Paper>
            ))}
          </Fragment>
        </OutlineBlock>
      ) : null}
      {Object.values(customFields || {}).length ? (
        <OutlineBlock label={t('components.clientDetails.additionalInformation')} sx={{ mt: 6 }}>
          <Fragment>
            {customFieldDefinitions.map((customField:ICustomFieldDefinition, index:number) => {
              if ( !customFields[customField.name] ) return null;
              return (
                <InfoBlock
                  key={`custom-field-item-${index}`}
                  label={customField.description}
                  value={
                    customField.type === 'date'
                    ? dayjs(customFields[customField.name]).format(inputFormat)
                    : customFields[customField.name]
                  }
                  direction="row"
                  size="large"
                />
              )
            })}
          </Fragment>
        </OutlineBlock>
      ) : null}
    </Box>
  )
}

export default ClientDetails;