// Types
import Statuses from '@root/types/Statuses';
import { PageTypes, PageActionTypes } from './page.types';
// Models
import { IPage } from '@root/models/Page';
import { IState } from './page.models';

export default class PageActions {
  // Fetch pages
  public static fetchPages = (params:any):PageActionTypes => ({
    type: PageTypes.FetchPages, payload: { params }
  });
  public static fetchPagesSuccess = (pages:Array<IPage>):PageActionTypes => ({
    type: PageTypes.FetchPagesSuccess, payload: { pages }
  });
  // Fetch page
  public static fetchPage = (documentId:number, pageNum:number):PageActionTypes => ({
    type: PageTypes.FetchPage, payload: { documentId, pageNum }
  });
  public static fetchPageSuccess = (page:IPage):PageActionTypes => ({
    type: PageTypes.FetchPageSuccess, payload: { page }
  });
  public static fetchPageFailure = (status: number):PageActionTypes => ({
    type: PageTypes.FetchPageFailure, payload: { status }
  });
  // Update page
  public static updatePage = (documentId:number, pageNum:number, data:any, message:string|null = null):PageActionTypes => ({
    type: PageTypes.UpdatePage, payload: { documentId, pageNum, data, message }
  });
  public static updatePageSuccess = (page:IPage):PageActionTypes => ({
    type: PageTypes.UpdatePageSuccess, payload: { page }
  });
  // Patch page
  public static patchPage = (documentId:number, pageNum:number, data:any, message:string|null = null):PageActionTypes => ({
    type: PageTypes.PatchPage, payload: { documentId, pageNum, data, message }
  });
  public static patchPageSuccess = (page:IPage):PageActionTypes => ({
    type: PageTypes.PatchPageSuccess, payload: { page }
  });
  // Rotate page
  public static rotatePage = (documentId:number, pageNum:number, data:any):PageActionTypes => ({
    type: PageTypes.RotatePage, payload: { documentId, pageNum, data }
  });
  public static rotatePageSuccess = (page:IPage):PageActionTypes => ({
    type: PageTypes.RotatePageSuccess, payload: { page }
  });
  // Delete page
  public static deletePage = (documentId:number, pageNum:number):PageActionTypes => ({
    type: PageTypes.DeletePage, payload: { documentId, pageNum }
  });
  public static deletePageSuccess = (documentId:number, pageNum:number):PageActionTypes => ({
    type: PageTypes.DeletePageSuccess, payload: { documentId, pageNum }
  });
  // Patch pages
  public static patchPages = (data:any, message:string|null = null, cb?:() => void):PageActionTypes => ({
    type: PageTypes.PatchPages, payload: { data, message, cb }
  });
  public static patchPagesSuccess = (pages:Array<IPage>):PageActionTypes => ({
    type: PageTypes.PatchPagesSuccess, payload: { pages }
  });
  // Export pages
  public static exportPagesToPDF = (pages:Array<{ documentId:number, pageNum:number }>):PageActionTypes => ({
    type: PageTypes.ExportPagesToPDF, payload: { pages }
  });
  public static exportPagesToPDFSuccess = ():PageActionTypes => ({
    type: PageTypes.ExportPagesToPDFSuccess
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):PageActionTypes => ({
    type: PageTypes.SetStatus, payload: { status }
  });
  public static setInitialField = (field:keyof IState):PageActionTypes => ({
    type: PageTypes.SetInitialField, payload: { field }
  });
  public static setFilter = (field:string, value:any):PageActionTypes => ({
    type: PageTypes.SetFilter, payload: { field, value }
  });
  // Compare
  public static addPageToCompare = (documentId:number, pageNum:number, stapleId:string|null):PageActionTypes => ({
    type: PageTypes.AddPageToCompare, payload: { documentId, pageNum, stapleId }
  });
  public static removePageFromCompare = (documentId:number, pageNum:number, stapleId:string|null):PageActionTypes => ({
    type: PageTypes.RemovePageFromCompare, payload: { documentId, pageNum, stapleId }
  });
  // Staple
  public static setStapleModeCreate = (documentId:number):PageActionTypes => ({
    type: PageTypes.SetStapleModeCreate, payload: { documentId }
  });
  public static setStapleModeEdit = (documentId:number, stapleId:string, isWorkspacePage:boolean = false):PageActionTypes => ({
    type: PageTypes.SetStapleModeEdit, payload: { documentId, stapleId, isWorkspacePage }
  });
  public static cancelStapleMode = ():PageActionTypes => ({
    type: PageTypes.CancelStapleMode
  });
  // Add/Remove to/from staple
  public static addPageToStaple = (page:IPage):PageActionTypes => ({
    type: PageTypes.AddPageToStaple, payload: { page }
  });
  public static removePageFromStaple = (page:IPage):PageActionTypes => ({
    type: PageTypes.RemovePageFromStaple, payload: { page }
  });
  public static addAllPagesToStaple = (documentId:number):PageActionTypes => ({
    type: PageTypes.AddAllPagesToStaple, payload: { documentId }
  });

  public static movePageInCompare = (pageId:string, isNext:boolean = false):PageActionTypes => ({
    type: PageTypes.MovePageInCompare, payload: { pageId, isNext }
  });

  public static documentPagesRefetch = (pages:IPage[]):PageActionTypes => ({
    type: PageTypes.DocumentPagesRefetch, payload: { pages }
  });


  public static updatePagesWithSocket = (pages:IPage[]):PageActionTypes => ({
    type: PageTypes.UpdatePagesWithSocket, payload: { pages }
  });

  public static setPageStaples = (pageStaples:IPage[] | null):PageActionTypes => ({
    type: PageTypes.SetPageStaples, payload: { pageStaples }
  });
}