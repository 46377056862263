import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import { IOption_V2 } from 'app/models/Option';
import IEpisode from 'app/models/Episode';
// Utilities
import { sortByCreationDate, sortByName } from 'app/utilities/SortBy';
// ToDO
import { selectPagesInWorkspaceIds } from 'app/store/page/page.selectors';
import PageService from 'app/services/PageService';
import { IPage } from '@root/models/Page';
// End: ToDO

export const selectEpisodesIds = (state:RootState) => state[Reducers.Episodes].episodes.ids;
export const selectEpisodesEntities = (state:RootState) => state[Reducers.Episodes].episodes.entities;
export const selectEpisodesTotal = (state:RootState) => state[Reducers.Episodes].episodes.total;
export const selectParams = (state:RootState) => state[Reducers.Episodes].params;
export const selectHasMore = (state:RootState) => state[Reducers.Episodes].hasMore;
export const selectGettingMore = (state:RootState) => state[Reducers.Episodes].gettingMore;
export const selectLoading = (state:RootState) => state[Reducers.Episodes].loading;

export const selectSelectedEpisodeId = (state:RootState) => state[Reducers.Episodes].selectedEpisodeId;

export const selectStatusCode = (state:RootState) => state[Reducers.Episodes].statusCode;
export const selectStatusMessage = (state:RootState) => state[Reducers.Episodes].statusMessage;

export const selectEpisodeEntity = createSelector(
  [
    selectEpisodesEntities,
    (_, props:{ episodeId:number | undefined }) => props
  ],
  (entities:Record<number, IEpisode>, { episodeId }) => {
    if ( !episodeId ) return undefined;
    return entities[episodeId];
  }
);

export const selectWorkspaceEpisodeIds = createSelector(
  [
    selectEpisodesIds,
    selectEpisodesEntities,
    (state:any) => selectPagesInWorkspaceIds(state)
  ],
  (ids:number[] | null, entities:Record<number, IEpisode>, pageIds:string[]) => {
    if ( !ids ) return null;
    return ids.filter((id:number) => {
      const episodeEntity = entities[id];
      const pageId = PageService.toPageId(episodeEntity.documentId, episodeEntity.pageNum);
      return pageIds.includes(pageId);
    });
  }
);

interface IAuthor {
  id: number;
  name: string;
  verified: boolean;
}

export const selectEpisodeAuthorsAsOptions = createSelector(
  [
    selectEpisodesEntities,
    selectWorkspaceEpisodeIds
  ],
  (entities:Record<number, IEpisode>, ids:number[] | null):IOption_V2[] | null => {
    if ( !ids ) return null;

    return ids.reduce((acc:IAuthor[], cur:number) => {
      const episode = entities[cur];
      if ( episode?.author?.id && !acc.some((a:IAuthor) => a.id === episode.author?.id) ){
        acc.push(episode.author);
      }
      return acc;
    }, [])
      .sort((a:IAuthor, b:IAuthor) => sortByName(a, b, 'name'))
      .map((author:IAuthor) => ({
        value: author.id.toString(),
        label: author.name,
        props: {
          verified: author.verified
        }
      }));
  }
);

export const selectEpisodeTypesAsOptions = createSelector(
  [
    selectEpisodesEntities,
    selectWorkspaceEpisodeIds
  ],
  (entities:Record<number, IEpisode>, ids:number[] | null):IOption_V2[] | null => {
    if ( !ids ) return null;
    return ids.reduce((acc:any[], cur:number) => {
      const episode = entities[cur];
      if ( episode.type && !acc.includes(episode.type) ){
        acc.push(episode.type)
      }
      return acc;
    }, [])
      .sort()
      .map((type:string) => ({
        value: type,
        label: type
      }));
  }
);

export const selectEpisodeLabelsAsOptions = createSelector(
  [
    selectEpisodesEntities,
    selectWorkspaceEpisodeIds
  ],
  (entities:Record<number, IEpisode>, ids:number[] | null):IOption_V2[] | null => {
    if ( !ids ) return null;
    return ids.reduce((acc:any[], cur:number) => {
      const episode = entities[cur];
      if ( episode.labels && episode.labels.length ){
        for ( let label of episode.labels ){
          if ( !acc.includes(label) ) acc.push(label);
        }
      }
      return acc;
    }, [])
      .sort()
      .map((label:string) => ({
        value: label,
        label
      }));
  }
);






// ToDO
export const selectEpisodesAll = createSelector(
  [
    selectEpisodesIds,
    selectEpisodesEntities
  ],
  (ids:number[] | null, entities:Record<number, IEpisode>) => {
    if ( !ids ) return null;
    return ids
      .map((id:number) => entities[id])
      .sort((a, b) => sortByCreationDate(a, b, 'asc'));
  }
);

export const selectPageEpisodesAll = createSelector(
  [
    selectEpisodesAll,
    (state:any) => state.page.pages,
    (_, props:{ documentId:number | undefined, pageNum:number | undefined, stapleId?:string | undefined, parentEpisodeId?:number | undefined }) => props
  ],
  ( episodes:IEpisode[] | null, pages:IPage[], { documentId, pageNum, stapleId, parentEpisodeId }) => {
    if ( !episodes || !documentId || !pageNum ) return null;

    const sortedEpisodes = (episodes: IEpisode[]) => {
      return typeof parentEpisodeId === 'undefined'
        ? episodes
        : episodes.sort((a, b) => (a.id === parentEpisodeId ? 0 : 1) - (b.id === parentEpisodeId ? 0 : 1));
    };

    if ( typeof stapleId === 'undefined' ) {
      const filteredEpisodes = episodes.filter((episode: IEpisode) => episode.documentId === documentId && episode.pageNum === pageNum);
      return sortedEpisodes(filteredEpisodes);
    };

    const staplePages = pages.filter((page:IPage) => page.staple?.id === stapleId);
    const staplePageIds = staplePages.map((page:IPage) => PageService.toPageId(page.documentId, page.pageNum));
    const filteredEpisodes = episodes.filter((episode: IEpisode) => staplePageIds.includes(PageService.toPageId(episode.documentId, episode.pageNum)));

    return sortedEpisodes(filteredEpisodes);
  }
);

export const selectHasPageAllEpisodesAnyNotes = createSelector(
  (state:any, props:any) => selectPageEpisodesAll(state, props),
  (episodes:Array<IEpisode> | null) => {
    if ( !episodes ) return false;
    return episodes.some((episode:IEpisode) => episode.notes);
  }
);

export const selectPageStapleId = createSelector(
  (state:any) => state.page.pages,
  (_:any, props:{ documentId:number, pageNum:number }) => props,
  (pages:any[] | null, { documentId, pageNum }) => {
    if ( !pages ) return null;
    const foundedPage = pages.find((page:any) => page.documentId === documentId && page.pageNum === pageNum);
    return foundedPage?.staple?.id || null;
  }
);

// export const getPageEpisodes = createSelector(
//   (state:any) => getSortedEpisodes(state),
//   (state:any) => state.page.pages,
//   (_:any, props:{ documentId:number | undefined, pageNum:number | undefined, staple?:any }) => props,
//   (episodes:Array<IEpisode> | null, pages:Array<any>, { documentId, pageNum, staple = null }) => {
//     if ( !pages || !episodes || !documentId || !pageNum ) return null;
//     if ( staple && staple.order === 1 ){
//       const staplePages = pages.filter((page:any) => page.staple && page.staple.id === staple.id);
//       const staplePagesIds = staplePages.map((page:any) => `${page.documentId}:${page.pageNum}`);
//       return episodes.filter((episode:IEpisode) => staplePagesIds.includes(`${episode.documentId}:${episode.pageNum}`))
//     }
//     return episodes.filter((episode:IEpisode) => episode.documentId === documentId && episode.pageNum === pageNum);
//   }
// );

export const selectEpisodeAuthors = createSelector(
  [
    selectEpisodesIds,
    selectEpisodesEntities
  ],
  (ids:number[] | null, entities:any) => {
    if ( !ids || !ids.length ) return [];

    const result:any[] = [];

    for (const id of ids) {
      const episode = entities[id];
      if ( episode.author && episode.author.id && episode.author.name && !result.some(a => a.id === episode.author.id ) ) {
        result.push(episode.author)
      }
    }

    return result.sort((a, b) => sortByName(a, b, 'name'))
      .map((author:any) => ({ id: author.id, name: author.name }));
  }
)

export const selectEpisodesByPageId = createSelector(
  [
    selectEpisodesIds,
    selectEpisodesEntities
  ],
  (ids:number[] | null, entities:any) => {
    if ( !ids ) return {};

    return ids.reduce((acc:any, cur:number) => {
      const episode = entities[cur];
      const pageId = `${episode.documentId}:${episode.pageNum}`;
      if ( acc[pageId] ){
        acc[pageId] = [...acc[pageId], episode];
      } else {
        acc[pageId] = [episode];
      }
      return acc;
    }, {});
  }
);
