import { ChangeEvent, FC, Fragment, useState } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Selectors
import { selectLoading } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  documentId: number;
}

const DocumentDeleteDialog:FC<Props> = ({
  // Props
  open, onClose, documentId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const [ value, setValue ] = useState('');

  const handleClick = async () => {
    try {
      await dispatch(deleteDocument({ documentId, data:{ message: value } })).unwrap();
      onClose();
    } catch(error){}
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const actions = (
    <Fragment>
      <Button
        name={`Cancel document delete dialog`}
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`Submit document delete dialog`}
        disabled={!value}
        onClick={handleClick}
        loading={loading}
        variant="contained"
        color="error"
      >{t('labels.delete')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={`${t('layouts.filesSidebar.document')} ${t('labels.delete')}`}
      actions={actions}
    >
      <Typography>{t('layouts.filesSidebar.typeReasonDelete')}</Typography>
      <Input
        label={t('labels.message')} name="message" id="message" value={value}
        onChange={handleChange}
      />
    </Dialog>
  )
}

export default DocumentDeleteDialog;