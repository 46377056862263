import { FC } from 'react';
// Mui
import { Box } from '@mui/material';

type Props = {
  label: string;
  width: string;
}

const CardPageSeparator:FC<Props> = ({
  // Props
  label, width
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        color: 'rgba(0,0,0,0.75)',
        fontSize: 32,
        fontWeight: 700,
        textTransform: 'capitalize',
        pl: 4
      }}
    >{label}</Box>
  )
}

export default CardPageSeparator;
