import i18n from "i18n";

const Messages = {
  PaymentSuccess: i18n.t('notifications.billingCharges.paymentSuccessful'),
  BulkPaymentSuccess: i18n.t('notifications.billingCharges.bulkPaymentSuccessful')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.PaymentSuccess = i18n.t('notifications.billingCharges.paymentSuccessful');
  Messages.BulkPaymentSuccess = i18n.t('notifications.billingCharges.bulkPaymentSuccessful');
});