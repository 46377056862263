import React from 'react';
import { forceVisible } from 'react-lazyload';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectFilter } from 'app/store/page/page.selectors';
// Components
import TagPicker from 'app/components/TagPicker';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

const Tags:React.FC = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const filter = useSelector(selectFilter);

  const { tags } = filter;

  const handleClickTag = (nextTag:string | null) => {
    const idx = filter.tags.indexOf(nextTag);
    const nextTags = idx !== -1 ? filter.tags.filter((_:any, i:number) => i !== idx) : [...filter.tags, nextTag];
    dispatch(PageActions.setFilter('tags', nextTags));
    forceVisible();
  }

  if ( trialMode ) return null;
  return (
    <TagPicker
      id="Workspace tag filter"
      activeTags={tags}
      noTagVisible={true}
      onClick={handleClickTag}
    />
  )
}

export default Tags;
