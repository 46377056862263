import { Reducer } from 'redux';
// Types
import Statuses from '@root/types/Statuses';
import StapleModes from '@root/types/StapleModes';
import StaplePageActions from '@root/types/StaplePageActions';
import { PageTypes, PageActionTypes } from './page.types';
// Models
import { IPage, IPageWithAction } from '@root/models/Page';
import { IState } from './page.models';
// Service
import { generatePageId } from './page.service';
// Utilities
import { uuidv4 } from 'app/utilities/Utilities';

import PageService from 'app/services/PageService';

export default class PageReducer {
  private static readonly _initialState:IState = {
    pageEntities: {},
    pageIds: null,

    pages: null,
    page: null,
    pageStaples: null,
    filter: {
      tags: [],
      colors: [],
      types: [],
      labels: [],
      authors: [],
      additional: false,
      duplicate: false
    },
    isLoading: false,
    status: Statuses.Initial,
    // Compare
    comparePagesIds: [],
    // Staple
    stapleMode: null,
    stapleId: null,
    staplePagesToUpdate: [],
    disabledPagesIds: [],
    error: null
  };

  public static reducer:Reducer<IState, PageActionTypes> = (
    state = PageReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch pages
      case PageTypes.FetchPages:
        return { ...state, pages: null, pagesEntities: null };
      case PageTypes.FetchPagesSuccess:
        const handleFetchPagesSuccess = () => {
          const pages = action.payload.pages;
          const pageIds = pages.map((page:IPage) => PageService.toPageId(page.documentId, page.pageNum))
          const pageEntities = pages.reduce((acc:any, cur:IPage) => {
            if ( acc[cur.documentId] ){
              acc[cur.documentId][cur.pageNum] = cur;
            } else {
              acc[cur.documentId] = { [cur.pageNum]:cur };
            }
            return acc;
          }, {});
          return { ...state, pageEntities, pageIds, pages }
        }
        return handleFetchPagesSuccess();
      // Fetch page
      case PageTypes.FetchPage:
        return { ...state, page: null };
      case PageTypes.FetchPageSuccess:
        return { ...state, page: action.payload.page };
      case PageTypes.FetchPageFailure:
        return { ...state, error: action.payload };
      // Update page
      case PageTypes.UpdatePage:
      case PageTypes.PatchPage:
      case PageTypes.RotatePage:
        return { ...state, isLoading: true };
      case PageTypes.UpdatePageSuccess:
      case PageTypes.PatchPageSuccess:
      case PageTypes.RotatePageSuccess:

        const handleUpdatePageSuccess = () => {
          const page = action.payload.page;
          return {
            ...state,
            pageEntities: {
              ...state.pageEntities,
              [page.documentId]: {
                ...state.pageEntities[page.documentId],
                [page.pageNum]: page
              }
            },
            pages: state.pages
              ? state.pages.map((p:IPage) => {
                  if ( p.documentId === page.documentId && p.pageNum === page.pageNum ) return page;
                  return p;
                })
              : state.pages
            ,
  
            pageStaples: state.pageStaples
              ? state.pageStaples.map((p:IPage) => {
                if ( p.documentId === page.documentId && p.pageNum === page.pageNum ) return page;
                  return p;
                })
              : state.pageStaples
            ,

            page: state.page && state.page.documentId === page.documentId && state.page.pageNum === page.pageNum
              ? action.payload.page
              : state.page
            ,

            isLoading: false
          };
        }

        return handleUpdatePageSuccess();

      // Delete page
      case PageTypes.DeletePage:
        return { ...state, isLoading: true };
      case PageTypes.DeletePageSuccess:

        const handleDeletePageSuccess = () => {
          const { documentId, pageNum } = action.payload;

          const pageEntity = state.pageEntities[documentId][pageNum];
          const updatePage = { ...pageEntity, deleted: true };

          return {
            ...state,
            pages: state.pages
              ? state.pages.map((p:IPage) => {
                  if ( p.documentId === updatePage.documentId && p.pageNum === updatePage.pageNum ) return updatePage;
                  return p;
                })
              : state.pages
            ,
            pageStaples: state.pageStaples
              ? state.pageStaples.map((p:IPage) => {
                  if ( p.documentId === updatePage.documentId && p.pageNum === updatePage.pageNum ) return updatePage;
                  return p;
                })
              : state.pageStaples
            ,

            page: state.page && state.page.documentId === updatePage.documentId && state.page.pageNum === updatePage.pageNum
              ? updatePage
              : state.page
            ,

            isLoading: false
          };
        }

        return handleDeletePageSuccess();
      // Patch pages
      case PageTypes.PatchPages:
        return { ...state, isLoading: true };
      case PageTypes.PatchPagesSuccess:

        const handleUpdatePagesSuccess = () => {
          const pages = action.payload.pages;
          const entities = pages.reduce((acc:any, cur:IPage) => {
            acc[generatePageId(cur.documentId, cur.pageNum)] = cur;
            return acc;
          }, {});

          const pageEntities = {...state.pageEntities};

          for ( let page of pages ){
            pageEntities[page.documentId][page.pageNum] = page;
          }

          return {
            ...state,
            pageEntities,
            pages: state.pages
              ? state.pages.map((p:IPage) => {
                  const pageId = generatePageId(p.documentId, p.pageNum);
                  if ( entities[pageId] ) return entities[pageId];
                  return p;
                })
              : state.pages
            ,
            pageStaples: state.pageStaples
              ? state.pageStaples.map((p:IPage) => {
                  const pageId = generatePageId(p.documentId, p.pageNum);
                  if ( entities[pageId] ) return entities[pageId];
                  return p;
                })
              : state.pageStaples
            ,
            page: state.page && entities[generatePageId(state.page.documentId, state.page.pageNum)]
              ? entities[generatePageId(state.page.documentId, state.page.pageNum)]
              : state.page
            ,
            isLoading: false
          };
        }

        return handleUpdatePagesSuccess();
      case PageTypes.ExportPagesToPDF:
        return { ...state, isLoading: true };
      case PageTypes.ExportPagesToPDFSuccess:
        return { ...state, isLoading: false };
      // Default
      // Set status
      case PageTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      case PageTypes.SetInitialField:
        return {
          ...state,
          [action.payload.field]: PageReducer._initialState[action.payload.field]
        };
      case PageTypes.SetFilter:
        return {
          ...state,
          filter: {
            ...state.filter,
            [action.payload.field]: action.payload.value
          }
        };
      // Compare
      case PageTypes.AddPageToCompare:
        return {
          ...state,
          comparePagesIds: [
            ...state.comparePagesIds,
            generatePageId(
              action.payload.documentId,
              action.payload.pageNum,
              action.payload.stapleId
            )
          ]
        };
      case PageTypes.RemovePageFromCompare:
        return {
          ...state,
          comparePagesIds: state.comparePagesIds.filter((pageId:string) => {
            return pageId !== generatePageId(
              action.payload.documentId,
              action.payload.pageNum,
              action.payload.stapleId
            );
          })
        };
      // Staple
      case PageTypes.SetStapleModeCreate:
        return setStapleModeCreate(state, action.payload);
      case PageTypes.SetStapleModeEdit:
        return setStapleModeEdit(state, action.payload);
      case PageTypes.CancelStapleMode:
        return {
          ...state,
          stapleMode: PageReducer._initialState['stapleMode'],
          stapleId: PageReducer._initialState['stapleId'],
          staplePagesToUpdate: PageReducer._initialState['staplePagesToUpdate'],
          disabledPagesIds: PageReducer._initialState['disabledPagesIds']
        };
      // Add/remove to/from staple
      case PageTypes.AddPageToStaple:
        return addPageToStaple(state, action.payload);
      case PageTypes.RemovePageFromStaple:
        return removePageFromStaple(state, action.payload);
      case PageTypes.AddAllPagesToStaple:
        return addAllPagesToStaple(state, action.payload);

      case PageTypes.MovePageInCompare:
        const { pageId, isNext } = action.payload;
        const index = state.comparePagesIds.indexOf(pageId);
        const nextIndex = isNext ? index + 1 : index - 1;
        const comparePagesIds = moveTo(state.comparePagesIds, index, nextIndex);
        return { ...state, comparePagesIds: [...comparePagesIds] };

      case PageTypes.DocumentPagesRefetch:
        const pages = [...(state.pages || [])];
        const pageIds = [...(state.pageIds || [])]
        const pageEntities = {...state.pageEntities};

        for ( let page of action.payload.pages ){
          const pageId = generatePageId(page.documentId, page.pageNum);
          if ( !state.pageIds ) break;
          if ( state.pageIds.includes(pageId) ) continue;

          if ( pageEntities[page.documentId] ){
            pageEntities[page.documentId][page.pageNum] = page;
          } else {
            pageEntities[page.documentId] = { [page.pageNum]: page };
          }

          pages.push(page);
          pageIds.push(pageId);
        }

        return {
          ...state,
          pageEntities,
          pageIds,
          pages,
          page: state.page
            ? pageEntities[state.page.documentId] ? pageEntities[state.page.documentId][state.page.pageNum] : null
            : state.page
          ,
        };

      case PageTypes.UpdatePagesWithSocket:
        const handleUpdatePagesWithSocket = () => {
          const pages = action.payload.pages;
          const entities = pages.reduce((acc:any, cur:IPage) => {
            acc[generatePageId(cur.documentId, cur.pageNum)] = cur;
            return acc;
          }, {});
  
          const pageEntities = {...state.pageEntities};
  
          for ( let page of pages ){
            pageEntities[page.documentId][page.pageNum] = page;
          }
  
          return {
            ...state,
            pageEntities,
            pages: state.pages
              ? state.pages.map((p:IPage) => {
                  const pageId = generatePageId(p.documentId, p.pageNum);
                  if ( entities[pageId] ) return entities[pageId];
                  return p;
                })
              : state.pages
          };
        }

        return handleUpdatePagesWithSocket();

      case PageTypes.SetPageStaples:
        return {...state, pageStaples: action.payload.pageStaples }

      default:
        return state;
    }
  }
}

const moveTo = (arr:string[], from:number, to:number) => {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}


const setStapleModeCreate = (state:IState, payload:{ documentId:number }) => {
  const filteredDocumentPages = state.pages
    ? state.pages.filter((page:IPage) => page.documentId === payload.documentId)
    : []
  ;
  return {
    ...state,
    stapleMode: StapleModes.Create,
    stapleId: uuidv4(),
    disabledPagesIds: filteredDocumentPages.filter((page:IPage) => page.staple).map((page:IPage) => `${page.documentId}:${page.pageNum}`)
  }
};

const setStapleModeEdit = (state:IState, payload:{ documentId:number, stapleId:string, isWorkspacePage:boolean }) => {
  const filteredPages = state.pages
    ? payload.isWorkspacePage
      ? state.pages.filter((page:IPage) => page.workspaceOrder)
      : state.pages.filter((page:IPage) => page.documentId === payload.documentId)
    : []
  ;
  return {
    ...state,
    stapleMode: StapleModes.Edit,
    stapleId: payload.stapleId,
    staplePagesToUpdate: filteredPages
      .filter((page:IPage) => page.staple && page.staple.id === payload.stapleId)
      .map((page:IPage) => ({...page, action: null}))
      .sort((a:IPage, b:IPage) => {
        if ( a.staple && b.staple ) return a.staple.order - b.staple.order;
        return 0;
      })
    ,
    disabledPagesIds: filteredPages
      .filter((page:IPage) => payload.isWorkspacePage ? page.documentId !== payload.documentId : true)
      .filter((page:IPage) => payload.isWorkspacePage ? true : page.staple && page.staple.id !== payload.stapleId && page.staple.order > 1)
      .map((page:IPage) => generatePageId(page.documentId, page.pageNum))
  };
};

const addPageToStaple = (state:IState, payload:{ page:IPage }) => {
  const secondaryStapleId = 
    payload.page.staple && payload.page.staple.order === 1 && state.stapleId !== payload.page.staple.id
    ? payload.page.staple.id
    : null
  ;

  let staplePagesToUpdate = state.staplePagesToUpdate;

  if ( secondaryStapleId ){
    const secondaryStaplePages = state.pages
      ?.filter((page:IPage) => page.staple && page.staple.id === secondaryStapleId)
       .sort((a:IPage, b:IPage) => {
         if ( a.staple && b.staple ) return a.staple.order - b.staple.order;
         return 0;
       })
    if ( secondaryStaplePages ){
      staplePagesToUpdate = [...staplePagesToUpdate, ...secondaryStaplePages.map((page:IPage) => ({...page, action: StaplePageActions.Added}))]
    }
  } else {
    if ( state.stapleMode === StapleModes.Create ){
      staplePagesToUpdate = [...staplePagesToUpdate, {...payload.page, action: StaplePageActions.Added}]
    } else {
      const foundedPage:IPageWithAction|undefined = staplePagesToUpdate.find((page:IPageWithAction) =>
        page.documentId === payload.page.documentId && page.pageNum === payload.page.pageNum
      );
      if ( foundedPage && foundedPage.action === StaplePageActions.Removed ){
        staplePagesToUpdate = staplePagesToUpdate.map((page:IPageWithAction) => {
          if ( page.documentId === foundedPage.documentId && page.pageNum === foundedPage.pageNum ){
            return { ...page, action: null };
          }
          return page;
        })
      } else {
        staplePagesToUpdate = [...staplePagesToUpdate, {...payload.page, action: StaplePageActions.Added}]
      }
    }
  }
  return {
    ...state,
    staplePagesToUpdate
  };
};

const removePageFromStaple = (state:IState, payload:{ page:IPage }) => {
  const secondaryStapleId = 
    payload.page.staple && payload.page.staple.order === 1 && state.stapleId !== payload.page.staple.id
    ? payload.page.staple.id
    : null
  ;

  let staplePagesToUpdate = state.staplePagesToUpdate;
  if ( secondaryStapleId ){
    staplePagesToUpdate = staplePagesToUpdate.filter((page:IPage) => {
      return !page.staple || page.staple.id !== secondaryStapleId
    });
  } else {
    if ( state.stapleMode === StapleModes.Create ){
      staplePagesToUpdate = staplePagesToUpdate.filter((page:IPageWithAction) => 
        !(page.documentId === payload.page.documentId && page.pageNum === payload.page.pageNum)
      );
    } else {
      const foundedPage:IPageWithAction|undefined = staplePagesToUpdate.find((page:IPageWithAction) =>
        page.documentId === payload.page.documentId && page.pageNum === payload.page.pageNum
      );
      if ( foundedPage && !foundedPage.action ){
        staplePagesToUpdate = staplePagesToUpdate.map((page:IPageWithAction) => {
          if ( page.documentId === foundedPage.documentId && page.pageNum === foundedPage.pageNum ){
            return { ...page, action: StaplePageActions.Removed };
          }
          return page;
        });
      } else {
        staplePagesToUpdate = staplePagesToUpdate.filter((page:IPageWithAction) => 
          !(page.documentId === payload.page.documentId && page.pageNum === payload.page.pageNum)
        );
      }
    }
  }
  return {
    ...state,
    staplePagesToUpdate
  };
};

const addAllPagesToStaple = (state:IState, payload:{ documentId:number }) => {
  const staplePagesToUpdate:Array<IPageWithAction> = state.pages
    ? state.pages
        .filter((page:IPage) => !page.deleted && page.documentId === payload.documentId)
        .filter((page:IPage) => !state.disabledPagesIds.includes(generatePageId(page.documentId, page.pageNum)))
        .sort((a:IPage, b:IPage) => a.pageNum - b.pageNum)
        .map((page:IPage) => ({...page, action: StaplePageActions.Added}))
    : []
  ;
  return {
    ...state,
    staplePagesToUpdate
  };
};
