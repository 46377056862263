import { KeyboardEvent, FC, useState, useEffect, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPhysicianFeeSchedules } from 'app/store/Medical/Medical.async';
// Actions
import { MedicalActions } from 'app/store/Medical/Medical.slice';
// Selectors
import { selectCarriersAsOptions } from 'app/store/Medical/Medical.selectors';
import { selectPage } from 'app/store/page/page.selectors';
// Mui
import { Box, Autocomplete } from '@mui/material';
// Components
import { Input, Select, Button } from 'app/components/Mui';
// Context
import { useEpisodeFactsContext } from 'app/context/EpisodeFacts.context';

const MAX_CODES:number = 5;

const ScheduleSearch:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const carrierOptions:IOption[] = useAppSelector(selectCarriersAsOptions);
  const page = useAppSelector((state:any) => selectPage(state))

  // Context
  const { values, errors, onChange, onSubmit, yearsOptions } = useEpisodeFactsContext();

  const [ carrierOption, setCarrierOption ] = useState<IOption | null>(null);

  const hcpcsCodes = useMemo(() => {
    if ( !page || !page.annotations ) return [];
    const annotations = page.annotations.filter((annotation:any) => annotation.name === 'hcpcsCode');
    if ( !annotations.length ) return [];
    return annotations.map((annotation:any) => annotation.value);
    // eslint-disable-next-line;
  }, [page]);

  const hasShowMore = hcpcsCodes.length > MAX_CODES;

  const [ showMore, setShowMore ] = useState(!hasShowMore);

  useEffect(() => {
    if ( values.carrierNumber && carrierOptions ){
      const foundedOption:IOption | undefined = carrierOptions.find((option:IOption) => option.id === values.carrierNumber);
      setCarrierOption(foundedOption || null);
    } else {
      setCarrierOption(null);
    }
    // eslint-disable-next-line
  }, [values.carrierNumber, carrierOptions]);

  const handleClick = (code:string) => () => {
    onChange({ name: 'search', value: code });

    setTimeout(() => {
      handleSearch(code);
    }, 500);
  }

  const handleChange = (_:any, nextValue:IOption | null) => {
    const value = nextValue ? nextValue.id.toString() : '';
    onChange({ name: 'carrierNumber', value });
  }

  const handleSearch = (code?:string) => onSubmit((values) => {
    const nextValues = code ? {...values, search: code} : values;

    console.log(nextValues);

    dispatch(getPhysicianFeeSchedules(nextValues));
    dispatch(MedicalActions.setSearchHcpcsCode(nextValues.search));
  });

  const handleKeyUp = (event:KeyboardEvent<HTMLInputElement>) => {
    if ( event.key === 'Enter' ) handleSearch();
  }

  const handleClear = () => {
    onChange({ name: 'search', value: '' });

    dispatch(MedicalActions.setSearchHcpcsCode(''));
  }

  const toggleShowMore = () => setShowMore(!showMore);

  const renderHcpcsCodes = () => {
    if ( !hcpcsCodes.length ) return [];
    return showMore ? hcpcsCodes : hcpcsCodes.slice(0, MAX_CODES);
  }

  return (
    <Fragment>
      {hcpcsCodes.length ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          {renderHcpcsCodes().map((code:string, index:number) => (
            <Button
              name={`hcpcsCodes-item-${code}-${index}`}
              key={`hcpcsCodes-item-${code}-${index}`}
              onClick={handleClick(code)}
              variant="outlined"
              size="small"
            >{code}</Button>
          ))}
          {hasShowMore ? (
            <Button
              name={showMore ? 'Show less' : 'Show more'}
              onClick={toggleShowMore}
              size="small"
            >{showMore ? t('labels.showLess') : t('labels.showMore')}</Button>
          ) : null}
        </Box>
      ) : null}
      <Box sx={{ pt: 1, mb: 4 }}>
        <Input
          sx={{ mb: 4 }}
          label={t('dialogs.episodeFacts.searchLabel')} name="search" value={values.search}
          onChange={onChange}
          required
          error={Boolean(errors.search)}
          helperText={errors.search ? t('validations.fieldRequired') : ''}
          size="small"
          margin="none"
          InputProps={{
            onKeyUp: handleKeyUp,
          }}
          onClear={handleClear}
        />
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <Box sx={{ flexGrow: 1, width: '50%' }}>
            <Autocomplete
              value={carrierOption}
              options={carrierOptions}
              onChange={handleChange}
              renderInput={(params) => (
                <Input
                  {...params}
                  label={t('dialogs.episodeFacts.selectLocality')}
                  required
                  error={Boolean(errors.carrierNumber)}
                  helperText={errors.carrierNumber ? t('validations.fieldRequired') : ''}
                  margin="none"
                  size="small"
                />
              )}
              getOptionLabel={(option) => option.name}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Select
              label={t('labels.year')} value={values.year} name="year"
              options={yearsOptions}
              onChange={onChange}
              required
              error={Boolean(errors.year)}
              helperText={errors.year ? t('validations.fieldRequired') : ''}
              size="small"
              margin="none"
              InputProps={{
                onKeyUp: handleKeyUp
              }}
            />
          </Box>

          <Button
            name="Search schedules"
            sx={{ mt: 1/2 }}
            onClick={() => handleSearch()}
            variant="contained"
          >{t('labels.search')}</Button>
        </Box>
      </Box>
    </Fragment>
  )
}

export default ScheduleSearch;
