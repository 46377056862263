import LogoImageUrl from 'assets/images/logo.png';

import { FC, useEffect, useRef } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectSelectedDate } from 'app/store/currentUser/currentUserSelectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Hooks
import useFormattedDate from 'app/hooks/useFormattedDate';
import useFormattedTime from 'app/hooks/useFormattedTime';

// LogoImageUrl size
// Width: 364
// Height: 56

const padding = 8;
const imageWidth = 364;
const imageHeight = 56;
const fontSize = 24;

const width = (padding * 2) + imageWidth;
const height = (padding * 4) + (fontSize * 2) + imageHeight;

type Props = {
  showEpisodeDate?: boolean;
  onImageDataGenerate: (imageDataUrl:string) => void;
}

const CaseChronologyLogo:FC<Props> = ({
  // Props
  showEpisodeDate = false, onImageDataGenerate
}) => {
  // State
  const selectedDate = useSelector(selectSelectedDate);
  const insuranceCase = useSelector(selectInsuranceCase);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const formattedEventDate = useFormattedDate(insuranceCase?.eventDate);
  const formattedEventTime = useFormattedTime(insuranceCase?.eventTime);
  const formattedEpisodeDate = useFormattedDate(selectedDate?.format('YYYY-MM-DD'));

  useEffect(() => {
    const canvas = canvasRef.current;
    if ( !canvas ) return;

    const context = canvas.getContext('2d');
    const image = new Image();
    image.src = LogoImageUrl;
    image.onload = () => {
      if ( !context ) return;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, padding, padding);

      // Set text properties
      context.font = `${fontSize}px Roboto`;
      context.fillStyle = 'black';

      const text1Y = imageHeight + (padding * 4);
      const text2Y = text1Y + padding + fontSize;
      // Draw text
      context.fillText(`Event: ${formattedEventDate}${formattedEventTime ? `, ${formattedEventTime}` : ''}`, padding, text1Y);
      if ( showEpisodeDate ){
        context.fillText(`Episode date: ${formattedEpisodeDate}`, padding, text2Y);
      }

      const imageDataUrl = canvas.toDataURL('image/png');
      onImageDataGenerate(imageDataUrl);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ display: 'none' }}>
      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  )
}

export default CaseChronologyLogo;
