import React, { useState } from 'react';
import { push } from 'connected-react-router';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Selectors
import { selectStapleMode, selectStapleById } from 'app/store/page/page.selectors';
// Components
import PageImage from '@root/components/PageImage';
// 
import CardImagePreview from './PageCardPreview';
import CardPageBodyStapleArrows from './CardPageBodyStapleArrows';
import CardPageBodyWorkspaceArrows from './CardPageBodyWorkspaceArrows';
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
// Hooks
import { Portal } from '../Portal';

import { AppUiDialogActions } from 'app/store/AppUIDialog/AppUIDialog.slice';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

let timeout = null;

const CardBody = ({
  // Props
  episodeId, page, isFilesPage, isWorkspacePage, isSearchPage
}) => {
  const stapleId = (episodeId || isSearchPage) && page.staple && page.staple.order > 1 ? page.staple.id : null;

  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode)
  const workspaceType = useSelector(selectWorkspaceType);
  const stapleMode = useSelector(selectStapleMode);
  const staplePages = useSelector((state) => selectStapleById(state, { stapleId }))
  // // Todo
  // const { pagesInRow } = useSelector(state => state._AppState);

  const [ open, setOpen ] = useState(false);
  const [ left, setLeft ] = useState(false);

  const handleMouseOver = event => {
    const { clientX } = event;
    const { clientWidth } = document.body;
    if ( clientX <= (clientWidth/2) ) setLeft(true);
    timeout = setTimeout(() => {
      setOpen(true);
    }, 1000);
  }

  const handleMouseOut = () => {
    setOpen(false);
    setLeft(false);
    clearTimeout(timeout);
  }

  const primaryStaplePage = staplePages ? staplePages[0] : null;

  const handleClick = () => {
    if ( stapleMode ) return false;
    if ( episodeId ) dispatch(push({ search: `?episodeId=${episodeId}` }));

    dispatch(AppUiDialogActions.showDialog({
      dialogName: 'PageDetailsDialog',
      dialogProps: {
        documentId: primaryStaplePage ? primaryStaplePage.documentId : page.documentId,
        pageNum: primaryStaplePage ? primaryStaplePage.pageNum : page.pageNum,
        openedPageNum: isSearchPage ? page.pageNum : null
      }
    }));
  }

  return (
    <>
      <div className="card-body">
        {!(trialMode && stapleMode) && workspaceType === 'pages' ? (
          <React.Fragment>
            <CardPageBodyStapleArrows page={page} isFilesPage={isFilesPage} />
            <CardPageBodyWorkspaceArrows page={page} isWorkspacePage={isWorkspacePage} />
          </React.Fragment>
        ) : null}
        <div
          style={{ height: '100%' }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleClick}
        >
          <PageImage
            documentId={page.documentId}
            pageNum={page.pageNum}
            showPageNum={false}
            wrapperStyles={{
              position: 'relative',
              display: 'inline-block'
            }}

            highlights={(isFilesPage || isWorkspacePage) && page.highlights ? page.highlights : undefined}
          />
        </div>
      </div>
      {open ? (
        <Portal>
          <CardImagePreview page={page} isLeft={left} onClose={handleMouseOut} isFilesPage={(isFilesPage || isWorkspacePage)} />
        </Portal>
      ) : null}
    </>
  )
}

export default CardBody;
