import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getCurrentAccountBilling } from 'app/store/Accounts/Accounts.async';
import { getOrders } from 'app/store/BillingOrders/BillingOrders.async';
// Mui
import { Box, Container } from '@mui/material';
// 
import OrdersAlert from './OrdersAlert';
import OrdersList from './OrdersList';
import OrdersFab from './OrdersFab';
import Reducers from 'app/types/Reducers';
import { RootState } from 'app/store';

const OrdersPage:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch:any = useAppDispatch();
  // ToDO
  const items:any = useAppSelector((state:RootState) => {
    const currentAccountBilling = state[Reducers.Accounts].currentAccountBilling;
    if ( !currentAccountBilling || !currentAccountBilling.subscription ) return null;
    return currentAccountBilling.subscription.items.filter((item:any) => item.additional)
  });

  useEffect(() => {
    dispatch(getCurrentAccountBilling({}));
    dispatch(getOrders({ caseId }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
        {items && !items.length ? (
          <OrdersAlert />
        ) : null}
        <OrdersList />
      </Container>
      {items && items.length ? (
        <OrdersFab />
      ) : null}
    </Box>
  )
}

export default OrdersPage;
