import { useState, useLayoutEffect } from 'react';
// Mui
import { Box, Fab } from '@mui/material';
// Icons
import UpIcon from '@mui/icons-material/KeyboardArrowUp';

const CaseDetailsScrollTop = ({
  // Props
  showBelow
}) => {
  const [ show, setShow ] = useState(showBelow ? false : true);

  const handleScroll = () => {
    const container = document.getElementById('scrollContainer');
    if ( !container ) return;
    setShow(_ => container?.scrollTop > showBelow ? true : false)
  }

  useLayoutEffect(() => {
    if ( showBelow ){
      const container = document.getElementById('scrollContainer');
      container?.addEventListener('scroll', handleScroll);
      return () => {
        container?.removeEventListener('scroll', handleScroll);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    const container = document.getElementById('scrollContainer');
    container?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      {show ? (
        <Box sx={{
          position: 'fixed',
          right: 0, bottom: '70px',
          transform: 'translateX(-50%)',
          marginBottom: '16px',
          // marginLeft: `${drawerOpen ? '200px' : 0}`,
          zIndex: 1299
        }}>
          <Fab
            sx={{
              opacity: 0.6,
              transition: 'all 0.3s',
              '&:hover': {
                opacity: 1,
              }
            }}
            onClick={handleClick}
            color="primary"
            size="medium"
          ><UpIcon /></Fab>
        </Box>
      ) : null}
    </>
  )
}

export default CaseDetailsScrollTop;
