import { t } from "i18next";
import { getI18n } from "react-i18next";

const i18n = getI18n();
i18n.setDefaultNamespace('common');

const RecordsPagesMessages = {
  Update: t('notifications.page.updatePage'),
  Delete: t('notifications.page.deletePage'),
  Rotate: t('notifications.page.rotatePage'),

  UpdateColors: t('notifications.page.updatePageColor'),
  RemoveColors: t('notifications.page.removePageColor'),

  UpdateTag: t('notifications.page.updatePageTag'),
  RemoveTag: t('notifications.page.removePageTag'),

  AddToWorkspace: t('notifications.page.addPageToWorkspace'),
  RemoveFromWorkspace: t('notifications.page.removePageFromWorkspace'),

  AddHighlight: i18n.t('notifications.page.addHighlightToPage'),
  DeleteHighlight: i18n.t('notifications.page.deleteHighlightFromPage'),

  RemoveFromStaple: i18n.t('notifications.page.removePageFromStaple'),

  ExportPagesToPDF: i18n.t('notifications.page.exportPagesToPDF'),

  CreateStaple: i18n.t('notifications.page.createStaple'),
  UpdateStaple: i18n.t('notifications.page.updateStaple'),
  RemoveStaple: i18n.t('notifications.page.removeStaple')
}

export default RecordsPagesMessages;

i18n.on('languageChanged', () => {
  RecordsPagesMessages.Update = i18n.t('notifications.page.updatePage');
  RecordsPagesMessages.Delete = i18n.t('notifications.page.deletePage');
  RecordsPagesMessages.Rotate = i18n.t('notifications.page.rotatePage');

  RecordsPagesMessages.UpdateColors = i18n.t('notifications.page.updatePageColor');
  RecordsPagesMessages.RemoveColors = i18n.t('notifications.page.removePageColor');

  RecordsPagesMessages.UpdateTag = i18n.t('notifications.page.updatePageTag');
  RecordsPagesMessages.RemoveTag = i18n.t('notifications.page.removePageTag');

  RecordsPagesMessages.AddToWorkspace = i18n.t('notifications.page.addPageToWorkspace');
  RecordsPagesMessages.RemoveFromWorkspace = i18n.t('notifications.page.removePageFromWorkspace');

  RecordsPagesMessages.AddHighlight = i18n.t('notifications.page.addHighlightToPage');
  RecordsPagesMessages.DeleteHighlight = i18n.t('notifications.page.deleteHighlightFromPage');

  RecordsPagesMessages.RemoveFromStaple = i18n.t('notifications.page.removePageFromStaple');

  RecordsPagesMessages.ExportPagesToPDF = i18n.t('notifications.page.exportPagesToPDF');

  RecordsPagesMessages.CreateStaple = i18n.t('notifications.page.createStaple');
  RecordsPagesMessages.UpdateStaple = i18n.t('notifications.page.updateStaple');
  RecordsPagesMessages.RemoveStaple = i18n.t('notifications.page.removeStaple');
});
