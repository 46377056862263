import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import AdditionalToolsNames from 'app/types/AdditionalToolsNames';
// Models
import { RootState } from 'app/store';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

export const selectColumns = (state:RootState) => state[Reducers.Ui].columns;
export const selectCompare = (state:RootState) => state[Reducers.Ui].compare;

export const selectShowRecordsSidebar = (state:RootState) => state[Reducers.Ui].showRecordsSidebar;

export const selectShowFilesToolbar = (state:RootState) => state[Reducers.Ui].showFilesToolbar;
export const selectShowWorkspaceToolbar = (state:RootState) => state[Reducers.Ui].showWorkspaceToolbar;

export const selectShowFilesAdditionalTools = (state:RootState) => state[Reducers.Ui].showFilesAdditionalTools;
export const selectShowWorkspaceAdditionalTools = (state:RootState) => state[Reducers.Ui].showWorkspaceAdditionalTools;

export const selectAdditionalTools = (state:RootState) => state[Reducers.Ui].additionalTools;

const excludedToolsInTrialMode = [
  AdditionalToolsNames.Tags,
  AdditionalToolsNames.Colors,
  AdditionalToolsNames.EpisodeLabels,
  AdditionalToolsNames.AdditionalRecordsOnly
];

export const selectColumnValue = createSelector(
  [ selectColumns ],
  (columns:string) => columns.split('_')[1]
);

export const selectIsInCompare = createSelector(
  [
    selectCompare,
    (_, props:{ id:string }) => props
  ],
  (compare:string[], { id }) => compare.includes(id)
);

export const selectAdditionalToolsShown = createSelector(
  [
    selectTrialMode,
    selectAdditionalTools,
    (_, props:{ toolsType:'files' | 'workspace' }) => props
  ],
  (trialMode:boolean, additionalTools:any, { toolsType }) => {
    if ( !trialMode ) return additionalTools[toolsType].shown;

    return additionalTools[toolsType].shown.filter((tool:AdditionalToolsNames) => !excludedToolsInTrialMode.includes(tool));
  }
);

export const selectAdditionalToolsHidden = createSelector(
  [
    selectTrialMode,
    selectAdditionalTools,
    (_, props:{ toolsType:'files' | 'workspace' }) => props
  ],
  (trialMode:boolean, additionalTools:any, { toolsType }) => {
    if ( !trialMode ) return additionalTools[toolsType].hidden;

    return additionalTools[toolsType].hidden.filter((tool:AdditionalToolsNames) => !excludedToolsInTrialMode.includes(tool));
  }
);

export const selectToolVisibility = createSelector(
  [
    selectAdditionalToolsShown,
    selectAdditionalToolsHidden,
    (_, props:{ additional:boolean, toolName:AdditionalToolsNames }) => props
  ],
  (toolsShown:AdditionalToolsNames[], toolsHidden:AdditionalToolsNames[], { additional, toolName }) => {
    if ( additional ) return toolsHidden.includes(toolName);
    return toolsShown.includes(toolName);
  }
);

// ToDO
export const selectWorkspaceType = (state:RootState) => state[Reducers.Ui].workspaceType;
