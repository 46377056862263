import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IEpisodeAuthor from "app/models/EpisodeAuthor";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { EpisodeAuthorsActions } from "./EpisodeAuthors.slice";
// Messages
import EpisodeAuthorsMessages from "./EpisodeAuthors.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $patch, $delete } from 'app/utilities/HttpClient';
import { trimValue } from "app/utilities/Utilities";

const _url:string = '/episodeAuthors';

export const checkAuthors = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Check authors`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const getAuthors = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Get authors`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const getAuthor = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Get author`,
  async (authorId:number) => {
    const response:Response = await $get(`${_url}/${authorId}`);
    return response.json();
  }
);

export const getAuthorAnalytics = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Get author analytics`,
  async (authorId:number) => {
    const response:Response = await $get(`${_url}/${authorId}/analytics`);
    return response.json();
  }
);

export const createAuthor = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Create author`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: EpisodeAuthorsMessages.Create
    }));

    return response.json();
  }
);

export const updateAuthor = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Update author`,
  async ({ authorId, data }:{ authorId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${authorId}`, data);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: EpisodeAuthorsMessages.Update
    }));

    return response.json();
  }
);

export const patchAuthor = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Patch author`,
  async ({ authorId, data }:{ authorId:number, data:any }, { dispatch }) => {
    const response:Response = await $patch(`${_url}/${authorId}`, data);

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: EpisodeAuthorsMessages.Update
    }));

    return response.json();
  }
);

export const deleteAuthor = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Delete author`,
  async ({ authorId, params = {} }:{ authorId:number, params?:any }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    let url = `${_url}/${authorId}`;
    if ( Object.keys(params).length ){
      const queryParams = new URLSearchParams();
      Object.keys(params).forEach((key:string) => {
        const value = params[key];
        if ( value ) queryParams.append(key, params[key]);
      });
      url = `${url}?${queryParams}`;
    }
    const response:Response = await $delete(url);
    if ( !response.ok ){
      if ( response.status === 409 ){
        dispatch(EpisodeAuthorsActions.setError({ status: response.status }));
        // Throw an error to evoke reject action without calling `rejectWithoutValue`
        // `rejectWihtoutValue` needed to throw an text error in snackbar
        throw Error(`Delete error with status ${response.status}`);
      }
      return rejectWithValue((await response.json()) as any);
    }

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: EpisodeAuthorsMessages.Delete
    }));

    return fulfillWithValue(authorId);
  }
);

export const deduplicateAuthors = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Deduplicate author`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    const response:Response = await $post(`${_url}/actions/deduplicate`, data);

    const author:IEpisodeAuthor = await response.json();

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: EpisodeAuthorsMessages.Deduplicate
    }));

    return fulfillWithValue({ sourceId: data.sourceId, author });
  }
);

export const getAuthorsTypes = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Get authors types`,
  async (params:any) => {
    const response:Response = await $get(`${_url}/episodeTypes`, params);
    return response.json();
  }
);

export const createAuthorType = asyncThunkHandler(
  `${Reducers.EpisodeAuthors}/Create episode type`,
  async ({ authorId, data }:{ authorId:number, data:any }, { fulfillWithValue }) => {
    await $post(`${_url}/${authorId}/episodeTypes`, data);
    return fulfillWithValue({ id: authorId, type: data.type })
  }
);

export const validateAuthorAndType = createAsyncThunk(
  `${Reducers.EpisodeAuthors}/Validate author and type`,
  async ({ authorName, authorType }:{ authorName:string, authorType:string | null }, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const trimedAuthorName = authorName ? trimValue(authorName) : '';
    const trimedAuthorType = authorType ? trimValue(authorType) : '';

    const author = { id: -1, name: trimedAuthorName, verified: false };

    if ( !trimedAuthorName ) return author;

    const resAuthors:Response = await $get(_url, { namePrefix: trimedAuthorName });
    if ( !resAuthors.ok ) return rejectWithValue((await resAuthors.json()) as any);
    const authors = await resAuthors.json() as IEpisodeAuthor[];

    const foundedAuthor = authors.find((a:IEpisodeAuthor) => a.name.toLowerCase() === trimedAuthorName.toLowerCase());

    if ( foundedAuthor ){
      const resAuthor:Response = await $get(`${_url}/${foundedAuthor.id}`);
      if ( !resAuthor.ok ) return rejectWithValue((await resAuthor.json()) as any);
      const episodeAuthor = await resAuthor.json() as IEpisodeAuthor;
      if (
        !episodeAuthor.episodeTypes ||
        (trimedAuthorType && !episodeAuthor.episodeTypes.includes(trimedAuthorType))
      ){
        dispatch(createAuthorType({ authorId: foundedAuthor.id, data: { type: trimedAuthorType } }));
      }
      author['id'] = episodeAuthor.id;
      author['name'] = episodeAuthor.name;
      author['verified'] = episodeAuthor.verified;
    } else {
      const data:any = { name: trimedAuthorName };
      if ( trimedAuthorType ) data['episodeType'] = trimedAuthorType;
      const episodeAuthor = await dispatch(createAuthor(data)).unwrap() as IEpisodeAuthor;

      author['id'] = episodeAuthor.id;
      author['name'] = episodeAuthor.name;
      author['verified'] = episodeAuthor.verified;
    }

    return fulfillWithValue(author);
  }
);