import React from 'react';
// Components
import Icon from 'app/components/Icon';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {};

const PageCardDeleted:React.FC<Props> = () => {
  const { t } = useTranslation('common');
  
  return (
    <div className="card-page deleted">
      <Icon
        sx={{
          width: 104,
          height: 104,
          color: 'rgba(0,0,0,0.32)',
          fontSize: 104
        }}
        icon="delete"
      />
      <span style={{
        display: 'block',
        color: 'rgba(0,0,0,0.32)',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'center'
      }}>{t('labels.pageDeleted')}</span>
    </div>
  )
}

export default PageCardDeleted;
