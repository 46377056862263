import { FC, Fragment } from 'react';
// Mui
import { Chip, Box, Theme } from '@mui/material';
// Materials
import useToggle from 'app/hooks/useToggle';
import CardPageBodyPreview from 'app/components/CardPage/CardPageBodyPreview';
import { useParams } from 'react-router-dom';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

type Props = {
  documentId: number;
  pageNum: number;
  highlighted?: boolean;
  conversationId?: string;
  messageId?: string;
}

const MetadataDocumentPageNum:FC<Props> = ({
  // Props
  documentId, pageNum, highlighted = false,
  conversationId, messageId
}) => {
  const { caseId } = useParams<{ caseId:string }>();
  
  const { open, toggle } = useToggle();

  const onOpen = () => {
    toggle();
    const mixpanelData:any = {
      caseId,
      conversationId: conversationId,
      docId: documentId,
      pageNum
    };
    if (messageId) mixpanelData['messageId'] = messageId;
    Mixpanel.track(MixpanelTracks.AIChatConversationResponsePagePreview, mixpanelData);
  }

  return (
    <Fragment>
      <Chip
        sx={{
          border: (theme:Theme) => highlighted ? `2px solid ${theme.palette.primary.main}` : 'initial',
        }}
        label={`Page ${pageNum}`}
        onClick={onOpen}
        size="small"
      />
      {open ? (
        <CardPageBodyPreview
          documentId={documentId}
          pageNum={pageNum}
          isLeft={false}
          onClose={toggle}
          backdrop={
            <Box
              sx={{
                position: 'fixed',
                top: 0, left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1
              }}
              onClick={toggle}
            />
          }
        />
      ) : null}
    </Fragment>
  )
}

export default MetadataDocumentPageNum;
