import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { rejectInsuranceCase } from 'app/store/Cases/Cases.async';
// Selectors
import { selectLoading } from 'app/store/Cases/Cases.selectors';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// Utility
import { isRequired } from 'app/utilities/Validations';
// i18next
import { Trans, useTranslation } from 'react-i18next';

interface IFormData {
  comment: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCaseId: number;
}

const InsuranceCaseRejectDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCaseId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors } } = useForm<IFormData>({
    defaultValues: {
      comment: ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      await dispatch(rejectInsuranceCase({ insuranceCaseId, data })).unwrap();
    } catch(error){}
  });

  const actions = (
    <Fragment>
      <Button
        name="Cancel reject case dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Submit reject case dialog"
        loading={loading}
        type="submit"
        variant="contained"
        color="error"
      >{t('labels.reject')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.staffPages.casesPage.rejectCase')}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      {/* Comment */}
      <Controller
        control={control} name="comment"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            multiline
            label={t('labels.comment')}
            required
            rows={8}
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message || ''}
          />
        )}
      />
    </Dialog>
  )
}

export default InsuranceCaseRejectDialog;