import { FC, Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';
// Hooks
import { useAppSelector } from 'app/hooks/useStore';
import useToggle from 'app/hooks/useToggle';
// Models
import IInsuranceCase from 'app/models/Case';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Box, Typography, Tooltip, Theme } from '@mui/material';
// Icons
import {
  ContentCopy as ContentCopyIcon,
  Check as CheckIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon
} from '@mui/icons-material';
// Components
import DocumentName from 'app/components/DocumentName';
import { Bull } from 'app/components/Utilities';
import { IconButton } from 'app/components/Mui';
// Dialogs
import ExportPagesToPDFDialog from '../ExportPagesToPDF.dialog';
// i18next
import { useTranslation } from 'react-i18next';
import UserRoles from 'app/types/UserRoles';
// hook
import useCopyToClipboard from 'app/hooks/useCopyToClipboard';

type Props = {
  documentId: number;
  pageNum: number;
};

const DocumentPageInfo:FC<Props> = ({
  // Props
  documentId, pageNum
}) => {
  const { t } = useTranslation('common');

  // State
  const trialMode = useAppSelector(selectTrialMode);
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const myUser = useAppSelector(selectMyUser);

  const toDocument = `/admin/cases/${insuranceCase?.id}/documents/${documentId}`;
  const toPage = `${toDocument}/${pageNum}`;
  const text = `${window.location.origin}${toPage}`;

  const { open, toggle } = useToggle();

  const [ asyncCopyTextToClipboardFn, copied ] = useCopyToClipboard();

  const handleClick = useCallback(async () => {
    try {
      await asyncCopyTextToClipboardFn(text);
    } catch(error){
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);


  return (
    <Fragment>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'rgba(0,0,0,0.6)',
        fontWeight: 400
      }}>
        {trialMode ? (
          <Box sx={{ overflow: 'hidden' }}>
            <DocumentName documentId={documentId} position="relative" />
          </Box>
        ) : (
          <Box
            sx={{
              textDecoration: 'none',
              color: 'rgba(0,0,0,0.6)',
              overflow: 'hidden',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            component={Link}
            to={toDocument} target="_blank"
          ><DocumentName documentId={documentId} position="relative" /></Box>
        )}
        <Bull />
        {trialMode ? (
          <Typography sx={{ whiteSpace: 'nowrap' }} variant="subtitle1">{t('labels.page')} {pageNum}</Typography>
        ) : (
          <Box
            sx={{
              whiteSpace: 'nowrap',
              textDecoration: 'none',
              color: 'rgba(0,0,0,0.6)',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            component={Link}
            to={toPage} target="_blank"
          ><Typography variant="subtitle1">{t('labels.page')} {pageNum}</Typography></Box>
        )}
        {!trialMode ? (
          <Tooltip
            sx={{
              ml: 2,
              '&:hover': {
                cursor: 'pointer',
                color: (theme:Theme) => theme.palette.primary.main
              }
            }}
            title={copied ? t('labels.copied') : t('dialogs.cardPage.copyLink')}
            aria-label={undefined}
          >
            <IconButton
              name="Copy page link"
              aria-label="Copy page link button"
              onClick={handleClick}
              size="small"
            >
              {copied ? (
                <CheckIcon color="success" fontSize="small" />
              ) : (
                <ContentCopyIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        ) : null}
        {!trialMode && myUser && (myUser.role === UserRoles.Admin || myUser.role === UserRoles.Staff) ? (
          <Tooltip
            sx={{
              ml: 1,
              '&:hover': {
                cursor: 'pointer',
                color: (theme:Theme) => theme.palette.primary.main
              }
            }}
            title="Export page(s) to PDF"
          >
            <IconButton
              name="Export page(s) to PDF"
              aria-label="Export page(s) to PDF button"
              onClick={toggle}
              size="small"
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>

      {open ? (
        <ExportPagesToPDFDialog open={open} onClose={toggle} />
      ) : null}
    </Fragment>
  )
}

export default DocumentPageInfo;
