import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
import AccountStatuses from "app/types/AccountStatuses";
// Models
import IManagementAccount from "app/models/ManagementAccount";
// Async
import {
  getAccounts, getAccount, createAccount, updateAccount, deleteAccount,
  suspendAccount, disableAccount, activateAccount,
  getAccountBilling, updateAccountBillingSubscription
} from './AccountsManagement.async';

interface IState {
  accounts: IManagementAccount[] | null;
  account: IManagementAccount | null;
  accountBilling: any;
  filter: {
    search: string;
    statuses: AccountStatuses[];
  },
  loading: boolean;
};

const initialState:IState = {
  accounts: null,
  account: null,
  accountBilling: null,
  filter: {
    search: '',
    statuses: [AccountStatuses.Active]
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.AccountsManagement,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:keyof IState['filter'], value:string | AccountStatuses[] }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder) {
    // Get accounts
    builder.addCase(getAccounts.pending, (state) => {
      state.accounts = null
    });
    builder.addCase(getAccounts.fulfilled, (state, action:PayloadAction<IManagementAccount[]>) => {
      state.accounts = action.payload;
    });
    // Get account details
    builder.addCase(getAccount.pending, (state) => {
      state.account = null
    });
    builder.addCase(getAccount.fulfilled, (state, action:PayloadAction<IManagementAccount>) => {
      state.account = action.payload;
    });
    // Create account
    builder.addCase(createAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAccount.fulfilled, (state, action:PayloadAction<IManagementAccount>) => {
      if ( state.accounts ) state.accounts.push(action.payload);
    });
    // Update account
    builder.addCase(updateAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAccount.fulfilled, (state, action:PayloadAction<IManagementAccount>) => {
      if ( state.accounts ){
        state.accounts = state.accounts.map((account:IManagementAccount) => {
          if ( account.id === action.payload.id ) return action.payload;
          return account;
        })
      }
    });
    // Delete account
    builder.addCase(deleteAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAccount.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.accounts ){
        state.accounts = state.accounts.filter((account:IManagementAccount) => {
          return account.id !== action.payload
        })
      }
    });
    // Suspend account
    builder.addCase(suspendAccount.fulfilled, (state, action:PayloadAction<IManagementAccount>) => {
      if ( state.accounts ){
        state.accounts = state.accounts.map((account:IManagementAccount) => {
          if ( account.id === action.payload.id ) return action.payload;
          return account;
        })
      }
    });
    // Disable account
    builder.addCase(disableAccount.fulfilled, (state, action:PayloadAction<IManagementAccount>) => {
      if ( state.accounts ){
        state.accounts = state.accounts.map((account:IManagementAccount) => {
          if ( account.id === action.payload.id ) return action.payload;
          return account;
        })
      }
    });
    // Activate account
    builder.addCase(activateAccount.fulfilled, (state, action:PayloadAction<IManagementAccount>) => {
      if ( state.accounts ){
        state.accounts = state.accounts.map((account:IManagementAccount) => {
          if ( account.id === action.payload.id ) return action.payload;
          return account;
        })
      }
    });
    // Get account billing details
    builder.addCase(getAccountBilling.pending, (state) => {
      state.accountBilling = null;
    });
    builder.addCase(getAccountBilling.fulfilled, (state, action:PayloadAction<any>) => {
      state.accountBilling = action.payload;
    });
    // Get account billing details
    builder.addCase(updateAccountBillingSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAccountBillingSubscription.fulfilled, (state, action:PayloadAction<any>) => {

    });

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const AccountsManagementActions = slice.actions;

export default slice.reducer;
