import { FC } from 'react';
// Mui
import { Box, Theme } from '@mui/material';
// 
import ConversationsCreate from './ConversationsCreate';
import ConversationsList from './ConversationsList';

const ConversationsSidebar:FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        width: 400,
        bgcolor: 'white',
        borderRight: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        overflow: 'hidden'
      }}
    >
      <ConversationsCreate />
      <ConversationsList />
    </Box>
  )
}

export default ConversationsSidebar;
