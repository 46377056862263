// Middlewares
import RequestedUrlMiddleware from "./RequestedUrl.middleware";
import RejectHandlerMiddleware from "./RejectHandler.middleware";
import SocketMiddleware from "./Socket.middleware";
import AbortMiddleware from "./Abort.middleware";
import PageFilterMiddleware from "./PageFilter.middleware";
import AuthMiddleware from "./Auth.middleware";
import UsersMiddleware from "./Users.middleware";
import AccountsMiddleware from "./Accounts.middleware";
import CasesMiddleware from "./Cases.middleware";
import DMSBatchesMiddleware from "./DMSBatches.middleware";
import CaseReportsMiddleware from "./CaseReports.middleware";
import EpisodesMiddleware from "./Episodes.middleware";
import EpisodeFactsMiddleware from "./EpisodeFacts.middleware";
import MedicalMiddleware from "./Medical.middleware";
import ReportSectionsMiddleware from "./ReportSections.middleware";
import ReportSectionTemplatesMiddleware from "./ReportSectionTemplates.middleware";
import AIChatModdleware from "./AIChat.moddleware";
import MessagesMiddleware from "./Messages.middleware";
import EpisodeAuthorsMiddleware from "./EpisodeAuthors.middleware";
import DMSDocumentsExportsMiddleware from "./DMSDocumentsExports.middleware";
import CaseSharesMiddleware from './CaseShares.middlewares';
import CaseAssignmentsMiddleware from "./CaseAssignments.middleware";
import PromptsMiddleware from "./Prompts.middleware";

const AppMiddlewares = [
  RequestedUrlMiddleware,
  RejectHandlerMiddleware,
  SocketMiddleware,
  AbortMiddleware,
  PageFilterMiddleware,
  AuthMiddleware,
  UsersMiddleware,
  AccountsMiddleware,
  CasesMiddleware,
  DMSBatchesMiddleware,
  CaseReportsMiddleware,
  EpisodesMiddleware,
  EpisodeFactsMiddleware,
  MedicalMiddleware,
  ReportSectionsMiddleware,
  ReportSectionTemplatesMiddleware,
  AIChatModdleware,
  MessagesMiddleware,
  EpisodeAuthorsMiddleware,
  DMSDocumentsExportsMiddleware,
  CaseSharesMiddleware,
  CaseAssignmentsMiddleware,
  PromptsMiddleware
];

export default AppMiddlewares;