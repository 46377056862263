import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getConversations } from 'app/store/Conversations/Conversations.async';
// Mui
import { Box } from '@mui/material';
// 
import ConversationsSidebar from './ConversationsSidebar';
import ConversationsRouting from './Conversations.routing';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';


const ConversationsPage:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    const insuranceCaseId = Number(caseId);

    Mixpanel.track(MixpanelTracks.CaseMessagesView, { id: insuranceCaseId });

    dispatch(getConversations({ 'context.insuranceCaseId': insuranceCaseId }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
      <ConversationsSidebar />
      <ConversationsRouting />
    </Box>
  )
}

export default ConversationsPage;
