import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
// Mui
import { Paper, Box, Typography } from '@mui/material';
// Services
import CalendarService, { IDay } from 'app/services/CalendarService';
// 
import CalendarDay from './CalendarDay';

type Props = {
  visibleMonths: number;
  currentMonth?: number;
  currentYearAndMonth: number[];
}

const CalendarMonth:FC<Props> = ({
  // Props
  visibleMonths, currentMonth = 0, currentYearAndMonth
}) => {
  const [ year, month ] = currentYearAndMonth;

  const weekdays = CalendarService.generateWeekdays();

  const nextYear = useMemo(() => {
    if ( !currentMonth ) return year;
    return month + currentMonth >= 13 ? year + 1 : year;
    // eslint-disable-next-line
  }, [currentMonth, year]);

  const nextMonth = useMemo(() => {
    if ( !currentMonth ) return month;
    return month + currentMonth >= 13 ? month + currentMonth - 12 : month + currentMonth;
    // eslint-disable-next-line
  }, [currentMonth, month]);

  const currentMonthDays = useMemo(() => {
    return CalendarService.getCurrentMonthDays(nextYear, nextMonth);
    // eslint-disable-next-line
  }, [nextYear, nextMonth]);

  const previousMonthDays = useMemo(() => {
    return CalendarService.getPreviousMonthDays(nextYear, nextMonth);
    // eslint-disable-next-line
  }, [currentMonthDays]);

  const nextMonthDays = useMemo(() => {
    return CalendarService.getNextMonthDays(nextYear, nextMonth);
    // eslint-disable-next-line
  }, [currentMonthDays]);

  const calendarDays = [
    ...previousMonthDays,
    ...currentMonthDays,
    ...nextMonthDays
  ];

  const daysLeftNumber = 42 - calendarDays.length;
  const daysLeft = Array.from(Array(daysLeftNumber).keys()).map((_, i) => ({ dateString: `${i}`, day: 1, isCurrentMonth: false }))

  const days = daysLeft.length && visibleMonths > 1 ? [...calendarDays, ...daysLeft] : calendarDays

  const monthText = dayjs().month(nextMonth - 1).format('MMMM');

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '& + &': {
        marginLeft: '8px'
      }
    }}>
      <Box>
        <Typography sx={{
          display: 'block',
          fontSize: '12px',
          fontWeight: 500,
          paddingTop: '4px',
          paddingBottom: '4px',
          textAlign: 'center'
        }}>{monthText}&nbsp;{nextYear}</Typography>
      </Box>
      <Paper sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }} variant="outlined">
        <Box display="flex">
          {weekdays.map((day:string) => (
            <Box
              sx={{
                width: '14.28%',
                color: 'rgba(0,0,0,0.87)',
                fontSize: '12px',
                fontWeight: 500,
                padding: '4px 8px',
                lineHeight: '22px',
                letterSpacing: '-0.4px',
                textAlign: 'center',
                '& + &': {
                  borderLeft: '1px solid #DCDCDC'
                }
              }}
              key={`weekdays-item-${day}`}
            >{day}</Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
          {days.map((day:IDay, index:number) => (
            <CalendarDay
              key={`calendar-day-item-${day.dateString}-${index}`}
              day={day}
            />
          ))}
        </Box>
      </Paper>
    </Box>
  )
}

export default CalendarMonth;
