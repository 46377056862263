import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getEpisodes } from 'app/store/Episodes/Episodes.async';
import { getAuthorAnalytics } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Actions
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
// Selectors
import { selectParams } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Container } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
// 
import EpisodesFilter from './EpisodesFilter';
import EpisodesList from './EpisodesList';

type Props = {
  open: boolean;
  onClose: () => void;
  author: IEpisodeAuthor;
}

const AuthorLinkedEpisodesDialog:FC<Props> = ({
  // Props
  open, onClose, author
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const params = useAppSelector(selectParams);

  useEffect(() => {
    dispatch(getAuthorAnalytics(author.id));

    return () => {
      dispatch(EpisodesActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getEpisodes({...params, authorId: author.id }));
    // eslint-disable-next-line
  }, [params])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('pages.adminPages.authorRegistryPage.authorLinkedEpisodesDialogTitle', { authorName: author.name })}
      fullScreen
    >
      <Container maxWidth="md">
        <EpisodesFilter author={author} />
        <EpisodesList />
      </Container>
    </Dialog>
  )
}

export default AuthorLinkedEpisodesDialog;
