import config from '../../../../config';

import { FC, Fragment } from 'react';
// Models
import { RootState } from 'app/store';
import IBillingInvoice from 'app/models/BillingInvoice';
import IManagementAccount from 'app/models/ManagementAccount';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAccountById } from 'app/store/AccountsManagement/AccountsManagement.selectors';
// Mui
import {
  Button,
  Box, Paper, Typography, Tooltip
} from '@mui/material';
// Icons
import{ Receipt as ReceiptIcon } from '@mui/icons-material';
// Components
import Icon from 'app/components/Icon';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useLongDateTme from 'app/hooks/useLongDateTime';
// 
import InvoicesListItemLines from './InvoicesListItemLines';

import InvoiceDiscountFormDialog from './InvoiceDiscountFormDialog';
import InvoicePayFormDialog from './InvoicePayFormDialog';
import InvoicesListItemTransactions from './InvoicesListItemTransactions';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  invoice: IBillingInvoice;
};

const InvoicesListItem:FC<Props> = ({
  // Props
  invoice
}) => {
  const { t } = useTranslation('common');
  // State
  const account:IManagementAccount | undefined = useAppSelector((state:RootState) => selectAccountById(state, {
    accountId: invoice.accountId
  }));

  const longDueDate = useLongDateTme({
    dateValue: invoice.dueDate,
    local: true
  });

  const { open:openDiscountDialog, toggle:toggleDiscountDialog } = useToggle();
  const { open:openPayDialog, toggle:togglePayDialog } = useToggle();

  const statusLabels:Record<string, string> = {
    sent: t('labels.outstanding'),
    paid: t('labels.paid')
  }

  return (
    <Fragment>
      <Paper
        sx={{ p: 4, '& + &': { mt: 4 } }}
        variant="outlined"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          {invoice.description ? (
            <Typography variant="subtitle1">{invoice.description}</Typography>
          ) : null}
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="body2"
            sx={{ textTransform: 'capitalize' }}
          >{statusLabels[invoice.status]}</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 4 }}>
          {longDueDate ? (
            <Tooltip title={t('labels.dueDate')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon icon="event" />
                <Typography variant="subtitle2">{longDueDate}</Typography>
              </Box>
            </Tooltip>
          ) : null}
          {account ? (
            <Tooltip title={t('labels.clientName')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon icon="team" />
                <Typography variant="subtitle2">{account.company}</Typography>
              </Box>
            </Tooltip>
          ) : null}
          {invoice.insuranceCase && invoice.insuranceCase.claimNumber ? (
            <Tooltip title={t('labels.claimNumber')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon icon="case_number" />
                <Typography variant="subtitle2">{invoice.insuranceCase.claimNumber}</Typography>
              </Box>
            </Tooltip>
          ) : null}
          {invoice.invoiceNumber ? (
            <Tooltip title={t('labels.invoiceNumber')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <ReceiptIcon />
                <Typography variant="subtitle2">{invoice.invoiceNumber}</Typography>
              </Box>
            </Tooltip>
          ) : null}
        </Box>
        <InvoicesListItemTransactions invoiceId={invoice.id} transactions={invoice.transactions} />
        <InvoicesListItemLines
          invoiceId={invoice.id}
          invoiceDiscountAmount={invoice.discountAmount}
          lines={invoice.lines}
        />
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 4,
          pt: 4
        }}>
          {invoice.quickBooksId ? (
            <Button
              href={`${config.quickBooksUrl}/invoice?txnId=${invoice.quickBooksId}`}
              target="_blank"
              rel="noreferrer"
              component="a"
              color="primary"
              size="small"
            >{t('labels.openInQuickBooks')}</Button>
          ) : null}
          {invoice.status === 'sent' ? (
            <Fragment>
              <Button
                onClick={toggleDiscountDialog}
                color="primary"
                size="small"
              >{t('pages.accountManagerPages.billingInvoicesPage.setDiscount')}</Button>
              <Button
                onClick={togglePayDialog}
                variant="contained"
                color="primary"
                size="small"
              >{t('pages.accountManagerPages.billingInvoicesPage.markPaid')}</Button>
            </Fragment>
          ) : null}
        </Box>
      </Paper>
      {/* Discount dialog */}
      {openDiscountDialog ? (
        <InvoiceDiscountFormDialog
          open={openDiscountDialog}
          onClose={toggleDiscountDialog}
          invoiceId={invoice.id}
          discountAmount={invoice.discountAmount || 0}
          amount={invoice.amount}
        />
      ) : null}
      {/* Pay dialog */}
      {openPayDialog ? (
        <InvoicePayFormDialog
          open={openPayDialog}
          onClose={togglePayDialog}
          invoiceId={invoice.id}
        />
      ) : null}
    </Fragment>
  )
}

export default InvoicesListItem;
