import { FC, useEffect } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getUsers } from 'app/store/Users/Users.async';
import { getAssignments } from 'app/store/CaseAssignments/CaseAssignments.async';
// Selectors
import { selectUsersForSharing } from 'app/store/Users/Users.selectors';
// Mui
import { Button } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
// 
import UsersList from './UsersList';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCaseId: number;
  clientAccountId: number;
}

const CaseShareAccessDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCaseId, clientAccountId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const presenters:any = useAppSelector((state:RootState) => selectUsersForSharing(state, {
    role: UserRoles.Presenter,
    clientAccountId
  }));
  const clients:any = useAppSelector((state:RootState) => selectUsersForSharing(state, {
    role: UserRoles.Client,
    clientAccountId
  }));

  useEffect(() => {
    dispatch(getUsers({ role: `${UserRoles.Presenter},${UserRoles.Client}` }));
    dispatch(getAssignments({ insuranceCaseId }));

    Mixpanel.track(MixpanelTracks.CaseShareAccessView, { caseId: Number(insuranceCaseId) });
    // eslint-disable-next-line
  }, []);

  const actions = (
    <Button
      onClick={onClose}
    >{t('labels.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('labels.share')}
      actions={actions}
    >
      <UsersList
        users={presenters}
        title={t('dialogs.caseShareAccess.presenters')}
        insuranceCaseId={insuranceCaseId}
        noText={t('dialogs.caseShareAccess.noPresenterUsers')}
      />
      <UsersList
        users={clients}
        title={t('dialogs.caseShareAccess.clients')}
        insuranceCaseId={insuranceCaseId}
        noText={t('dialogs.caseShareAccess.noClientUsers')}
      />
    </Dialog>
  )
}

export default CaseShareAccessDialog;
