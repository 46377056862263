import { useMemo } from 'react';
import dayjs from 'dayjs';
// Models
import { ICurrentAccount } from 'app/models/Account';
// Redux
import { useAppSelector } from './useStore';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';

const useFormattedDate = (value:string | undefined) => {
  const initialDateFormat = 'MM/DD/YYYY';
  // State
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);

  const formatedDate = useMemo(() => {
    if ( !value ) return null;
    const date = dayjs(value);
    if ( !currentAccount || !currentAccount.settings?.dateFormat ) return date.format(initialDateFormat);
    return date.format(currentAccount.settings?.dateFormat.toUpperCase());
    // eslint-disable-next-line
  }, [value]);

  return formatedDate;
}

export default useFormattedDate;
