import { FC, Fragment, useState, ChangeEvent, KeyboardEvent } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// Mui
import {
  Box, Button,
  Paper, List
} from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
import { Message, OutlineBlock } from 'app/components/Utilities';
import EpisodeTypesItem from './EpisodeTypesItem';
// i18next
import { useTranslation } from 'react-i18next';
import { trimValue } from 'app/utilities/Utilities';

type Props = {
  episodeTypes: string[];
  onSetEpisodeTypes: (episodeTypes:string[]) => void;
}

const EpisodeTypes:FC<Props> = ({
  // Props
  episodeTypes, onSetEpisodeTypes
}) => {
  const { t } = useTranslation('common');

  const [ value, setValue ] = useState('');

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const handleAdd = () => {
    if ( !value ) return;

    onSetEpisodeTypes([...episodeTypes, trimValue(value)]);
    setValue('');
  }

  const handleUpdate = (index:number, value:string) => {
    onSetEpisodeTypes(episodeTypes.map((type:string, i:number) => i === index ? value : type));
  }
  const handleDelete = (index:number) => {
    onSetEpisodeTypes(episodeTypes.filter((_:any, i:number) => i !== index));
  }

  const onKeyDown = (event:KeyboardEvent<HTMLInputElement>) => {
    if ( event.key === 'Enter' ){
      event.preventDefault();
      handleAdd();
    }
  }

  const onDragEnd = (result:any) => {
    if ( !result.destination || !result.source ) return;
    onSetEpisodeTypes(reorder(episodeTypes, result.source.index, result.destination.index));
  }

  return (
    <OutlineBlock sx={{ mt: 6, pt: 6 }} label={t('labels.episodeTypes')}>
      <Fragment>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Box flexGrow={1}>
            <Input
              label={t('labels.episodeType')} value={value}
              onChange={handleChange}
              margin="none"
              onKeyDown={onKeyDown}
            />
          </Box>
          <Box sx={{ pt: '10px' }}>
            <Button
              disabled={!value}
              onClick={handleAdd}
              variant="contained"
              color="primary"
            >{t('labels.add')}</Button>
          </Box>
        </Box>
        {episodeTypes.length ? (
          <Paper sx={{ mt: 4 }} variant="outlined">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided:any, snapshot:any) => (
                  <List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {episodeTypes.map((episodeType:string, index:number) => (
                      <EpisodeTypesItem
                        key={`Episode type item ${episodeType} ${index}`}
                        index={index}
                        episodeType={episodeType}
                        isDragging={snapshot.isDraggingOver}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                      />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Paper>
        ) : <Message text={t('pages.adminPages.authorRegistryPage.noEpisodeTypes')} />}
      </Fragment>
    </OutlineBlock>
  )
}

export default EpisodeTypes;

const reorder = (list:Array<string>, startIndex:number, endIndex:number) => {
  const result = Array.from(list);
  const [ removed ] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
