import React from 'react';
// Models
import { IPage, IPageDuplicate } from '@root/models/Page';
// MaterialUI
// Cores
import Grid from '@mui/material/Grid';
// 
import PageCompareDuplicatesItem from './PageCompareDuplicatesItem';

type Props = {
  page: IPage;
  pageDuplicate: IPageDuplicate;
};

const PageCompareDuplicates:React.FC<Props> = ({
  // Props
  page, pageDuplicate
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PageCompareDuplicatesItem
          documentId={page.documentId}
          pageNum={page.pageNum}
          isOriginal={true}
        />
      </Grid>
      <Grid item xs={6}>
        <PageCompareDuplicatesItem
          documentId={pageDuplicate.documentId}
          pageNum={pageDuplicate.pageNum}
        />
      </Grid>
    </Grid>
  )
}

export default PageCompareDuplicates;
