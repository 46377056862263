import { FC, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// Types
import GrantTypes from 'app/types/GrantTypes';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { resetPassword } from 'app/store/Users/Users.async';
// Actions
import { AuthActions } from 'app/store/Auth/Auth.slice';
// Selectors
import { selectMultiAccountsData } from 'app/store/Auth/Auth.selectors';
import { selectLoading } from 'app/store/Users/Users.selectors';
// Mui
import { Box, Container, Paper, Typography } from '@mui/material';
// Components
import Brand from 'app/components/Brand';
import LanguagePicker from 'app/components/LanguagePicker';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// Dialogs
import MultiAccountsDialog from 'app/dialogs/MultiAccountsDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { isRequired, isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  grantType: GrantTypes;
  email: string;
}

const ForgotPasswordPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const multiAccountsData = useAppSelector(selectMultiAccountsData);
  const loading = useAppSelector(selectLoading);

  const { open, toggle } = useToggle();

  const { control, handleSubmit, formState:{ errors }, getValues } = useForm<IFormData>({
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    if ( multiAccountsData.accounts && multiAccountsData.accounts.length ) toggle();
    // eslint-disable-next-line
  }, [multiAccountsData]);

  const onSubmit = handleSubmit((data:IFormData) => {
    asyncResetPassword(data);
  });

  const handleClose = (accountId?:number) => {
    dispatch(AuthActions.setInitialField('multiAccounts'));
    toggle();
    if ( !accountId ) return;
    const email = getValues('email');
    asyncResetPassword({ email, accountId });
  }

  const asyncResetPassword = async (data:any) => {
    try {
      await dispatch(resetPassword(data)).unwrap();
      // reset();
    } catch(error){}
  }

  return (
    <Fragment>
      <Container sx={{ pt: 8 }} maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Brand />
        </Box>
        <Paper
          sx={{ p: 8 }}
          variant="outlined"
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          {/* E-mail */}
          <Controller
            control={control} name="email"
            rules={{ required: isRequired, pattern: isEmailValid }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.email')} id="email" type="email" autoComplete="email"
                required
                error={Boolean(errors.email)}
                helperText={errors.email?.message || ''}
              />
            )}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
            <LoadingButton
              name="Send password reset link form"
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >{t('pages.forgotPasswordPage.sendPasswordResetLink')}</LoadingButton>
            <Button
              name="Go to login"
              component={Link}
              to="/"
              fullWidth
            >{t('pages.forgotPasswordPage.goToLogin')}</Button>
          </Box>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography display="block" align="center" variant="subtitle2" color="textSecondary" sx={{ py: 4 }}>
            <a
              style={{ color: 'rgba(0, 0, 0, 0.54)', textDecoration: 'none' }}
              href="https://www.casechronology.com/patent-information"
              target="_blank"
              rel="noreferrer"
            >{t('pages.loginPage.patentedSoftware')}</a>
          </Typography>
          <LanguagePicker />
        </Box>
      </Container>
      {open ? (
        <MultiAccountsDialog
          open={open}
          onClose={handleClose}
        />
      ) : null}
    </Fragment>
  );
}

export default ForgotPasswordPage;
