import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
// TYpes
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectComparePagesIds, selectPageEntity } from 'app/store/page/page.selectors';
import { selectStapleById } from 'app/store/page/page.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Service
import { parsePageId } from 'app/store/page/page.service';
// MaterialUI
import { Box, Button, Tooltip } from '@mui/material';
// Icons
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
// Components
import PageImage from '@root/components/PageImage';
import Icon from 'app/components/Icon';
import DocumentName from 'app/components/DocumentName';
import { IconButton } from 'app/components/Mui';

import { generateWorkspaceOrder } from 'app/store/page/page.service';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// i18next
import { useTranslation } from 'react-i18next';
import Highlights from 'app/components/PageImageHighlights/Highlights';

type Props = {
  pageId: string;
};

const PageCompareListItem:React.FC<Props> = ({
  // Props
  pageId
}) => {
  const { t } = useTranslation('common');

  const { documentId, pageNum, stapleId } = parsePageId(pageId);

  // Dispatch
  const dispatch = useDispatch();
  // State
  const comparePagesIds = useSelector(selectComparePagesIds);
  const page:IPage | undefined = useSelector((state:any) => selectPageEntity(state, { documentId, pageNum }));
  const staple:Array<IPage> | null = useSelector((state:any) => selectStapleById(state, { stapleId: stapleId || null }));

  const handleRemovePageFromCompare = () => {
    dispatch(PageActions.removePageFromCompare(documentId, pageNum, stapleId));
  }

  const handleMovePageInCompare = (documentId:number | undefined, pageNum:number | undefined, isNext:boolean = false) => {
    if ( !documentId || !pageNum ) return;
    dispatch(PageActions.movePageInCompare(pageId, isNext))
  }

  const index = comparePagesIds.indexOf(pageId);
  const isPrevDisabled = index === 0;
  const isNextDisabled = index === comparePagesIds.length - 1;

  const buttonPrev = (
    <Button
      disabled={isPrevDisabled}
      onClick={() => handleMovePageInCompare(page?.documentId, page?.pageNum, false)}
      variant="outlined"
      color="primary"
      size="small"
    ><ChevronLeft /></Button>
  );

  const buttonNext = (
    <Button
      disabled={isNextDisabled}
      onClick={() => handleMovePageInCompare(page?.documentId, page?.pageNum, true)}
      variant="outlined"
      color="primary"
      size="small"
    ><ChevronRight /></Button>
  );

  const buttonRemoveFromCompare = (
    <Tooltip title={t('dialogs.pageCompare.removePageFromCompare')}>
      <IconButton
        name="Remove page from compare"
        onClick={handleRemovePageFromCompare}
        color="primary"
        size="small"
      ><Icon icon="compare" /></IconButton>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '8px 0'
      }}>
        <DocumentName documentId={documentId} />
      </div>
      {stapleId && staple ? (
        <Fragment>
          {staple.map((page:IPage, index:number) => (
            <Fragment key={`compare-staple-item-${stapleId}-${index}`} >
              {index === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4, gap: 2 }}>
                  <Box sx={{ display: 'flex', gap: 4, flexGrow: 1 }}>
                    {buttonPrev}
                    {stapleId ? (
                      <span style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <Icon sx={{ mr: 2 }} icon="stapled" />
                        {t('dialogs.pageCompare.isStapled')}
                      </span>
                    ) : null}
                  </Box>
                  <ButtonWorkspace page={page} />
                  {buttonRemoveFromCompare}
                  {buttonNext}
                </Box>
              ) : null}
              <div
                style={{
                  position: 'relative',
                  border: '1px solid rgba(0,0,0,0.08)',
                  borderRadius: '4px',
                  marginBottom: '8px',
                  overflow: 'hidden'
                }}
              >
                <PageImage
                  documentId={page.documentId}
                  pageNum={page.pageNum}
                />
                <Highlights highlights={page.highlights as any} />
              </div>
            </Fragment>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4, gap: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              {buttonPrev}
            </Box>
            <ButtonWorkspace page={page as IPage} />
            {buttonRemoveFromCompare}
            {buttonNext}
          </Box>
          <div style={{
            position: 'relative',
            border: '1px solid rgba(0,0,0,0.08)',
            borderRadius: '4px',
            marginBottom: '8px',
            overflow: 'hidden'
          }}>
            <PageImage
              documentId={documentId}
              pageNum={pageNum}
            />
            <Highlights highlights={(page as any).highlights as any} />
          </div>
        </Fragment>
      )}
    </React.Fragment>
  )
}

export default PageCompareListItem;

const ButtonWorkspace = ({ page }:{ page:IPage }) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  const stapleId = page.staple && page.staple.order === 1 ? page.staple.id : null;

  const dispatch = useAppDispatch();

  const trialMode = useAppSelector(selectTrialMode);
  const staplePages:Array<IPage> | null = useSelector((state:any) => selectStapleById(state, { stapleId }))
  const episodes:any = useSelector(selectEpisodesAll);

  const handleClick = () => {
    const inWorkspace = !page.inWorkspace;
    const message = inWorkspace ? pageMessages.addPageToWorkspace : pageMessages.removePageFromWorkspace;
    const workspaceOrder = inWorkspace ? generateWorkspaceOrder(
      episodes, {
        parentEpisodeId: page.parentEpisodeId,
        workspaceOrder: page.workspaceOrder || null
      }
    ) : null;
    if ( staplePages && page.staple && page.staple.order === 1 ){
      const pagesToUpdate = staplePages.map((page:IPage) => ({
        caseId,
        documentId: page.documentId,
        pageNum: page.pageNum,
        version: page.version,
        inWorkspace,
        workspaceOrder
      }));
      dispatch(PageActions.patchPages(pagesToUpdate, message));
    } else {
      dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
        caseId,
        version: page.version,
        inWorkspace,
        workspaceOrder
      }, message))
    }
  }

  if ( trialMode || !page ) return null;
  if ( page.staple && page.staple.order > 1 ) return null;
  return (
    <Tooltip title={page.workspaceOrder ? t('dialogs.pageCompare.removePageFromWorkspace') : t('dialogs.pageCompare.addPageToWorkspace')}>
      <IconButton
        name={page.workspaceOrder ? 'Remove page from workspace' : 'Add page to workspace'}
        onClick={handleClick}
        color={page.workspaceOrder ? 'primary' : 'default'}
        size="small"
      ><Icon icon="add_to_workspace" /></IconButton>
    </Tooltip>
  );
}

