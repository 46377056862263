import { useEffect, useState } from 'react';

const useDebounce = <T>(value:T, delay:number = 1000) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay);

    return () => {
      clearTimeout(handler);
    }
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
