import React from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// State
import { useSelector } from 'react-redux';
// MaterialUI
import { Box } from '@mui/material';
// Components
import PageCard from '@root/components/PageCard';
import { selectColumnValue } from 'app/store/ui/uiSelectors';
import { selectForceReload } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';

const CARD_WIDTH = 300;
const CARD_HEIGHT = 450;

type Props = {
  pages: any;
  listRef: any;
};

const WorkspaceList:React.FC<Props> = ({
  // Props
  pages, listRef
}) => {
  // ToDO
  const columnValue:string = useSelector(selectColumnValue);
  const forceReload = useSelector(selectForceReload);
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
          <AutoSizer>
            {({ width, height }:any) => {
              const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
              const itemCount = Math.ceil(pages.length / itemsPerRow);
              const itemSize = columnValue === 'auto' ? CARD_HEIGHT : width/Number(columnValue)*3/2;

              return (
                <List
                  ref={listRef}
                  width={width}
                  height={height}
                  itemCount={itemCount}
                  itemSize={itemSize}
                  className="scrollListContainer"
                  initialScrollOffset={0}

                  itemData={{
                    itemsPerRow,
                    pages,
                    itemSize,
                    forceReload
                  }}
                >
                  {Row}
                </List>
              )
            }}
          </AutoSizer>
        </Box>
      </Box>
    </Box>
  )
}

export default WorkspaceList;

const Row = React.memo(({ index, style, data:{ itemsPerRow, pages, itemSize, forceReload } }:any) => {
  const items = [];
  const fromIndex = index * itemsPerRow;
  const toIndex = Math.min(fromIndex + itemsPerRow, pages.length);

  for ( let i = fromIndex; i < toIndex; i++ ){
    const page = pages[i];
    items.push(
      page.type === 'placeholder' ? (
        <div key={`${i}-${page.label}`} className="card-page-item" style={{ width: `${(100/itemsPerRow).toFixed(2)}%` }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              fontSize: 32,
              fontWeight: 700,
              color: 'rgba(0,0,0,0.75)'
            }}
          >
            {page.label}
          </div>
        </div>
      ) : (
        <div key={`${i}-page-item-${page.documentId}-${page.pageNum}-${forceReload}`} className="card-page-item" style={{ width: `${(100/itemsPerRow).toFixed(2)}%` }}>
          <PageCard
            scrollContainer=".scrollListContainer"
            episodeId={(page as any).id || null}
            documentId={page.documentId}
            pageNum={page.pageNum}
            index={page.index}
            height={itemSize - 16}
          />
        </div>
      )
    );
  }
  return (
    <div
      className="card-page-container"
      style={{ ...style }}
    >{items}</div>
  )
}, areEqual)