// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './Clients.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put } from 'app/utilities/HttpClient';

const _url:string = '/clients';

// Gte client accounts
export const getAccounts = asyncThunkHandler(
  `${Reducers.Clients}/Get accounts`,
  async () => {
    const response:Response = await $get(`${_url}/accounts`);
    return response.json();
  }
);

// Create client account
export const createAccount = asyncThunkHandler(
  `${Reducers.Clients}/Create account`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(`${_url}/accounts`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update client account
export const updateAccount = asyncThunkHandler(
  `${Reducers.Clients}/Update account`,
  async ({ accountId, data }:{ accountId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/accounts/${accountId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Get client account users
export const getAccountUsers = asyncThunkHandler(
  `${Reducers.Clients}/Get account users`,
  async (accountId:number) => {
    const response:Response = await $get(`${_url}/accounts/${accountId}/users`);
    return response.json();
  }
);