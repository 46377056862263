import { call, delay, put, takeLatest, select } from 'redux-saga/effects';
// Types
import NotificationStatuses from 'app/types/NotificationStatuses';
import Statuses from '@root/types/Statuses';
import { pageMessages } from './page.messages';
import { PageTypes } from './page.types';
// Models
// Api
import PageAPI from './page.api';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import PageActions from './page.actions';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
import { selectPageEntity, selectStapleById } from './page.selectors';
import { sortByStapleOrder } from '@root/utilities/sort';
import { IPage } from '@root/models/Page';

export default function* pageSaga(){
  yield takeLatest(PageTypes.FetchPage, _fetchPage);
  yield takeLatest(PageTypes.UpdatePage, _updatePage);
  yield takeLatest(PageTypes.PatchPage, _patchPage);
  yield takeLatest(PageTypes.DeletePage, _deletePage);
  yield takeLatest(PageTypes.PatchPages, _patchPages);
  yield takeLatest(PageTypes.ExportPagesToPDF, _exportPagesToPDF);
  yield takeLatest(PageTypes.RotatePage, _rotatePage);
}

function* _fetchPage(action:any){
  yield put(PageActions.setStatus(Statuses.Initial));

  const { documentId, pageNum } = action.payload;
  yield delay(100);

  const pageEntity:IPage = yield select((state:any) => selectPageEntity(state, { documentId, pageNum }));
  const staplePages:any[] = yield select((state:any) => selectStapleById(state, {
    stapleId: pageEntity.staple && pageEntity.staple.order === 1 ? pageEntity.staple.id : null
  }));
  
  yield put(PageActions.fetchPageSuccess(pageEntity));
  yield put(PageActions.setPageStaples(staplePages ? [...staplePages].sort(sortByStapleOrder) : [pageEntity]));
}

function* _updatePage(action:any){
  yield put(PageActions.setStatus(Statuses.Initial));
  yield delay(100);
  const { documentId, pageNum, data, message } = action.payload;
  try {
    const { parseBody:page } = yield call(PageAPI.updatePage, documentId, pageNum, data);
    yield put(PageActions.updatePageSuccess(page));
    yield put(PageActions.setStatus(Statuses.Success));
    yield delay(100);
    yield put(PageActions.setStatus(Statuses.Initial));
    if ( message ) yield put(AppUiNotificationsActions.addSnackbar({ message }));
  } catch(error:any){
    console.error(error);
    if ( error.status === 409 ){
      yield put(DMSDocumentPagesActions.setError({
        status: error.status,
        props: {
          documentId,
          pageNum,
          message: error.errors[0].message
        }
      }));
    } else {
      if ( error.errors && error.errors.length ){
        for ( let err of error.errors ){
          yield put(AppUiNotificationsActions.addSnackbar({
            message: err.message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      } else {
        yield put(AppUiNotificationsActions.addSnackbar({
          message: error.message,
          options: { variant: NotificationStatuses.Error }
        }));
      }
    }
    yield put(PageActions.setStatus(Statuses.Error));
  }
}

function* _patchPage(action:any){
  yield put(PageActions.setStatus(Statuses.Initial));
  yield delay(100);
  const { documentId, pageNum, data:{ caseId, ...otherData }, message } = action.payload;
  try {
    const { parseBody:page } = yield call(PageAPI.patchPage, documentId, pageNum, otherData);
    yield put(PageActions.patchPageSuccess(page));
    yield put(PageActions.setStatus(Statuses.Success));
    yield delay(100);
    yield put(PageActions.setStatus(Statuses.Initial));
    if ( message ){
      yield put(AppUiNotificationsActions.addSnackbar({ message }));
      if (
        message === pageMessages.updatePageColor ||
        message === pageMessages.removePageColor ||
        message === pageMessages.updatePageTag ||
        message === pageMessages.removePageTag ||
        message === pageMessages.addPageToWorkspace ||
        message === pageMessages.removePageFromWorkspace
      ){
        Mixpanel.track(MixpanelTracks.CasePageUpdate, {
          caseId: Number(caseId),
          docId: documentId,
          pageNum: pageNum
        });
      }
    }
  } catch(error:any){
    console.error(error);
    if ( error.status === 409 ){
      yield put(DMSDocumentPagesActions.setError({
        status: error.status,
        props: {
          documentId,
          pageNum,
          message: error.errors[0].message
        }
      }));
    } else {
      if ( error.errors && error.errors.length ){
        for ( let err of error.errors ){
          yield put(AppUiNotificationsActions.addSnackbar({
            message: err.message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      } else {
        yield put(AppUiNotificationsActions.addSnackbar({
          message: error.message,
          options: { variant: NotificationStatuses.Error }
        }));
      }
    }
    yield put(PageActions.setStatus(Statuses.Error));
  }
}

function* _deletePage(action:any){
  yield put(PageActions.setStatus(Statuses.Initial));
  yield delay(100);
  const { documentId, pageNum } = action.payload;
  try {
    yield call(PageAPI.deletePage, documentId, pageNum);
    yield put(PageActions.deletePageSuccess(documentId, pageNum));
    yield put(PageActions.setStatus(Statuses.Success));
    yield delay(100);
    yield put(PageActions.setStatus(Statuses.Initial));
    yield put(AppUiNotificationsActions.addSnackbar({
      message: pageMessages.deletePage
    }));
  } catch(error:any){
    console.error(error);
    if ( error.status === 409 ){
      yield put(DMSDocumentPagesActions.setError({
        status: error.status,
        props: {
          documentId,
          pageNum,
          message: error.errors[0].message
        }
      }));
    } else {
      if ( error.errors && error.errors.length ){
        for ( let err of error.errors ){
          yield put(AppUiNotificationsActions.addSnackbar({
            message: err.message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      } else {
        yield put(AppUiNotificationsActions.addSnackbar({
          message: error.message,
          options: { variant: NotificationStatuses.Error }
        }));
      }
    }
    yield put(PageActions.setStatus(Statuses.Error));
  }
}

function* _patchPages(action:any){
  yield put(PageActions.setStatus(Statuses.Initial));
  yield delay(100);

  const { data, message, cb } = action.payload;

  const { documentId, pageNum } = data[0];

  try {
    const { parseBody:pages } = yield call(PageAPI.patchPages, data.map(({caseId, ...p}:any) => p));
    yield put(PageActions.patchPagesSuccess(pages));
    yield put(PageActions.setStatus(Statuses.Success));
    yield delay(100);
    yield put(PageActions.setStatus(Statuses.Initial));
    if ( message ){
      yield put(AppUiNotificationsActions.addSnackbar({ message }));

      const primaryPage = pages.find((page:any) => page.staple && page.staple.order === 1);

      if (
        primaryPage && (
          message === pageMessages.updatePageColor ||
          message === pageMessages.removePageColor ||
          message === pageMessages.updatePageTag ||
          message === pageMessages.removePageTag ||
          message === pageMessages.addPageToWorkspace ||
          message === pageMessages.removePageFromWorkspace
        )
      ){
        Mixpanel.track(MixpanelTracks.CasePagesUpdate, {
          caseId: Number(data[0].caseId),
          pagesData: pages.map((page: any) => ({
            docId: page.documentId,
            pageNum: page.pageNum
          }))
        });
      }
    }

    if ( cb ) cb();

  } catch(error:any){
    console.error(error);
    if ( error.status === 409 ){
      yield put(DMSDocumentPagesActions.setError({
        status: error.status,
        props: {
          documentId,
          pageNum,
          message: error.errors[0].message
        }
      }));
    } else {
      if ( error.errors && error.errors.length ){
        for ( let err of error.errors ){
          yield put(AppUiNotificationsActions.addSnackbar({
            message: err.message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      } else {
        yield put(AppUiNotificationsActions.addSnackbar({
          message: error.message,
          options: { variant: NotificationStatuses.Error }
        }));
      }
    }
    yield put(PageActions.setStatus(Statuses.Error));
  }
}

function* _rotatePage(action:any){
  yield put(PageActions.setStatus(Statuses.Initial));
  yield delay(1500);
  const { documentId, pageNum, data } = action.payload;
  try {
    const { parseBody:page } = yield call(PageAPI.rotatePage, documentId, pageNum, data);
    yield put(PageActions.rotatePageSuccess(page));
    yield put(PageActions.setStatus(Statuses.Success));
    yield delay(100);
    yield put(PageActions.setStatus(Statuses.Initial));
    yield put(AppUiNotificationsActions.addSnackbar({ message: pageMessages.rotatePage }));
  } catch(error:any){
    console.error(error);
    if ( error.status === 409 ){
      yield put(DMSDocumentPagesActions.setError({
        status: error.status,
        props: {
          documentId,
          pageNum,
          message: error.errors[0].message
        }
      }));
    } else {
      if ( error.errors && error.errors.length ){
        for ( let err of error.errors ){
          yield put(AppUiNotificationsActions.addSnackbar({
            message: err.message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      } else {
        yield put(AppUiNotificationsActions.addSnackbar({
          message: error.message,
          options: { variant: NotificationStatuses.Error }
        }));
      }
    }
    yield put(PageActions.setStatus(Statuses.Error));
  }
}

function* _exportPagesToPDF(action:any){
  yield put(PageActions.setStatus(Statuses.Initial));
  yield delay(100);
  try {
    const { pages } = action.payload;
    yield call(PageAPI.exportPagesToPDF, pages);
    yield put(PageActions.exportPagesToPDFSuccess());
    yield put(PageActions.setStatus(Statuses.Success));
    yield delay(100);
    yield put(PageActions.setStatus(Statuses.Initial));
    yield put(AppUiNotificationsActions.addSnackbar({ message: pageMessages.exportPagesToPDF }));
  } catch(error:any){
    console.error(error);
    yield put(PageActions.setStatus(Statuses.Error));
    if ( error.errors && error.errors.length ){
      for ( let err of error.errors ){
        yield put(AppUiNotificationsActions.addSnackbar({
          message: err.message,
          options: { variant: NotificationStatuses.Error }
        }));
      }
    } else {
      yield put(AppUiNotificationsActions.addSnackbar({
        message: error.message,
        options: { variant: NotificationStatuses.Error }
      }));
    }
  }
}
