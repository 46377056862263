import { FC } from 'react';
// 
import CardPageFootCompare from './CardPageFootCompare';
import CardPageFootColors from './CardPageFootColors';
import CardPageFootStaple from './CardPageFootStaple';
import CardPageFootStapleMode from './CardPageFootStapleMode';

type Props = {
  pageId: string;
}

const CardPageFoot:FC<Props> = ({
  // Props
  pageId
}) => {
  return (
    <div className="cp-foot">
      <CardPageFootCompare pageId={pageId} />
      <CardPageFootColors pageId={pageId} />
      <CardPageFootStaple pageId={pageId} />
      <CardPageFootStapleMode pageId={pageId} />
    </div>
  )
}

export default CardPageFoot;