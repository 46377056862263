import { FC } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
// Types
import UserRoles from './types/UserRoles';
// Store
import { useAppSelector } from './hooks/useStore';
// Selectors
import { selectAuthenticated } from './store/Auth/Auth.selectors';
// Pages
import LoginPage from 'app/pages/LoginPage';
import ForgotPasswordPage from 'app/pages/ForgotPasswordPage';
import UpdatePasswordPage from 'app/pages/UpdatePasswordPage';
import VerificationPage from 'app/pages/VerificationPage';
// Inner pages
import Admin from 'pages/Admin';
import AccountManagerRouting from 'app/pages/AccountManager/AccountManager.routing';
import PresenterRouting from 'app/pages/Presenter/Presenter.routing';
import MyProfilePage from 'app/pages/MyProfilePage';
import IntegrationsQuickbooksCallback from 'pages/IntegrationsQuickbooksCallback';
import TermsAndConditionsPage from 'app/pages/TermsAndConditionsPage';
import MyTermsAndConditions from 'app/pages/MyTermsAndConditionsPage';
// Components
import PublicRoute from 'app/components/PublicRoute';
import PrivateRoute from 'app/components/PrivateRoute';
import useRedirectPath from './hooks/useRedirectPath';

const AppRouting:FC = () => {
  const authenticated = useAppSelector(selectAuthenticated);

  const [ myUserRolePath ] = useRedirectPath();

  const redirectTo = authenticated ? myUserRolePath : '/login';

  return (
    <Switch>
      <PublicRoute path="/login" component={LoginPage} />
      <PublicRoute path="/forgot-password" component={ForgotPasswordPage} />
      <PublicRoute path="/reset-password" component={UpdatePasswordPage} />
      <PublicRoute path="/verification" component={VerificationPage} />

      <Route path="/integrations/quickbooks/callback" component={IntegrationsQuickbooksCallback} />

      <PrivateRoute
        roles={[UserRoles.Admin, UserRoles.Staff]}
        path="/admin"
        component={Admin}
      />
      <PrivateRoute
        roles={[UserRoles.AccountManager]}
        path="/am"
        component={AccountManagerRouting}
      />
      <PrivateRoute
        roles={[UserRoles.Presenter, UserRoles.Client]}
        path="/presenter"
        component={PresenterRouting}
      />
      <PrivateRoute
        roles={[UserRoles.Admin, UserRoles.Staff, UserRoles.AccountManager, UserRoles.Presenter]}
        path="/profile"
        component={MyProfilePage}
      />
      <PrivateRoute
        path="/terms-and-conditions"
        roles={[UserRoles.Admin, UserRoles.Staff, UserRoles.Presenter, UserRoles.Client]}
        component={TermsAndConditionsPage}
      />
      <PrivateRoute
        roles={[UserRoles.Admin, UserRoles.Staff, UserRoles.AccountManager, UserRoles.Presenter]}
        path="/my-terms-and-conditions"
        component={MyTermsAndConditions}
      />

      <Redirect to={redirectTo as string} />
    </Switch>
  );
}

export default AppRouting;
