import { FC, Fragment } from 'react';
// Mui
import { Fab } from '@mui/material';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import PackageFormDialog from './PackageFormDialog';
// i18next
import { useTranslation } from 'react-i18next';

const PackagesFab:FC = () => {
  const { t } = useTranslation('common');

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Fab
        name="Create subscription package"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggle}
        color="primary"
        variant="extended"
      >{t('pages.accountManagerPages.subscriptionPackagesPage.createSubscriptionPackage')}</Fab>
      {/* Package form dialog */}
      {open ? (
        <PackageFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default PackagesFab;
