import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { updateInvoice } from 'app/store/BillingInvoices/BillingInvoices.async';
// Selectors
import { selectLoading } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Button, FormHelperText, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import Dialog from 'app/components/Dialog';
import { Input } from 'app/components/Mui';
// Utility
import { isRequired, isMin, isMax } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  discountAmount: number;
};

type Props = {
  open: boolean;
  onClose: () => void;
  invoiceId: number;
  discountAmount: number;
  amount: number;
};

const InvoiceDiscountFormDialog:FC<Props> = ({
  // Props
  open, onClose, invoiceId, discountAmount, amount
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors } } = useForm<IFormData>({
    defaultValues: {
      discountAmount
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      await dispatch(updateInvoice({ invoiceId, data: {
        discountAmount: Number(data.discountAmount)
      } })).unwrap();
      onClose();
    } catch(error){}
  });

  const actions = (
    <Fragment>
      <Button
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('labels.discount')}
      actions={actions}
    >
      <form noValidate>
        {/* Quickbooks ID */}
        <Controller
          control={control} name="discountAmount"
          rules={{
            required: isRequired,
            min: isMin(0),
            max: discountAmount ? isMax(amount + discountAmount) : isMax(amount)
          }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('pages.accountManagerPages.billingInvoicesPage.discountAmount')}
              error={Boolean(errors.discountAmount)}
              helperText={errors.discountAmount ? errors.discountAmount.message : ''}
              required
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          )}
        />
        <FormHelperText>{t('pages.accountManagerPages.billingInvoicesPage.discountAmountHelperText')}</FormHelperText>
      </form>
    </Dialog>
  );
}

export default InvoiceDiscountFormDialog;
