import { FC, Fragment } from 'react';
import { Link, useParams }from 'react-router-dom';
import dayjs from 'dayjs';
// Types
import CalendarLegends from 'app/types/CalendarLegends';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
import { setSelectedDate } from 'app/store/currentUser/currentUserSlice';
// Selectors
import { selectIsPageInCompare, selectStapleById } from 'app/store/page/page.selectors';
import { selectPageStapleId } from 'app/store/Episodes/Episodes.selectors';
// Material
import {
  IconButton,
  Box, Typography, Tooltip
} from '@mui/material';
import {
  Link as LinkIcon,
  Close as CloseIcon
} from '@mui/icons-material';
// Components
import EpisodeLabels from '@root/components/EpisodeLabels';
import Icon from 'app/components/Icon';
// Hooks
import useLongDateTme from 'app/hooks/useLongDateTime';
// i18next
import { useTranslation } from 'react-i18next';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

type Props = {
  onClose: () => void;
  episode: any;
  showDailyButton?: boolean;
};

const PopoverContent:FC<Props> = ({
  // Props
  onClose, episode, showDailyButton = false
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const stapleId = useSelector((state:any) => selectPageStapleId(state, { documentId: episode.documentId, pageNum: episode.pageNum }));
  const staplePages = useSelector((state:any) => selectStapleById(state, { stapleId }));

  const longDateTime = useLongDateTme({
    dateValue: episode.date,
    timeValue: episode.time
  });

  const isPageInCompare = useSelector((state:any) => selectIsPageInCompare(state, {
    documentId: episode.documentId,
    pageNum: episode.pageNum,
    stapleId
  }));

  const handleSetSelectedDate = () => {
    dispatch(setSelectedDate(dayjs(episode.date)));
  }

  const togglePageCompare = () => {
    if ( isPageInCompare ){
      dispatch(PageActions.removePageFromCompare(episode.documentId, episode.pageNum, stapleId));
    } else {
      dispatch(PageActions.addPageToCompare(episode.documentId, episode.pageNum, stapleId));
    }
  }

  const primaryStaplePage = staplePages && staplePages.length ? staplePages.find((p) => p.staple && p.staple.order === 1) : null;
  const pageNum = primaryStaplePage ? primaryStaplePage.pageNum : episode.pageNum;

  const link = `/admin/cases/${caseId}/documents/${episode.documentId}/${pageNum}`
  const linkToFullPreview = `${link}?episodeId=${episode.id}`;

  const isPublicTypeActive =
    episode.legend.key !== CalendarLegends.EventDate &&
    episode.legend.key !== CalendarLegends.ExaminationDate &&
    episode.legend.key !== CalendarLegends.TrialDate
  ;

  return (
    <Box sx={{ width: 480, display: 'flex', flexDirection: 'column', px: 4, py: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {longDateTime ? (
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', color: 'rgba(0,0,0,0.6)', paddingRight: '8px' }}>
            <Icon
              // sx={{ color: 'rgba(0,0,0,0.87)' }}
              icon="event"
              label={longDateTime}
            />
          </Box>
        ) : null}
        <Box>
          {isPublicTypeActive ? (
            <Fragment>
              <Tooltip title={t('labels.pagePreview')}>
                <IconButton
                  component={Link}
                  to={linkToFullPreview}
                  target="_blank"
                ><LinkIcon /></IconButton>
              </Tooltip>
              {showDailyButton ? (
                <Tooltip title={t('pages.adminPages.calendarPage.loadDataToDailyChart')}>
                  <IconButton
                    onClick={handleSetSelectedDate}
                  ><Icon icon="load_data_to_daily_chart" /></IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title={isPageInCompare ? t('pages.adminPages.calendarPage.removeFromCompare') : t('pages.adminPages.calendarPage.addToCompare')}>
                <IconButton
                  onClick={togglePageCompare}
                  color={isPageInCompare ? 'primary' : 'default'}
                ><Icon icon="compare" /></IconButton>
              </Tooltip>
            </Fragment>
          ) : null}
          <Tooltip title={t('labels.close')}>
            <IconButton
              onClick={onClose}
              edge="end"
            ><CloseIcon /></IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        {episode.labels && episode.labels.length ? (
          <Box sx={{ mb: 2 }}>
            <EpisodeLabels id="calendar-popover" labels={episode.labels} />
          </Box>
        ) : null}
        <Typography variant="h6">{episode.legend.label}</Typography>
        {episode.author && episode.author.name ? (
          <Typography variant="subtitle2">{episode.author.name}</Typography>
        ) : null}
        {!trialMode && episode.notes ? (
          <Box className="ql-editor" sx={{ px: 0 }} dangerouslySetInnerHTML={{__html: episode.notes}} />
        ) : null}
      </Box>
    </Box>
  )
}

export default PopoverContent;
