// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./BillingCharges.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $post } from 'app/utilities/HttpClient';

const _url:string = '/billing/charges';

// Get invoices
export const chargeInvoice = asyncThunkHandler(
  `${Reducers.BillingInvoices}/Charge invoice`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.PaymentSuccess
    }));
    return fulfillWithValue('Payment successful');
  }
);
// Batch charge invoices
export const batchChargeInvoices = asyncThunkHandler(
  `${Reducers.BillingInvoices}/Batch charge invoices`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/batch`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.BulkPaymentSuccess
    }));
    return fulfillWithValue('Bulk payment successful');
  }
);

