import { FC, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
// Model
import { IPresenterCase } from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { setSelectedDate } from 'app/store/calendar/calendarSlice';
// Async
import { getMyPreferences } from 'app/store/currentUser/currentUserAsync';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectInWorkspaceEpisodeIds } from 'app/store/calendar/calendarSelectors';
// Mui
import { Box, Paper, Tabs, Tab } from '@mui/material';
// Components
import Calendar from 'app/components/Calendar';
import { Loader } from 'app/components/Utilities';
// 
import Timeline from './Timeline';
import dayjs from 'dayjs';
// i18next
import { useTranslation } from 'react-i18next';

const CalendarPage:FC = () => {
  const { t } = useTranslation('common');

  const { insuranceCaseId } = useParams<{ insuranceCaseId:string }>();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const queryTab = searchParams.get('tab') || 'calendar';
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase:IPresenterCase | null = useAppSelector(selectInsuranceCase);
  const episodeIds:number[] | null = useAppSelector(selectInWorkspaceEpisodeIds);

  const [ tab, setTab ] = useState(queryTab);

  useEffect(() => {
    dispatch(getMyPreferences(`case:${insuranceCaseId}.chart.legend`));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !insuranceCase || !insuranceCase.eventDate ) return;

    dispatch(setSelectedDate(dayjs(insuranceCase.eventDate)));
    // eslint-disable-next-line
  }, [insuranceCase]);

  useEffect(() => {
    setTab(queryTab);
    // eslint-disable-next-line
  }, [queryTab]);

  const handleChange = (_:any, nextTab:string) => {
    dispatch(push({ search: `tab=${nextTab}` }));
  }

  if ( !episodeIds ) return <Loader />;
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto', p: 4, height: '100%' }}>
      <Paper variant="outlined">
        <Tabs
          value={tab}
          onChange={handleChange}
          centered
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab value="calendar" label={t('labels.calendar')} />
          <Tab value="timeline" label={t('labels.timeline')} />
        </Tabs>
      </Paper>
      {tab === 'calendar' ? <Calendar /> : null}
      {tab === 'timeline' ? <Timeline /> : null}
    </Box>
  )
}

export default CalendarPage;
