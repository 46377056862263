import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Services
import LocalStorageService from "app/services/LocalStorage.service";

interface IState {
  trialMode: boolean;
  workspacePageIds: Record<number, string>;
  calendarDates: Record<number, string>;

  requestedUrl: string | null;
  pagesInRow: string;
  gridResizerWidth: number;

  availableLanguages: string[];
  language: string;

  // Files
  filesSidebarOpen: boolean;
  aiChatSidebarOpen: boolean;
  // Search
  searchSidebarOpen: boolean;
  // Invoice
  invoicesSidebarOpen: boolean;
  // Labeling tool sidebar open
  labellingToolsSidebarOpen: boolean;

  selectedPageIds: any;
};

const initialState:IState = {
  trialMode: LocalStorageService.getTrialMode(),
  workspacePageIds: LocalStorageService.getWorkspacePageIds(),
  calendarDates: LocalStorageService.getCalendarDates(),

  requestedUrl: null,
  pagesInRow: 'auto',
  gridResizerWidth: LocalStorageService.getGridResizerWidth(),

  availableLanguages: ['en', 'es'],
  language: LocalStorageService.getLanguage(),

  // Files
  filesSidebarOpen: true,
  aiChatSidebarOpen: true,
  // Search
  searchSidebarOpen: true,
  // Invoices
  invoicesSidebarOpen: true,
  // Labeling
  labellingToolsSidebarOpen: false,

  selectedPageIds: []
};

const slice = createSlice({
  name: Reducers.AppUi,
  initialState,
  reducers: {
    toggleTrialMode: (state:IState) => {
      const trialMode = !state.trialMode;
      state.trialMode = trialMode;
      LocalStorageService.setTrialMode(trialMode);
    },

    setWorkspacePageId: (state:IState, action:PayloadAction<{ caseId:number, pageId:string }>) => {
      const { caseId, pageId } = action.payload;
      const workspacePageIds = {
        ...state.workspacePageIds,
        [caseId]: pageId
      };
      state.workspacePageIds = workspacePageIds;
      LocalStorageService.setWorkspacePageIds(workspacePageIds);
    },

    setCalendarDate: (state:IState, action:PayloadAction<{ caseId:number, date:string }>) => {
      const { caseId, date } = action.payload;
      const calendarDates = {
        ...state.calendarDates,
        [caseId]: date
      };
      state.calendarDates = calendarDates;
      LocalStorageService.setCalendarDates(calendarDates);
    },

    setRequestedUrl: (state:IState, action:PayloadAction<string | null>) => {
      state.requestedUrl = action.payload;
    },
    setPagesInRow: (state:IState, action:PayloadAction<string>) => {
      state.pagesInRow = action.payload;
    },
    setGridResizerWidth: (state:IState, action:PayloadAction<number>) => {
      state.gridResizerWidth = action.payload;
      LocalStorageService.setGridResizerWidth(action.payload);
    },
    setLanguage: (state:IState, action:PayloadAction<string>) => {
      state.language = action.payload;
      LocalStorageService.setLanguage(action.payload);
    },

    toggleFilesSidebar: (state:IState) => {
      state.filesSidebarOpen = !state.filesSidebarOpen;
    },

    toggleAIChatSidebar: (state:IState) => {
      state.aiChatSidebarOpen = !state.aiChatSidebarOpen;
    },

    toggleSearchSidebarOpen: (state:IState) => {
      state.searchSidebarOpen = !state.searchSidebarOpen;
    },

    toggleInvoicesSidebarOpen: (state:IState) => {
      state.invoicesSidebarOpen = !state.invoicesSidebarOpen;
    },

    toggleLabellingToolsSidebarOpen: (state:IState) => {
      state.labellingToolsSidebarOpen = !state.labellingToolsSidebarOpen;
    },

    // Add page to selected
    addPageIdsToSelected: (state:IState, action:PayloadAction<string[]>) => {
      state.selectedPageIds = action.payload;
    },
    addPageIdToSelected: (state:IState, action:PayloadAction<string>) => {
      state.selectedPageIds = [...state.selectedPageIds, action.payload];
    },
    removePageIdFromSelected: (state:IState, action:PayloadAction<string>) => {
      state.selectedPageIds = state.selectedPageIds.filter((pageId:string) => pageId !== action.payload)
    },

    setInitialField: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  }
});

export const AppUiActions = slice.actions;

export default slice.reducer;
