import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import { IConversation, IMessage } from 'app/models/ChatAI';

import { uuidv4 } from 'app/utilities/Utilities';

export const selectConversations = (state:RootState) => state[Reducers.AIChat].conversations;
export const selectConversation = (state:RootState) => state[Reducers.AIChat].conversation;
export const selectLoading = (state:RootState) => state[Reducers.AIChat].loading;
export const selectLoadingMessage = (state:RootState) => state[Reducers.AIChat].loadingMessage;
export const selectStatusMessage = (state:RootState) => state[Reducers.AIChat].statusMessage;

export const selectRefineParentId = (state:RootState) => state[Reducers.AIChat].refineParentId;

export const selectConversationMessagesArray = createSelector(
  [ selectConversation ],
  ( conversation:IConversation | null ) => {
    if ( !conversation ) return null;
    const messages = conversation.messages || [];
    const result:Map<string, IMessage[]> = new Map();
    for ( let message of messages ){
      const id = message.id || uuidv4();
      const parentId = message.parentId;
      if ( parentId ){
        if ( result.has(parentId) ){
          const getMessages = result.get(parentId);
          result.set(parentId, [...(getMessages || []), message]);
        }
      } else {
        result.set(id, [message]);
      }
    }
    return Array.from(result).map(([ key, value ]:[ string, IMessage[] ]) => value);
  }
);

export const selectLastHumanMessage = createSelector(
  [ selectConversation ],
  ( conversation:IConversation | null ) => {
    if ( !conversation ) return null;
    const messages = conversation.messages || [];
    for ( let i = messages.length - 1; i >= 0; i-- ){
      const message = messages[i];
      if ( message.type === 'human' ) return message;
    }
    return null;
  }
);

export const selectIsRefineButtonVisible = createSelector(
  [
    selectLastHumanMessage,
    (_, props:{ parentId:string | undefined }) => props
  ],
  ( message:IMessage | null, { parentId }) => {
    if ( !message || !parentId ) return false;
    return message.id === parentId;
  }
);
