import { useState } from 'react';

const useToggle = (initialOpen:boolean = false) => {
  const [ open, setOpen ] = useState(initialOpen);

  const toggle = () => setOpen(!open);

  return { open, toggle };
}

export default useToggle;
