import config from "../../../config";

// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from "app/store/AppUINotifications/AppUINotifications.slice";
// Messages
import Messages from "./AIChatPrompts.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from "app/utilities/HttpClient";

const _url: string = `${config.chatAIApiUrl}/prompts`;

// Get promps
export const getPrompts = asyncThunkHandler(
  `${Reducers.AIChatPrompts}/Get prompts`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  },
);
// Get promp
export const getPrompt = asyncThunkHandler(
  `${Reducers.AIChatPrompts}/Get prompt`,
  async (id:string) => {
    const response:Response = await $get(`${_url}/${id}`);
    return response.json();
  },
);
// Create promp
export const createPrompt = asyncThunkHandler(
  `${Reducers.AIChatPrompts}/Create prompt`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create,
    }));
    return response.json();
  },
);
// Update promp
export const updatePrompt = asyncThunkHandler(
  `${Reducers.AIChatPrompts}/Update prompt`,
  async ({ id, data }: { id:string; data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${id}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update,
    }));
    return response.json();
  },
);
// Delete promp
export const deletePrompt = asyncThunkHandler(
  `${Reducers.AIChatPrompts}/Delete prompt`,
  async (id:string, { fulfillWithValue, dispatch }) => {
    await $delete(`${_url}/${id}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete,
    }));
    return fulfillWithValue(id);
  },
);
