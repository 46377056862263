import { createSlice } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';

interface IState {
  loading: boolean;
};

const initialState:IState = {
  loading: false
};

const slice = createSlice({
  name: Reducers.Content,
  initialState,
  reducers: {

  }
});

// export const { } = slice.actions;

export default slice.reducer;
