import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.dmsCollections.create'),
  Update: i18n.t('notifications.dmsCollections.update'),
  Delete: i18n.t('notifications.dmsCollections.delete')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.dmsCollections.create');
  Messages.Update = i18n.t('notifications.dmsCollections.update');
  Messages.Delete = i18n.t('notifications.dmsCollections.delete');
});