import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IGeneralSettings from "app/models/Settings";
// Async
import { getGeneralSettings } from './Settings.async';

interface IState {
  generalSettings: IGeneralSettings | null;
  loading: boolean;
};

const initialState:IState = {
  generalSettings: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.Settings,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get presets
    builder.addCase(getGeneralSettings.pending, (state) => {
      state.generalSettings = null;
    });
    builder.addCase(getGeneralSettings.fulfilled, (state, action:PayloadAction<IGeneralSettings>) => {
      state.generalSettings = action.payload;
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const SettingsActions = slice.actions;

export default slice.reducer;
