import { FC, useRef, useEffect, Fragment } from 'react';
// Types
import IConversation from 'app/models/Conversation';
import IMessage from 'app/models/Message';
// Mui
import { Box } from '@mui/material';
// Components
import { Message, Loader } from 'app/components/Utilities';
// 
import MessagesListItem from './MessagesListItem';
import { useAppSelector } from 'app/hooks/useStore';
import { selectConversation } from 'app/store/Conversations/Conversations.selectors';
import { selectMessages } from 'app/store/Messages/Messages.selectors';

const MessagesList:FC = () => {
  // State
  const conversation:IConversation | null = useAppSelector(selectConversation);
  const messages:IMessage[] | null = useAppSelector(selectMessages);

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( conversation?.me && messages && messages.length ){
      const lastItem = messages[messages.length - 1];
      if ( conversation.me.lastSeenMessageId !== lastItem.id ){
        scrollToBottom();
      }
    }
    // eslint-disable-next-line
  }, [conversation, messages]);

  if ( !messages ) return <Loader />;
  if ( !messages.length ) return <Message text="No message(s)" />;
  return (
    <Fragment>
      <Box component="ul" sx={{ m: 0, px: 4, py: 2 }}>
        {messages.map((item:IMessage) => (
          <MessagesListItem key={`message-item-${item.id}`} item={item} />
        ))}
      </Box>
      <div ref={messagesEndRef} />
    </Fragment>
  )
}

export default MessagesList;
