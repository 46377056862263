import Quill from 'quill';

class PageBreakBlot extends Quill.import('blots/block/embed') {
  static blotName = 'pagebreak';
  static tagName = 'hr';

  static create(value:any) {
    const node = super.create(value);
    node.style = 'page-break-after: always;';
    return node;
  }
}

Quill.register(PageBreakBlot, true);