import { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
// Models
import IEventTime from 'app/models/EventTime';
import { ICurrentAccount } from 'app/models/Account';
// Redux
import { useAppSelector } from './useStore';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';

type Props = {
  dateValue: string | undefined;
  timeValue?: IEventTime | string | undefined;
  displayTime?: boolean;
  local?: boolean
}

// @date: YYYY-MM-DDTHH:mm:ss.zzzZ || YYYY-MM-DD
// @time: HH:mm

const useLongDateTme = ({ dateValue, timeValue, displayTime = false, local = false }:Props) => {
  // State
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);

  const initialDateFormat = 'MMMM DD, YYYY';
  const initialTimeFormat = currentAccount?.settings?.timeFormat || 'hh:mm a';

  const longDateTime = useMemo(() => {
    if ( !dateValue ) return undefined;

    const dateTimeFormat = displayTime || timeValue
      ? `${initialDateFormat}, ${initialTimeFormat}`
      : initialDateFormat
    ;

    let date;
    if ( local ){
      dayjs.extend(utc);
      date = dayjs(dateValue).local();
    } else {
      date = dayjs(dateValue);
    }

    if ( timeValue ){
      let hour;
      let minute;
      if ( typeof timeValue === 'string' ){
        hour = Number(timeValue.split(':')[0]);
        minute = Number(timeValue.split(':')[1]);
      } else {
        hour = timeValue.hour;
        minute = timeValue.minute;
      }
      return date.hour(hour).minute(minute).format(dateTimeFormat);
    } else {
      return date.format(dateTimeFormat);
    }
    // eslint-disable-next-line
  }, [dateValue, timeValue, local]);

  return longDateTime;
}

export default useLongDateTme;
