import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
// Models
import IOption from 'app/models/Option';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getAccountBilling, updateAccountBillingSubscription } from 'app/store/AccountsManagement/AccountsManagement.async';
// Selectors
import { selectPackagesAsOptions } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectAccountBilling, selectLoading } from 'app/store/AccountsManagement/AccountsManagement.selectors';
// Mui
import { Button, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import Dialog from 'app/components/Dialog';
import Subscription from 'app/components/Subscription';
import { Select } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  accountId: number;
};

const AccountSubscriptionDialog:FC<Props> = ({
  // Props
  open, onClose, accountId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const packages:IOption[] = useAppSelector(selectPackagesAsOptions) || [];
  const accountBilling:any = useAppSelector(selectAccountBilling);
  const loading:boolean = useAppSelector(selectLoading);

  const [ subscriptionPackageId, setSubscriptionPackageId ] = useState<string>('');

  useEffect(() => {
    dispatch(getAccountBilling(accountId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( accountBilling && accountBilling.subscription ){
      const { subscriptionPackage } = accountBilling.subscription;
      setSubscriptionPackageId(subscriptionPackage.id);
    } else {
      setSubscriptionPackageId('');
    }
    // eslint-disable-next-line
  }, [accountBilling])

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setSubscriptionPackageId(event.target.value);
  }

  const handleClick = async () => {
    try {
      await dispatch(updateAccountBillingSubscription({
        accountId,
        data: { subscriptionPackageId: Number(subscriptionPackageId) }
      })).unwrap();
      onClose();
    } catch(error){}
  };

  const isDisabled = subscriptionPackageId
    ? accountBilling && accountBilling.subscription
      ? accountBilling.subscription.subscriptionPackage.id === Number(subscriptionPackageId)
      : !Boolean(subscriptionPackageId)
    : true
  ;

  const actions = (
    <Fragment>
      <Button
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        disabled={isDisabled}
        onClick={handleClick}
        loading={loading}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.accountManagerPages.companyAccountsPage.accountSubscription')}
      actions={actions}
    >
      {/* Package */}
      <Select
        label={t('pages.accountManagerPages.companyAccountsPage.subscriptionPackages')}
        value={subscriptionPackageId}
        onChange={handleChange}
        options={[
          { id: '', name: t('pages.accountManagerPages.companyAccountsPage.subscriptionPackages') },
          ...packages
        ]}
        InputLabelProps={{
          shrink: true
        }}
        SelectProps={{
          displayEmpty: true
        }}
      />
      {accountBilling && accountBilling.subbscription ? <Divider sx={{ my: 4 }} /> : null}
      <Subscription accountBilling={accountBilling} />
    </Dialog>
  );
}

export default AccountSubscriptionDialog;
