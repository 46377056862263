import { FC, Fragment } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
// Models
import IInsuranceCase from 'app/models/Case';
// Mui
import { Paper, Box, Typography, Tooltip, Divider } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import Icon from 'app/components/Icon';
import { Button } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useFormattedDate from 'app/hooks/useFormattedDate';
import useLongDateTme from 'app/hooks/useLongDateTime';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: (isAccepted:boolean) => void;
  searchCasesData: Record<string, IInsuranceCase[]> | undefined;
}

const CaseCheckDialog:FC<Props> = ({
  // Props
  open, onClose, searchCasesData
}) => {
  const { t } = useTranslation('common');

  const { url } = useRouteMatch<{ url:string }>();

  const handleClose = (isAccepted:boolean) => () => {
    onClose(isAccepted);
  }

  const actions = (
    <Fragment>
      <Button
        name="Decline check case dialog"
        onClick={handleClose(false)}
      >{t('pages.staffPages.casesPage.cancelCaseCreation')}</Button>
      <Button
        name="Accept check case dialog"
        onClick={handleClose(true)}
        variant="contained"
        color="primary"
      >{t('pages.staffPages.casesPage.acknowledgeAndProceed')}</Button>
    </Fragment>
  );

  const searchCasesClaimantName = searchCasesData ? searchCasesData['claimant.name'] : null;
  const searchCasesClaimantDateOfBirth = searchCasesData ? searchCasesData['claimant.dateOfBirth'] : null;

  return (
    <Dialog
      open={open}
      onClose={handleClose(false)}
      maxWidth="md"
      title={t('pages.staffPages.casesPage.potentialConflicts')}
      actions={actions}
    >
      {searchCasesClaimantName && searchCasesClaimantName.length ? (
        <Box sx={{ mb: searchCasesClaimantDateOfBirth && searchCasesClaimantDateOfBirth.length ? 4 : 0 }}>
          <Typography sx={{ mb: 2 }} variant="h6">{t('pages.staffPages.casesPage.claimantNameCaseMatches')}</Typography>
          {searchCasesClaimantName.map((insuranceCase:IInsuranceCase) => (
            <InsuranceCaseItem
              key={`cases by claimant name ${insuranceCase.id}`}
              url={url}
              insuranceCase={insuranceCase}
            />
          ))}
        </Box>
      ) : null}
      {searchCasesClaimantDateOfBirth && searchCasesClaimantDateOfBirth.length ? (
        <Fragment>
          <Typography sx={{ mb: 2 }} variant="h6">{t('pages.staffPages.casesPage.claimantDOBCaseMatches')}</Typography>
          {searchCasesClaimantDateOfBirth.map((insuranceCase:IInsuranceCase) => (
            <InsuranceCaseItem
              key={`cases by claimant date of birth ${insuranceCase.id}`}
              url={url}
              insuranceCase={insuranceCase}
            />
          ))}
        </Fragment>
      ) : null}
    </Dialog>
  )
}

export default CaseCheckDialog;


const InsuranceCaseItem = ({ url, insuranceCase }:{ url:string, insuranceCase:IInsuranceCase }) => {
  const { t } = useTranslation('common');

  const formattedDateOfBirth = useFormattedDate(insuranceCase.claimant?.dateOfBirth);
  const longCreatedOn = useLongDateTme({
    dateValue: insuranceCase.createdOn,
    displayTime: true,
    local: true
  });
  const longBatchCreatedOn = useLongDateTme({
    dateValue: insuranceCase.documentUploadedOn,
    displayTime: true,
    local: true
  });

  return (
    <Paper sx={{ p: 4, '& + &': { mt: 2 } }} variant="outlined">
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4, mb: 2 }}>
        <Typography sx={{
          flexGrow: 1,
          lineHeight: 1.2,
          '& > a': {
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
          }
        }} variant="h5">
          {insuranceCase.status === CaseStatuses.Pending || insuranceCase.status === CaseStatuses.Rejected ? (
            insuranceCase.name
          ) : (
            <Link to={`${url}/${insuranceCase.id}/documents`} target="_blank">{insuranceCase.name}</Link>
          )}
        </Typography>
      </Box>
      {insuranceCase.rejectComment ? (
        <Typography sx={{ mb: 2 }} variant="body2" color="GrayText">{insuranceCase.rejectComment}</Typography>
      ) : null}
      <Box sx={{ display: 'flex', gap: 4, mb: insuranceCase?.labels?.length ? 4 : 0 }}>
        {formattedDateOfBirth ? (
          <Tooltip title={t('labels.claimantDateOfBirth')}>
            <Box display="flex" alignItems="center">
              <Icon sx={{ mr: 2 }} icon="event" />
              <Typography variant="subtitle2">{t('labels.dob')}&nbsp;{formattedDateOfBirth}</Typography>
            </Box>
          </Tooltip>
        ) : null}
        {insuranceCase.claimNumber ? (
          <Tooltip title={t('labels.claimNumber')}>
            <Box display="flex" alignItems="center">
              <Icon sx={{ mr: 2 }} icon="case_number" />
              <Typography variant="subtitle2">{insuranceCase.claimNumber}</Typography>
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      <Divider sx={{ borderColor: 'rgba(33,33,33,0.08)', my: 4 }} />
      <Box sx={{ display: 'flex', gap: 4 }}>
        {longCreatedOn ? (
          <InfoBlock label={t('labels.createdAt')} value={longCreatedOn} />
        ) : null}
        {longBatchCreatedOn ? (
          <InfoBlock label={t('pages.staffPages.casesPage.lastMaterialUploadedAt')} value={longBatchCreatedOn} />
        ) : null}
      </Box>
    </Paper>
  )
};