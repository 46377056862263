import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { AppBar, Box, Theme, Toolbar, Typography } from '@mui/material';
// Icons
import {
  SupportAgent as SupportAgentIcon,
  PriorityHigh as PriorityHighIcon
} from '@mui/icons-material';
// Components
import Brand from 'app/components/Brand';
import { Fab } from 'app/components/Mui';

const Fallback = () => {
  const { t } = useTranslation('common');

  return (
    <Fragment>
      <AppBar>
        <Toolbar>
          <Brand />
        </Toolbar>
      </AppBar>
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <PriorityHighIcon sx={{
          color: (theme:Theme) => theme.palette.error.main,
          fontSize: 128
        }} />
        <Typography variant="h6">{t('components.fallback.title')}</Typography>
        <Typography variant="body1">{t('components.fallback.description')}</Typography>
      </Box>
      <Fab
        name="Support"
        aria-label="Support button"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        component="a"
        // @ts-ignore
        href="https://www.casechronology.com/#contact-us"
        color="primary"
      ><SupportAgentIcon /></Fab>
    </Fragment>
  );
}

export default Fallback;

