import { FC } from 'react';
// Mui
import { Box, Typography } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const PasswordHints:FC = () => {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ pt: 2 }}>
      <Typography display="block" variant="caption" component="small">{t('components.passwordHints.hint1')}</Typography>
      <Typography display="block" variant="caption" component="small">{t('components.passwordHints.hint2')}</Typography>
      <Typography display="block" variant="caption" component="small">{t('components.passwordHints.hint3')}</Typography>
    </Box>
  )
}

export default PasswordHints;
