import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Mui
import { MenuItem } from '@mui/material';
// Component
import { Select } from 'app/components/Mui';
// Constants
import { FONT_FAMILIES, FONT_SIZES } from 'app/App.constants';

const ReportTabContent:FC = () => {
  const { t } = useTranslation('common');

  const { control } = useFormContext();

  return (
    <Fragment>
      <Controller
        control={control} name="settings.reportPreferences.fontFamily"
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.fontFamily')}
            options={FONT_FAMILIES.map((fontFamily) => ({
              id: fontFamily,
              name: fontFamily
            }))}
            renderOptions={(option, index) => (
              <MenuItem
                key={`option-item-${index}-${option.id}`}
                value={option.id}
                sx={{
                  fontFamily: option.id
                }}
              >{option.name}</MenuItem>
            )}
          />
        )}
      />
      {/* Font size */}
      <Controller
        control={control} name="settings.reportPreferences.fontSize"
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.fontSize')}
            options={FONT_SIZES.map((fontSize) => ({
              id: fontSize.toString(),
              name: `${fontSize}pt`
            }))}
          />
        )}
      />
    </Fragment>
  )
}

export default ReportTabContent;
