import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.episode.create'),
  Update: i18n.t('notifications.episode.update'),
  Delete: i18n.t('notifications.episode.delete')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.episode.create');
  Messages.Update = i18n.t('notifications.episode.update');
  Messages.Delete = i18n.t('notifications.episode.delete');
});