import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IAnnotation } from 'app/models/LabellingTool';
// Async
import { getAnnotations, createAnnotation, deleteAnnotation } from './LabellingToolAnnotations.async';

interface IState {
  annotations: IAnnotation[] | null;
  loading: boolean;
};

const initialState:IState = {
  annotations: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.LabellingToolJobs,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get annotations
    builder.addCase(getAnnotations.pending, (state) => {
      state.annotations = null;
    })
    builder.addCase(getAnnotations.fulfilled, (state, action:PayloadAction<IAnnotation[]>) => {
      state.annotations = action.payload;
    })
    // Create annotation
    builder.addCase(createAnnotation.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(createAnnotation.fulfilled, (state, action:PayloadAction<IAnnotation>) => {
      if ( state.annotations ){
        state.annotations = [...state.annotations, action.payload];
      }
    })
    // Delete annotation
    builder.addCase(deleteAnnotation.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(deleteAnnotation.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.annotations ){
        state.annotations = state.annotations.filter((annotation:IAnnotation) => annotation.id !== action.payload)
      }
    })
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const LabellingToolJobsActions = slice.actions;

export default slice.reducer;