import { FC, useState, useEffect } from 'react';
// Models
import IOption from 'app/models/Option';
import IInsuranceCase from 'app/models/Case';
import IConversation, { IMember } from 'app/models/Conversation';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getAccountUsers } from 'app/store/Clients/Clients.async';
import { getTeam } from 'app/store/Teams/Teams.async';
import { kickMember, inviteMember } from 'app/store/Conversations/Conversations.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectLoading, selectMembersAsOptions } from 'app/store/Conversations/Conversations.selectors';
// Mui
import { Box, Chip, Autocomplete } from '@mui/material';
// Lab
import { Message } from 'app/components/Utilities';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  conversation: IConversation;
};

const ConversationMembersDialog:FC<Props> = ({
  // Props
  open, onClose, conversation
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase)
  const loading = useAppSelector(selectLoading);
  const membersAsOptions:IOption[] = useAppSelector(selectMembersAsOptions);

  const [ selectedMember, setSelectedMember ] = useState<IOption | null>(null);

  useEffect(() => {
    if ( insuranceCase ){
      dispatch(getTeam(insuranceCase.team.id));
      dispatch(getAccountUsers(insuranceCase.clientAccountId));
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (_:any, newValue:IOption | null) => {
    setSelectedMember(newValue)
  }

  const handleInviteMember = async () => {
    if ( !selectedMember ) return;

    try {
      await dispatch(inviteMember({
        conversationId: conversation.id,
        data: { user: selectedMember }
      })).unwrap();
      setSelectedMember(null);
    } catch(error){}
  }

  const handleKickMember = (memberId:number) => () => {
    // Ask to rename `userId` to `memberId`
    dispatch(kickMember({
      conversationId: conversation.id,
      data: { userId: memberId }
    }))
  } 

  const actions = (
    <Button
      name="Close members dialog"
      onClick={onClose}
    >{t('labels.close')}</Button>
  );

  const memberName = (member:IMember | IOption) => {
    if ( (member.id as string).includes('clients') ) return `${member.name} (${t('pages.staffPages.caseDetailsPage.client')})`;
    return member.name;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title="Members"
      actions={actions}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 4
      }}>
        <Box sx={{ flexGrow: 1 }}>
          <Autocomplete
            value={selectedMember}
            options={membersAsOptions}
            onChange={handleChange}
            renderInput={(params) => (
              <Input
                {...params}
                label={t('labels.users')} name="members"
                margin="none"
                variant="outlined"
                fullWidth
              />
            )}
            getOptionLabel={(option:IOption) => memberName(option)}
            isOptionEqualToValue={(option, value) => option.id === value.id || true}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>{memberName(option)}</li>
            )}
          />
        </Box>
        <LoadingButton
          name="Invite member"
          loading={loading}
          onClick={handleInviteMember}
          color="primary"
          variant="contained"
        >{t('labels.invite')}</LoadingButton>
      </Box>

      {conversation?.members && conversation.members.length ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 4 }}>
          {conversation.members.map((member:any) => (
            <Chip
              key={`member-item-${member.id}`}
              label={memberName(member)}
              onDelete={conversation.createdBy !== member.id ? handleKickMember(member.id) : undefined}
            />
          ))}
        </Box>
      ) : (
        <Message text={t('pages.staffPages.caseDetailsPage.noMembers')} />
      )}
    </Dialog>
  )
}

export default ConversationMembersDialog;
