import React from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Selectors
import { selectSystemMessages } from 'app/store/AppUINotifications/AppUINotifications.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Alert } from '@mui/material';
// Icons
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';

const SystemNotifications = () => {
  const dispatch = useDispatch();

  const myUser:any = useSelector(selectMyUser);
  const messages:any[] = useSelector(selectSystemMessages);

  const [ selectedMessage, setSelectedMessage ] = React.useState<any>(null);

  React.useEffect(() => {
    setSelectedMessage(messages.length ? messages[0] : null);
    // eslint-disable-next-line
  }, [messages]);

  const handleCloseMessage = () => {
    if ( selectedMessage ) dispatch(AppUiNotificationsActions.removeSystemMessage(selectedMessage.id));
  }

  if ( !messages.length || !selectedMessage ) return null;
  if ( !myUser || !myUser.notificationPreferences.push ) return null;
  return (
    <Alert
      className="system-alert"
      color="warning"
      icon={false}
      action={
        selectedMessage.type !== 'version' ? (
          <IconButton
            name="Close alert"
            color="inherit"
            onClick={handleCloseMessage}
            size="small"
          ><CloseIcon fontSize="inherit" /></IconButton>
        ) : null
      }
    ><div dangerouslySetInnerHTML={{ __html: selectedMessage.message }} /></Alert>
  )
}

export default SystemNotifications;
