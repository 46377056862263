import { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// Models
import IReportPreset from 'app/models/ReportPreset';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getGlobalTemplates } from 'app/store/Templates/Templates.async';
import { createReport } from 'app/store/CaseReports/CaseReports.async';
import { getTeams } from 'app/store/Teams/Teams.async';
import { getPresets, createPreset, updatePreset } from 'app/store/ReportPresets/ReportPresets.async';
// Actions
import { TemplatesActions } from 'app/store/Templates/Templates.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import CaseInfoBar from 'app/components/CaseInfoBar';
// Context
import ReportPresetProvider from 'app/context/ReportPreset.context';
// 
import DialogTitle from './DialogTitle';
import DialogContainer from './DialogContainer';

interface IFormData {
  name: string;
  headerTemplateId: string;
  footerTemplateId: string;
  templateIds: number[];
  watermark: {
    text: string;
    tiled: boolean;
  };
  teamIds: any;
}

type Props = {
  open: boolean;
  onClose: () => void;
  caseId?: number;
  preset?: IReportPreset;
}

const ReportPresetFormDialog:FC<Props> = ({
  // Props
  open, onClose, caseId, preset
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase);

  const methods = useForm<IFormData>({
    defaultValues: {
      name: preset?.name || '',
      headerTemplateId: preset?.headerTemplateId ? preset?.headerTemplateId.toString() : '0',
      footerTemplateId: preset?.footerTemplateId ? preset?.footerTemplateId.toString() : '0',
      templateIds: preset?.templateIds || [],
      watermark: {
        text: 'CaseChronology.com',
        tiled: true
      },
      teamIds: preset?.teamIds || []
    }
  });

  const { handleSubmit, register } = methods;

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { templateIds, headerTemplateId, footerTemplateId, watermark, teamIds, ...otherData } = data;

    if ( !templateIds || !templateIds.length ) return;

    const nextData:any = { ...otherData, templateIds };
    if ( headerTemplateId && headerTemplateId !== '0' ) nextData['headerTemplateId'] = Number(headerTemplateId);
    if ( footerTemplateId && footerTemplateId !== '0' ) nextData['footerTemplateId'] = Number(footerTemplateId);
    if ( teamIds && teamIds.length ) nextData['teamIds'] = teamIds;

    if ( caseId ){
      nextData['insuranceCaseId'] = caseId;
      if ( watermark.text ) nextData['watermark'] = watermark;
      asyncCreateReport(nextData);
    } else {
      if ( preset ){
        asyncUpdatePreset(preset.id, nextData);
      } else {
        asyncCreatePreset(nextData);
      } 
    }
  });

  const asyncCreateReport = async (data:any) => {
    try {
      await dispatch(createReport(data)).unwrap();
      onClose();
    } catch(error){}
  }

  const asyncCreatePreset = async (data:any) => {
    try {
      await dispatch(createPreset(data)).unwrap();
      onClose();
    } catch(error){}
  }

  const asyncUpdatePreset = async (presetId:number, data:any) => {
    try {
      await dispatch(updatePreset({ presetId, data })).unwrap();
      onClose();
    } catch(error){}
  }

  useEffect(() => {
    dispatch(getGlobalTemplates({}));

    // By `caseId` we check is report global or not
    if ( caseId ){
      const teamId = insuranceCase ? insuranceCase.team.id : '';
      dispatch(getPresets({ teamId }));
    } else {
      dispatch(getTeams({}));
    }

    return () => {
      dispatch(TemplatesActions.setInitialField('globalTemplates'));
      dispatch(TemplatesActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{ sx: { border: 0 } }}
      ContentProps={{ sx: { border: 0, p: 0 } }}
    >
      <FormProvider {...methods}>
        <ReportPresetProvider caseId={caseId}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'relative'
            }}
            component="form"
            onSubmit={onSubmit}
            noValidate
          >
            <input {...register('templateIds') as any} type="hidden" />

            <DialogTitle onClose={onClose} caseId={caseId} preset={preset} />
            <CaseInfoBar />
            <DialogContainer />
          </Box>
        </ReportPresetProvider>
      </FormProvider>
    </Dialog>
  )
}

export default ReportPresetFormDialog;
