import Logo from 'assets/images/logo.svg';

import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
// Mui
import { Theme, Box, Container, CircularProgress, Typography, SxProps, Tooltip } from '@mui/material';
// Icons
import {
  Help as HelpIcon,
  Lock as LockIcon,
  SearchOff as SearchOffIcon
} from '@mui/icons-material';
// Components
import { Button } from './Mui';

export const AppLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0, left: 0,
        width: '100%',
        height: '100%',
        bgcolor: 'white',
        zIndex: 99
      }}
    >
      <Box>
        <img src={Logo} alt={`Case Chronology - Case Portal`} />
        <Loader />
      </Box>
    </Box>
  )
};

export const Loader:FC = () => {
  return (
    <Box sx={{ py: 4, textAlign: 'center' }}>
      <CircularProgress color="primary" thickness={4} />
    </Box>
  )
};

// -----------------
// Message
// -----------------
type MessageProps = {
  text: string;
  icon?: ReactNode;
  maxWidth?: string;
};

export const Message:FC<MessageProps> = ({
  // Props
  text, icon, maxWidth = '50%'
}) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
      {typeof icon !== 'undefined' ? (
        <Box sx={{ mb: 2 }}>{icon}</Box>
      ) : null}
      <Typography sx={{ color: 'rgba(0,0,0,0.54)', maxWidth, textAlign: 'center' }} variant="body1">{text}</Typography>
    </Box>
  )
}

// -----------------
// Error message
// -----------------
type MessageErrorProps = {
  status: number;
  title: string;
  text: string;
  backTo?: string;
  backToLabel?: string;
};

export const MessageError:FC<MessageErrorProps> = ({
  // Props
  status, title, text, backTo, backToLabel = 'Return back',
}) => {
  const accessDenied = [401, 403].includes(status);
  // const notFound = status === 404;
  const Icon = accessDenied ? LockIcon : SearchOffIcon;

  return (
    <Container
      maxWidth="sm"
      sx={{
        py: 4,
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          width: 128,
          height: 128,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: '50%',
          bgcolor: 'white',
          mx: 'auto',
          mb: 4
        }}
      >
        <Icon color="disabled" fontSize="large" />
      </Box>
      <Typography sx={{ mb: 4 }} variant="h5">
        {title}
      </Typography>
      <Typography sx={{ mb: 4 }} variant="body1">{text}</Typography>
      {backTo ? (
        <Button
          name={backToLabel}
          component={Link}
          to={backTo}
          variant="contained"
          color="primary"
        >{backToLabel}</Button>
      ) : null}
    </Container>
  )
}

type InfoBlockProps = {
  label: string;
  value?: JSX.Element | string | number;
  direction?: 'row' | 'column';
  size?: 'small' | 'default' | 'large';
}

export const InfoBlock:FC<InfoBlockProps> = ({ label, value, direction = 'column', size = 'default' }) => {
  const fontSize = size === 'large' ? 14 : 12;

  return (
    <Box sx={{
      display: 'flex', flexDirection: direction,
      gap: direction === 'column' ? 0 : 1,
      alignItems: direction === 'row' ? 'center' : 'flex-start'
    }}>
      <Typography
        sx={{ display: 'block', color: 'rgba(0,0,0,0.55)', fontSize, fontWeight: 500, whiteSpace: 'nowrap' }}
        variant="caption"
      >{label}</Typography>
      {value ? (
        typeof value === 'string' || typeof value === 'number' ? (
          <Typography
            sx={{ display: 'block', color: 'rgba(0,0,0,0.75)', fontSize, fontWeight: 700 }}
            variant="caption"
          >{value}</Typography>
        ) : value
      ) : null}
    </Box>
  )
}

export const Bull:FC = () => {
  return (
    <Box component="span" sx={{ display: 'inline-block', mx: 2, lineHeight: 1 }}>&bull;</Box>
  )
}


type OutlineBlockProps = {
  label?: string;
  helperText?: string;
  sx?: SxProps<Theme>;
  children: JSX.Element;
}

export const OutlineBlock:FC<OutlineBlockProps> = ({
  // Props
  label = '', helperText = '', sx = {}, children
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        pt: 4,
        mt: 1,
        ...sx
      }}
    >
      {label ? (
        <Typography
          sx={{
            position: 'absolute',
            top: 0, left: 0,
            bgcolor: 'white',
            fontWeight: 700,
            pr: 2,
            mt: -3
          }}
          variant="body2"
        >
          {label}
          {helperText ? (
            <Tooltip sx={{ ml: 2 }} title={helperText}>
              <HelpIcon fontSize="small" />
            </Tooltip>
          ) : null}
        </Typography>
      ) : null}
      {children}
    </Box>
  )
}

export const MailTo:FC<{email: string}> = ({ email }) => {
  return (
    <Typography
      sx={{
        display: 'block', color: 'rgba(0,0,0,0.75)', fontSize: '14px',
        fontWeight: 700, textDecoration: 'none', transition: 'color 0.3s',
        '&:hover': { color: (theme:Theme) => `${theme.palette.primary.main}` }
      }}
      variant="caption"
      component="a" href={`mailto:${email}`}
    >{email}</Typography>
  )
}
