import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
// Models
import { AppDispatch } from 'app/store';
import { ICurrentAccount } from 'app/models/Account';
// Async
import { getCurrentAccount } from 'app/store/Accounts/Accounts.async';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Services
import Mixpanel from "app/services/Mixpanel.service";

const AccountsMiddleware:Middleware = ({ dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type === getCurrentAccount.fulfilled.type ){
    const currentAccount = payload as ICurrentAccount;

    Mixpanel.registerOnceAccountId(currentAccount.id);

    if ( currentAccount.status === 'suspended' ){
      dispatch(AppUiNotificationsActions.addSystemMessage({
        type: 'message',
        message: 'Your account is suspended. You can’t upload new records.'
      }));
    }
  }

  return next(action);
}

export default AccountsMiddleware;
