import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, Typography } from '@mui/material';
// Icons
import { Lock as LockIcon } from '@mui/icons-material';
// Components
import { Button } from 'app/components/Mui';

type Props = {
  onClose: () => void;
}

const AccessDeniedContent:FC<Props> = ({
  // Props
  onClose
}) => {
  const { t } = useTranslation('common');

  return (
    <Fragment>
      <Box
        sx={{
          width: 128,
          height: 128,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: '50%',
          bgcolor: 'white',
          mx: 'auto',
          mb: 4
        }}
      ><LockIcon color="disabled" fontSize="large" /></Box>
      <Typography sx={{ mb: 4 }} variant="h5">{t('notifications.requests.accessDenied.title')}</Typography>
      <Typography sx={{ mb: 4 }} variant="body1">{t('notifications.requests.accessDenied.content')}</Typography>
      <Button
        name="Home"
        component={Link}
        to="/"
        onClick={onClose}
        variant="contained"
        color="primary"
      >Home</Button>
    </Fragment>
  )
}

export default AccessDeniedContent;
