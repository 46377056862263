import { FC, useState } from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selectors
import { selectCasesParams, selectPendingCasesLength } from 'app/store/Cases/Cases.selectors';
// Mui
import { Theme, Toolbar, Grid, Typography, Tabs, Badge } from '@mui/material';
// Components
import { Tab } from 'app/components/Mui';

type Props = {
  currentStatus: CaseStatuses;
}

const CasesToolbar:FC<Props> = ({
  // Props
  currentStatus
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const params:any = useAppSelector(selectCasesParams);
  const pendingCasesLength:number = useAppSelector(selectPendingCasesLength);

  const [ tab, setTab ] = useState<CaseStatuses>(currentStatus);

  const handleChange = (_:any, nextTab:CaseStatuses) => {
    const nextParams = {
      ...params,
      offset: 0,
      status: nextTab,
      'invoice.status': 'all'
    };
    setTab(nextTab);
    dispatch(CasesActions.setParams(nextParams));
    dispatch(push({ search: `tab=${nextTab}` }));
  }

  const typeLabels:Record<CaseStatuses, string> = {
    [CaseStatuses.Archived]: t('pages.staffPages.casesPage.archived'),
    [CaseStatuses.CreationInProgress]: t('pages.staffPages.casesPage.creationInProgress'),
    [CaseStatuses.Open]: t('labels.open'),
    [CaseStatuses.Pending]: t('pages.staffPages.casesPage.pending'),
    [CaseStatuses.Rejected]: t('pages.staffPages.casesPage.rejected'),
    [CaseStatuses.Waiting]: t('pages.staffPages.casesPage.waitingForRecords')
  }

  return (
    <Toolbar variant="dense">
      <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid item xs={2}>
          <Typography variant="h6">{t('labels.cases')}</Typography>
        </Grid>
        <Grid item>
          <Tabs value={tab} onChange={handleChange}>
            {(Object.values(CaseStatuses) as CaseStatuses[]).map((type) => {
              if ( type === CaseStatuses.CreationInProgress ) return null;
              return (
                <Tab
                  name={type}
                  key={`case-tab-item-${type}`}
                  sx={{
                    '&:hover': {
                      bgcolor: (theme:Theme) => theme.palette.primary.light
                    }
                  }}
                  value={type}
                  label={
                    type === CaseStatuses.Pending
                      ? 
                        <Badge badgeContent={pendingCasesLength} color="primary">
                          &nbsp;&nbsp;{typeLabels[type]}&nbsp;&nbsp;
                        </Badge>
                      : typeLabels[type]
                  }
                />
              )
            })}
          </Tabs>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Toolbar>
  )
}

export default CasesToolbar;
