import { FC } from 'react';
import { forceVisible } from 'react-lazyload';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectFilter } from 'app/store/page/page.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import AutocompleteTypes from 'app/components/AutocompleteTypes';

const EpisodeTypes:FC = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const filter:any = useSelector(selectFilter);

  const handleChange = (nextValue:string[]) => {
    dispatch(PageActions.setFilter('types', nextValue));
    forceVisible();
  }

  return (
    <Box sx={{ width: 320 }}>
      <AutocompleteTypes
        value={filter.types}
        onChange={handleChange}
  
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default EpisodeTypes;
