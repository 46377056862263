import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import UserPermissions from 'app/types/Permissions';
// Models
import { RootState } from 'app/store';
import IEpisodeFact from 'app/models/EpisodeFact';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUserHasPermission } from 'app/store/Users/Users.selectors';
import { selectEpisodeFacts } from 'app/store/EpisodeFacts/EpisodeFacts.selectors';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import {
  TextSnippet as TextSnippetIcon,
  Edit as EditIcon
} from '@mui/icons-material';
// Dialogs
import EpisodeFactsDialog from 'app/dialogs/EpisodeFactsDialog';
// Components
import { IconButton } from 'app/components/Mui';
import Facts from 'app/components/Facts';
// Hooks
import useToggle from 'app/hooks/useToggle';
import { useFormContext } from 'react-hook-form';

type Props = {
  isDisabled: boolean;
}

const EpisodeFormFacts:FC<Props> = ({
  // Props
  isDisabled
}) => {
  const { t } = useTranslation('common');

  const { watch } = useFormContext();

  // State
  const myUserCPTEnabled = useAppSelector((state:RootState) => selectMyUserHasPermission(state, {
    permission: UserPermissions.HcpcsCodes
  }));
  const facts:IEpisodeFact[] | null = useAppSelector(selectEpisodeFacts);

  const { open, toggle } = useToggle();

  const watchDate = watch('date');

  if ( !myUserCPTEnabled ) return null;
  if ( !facts ) return null;
  if ( isDisabled && !facts.length ) return null;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
        <Tooltip title={t('labels.cptCodes')}>
          <TextSnippetIcon sx={{ color: 'rgba(0,0,0,0.54)', mr: 2 }} />
        </Tooltip>
        <Facts id="episodeFormFacts" facts={facts} />
        {!isDisabled ? (
          <Tooltip title={t('components.episodeFacts.manageCptCodes')}>
            <IconButton
              name="Edit episode facts"
              onClick={toggle}
              size="small"
            ><EditIcon /></IconButton>
          </Tooltip>
        ) : null}
      </Box>
      {open ? (
        <EpisodeFactsDialog
          open={open}
          onClose={toggle}
          date={watchDate}
        />
      ) : null}
    </Fragment>
  )
}

export default EpisodeFormFacts;
