import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import IBillingService from 'app/models/BillingService';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createService, updateService } from 'app/store/BillingServices/BillingServices.async';
// Selectors
import { selectLoading } from 'app/store/BillingServices/BillingServices.selectors';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton, Input, Select } from 'app/components/Mui';
// Utility
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
  description: string;
  unit: 'case' | 'user' | 'page' | 'minute';
  quickBooksId: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  service?: IBillingService;
};

const ServiceFormDialog:FC<Props> = ({
  // Props
  open, onClose, service
}) => {
  const { t } = useTranslation('common');

  const label = service?.id ? t('labels.update') : t('labels.create');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors } } = useForm<IFormData>({
    defaultValues: {
      name: service?.name || '',
      description: service?.description || '',
      unit: service?.unit || 'page',
      quickBooksId: service?.quickBooksId || ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    if ( service?.id ){
      asyncUpdateService({ serviceId: service.id, data });
    } else {
      asyncCreateService(data);
    }
  });

  const asyncUpdateService = async (nextData:any) => {
    try {
      await dispatch(updateService(nextData)).unwrap();
      onClose();
    } catch(e) {}
  }

  const asyncCreateService = async (data:IFormData) => {
    try {
      await dispatch(createService(data)).unwrap();
      onClose();
    } catch(e) {}
  }

  const isControlDisabled = service ? !service.additional : false;

  const actions = (
    <Fragment>
      <Button
        name="Cancel service dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`${label} service dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={`${label} ${t('labels.service')}`}
      actions={actions}
    >
      <form noValidate>
        {/* Name */}
        <Controller
          control={control} name="name"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.name')}
              error={Boolean(errors.name)}
              helperText={errors.name?.message || ''}
              required
            />
          )}
        />
        {/* Description */}
        <Controller
          control={control} name="description"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.description')}
              error={Boolean(errors.description)}
              helperText={errors.description?.message || ''}
              required
              multiline
              rows={5}
            />
          )}
        />
        {/* Unit */}
        <Controller
          control={control} name="unit"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('pages.accountManagerPages.billingServicesPage.unit')}
              options={[
                { id: 'case', name: 'Case' },
                { id: 'user', name: 'User' },
                { id: 'page', name: 'Page' },
                { id: 'minute', name: 'Minute' }
              ]}
              error={Boolean(errors.unit)}
              helperText={errors.unit?.message || ''}
              required
              disabled={isControlDisabled}
            />
          )}
        />
        {/* QuickBookcsId */}
        <Controller
          control={control} name="quickBooksId"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.quickbooksId')}
              error={Boolean(errors.quickBooksId)}
              helperText={errors.quickBooksId?.message || ''}
              required
            />
          )}
        />
      </form>
    </Dialog>
  );
}

export default ServiceFormDialog;
