// Types
import Reducers from 'app/types/Reducers';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./Teams.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/teams';

// Get teams
export const getTeams = asyncThunkHandler(
  `${Reducers.Teams}/Get teams`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);
// Get team
export const getTeam = asyncThunkHandler(
  `${Reducers.Teams}/Get team`,
  async (teamId:number) => {
    const response:Response = await $get(`${_url}/${teamId}`);
    return response.json();
  }
);
// Create team
export const createTeam = asyncThunkHandler(
  `${Reducers.Teams}/Create team`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);
// Update team
export const updateTeam = asyncThunkHandler(
  `${Reducers.Teams}/Update team`,
  async ({ teamId, data }:{ teamId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${teamId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);
// Delete team
export const deleteTeam = asyncThunkHandler(
  `${Reducers.Teams}/Delete team`,
  async (teamId:number, { fulfillWithValue, dispatch }) => {
    await $delete(`${_url}/${teamId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(teamId);
  }
);