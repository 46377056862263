import { FC, Fragment, useMemo } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectPresenterTags } from 'app/store/Presenter/Presenter.selectors';
// Mui
import { Theme, Box } from '@mui/material';
// Components
import { Chip } from 'app/components/Mui';
// Constants
import { NO_TAG_LABEL } from 'app/App.constants';

type Props = {
  id: string | number;
  onClick?: (nextTag:string | null) => void;
  activeTags?: (string | null)[];
  noTagVisible?: boolean;
  isDisabled?: boolean;
}

const TagPicker:FC<Props> = ({
  // Props
  id, onClick, activeTags = [], noTagVisible = false, isDisabled = false
}) => {
  const onClickUndefined = typeof onClick === 'undefined';
  // State
  const currentAccount:any = useAppSelector(selectCurrentAccount);
  const presenterTags = useAppSelector(selectPresenterTags);

  const tags = useMemo(() => {
    if ( onClickUndefined ) return activeTags;
    if ( !currentAccount || !currentAccount.tags ) return presenterTags;
    return currentAccount.tags;
  }, [currentAccount, activeTags, onClickUndefined, presenterTags]);

  const handleClick = (nextTag:string | null) => () => {
    if ( onClickUndefined ) return false;
    onClick(nextTag);
  }

  const isTagActive = (tag:string | null) => {
    return activeTags.includes(tag);
  }

  const sx = (tag:string | null) => ({
    fontWeight: 500,
    bgcolor: (theme:Theme) => isTagActive(tag) ? theme.palette.primary.light : 'rgba(0,0,0,0.08)',
    color: (theme:Theme) => isTagActive(tag) ? theme.palette.primary.main : 'rgba(0,0,0,0.87)',
    '&:hover': {
      bgcolor: (theme:Theme) => isTagActive(tag) ? theme.palette.primary.light : 'rgba(0,0,0,0.12)'
    }
  });

  if ( !tags || !tags.length ) return null;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {noTagVisible ? (
          <Chip
            name={NO_TAG_LABEL}
            active={isTagActive(null)}
            sx={sx(null)}
            label={NO_TAG_LABEL}
            onClick={handleClick(null)}
          />
        ) : null}
        {tags.map((tag:string, index:number) => (
          <Chip
            name={tag}
            active={isTagActive(tag)}
            key={`${id}-tag-item-${tag}-${index}`}
            sx={sx(tag)}
            label={tag}
            onClick={handleClick(tag)}
            disabled={isDisabled}
          />
        ))}
      </Box>
    </Fragment>
  )
}

export default TagPicker;