import Quill from "quill";
// Constants
import { FONT_SIZES } from "app/App.constants";

// Font size
const Size = Quill.import('attributors/style/size');

Size.whitelist = FONT_SIZES.map((fontSize:number) => `${fontSize}pt`);

Quill.register(Size, true);
