import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { setCloudfrontCookies } from 'app/store/Content/Сontent.async';
import { getTemplates } from 'app/store/Templates/Templates.async';
// Actions
import { TemplatesActions } from 'app/store/Templates/Templates.slice';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import TemplatesFilter from './TemplatesFilter';
import TemplatesList from './TemplatesList';
import TemplatesFab from './TemplatesFab';
// i18next
import { useTranslation } from 'react-i18next';

const TemplatesPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCloudfrontCookies({}));
    dispatch(getTemplates({}));

    return () => {
      dispatch(TemplatesActions.setInitialField('templates'));
      dispatch(TemplatesActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.adminPages.templatesPage.templates')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 22 }} maxWidth="sm">
          <TemplatesFilter />
          <TemplatesList />
        </Container>
        <TemplatesFab />
      </Box>
    </Fragment>
  )
}

export default TemplatesPage;
