import { FC, Fragment } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
// Utilities
import { Loader, OutlineBlock } from 'app/components/Utilities';
// Utilities
import DocumentItem from './DocumentItem';
// i18next
import { useTranslation } from 'react-i18next';

const DocumentList:FC= () => {
  const { t } = useTranslation('common');
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'documentPlacements'
  });

  if ( !fields || !fields.length ) return <Loader />;
  return (
    <OutlineBlock sx={{ mt: 8 }} label={t('pages.staffPages.casesPage.sharedDocuments')}>
      <Fragment>
        {fields.map((field:any, index:number) => (
          <DocumentItem
            key={`document item ${field.id}`}
            field={field}
            index={index}
          />
        ))}
      </Fragment>
    </OutlineBlock>
  )
};

export default DocumentList;
