import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Async
import {
  getCurrentAccount, updateCurrentAccount,
  getCurrentAccountBilling
} from './Accounts.async';

interface IState {
  currentAccount: any;
  currentAccountBilling: any;
  loading: boolean;
};

const initialState:IState = {
  currentAccount: null,
  currentAccountBilling: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.Accounts,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get current account
    builder.addCase(getCurrentAccount.pending, (state) => {
      state.currentAccount = null;
    });
    builder.addCase(getCurrentAccount.fulfilled, (state, action:PayloadAction<any>) => {
      state.currentAccount = action.payload;
    });
    // Update current account
    builder.addCase(updateCurrentAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCurrentAccount.fulfilled, (state, action:PayloadAction<any>) => {
      state.currentAccount = action.payload;
    });
    // Get current account billing
    builder.addCase(getCurrentAccountBilling.pending, (state) => {
      state.currentAccountBilling = null;
    });
    builder.addCase(getCurrentAccountBilling.fulfilled, (state, action:PayloadAction<any>) => {
      state.currentAccountBilling = action.payload;
    });
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const AccountsActions = slice.actions;

export default slice.reducer;