import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getTermsAndConditions } from 'app/store/Terms/Terms.async';
// Selectors
import { selectTermsAndConditions } from 'app/store/Terms/Terms.selectors';
// Mui
import {
  Box, Container, Paper, Toolbar,
  List, ListItem, ListItemText, ListItemSecondaryAction,
  Typography, Tooltip
} from '@mui/material';
// Icons
import { Visibility as VisibilityIcon } from '@mui/icons-material';
// Components
import Header from 'app/components/Header';
import DataLoading from 'app/components/DataLoading';
import { IconButton } from 'app/components/Mui';
// Dialogs
import TermsPreviewDialog from 'app/dialogs/TermsPreviewDialog';

const MyTermsAndConditions = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const termsAndConditions = useAppSelector(selectTermsAndConditions);

  const [ content, setContent ] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getTermsAndConditions({ ackBy: "self", owners: "self,platform" }));
    // eslint-disable-next-line
  }, []);

  const handleClick = (c:string) => () => {
    setContent(c);
  }

  const handleClose = () => {
    setContent(null);
  }

  const open = Boolean(content);

  return (
    <Fragment>
      <Header />
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.myTermsAndConditionsPage.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="xs">
          <DataLoading
            data={termsAndConditions}
            text={t('pages.adminPages.termsPage.noTermsAndConditions')}
          >
            {(termsAndConditions) => (
              <Paper variant="outlined">
                <List>
                  {termsAndConditions.map((terms) => (
                    <ListItem key={`terms and conditions item ${terms.id}`}>
                      <ListItemText primary={terms.name} />
                      <ListItemSecondaryAction>
                        <Tooltip title={'Preview'}>
                          <IconButton
                            name={`Preview terms and conditions ${terms.id}`}
                            onClick={handleClick(terms.content)}
                          ><VisibilityIcon /></IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </DataLoading>
        </Container>
      </Box>
      {open ? (
        <TermsPreviewDialog
          open={open}
          onClose={handleClose}
          content={content as string}
        />
      ) : null}
    </Fragment>
  )
}

export default MyTermsAndConditions;
