import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IReport from 'app/models/Report';
// Async
import {
  getReports, createReport, updateReport, deleteReport,
  shareReport
} from './CaseReports.async';

interface IState {
  reports: IReport[] | null;
  loading: boolean;
};

const initialState:IState = {
  reports: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.CaseReports,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get reports
    builder.addCase(getReports.pending, (state) => {
      state.reports = null;
    })
    builder.addCase(getReports.fulfilled, (state, action:PayloadAction<IReport[]>) => {
      state.reports = action.payload;
    })
    // Create report
    builder.addCase(createReport.pending, (state) => {
      state.loading = true
    });
    builder.addCase(createReport.fulfilled, (state, action:PayloadAction<IReport>) => {
      if ( state.reports ){
        state.reports.push(action.payload);
      }
    });
    // Update report
    builder.addCase(updateReport.pending, (state) => {
      state.loading = true
    });
    builder.addCase(updateReport.fulfilled, (state, action:PayloadAction<IReport>) => {
      if ( state.reports ){
        state.reports = state.reports.map((report:IReport) => {
          if ( report.id === action.payload.id ) return action.payload;
          return report;
        });
      }
    });
    // Delete report
    builder.addCase(deleteReport.pending, (state) => {
      state.loading = true
    });
    builder.addCase(deleteReport.fulfilled, (state, action:{ payload:number }) => {
      if ( state.reports ){
        state.reports = state.reports.filter((report:IReport) => report.id !== action.payload);
      }
    });
    // Share report
    builder.addCase(shareReport.pending, (state) => {
      state.loading = true
    });
    builder.addCase(shareReport.fulfilled, (state, action:PayloadAction<IReport>) => {
      if ( state.reports ){
        state.reports = state.reports.map((report:IReport) => {
          if ( report.id === action.payload.id ) return action.payload;
          return report;
        });
      }
    });
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const CaseReportsActions = slice.actions;

export default slice.reducer;