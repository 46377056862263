import { ChangeEvent, FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Dispatch
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSelectedEpisodeId } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import Labels from 'app/components/Labels';
import { Input, Button } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (nextLabels:string[]) => void;
  labels?: string[];
};

const EpisodeFormLabelsDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm, labels = []
}) => {
  const { t } = useTranslation('common');

  // State
  const selectedEpisodeId = useAppSelector(selectSelectedEpisodeId);

  const [ name, setName ] = useState('');
  const [ stateLabels, setStateLabels ] = useState<string[]>(labels);

  const modifiedLabels = useMemo(() => {
    return stateLabels.map((label:string) => ({ name: label, color: 'initial' }))
  }, [stateLabels]);

  const handleClick = () => {
    onConfirm(stateLabels);
    onClose()
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleAdd = () => {
    if ( !name ) return;

    setStateLabels((prevState) => [...prevState, name])
    setName('');
  }

  const handleRemoveLabel = (index:number) => {
    setStateLabels((prevState:string[]) => prevState.filter((_:any, idx) => idx !== index));
  }

  const label = selectedEpisodeId && selectedEpisodeId > 0 ? t('labels.save') : t('labels.apply');

  const actions = (
    <Fragment>
      <Button
        name="Cancel episode labels dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <Button
        name={`${selectedEpisodeId ? 'Save' : 'Apply'} episode labels dialog`}
        onClick={handleClick}
        variant="contained"
        color="primary"
      >{label}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('labels.episodeLabels')}
      actions={actions}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box flexGrow={1}>
          <Input
            label={t('labels.name')} value={name} name="name"
            onChange={handleChange}
            margin="none"
          />
        </Box>
        <Box pt="10px">
          <Button
            name="Add label"
            disabled={!name}
            onClick={handleAdd}
            variant="contained"
            color="primary"
          >{t('labels.add')}</Button>
        </Box>
      </Box>
      {modifiedLabels.length ? (
        <Box sx={{ pt: 4 }}>
          <Labels
            id=""
            labels={modifiedLabels}
            onRemove={handleRemoveLabel}
          />
        </Box>
      ) : null}
    </Dialog>
  )
}

export default EpisodeFormLabelsDialog;