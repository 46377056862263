import { FC, Fragment, useState, ChangeEvent, KeyboardEvent } from 'react';
// Mui
import { Box, Button, Chip, FormHelperText } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utilities
import { isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  emails: string[];
  onSetEmails: (emails:string[]) => void;
  disabled?: boolean;
}

const NotificationPreferences:FC<Props> = ({
  // Props
  emails, onSetEmails, disabled
}) => {
  const { t } = useTranslation('common');

  const [ value, setValue ] = useState('');
  const [ error, setError ] = useState(false);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const handleAdd = () => {
    if ( !isEmailValid.value.test(value) ) {
      setError(true);
      return;
    };
    onSetEmails([...emails, value]);
    setValue('');
    setError(false);
  }

  const handleRemove = (idx:number) => () => {
    onSetEmails(emails.filter((_, index:number) => index !== idx));
  }

  const onKeyDown = (event:KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAdd();
    }
  }

  return (
    <OutlineBlock sx={{ mt: 6, pt: 6 }} label={t('components.notificationPreferences.notificationPreferences')}>
      <Fragment>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Box flexGrow={1}>
            <Input
              label={t('labels.email')} value={value}
              onChange={handleChange}
              error={error}
              helperText={error ? isEmailValid.message : ''}
              margin="none"
              onKeyDown={onKeyDown}
              disabled={disabled}
            />
          </Box>
          <Box sx={{ pt: '10px' }}>
            <Button
              disabled={!value || disabled}
              onClick={handleAdd}
              variant="contained"
              color="primary"
            >{t('labels.add')}</Button>
          </Box>
        </Box>
        <Box>
          <FormHelperText>{t('components.notificationPreferences.emailHelperText')}</FormHelperText>
        </Box>
        {emails.length ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, gap: 2 }}>
            {emails.map((email:string, index:number) => (
              <Chip
                key={`notification-email-item-${index}`}
                label={email}
                onDelete={handleRemove(index)}
                disabled={disabled}
              />
            ))}
          </Box>
        ) : null}
      </Fragment>
    </OutlineBlock>
  )
}

export default NotificationPreferences;