import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getPackages } from 'app/store/BillingPackages/BillingPackages.async';
// Mui
import { Box, Toolbar, Container, Typography } from '@mui/material';
// 
import PackagesList from './PackagesList';
import PackagesFab from './PackagesFab';

const CompanyAccountsPage:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPackages({}));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.accountManagerPages.subscriptionPackagesPage.subscriptionPackages')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <PackagesList />
        </Container>
      </Box>
      <PackagesFab />
    </Fragment>
  )
}

export default CompanyAccountsPage;
