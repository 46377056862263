import { FC, Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Mui
import { Paper, Typography } from '@mui/material';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptions } from 'app/store/DMSCollections/DMSCollections.selectors';
// Components
import { Select } from 'app/components/Mui';
import Autocomplete from 'app/components/Mui/AutocompleteV2';

const WorkflowsTabContent:FC = () => {
  const { t } = useTranslation('common');
  // State
  const collectionOptions = useAppSelector(selectCollectionsAsOptions);

  const { register, control, watch, setValue } = useFormContext();

  const watchTriggerEvent = watch('settings.caseStatusTransitionRules.triggerEvent');

  useEffect(() => {
    if ( watchTriggerEvent && watchTriggerEvent === 'none' ){
      setValue('settings.caseStatusTransitionRules.filter.collectionIds', []);
    }
    // eslint-disable-next-line
  }, [watchTriggerEvent]);

  return (
    <Fragment>
      <input {...register('settings.caseStatusTransitionRules.status')} type="hidden" value="open" />

      <Paper sx={{ p: 4, overflow: 'hidden' }} variant="outlined">
        <Typography sx={{ mb: 4 }} variant="subtitle2">Move case to status Open when:</Typography>
        <Controller
          control={control} name="settings.caseStatusTransitionRules.triggerEvent" defaultValue="none"
          render={({ field }) => (
            <Select
              {...field}
              label="Trigger event"
              options={[
                { id: 'none', name: 'None' },
                { id: 'documentCreated', name: 'Document is created' }
              ]}
              margin="none"
            />
          )}
        />
      </Paper>
      {watchTriggerEvent && watchTriggerEvent !== 'none' ? (
        <Paper sx={{ mt: 4, p: 4, overflow: 'hidden' }} variant="outlined">
          <Typography sx={{ mb: 4 }} variant="subtitle2">For selected documents collections:</Typography>
          <Controller
            control={control} name="settings.caseStatusTransitionRules.filter.collectionIds" defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={collectionOptions || []}
                onChange={(nextValue:any) => {
                  // TODO: Need to check, why CustomAutocomplete can`t handle correct with `undefined` value
                  field.onChange(nextValue && nextValue.length ? nextValue : []);
                }}
                renderOption={(props, option) => (
                  <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                    <Typography variant="subtitle1">{option.name}</Typography>
                    {option.props.description ? (
                      <Typography variant="caption">{option.props.description}</Typography>
                    ) : null}
                  </li>
                )}
                InputProps={{
                  label: t('dialogs.templateForm.documentCollections'), 
                  name: field.name,
                  margin: 'none'
                }}
                multiple={true}
                filterSelectedOptions
              />
            )}
          />
        </Paper>
      ) : null}
    </Fragment>
  )
}

export default WorkflowsTabContent;
