import { MouseEvent, FC, useState, useCallback, useEffect, Fragment } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import { AppUiActions } from 'app/store/AppUI/AppUI.slice';
// Mui
import { Box } from '@mui/material';

let timeout:any = null;

const GridResizer:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();

  const [ resizerElem, setResizerElem ] = useState<HTMLDivElement | null>(null);
  const [ left, setLeft ] = useState<number | null>(null);

  const handleMouseDown = useCallback((event:MouseEvent<HTMLDivElement>) => {
    setResizerElem(event.currentTarget);
    // eslint-disable-next-line
  }, []);

  const handleMouseMove = useCallback((event:any) => {
    event.preventDefault();
    if ( resizerElem ){
      const percents = Math.floor((event as any).x * 100/window.innerWidth);
      const gridResizerWidth = percents > 70 ? 70 : percents < 30 ? 30 : percents;
      setLeft(gridResizerWidth);
      if ( timeout ) clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(AppUiActions.setGridResizerWidth(gridResizerWidth));
      }, 500);
    }
    // eslint-disable-next-line
  }, [resizerElem]);

  const handleMouseUp = useCallback(() => {
    setResizerElem(null);
    setLeft(null)
    // eslint-disable-next-line
  }, [setResizerElem, setLeft]);

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
    // clearTimeout(timeout);
  }, [handleMouseMove, handleMouseUp]);

  useEffect(() => {
    if ( resizerElem ){
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      removeListeners();
    }
    // eslint-disable-next-line
  }, [resizerElem, handleMouseMove, handleMouseUp, removeListeners]);

  return (
    <Fragment>
      <Box
        sx={{
          width: 6,
          bgcolor: 'rgba(0,0,0,0.08)',
          borderRadius: 1,
          cursor: 'col-resize',
          '&:hover': {
            bgcolor: 'rgba(0,0,0,0.16)'
          }
        }}
        onMouseDown={handleMouseDown}
      />
      {resizerElem ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: `${left}%`,
            width: 6,
            height: '100%',
            bgcolor: 'rgba(0,0,0,0.16)',
            borderRadius: 1,
          }}
        ></Box>
      ) : null}
    </Fragment>
  )
}

export default GridResizer;
