export const sortByCreationDate = (a:any, b:any, sort:'asc'|'desc' = 'desc') => {
  const aTime = new Date(a.createdOn).getTime();
  const bTime = new Date(b.createdOn).getTime();
  return sort === 'desc' ? bTime - aTime : aTime - bTime;
}

export const sortByName = <T>(a:T, b:T, field:keyof T, sort:'asc'|'desc' = 'asc') => {
  const aName = (a[field] as string).toLowerCase();
  const bName = (b[field] as string).toLowerCase();
  return aName === bName
    ? 0
    : sort === 'asc'
      ? aName < bName ? -1 : 1
      : aName < bName ? 1 : -1
  ;
}
