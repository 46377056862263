// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get } from 'app/utilities/HttpClient';

const _url:string = '/analytics';

// Get stats
export const getStats = asyncThunkHandler(
  `${Reducers.Analytics}/Get stats`,
  async () => {
    const response:Response = await $get(`${_url}/stats`);
    return response.json();
  }
);
// Get monthly stats
export const getMonthlyStats = asyncThunkHandler(
  `${Reducers.Analytics}/Get monthly stats`,
  async (params:any) => {
    const response:Response = await $get(`${_url}/monthlyStats`, params);
    return response.json();
  }
);

// Get account stats
export const getAccountStats = asyncThunkHandler(
  `${Reducers.Analytics}/Get account stats`,
  async (accountId:number) => {
    const response:Response = await $get(`${_url}/accounts/${accountId}/stats`);
    return response.json();
  }
);
// Get account monthly stats
export const getAccountMonthlyStats = asyncThunkHandler(
  `${Reducers.Analytics}/Get account monthly stats`,
  async ({ accountId, params }:{ accountId:number, params:any }) => {
    const response:Response = await $get(`${_url}/accounts/${accountId}/monthlyStats`, params);
    return response.json();
  }
);

// Get curent stats
export const getCurrentStats = asyncThunkHandler(
  `${Reducers.Analytics}/Get current stats`,
  async () => {
    const response:Response = await $get(`${_url}/accounts/current/stats`);
    return response.json();
  }
);
// Get curent monthly stats
export const getCurrentMonthlyStats = asyncThunkHandler(
  `${Reducers.Analytics}/Get current monthly stats`,
  async (params:any) => {
    const response:Response = await $get(`${_url}/accounts/current/monthlyStats`, params);
    return response.json();
  }
);