// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./Templates.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/templates';

// Get global templates
export const getGlobalTemplates = asyncThunkHandler(
  `${Reducers.Templates}/Get global templates`,
  async (_, { signal }) => {
    const response:Response = await $get(_url, {}, {
      signal
    });
    return response.json();
  }
);

// Get templates
export const getTemplates = asyncThunkHandler(
  `${Reducers.Templates}/Get templates`,
  async (params:any, { signal }) => {
    const response:Response = await $get(_url, params, {
      signal
    });
    return response.json();
  }
);

// Get template
export const getTemplate = asyncThunkHandler(
  `${Reducers.Templates}/Get template`,
  async (templateId:number) => {
    const response:Response = await $get(`${_url}/${templateId}`);
    return response.json();
  }
);

// Create template
export const createTemplate = asyncThunkHandler(
  `${Reducers.Templates}/Create template`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update template
export const updateTemplate = asyncThunkHandler(
  `${Reducers.Templates}/Update template`,
  async ({ templateId, data }:{ templateId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${templateId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Delete template
export const deleteTemplate = asyncThunkHandler(
  `${Reducers.Templates}/Delete template`,
  async (templateId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${templateId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(templateId);
  }
);

// Get template variables
export const getVariables = asyncThunkHandler(
  `${Reducers.Templates}/Get variables`,
  async (_) => {
    const response:Response = await $get(`${_url}/variables`);
    return response.json();
  }
);

// Merge template
export const mergeTemplate = asyncThunkHandler(
  `${Reducers.Templates}/Merge template`,
  async (data:any) => {
    const response:Response = await $post(`${_url}/actions/merge`, data);
    return response.json();
  }
);
