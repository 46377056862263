import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Async
import {
  getStats, getMonthlyStats,
  getAccountStats, getAccountMonthlyStats,
  getCurrentStats, getCurrentMonthlyStats
} from './Analytics.async';

interface IState {
  stats: any;
  monthlyStats: any;
  loading: boolean;
};

const initialState:IState = {
  stats: null,
  monthlyStats: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.Analytics,
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    // Get stats
    builder.addCase(getStats.pending, (state) => {
      state.stats = null
    });
    builder.addCase(getStats.fulfilled, (state, action:PayloadAction<any>) => {
      state.stats = action.payload;
    });
    builder.addCase(getStats.rejected, (state) => {
      state.stats = {}
    });
    // Get monthly stats
    builder.addCase(getMonthlyStats.pending, (state) => {
      state.monthlyStats = null
    });
    builder.addCase(getMonthlyStats.fulfilled, (state, action:PayloadAction<any>) => {
      state.monthlyStats = action.payload;
    });

    // Get account stats
    builder.addCase(getAccountStats.pending, (state) => {
      state.stats = null
    });
    builder.addCase(getAccountStats.fulfilled, (state, action:PayloadAction<any>) => {
      state.stats = action.payload;
    });
    builder.addCase(getAccountStats.rejected, (state) => {
      state.stats = {}
    });

    // Get account monthly stats
    builder.addCase(getAccountMonthlyStats.pending, (state) => {
      state.monthlyStats = null
    });
    builder.addCase(getAccountMonthlyStats.fulfilled, (state, action:PayloadAction<any>) => {
      state.monthlyStats = action.payload;
    });

    // Get current stats
    builder.addCase(getCurrentStats.pending, (state) => {
      state.stats = null
    });
    builder.addCase(getCurrentStats.fulfilled, (state, action:PayloadAction<any>) => {
      state.stats = action.payload;
    });
    builder.addCase(getCurrentStats.rejected, (state) => {
      state.stats = {}
    });
    // Get current monthly stats
    builder.addCase(getCurrentMonthlyStats.pending, (state) => {
      state.monthlyStats = null
    });
    builder.addCase(getCurrentMonthlyStats.fulfilled, (state, action:PayloadAction<any>) => {
      state.monthlyStats = action.payload;
    });

    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
})

export default slice.reducer;
