import { FC, Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import Colors from 'app/types/Colors';
// Models
import { IPage } from '@root/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// ToDO: Actions
import { pageMessages } from 'app/store/page/page.messages';
import PageActions from 'app/store/page/page.actions';
import { selectStapleById, selectIsLoading, selectStatus } from 'app/store/page/page.selectors';
// End ToDO
// Components
import Dialog from 'app/components/Dialog';
import ColorPicker from 'app/components/ColorPicker';
import { Button, LoadingButton } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClose: () => void;
  page?: IPage;
  pages?: IPage[];

  dialogTitle?: string;
}

const CardPageColorsDialog:FC<Props> = ({
  // Props
  open, onClose, page, pages ,dialogTitle
}) => {
  const { t } = useTranslation('common');

  const stapleId = page?.staple && page?.staple.order === 1 ? page.staple.id : null;

  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const staplePages:IPage[] | null = useAppSelector((state:any) => selectStapleById(state, { stapleId }))
  const isLoading:boolean = useAppSelector((state:any) => selectIsLoading(state));
  const status = useAppSelector((state:any) => selectStatus(state));

  useEffect(() => {
    if ( status === 'Success' ) onClose();
    // eslint-disable-next-line
  }, [status]);

  const [ colors, setColors ] = useState<(Colors|string)[]>(page?.colors || []);

  const handleColorClick = (nextColor:Colors|string) => {
    const nextColors = colors.includes(nextColor)
      ? colors.filter((color:Colors | string) => color !== nextColor)
      : [...colors, nextColor]
    ;
    setColors(nextColors);
  }

  const handleSave = () => {
    const message = colors.length > 0 ? pageMessages.updatePageColor : pageMessages.removePageColor;
    if ( pages && pages.length ){
      const pagesToUpdate = pages.map((page:IPage) => {
        const pageColors = page.colors || [];
        const updatedPageColors = pageColors.filter((color:Colors | string) => !colors.includes(color));
        return {
          caseId,
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          colors: [...updatedPageColors, ...colors]
        }
      });
      dispatch(PageActions.patchPages(pagesToUpdate, message));
    } else {
      if ( staplePages && staplePages.length ){
        const pagesToUpdate = staplePages.map((page:IPage) => ({
          caseId,
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          colors
        }));
        dispatch(PageActions.patchPages(pagesToUpdate, message));
      } else {
        if ( page ){
          dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
            caseId,
            version: page.version,
            colors
          }, message));
        }
      }
    }
  }

  const isButtonDisabled = Boolean((pages && pages.length) && !colors.length);

  const actions = (
    <Fragment>
      <Button
        name="Cancel page colors dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Save page colors dialog"
        disabled={isButtonDisabled}
        onClick={handleSave}
        loading={isLoading}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={dialogTitle || t('dialogs.cardPageColors.setPageColors')}
      actions={actions}
    >
      <ColorPicker
        id="cardPageColorDialog"
        activeColors={colors}
        onClick={handleColorClick}
      />
    </Dialog>
  )
}

export default CardPageColorsDialog;
