import { FC, ReactNode, createContext, useContext, useState } from 'react';

interface ILists {
  available: number[];
  selected: number[]
};

type ActionType = 'add' | 'remove';

type ContextType = {
  caseId?: number;
  availableId: string;
  selectedId: string;
  lists: ILists;
  onUpdateLists: (nextLists:{ available?:number[], selected?:number[] }) => void;
  onUpdateItem: (actionType:ActionType, item:number) => void;
}

const Context = createContext<ContextType | undefined>(undefined);

export const useReportPresetContext = ():ContextType => {
  const context = useContext(Context);
  if ( !context ) throw new Error('useReportPresetContext must be used within a ListProvider');
  return context;
}

type Props = {
  caseId?: number;
  children: ReactNode;
}

const ReportPresetProvider:FC<Props> = ({
  // Props
  caseId, children
}) => {
  const availableId = 'available';
  const selectedId = 'selected';

  const [ lists, setLists ] = useState<ILists>({
    available: [],
    selected: []
  });

  const handleUpdateLists = (nextLists:{ available?:number[], selected?:number[] }) => {
    setLists((prevState) => ({
      ...prevState,
      ...nextLists
    }));
  }

  const handleUpdateItem = (actionType:ActionType, item:number) => {
    const available = actionType === 'add'
      ? lists.available.filter((i) => i !== item)
      : [...lists.available, item]
    ;
    const selected =  actionType === 'add'
      ? [...lists.selected, item]
      : lists.selected.filter((i) => i !== item)
    ;
    setLists(({
      available,
      selected
    }));
  }

  const value:ContextType = {
    caseId,
    availableId,
    selectedId,
    lists,
    onUpdateLists: handleUpdateLists,
    onUpdateItem: handleUpdateItem
  };

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
}

export default ReportPresetProvider;

