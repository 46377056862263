import { createSelector } from '@reduxjs/toolkit';
// Types
import StaplePageActions from '@root/types/StaplePageActions';
// Models
import { IPage, IPageDuplicate, IPageWithAction } from '@root/models/Page';
import { IEpisode } from '@root/models/Episode';
// Selectors
import { selectDuplicatesList } from 'app/store/Cases/Cases.selectors';
// Services
import { generatePageId } from './page.service';
// Utilities
import { sortByStapleOrder, sortByWorkspaceOrder } from '@root/utilities/sort';

import { selectBatchesWithDocuments } from 'app/store/DMSBatches/DMSBatches.selectors';
import { selectDocumentsDates } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import Reducers from 'app/types/Reducers';
import { sortByCreationDate } from 'app/utilities/SortBy';


import { selectEpisodesEntities, selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';

export const selectPageIds = (state:any) => state.page.pageIds;
export const selectPageEntities = (state:any) => state.page.pageEntities;
export const selectAllPages = (state:any) => state.page.pages;
export const selectPage = (state:any) => state.page.page;
export const selectFilter = (state:any) => state.page.filter;
export const selectIsLoading = (state:any) => state.page.isLoading;
export const selectStatus = (state:any) => state.page.status;
export const selectError = (state:any) => state.page.error;

export const selectPageStaples = (state:any) => state.page.pageStaples;
// Compare
export const selectComparePagesIds = (state:any) => state.page.comparePagesIds;
// Staple
export const selectStapleMode = (state:any) => state.page.stapleMode;
export const selectStapleId = (state:any) => state.page.stapleId;
export const selectStaplePagesToUpdate = (state:any) => state.page.staplePagesToUpdate;
export const selectDisabledPagesIds = (state:any) => state.page.disabledPagesIds;

interface IPageEntities {
  [key:number]: Record<number, IPage>
};

export const selectPageDates = createSelector(
  [
    selectDocumentsDates,
    (_:any, props:{ documentId:number, pageNum:number, isFilesPage:boolean }) => props
  ],
  (batchDates, { documentId, pageNum, isFilesPage }) => {
    if ( !batchDates ) return [];
    let dates:any = batchDates[`${documentId}:${pageNum}`];
    if ( !dates || dates.length === 0 ){
      dates = [];
    } else {
      dates = [...dates].map(d => ({ date: d, fromPrevPage: false }))
    }
    return dates;
  }
);

export const selectPageEntity = createSelector(
  [
    selectPageEntities,
    (_:any, props:{ documentId:number, pageNum:number }) => props
  ],
  (entities:IPageEntities, { documentId, pageNum }) => {
    const document = entities[documentId];
    if (!document) return undefined; // Might happen if new document is created by another user. This isn't handled on background at runtime.
    return document[pageNum];
  }
);

export const selectStapleById = createSelector(
  [
    selectAllPages,
    (_:any, props:{ stapleId:string|null }) => props
  ],
  (pages:Array<IPage> | null, { stapleId }) => {
    if ( !pages || !stapleId ) return null;
    const id = stapleId.split(':')[0];
    return pages.filter((page:IPage) => {
      if ( !page.staple ) return false;
      return (page.staple.id === stapleId || page.staple.id.includes(id));
    }).sort(sortByStapleOrder)
  }
);

export const selectIsPageDisabled = createSelector(
  [
    selectDisabledPagesIds,
    (_:any, props:{ documentId:number, pageNum:number }) => props
  ],
  (disabledPagesIds:Array<string>, { documentId, pageNum }) => disabledPagesIds.includes(generatePageId(documentId, pageNum))
);

export const selectIsPageInStapleUpdate = createSelector(
  [
    selectStaplePagesToUpdate,
    (_:any, props:{ documentId:number, pageNum:number }) => props
  ],
  (staplePagesToUpdate:Array<IPageWithAction>, { documentId, pageNum }) => {
    const foundedPage = staplePagesToUpdate.find((page:IPageWithAction) => {
      return page.documentId === documentId && page.pageNum === pageNum && page.action !== StaplePageActions.Removed
    });
    return Boolean(foundedPage);
  }
);

export const selectIsPageInCompare = createSelector(
  [
    selectComparePagesIds,
    (_:any, props:{ documentId:number, pageNum:number, stapleId:string|null }) => props
  ],
  (comparePagesIds:Array<string>, { documentId, pageNum, stapleId }) => {
    return comparePagesIds.includes(generatePageId(documentId, pageNum, stapleId))
  }
);

export const selectPagesDatesMap = createSelector(
  [
    selectAllPages,
    selectEpisodesEntities,
  ],
  (pages:Array<IPage> | null, episodesEntities:any) => {
    if ( !pages ) return {};
    return pages.reduce((acc:{ [key:string]:string }, cur:IPage) => {
      if ( cur.parentEpisodeId ){
        const episode = episodesEntities[cur.parentEpisodeId];
        if ( episode && episode.date ){
          acc[generatePageId(cur.documentId, cur.pageNum)] = episode.date;
        }
      }
      return acc;
    }, {})
  }
)

export const selectPrevStaplePage = createSelector(
  [
    selectStapleById,
    (_:any, props:{ pageNum:number }) => props
  ],
  (staple:Array<IPage>|null, { pageNum }) => {
    if ( !staple ) return null;
    const pageIndex = staple.findIndex((page:IPage) => page.pageNum === pageNum);
    return staple[pageIndex - 1];
  }
);

export const selectNextStaplePage = createSelector(
  [
    selectStapleById,
    (_:any, props:{ pageNum:number }) => props
  ],
  (staple:Array<IPage>|null, { pageNum }) => {
    if ( !staple ) return null;
    const pageIndex = staple.findIndex((page:IPage) => page.pageNum === pageNum);
    return staple[pageIndex + 1];
  }
);

// Workspace
export const selectPagesInWorkspaceIds = createSelector(
  [ selectAllPages ],
  (pages:Array<IPage> | null) => {
    if ( !pages ) return [];
    return pages
      .filter((page:IPage) => !page.deleted && page.workspaceOrder)
      .map((page:IPage) => generatePageId(page.documentId, page.pageNum))
    ;
  }
);

export const selectEpisodeTypesInWorkspace = createSelector(
  [
    selectPagesInWorkspaceIds,
    (state:any) => selectEpisodesAll(state)
  ],
  (pagesInWorkspaceIds:Array<string>, episodes:any[] | null) => {
    if ( !episodes ) return [];
    return episodes
      .filter((episode:any) => {
        if ( !episode.type ) return false;
        return pagesInWorkspaceIds.includes(generatePageId(episode.documentId, episode.pageNum))
      })
      .reduce((acc:Array<string>, cur:any) => {
        return acc.includes(cur.type) ? acc : [...acc, cur.type];
      }, []).sort()
  }
);

export const selectEpisodeTypesMapInWorkspace = createSelector(
  [
    selectAllPages,
    selectPageEntities,
    selectEpisodeTypesInWorkspace,
    (state:any) => selectEpisodesAll(state)
  ],
  (pages:Array<IPage> | null, entities:IPageEntities, episodeTypes:Array<string>, episodes:any[] | null) => {
    if ( episodeTypes.length === 0 || !episodes ) return [];
    return episodes.reduce((acc:any, cur:any) => {
      if ( episodeTypes.includes(cur.type) ){
        let pageId = generatePageId(cur.documentId, cur.pageNum);
        // const episodePage = pagesEntities[pageId];
        const pageEntity = entities[cur.documentId][cur.pageNum]

        if ( pageEntity && pageEntity.staple && pageEntity.staple.order > 1 ){
          const stapleId = pageEntity.staple.id;
          const mainStaplePage = pages ? pages.find((page:IPage) => page.staple && page.staple.order === 1 && page.staple.id === stapleId) : null;
          if ( mainStaplePage ) pageId = generatePageId(mainStaplePage.documentId, mainStaplePage.pageNum);
        }

        if ( !acc[cur.type] ){
          acc[cur.type] = [pageId]
        } else {
          if ( !acc[cur.type].includes(pageId) ){
            acc[cur.type] = [...acc[cur.type], pageId];
          }
        }
      }
      return acc;
    }, {});
  }
);

export const selectEpisodeLabelsInWorkspace = createSelector(
  [
    selectPagesInWorkspaceIds,
    (state:any) => selectEpisodesAll(state)
  ],
  (pagesInWorkspaceIds:Array<string>, episodes:any[] | null) => {
    if ( !episodes ) return [];
    return episodes
      .filter((episode:any) => {
        if ( !episode.labels || episode.labels.length === 0 ) return false;
        return pagesInWorkspaceIds.includes(generatePageId(episode.documentId, episode.pageNum))
      })
      .reduce((acc:Array<string>, cur:IEpisode) => {
        if ( cur.labels ){
          for ( let label of cur.labels ){
            if ( !acc.includes(label) ) acc.push(label);
          }
        }
        return acc;
      }, []).sort();
  }
);

export const selectEpisodeAuthorsInWorkspace = createSelector(
  [
    selectPagesInWorkspaceIds,
    (state:any) => selectEpisodesAll(state)
  ],
  (pagesInWorkspaceIds:Array<string>, episodes:any[] | null) => {
    if ( !episodes ) return [];
    return episodes
      .filter((episode:any) => pagesInWorkspaceIds.includes(generatePageId(episode.documentId, episode.pageNum)))
      .reduce((acc:number[], cur:IEpisode) => {
        if ( cur.authorId && !acc.includes(cur.authorId) ){
          acc.push(cur.authorId);
        }
        // if ( cur.author && cur.author.id ){
        //   if ( !acc.includes(cur.author.name) ) acc.push(cur.author.name);
        // }
        return acc;
      }, []).sort();
  }
);

// export const selectPrevNextDocumentsId = createSelector(
//   [
//     (state:any) => selectBatchesWithDocuments(state),
//     selectAllPages,
//     (_:any, props:{ documentId:number }) => props
//   ],
//   (batches:any, pages:any, { documentId }) => {
//     if ( !pages ) return null;
//     const documentsIds = batches.reduce((acc:any, cur:any) => {
//       return [...acc, ...cur.documentsIds]
//     }, []);
//     // const documentsIds = documents.map((d:any) => d.id);
//     let result:any = [];
//     documentsIds.forEach((id:number) => {
//       const documentPages = pages.filter((p:any) => p.documentId === id).sort(sortByPageNumber);
//       result = [...result, ...documentPages];
//     });
//     return result;
//   }
// );
export const selectFilteredDocumentsPages = createSelector(
  [
    (state) => selectBatchesWithDocuments(state),
    (state) => selectDuplicatesList(state),
    (state) => selectPagesInWorkspaceIds(state),
    (state) => selectAllPages(state),
    (state) => selectFilter(state),
  ],
  ( batches:any, duplicates, workspacePagesIds:any, pages:any, filter:any ) => {
    if ( !pages || !batches || !duplicates ) return null;

    const documentIds = [...batches]
      .sort((a, b) => sortByCreationDate(a, b, 'asc'))
      .reduce((acc:any, cur:any) => {
        return [...acc, ...cur.documentIds]
      }, []);
    let sortedDocumentsPages:any[] = [];
    for ( let documentId of documentIds ){
      const documentPages = pages
        .filter((page:any) => page.documentId === documentId)
        .map(generateMainStaplePageNum)
        .sort(sortByMainStaplePageNum)
      ;
      sortedDocumentsPages = [...sortedDocumentsPages, ...documentPages]
    }

    const { tags, colors, duplicate } = filter;

    return sortedDocumentsPages
      .filter((page:IPage) => {
        const pageTag = page.tag || null;
        const pageColors = page.colors && page.colors.length ? page.colors : ['noColor'];
        const hasTags = tags.length === 0 ? true : tags.includes(pageTag);
        const hasColors = colors.length === 0 ? true : colors.some((color:string) => pageColors.includes(color));
        return hasTags && hasColors;
      })
      .filter((page:IPage) => {
        if ( !duplicate ) return page;
        if ( !page.inWorkspace ) return false;

        let pickedCluster = null;

        for ( let cluster of Object.values(duplicates) ){
          for ( let p of cluster as any ){
            if ( p.documentId === page.documentId && p.pageNum === page.pageNum ){
              pickedCluster = cluster;
              break;
            }
          }
          if ( pickedCluster ) break;
        }

        let pageDuplicate:any = null;

        if ( pickedCluster ){
          for ( let p of pickedCluster as any ){
            if ( p.documentId === page.documentId && p.pageNum === page.pageNum ) continue;
            if ( workspacePagesIds.includes(`${p.documentId}:${p.pageNum}`) ){
              pageDuplicate = p;
              break;
            }
          }
        }

        return Boolean(pageDuplicate);
      })
  }
)

export const selectFilteredDocumentPages = createSelector(
  [
    selectFilteredDocumentsPages,
    (_:any, props:{ documentId:number }) => props
  ],
  (pages:Array<IPage> | null, { documentId }) => {
    if ( !pages ) return null;
    return pages.filter((page:IPage) => page.documentId === documentId);
  }
);

export const selectEpisodeLabelsMapInWorkspace = createSelector(
  [
    (state:any) => selectAllPages(state),
    (state:any) => selectPageEntities(state),
    (state:any) => selectEpisodeLabelsInWorkspace(state),
    (state:any) => selectEpisodesAll(state)
  ],
  (pages:Array<IPage> | null, entities:IPageEntities, episodeLabels:Array<string>, episodes:any[] | null) => {
    if ( episodeLabels.length === 0 || !episodes ) return [];
    return episodes.reduce((acc:any, cur:any) => {
      if ( cur.labels ){
        let pageId = generatePageId(cur.documentId, cur.pageNum);
        const pageEntity = entities[cur.documentId][cur.pageNum];
  
        if ( pageEntity && pageEntity.staple && pageEntity.staple.order > 1 ){
          const stapleId = pageEntity.staple.id;
          const mainStaplePage = pages ? pages.find((page:IPage) => page.staple && page.staple.order === 1 && page.staple.id === stapleId) : null;
          if ( mainStaplePage ) pageId = generatePageId(mainStaplePage.documentId, mainStaplePage.pageNum);
        }

        for ( let label of cur.labels ){
          if ( episodeLabels.includes(label) ){

            if ( !acc[label] ){
              acc[label] = [pageId]
            } else {
              if ( !acc[label].includes(pageId) ){
                acc[label] = [...acc[label], pageId];
              }
            }

          }
        }
      }
      return acc;
    }, {});
  }
);

export const selectEpisodeAuthorsMapInWorkspace = createSelector(
  [
    selectAllPages,
    selectPageEntities,
    selectEpisodeAuthorsInWorkspace,
    (state:any) => selectEpisodesAll(state)
  ],
  (pages:Array<IPage> | null, entities:IPageEntities, episodeAuthors:Array<string>, episodes:any[] | null) => {
    if ( episodeAuthors.length === 0 || !episodes ) return [];
    return episodes.reduce((acc:any, cur:any) => {
      if ( cur.authorId ){
        let pageId = generatePageId(cur.documentId, cur.pageNum);
        const pageEntity = entities[cur.documentId][cur.pageNum];
  
        if ( pageEntity && pageEntity.staple && pageEntity.staple.order > 1 ){
          const stapleId = pageEntity.staple.id;
          const mainStaplePage = pages ? pages.find((page:IPage) => page.staple && page.staple.order === 1 && page.staple.id === stapleId) : null;
          if ( mainStaplePage ) pageId = generatePageId(mainStaplePage.documentId, mainStaplePage.pageNum);
        }

        if ( episodeAuthors.includes(cur.authorId) ){

          if ( !acc[cur.authorId] ){
            acc[cur.authorId] = [pageId]
          } else {
            if ( !acc[cur.authorId].includes(pageId) ){
              acc[cur.authorId] = [...acc[cur.authorId], pageId];
            }
          }

        }
      }
      return acc;
    }, {});
  }
);

export const selectPagesInWorkspace = createSelector(
  [
    selectAllPages,
    selectEpisodeTypesMapInWorkspace,
    selectEpisodeLabelsMapInWorkspace,
    selectEpisodeAuthorsMapInWorkspace,
    selectFilter,
    (state) => state[Reducers.DMSBatches].entities
  ],
  (pages:Array<IPage> | null, episodeTypesMap:any, episodeLabelsMap, episodeAuthorsMap, filter:any, batchesEntities:any) => {
    if ( !pages ) return null;

    let { tags, colors, types, labels, authors } = filter;

    // Remove deleted episode type from types filter
    // if ( types.length ){
    //   types = types.map((type:{ value:string, label:string }) => type.value).filter((type:string) => episodeTypesMap[type]);
    // }
    // if ( labels.length ){
    //   labels = labels.map((label:{ value:string, label:string }) => label.value).filter((label:string) => episodeLabelsMap[label]);
    // }
    // if ( authors.length ){
    //   authors = authors.map((label:{ value:string, label:string }) => label.value).filter((author:string) => episodeAuthorsMap[author]);
    // }

    const nextPages = pages
      .filter((page:IPage) => !page.workspaceOrder || page.deleted ? false : true)
      .filter((page:IPage) => {
        const pageId = generatePageId(page.documentId, page.pageNum);

        const pageTag = page.tag || null;
        const pageColors = page.colors && page.colors.length ? page.colors : ['noColor'];
        const hasTags = tags.length === 0 ? true : tags.includes(pageTag);
        const hasColors = colors.length === 0 ? true : colors.some((color:string) => pageColors.includes(color));

        let hasTypes = types.length === 0;
        if ( !hasTypes ){
          for ( let type of types ){
            if ( episodeTypesMap[type] && episodeTypesMap[type].includes(pageId) ){
              hasTypes = true;
              break;
            }
          }
        }
        let hasLabels = labels.length === 0;
        if ( !hasLabels ){
          for ( let label of labels ){
            if ( episodeLabelsMap[label] && episodeLabelsMap[label].includes(pageId) ){
              hasLabels = true;
              break;
            }
          }
        }
        let hasAuthors = authors.length === 0;
        if ( !hasAuthors ){
          for ( let author of authors ){
            if ( episodeAuthorsMap[author] && episodeAuthorsMap[author].includes(pageId) ){
              hasAuthors = true;
              break;
            }
          }
        }

        return hasTags && hasColors && hasTypes && hasLabels && hasAuthors;
      })
      .sort(sortByWorkspaceOrder)
    ;

    if ( !filter.additional ) return nextPages;

    const documentIds = Object.values(batchesEntities).filter((batch:any) => batch.additional)
      .reduce((acc:number[], cur:any) => {
        if ( cur.documents && cur.documents.length ){
          for ( let doc of cur.documents ){
            acc.push(doc.id);
          }
        }
        return acc;
      }, []);

    return nextPages.filter((page:IPage) => documentIds.includes(page.documentId));
  }
);

export const selectPagesInWorkspaceWithoutSecondary = createSelector(
  [selectPagesInWorkspace],
  (pages:Array<IPage> | null) => {
    if ( !pages ) return null;
    return pages.filter((page:IPage) => {
      if ( page.staple && page.staple.order > 1 ) return false;
      return true;
    })
  }
);

export const selectTotalPagesInWorkspace = createSelector(
  [selectAllPages, selectPagesInWorkspace],
  (allPages:IPage[] | null, pagesInWorkspace:Array<IPage> | null) => {
    if ( !allPages || !pagesInWorkspace ) return null;

    let filteredPages:any = [];

    pagesInWorkspace.forEach((page:IPage) => {
      if ( !page.staple ){
        filteredPages = [...filteredPages, page];
      } else {
        if ( page.staple.order === 1 ){
          const staplePages:Array<IPage> = allPages.filter((p:IPage) => p.staple && p.staple.id === page?.staple?.id).sort(sortByStapleOrder);
          filteredPages = [...filteredPages, ...staplePages];
        }
      }
    });

    return {
      totalPagesInWorkspace: filteredPages.length,
      totalMainPagesInWorkspace: filteredPages.filter((page:IPage) => !page.staple || page.staple.order === 1).length
    }
  }
);

export const selectPagesInWorkspaceByPreEvent = createSelector(
  [
    (state:any) => selectPagesInWorkspaceWithoutSecondary(state),
    (state:any) => selectEpisodesEntities(state),
    (_:any, props:{ preEvent:boolean }) => props
  ],
  (pages:Array<IPage> | null, episodesEntities:any, { preEvent }) => {
    if ( !pages || !episodesEntities ) return null;

    return pages
      .filter((page:IPage) => {
        if ( page.parentEpisodeId ){
          const parentEpisode:IEpisode = episodesEntities[page.parentEpisodeId];

          if ( !parentEpisode ) return false;

          return preEvent ? parentEpisode.preEvent : !parentEpisode.preEvent;
        }
        return preEvent ? false : true;
      })
  }
);

export const selectEpisodesInWorkspace = createSelector(
  [
    (state:any) => selectAllPages(state),
    (state:any) => selectFilter(state),
    (state:any) => selectEpisodesAll(state),
    (state) => state[Reducers.DMSBatches].entities
  ],
  (pages:any, { tags = [], colors = [], types = [], labels = [], authors = [], additional }, episodes:any, batcheEntities) => {
    if ( !pages || !episodes ) return null;

    const pagesIds = pages
      .filter((page:any) => page.workspaceOrder && !page.deleted)
      .sort(sortByWorkspaceOrder)
      .filter((page:any) => {
        const pageTag = page.tag || null;
        const pageColors = page.colors && page.colors.length ? page.colors : ['noColor'];
        const hasTags = tags.length === 0 ? true : tags.includes(pageTag);
        const hasColors = colors.length === 0 ? true : colors.some((color:string) => pageColors.includes(color));

        return hasTags && hasColors
      })
      .map((page:any) => `${page.documentId}:${page.pageNum}`);

    const nextEpisodes = episodes
      .filter((episode:any) => pagesIds.includes(`${episode.documentId}:${episode.pageNum}`))
      .filter((episode:any) => {

        let hasTypes = types.length === 0;
        if ( !hasTypes ){
          if ( episode.type ){
            hasTypes = types.includes(episode.type);
          }
        }
        let hasLabels = labels.length === 0;
        if ( !hasLabels ){
          if ( episode.labels && episode.labels.length ){
            for ( let label of episode.labels ){
              if ( labels.includes(label) ){
                hasLabels = true;
                break;
              }
            }
          }
        }
        let hasAuthors = authors.length === 0;
        if ( !hasAuthors ){
          if ( episode.author && episode.author.id ){
            hasAuthors = authors.includes(episode.author.id);
          }
        }
        return hasTypes && hasLabels && hasAuthors;
      });

    if ( !additional ) return nextEpisodes;

    const documentIds = Object.values(batcheEntities).filter((batch:any) => batch.additional)
      .reduce((acc:number[], cur:any) => {
        if ( cur.documents && cur.documents.length ){
          for ( let doc of cur.documents ){
            acc.push(doc.id);
          }
        }
        return acc;
      }, []);

    return nextEpisodes.filter((episode:any) => documentIds.includes(episode.documentId));
  }
);

export const selectEpisodesInWorkspaceByPreEvent = createSelector(
  [
    (state:any) => selectEpisodesInWorkspace(state),
    (_:any, props:{ preEvent:boolean }) => props
  ],
  (episodes:any, { preEvent }) => {
    if ( !episodes ) return null;
    return episodes.filter((episode:any) => {
      if ( !preEvent ) return !episode.preEvent;
      return episode.preEvent === preEvent;
    }).sort((a:any, b:any) => sortByCreationDate(a, b, 'asc'));
  }
)



export const selectPrevWorkspacePage = createSelector(
  [
    (state:any) => selectPagesInWorkspaceWithoutSecondary(state),
    (state:any) => selectEpisodesEntities(state),
    (_:any, props:{ documentId:number, pageNum:number }) => props
  ],
  (pages:Array<IPage> | null, episodeEntities:any, { documentId, pageNum }) => {
    if ( !pages ) return null;
    const page:IPage | undefined = pages.find((page:IPage) => page.documentId === documentId && page.pageNum === pageNum);
    
    if ( page ){
      const pageIndex = pages.indexOf(page);
      const prevPage = pages[pageIndex - 1];

      const pageEpisode = page.parentEpisodeId && episodeEntities ? episodeEntities[page.parentEpisodeId] : null;
      const prevPageEpisode = prevPage && prevPage.parentEpisodeId && episodeEntities ? episodeEntities[prevPage.parentEpisodeId] : null;

      // if ( pageEpisode && pageEpisode.date ){
      //   if ( prevPageEpisode && prevPageEpisode.date === pageEpisode.date ) return prevPage;
      // } else {
      //   if ( prevPage && (!prevPageEpisode || !prevPageEpisode.date) ) return prevPage;
      // }
      if ((pageEpisode && pageEpisode.date && prevPage && prevPageEpisode && prevPageEpisode.date && pageEpisode.date === prevPageEpisode.date && pageEpisode.preEvent === prevPageEpisode.preEvent) ||
          (!pageEpisode || !pageEpisode.date) && (!prevPageEpisode || !prevPageEpisode.date)) {
        return prevPage;
      }
    }
    return null;
  }
);

export const selectNextWorkspacePage = createSelector(
  [
    (state:any) => selectPagesInWorkspaceWithoutSecondary(state),
    (state:any) => selectEpisodesEntities(state),
    (_:any, props:{ documentId:number, pageNum:number }) => props
  ],
  (pages:Array<IPage> | null, episodeEntities, { documentId, pageNum }) => {
    if ( !pages ) return null;
    const page:IPage | undefined = pages.find((page:IPage) => page.documentId === documentId && page.pageNum === pageNum);
    if ( page ){
      const pageIndex = pages.indexOf(page);
      const nextPage = pages[pageIndex + 1];

      const pageEpisode = page.parentEpisodeId && episodeEntities ? episodeEntities[page.parentEpisodeId] : null;
      const nextPageEpisode = nextPage && nextPage.parentEpisodeId && episodeEntities ? episodeEntities[nextPage.parentEpisodeId] : null;

      // if ( pageEpisode && pageEpisode.date ){
      //   if ( nextPageEpisode && nextPageEpisode.date === pageEpisode.date ) return nextPage;
      // } else {
      //   if ( nextPage && (!nextPageEpisode || !nextPageEpisode.date) ) return nextPage;
      // }
      if ((pageEpisode && pageEpisode.date && nextPage && nextPageEpisode && nextPageEpisode.date && pageEpisode.date === nextPageEpisode.date && pageEpisode.preEvent === nextPageEpisode.preEvent) ||
          (!pageEpisode || !pageEpisode.date) && (!nextPageEpisode || !nextPageEpisode.date)) {
        return nextPage;
      }
    }
    return null;
  }
);

// Duplicates
export const selectPageDuplicateInWorkspace = createSelector(
  [
    (state:any) => selectPagesInWorkspaceIds(state),
    (state:any) => selectDuplicatesList(state),
    (_:any, props:{ documentId:number, pageNum:number }) => props
  ],
  (pagesIds:Array<string> | null, duplicates, { documentId, pageNum }) => {
    if ( !pagesIds || !duplicates ) return null;

    let selectedCluster:IPageDuplicate[] | null = null;

    for ( let cluster of Object.values(duplicates) ){
      for ( let page of cluster ){
        if ( page.documentId === documentId && page.pageNum === pageNum ){
          selectedCluster = cluster;
          break;
        }
      }
      if ( selectedCluster ) break;
    }

    if ( !selectedCluster || selectedCluster.length === 0 ) return null;

    selectedCluster = selectedCluster.filter((page) => {
      if ( page.documentId === documentId && page.pageNum === pageNum ) return false;
      return true;
    });

    let pageDuplicate = null;

    for ( let page of selectedCluster ){
      const pageId = generatePageId(page.documentId, page.pageNum);
      if ( pagesIds.includes(pageId) ){
        pageDuplicate = page;
        break;
      }
    }
    return pageDuplicate;
  }
);


// ToDO: In future should be removed with .sort()
const generateMainStaplePageNum = (page:IPage, _:number, array:Array<IPage>) => {
  if ( page.staple ){
    const mainStaplePage:IPage|undefined = array.find((p:IPage) => {
      return p.staple && page.staple && p.staple.order === 1 && p.staple.id === page.staple.id
    });
    if ( mainStaplePage ){
      return { ...page,
        staple: {
          ...page.staple,
          mainStaplePageNum: mainStaplePage.pageNum
        }
      }
    }
  }
  return page;
};
const sortByMainStaplePageNum = (a:any, b:any) => {
  let aPageNum = a.staple ? a.staple.mainStaplePageNum : a.pageNum;
  let bPageNum = b.staple ? b.staple.mainStaplePageNum : b.pageNum;
  if ( a.staple && b.staple && a.staple.id === b.staple.id ){
    aPageNum = a.staple.order;
    bPageNum = b.staple.order;
  }
  return aPageNum - bPageNum;
}