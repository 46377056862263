'use client';

import { FC, Fragment, useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// Redux
import { useAppDispatch, useAppSelector } from './hooks/useStore';
// Async
import { checkAccessToken } from 'app/store/Auth/Auth.async';
// Selectors
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';
// Layouts
import PagesErrorHandler from 'app/layouts/PagesErrorHandler';
// Layout
import { AppLoader } from 'app/components/Utilities';
import Snackbars from 'app/components/Snackbars';
import StatusCodeHandler from 'app/layouts/StatusCodeHandler';
import DialogHandler from 'app/layouts/DialogHandler';
// Routing
import AppRouting from './App.routing';
// Services
import WebSocketService from 'app/services/WebSocketService';

const App:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const authenticated = useAppSelector(selectAuthenticated);

  useEffect(() => {
    dispatch(checkAccessToken());

    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('unload', handleUnload);
    }
    // eslint-disable-next-line
  }, []);

  const handleUnload = () => {
    WebSocketService.close();
  }

  if ( typeof authenticated === 'undefined' ) return <AppLoader />;
  return (
    <Fragment>
      <SnackbarProvider maxSnack={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppRouting />
          <DialogHandler />
        </LocalizationProvider>
        <Snackbars />
        <StatusCodeHandler />
        <PagesErrorHandler />
      </SnackbarProvider>
    </Fragment>
  )
}

export default App;
