import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
// Dispatch
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { patchEpisode } from 'app/store/Episodes/Episodes.async';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import {
  LabelOutlined as LabelOutlinedIcon,
  Edit as EditIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import Labels from 'app/components/Labels';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Context
import { usePageEpisodesContext } from 'app/context/PageEpisodes.context';
// 
import EpisodeFormLabelsDialog from './EpisodeFormLabelsDialog';

type Props = {
  episodeId?: number;
  episodeVersion?: number;
  isDisabled: boolean;
}

const EpisodeFormLabels:FC<Props> = ({ episodeId, episodeVersion, isDisabled }) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();

  const { onPatch } = usePageEpisodesContext();
  const { getValues, setValue } = useFormContext();

  const { open, toggle } = useToggle();

  const watchLabels = getValues('labels');

  const modifiedLabels = useMemo(() => {
    return watchLabels.map((label:string) => ({ name: label, color: 'initial' }))
  }, [watchLabels]);

  const handleClick = (nextLabels:string[]) => {
    setValue('labels', nextLabels);

    if ( episodeId ){
      asyncPatchEpisode(episodeId, { labels: nextLabels, version: episodeVersion });
    }
  }

  const asyncPatchEpisode = async (episodeId:number, data:any) => {
    try {
      const episode = await dispatch(patchEpisode({ episodeId, data })).unwrap();
      onPatch(episode);
    } catch(error){}
  }

  if ( isDisabled && !watchLabels.length ) return null;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
        <Tooltip title={t('labels.labels')}>
          <LabelOutlinedIcon sx={{ color: 'rgba(0,0,0,0.54)', mr: 2 }} />
        </Tooltip>
        <Labels id="episodeFormLabels" labels={modifiedLabels} />
        {!isDisabled ? (
          <Tooltip title={t('components.pageDetailsEpisodes.editEpisodeLabels')}>
            <IconButton
              name="Edit episode labels"
              onClick={toggle}
              size="small"
            ><EditIcon /></IconButton>
          </Tooltip>
        ) : null}
      </Box>
      {open ? (
        <EpisodeFormLabelsDialog
          open={open}
          onClose={toggle}
          onConfirm={handleClick}
          labels={watchLabels}
        />
      ) : null}
    </Fragment>
  )
}

export default EpisodeFormLabels;
