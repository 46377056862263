import config from '../../../../../config';

import { FC, Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// Types
import UserRoles from 'app/types/UserRoles';
import UserPermissions from 'app/types/Permissions';
// Models
import { IMyUser } from 'app/models/User';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser, selectMyUserHasPermission } from 'app/store/Users/Users.selectors';
import { selectIntegrations } from 'app/store/Integrations/Integrations.selectors';
import { selectLoading } from 'app/store/Invoices/Invoices.selectors';
// Async
import { deleteInvoice, pushInvoice } from 'app/store/Invoices/Invoices.async';
// Mui
import {
  Box, Paper,
  Table, TableBody, TableCell, TableHead, TableRow,
  Typography
} from '@mui/material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useLongDateTme from 'app/hooks/useLongDateTime';
// 
import InvoiceFormDialog from './InvoiceFormDialog';
import InvoiceSendPayFormDialog from './InvoiceSendPayFormDialog';

type Props = {
  invoice: any;
}

const InvoicesTable:FC<Props> = ({
  // Props
  invoice
}) => {
  const { t } = useTranslation('common');

  const {
    id, description, status: invoiceStatus, invoiceNumber, createdOn, amount, lines, sentOn, paidOn, quickBooksId, customFields, dueDate, modifiedSinceLastPush, sentToEmail
  } = invoice;

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const myUserInvoiceEditEnabled = useAppSelector((state) => selectMyUserHasPermission(state, {
    permission: UserPermissions.InvoiceEdit
  }));
  const integrations = useAppSelector(selectIntegrations);
  const loading = useAppSelector(selectLoading);

  const { open, toggle } = useToggle();
  const { open:openSendFormDialog, toggle:toggleSendFormDialog } = useToggle();
  const { open:openPayFormDialog, toggle:togglePayFormDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();
  const { open:openConfirmationDeleteDialog, toggle:toggleConfirmationDeleteDialog } = useToggle();

  const longCreatedOn = useLongDateTme({
    dateValue: createdOn,
    displayTime: true,
    local: true
  });
  const longSentOn = useLongDateTme({
    dateValue: sentOn,
    displayTime: true,
    local: true
  });
  const longPaidOn = useLongDateTme({
    dateValue: paidOn,
    displayTime: true,
    local: true
  });
  const longDueDate = useLongDateTme({
    dateValue: dueDate,
    local: true
  });

  const [ stateLoading, setStateLoading ] = useState(false);

  const handlePushInvoice = async () => {
    setStateLoading(true);
    try {
      await dispatch(pushInvoice({ id, data: { integrationId: integration.id } })).unwrap();
    } catch(error){} finally {
      setStateLoading(false);
    }
  }

  const handleConfirm = async () => {
    try {
      await dispatch(deleteInvoice({ id, data: {} })).unwrap();
      toggleConfirmationDialog();
    } catch(error){}
  }

  const handleUnconfirmDelete = async () => {
    try {
      await dispatch(deleteInvoice({ id, data: { deleteFromIntegration: false } })).unwrap();
      toggleConfirmationDialog();
    } catch(error){}
  }

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deleteInvoice({ id, data: { deleteFromIntegration: true } })).unwrap();
      toggleConfirmationDialog();
    } catch(error){}
  }

  const myUserRoleStaff = myUser.role === UserRoles.Staff;
  const integration = integrations && integrations.length !== 0 ? integrations[0] : null;

  return (
    <Fragment>
      <Paper sx={{ mt: 2 }} variant="outlined">
        <Box sx={{ pt: 4, px: 4, mb: 1 }}>
          {description ? (
            <Typography display="block" variant="body2">{description}</Typography>
          ) : null}
          <Typography display="block" variant="caption">
            <b>{t('labels.status')}</b>&nbsp;<Typography
              sx={{
                fontSize: 12,
                color: (theme) => invoiceStatus === 'sent' || invoiceStatus === 'paid'
                  ? theme.palette.success.main
                  : theme.palette.grey[700]
              }}
              component="span"
            >{invoiceStatus}</Typography>
          </Typography>
          {longCreatedOn ? (
            <Typography display="block" variant="caption">
              <b>{t('pages.adminPages.caseDetailsPage.createdOn')}</b>&nbsp;{longCreatedOn}
            </Typography>
          ) : null}
          {longSentOn && sentToEmail ? (
            <Typography display="block" variant="caption">
              <Trans
                t={t} i18nKey="pages.adminPages.caseDetailsPage.sentToAt"
                components={{ b: <b /> }}
                values={{ sentToEmail, longSentOn }}
              />
            </Typography>
          ) : (
            <Fragment>
              {longSentOn ? (
                <Typography display="block" variant="caption">
                  <b>{t('pages.adminPages.caseDetailsPage.sentOn')}</b>&nbsp;{longSentOn}
                </Typography>
              ) : null}
              {sentToEmail ? (
                <Typography display="block" variant="caption">
                  <b>{t('pages.adminPages.caseDetailsPage.sentTo')}</b>&nbsp;{sentToEmail}
                </Typography>
              ) : null}
            </Fragment>
          )}
          {longPaidOn ? (
            <Typography display="block" variant="caption">
              <b>{t('pages.adminPages.caseDetailsPage.paidOn')}</b>&nbsp;{longPaidOn}
            </Typography>
          ) : null}
          {longDueDate ? (
            <Typography display="block" variant="caption">
              <b>{t('labels.invoiceDueDate')}</b>&nbsp;{longDueDate}
            </Typography>
          ) : null}
          {invoiceNumber ? (
            <Typography display="block" variant="caption">
            <b>{t('labels.invoiceNumber')}</b>&nbsp;{invoiceNumber}
          </Typography>
          ) : null}
          {(customFields && customFields.customerQuickBooksId) ? (
            <Typography display="block" variant="caption">
              <b>{t('pages.adminPages.caseDetailsPage.customerQuickbooksId')}</b>
              &nbsp;
              <a
                href={`${config.quickBooksUrl}/customerdetail?nameId=${customFields.customerQuickBooksId}`}
                target="_blank"
              >{customFields.customerQuickBooksId}</a>
            </Typography>
          ) : null}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('labels.name')}</TableCell>
              <TableCell align="right">{t('labels.description')}</TableCell>
              <TableCell align="right">{t('labels.qty')}</TableCell>
              <TableCell align="right">{t('labels.rate')}</TableCell>
              <TableCell align="right">{t('labels.total')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map((line:any, i:number) => (
              <TableRow key={`invoice-table-${id}-row-${i}`}>
                <TableCell component="th" scope="row">{line.name}</TableCell>
                <TableCell align="right">{line.description}</TableCell>
                <TableCell align="right">{line.quantity}</TableCell>
                <TableCell align="right">{line.price < 0 ? `-$${line.price * -1}` : `$${line.price}`}</TableCell>
                <TableCell align="right">{line.amount < 0 ? `-$${line.amount * -1}` : `$${line.amount}`}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} component="th" align="right">{t('labels.total')}</TableCell>
              <TableCell align="right">{amount < 0 ? `-$${amount * -1}` : `$${amount}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 4 }}>
          {!myUserRoleStaff && quickBooksId ? (
            <Button
              name={`Open in quickbooks ${id}`}
              href={`${config.quickBooksUrl}/invoice?txnId=${quickBooksId}`}
              target="_blank"
              rel="noreferrer"
              component="a"
              color="primary"
              size="small"
            >{t('labels.openInQuickBooks')}</Button>
          ) : null}
          {invoiceStatus === 'draft' ? (
            <>
              {!myUserRoleStaff ? (
                <>
                  {(integration && integration.id) ? (
                    <LoadingButton
                      name={`Push to quickbooks ${id}`}
                      loading={stateLoading}
                      disabled={integration.status === 'connected' && (!quickBooksId || modifiedSinceLastPush) ? false : true}
                      onClick={handlePushInvoice}
                      variant="contained"
                      color="primary"
                      size="small"
                    >{t('pages.adminPages.caseDetailsPage.pushToQuickBooks')}</LoadingButton>
                  ) : null}
                  {!quickBooksId ? (
                    <Button
                      name={`Mark sent invoice ${id}`}
                      onClick={toggleSendFormDialog}
                      variant="contained"
                      color="primary"
                      size="small"
                    >{t('pages.adminPages.caseDetailsPage.markSent')}</Button>
                  ) : null}
                </>
              ) : null}
              {!myUserRoleStaff || myUserInvoiceEditEnabled ? (
                <Button
                  name={`Edit invoice ${id}`}
                  onClick={toggle}
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={((integration && integration.status === 'disconnected') && quickBooksId) ? true : false}
                >{t('labels.edit')}</Button>
              ) : null}
            </>
          ) : invoiceStatus === 'sent' && !myUserRoleStaff ? (
            <>
              <Button
                name={`Edit invoice ${id}`}
                onClick={toggle}
                variant="outlined"
                color="primary"
                size="small"
                disabled={((integration && integration.status === 'disconnected') && quickBooksId) ? true : false}
              >{t('labels.edit')}</Button>
              {!quickBooksId ? (
                <Button
                  name={`Mark paid invoice ${id}`}
                  onClick={togglePayFormDialog}
                  variant="contained"
                  color="primary"
                  size="small"
                >{t('pages.adminPages.caseDetailsPage.markPaid')}</Button>
              ) : null}
            </>
          ) : null}
          {!myUserRoleStaff ? (
            !quickBooksId ? (
              <Button
                name={`Delete invoice ${id}`}
                onClick={toggleConfirmationDialog}
                variant="outlined"
                color="error"
                size="small"
              >{t('labels.delete')}</Button>
            ) : (
              <Button
                name={`Delete invoice ${id}`}
                onClick={toggleConfirmationDeleteDialog}
                variant="outlined"
                color="error"
                size="small"
                disabled={integration.status === 'disconnected'}
              >{t('labels.delete')}</Button>
            )
          ) : null}
        </Box>
      </Paper>
      {/* Edit form */}
      {open ? (
        <InvoiceFormDialog
          open={open}
          onClose={toggle}
          invoice={invoice}
        />
      ) : null}
      {/* Send/Pay */}
      {openPayFormDialog ? (
        <InvoiceSendPayFormDialog
          open={openPayFormDialog}
          onClose={togglePayFormDialog}
          invoiceId={id}
        />
      ) : null}
      {openSendFormDialog ? (
        <InvoiceSendPayFormDialog
          open={openSendFormDialog}
          onClose={toggleSendFormDialog}
          invoiceId={id}
          isPayFormAction={false}
        />
      ) : null}
      {/* Confirmation delete */}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.adminPages.caseDetailsPage.deleteInvoice')}
          content={t('pages.adminPages.caseDetailsPage.deleteInvoiceConfirmation')}
          onConfirm={handleConfirm}
        />
      ) : null}
      {openConfirmationDeleteDialog ? (
        <ConfirmationDialog
          open={openConfirmationDeleteDialog}
          onClose={toggleConfirmationDeleteDialog}
          loading={loading}
          title={t('pages.adminPages.caseDetailsPage.deleteInvoice')}
          content={t('pages.adminPages.caseDetailsPage.deleteInvoiceFromQuickbooksConfirmation')}
          onConfirm={handleConfirmDelete}
          onUnconfirm={handleUnconfirmDelete}
          confirmLabel="Yes"
        />
      ) : null}
    </Fragment>
  )
}

export default InvoicesTable;
