import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./DMSDocuments.messages";
// Utilities
import { $get, $post, $put, $patch, $delete } from 'app/utilities/HttpClient';
// Utilties
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { getFileExtFromFileName } from "app/utilities/Utilities";

const _url:string = '/dms/documents';

// Get documents
export const getDocuments = asyncThunkHandler(
  `${Reducers.DMSDocuments}/Get documents`,
  async (params:any, { signal }) => {
    const response:Response = await $get(_url, params, {
      signal
    });
    return response.json();
  }
);

// Get document
export const getDocument = asyncThunkHandler(
  `${Reducers.DMSDocuments}/Get document`,
  async (documentId:number) => {
    const response:Response = await $get(`${_url}/${documentId}`);
    return response.json();
  }
);

// Create document
export const createDocument = asyncThunkHandler(
  `${Reducers.DMSDocuments}/Create document`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update document
export const updateDocument = asyncThunkHandler(
  `${Reducers.DMSDocuments}/Update document`,
  async ({ documentId, data }:{ documentId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${documentId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Patch document
export const patchDocument = asyncThunkHandler(
  `${Reducers.DMSDocuments}/Patch document`,
  async ({ documentId, data }: { documentId: number; data: any; }, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const response: Response = await $patch(`${_url}/${documentId}`, data);
    if (!response.ok) return rejectWithValue((await response.json()) as any);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Delete document
export const deleteDocument = asyncThunkHandler(
  `${Reducers.DMSDocuments}/Delete document`,
  async ({ documentId, data }:{ documentId:number, data:any }, { dispatch }) => {
    const response:Response = await $delete(`${_url}/${documentId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return response.json();
  }
);

// Process document
export const processDocument = asyncThunkHandler(
  `${Reducers.DMSDocuments}/Process document`,
  async (documentId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${documentId}/actions/process`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Process
    }));
    return response.json();
  }
);

// Download document
export const downloadDocument = createAsyncThunk(
  `${Reducers.DMSDocuments}/Download document`,
  async ({ url, documentName }:{ url:string, documentName:string }, { fulfillWithValue }) => {
    const ext = getFileExtFromFileName(documentName);
    const name = documentName.replace(`.${ext}`, '');

    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return fulfillWithValue('Document downloaded');
  }
);
