import { FC, Fragment, useMemo, useState } from 'react';
// Models
import IOption from 'app/models/Option';
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deduplicateAuthors } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Selectors
import { selectAuthorsAsOptions, selectLoading } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// Mui
import { Autocomplete, TextField } from '@mui/material';
// Dialog
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  author: IEpisodeAuthor;
};

const AuthorRegistryDeduplicateDialog:FC<Props> = ({
  // Props
  open, onClose, author
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const authorsOptions:IOption[] | null = useAppSelector(selectAuthorsAsOptions)
  const loading = useAppSelector(selectLoading);

  const [ targetAuthor, setTargetAuthor ] = useState<IOption | null>(null);

  const options = useMemo(() => {
    if ( !authorsOptions ) return null;
    return authorsOptions.filter((option:IOption) => option.id !== author.id);
    // eslint-disable-next-line
  }, []);
  
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleChange = (_:any, nextOption:IOption | null) => {
    setTargetAuthor(nextOption);
  }

  const handleConfirm = async () => {
    if ( !targetAuthor ) return;

    try {
      await dispatch(deduplicateAuthors({
        sourceId: author.id,
        targetId: targetAuthor.id
      })).unwrap();
      onClose();
    } catch(error){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel episode author dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Deduplicate episode author dialog"
        loading={loading}
        onClick={toggleConfirmationDialog}
        variant="contained"
        color="primary"
      >{t('pages.adminPages.authorRegistryPage.deduplicate')}</LoadingButton>
    </Fragment>
  );

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={t('pages.adminPages.authorRegistryPage.deduplicateDialogTitle', { authorName: author.name })}
        actions={actions}
      >
        <Autocomplete
          value={targetAuthor}
          onChange={handleChange}
          options={options || []}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('labels.types')}
              margin="normal"
              fullWidth
            />
          )}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          fullWidth
        />
      </Dialog>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          title={t('pages.adminPages.authorRegistryPage.deduplicateAuthor')}
          content={t('pages.adminPages.authorRegistryPage.deduplicateAuthorConfirmation', { authorName: author.name, targetAuthorName: targetAuthor?.name || '' })}
          loading={loading}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default AuthorRegistryDeduplicateDialog;
