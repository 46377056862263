import { FC, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getReportSection } from 'app/store/ReportSections/ReportSections.async';
// Actions
import { ReportSectionsActions } from 'app/store/ReportSections/ReportSections.slice';
// Selectors
import { selectReportSection } from 'app/store/ReportSections/ReportSections.selectors';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';

const ReportSectionPreviewPage:FC = () => {
  const { t } = useTranslation('common');

  const { reportSectionId } = useParams<{ reportSectionId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const reportSection = useAppSelector(selectReportSection);

  useEffect(() => {
    if ( reportSectionId ) dispatch(getReportSection(Number(reportSectionId)));
    return () => {
      dispatch(ReportSectionsActions.setInitialField('reportSection'));
    }
    // eslint-disable-next-line
  }, []);

  if ( !reportSection ) return <Loader />;
  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{`${t('pages.adminPages.caseDetailsPage.reportSection')}: ${reportSection.name}`}</Typography>
      </Toolbar>
      <Container sx={{ py: 4 }}>
        {!reportSection.content ? <Message text="No data" /> : (
          <Box dangerouslySetInnerHTML={{ __html: reportSection.content }} />
        )}
      </Container>
    </Fragment>
  )
}

export default ReportSectionPreviewPage;
