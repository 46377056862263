import { FC, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createQuestion } from 'app/store/AIChat/AIChat.async';
// Actions
import { AIChatActions } from 'app/store/AIChat/AIChat.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectLoadingMessage, selectStatusMessage } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
// Components
import { IconButton, Input, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import AiChatPromptDialog from './AiChatPromptDialog';

interface IFormData {
  value: string;
  rows: number;
}

const MessageForm:FC = () => {
  const { conversationId } = useParams<{ conversationId:string }>();

  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const loadingMessage = useAppSelector(selectLoadingMessage);
  const statusMessage = useAppSelector(selectStatusMessage);

  const { open, toggle } = useToggle();

  const { register, reset, setValue, watch } = useForm<IFormData>({
    defaultValues: {
      value: '',
      rows: 1,
    },
  });

  const watchValue = watch('value');
  const watchRows = watch('rows');

  useEffect(() => {
    if ( statusMessage === 'sent' ){
      reset({ value: '', rows: 1 });
      dispatch(AIChatActions.setStatusMessage('initial'));
    }
    // eslint-disable-next-line
  }, [statusMessage]);

  const appendValueWithRows = (value:string) => {
    const lineCount = (value.match(/\n/g) || []).length + 1;
    setValue('value', value);
    setValue('rows', Math.min(lineCount, 4));
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Hz how it works, but when I remove this line, "Shift+Enter" stop works
    if ( event.key === 'Enter' && event.shiftKey ){
      // event.preventDefault(); // Prevent default behavior (new line)
    } else if ( event.key === 'Enter' ){
      event.preventDefault(); // Prevent default behavior (form submission)
      handleClick();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    appendValueWithRows(value);
  };

  const handleClick = () => {
    if ( !watchValue || !watchValue.trim() ) return;

    dispatch(AIChatActions.addMessage({ createdOn: new Date().toString(), conversationId, text: watchValue, type: 'human' }));
    dispatch(createQuestion({ conversationId, data: { question: watchValue } }));
  }

  const handleClose = (promptValue?:string) => {
    if ( promptValue ) appendValueWithRows(promptValue);
    if ( open ) toggle();
  }

  const isInputDisabled = insuranceCase?.processing?.status !== 'ready' || loadingMessage;
  const isButtonDisabled = insuranceCase?.processing?.status !== 'ready';

  return (
  <Fragment>
      <Box sx={{
        width: '100%',
        maxWidth: 920,
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        p: 4
      }}>
        <input {...register('rows')} type="hidden" value={watchRows} />
        <Tooltip title="Select prompt">
          <IconButton
            name="Select prompt"
            onClick={toggle}
          ><AddCircleOutlineIcon /></IconButton>
        </Tooltip>
        <Input
          {...register('value')}
          label={t('labels.message')}
          value={watchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          multiline
          rows={watchRows}
          margin="none"
          size="small"
          disabled={isInputDisabled}
        />
        <LoadingButton
          name="Send message"
          loading={loadingMessage}
          disabled={isButtonDisabled}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >{t('labels.send')}</LoadingButton>
      </Box>
      {open ? (
        <AiChatPromptDialog
          open={open}
          onClose={handleClose}
        />
      ) : null}
    </Fragment>
  )
}

export default MessageForm;
