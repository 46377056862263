export const sortByCreatedOn = (a:any, b:any, type = 'asc') => {
  const aCreatedOnTime = new Date(a.createdOn).getTime();
  const bCreatedOnTime = new Date(b.createdOn).getTime();
  if ( aCreatedOnTime < bCreatedOnTime ) return type === 'asc' ? -1 : 1;
  if ( aCreatedOnTime > bCreatedOnTime ) return type === 'asc' ? 1 : -1;
  return 0;
};

export const sortByStapleOrder = (a:any, b:any) => {
  return a.staple.order - b.staple.order;
  // if ( a.staple.order < b.staple.order ) return -1;
  // if ( a.staple.order > b.staple.order ) return 1;
  // return 0;
};

export const sortByWorkspaceOrder = (a:any, b:any, type = 'asc') => {
  if ( a.workspaceOrder < b.workspaceOrder ) return type === 'asc' ? -1 : 1;
  if ( a.workspaceOrder > b.workspaceOrder ) return type === 'asc' ? 1 : -1;
  return 0;
}
