import { FC, Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectExportTemplatesAsOptions } from 'app/store/ExportTemplates/ExportTemplates.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectHcpcsCodesEnabled, selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Box, Divider, FormHelperText, Typography } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Autocomplete';
import { Input, Select, DesktopDatePicker, DesktopTimePicker } from 'app/components/Mui';
import NotificationPreferences from 'app/components/NotificationPreferences';
// Utility
import { isRequired, isDateValid, isTimeValid, isMaxLength } from 'app/utilities/Validations';

interface IFormData {
  name: string;
  reportTemplateId: string | number;
  teamId: string | number;
  clientAccountId?: string | number;
  settings: {
    medicareOverchargeThreshold: number;
  };
  eventDate: Dayjs | null,
  eventTime: Dayjs | null;
  notificationPreferences: {
    emails: string[];
  };
};

type Props = {
  caseId?: number;
  onSetError: (error:boolean) => void;
}

const CaseTabContent:FC<Props> = ({
  // Props
  caseId, onSetError
}) => {
  const { t } = useTranslation('common');

  // State
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const exportTemplatesOptions:IOption[] | null = useAppSelector(selectExportTemplatesAsOptions);
  const teamsOptions:IOption[] | null = useAppSelector(selectTeamsAsOptions);
  const accountsOptions:IOption[] | null = useAppSelector(selectAccountsAsOptions);

  const { control, watch, formState:{ errors, isSubmitted }, setValue } = useFormContext<IFormData>();

  useEffect(() => {
    if ( !isSubmitted ) return;
    const { claimant, claimNumber, ...otherErrors } = errors as any;
    onSetError(Boolean(Object.keys(otherErrors).length));
    // eslint-disable-next-line
  }, [isSubmitted, Object.keys(errors)]);

  const handleSetEmails = (nextEmails:string[]) => {
    setValue('notificationPreferences.emails', nextEmails);
  }

  const watchEventDate= watch('eventDate');
  const watchEmails = watch('notificationPreferences.emails');

  return (
    <Fragment>
      <Controller
        control={control} name="name"
        rules={{
          required: isRequired,
          maxLength: isMaxLength(100)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.caseName')}
            required
            error={Boolean(errors.name)}
            helperText={errors.name?.message || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watch('name').length} / 100`}</FormHelperText>
              )
            }}
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 4 }} >
        <Controller
          control={control} name="eventDate"
          rules={{
            required: isRequired,
            validate: isDateValid
          }}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('labels.eventDate')}
              inputProps={{
                required: true,
                error: Boolean(errors.eventDate),
                helperText: errors.eventDate?.message || ''
              }}
            />
          )}
        />
        {watchEventDate ? (
          <Controller
            control={control} name="eventTime"
            rules={{
              validate: {
                pattern: isTimeValid
              }
            }}
            render={({ field }) => (
              <DesktopTimePicker
                {...field}
                label={t('labels.eventTime')}
                inputProps={{
                  error: Boolean(errors.eventTime),
                  helperText: errors.eventTime?.message || ''
                }}
              />
            )}
          />
        ) : null}
      </Box>
      <Divider sx={{ mt: 4, mb: 2 }} />

      {/* Report template */}
      {legacyReportTemplatesEnabled ? (
        <Controller
          control={control} name="reportTemplateId"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.reportTemplate')}
              options={exportTemplatesOptions}
              required={true}
              error={Boolean(errors.reportTemplateId)}
              helperText={errors.reportTemplateId?.message || ''}
            />
          )}
        />
      ) : null}
      <Controller
        control={control} name="teamId"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            label={t('labels.team')}
            options={teamsOptions || []}
            required={true}
            error={Boolean(errors.teamId)}
            helperText={errors.teamId?.message || ''}
            renderOptions={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                <Typography variant="subtitle1">{option.name}</Typography>
                {option.subname ? (
                  <Typography variant="caption">{option.subname}</Typography>
                ) : null}
              </li>
            )}
          />
        )}
      />
      {/* Client account */}
      {!caseId ? (
        <Controller
          control={control} name="clientAccountId"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              label={t('labels.clientAccount')}
              options={accountsOptions || []}
              required={true}
              error={Boolean(errors.clientAccountId)}
              helperText={errors.clientAccountId?.message || ''}
              renderOptions={(props, option) => (
                <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                  <Typography variant="subtitle1">{option.name}</Typography>
                  {option.props?.fullAddress ? (
                    <Typography variant="caption">{option.props.fullAddress}</Typography>
                  ) : null}
                </li>
              )}
            />
          )}
        />
      ) : null}

      {hcpcsCodesEnabled ? (
        <Fragment>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Controller
            control={control} name="settings.medicareOverchargeThreshold"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.medicareOverchargeThreshold')} type="number"
                error={Boolean(errors.settings?.medicareOverchargeThreshold)}
                helperText={errors.settings?.medicareOverchargeThreshold?.message || ''}
                required={hcpcsCodesEnabled}
              />
            )}
          />
          <FormHelperText>{t('labels.medicareOverchargeThresholdHint')}</FormHelperText>
        </Fragment>
      ) : null}

      <NotificationPreferences
        emails={watchEmails}
        onSetEmails={handleSetEmails}
      />
    </Fragment>
  )
}

export default CaseTabContent;
