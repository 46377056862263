import i18n from "i18n";

const Messages = {
  Share: i18n.t('notifications.caseShares.share'),
  Accept: i18n.t('notifications.caseShares.accept'),
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Share = i18n.t('notifications.caseShares.share');
  Messages.Accept = i18n.t('notifications.caseShares.accept');
});