import { FC } from 'react';
// Models
// import { RootState } from 'app/store';
import { IBillingOrderLine } from 'app/models/BillingOrder';
// Redux
// import { useAppSelector } from 'app/hooks/useStore';
// Async
// import { selectServiceEntity } from 'app/store/billingServices/billingServicesSelectors';
// Components
import { InfoBlock } from 'app/components/Utilities';
// import IBillingService from 'app/models/BillingService';

type Props = {
  line: IBillingOrderLine
};

const OrderLineItem:FC<Props> = ({
  // Props
  line
}) => {
  // State
  // const service:IBillingService = useAppSelector((state:RootState) => selectServiceEntity(state, {
  //   serviceId: line.serviceId
  // }));

  // const label = service?.name || 'Undefined';

  return (
    <InfoBlock label={line.service.name} value={line.quantity} />
  )
}

export default OrderLineItem;
