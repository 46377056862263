import { FC, Fragment } from 'react';
import { Dayjs } from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createEpisode, deleteEpisode, patchEpisode } from 'app/store/Episodes/Episodes.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectPageDates } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectLoading, selectEpisodeEntity } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import PageDates from 'app/components/PageDates';
import { Button, LoadingButton, DesktopDatePicker, DesktopTimePicker } from 'app/components/Mui';
// Utilities
import { parseDate, parseTime, dateToString, timeToString, setPreEvent } from 'app/utilities/DateTime';
import { validateEpisodeDate, validateEpisodeTime } from 'app/utilities/Validations';
// ToDO
// Actions
import PageActions from 'app/store/page/page.actions';
// Service
import { generateWorkspaceOrder } from 'app/store/page/page.service';
// End: ToDO

interface IFormData {
  date: Dayjs | null;
  time: Dayjs | null;
}

type Props = {
  open: boolean;
  onClose: () => void;
  page: any;
}

const CardPageDateDialog:FC<Props> = ({
  // Props
  open, onClose, page
}) => {
  const { documentId, pageNum, inWorkspace, workspaceOrder } = page;

  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const loading = useAppSelector(selectLoading);
  const pageDates = useAppSelector((state:RootState) => selectPageDates(state, {
    documentId,
    pageNum
  }));
  const episodeEntity = useAppSelector((state:RootState) => selectEpisodeEntity(state, {
    episodeId: page.parentEpisodeId
  }));

  const { control, handleSubmit, watch, setValue, formState:{ errors }} = useForm<IFormData>({
    defaultValues: {
      date: episodeEntity?.date ? parseDate(episodeEntity.date) : null,
      time: episodeEntity?.time ? parseTime(episodeEntity.time) : null
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const { date, time } = data;
    const nextData:any = {
      date: null,
      time: null,
      preEvent: setPreEvent({
        eventDate: insuranceCase.eventDate,
        eventTime: insuranceCase.eventTime,
        currentDate: date,
        currentTime: time
      }),
    }
    if ( date ){
      nextData['date'] = dateToString(date);
      if ( time ) nextData['time'] = timeToString(time);
    }
    if ( episodeEntity?.id ){
      if ( !date && !episodeEntity.authorId && !episodeEntity.type && !episodeEntity.notes ){
        asyncDeleteEpisode(episodeEntity.id)
      } else {
        nextData['version'] = episodeEntity.version;
        asyncPatchEpisode(episodeEntity.id, nextData);
      }
    } else {
      nextData['documentId'] = documentId;
      nextData['pageNum'] = pageNum;
      nextData['showInTimeline'] = true;
      asyncCreateEpisode(nextData);
    }
  });

  const asyncCreateEpisode = async (data:any) => {
    try {
      const episode = await dispatch(createEpisode(data)).unwrap();
      asyncPatchPage(episode.id, episode.date, episode.time);
      onClose();
    } catch(error){}
  }

  const asyncPatchEpisode = async (episodeId:number, data:any) => {
    try {
      const episode = await dispatch(patchEpisode({ episodeId, data })).unwrap();
      asyncPatchPage(episode.id, episode.date, episode.time);
      onClose();
    } catch(error){}
  }

  const asyncDeleteEpisode = async (episodeId:number) => {
    try {
      if ( page.parentEpisodeId === episodeId ){
        await asyncPatchPage(null, undefined, undefined);
      }
      await dispatch(deleteEpisode(episodeId)).unwrap();
      onClose();
    } catch(error){}
  }

  // ToDO
  const asyncPatchPage = async (parentEpisodeId:number | null, date:string | undefined, time:string | undefined) => {
    // Patch page
    const nextPageData:any = {
      version: page.version,
      parentEpisodeId
    };
    if ( inWorkspace ){
      nextPageData['workspaceOrder'] = generateWorkspaceOrder(null, {
        workspaceOrder,
        date,
        time
      });
    }
    return new Promise((resolve) => {
      dispatch(PageActions.patchPage(documentId, pageNum, nextPageData));
      setTimeout(() => {
        resolve(`Page updated`)
      }, 500);
    });
  }

  const handleClick = (date:string) => {
    setValue('date', parseDate(date));
  };

  const watchDate = watch('date');

  const actions = (
    <Fragment>
      <Button
        name="Cancel page date dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Save page date dialog"
        loading={loading}
        type="submit"
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('dialogs.pageEpisodeDate.parentEpisodeDate')}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      <PageDates
        dates={pageDates}
        onClick={handleClick}
        showMoreVisible={false}
      />

      <Box sx={{ display: 'flex', gap: 4 }} >
        <Controller
          control={control} name="date"
          rules={{
            validate: validateEpisodeDate
          }}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('labels.date')}
              inputProps={{
                error: Boolean(errors.date),
                helperText: errors.date?.message || ''
              }}
            />
          )}
        />
        {watchDate ? (
          <Controller
            control={control} name="time"
            rules={{
              validate: validateEpisodeTime
            }}
            render={({ field }) => (
              <DesktopTimePicker
                {...field}
                label={t('labels.time')}
                inputProps={{
                  error: Boolean(errors.time),
                  helperText: errors.time?.message || ''
                }}
              />
            )}
          />
        ) : null}
      </Box>
    </Dialog>
  )
}

export default CardPageDateDialog;
