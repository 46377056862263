import React from 'react';
// Models
import { IPage } from '@root/models/Page';
// MaterialUI
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// Components
import PageEpisodes from 'app/components/PageEpisodes';
import ColorPicker from 'app/components/ColorPicker';
import TagPicker from 'app/components/TagPicker';
import Icon from 'app/components/Icon';
// i18next
import { useTranslation } from 'react-i18next';
import { Loader } from 'app/components/Utilities';

type Props = {
  page: IPage;
}

const CardPageStaplePlaceholder:React.FC<Props> = ({
  // Props
  page
}) => {
  const { t } = useTranslation('common');

  if ( !page ) return <Loader />;
  return (
    <React.Fragment>
      <Icon
        icon="stapled"
        size="small"
        label={t('dialogs.cardPage.stapled')}
      />
      <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
      {page.tag ? (
        <div style={{ marginBottom: '16px' }}>
          <TagPicker
            id="page-tag-form"
            activeTags={page.tag ? [page.tag] : []}
          />
        </div>
      ) : null}
      {/* Color */}
      {page.colors && page.colors.length ? (
        <div style={{ marginBottom: '16px' }}>
          <ColorPicker
            id="page-form"
            activeColors={page.colors}
          />
        </div>
      ) : null}
      <div style={{ marginBottom: '16px' }}>
        <FormControlLabel
          control={
            <Switch
              color="default"
              name="inWorkspace"
              checked={page.inWorkspace}
              disabled={true}
              onChange={() => null}
            />
          }
          label={t('dialogs.cardPage.includePageIntoWorkspace')}
        />
      </div>
      <PageEpisodes />
    </React.Fragment>
  )
}

export default CardPageStaplePlaceholder;
