import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Types
import Reducers from 'app/types/Reducers';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createOrder, updateOrder } from 'app/store/BillingOrders/BillingOrders.async';
// Selectors
import { selectLoading } from 'app/store/BillingOrders/BillingOrders.selectors';
// Mui
import { Button, Box, Divider, Typography, Tooltip, IconButton, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import Icon from 'app/components/Icon';
import Dialog from 'app/components/Dialog';
import { Input, Select } from 'app/components/Mui';
import { InfoBlock, Message } from 'app/components/Utilities';
// Utility
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  description: string;
  lines: any;
};

type Props = {
  open: boolean;
  onClose: () => void;
  order?: any;
};

const OrderFormDialog:FC<Props> = ({
  // Props
  open, onClose, order
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);
  // ToDO
  const items:any = useAppSelector((state:any) => {
    const currentBilling = state[Reducers.Accounts].currentAccountBilling;
    if ( !currentBilling || !currentBilling.subscription ) return null;
    return currentBilling.subscription.items.filter((item:any) => item.additional)
  });

  const [ serviceId, setServiceId ] = useState('');

  const { register, control, handleSubmit, formState: { errors, isSubmitted } } = useForm<IFormData>({
    defaultValues: {
      description: order?.description || '',
      lines: order?.lines || ''
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lines'
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    if ( !data.lines.length ) return;

    const nextData = {
      caseId,
      description: data.description,
      lines: data.lines.map((line:any) => ({
        serviceId: Number(line.service.id),
        quantity: Number(line.quantity)
      }))
    };
    if ( order && order.id ){
      asyncUpdateOrder({ orderId: order.id, data: nextData });
    } else {
      asyncCreateOrder(nextData);
    }
  });

  const asyncUpdateOrder = async (nextData:any) => {
    try {
      await dispatch(updateOrder(nextData)).unwrap();
      onClose();
    } catch(e) {}
  }

  const asyncCreateOrder = async (data:IFormData) => {
    try {
      await dispatch(createOrder(data)).unwrap();
      onClose();
    } catch(e) {}
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setServiceId(event.target.value.toString());
  }

  const handleClickAdd = () => {
    if ( serviceId ){
      const foundedItem = items.find((item:any) => item.service.id === Number(serviceId));
      if ( !foundedItem ) return;
      append({ service: foundedItem.service, price: foundedItem.price, quantity: 0 });
      setServiceId('');
    }
  }

  const handleClickRemove = (index:number) => {
    remove(index);
  }

  const isButtonDisabled = Boolean(serviceId);

  const getErrorMessage = (index:number, field:string) => {
    // @ts-ignore
    return errors.lines && errors.lines[index] && errors.lines[index][field] ? errors.lines[index][field].message : ''
  }

  const fieldServiceIds = fields.map((field:any) => field.service.id.toString());

  const filteredServiceOptions = items
    ? items.filter((item:any) => !fieldServiceIds.includes(item.service.id.toString()))
    : []
  ;

  const actions = (
    <Fragment>
      <Button
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={order && order.id ? t('pages.adminPages.ordersPage.updateOrder') : t('pages.adminPages.ordersPage.createOrder')}
      actions={actions}
    >
      <form noValidate>
        {/* Description */}
        <Controller
          control={control} name="description"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.description')}
              error={Boolean(errors.description)}
              helperText={errors.description ? errors.description.message : ''}
              required
              multiline
              rows={5}
            />
          )}
        />

        <Box paddingTop={4}>
          {items.length ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Select
                label={t('labels.service')} value={serviceId}
                onChange={handleChange}
                options={filteredServiceOptions.map((item:any) => ({
                  id: item.service.id,
                  name: item.service.name
                }))}
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                margin="none"
              />
              <Button
                disabled={!isButtonDisabled}
                onClick={handleClickAdd}
                variant="contained"
              >{t('labels.add')}</Button>
            </Box>
          ) : (
            <Message text={t('pages.adminPages.ordersPage.noAdditionalServicesAvailable')} />
          )}
          {!fields.length && isSubmitted ? (
            <FormHelperText sx={{ pl: 4 }} error>{t('pages.adminPages.ordersPage.oneServicesShouldBeSelected')}</FormHelperText>
          ) : null}
          {fields.length ? (
            <Divider sx={{ my: 4 }} />
          ) : null}
          {fields.map((field:any, index:number) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                pb: 4,
                '& + &': {
                  pt: 4,
                  borderTop: '1px solid rgba(0,0,0,0.08)'
                }
              }}
              key={`service-item-${field.id}`}
            >
              <input
                {...register(`lines.${index}.service.id`)}
                type="hidden"
                value={field.service.id}
              />
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle2"
                >{field.service.name}</Typography>
                {field.service.description ? (
                  <Typography variant="body2" color="GrayText">{field.service.description}</Typography>
                ) : null}
                <InfoBlock label={t('labels.price')} value={`$${field.price}`} direction="row" />
              </Box>
              <Controller
                control={control}
                name={`lines.${index}.quantity`}
                defaultValue={field.quantity}
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('pages.adminPages.ordersPage.quantity')} type="number"
                    error={Boolean(getErrorMessage(index, 'quantity'))}
                    helperText={getErrorMessage(index, 'quantity')}
                    required
                    margin="none"
                    sx={{ maxWidth: 220 }}
                  />
                )}
              />
              <Tooltip title={t('pages.adminPages.ordersPage.deleteLine')}>
                <IconButton
                  sx={{ alignSelf: 'center' }}
                  onClick={() => handleClickRemove(index)}
                ><Icon icon="delete" /></IconButton>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </form>
    </Dialog>
  );
}

export default OrderFormDialog;
