import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import IAuditRecord from 'app/models/AuditRecord';
// Models
import { RootState } from 'app/store';
// Utilities
import { sortByCreationDate } from 'app/utilities/SortBy';

export const selectAuditRecords = (state:RootState) => state[Reducers.Audit].auditRecords;
export const selectLoading = (state:RootState) => state[Reducers.Audit].loading;
export const selectSearch = (state:RootState) => state[Reducers.Audit].search;

export const selectFilteredAuditRecords = createSelector(
  [ selectAuditRecords, selectSearch ],
  ( auditRecords:IAuditRecord[] | null, search: string ) => {
    if ( !auditRecords ) return null;
    return [...auditRecords]
      .filter(record => record.description.toLocaleLowerCase().includes(search.toLocaleLowerCase().trim()))
      .sort(sortByCreationDate)
  }
);
