// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post } from 'app/utilities/HttpClient';

const _url:string = '/auditRecords';

export const getAuditRecords = asyncThunkHandler(
  `${Reducers.Audit}/Get audit records`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const createAuditRecord = asyncThunkHandler(
  `${Reducers.Audit}/Create audit record`,
  async (data:any) => {
    const response:Response = await $post(_url, data);
    return response.json();
  }
);
