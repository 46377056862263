import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import ISnackbar from "app/models/Snackbar";
import ISystemMessage from "app/models/SystemMessage";
// Utilities
import { uuidv4 } from 'app/utilities/Utilities';

interface IPushAlert {
  caseId: number;
  message: string;
}

interface IState {
  snackbars: ISnackbar[];
  systemMessages: ISystemMessage[];
  pushAlerts: {
    case: {
      labels: IPushAlert | null;
      notes: IPushAlert | null;
    }
  };
};

const initialState:IState = {
  snackbars: [],
  systemMessages: [],
  pushAlerts: {
    case: {
      labels: null,
      notes: null
    }
  }
};

const slice = createSlice({
  name: Reducers.AppUiNotifications,
  initialState,
  reducers: {
    addSnackbar: (state, action:PayloadAction<ISnackbar>) => {
      const key = action.payload.key || uuidv4();
      state.snackbars = [
        ...state.snackbars,
        { ...action.payload, key }
      ];
    },
    closeSnackbar: (state, action:PayloadAction<{ key:string, dismissAll:boolean }>) => {
      state.snackbars = state.snackbars.map((snackbar:ISnackbar) =>
        (action.payload.dismissAll || snackbar.key === action.payload.key)
          ? { ...snackbar, dismissed: true }
          : snackbar
      )
    },
    removeSnackbar: (state, action:PayloadAction<string>) => {
      state.snackbars = state.snackbars.filter((snackbar:ISnackbar) => snackbar.key !== action.payload)
    },

    addSystemMessage: (state, action:PayloadAction<ISystemMessage>) => {
      const id = action.payload.id || uuidv4();
      state.systemMessages = [
        ...state.systemMessages,
        { ...action.payload, id }
      ];
    },
    removeSystemMessage: (state, action:PayloadAction<string>) => {
      state.systemMessages = state.systemMessages.filter((systemMessage:ISystemMessage) => {
        return systemMessage.id !== action.payload
      })
    },

    addPushAlert: (state, action:PayloadAction<{
      category: keyof IState['pushAlerts'],
      type: keyof IState['pushAlerts'][keyof IState['pushAlerts']];
      alert: IPushAlert
    }>) => {
      const { category, type, alert } = action.payload;
      state.pushAlerts[category][type] = alert;
    },
    removePushAlert: (state, action:PayloadAction<{
      category:keyof IState['pushAlerts'],
      type:keyof IState['pushAlerts'][keyof IState['pushAlerts']];
    }>) => {
      const { category, type } = action.payload;
      state.pushAlerts[category][type] = initialState.pushAlerts[category][type];
    },

    setInitialField: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  }
});

export const AppUiNotificationsActions = slice.actions;

export default slice.reducer;
