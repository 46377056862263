import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useCaseStatus from 'app/hooks/useCaseStatus';
// 
import SharedCaseFormDialog from './ShareFormDialog';

const SharesFab:FC = () => {
  const { t } = useTranslation('common');
  // State
  const myUser = useSelector(selectMyUser);

  const { isArchived } = useCaseStatus();

  const { open, toggle } = useToggle();

  if ( !myUser || myUser.role !== 'admin' ) return null;
  return (
    <Fragment>
      <Fab
        name="Create case share"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        disabled={isArchived}
        onClick={toggle}
        color="primary"
        variant="extended"
      >{t('pages.adminPages.sharedCasesPage.createCaseShare')}</Fab>
      {open ? (
        <SharedCaseFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default SharesFab;
