import React from 'react';
// Types
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectPageStaples, selectIsLoading } from 'app/store/page/page.selectors';
// Components
import TagPicker from 'app/components/TagPicker';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import IInsuranceCase from 'app/models/Case';

type Props = {
  page: IPage;
  isDisabled?: boolean;
}

const CardPageTag:React.FC<Props> = ({
  // Props
  page, isDisabled = false
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const insuranceCase = useSelector(selectInsuranceCase) as IInsuranceCase;
  const staplePages = useSelector(selectPageStaples)
  const loading = useSelector(selectIsLoading);

  const handleClick = (newTag:string | null) => {
    if ( loading ) return;

    const tag = page.tag === newTag ? null : newTag;
    const message = tag ? pageMessages.updatePageTag : pageMessages.removePageTag;
    if ( staplePages && page.staple && page.staple.order === 1 ){
      const pagesToUpdate = staplePages.map((page:any) => ({
        caseId: insuranceCase.id,
        documentId: page.documentId,
        pageNum: page.pageNum,
        version: page.version,
        tag
      }));
      dispatch(PageActions.patchPages(pagesToUpdate, message));
    } else {
      dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
        version: page.version,
        tag
      }, message))
    }
  }

  const disabled = isDisabled || loading;

  return (
    <div style={{ marginBottom: '16px' }}>
      <TagPicker
        id="page-tag-form"
        activeTags={page.tag ? [page.tag] : []}
        onClick={handleClick}
        isDisabled={disabled}
      />
    </div>
  )
}

export default CardPageTag;