import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { payInvoice } from 'app/store/BillingInvoices/BillingInvoices.async';
// Selectors
import { selectLoading } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import Dialog from 'app/components/Dialog';
import { DesktopDateTimePicker } from 'app/components/Mui';
// Utility
import { isRequired, validateDateTime } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

dayjs.extend(isSameOrAfter);

interface IFormData {
  date: Dayjs | null;
};

type Props = {
  open: boolean;
  onClose: () => void;
  invoiceId: number;
};

const InvoicePayFormDialog:FC<Props> = ({
  // Props
  open, onClose, invoiceId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors } } = useForm<any>({
    defaultValues: {
      date: null
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    dayjs.extend(utc);
    try {
      const nextData = { date: dayjs.utc(data.date).toISOString() };
      await dispatch(payInvoice({ invoiceId, data: nextData })).unwrap();
      onClose();
    } catch(error){}
  });

  const actions = (
    <Fragment>
      <Button
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.accountManagerPages.billingInvoicesPage.markInvoicePaid')}
      actions={actions}
    >
      <form noValidate>
        <Controller
          control={control} name="date"
          rules={{
            required: isRequired,
            validate: validateDateTime
          }}
          render={({ field }) => (
            <DesktopDateTimePicker
              {...field}
              label={t('labels.dateAndTime')}
              inputProps={{
                required: true,
                error: Boolean(errors.date),
                helperText: errors.date?.message as string || ''
              }}
            />
          )}
        />
      </form>
    </Dialog>
  );
}

export default InvoicePayFormDialog;
