import { FC, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getAccounts } from 'app/store/AccountsManagement/AccountsManagement.async';
import { getInvoices } from 'app/store/BillingInvoices/BillingInvoices.async';
import { getInvoicesSummary } from 'app/store/BillingInvoices/BillingInvoices.async';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectParams } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Toolbar, Container, Typography } from '@mui/material';
// 
import InvoicesSidebar from './InvoicesSidebar';
import InvoicesSummary from './InvoicesSummary';
import InvoicesList from './InvoicesList';
import InvoicesPagination from './InvoicesPagination';
// ToDO: Should be reworked
import InvoicesQuckbooks from 'app/pages/Staff/CaseDetailsPage/InvoicesPage/InvoicesQuickbooks';
// i18next
import { useTranslation } from 'react-i18next';

const BillingInvoicesPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const params:any = useAppSelector(selectParams);

  useEffect(() => {
    dispatch(getAccounts({}));

    return () => {
      dispatch(BillingInvoicesActions.setInitialField('invoices'));
      dispatch(BillingInvoicesActions.setInitialField('params'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { limit, offset, dateRangeStart, dateRangeEnd, ...otherParams } = params;

    if ( !dateRangeStart || !dateRangeEnd ) return;

    const nextParams = {
      ...otherParams,
      dateRangeStart: dateRangeStart ? dateRangeStart.format('YYYY-MM-DD') : null,
      dateRangeEnd: dateRangeEnd ? dateRangeEnd.add(1, 'day').format('YYYY-MM-DD') : null
    };
    dispatch(getInvoices({...nextParams, limit, offset}));
    dispatch(getInvoicesSummary(nextParams));
    // eslint-disable-next-line
  }, [params]);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }} >
      <InvoicesSidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Toolbar variant="dense">
          <Typography variant="h6">{t('pages.accountManagerPages.billingInvoicesPage.billingInvoices')}</Typography>
        </Toolbar>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
          <Container sx={{ py: 4 }} maxWidth="md">
            <InvoicesSummary />
            <Box sx={{ mb: 4 }}>
              <InvoicesQuckbooks />
            </Box>
            <InvoicesList />
            <InvoicesPagination />
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default BillingInvoicesPage;
