import { FC, Fragment } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Divider, ListItemButton, ListItemText } from '@mui/material';

type Props = {
  onClose: () => void;
}

const AdminMenuItems:FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');
  // State
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);

  const listItems = [
    { to: '/admin/cases', type: 'item', label: t('labels.cases') },
    { to: '/admin/users', type: 'item', label: t('components.userMenu.casePortalUsers') },
    { to: '/admin/teams', type: 'item', label: t('components.userMenu.casePortalTeams') },
    { to: '/admin/client-accounts', type: 'item', label: t('components.userMenu.clientAccounts') },
    { to: '/admin/author-registry', type: 'item', label: t('components.userMenu.authorRegistry') },
    { to: '/admin/terms-and-conditions', type: 'item', label: t('components.userMenu.termsAndConditions') },
    { to: '/admin/document-collections', type: 'item', label: t('components.userMenu.documentCollections') },
    { type: 'divider' },
    { to: '/admin/company-account', type: 'item', label: t('labels.companyAccount') },
    { to: '/admin/billing-invoices', type: 'item', label: t('components.userMenu.billingInvoices') },
    { to: '/admin/analytics', type: 'item', label: t('labels.usageReport') },
    { type: 'divider' },
    { to: '/admin/report-templates', type: 'item', label: t('components.userMenu.reportTemplates'), hidden: !legacyReportTemplatesEnabled },
    { to: '/admin/report-section-templates', type: 'item', label: t('components.userMenu.reportSectionTemplates'), hidden: !legacyReportTemplatesEnabled },
    { to: '/admin/presets', type: 'item', label: t('components.userMenu.reportPresets') },
    { to: '/admin/templates', type: 'item', label: t('components.userMenu.templates') },
    { to: '/admin/ai-chat-prompts', type: 'item', label: t('components.userMenu.aiChatPrompts') },
    { type: 'divider' }
  ];

  if ( !myUser || myUser.role !== UserRoles.Admin ) return null;
  return (
    <Fragment>
      {listItems.map((listItem:any, index:number) => {
        if ( listItem.hidden ) return null;
        if ( listItem.type === 'divider' ) return (
          <Divider key={`user menu list divider ${index}`} component="li" />
        );
        return (
          <ListItemButton
            key={`user menu list item ${index}`}
            component={Link}
            to={listItem.to}
            onClick={onClose}
          ><ListItemText primary={listItem.label} /></ListItemButton>
        );
      })}
    </Fragment>
  )
}

export default AdminMenuItems;
