// Types
import Reducers from 'app/types/Reducers';
// Actions
import { DMSUploadsActions } from "./DMSUploads.slice";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $post } from 'app/utilities/HttpClient';

const _url:string = `/dms/uploads`;

export const createFileUploadToken = asyncThunkHandler(
  `${Reducers.DMSUploads}/Create file upload token`,
  async (data:any) => {
    const response:Response = await $post(`${_url}/tokens`, data);
    return response.json();
  }
);

export const createPreSignedUrlForFile = asyncThunkHandler(
  `${Reducers.DMSUploads}/Create pre signed url for file`,
  async ({ index, data }:{ index:number, data:any }, { rejectWithValue, dispatch }) => {
    const response:Response = await $post(`${_url}/presignedUrls`, data);
    if ( !response.ok ){
      dispatch(DMSUploadsActions.addPreSignedUrl({ index, url: null }));
      return rejectWithValue((await response.json()) as any);
    }
    return response.json();
  }
);
