// Types
import Reducers from 'app/types/Reducers';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./Terms.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/termsAndConditions';

// Get terms and conditions
export const getTermsAndConditions = asyncThunkHandler(
  `${Reducers.Terms}/Get terms and conditions`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);
// Create term and condition
export const createTerms = asyncThunkHandler(
  `${Reducers.Terms}/Create term and condition`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);
// Update term and condition
export const updateTerms = asyncThunkHandler(
  `${Reducers.Terms}/Update term and condition`,
  async ({ id, data }:{ id:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${id}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);
// Delete term and condition
export const deleteTerms = asyncThunkHandler(
  `${Reducers.Terms}/Delete term and condition`,
  async (id:number, { fulfillWithValue, dispatch }) => {
    await $delete(`${_url}/${id}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(id);
  }
);
// Publish term and condition
export const publishTerms = asyncThunkHandler(
  `${Reducers.Terms}/Publish term and condition`,
  async (id:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${id}/actions/publish`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Publish
    }));
    return response.json();
  }
);
// Archive term and condition
export const archiveTerms = asyncThunkHandler(
  `${Reducers.Terms}/Archive term and condition`,
  async (id:number, { fulfillWithValue, dispatch }) => {
    const response:Response = await $post(`${_url}/${id}/actions/archive`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Archive
    }));
    return response.json();
  }
);