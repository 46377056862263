// Models
import IEpisode from "app/models/Episode";

class EpisodeFactory implements IEpisode {
  id: number = 0;
  version: number = 0;
  documentId: number = 0;
  pageNum: number = 0;
  date?: string = '';
  time?: string = '';
  preEvent: boolean = false;
  showInTimeline: boolean = false;
  authorId?: number = 0;
  author?: {
    id: number;
    name: string;
    verified: boolean;
  } = undefined;
  type?: string = '';
  notes?: string = '';
  labels?: string[] = [];
  insuranceCase?: {
    id: number;
    name: string;
  } = undefined;

  create(data:Partial<IEpisode>):IEpisode {
    return {
      id: data.id || this.id,
      version: data.version || this.version,
      documentId: data.documentId || this.documentId,
      pageNum: data.pageNum || this.pageNum,
      date: data.date || this.date,
      time: data.time || this.time,
      preEvent: data.preEvent || this.preEvent,
      showInTimeline: data.showInTimeline || this.showInTimeline,
      authorId: data.authorId || this.authorId,
      author: data.author !== undefined ? data.author : this.author,
      type: data.type || this.type,
      notes: data.notes || this.notes,
      labels: data.labels || this.labels,
      insuranceCase: data.insuranceCase !== undefined ? data.insuranceCase : this.insuranceCase,
    };
  }
}

const factory = new EpisodeFactory();
export default factory;
