import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Utilities
import { $get, $put } from 'app/utilities/HttpClient';
// 
import { STORAGE_KEY } from './currentUserSelectors';

const _url:string = '/users/me';

// Get my preferences
export const getMyPreferences = createAsyncThunk(
  `${Reducers.CurrentUser}/Get my preferences`,
  async (preferenceName:string) => {
    const response:Response = await $get(`${_url}/preferences/${preferenceName}`);
    if ( !response.ok ){
      // return rejectWithValue((await response.json()) as any)
      throw Error('Getting an error while fetching user preferences');
    }
    return response.json();
  }
);
// Update my preferences
export const updateMyPreferences = createAsyncThunk(
  `${Reducers.CurrentUser}/Update my Preferences`,
  async ({ preferenceName, data }:{ preferenceName:string, data:any }, { rejectWithValue }) => {
    const response:Response = await $put(`${_url}/preferences/${preferenceName}`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    localStorage.removeItem(STORAGE_KEY);
    return response.json();
  }
);
