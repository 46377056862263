import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectExportTemplatesAsOptions } from 'app/store/ExportTemplates/ExportTemplates.selectors';
import { selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Components
import { Select } from 'app/components/Mui';
// Utility
import { isRequired } from 'app/utilities/Validations';

interface IFormData {
  reportTemplateId: string | number;
}

const ReportStepContent:FC = () => {
  const { t } = useTranslation('common');

  // State
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const exportTemplatesOptions = useAppSelector(selectExportTemplatesAsOptions);

  const { control, formState: { errors } } = useFormContext<IFormData>();

  if ( !legacyReportTemplatesEnabled ) return null;
  return (
    <Controller
      control={control} name="reportTemplateId"
      rules={{ required: isRequired }}
      render={({ field }) => (
        <Select
          {...field}
          label={t('labels.reportTemplate')}
          options={exportTemplatesOptions}
          required={true}
          error={Boolean(errors.reportTemplateId)}
          helperText={errors.reportTemplateId?.message || ''}
        />
      )}
    />
  );
};

export default ReportStepContent;