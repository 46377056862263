import { FC, Fragment, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Types
import Features, { DatasetHPLFeatures } from 'app/types/Features';
// Models
import IManagementAccount from 'app/models/ManagementAccount';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPackage } from 'app/store/BillingPackages/BillingPackages.async';
import { updateAccount, getAccountBilling } from 'app/store/AccountsManagement/AccountsManagement.async';
// Actions
import { AccountsManagementActions } from 'app/store/AccountsManagement/AccountsManagement.slice';
import { BillingPackagesActions } from 'app/store/BillingPackages/BillingPackages.slice';
// Selectors
import { selectHasPackageCptCodesAccess } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectAccountBilling, selectLoading } from 'app/store/AccountsManagement/AccountsManagement.selectors';
// Mui
import { FormGroup } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Select, Switch, Button, LoadingButton } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utility
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  quickBooksId: string;
  // Features
  datasetHPL: DatasetHPLFeatures | undefined;
  features: (Features)[];
};

type Props = {
  open: boolean;
  onClose: () => void;
  account: IManagementAccount;
};

const AccountEditFormDialog:FC<Props> = ({
  // Props
  open, onClose, account
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const hasPackageCptCodesAccess = useAppSelector(selectHasPackageCptCodesAccess);

  const accountBilling = useAppSelector(selectAccountBilling);
  const loading:boolean = useAppSelector(selectLoading);

  const getDatasetHPLValue = useCallback(() => {
    const datasetHPLFeatures =  [Features.DatasetHPL_FL, Features.DatasetHPL_CA, Features.DatasetHPL_WA];

    if ( !account.features || !account.features.length ) return undefined;

    for ( let feature of datasetHPLFeatures ){
      if ( account.features.includes(feature) ) return feature as DatasetHPLFeatures;
    }

    return undefined;
    // eslint-disable-next-line
  }, [account.features]);

  const { control, handleSubmit, formState: { errors } } = useForm<IFormData>({
    defaultValues: {
      quickBooksId: account.quickBooksId,
      datasetHPL: getDatasetHPLValue(),
      features: account.features.filter((feature) => 
        feature !== Features.DatasetHPL_FL &&
        feature !== Features.DatasetHPL_WA &&
        feature !== Features.DatasetHPL_CA
      )
    }
  });

  useEffect(() => {
    dispatch(getAccountBilling(account.id));
    return () => {
      dispatch(AccountsManagementActions.setInitialField('accountBilling'));
      dispatch(BillingPackagesActions.setInitialField('package'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( accountBilling?.subscription?.subscriptionPackage?.id ){
      dispatch(getPackage(accountBilling.subscription.subscriptionPackage.id));
    }
    // eslint-disable-next-line
  }, [accountBilling])

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      const { features, datasetHPL, ...otherData } = data;
      const nextData:any = {
        ...otherData,
        features: features.length ? features : []
      };
      if ( datasetHPL ) nextData['features'] = [...nextData['features'], datasetHPL];
      await dispatch(updateAccount({ accountId: account.id, data: nextData })).unwrap();
      onClose();
    } catch(error){}
  });

  const actions = (
    <Fragment>
      <Button
        name="Cancel update company account dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Save company account dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.save')}</LoadingButton>
    </Fragment>
  );

  const datasetHPLOptions = [
    { id: '', name: 'Choose author matching dataset' },
    { id: Features.DatasetHPL_FL, name: 'FL' },
    { id: Features.DatasetHPL_WA, name: 'WA' },
    { id: Features.DatasetHPL_CA, name: 'CA' },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.accountManagerPages.companyAccountsPage.updateCompanyAccount')}
      actions={actions}
    >
      <form noValidate>
        {/* Quickbooks ID */}
        <Controller
          control={control} name="quickBooksId"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.quickbooksId')}
              error={Boolean(errors.quickBooksId)}
              helperText={errors.quickBooksId ? errors.quickBooksId.message : ''}
              required
            />
          )}
        />
        <OutlineBlock sx={{ mt: 6 }} label={t('labels.features')}>
          <Fragment>
            <Controller
              control={control} name="features"
              render={({ field:{ onChange, value } }) => {
                const handleChange = (feature:Features) => () => {
                  const hasFeature = (value as (Features)[]).includes(feature);
                  const nextFeatures = hasFeature
                    ? (value as (Features)[]).filter((v) => v !== feature)
                    : [...value, feature]
                  ;
                  onChange(nextFeatures);
                }
                return (
                  <FormGroup>
                    <Switch
                      name="AI Chat"
                      label="AI Chat"
                      value={value.includes(Features.AIChat)}
                      onChange={handleChange(Features.AIChat)}
                    />
                    {hasPackageCptCodesAccess ? (
                      <Switch
                        name="Cpt codes access"
                        label={t('pages.accountManagerPages.companyAccountsPage.cptCodesAccess')}
                        value={value.includes(Features.HcpcsCodes)}
                        onChange={handleChange(Features.HcpcsCodes)}
                        helperText={t('pages.accountManagerPages.companyAccountsPage.cptCodesAccessHelperText')}
                      />
                    ) : null}
                    <Switch
                      name="Automatic page classification"
                      label={t('pages.accountManagerPages.companyAccountsPage.automaticPageClassification')}
                      value={value.includes(Features.PageClassification)}
                      onChange={handleChange(Features.PageClassification)}
                      helperText={t('pages.accountManagerPages.companyAccountsPage.automaticPageClassificationHelperText')}
                    />
                    <Switch
                      name="Patient portal"
                      label={t('pages.accountManagerPages.companyAccountsPage.patientPortal')}
                      value={value.includes(Features.PatientPortal)}
                      onChange={handleChange(Features.PatientPortal)}
                    />
                  </FormGroup>
                )
              }}
            />
            <Controller
              control={control} name="datasetHPL"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('pages.accountManagerPages.companyAccountsPage.authorMatchingDataset')}
                  options={datasetHPLOptions}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
      </form>
    </Dialog>
  );
}

export default AccountEditFormDialog;
