import { FC, useEffect, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getBatch } from 'app/store/DMSBatches/DMSBatches.async';
import { createDocument, updateDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Api
import dmsDocumentsApi, { useLazyGetDocumentQuery } from 'app/store/DMSDocuments/DMSDocuments.api';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import CaseInfoBar from 'app/components/CaseInfoBar';
import { Editor, Toolbar } from 'app/components/QuillEditor';
// 
import DialogTitle from './DialogTitle';

interface IFormData {
  collectionId: string;
  name: string;
  content: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  content?: string;
  documentId?: number;
}

const DocumentFormDialog:FC<Props> = ({
  // Props
  open, onClose, content, documentId
}) => {
  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // Api
  const [ getDocument, { data:documentEntity } ] = useLazyGetDocumentQuery();

  const methods = useForm<IFormData>({
    defaultValues: {
      collectionId: '',
      name: '',
      content: content || '',
    }
  });

  const [ rendered, setRendered ] = useState(false);

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit((data:IFormData) => {
    const { collectionId, ...otherData } = data;
    const nextData:any = {
      ...otherData,
      insuranceCaseId: Number(caseId),
      collectionId: Number(collectionId)
    };
    if ( !documentId ){
      asyncCreateDocument(nextData);
    } else {
      if ( documentEntity && documentEntity.labels ){
        nextData['labels'] = documentEntity.labels;
      }
      asyncUpdateDocument(documentId, nextData);
    }
  });

  const asyncCreateDocument = async (data:any) => {
    try {
      const documentResponse = await dispatch(createDocument(data)).unwrap();
      const batchResponse = await dispatch(getBatch(documentResponse.batchId)).unwrap();
      dispatch(DMSBatchesActions.addBatch(batchResponse));
      onClose();
    } catch(error){
      console.log(error);
    }
  }

  const asyncUpdateDocument = async (documentId:number, data:any) => {
    try {
      await dispatch(updateDocument({ documentId, data })).unwrap();
      onClose();
    } catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    if ( !documentId ){
      setRendered(true);
    } else {
      getDocument(documentId);
    }

    return () => {
      dispatch(dmsDocumentsApi.util.resetApiState());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !documentEntity ) return;

    reset({
      name: documentEntity.name,
      collectionId: documentEntity.collectionId.toString(),
      content: documentEntity.content || ''
    });

    setRendered(true);
    // eslint-disable-next-line
  }, [documentEntity])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{ sx: { border: 0 } }}
      ContentProps={{ sx: { border: 0, p: 0 } }}
    >
      <FormProvider {...methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative'
          }}
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          <DialogTitle onClose={onClose} />
          <CaseInfoBar />
          <Box
            sx={{
              borderBottom: '1px solid rgba(0,0,0,0.08)',
              px: 6,
              py: 2
            }}
          >
            <Toolbar id="content" isTabStopVisible={true} isPageBreakVisible={true} />
          </Box>
          {rendered ? (
            <Box sx={{
              flexGrow: 1,
              bgcolor: '#f7f7f7',
              py: 4,
              px: 6,
              overflow: 'hidden'
            }}>
              <Box sx={{
                width: '100%',
                maxWidth: 1280,
                height: '100%',
                mx: 'auto'
              }}>
                <Controller
                  name="content"
                  render={({ field }) => (
                    <Editor
                      {...field}
                      label="Content" id="content"
                    />
                  )}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default DocumentFormDialog;
