import { FC, useState } from 'react';
// Types
import Colors from 'app/types/Colors';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
// Selectors
import { selectPagesFilter } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
import { selectEpisodeTypesAsOptions, selectEpisodeLabelsAsOptions, selectEpisodeAuthorsAsOptions } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import ColumnSwitcher from 'app/components/ColumnSwitcher';
import ColorPicker from 'app/components/ColorPicker';
import { MultipleAutocomplete } from 'app/components/Autocomplete';
import TagPicker from 'app/components/TagPicker';
// i18next
import { useTranslation } from 'react-i18next';

const PagesFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const filter:any = useAppSelector(selectPagesFilter);
  const episodeTypesOptions = useAppSelector(selectEpisodeTypesAsOptions);
  const episodeLabelsOptions = useAppSelector(selectEpisodeLabelsAsOptions);
  const episodeAuthors = useAppSelector(selectEpisodeAuthorsAsOptions);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const handleColorClick = (color:Colors|string) => {
    const nextColors = stateFilter.colors.includes(color)
      ? stateFilter.colors.filter((c:Colors | string) => c !== color)
      : [...stateFilter.colors, color]
    ;
    setStateFilter((prevState:any) => ({ ...prevState, colors: nextColors }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field: 'colors', value: nextColors }));
  }

  const handleChangeEpisodeTypes = (nextEpisodeTypes:(string | number)[]) => {
    setStateFilter((prevState:any) => ({ ...prevState, episodeTypes: nextEpisodeTypes }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field: 'episodeTypes', value: nextEpisodeTypes }));
  }

  const handleChangeEpisodeLabels = (nextEpisodeLabels:(string | number)[]) => {
    setStateFilter((prevState:any) => ({ ...prevState, episodeLabels: nextEpisodeLabels }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field: 'episodeLabels', value: nextEpisodeLabels }));
  }

  const handleChangeEpisodeAuthors = (nextEpisodeAuthors:(string | number)[]) => {
    setStateFilter((prevState:any) => ({ ...prevState, episodeAuthors: nextEpisodeAuthors }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field: 'episodeAuthors', value: nextEpisodeAuthors }));
  }

  const handleClickTag = (nextTag:string | null) => {
    const idx = stateFilter.tags.indexOf(nextTag);
    const nextTags = idx !== -1 ? stateFilter.tags.filter((t:string, i:number) => i !== idx) : [...stateFilter.tags, nextTag];
    setStateFilter((prevState:any) => ({ ...prevState, tags: nextTags }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field: 'tags', value: nextTags }));
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 4, p: 4 }}>
      <MultipleAutocomplete
        label={t('labels.episodeTypes')} value={stateFilter.episodeTypes}
        onChange={handleChangeEpisodeTypes}
        options={episodeTypesOptions}
        margin="none"
        size="small"
        sx={{
          minWidth: 260
        }}
      />
      <MultipleAutocomplete
        label={t('labels.episodeLabels')} value={stateFilter.episodeLabels}
        onChange={handleChangeEpisodeLabels}
        options={episodeLabelsOptions}
        margin="none"
        size="small"
        sx={{
          minWidth: 260
        }}
      />
      <MultipleAutocomplete
        label={t('labels.episodeAuthors')} value={stateFilter.episodeAuthors}
        onChange={handleChangeEpisodeAuthors}
        options={episodeAuthors}
        margin="none"
        size="small"
        sx={{
          minWidth: 260
        }}
      />
      <ColumnSwitcher />
      <TagPicker
        id="presenter-workspace-tags"
        onClick={handleClickTag}
        noTagVisible={true}
        activeTags={stateFilter.tags}
      />
      <ColorPicker
        id="workspacePage"
        activeColors={stateFilter.colors}
        noColorVisible={true}
        onClick={handleColorClick}
      />
    </Box>
  )
}

export default PagesFilter;
