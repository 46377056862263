import { FC } from 'react';
import { forceVisible } from 'react-lazyload';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectFilter } from 'app/store/page/page.selectors';
// 
import { Switch } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const HaveDuplicateInWorkspace:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useDispatch();
  // State
  const filter = useSelector(selectFilter);

  const { duplicate } = filter;

  const handleChange = (_:any, checked:boolean) => {
    dispatch(PageActions.setFilter('duplicate', checked));
    forceVisible();
  }

  return (
    <Switch
      label={t('layouts.filesTools.haveDuplicateInWorkspace')} value={duplicate} name="duplicate"
      onChange={handleChange}
    />
  )
}

export default HaveDuplicateInWorkspace;
