import { FC, useState, useEffect } from 'react';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Types
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Actions
import { EpisodeAuthorsActions } from 'app/store/EpisodeAuthors/EpisodeAuthors.slice';
// Selectors
import { selectFilter, selectFilteredAuthors } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// Mui
import { Pagination } from '@mui/material';

const AuthorRegistryPagination:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const authors:IEpisodeAuthor[] | null = useAppSelector(selectFilteredAuthors);
  const filter = useAppSelector(selectFilter);

  const [ pageCount, setPageCount ] = useState(0);

  useEffect(() => {
    if ( authors ){
      const totalItems = authors.length;
      setPageCount(Math.ceil(totalItems/filter.limit));
    }
    // eslint-disable-next-line
  }, [authors]);

  const handleChange = (_:any, value: number) => {
    dispatch(EpisodeAuthorsActions.setFilter({
      offset: (value - 1) * filter.limit
    }));
  };

  if ( pageCount <= 1 ) return null;
  return (
    <Pagination
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 4
      }}
      count={pageCount}
      color="primary"
      onChange={handleChange}
    />
  )
}

export default AuthorRegistryPagination;
