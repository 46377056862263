import config from '../../config';

import mp from 'mixpanel-browser';

class MixpanelService {
  constructor(){
    mp.init(config.mixpanelToken, {
      debug: true
    });
  }

  identify(id:string | number){
    const stringId = MixpanelService._idToString(id);
    mp.identify(stringId);
  }

  track(name:string, props?:any){
    mp.track(name, props)
  }

  registerOnceAccountId(accountId:number){
    mp.register_once({ accountId });
  }

  reset(){
    mp.reset();
  }

  private static _idToString(id:string | number){
    if ( typeof id === 'number' ) return id.toString();
    return id;
  }
}

const Mixpanel = new MixpanelService();

export default Mixpanel;
