import Quill from "quill";
// Constants
import { APP_URL_LABELS } from "app/App.constants";

class LinkVariableFormat extends Quill.import('blots/inline') {
  static blotName = 'link_variable';
  static className = 'ql-link-variable';
  static tagName = 'A';

  static create(value:string) {
    let node = super.create();
    node.setAttribute('target', '_blank');

    value = value.includes('{{')
      ? value.replace('{{ ', '').replace(' }}', '')
      : value
    ;

    node.setAttribute('href', `{{ ${value} }}`);
    node.setAttribute('data-href', value);
    node.setAttribute('title', APP_URL_LABELS[value as keyof typeof APP_URL_LABELS]);

    node.setAttribute('data-title', APP_URL_LABELS[value as keyof typeof APP_URL_LABELS]);

    return node;
  }

  static formats(domNode:Element) {
    return domNode.getAttribute('data-href') || domNode.getAttribute('href') || '';
  }
}

Quill.register('formats/link_variable', LinkVariableFormat);