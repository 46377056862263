import { createSelector } from '@reduxjs/toolkit';
// Models
import { RootState } from 'app/store';
import IBillingService from 'app/models/BillingService';
import IBillingPackage, { IBillingPackageItem } from 'app/models/BillingPackage';
// Selectors
import { selectEntities as selectServicesEntities } from 'app/store/BillingServices/BillingServices.selectors';

export const selectPackages = (state:RootState) => state['billing/packages'].packages;
export const selectPackage = (state:RootState) => state['billing/packages'].package;
export const selectLoading = (state:RootState) => state['billing/packages'].loading;

export const selectPackagesAsOptions = createSelector(
  [ selectPackages ],
  ( packages:IBillingPackage[] | null) => {
    if ( !packages ) return null;
    return packages.map((pkg:IBillingPackage) => ({ id: pkg.id, name: pkg.name }))
  }
);

export const selectHasPackageCptCodesAccess = createSelector(
  [
    selectServicesEntities,
    selectPackage
  ],
  ( servicesEntities:any, billingPackage:IBillingPackage | null ) => {
    if ( !billingPackage || !billingPackage.items ) return false;
    return billingPackage.items.some((item:IBillingPackageItem) => {
      const serviceEntity = servicesEntities[item.serviceId] as IBillingService;
      if ( !serviceEntity ) return false;
      return serviceEntity.code === 'cptCodesAccess';
    });
  }
)
