import { useMemo } from 'react';
import dayjs from 'dayjs';
// Models
import { ICurrentAccount } from 'app/models/Account';
import IEventTime from 'app/models/EventTime';
// Redux
import { useAppSelector } from './useStore';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';

const useFormattedTime = (value:IEventTime | string | undefined) => {
  const initialTimeFormat = 'hh:mm a';
  // State
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);

  const formatedDate = useMemo(() => {
    if ( !value ) return null;

    let hour;
    let minute;
    if ( typeof value === 'string' ){
      hour = Number(value.split(':')[0]);
      minute = Number(value.split(':')[1]);
    } else {
      hour = value.hour;
      minute = value.minute;
    }

    const time = dayjs().hour(hour).minute(minute);

    if ( !currentAccount || !currentAccount.settings?.timeFormat ) return time.format(initialTimeFormat);
    return time.format(currentAccount.settings?.timeFormat);
    // eslint-disable-next-line
  }, [value]);

  return formatedDate;
}

export default useFormattedTime;
