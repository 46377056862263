import dayjs from "dayjs";

class PageService {
  public static toPageId(documentId:number, pageNum:number):string {
    return `${documentId}:${pageNum}`;
  }
  
  public static fromPageId(pageId:string):number[] {
    return pageId.split(':').map(Number);
  }
  
  public static generateWorkspaceOrder(date:string | undefined, time:string | undefined, workspaceOrder?:string):string {
    let PAGE_DATE:string = date ? date.replace(/-/gi, '') : '00000000';
    let PAGE_TIME:string = time ? time.replace(/:/gi, '') : '0000';
    let TIME_STAMP:string = dayjs().utc().format('YYYYMMDDHHmmssSSS');
    if ( workspaceOrder ) TIME_STAMP = workspaceOrder.substring(12, 29);
    return `${PAGE_DATE}${PAGE_TIME}${TIME_STAMP}`;
  }
}

export default PageService;