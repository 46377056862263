import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
// Types
import Reducers from 'app/types/Reducers';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
// Async
import { createReportSection, updateReportSection, deleteReportSection } from "app/store/ReportSections/ReportSections.async";
// Selectors
import { selectInsuranceCase } from "app/store/Cases/Cases.selectors";
// Services
import Mixpanel from "app/services/Mixpanel.service";

const ReportSectionsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.ReportSections) ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state);
    if ( type === createReportSection.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseReportSectionAdded, { caseId: insuranceCase?.id });

      if ( payload.accountId ){
        Mixpanel.track(MixpanelTracks.CaseReportSectionTemplateImport, {
          accountid: payload.accountId,
          caseId: insuranceCase?.id,
          reportSectionTemplateId: payload.id
        });
      }
    }
    if ( type === updateReportSection.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseReportSectionUpdated, { caseId: insuranceCase?.id });
    }
    if ( type === deleteReportSection.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseReportSectionDeleted, { caseId: insuranceCase?.id });
    }
  }
  return next(action);
}

export default ReportSectionsMiddleware;
