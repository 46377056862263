import { FC } from 'react';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// ToDO
import { selectHighlightModeEnabled } from 'app/store/TODO2_app/app.selectors';
// End: ToDO
// Mui
import { Box, Tooltip, colors, alpha } from '@mui/material';

export interface IHighlight {
  width: string;
  height: string;
  top: string;
  left: string;
}

type Props = {
  highlights: IHighlight[];
  onRemove?: (index:number) => void;
}

const Highlights:FC<Props> = ({
  // Props
  highlights, onRemove
}) => {
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const highlightModeEnabled = useAppSelector((store:any) => selectHighlightModeEnabled(store));

  const renderRemoveButton = (index:number) => {
    if ( trialMode || !highlightModeEnabled || typeof onRemove === 'undefined' ) return null;
    return (
      <Tooltip title="Remove">
        <Box sx={{
          position: 'absolute',
          top: 0, right: 0,
          width: 24,
          height: 24,
          borderRadius: '50%',
          border: `3px solid ${alpha(colors.amber[200], 0.5)}`,
          bgcolor: 'white',
          color: 'black',
          fontSize: '22px',
          lineHeight: '18px',
          m: -1.5,
          textAlign: 'center',
          '&:hover': {
            borderColor: colors.amber[200],
            cursor: 'pointer'
          }
        }}
        onClick={() => onRemove(index)}
      >&times;</Box>
      </Tooltip>
    )
  }

  if ( !highlights || !highlights.length ) return null;
  return (
    <Box sx={{
      position: 'absolute',
      top: 0, left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      zIndex: 2
    }}>
      {highlights.map((highlight:IHighlight, index:number) => (
        <Box
          key={`hightlight item ${index}`}
          sx={{
            ...highlight,
            position: 'absolute',
            bgcolor: alpha(colors.amber[200], 0.5),
            pointerEvents: 'all',
            zIndex: 1,
            '&:hover': { zIndex: 2 }
          }}
        >{renderRemoveButton(index)}</Box>
      ))}
    </Box>
  )
}

export default Highlights;
