import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Mui
import { Box } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
import TagPicker from 'app/components/TagPicker';
import ColorPicker from 'app/components/ColorPicker';
// 
import AutocompleteAuthors from 'app/components/AutocompleteAuthors';
import AutocompleteLabels from 'app/components/AutocompleteLabels';
import AutocompleteTypes from 'app/components/AutocompleteTypes';
// i18next
import { useTranslation } from 'react-i18next';

const EpisodeTableSettings:FC = () => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  const { control } = useFormContext();

  return (
    <Fragment>
      <Controller
        control={control} name="page.tags" defaultValue={[]}
        render={({ field }) => (
          <Box sx={{ mb: 4 }}>
            <TagPicker
              id="Wizard dialog tags"
              activeTags={field.value}
              onClick={(newTag:string | null) => {
                const idx = field.value.indexOf(newTag);
                const nextValue = idx !== -1 ? field.value.filter((_:any, i:number) => i !== idx) : [...field.value, newTag];
                field.onChange(nextValue)
              }}
              noTagVisible={true}
            />
          </Box>
        )}
      />
      <Controller
        control={control} name="page.colors" defaultValue={[]}
        render={({ field }) => (
          <Box>
            <ColorPicker
              id="Wizard dialog colors"
              activeColors={field.value}
              onClick={(newColor:string) => {
                const idx = field.value.indexOf(newColor);
                const nextValue = idx !== -1 ? field.value.filter((_:any, i:number) => i !== idx) : [...field.value, newColor];
                field.onChange(nextValue);
              }}
              noColorVisible={true}
            />
          </Box>
        )}
      />
      {/* Pre/Post events */}
      <Controller
        control={control} name="episode.preEvent" defaultValue="all"
        render={({ field }) => (
          <Select
            {...field}
            // ToDO
            value={field.value.toString()}
            label={t('labels.episodes')}
            options={[
              { id: 'all', name: t('labels.all') },
              { id: 'true', name: t('labels.preEvent') },
              { id: 'false', name: t('labels.postEvent') },
            ]}
          />
        )}
      />
      {caseId ? (
        <Fragment>
          {/* Authors */}
          <Controller
            control={control} name="episode.authorIds" defaultValue={[]}
            render={({ field }) => (
              <AutocompleteAuthors {...field} />
            )}
          />
          {/* Labels */}
          <Controller
            control={control} name="episode.labels" defaultValue={[]}
            render={({ field }) => (
              <AutocompleteLabels {...field} />
            )}
          />
          {/* Types */}
          <Controller
            control={control} name="episode.types" defaultValue={[]}
            render={({ field }) => (
              <AutocompleteTypes {...field} />
            )}
          />
        </Fragment>
      ) : null}
    </Fragment>
  )
}

export default EpisodeTableSettings;
