import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// State
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { payInvoice, sendInvoice } from 'app/store/Invoices/Invoices.async';
// Selectors
import { selectLoading } from 'app/store/Invoices/Invoices.selectors';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton, DesktopDateTimePicker } from 'app/components/Mui';
// Utilities
import { isRequired, validateDateTime } from 'app/utilities/Validations';

type Props = {
  open: boolean;
  onClose: () => void;
  invoiceId: number;
  isPayFormAction?: boolean;
}

const InvoiceSendPayFormDialog:FC<Props> = ({
  // Props
  open, onClose, invoiceId, isPayFormAction = true
}) => {
  const { t } = useTranslation('common');

  const label = isPayFormAction ? t('labels.pay') : t('labels.send');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState:{ errors } } = useForm({
    defaultValues: {
      date: null
    }
  });

  const onSubmit = handleSubmit(async (data:any) => {
    try {
      dayjs.extend(utc);
      const newData = { date: dayjs.utc(data.date).toISOString() };
      if ( isPayFormAction ){
        await dispatch(payInvoice({ id: invoiceId, data: newData })).unwrap();
      } else {
        await dispatch(sendInvoice({ id: invoiceId, data: newData })).unwrap();
      }
      onClose();
    } catch(error){}
  })

  const actions = (
    <Fragment>
      <Button
        name="Cancel invoice form dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`${label} invoice form dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{label}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={`${label} ${t('labels.invoice')}`}
      actions={actions}
    >
      <form noValidate>
        <Controller
          control={control} name="date"
          rules={{
            required: isRequired,
            validate: validateDateTime
          }}
          render={({ field }) => (
            <DesktopDateTimePicker
              {...field}
              label={t('labels.dateAndTime')}
              inputProps={{
                required: true,
                error: Boolean(errors.date),
                helperText: errors.date?.message || ''
              }}
            />
          )}
        />
      </form>
    </Dialog>
  )
}

export default InvoiceSendPayFormDialog;
