import { FC, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Redux
import { useAppDispatch, useAppSelector } from "app/hooks/useStore";
// Async
import { getPrompts } from "app/store/AIChatPrompts/AIChatPrompts.async";
// Actions
import { AIChatPromptsActions } from "app/store/AIChatPrompts/AIChatPrompts.slice";
// Selectors
import { selectFilteredPrompts } from "app/store/AIChatPrompts/AIChatPrompts.selectors";
// Mui
import { Box, Container, Toolbar, Typography, Paper, List } from "@mui/material";
// Components
import DataLoading from "app/components/DataLoading";
// 
import AIChatPromptsFilter from "./AIChatPromptsFilter";
import PromptItem from "./PromptItem";
import AIChatPromptsFab from "./AIChatPromptsFab";

const AIChatPromptsPage: FC = () => {
  const { t } = useTranslation("common");

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const prompts = useAppSelector(selectFilteredPrompts);

  useEffect(() => {
    dispatch(getPrompts({}));

    return () => {
      dispatch(AIChatPromptsActions.resetState());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t("pages.adminPages.aiChatPromptsPage.title")}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: "auto" }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <AIChatPromptsFilter />
          <DataLoading
            data={prompts}
            text={t("pages.adminPages.aiChatPromptsPage.noDataText")}
          >{(prompts) => (
            <Paper variant="outlined">
              <List>
                {prompts.map((aiChatPrompt) => (
                  <PromptItem
                    key={`prompt item ${aiChatPrompt.id}`}
                    aiChatPrompt={aiChatPrompt}
                  />
                ))}
              </List>
            </Paper>
          )}</DataLoading>
        </Container>
      </Box>
      <AIChatPromptsFab />
    </Fragment>
  );
};

export default AIChatPromptsPage;
