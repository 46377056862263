import { FC } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { setColumns } from 'app/store/ui/uiSlice';
// Selectors
import { selectColumns } from 'app/store/ui/uiSelectors';
// Mui
import { ButtonGroup, Button, Theme } from '@mui/material';
// Components
import Icon, { IconVariants } from 'app/components/Icon';

const ColumnSwitcher:FC = () => {
  const icons:IconVariants[] = ['columns_auto', 'columns_2', 'columns_3', 'columns_4'];
  // Dispach
  const dispatch = useAppDispatch();
  // State
  const columns:string = useAppSelector(selectColumns);

  const handleClick = (nextColumns:string) => () => {
    dispatch(setColumns(nextColumns));
  }

  return (
    <ButtonGroup color="inherit">
      {icons.map((icon:IconVariants) => (
        <Button
          key={`column-switch-item-${icon}`}
          aria-label={`Switch ${icon.split('_').join(' ')} button`}
          sx={{
            bgcolor: (theme:Theme) => columns === icon ? theme.palette.primary.light : 'transparent',
            borderColor: 'rgba(0,0,0,0.08)',
            color: (theme:Theme) => columns === icon ? theme.palette.primary.main : 'initial',
            px: '4px',
            '&:hover': {
              borderRightColor: 'rgba(0,0,0,0.08) !important'
            }
          }}
          onClick={handleClick(icon)}
        ><Icon icon={icon} /></Button>
      ))}
    </ButtonGroup>
  )
}

export default ColumnSwitcher;