import { Loader, Message } from 'app/components/Utilities';
import { FC, Fragment } from 'react';
// Mui
import { Paper, Typography, List } from '@mui/material';
// 
import UsersListItem from './UsersListItem';

type Props = {
  users: any;
  title: string;
  insuranceCaseId: number;
  noText: string;
}

const UsersList:FC<Props> = ({
  // Props
  users, title, insuranceCaseId, noText
}) => {
  if ( !users ) return <Loader />;
  if ( !users.length ) return <Message text={noText} />
  return (
    <Fragment>
      <Typography sx={{ mb: 2 }} variant="h6">{title}</Typography>
      <Paper sx={{ mb: 4 }} variant="outlined">
        <List>
          {users.map((user:any) => (
            <UsersListItem
              key={`user-item-${user.id}`}
              user={user}
              insuranceCaseId={insuranceCaseId}
            />
          ))}
        </List>
      </Paper>
    </Fragment>
  )
}

export default UsersList;
