import { Fragment, ReactNode } from "react";
// Components
import { Loader, Message } from 'app/components/Utilities'

type Props<T> = {
  data: T[] | null | undefined;
  text: string;
  children: (data:T[]) => ReactNode;
}

const DataLoading = <T,>({
  // Props
  data, text, children
}:Props<T>) => {
  if ( !data ) return <Loader />;
  if ( !data.length ) return <Message text={text} />
  return <Fragment>{children(data)}</Fragment>
}

export default DataLoading;
