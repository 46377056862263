import { FC, Fragment, useMemo } from 'react';
// Models
import { IInvoiceLine } from 'app/models/BillingInvoice';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCurrentAccountBilling } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Table, TableHead, TableBody, TableRow, TableCell, TableFooter } from '@mui/material';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  invoiceId: number;
  invoiceDiscountAmount: number | undefined;
  lines: IInvoiceLine[];
}

const InvoicesListItemLines:FC<Props> = ({
  // Props
  invoiceId, invoiceDiscountAmount = 0, lines
}) => {
  const { t } = useTranslation('common');
  // State
  const currentBilling = useAppSelector(selectCurrentAccountBilling);

  const subtotal = useMemo(() => {
    return lines.reduce((acc:number, cur:IInvoiceLine) => {
      acc = acc + cur.amount;
      return acc;
    }, 0);
  }, [lines]);

  const total = useMemo(() => {
    return invoiceDiscountAmount ? subtotal - invoiceDiscountAmount : subtotal;
  }, [subtotal, invoiceDiscountAmount]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right">{t('labels.qty')}</TableCell>
          <TableCell align="right">{t('labels.rate')}</TableCell>
          <TableCell align="right">{t('labels.amount')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((line:IInvoiceLine, index:number) => (
          <TableRow key={`biling invoice item ${invoiceId} line ${index}`}>
            <TableCell component="th" scope="row">
              {line.name}
              {getServiceDescription(currentBilling, (line as any).serviceId) ? (
                <p>{getServiceDescription(currentBilling, (line as any).serviceId)}</p>
              ) : null}
            </TableCell>
            <TableCell align="right">{line.quantity}</TableCell>
            <TableCell align="right">{line.price < 0 ? `-$${line.price * -1}` : `$${line.price}`}</TableCell>
            <TableCell align="right">{line.amount < 0 ? `-$${toNumberWithCommas(line.amount * -1)}` : `$${toNumberWithCommas(line.amount)}`}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        {invoiceDiscountAmount ? (
          <Fragment>
            <TableRow>
              <TableCell component="th" scope="row" align="right" colSpan={3}>{t('labels.subtotal')}</TableCell>
              <TableCell align="right">${toNumberWithCommas(subtotal)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align="right" colSpan={3}>{t('labels.discount')}</TableCell>
              <TableCell align="right">-${toNumberWithCommas(invoiceDiscountAmount)}</TableCell>
            </TableRow>
          </Fragment>
        ) : null}
        <TableRow>
          <TableCell component="th" scope="row" align="right" colSpan={3}>{t('labels.total')}</TableCell>
          <TableCell align="right">${toNumberWithCommas(total)}</TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default InvoicesListItemLines;

export const getServiceDescription = (currentBilling:any, serviceId:any) => {
  if ( !currentBilling || !currentBilling.subscription ) return null;
  const foundedItem = currentBilling.subscription.items.find((item:any) => item.service.id === serviceId);
  if ( !foundedItem ) return null;
  return foundedItem.service.description;
}
