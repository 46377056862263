import { FC, Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPendingCases, getInsuranceCases } from 'app/store/Cases/Cases.async';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectAccountCasesParams } from 'app/store/Cases/Cases.selectors';
// Mui
import { Box, Container } from '@mui/material';
// Utilities
import { getSearchParam } from 'app/utilities/Utilities';
// 
import CasesToolbar from './CasesToolbar';
import CasesFilter from './CasesFilter';
import CasesList from './CasesList';
import CasesFab from './CasesFab';
// 
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

const CasesPage:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount = useAppSelector(selectCurrentAccount);
  const params = useAppSelector(selectAccountCasesParams);

  const { search } = useLocation<{ search:string }>();

  const currentStatus = (getSearchParam(search, 'tab') as CaseStatuses) || CaseStatuses.Open;

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseListView);

    dispatch(getPendingCases({}));

    return () => {
      dispatch(CasesActions.resetCasesList());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // ToDO: Added to avoid mutiple `params` fetch
    // when `currentAccount` switch from null to Object
    if ( !currentAccount ) return;

    const caseStatusPendingOrWaiting = params.status === CaseStatuses.Pending || params.status === CaseStatuses.Waiting;

    const nextParams = {
      ...params,
      status: params.status !== currentStatus ? currentStatus : params.status,
      'sort': caseStatusPendingOrWaiting ? 'createdOn desc' : params['sort'],
      'sort.priority.collectionIds': caseStatusPendingOrWaiting ? '' : params['sort.priority.collectionIds']
    };

    dispatch(getInsuranceCases(nextParams));
    // eslint-disable-next-line
  }, [currentAccount, Object.values(params)]);

  return (
    <Fragment>
      <CasesToolbar currentStatus={currentStatus} />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 16 }} maxWidth="md" >
          <CasesFilter currentStatus={currentStatus} />
          <CasesList />
        </Container>
      </Box>
      <CasesFab />
    </Fragment>
  )
}

export default CasesPage;
