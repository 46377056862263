import { MiddlewareAPI, Middleware, isRejectedWithValue } from '@reduxjs/toolkit'
// Types
import BodyErrors from 'app/types/BodyErrors';
import NotificationStatuses from "app/types/NotificationStatuses";
// Models
import { AppDispatch } from 'app/store';
// Actions
import { AppUiNotificationsActions } from "app/store/AppUINotifications/AppUINotifications.slice";
import { isAIChatPathValid } from 'app/utilities/Utilities';

const RejectHandlerMiddleware:Middleware = ({ dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  // List of errors on which snackbar should be skipped
  const excludedErrors = [
    BodyErrors.MfaRequired,
    BodyErrors.AccountRequired,
    BodyErrors.PasswordChangeRequired
  ];
  // ToDO: Status `401, 500` can have `errors`
  // In this case we should exclude showing snackabr
  const excludedStatusesWithErrors = [401, 403, 500];
  const excludedStatusesAll = excludedStatusesWithErrors.concat([403, 404, 409, 502, 503]);

  const excludedMethods = ['POST'];

  if ( isRejectedWithValue(action) ){
    if ( typeof action.payload !== 'undefined' ){
      const { error, errors, status, message, path, method } = action.payload;

      // ToDO
      if ( status === 503 && path && method ){
        if ( isAIChatPathValid(path) && excludedMethods.includes(method) ) return next(action);
      }
      // End: ToDO

      if ( errors && errors.length ){
        if ( excludedStatusesWithErrors.includes(status) ) return next(action);
        for ( let error of errors ){
          dispatch(AppUiNotificationsActions.addSnackbar({
            message: error.message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      } else {
        let skipSnackbar = false;
        if (
          error &&
          excludedErrors.includes(error) &&
          excludedStatusesAll.includes(status)
        ){
          skipSnackbar = true;
          return next(action);
        }
        if ( !skipSnackbar && message ){
          dispatch(AppUiNotificationsActions.addSnackbar({
            message,
            options: { variant: NotificationStatuses.Error }
          }));
        }
      }
    } else {
      dispatch(AppUiNotificationsActions.addSnackbar({
        message: 'Something went wrong',
        options: { variant: NotificationStatuses.Error },
      }));
    }
  }
  return next(action);
}

export default RejectHandlerMiddleware;