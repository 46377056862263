import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import ICaseAlert from 'app/models/CaseAlert';
import { sortByCreationDate } from 'app/utilities/SortBy';

export const selectAlerts = (state:RootState) => state[Reducers.CaseAlerts].alerts;
export const selectLoading = (state:RootState) => state[Reducers.CaseAlerts].loading;

export const selectSortedAlerts = createSelector(
  [ selectAlerts ],
  (alerts:ICaseAlert[] | null) => {
    if ( !alerts ) return null;
    return [...alerts].sort(sortByCreationDate);
  }
);

export const selectTotalUnresolvedAlerts = createSelector(
  [ selectAlerts ],
  ( alerts:ICaseAlert[] | null ) => {
    if ( !alerts ) return 0;
    return alerts.reduce((acc:number, cur:ICaseAlert) => {
      if ( !cur.resolved ) acc = acc + 1;
      return acc;
    }, 0);
  }
);
