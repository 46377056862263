import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
// Types
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
// Selectors
import { selectInsuranceCase } from "app/store/Cases/Cases.selectors";
// Services
import Mixpanel from "app/services/Mixpanel.service";

const PageFilterMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type === '@@page/Set filter' ){
    const state = getState();
    const insuranceCase = selectInsuranceCase(state);
    const trackedAction = window.location.pathname.includes('/workspace') ? MixpanelTracks.CaseWorkspaceFilter : MixpanelTracks.CaseDocumentFilter;
    if ( insuranceCase?.id && payload.field ){
      Mixpanel.track(trackedAction, { caseId: insuranceCase.id, filterName: payload.field });
    }
  }
  return next(action);
}

export default PageFilterMiddleware;
