export default class IdempotencyKeyService {
  private static keys:string[] = [];
  private static duplicateKeys:string[] = [];

  // Keys
  public static getKeys = () => this.keys;

  public static addKey = (key:string) => {
    this.keys = [...this.keys, key];
  }

  public static removeKey = (key:string) => {
    this.keys = this.keys.filter((k:string) => k !== key);
  }

  public static hasKey = (key:string) => {
    return this.keys.includes(key);
  }

  // Duplicate keys
  public static addDuplicateKey = (key:string) => {
    if ( this.duplicateKeys.length > 100 ){
      this.duplicateKeys = this.duplicateKeys.splice(-10);
    }
    this.duplicateKeys = [...this.duplicateKeys, key];
  }
  public static hasKeyInDuplicate = (key:string) => {
    return this.duplicateKeys.includes(key);
  }
  public static clearDuplicateKeys = () => {
    this.duplicateKeys = [];
  }
}
