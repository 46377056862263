// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get } from 'app/utilities/HttpClient';

const _url:string = '/labellingtool/jobs';

// Get jobs
export const getJobs = asyncThunkHandler(
  `${Reducers.LabellingToolJobs}/Get jobs`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Get jobs
export const getJob = asyncThunkHandler(
  `${Reducers.LabellingToolJobs}/Get job`,
  async (jobId:number) => {
    const response:Response = await $get(`${_url}/${jobId}`);
    return response.json();
  }
);
