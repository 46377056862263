import { FC } from 'react';
import { Link } from 'react-router-dom';
// Models
import { RootState } from 'app/store';
import IConversation from 'app/models/Conversation';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectConversationCasesEntities } from 'app/store/Cases/Cases.selectors';
import { selectUnseenConversation } from 'app/store/Conversations/Conversations.selectors';
// Mui
import {
  Box, Chip,
  ListItemButton, ListItemText, Theme, ListItemIcon, ListItem
} from '@mui/material';
// Components
import { Bull } from 'app/components/Utilities';
// Hooks
import useLongDateTme from 'app/hooks/useLongDateTime';

type Props = {
  conversationId: number;
}

const ConversationListItem:FC<Props> = ({
  // Props
  conversationId
}) => {
  // State
  const insuranceCasesEntities = useAppSelector(selectConversationCasesEntities);
  const conversation:IConversation = useAppSelector((state:RootState) => selectUnseenConversation(state, {
    conversationId
  }));

  const longCreatedOn = useLongDateTme({
    dateValue: conversation.lastMessage?.createdOn,
    displayTime: true,
    local: true
  });

  const insuranceCaseName = insuranceCasesEntities[conversation.context.insuranceCaseId];

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={`/admin/cases/${conversation.context.insuranceCaseId}/messages/${conversation.id}`}
      >
        <ListItemText
          primary={<Box component="span">{(insuranceCaseName || '')}<Bull />{conversation.name}</Box>}
          secondary={
            conversation.lastMessage ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                component="span"
              >
                <Box
                  sx={{
                    color: (theme:Theme) => theme.palette.grey[700],
                    whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                  }}
                  component="span"
                >{conversation.lastMessage.text}</Box>
                <Box
                  sx={{
                    color: (theme:Theme) => theme.palette.grey[500]
                  }}
                  component="span"
                >{longCreatedOn}</Box>
              </Box>
            ) : null
          }
        />
        {conversation.me && conversation.me.numberOfUnseenMessages ? (
          <ListItemIcon
            sx={{
              justifyContent: 'flex-end',
              minWidth: 'initial',
              pl: 4
            }}
          >
            <Chip
              label={conversation.me.numberOfUnseenMessages}
              color="primary"
              size="small"
            />
          </ListItemIcon>
        ) : null}
      </ListItemButton>
    </ListItem>
  )
}

export default ConversationListItem;
