import React, { Fragment } from 'react';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectPage, selectPageStaples } from 'app/store/page/page.selectors';
// 
import CardPageTabsTextItem from './CardPageTabsTextItem';

type Props = {
  activeTab: number;
};

const CardPageTabsText:React.FC<Props> = ({
  // Props
  activeTab
}) => {
  // State
  const page:IPage | null = useSelector(selectPage);
  const staplePages:Array<IPage> | null = useSelector(selectPageStaples);

  if ( !page ) return null;
  return (
    <Fragment>
      {staplePages ? (
        staplePages.map((p:IPage) => (
          <CardPageTabsTextItem
            key={`card-page-tabs-pdf-item-${p.documentId}-${p.pageNum}`}
            page={p}
            activeTab={activeTab}
          />
        ))
      ) : (
        <CardPageTabsTextItem page={page} activeTab={activeTab} />
      )}
    </Fragment>
  )
}

export default CardPageTabsText;
