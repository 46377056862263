export default class AppService {
  private static readonly STORE_KEY:string = 'case_portal_v3:last_opened_workspace_pages';
  private static readonly FILE_STORE_KEY:string = 'case_portal_v3:last_opened_file_pages';

  // Last opened workspace pages
  public static setLastOpenedWorkspacePages(lastOpenedWorkspacePages:{ [key:number]:string }):void {
    localStorage.setItem(AppService.STORE_KEY, JSON.stringify(lastOpenedWorkspacePages));
  }
  public static getLastOpenedWorkspacePages():{ [key:number]:string } {
    const lastOpenedWorkspacePages = localStorage.getItem(AppService.STORE_KEY) || `{}`;
    return JSON.parse(lastOpenedWorkspacePages);
  }

  // Last opened file pages
  public static setLastOpenedFilePages(lastOpenedWorkspacePages:{ [key:number]:string }):void {
    localStorage.setItem(AppService.STORE_KEY, JSON.stringify(lastOpenedWorkspacePages));
  }
  public static getLastOpenedFilePages():{ [key:number]:string } {
    const lastOpenedFilePages = localStorage.getItem(AppService.STORE_KEY) || `{}`;
    return JSON.parse(lastOpenedFilePages);
  }
}
