import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import { ITerms } from 'app/store/Terms/Terms.models';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createTerms, updateTerms } from 'app/store/Terms/Terms.async';
// Selectors
import { selectLoading } from 'app/store/Terms/Terms.selectors';
// Components
import Dialog from 'app/components/Dialog';
import QuillEditor from 'app/components/QuillEditor';
import { Button, LoadingButton, Input, Select } from 'app/components/Mui';
// Utility
import { isRequired } from 'app/utilities/Validations';

interface IFormData {
  name: string;
  content: string;
  scope: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  terms?: ITerms;
}

const TermFormDialog:FC<Props> = ({
  // Props
  open, onClose, terms
}) => {
  const { t } = useTranslation('common');

  const label = terms ? t('labels.update') : t('labels.create');
  const title = `${label} ${t('pages.adminPages.termsPage.termsAndConditions')}`

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState:{ errors } } = useForm<IFormData>({
    defaultValues: {
      name: terms?.name || '',
      content: terms?.content || '',
      scope: terms?.scope || 'general'
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    if ( terms ){
      asyncUpdateTerms({ id: terms.id, data });
    } else {
      asyncCreateTerms(data);
    }
  });

  const asyncCreateTerms = async (data:any) => {
    try {
      await dispatch(createTerms(data)).unwrap();
      onClose();
    } catch(err){}
  }

  const asyncUpdateTerms = async (data:any) => {
    try {
      await dispatch(updateTerms(data)).unwrap();
      onClose();
    } catch(err){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel terms form dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`${label} terms form dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{label}</LoadingButton>
    </Fragment>
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      title={title}
      actions={actions}
    >
      <form noValidate>
        <Controller
          control={control} name="name"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.name')}
              error={Boolean(errors.name)}
              helperText={errors.name?.message || ''}
              required
            />
          )}
        />
        {!terms ? (
          <Controller
            control={control} name="scope"
            render={({ field }) => (
              <Select
                {...field}
                label={t('labels.scope')}
                options={[
                  { id: 'general', name: 'General' },
                  { id: 'aiChat', name: 'AIChat' },
                ]}
              />
            )}
          />
        ) : null}
        <Controller
          control={control} name="content"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Fragment>
              <QuillEditor
                {...field}
                id="terms-and-conditions-quill-editor"
                label={t('labels.content')}
                required={true}
                error={Boolean(errors.content)}
                helperText={errors.content?.message || ''}
                height={400}
              />
            </Fragment>
          )}
        />
      </form>
    </Dialog>
  )
}

export default TermFormDialog;
