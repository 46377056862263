import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import IReportPreset from 'app/models/ReportPreset';
// Models
import { RootState } from 'app/store';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectPresets = (state:RootState) => state[Reducers.ReportPresets].presets;
export const selectFilter = (state:RootState) => state[Reducers.ReportPresets].filter;
export const selectLoading = (state:RootState) => state[Reducers.ReportPresets].loading;

// Regular templates
export const selectFilteredPresets = createSelector(
  [
    selectPresets,
    selectFilter
  ],
  (presets:IReportPreset[] | null, filter:any) => {
    if ( !presets ) return null;
    const { search } = filter;
    const sortedPresets = [...presets].sort((a, b) => sortByName(a, b, 'name'))
    if ( !search ) return sortedPresets;
    const searchToLower = search.toLowerCase();
    return sortedPresets.filter((preset:IReportPreset) => preset.name.toLowerCase().includes(searchToLower));
  }
);

export const selectPresetsAsOptions = createSelector(
  [ selectPresets ],
  (presets:IReportPreset[] | null) => {
    if ( !presets ) return null;
    return presets.map((preset:IReportPreset) => ({
      id: preset.id,
      name: preset.name,
      props: {
        templateIds: preset.templateIds,
        headerTemplateId: preset.headerTemplateId,
        footerTemplateId: preset.footerTemplateId,
      }
    }));
  }
);
