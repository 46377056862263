import { FC } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Mui
import { Box, Badge, Tooltip } from '@mui/material';
// Components
import { IconButton } from 'app/components/Mui';
// ToDO
import PageCompareDialog from '@root/dialogs/PageCompareDialog';
import Icon from 'app/components/Icon';
import { selectComparePagesIds } from 'app/store/page/page.selectors';
// End: ToDO
// i18next
import { useTranslation } from 'react-i18next';
import useToggle from 'app/hooks/useToggle';

const CaseDetailsActions:FC = () => {
  const { t } = useTranslation('common');
  // State
  const comparePagesIds = useAppSelector((state:any) => selectComparePagesIds(state));

  const { open, toggle } = useToggle();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip title={t('pages.staffPages.caseDetailsPage.pagesCompare')}>
        <IconButton
          name="Open compare page dialog"
          onClick={toggle}
        >
          <Badge
            aria-label={t('pages.staffPages.caseDetailsPage.pagesCompare')}
            badgeContent={comparePagesIds.length}
            color={comparePagesIds.length === 0 ? 'default' : 'primary'}
          ><Icon icon="compare" /></Badge>
        </IconButton>
      </Tooltip>
      {/* Dialogs */}
      {open ? (
        <PageCompareDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Box>
  )
}

export default CaseDetailsActions;
