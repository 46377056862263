import { FC } from 'react';
// Mui
import { Alert } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const OrdersAlert:FC = () => {
  const { t } = useTranslation('common');

  return (
    <Alert
      sx={{ mb: 4 }}
      color="info"
    >{t('pages.adminPages.ordersPage.noAdditionalServicesAvailable')}</Alert>
  )
}

export default OrdersAlert;
