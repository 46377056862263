import i18n from "i18n";

const Messages = {
  ProfileUpdate: i18n.t('notifications.users.profileUpdate'),
  ResetPassword: i18n.t('notifications.users.resetPassword'),
  Create: i18n.t('notifications.users.create'),
  Update: i18n.t('notifications.users.update'),
  Import: i18n.t('notifications.users.import'),
  Delete: i18n.t('notifications.users.delete'),
  AuthenticationEnabled: i18n.t('notifications.users.2FAuthenticationEnabled'),
  AuthenticationDisabled: i18n.t('notifications.users.2FAuthenticationDisabled'),
  Enable: i18n.t('notifications.users.enable'),
  Disable: i18n.t('notifications.users.disable')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.ProfileUpdate = i18n.t('notifications.users.profileUpdate');
  Messages.ResetPassword = i18n.t('notifications.users.resetPassword');
  Messages.Create = i18n.t('notifications.users.create');
  Messages.Update = i18n.t('notifications.users.update');
  Messages.Import = i18n.t('notifications.users.import');
  Messages.Delete = i18n.t('notifications.users.delete');
  Messages.AuthenticationEnabled = i18n.t('notifications.users.2FAuthenticationEnabled');
  Messages.AuthenticationDisabled = i18n.t('notifications.users.2FAuthenticationDisabled');
  Messages.Enable = i18n.t('notifications.users.enable');
  Messages.Disable = i18n.t('notifications.users.disable');
});