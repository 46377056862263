import 'react-phone-input-2/lib/material.css';

import { useState, forwardRef, useLayoutEffect, useRef, useEffect } from 'react';
import PI from 'react-phone-input-2';
// Mui
import { FormControl, FormHelperText } from '@mui/material'
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

const PhoneInput = (PI as any).default ? (PI as any).default : PI;

type Props = {
  label: string;
  name: string;
  value: string;
  onChange: (nextPhone:string) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  margin?: 'dense' | 'normal' | 'none';
};

const Phone = forwardRef<Props, any>(({
  // Props
  label, name, value, onChange, disabled = false, error = false, helperText = '', required = false, margin = 'normal'
}, ref) => {
  const phoneInputRef = useRef(null);

  const [ phoneValue, setPhoneValue ] = useState('');

  const countryCodes:string[] = ['us', 'ca', 'es', 'ua'];

  useEffect(() => {
    // ToDO: Should be checked
    // for some reason, country autodetect doesnt work with '+' symbol
    // this is only applied for data which is dynamically fetched ( like Company Account )
    // data on Profile page is fetched and rendered before this component is visible
    const sanitizedValue = value.replace('+', '')
    // console.log(value);
    setPhoneValue(sanitizedValue);
    // eslint-disable-next-line
  }, [value]);

  useLayoutEffect(() => {
    if ( !phoneInputRef.current ) return;

    const current = phoneInputRef.current as any;
    const flagDropdownElem = current.dropdownContainerRef as HTMLDivElement;

    if ( !flagDropdownElem ) return;

    function handleClick(){
      const hasOpen = flagDropdownElem.classList.contains('open');
      const dialogContainerElem = document.querySelector('.MuiDialogContent-root') as HTMLDivElement | undefined;
      const containerHeight = dialogContainerElem ? dialogContainerElem.clientHeight : window.innerHeight;

      const dropdownRect = flagDropdownElem.getBoundingClientRect();
      const spaceAbove = dropdownRect.top;
      const spaceBelow = containerHeight - dropdownRect.bottom;

      const idValue = spaceAbove > spaceBelow ? 'top' : '';

      if ( hasOpen ){
        flagDropdownElem.removeAttribute('id');
      } else {
        flagDropdownElem.id = idValue;
      }
    }

    flagDropdownElem.addEventListener('click', handleClick)

    return () => {
      flagDropdownElem.removeEventListener('click', handleClick)
    }
  }, []);

  const handleChange = (value:string) => { // _data: Record<string, never>, _e: ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    onChange(value ? `+${value}` : '');
    // ToDO: Should be checked
    // setPhoneValue(value ? formattedValue : '');
  }

  return (
    <FormControl
      fullWidth
      margin={margin}
      required={required}
      error={error}
      {...generateAttrForTesting('control', 'input', name)}
    >
      <PhoneInput
        ref={phoneInputRef}
        inputProps={{ disabled }}
        value={phoneValue}
        onChange={handleChange}
        specialLabel={`${label}${required ? ' *' : ''}`}
        country={countryCodes[0]}
        // disableCountryGuess={true}
        onlyCountries={countryCodes}
        containerClass={`phoneContainer ${error ? 'error' : ''}`}
      />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
});

export default Phone;