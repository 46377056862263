import { FC } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredTeams } from 'app/store/Teams/Teams.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import TeamsListItem from './TeamsListItem';
import ITeam from 'app/models/Team';
// i18next
import { useTranslation } from 'react-i18next';

const TeamsList:FC = () => {
  const { t } = useTranslation('common');
  // State
  const teams:ITeam[] | null = useAppSelector(selectFilteredTeams);

  if ( !teams ) return <Loader />;
  if ( !teams.length ) return <Message text={t('pages.adminPages.teamsPage.noTeams')} />;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {teams.map((team:ITeam) => (
        <TeamsListItem
          key={`team-item-${team.id}`}
          team={team}
        />
      ))}
    </Box>
  )
}

export default TeamsList;
