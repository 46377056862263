import { FC } from 'react';
// State
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectComparePagesIds } from 'app/store/page/page.selectors';
// MaterialUI
import Button from '@mui/material/Button';
// i18next
import { useTranslation } from 'react-i18next';

const PageCompareActions:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useDispatch();
  // Select
  const comparePagesIds = useSelector(selectComparePagesIds);

  const handleClearCompare = () => {
    dispatch(PageActions.setInitialField('comparePagesIds'));
  }

  if ( comparePagesIds.length === 0 ) return null;
  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleClearCompare}
    >{t('labels.clearAll')}</Button>
  )
}

export default PageCompareActions;
