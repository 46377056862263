import { ChangeEvent, FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import IOption from 'app/models/Option';
import ITemplate from 'app/models/Template';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTemplates, selectFilteredAllTemplateIds } from 'app/store/Templates/Templates.selectors';
import { selectPresetsAsOptions } from 'app/store/ReportPresets/ReportPresets.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';

const PresetSelector:FC = () => {
  const { t } = useTranslation('common');
  // State
  const filteredAllTemplateIds = useAppSelector(selectFilteredAllTemplateIds)
  const templates = useAppSelector(selectTemplates);
  const presetsOptions = useAppSelector(selectPresetsAsOptions);

  const { setValue } = useFormContext();

  const { caseId, onUpdateLists } = useReportPresetContext();

  const [ presetId, setPresetId ] = useState<string>('0');

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    if ( !presetsOptions || !templates ) return;

    const { value } = event.target;
    const valueToNum = Number(value);

    const foundedOption = presetsOptions.find((option:IOption) => option.id === valueToNum);
    const templateIds = !foundedOption ? [] : foundedOption.props.templateIds;
    const headerTemplateId = foundedOption?.props.headerTemplateId
      ? foundedOption.props.headerTemplateId.toString()
      : null
    ;
    const footerTemplateId = foundedOption?.props.footerTemplateId
      ? foundedOption.props.footerTemplateId.toString()
      : null
    ;
    const selectedIds = templateIds.reduce((acc:number[], cur:number) => {
      const foundedTemplate = templates.find((template:ITemplate) => template.globalTemplateId && template.globalTemplateId === cur);
      acc = [...acc, foundedTemplate ? foundedTemplate.id : cur];
      return acc;
    }, []);
    const availableIds = [...(filteredAllTemplateIds || [])].filter((id:number) => !selectedIds.includes(id));

    onUpdateLists({
      selected: selectedIds,
      available: availableIds
    });

    setPresetId(value);

    if ( headerTemplateId ) setValue('headerTemplateId', headerTemplateId);
    if ( footerTemplateId ) setValue('footerTemplateId', footerTemplateId);
  }

  if ( !caseId ) return null;
  return (
    <Box sx={{ borderBottom: '1px solid rgba(0,0,0,0.08)', py: 4, px: 6 }}>
      <Select
        label={t('labels.reportPresets')} name="presets" value={presetId}
        onChange={handleChange}
        options={[
          { id: 0, name: t('dialogs.reportPresetForm.blankReport') },
          ...(presetsOptions || [])
        ]}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default PresetSelector;
