import { FC, Fragment, useState, ChangeEvent, KeyboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';
// Mui
import { Box, Button, Chip, FormHelperText } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Utilities
import { isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  notificationPreferences: {
    emails: string[];
  }
}

const NotificationsStepContent:FC = () => {
  const { t } = useTranslation('common');

  const methods = useFormContext<IFormData>();

  const handleSetEmails = (nextEmails:string[]) => {
    methods.setValue('notificationPreferences.emails', nextEmails);
  }

  const watchEmails = methods.watch('notificationPreferences.emails') || [];

  const [ value, setValue ] = useState('');
  const [ error, setError ] = useState(false);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const handleAdd = () => {
    if ( !isEmailValid.value.test(value) ) {
      setError(true);
      return;
    };
    handleSetEmails([...watchEmails, value]);
    setValue('');
    setError(false);
  }

  const handleRemove = (idx:number) => () => {
    handleSetEmails(watchEmails.filter((_, index:number) => index !== idx));
  }

  const onKeyDown = (event:KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAdd();
    }
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Box flexGrow={1}>
          <Input
            label={t('labels.email')} value={value}
            onChange={handleChange}
            error={error}
            helperText={error ? isEmailValid.message : ''}
            margin="none"
            onKeyDown={onKeyDown}
          />
        </Box>
        <Box sx={{ pt: '10px' }}>
          <Button
            disabled={!value}
            onClick={handleAdd}
            variant="contained"
            color="primary"
          >{t('labels.add')}</Button>
        </Box>
      </Box>
      <Box>
        <FormHelperText>{t('components.notificationPreferences.emailHelperText')}</FormHelperText>
      </Box>
      {watchEmails.length ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, gap: 2 }}>
          {watchEmails.map((email:string, index:number) => (
            <Chip
              key={`notification-email-item-${index}`}
              label={email}
              onDelete={handleRemove(index)}
            />
          ))}
        </Box>
      ) : null}
    </Fragment>
  );
};

export default NotificationsStepContent;