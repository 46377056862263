import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { RootState } from 'app/store';
import { IMyUser } from 'app/models/User';
import IPage from 'app/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectStapleModeType, selectPageEntity } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { IconButton, Tooltip } from '@mui/material';
// Components
import Icon from 'app/components/Icon';
// Services
import PageService from 'app/services/PageService';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  pageId:string;
}

const CardPageFootStaple:FC<Props> = ({
  // Props
  pageId
}) => {
  const { t } = useTranslation('common');
  const [ documentId, pageNum ] = PageService.fromPageId(pageId);
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const page = useAppSelector((state:RootState) => selectPageEntity(state, {
    documentId,
    pageNum
  })) as IPage;
  const stapleModeType = useAppSelector(selectStapleModeType);
  // ToDO
  const trialMode:boolean = useAppSelector(selectTrialMode);

  const handleEditStaple = () => {
    if ( !page.staple || page.staple.order !== 1 ) return;

    dispatch(DMSDocumentPagesActions.setStapleModeEdit({ documentId, stapleId: page.staple.id }));
  }

  const handleDeleteStaple = () => {

  }

  const myUserRoleClientOrPresenter = myUser?.role === UserRoles.Client || myUser?.role === UserRoles.Presenter;

  if ( trialMode ) return null;
  if ( myUserRoleClientOrPresenter ) return null;
  if ( stapleModeType ) return null;
  if ( !page.staple || page.staple.order > 1 ) return null;
  return (
    <Fragment>
      <Tooltip title={t('components.cardPage.editStaple')}>
        <IconButton
          onClick={handleEditStaple}
          size="small"
        ><Icon icon="staple_edit" /></IconButton>
      </Tooltip>
      <Tooltip title={t('components.cardPage.deleteStaple')}>
        <IconButton
          onClick={handleDeleteStaple}
          size="small"
        ><Icon icon="staple_delete" /></IconButton>
      </Tooltip>
    </Fragment>
  )
}

export default CardPageFootStaple;