import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
// Utilities
import { sortByCreationDate, sortByName } from 'app/utilities/SortBy';

export const selectInvoices = (state:RootState) => state[Reducers.Invoices].invoices;
export const selectServiceItems = (state:RootState) => state[Reducers.Invoices].serviceItems;
export const selectLoading = (state:RootState) => state[Reducers.Invoices].loading;

export const selectSortedInvoices = createSelector(
  [ selectInvoices ],
  ( invoices:any ) => {
    if ( !invoices ) return null;
    return [...invoices].sort(sortByCreationDate);
  }
);

export const selectSortedServiceItems = createSelector(
  [ selectServiceItems ],
  ( serviceItems:any ) => {
    if ( !serviceItems ) return null;
    return [...serviceItems].sort((a, b) => sortByName(a, b, 'name'));
  }
);
