import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { TemplateLayout } from 'app/types/Template';

const TableConfigToolbars:FC = () => {
  const { watch } = useFormContext();

  const watchLayout = watch('layout');

  const isLayoutTable = watchLayout === TemplateLayout.Table;

  if ( !isLayoutTable ) return null;
  return <div id="column-editor-toolbars" />
}

export default TableConfigToolbars;