import { ReactNode, FC, useRef, useEffect, useLayoutEffect, Fragment, } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUIStatusActions } from 'app/store/AppUIStatus/AppUIStatus.slice';
// Selectors
import { selectRequestStatusCode } from 'app/store/AppUIStatus/AppUIStatus.selectors';
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';
// Mui
import {
  Dialog, DialogTitle, DialogContent, Tooltip
} from '@mui/material';
// Icons
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useNavigatorOnline from 'app/hooks/useNavigatorOnline';
// 
import NoInternetContent from './NoInternetContent';
import AccessDeniedContent from './AccessDeniedContent';
import NotFoundContent from './NotFoundContent';
import ServiceUnavailableContent from './ServiceUnavailableContent';
import InternalServerErrorContent from './InternalServerErrorContent';

const StatusCodeHandler:FC = () => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const requestStatusCode = useAppSelector(selectRequestStatusCode); // 401, 403, 404, 502|503
  const authenticated = useAppSelector(selectAuthenticated);

  const { open, toggle } = useToggle();

  const handleClose = () => {
    toggle();
    dispatch(AppUIStatusActions.setRequestStatusCode(null));
  }

  const contentByStatusCode:Record<number, ReactNode> = {
    403: <AccessDeniedContent onClose={handleClose} />,
    404: <NotFoundContent />,
    500: <InternalServerErrorContent />,
    502: <ServiceUnavailableContent />,
    503: <ServiceUnavailableContent />
  };

  const isOnline = useNavigatorOnline();

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if ( firstUpdate.current ){
      firstUpdate.current = false;
      return;
    }

    if ( !isOnline ){
      if ( !open ) toggle();
    } else {
      if ( open && !requestStatusCode ) toggle();
    }

    console.log(`You are ${isOnline ? '🟢 online' : '🔴 offline'}`);
    // eslint-disable-next-line
  }, [isOnline]);

  console.log('Outside', requestStatusCode);

  useEffect(() => {
    if ( !requestStatusCode ) return;
    if ( authenticated && !open ) toggle();
    // eslint-disable-next-line
  }, [requestStatusCode]);

  const renderContent = () => {
    if ( !isOnline ) return <NoInternetContent />;
    if ( requestStatusCode ) return contentByStatusCode[requestStatusCode] || null;
    return (
      <Fragment>
        Is online: {isOnline}
        Request status code: {requestStatusCode}
      </Fragment>
    );
  }

  if ( !open ) return null;
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{
        '.MuiDialog-container': {
          alignItems: 'flex-start',
          mt: 8
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'right' }}>
        <Tooltip title={t('labels.closeDialog')} aria-label={undefined}>
          <IconButton
            name="Close dialog"
            aria-label="Close dialog button"
            onClick={handleClose}
            edge="end"
          ><CloseIcon /></IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>{renderContent()}</DialogContent>
    </Dialog>
  )
}

export default StatusCodeHandler;
