import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Async
import {
  getReportSectionTemplate,
  createReportSectionTemplate, updateReportSectionTemplate, deleteReportSectionTemplate
} from './ReportSectionTemplates.async';

interface IState {
  reportSectionTemplates: any[] | null;
  loading: boolean;
}

const initialState:IState = {
  reportSectionTemplates: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.ReportSectionTemplates,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState,

    filterReportSectionTemplate: (state, { payload }) => {
      if ( state.reportSectionTemplates ){
        state.reportSectionTemplates = state.reportSectionTemplates.filter((template) => template.id !== payload)
      }
    }
  },
  extraReducers(builder) {
    // Get report section templates
    builder.addCase(getReportSectionTemplate.pending, (state) => {
      state.reportSectionTemplates = null;
    });
    builder.addCase(getReportSectionTemplate.fulfilled, (state, action:PayloadAction<any[]>) => {
      state.reportSectionTemplates = action.payload;
    });
    // Create report section
    builder.addCase(createReportSectionTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createReportSectionTemplate.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.reportSectionTemplates ){
        state.reportSectionTemplates = [...state.reportSectionTemplates, action.payload]
      }
    });
    // Update report section
    builder.addCase(updateReportSectionTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateReportSectionTemplate.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.reportSectionTemplates ){
        state.reportSectionTemplates = state.reportSectionTemplates.map((reportSection:any) => {
          if ( reportSection.id === action.payload.id ) return action.payload;
          return reportSection;
        })
      }
    });
    // Delete report section
    builder.addCase(deleteReportSectionTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteReportSectionTemplate.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.reportSectionTemplates ){
        state.reportSectionTemplates = state.reportSectionTemplates.filter((reportSection:any) => {
          return reportSection.id !== action.payload
        })
      }
    });

    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const ReportSectionTemplatesActions = slice.actions;

export default slice.reducer;
