import { ReactNode, FC, Fragment, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Models
import { ICurrentAccount } from 'app/models/Account';
import { IMyUser } from 'app/models/User';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getExportTemplates } from 'app/store/ExportTemplates/ExportTemplates.async';
import { getTeams } from 'app/store/Teams/Teams.async';
import { getAccounts } from 'app/store/Clients/Clients.async';
import { createInsuranceCase } from 'app/store/Cases/Cases.async';
import { selectLoading } from 'app/store/Cases/Cases.selectors';
// Api query
import casesApi, { useLazyGetSearchCasesQuery } from 'app/store/Cases/Cases.api';
// Selector
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectCurrentAccount, selectLegacyReportTemplatesEnabled, selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { SxProps, Grid, Stepper, Step, StepLabel, Box, Theme } from '@mui/material';
// Icons
import {
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  Error as ErrorIcon
} from '@mui/icons-material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { convertDateToString, convertTimeToString } from 'app/utilities/DateTime';
// Check dialog
import CaseCheckDialog from '../CaseCheckDialog';
// 
import GeneralStepContent from './GeneralStepContent';
import ClaimantStepContent from './ClaimantStepContent';
import ReportStepContent from './ReportStepContent';
import MedicareStepContent from './MedicareStepContent';
import NotificationsStepContent from './NotificationsStepContent';
import ReviewCreateStepContent from './ReviewCreateStepContent';

interface IFormData {
  name: string;
  reportTemplateId: string | number;
  teamId: string | number;
  clientAccountId?: string | number;
  claimant: {
    name: string;
    dateOfBirth: Dayjs | null;
  },
  settings: {
    medicareOverchargeThreshold: number;
  };
  eventDate: Dayjs | null,
  eventTime: Dayjs | null;
  claimNumber: string;
  notificationPreferences: {
    emails: string[];
  }
};

type Props = {
  open: boolean;
  onClose: () => void;
}

const CaseCreateFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const currentAccount = useAppSelector(selectCurrentAccount) as ICurrentAccount;
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);
  const loading = useAppSelector(selectLoading);
  // Query
  const [ getSearchCases, { data:searchCasesData, isLoading } ] = useLazyGetSearchCasesQuery();

  const steps = [
    'General',
    'Claimant',
    legacyReportTemplatesEnabled ? 'Report' : '',
    hcpcsCodesEnabled ? 'Medicare' : '',
    'Notifications',
    'Review + Create'
  ].filter(s => s);

  const stepFields: (string[] | null)[] = [
    ['name', 'eventDate', 'eventTime', 'teamId', 'clientAccountId'],
    ['claimant', 'claimNumber'],
    legacyReportTemplatesEnabled ? ['reportTemplateId'] : null,
    ['settings'],
    ['notificationPreferences.emails'],
    []
  ].filter(s => s !== null);

  const methods = useForm<IFormData>({
    defaultValues: {
      name: '',
      reportTemplateId: '',
      teamId: '',
      clientAccountId: '',
      settings: {
        medicareOverchargeThreshold: currentAccount.settings.medicareOverchargeThreshold
      },
      claimant: {
        name: '',
        dateOfBirth: null
      },
      eventDate: null,
      eventTime: null,
      claimNumber: '',
      notificationPreferences: {
        emails: [myUser.email]
      }
    }
  });

  const { trigger, handleSubmit, formState: { errors } } = methods;

  const [ activeStep, setActiveStep ] = useState(0);
  const [ visitedSteps, setVisitedSteps ] = useState<number[]>([0]);
  const [ isSummaryStepVisited, setIsSummaryStepVisited ] = useState(false);
  const [ caseFormData, setCaseFormData ] = useState<IFormData | null>(null);

  const { open:openCheckDialog, toggle:toggleCheckDialog } = useToggle();

  useEffect(() => {
    dispatch(getTeams({}));
    dispatch(getExportTemplates({}));
    dispatch(getAccounts({}));

    return () => {
      dispatch(casesApi.util.resetApiState());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( searchCasesData ){
      if ( Object.keys(searchCasesData).length ){
        toggleCheckDialog();
      } else {
        asyncCreateInsuranceCase();
      }
    }
    // eslint-disable-next-line
  }, [searchCasesData]);

  const onSubmit = handleSubmit((data:IFormData) => {
    const { reportTemplateId, teamId, clientAccountId, settings, name, claimant, eventDate, eventTime, claimNumber, notificationPreferences } = data;
    const nextData:any = {
      name,
      teamId: Number(teamId),
      clientAccountId: Number(clientAccountId),
      eventDate: eventDate ? convertDateToString(eventDate) : null,
      claimant: {
        name: claimant.name,
        dateOfBirth: claimant.dateOfBirth ? convertDateToString(claimant.dateOfBirth) : null,
      }
    };
    if ( eventTime ) nextData['eventTime'] = convertTimeToString(eventTime);
    if ( claimNumber ) nextData['claimNumber'] = claimNumber;
    if ( legacyReportTemplatesEnabled && reportTemplateId ) nextData['reportTemplateId'] = Number(reportTemplateId);
    if ( notificationPreferences ){
      const { emails } = notificationPreferences;
      if ( emails && emails.length ){
        nextData['notificationPreferences'] = { emails };
      }
    }

    if ( hcpcsCodesEnabled ){
      nextData['settings'] = {
        medicareOverchargeThreshold: Number(settings.medicareOverchargeThreshold)
      };
    }

    setCaseFormData(nextData);

    getSearchCases({
      params: {
        'claimant.name': nextData.claimant.name,
        'claimant.dateOfBirth': nextData.claimant.dateOfBirth,
      },
      caseId: undefined
    });
  });

  const asyncCreateInsuranceCase = async () => {
    dispatch(casesApi.util.resetApiState());

    try {
      await dispatch(createInsuranceCase(caseFormData)).unwrap();
      onClose();
    } catch(error){}
  }

  const handleStepClick = (step:number) => async () => {
    const isSummaryStep = step === steps.length - 1;
    setActiveStep(step);
    if ( isSummaryStepVisited ){
      await trigger(stepFields[activeStep] as (keyof IFormData)[]);
    } else {
      if ( isSummaryStep ){
        if ( !isSummaryStepVisited ) setIsSummaryStepVisited(true);
        await Promise.all(stepFields.slice(0, steps.length - 1).map(field => trigger(field as (keyof IFormData)[])));
      }
    }
  };

  const handleNext = async () => {
    const isSummaryStep = activeStep === steps.length - 2; // Check if the next step is the Summary step
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    setVisitedSteps((prev) => {
      if ( !prev.includes(newActiveStep) ) return [...prev, newActiveStep];
      return prev;
    });
    if ( isSummaryStepVisited ){
      await trigger(stepFields[activeStep] as (keyof IFormData)[]);
    } else {
      if ( isSummaryStep ){
        if ( !isSummaryStepVisited ) setIsSummaryStepVisited(true);
        await Promise.all(stepFields.slice(0, steps.length - 1).map(field => trigger(field as (keyof IFormData)[])));
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const hasError = (fields:(keyof IFormData)[]): boolean => {
    return fields.some(field => !!errors[field]);
  };

  const getStepStatus = (step:number): { icon: ReactNode; sx:SxProps<Theme>; disabled:boolean; error:boolean } => {
    const active = activeStep === step;
    const disabled = !visitedSteps.includes(step);
    const error = hasError(stepFields[step] as (keyof IFormData)[]);
    return {
      icon: error
        ? <ErrorIcon color="error" />
        : active
          ? <RadioButtonCheckedIcon color="primary" />
          : <RadioButtonUncheckedIcon color="disabled" />
      ,
      sx: {
        cursor: 'pointer !important',
        '& .MuiStepLabel-iconContainer': {
          color: (theme:Theme) => `${theme.palette.primary.main} !important`
        },
        '& .MuiStepLabel-label': {
          color: (theme:Theme) => `${
            error
              ? theme.palette.error.main
              : active
                ? theme.palette.primary.main
                : theme.palette.grey[700]
          }`,
          '&.Mui-completed': {
            fontWeight: 400
          }
        }
      },
      error,
      disabled
    };
  };

  const handleClose = (isAccepted:boolean) => {
    toggleCheckDialog();
    dispatch(casesApi.util.resetApiState());

    if ( !isAccepted ) return;

    asyncCreateInsuranceCase();
  }

  const title = `${t('labels.create')} ${t('labels.case')}`;

  const getDisplay = (step: number) => activeStep === step ? 'block' : 'none';

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        title={title}
      >
        <FormProvider {...methods}>
          <Box
            sx={{ width: '100%' }}
            component="form"
            onSubmit={onSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => {
                    const { icon, sx, disabled, error } = getStepStatus(index);
                    return (
                      <Step
                        key={label}
                        disabled={disabled}
                        onClick={handleStepClick(index)}
                      ><StepLabel sx={sx} icon={icon} error={error}>{label}</StepLabel></Step>
                    );
                  })}
                </Stepper>
              </Grid>
              <Grid item xs={8}>
                {activeStep < steps.length ? (
                  <Box sx={{ minHeight: 480 }}>
                    <Box sx={{ display: getDisplay(0) }}><GeneralStepContent /></Box>
                    <Box sx={{ display: getDisplay(1) }}><ClaimantStepContent /></Box>
                    {legacyReportTemplatesEnabled ? (
                      <Box sx={{ display: getDisplay(2) }}><ReportStepContent /></Box>
                    ) : null}
                    {hcpcsCodesEnabled ? (
                      <Box sx={{ display: getDisplay(legacyReportTemplatesEnabled ? 3 : 2) }}><MedicareStepContent /></Box>
                    ) : null}
                    <Box sx={{ display: getDisplay(steps.length - 2) }}><NotificationsStepContent /></Box>
                    <Box sx={{ display: getDisplay(steps.length - 1) }}><ReviewCreateStepContent /></Box>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
            {activeStep < steps.length ? (
              <Box sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2
              }}>
                <Button
                  name="Back"
                  disabled={activeStep === 0 || (isLoading || loading)}
                  onClick={handleBack}
                >Back</Button>
                {activeStep === steps.length - 1 ? (
                  <LoadingButton
                    name={`Create case form dialog`}
                    loading={isLoading || loading}
                    disabled={Boolean(Object.keys(errors).length)}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >{t('labels.create')}</LoadingButton>
                ) : (
                  <Button
                    name={`Next ${activeStep}`}
                    onClick={handleNext}
                    color="primary"
                    variant="contained"
                  >Next</Button>
                )}
              </Box>
            ) : null}
          </Box>
        </FormProvider>
      </Dialog>
      {openCheckDialog ? (
        <CaseCheckDialog
          open={open}
          onClose={handleClose}
          searchCasesData={searchCasesData}
        />
      ) : null}
    </Fragment>
  );
};

export default CaseCreateFormDialog;
