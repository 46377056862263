import { FC, Fragment } from 'react';
// Mui
import { Box, Fab } from '@mui/material';
// Components
import Icon from 'app/components/Icon';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';
// 
import OrderFormDialog from './OrderFormDialog';
// i18next
import { useTranslation } from 'react-i18next';

const OrdersFab:FC = () => {
  const { t } = useTranslation('common');

  const { open, toggle } = useToggle();

  // For testing
  const fabAttr = generateAttrForTesting('action', 'fab', 'Create billing order');

  return (
    <Fragment>
      <Box
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        react-action="fab"
      >
        <Fab
          {...fabAttr}
          variant="extended"
          color="primary"
          onClick={toggle}
        >
          <Icon sx={{ mr: 2 }} icon="add" />
          {t('pages.adminPages.ordersPage.order')}
        </Fab>
      </Box>
      {/* Order create form dialog */}
      {open ? (
        <OrderFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default OrdersFab;
