import { FC } from 'react';
import { forceVisible } from 'react-lazyload';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectFilter } from 'app/store/page/page.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import AutocompleteAuthors from 'app/components/AutocompleteAuthors';

const EpisodeAuthors:FC = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const filter:any = useSelector(selectFilter);

  const handleChange = (nextValue:number[]) => {
    dispatch(PageActions.setFilter('authors', nextValue));
    forceVisible();
  }

  return (
    <Box sx={{ width: 320 }}>
      <AutocompleteAuthors
        value={filter.authors}
        onChange={handleChange}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default EpisodeAuthors;
