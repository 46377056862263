import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// Models
import IOption from "app/models/Option";
import { getI18n } from "react-i18next";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);


const i18n = getI18n();
i18n.setDefaultNamespace('common');

const today = dayjs();

export let isRequired = i18n.t('validations.fieldRequired');

export const isRequiredMultiple = (value:IOption[]) => {
  if ( !value.length ) return i18n.t('validations.fieldRequired');
  return true;
}

export const isEmailValid = {
  // eslint-disable-next-line
  value: /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
  message: i18n.t('validations.emailNotValid')
};

export const isGreaterZero = {
  value: /^\s*(?=.*[1-9])\d*(?:\.\d{1,9})?\s*$/,
  message: `The value must be greater than 0`
}

export const isMin = (value:any) => ({
  value,
  message: i18n.t('validations.minValue', { value })
});

export const isMax = (value:any) => ({
  value,
  message: i18n.t('validations.maxValue', { value })
});

// export const isDateValid = (value?:Dayjs | null) => ({
//   validation: value ? value.isValid() : true,
//   message: i18n.t('validations.dateNotValid')
// });

export const isDateValid = (date:Dayjs | null):string | boolean => {
  if ( date && !date.isValid() ) return i18n.getResource(i18n.language, 'common', 'validations.dateNotValid');
  // false - not valid
  // true - valid
  return true;
}

export const isTimeValid = (date:Dayjs | null):string | boolean => {
  if ( date && !date.isValid() ) return i18n.getResource(i18n.language, 'common', 'validations.timeNotValid');
  // false - not valid
  // true - valid
  return true;
}

export const isDateInFeature = (value?:Dayjs | null) => ({
  validation: value ? value.isSameOrBefore(today, 'day') : true,
  message: i18n.t('validations.dateCannotBeSetInFuture')
});

export const isDateInPast = (value?:Dayjs | null) => ({
  validation: value ? value.isSameOrAfter(today.subtract(100, 'year'), 'day') : true,
  message: i18n.t('validations.dateIsMoreThan100')
});

export const isStartBeforeEnd = (start?:Dayjs | null, end?:Dayjs | null) => ({
  validation: start && end ? start.isBefore(end) : true,
  message: i18n.t('validations.startDateCantBeMoreThanEndDate')
});





export const isMaxLength = (maxValue:number) => ({
  value: maxValue,
  message: i18n.t('validations.maxCharactersSize', { value: maxValue })
});

export const isDateOfBirthValid = (date:Dayjs | null):string | boolean => {
  if ( !date || !date.isValid() ) return i18n.t('validations.dateNotValid') as string;
  const today = dayjs();
  if ( date.isAfter(today) ) return i18n.t('validations.dateCannotBeSetInFuture') as string;
  // false - not valid
  // true - valid
  return true;
}

export const validateEpisodeDate = (date:Dayjs | null) => {
  if ( !date ) return true;

  if ( !date.isValid() ) return i18n.t('validations.dateNotValid');

  const now = dayjs();

  if ( date.isAfter(now.add(100, 'year'), 'day') ) return i18n.t('validations.dateIsMoreThan100Ahead');
  if ( date.isBefore(now.subtract(100, 'year'), 'day') ) return i18n.t('validations.dateIsMoreThan100');

  // // false - not valid
  // // true - valid
  // return true;
}

export const validateEpisodeTime = (time:Dayjs | null) => {
  if ( time && !time.isValid() ) return i18n.t('validations.timeNotValid');
  // false - not valid
  // true - valid
  return true;
}

export const validateDateTime = (dateTime:Dayjs | null) => {
  if ( !dateTime ) return true;

  if ( !dateTime.isValid() ) return i18n.t('validations.dateTimeNotValid');

  const now = dayjs();

  if ( dateTime.isSameOrAfter(now, 'minute') ) return i18n.t('validations.dateTimeCannotBeSetInFuture')
  // false - not valid
  // true - valid
  return true;
}

function handleChangeLanguage() {
  isRequired = i18n.t('validations.fieldRequired');
  isEmailValid.message = i18n.t('validations.emailNotValid');
}

i18n.on('languageChanged', handleChangeLanguage);
