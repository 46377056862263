import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.insuranceCase.create'),
  Update: i18n.t('notifications.insuranceCase.update'),
  Delete: i18n.t('notifications.insuranceCase.delete'),
  Archive: i18n.t('notifications.insuranceCase.archive'),
  Unarchive: i18n.t('notifications.insuranceCase.unarchive'),
  Accept: i18n.t('notifications.insuranceCase.accept'),
  Reject: i18n.t('notifications.insuranceCase.reject'),
  Transmit: i18n.t('notifications.insuranceCase.transmit'),
  CreateDuplicate: i18n.t('notifications.insuranceCase.createDuplicate'),
  Status: i18n.t('notifications.insuranceCase.statusUpdate')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.insuranceCase.create');
  Messages.Update = i18n.t('notifications.insuranceCase.update');
  Messages.Delete = i18n.t('notifications.insuranceCase.delete');
  Messages.Archive = i18n.t('notifications.insuranceCase.archive');
  Messages.Unarchive = i18n.t('notifications.insuranceCase.unarchive');
  Messages.Accept = i18n.t('notifications.insuranceCase.accept');
  Messages.Reject = i18n.t('notifications.insuranceCase.reject');
  Messages.Transmit = i18n.t('notifications.insuranceCase.transmit');
  Messages.CreateDuplicate = i18n.t('notifications.insuranceCase.createDuplicate');
  Messages.Status = i18n.t('notifications.insuranceCase.statusUpdate');
});