import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
// Types
import Reducers from 'app/types/Reducers';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
// Asyncs
import { createReportSectionTemplate, updateReportSectionTemplate, deleteReportSectionTemplate } from "app/store/ReportSectionTemplates/ReportSectionTemplates.async";
// Services
import Mixpanel from "app/services/Mixpanel.service";
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';

const ReportSectionTemplatesMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.ReportSectionTemplates) ){
    const state = getState()
    const currenAccount = selectCurrentAccount(state);

    if ( type === createReportSectionTemplate.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseReportSectionTemplateAdd, {
        accountId: currenAccount.id,
        reportSectionTemplateId: payload.id
      });
    }
    if ( type === updateReportSectionTemplate.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseReportSectionTemplateEdit, {
        accountId: currenAccount.id,
        reportSectionTemplateId: payload.id
      });
    }
    if ( type === deleteReportSectionTemplate.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.CaseReportSectionTemplateDelete, {
        accountId: currenAccount.id,
        reportSectionTemplateId: payload
      });
    }
  }
  return next(action);
}

export default ReportSectionTemplatesMiddleware;
