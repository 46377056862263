// Types
import Reducers from "app/types/Reducers";
// Models
import { IPresenterPage } from "app/models/Page";
import IEpisode from "app/models/Episode";
// Actions
import { AppUiNotificationsActions } from "app/store/AppUINotifications/AppUINotifications.slice";
import { CasesActions } from "app/store/Cases/Cases.slice";
import { DMSDocumentsActions } from "app/store/DMSDocuments/DMSDocuments.slice";
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post } from 'app/utilities/HttpClient';
import { downloadFile } from "app/utilities/Utilities";
// ToDO
import PageActions from "app/store/page/page.actions";
import { pageMessages } from "app/store/page/page.messages";
// End: ToDO

const _url:string = '/presentation';

// Get insurance cases
export const getInsuranceCases = asyncThunkHandler(
  `${Reducers.Presenter}/Get insurance cases`,
  async () => {
    const response:Response = await $get(`${_url}/insuranceCases`);
    return response.json();
  }
);
// Get insurance case by id
export const getInsuranceCaseById = asyncThunkHandler(
  `${Reducers.Presenter}/Get insurance case by id`,
  async (insuranceCaseId:number, { dispatch, fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/insuranceCases/${insuranceCaseId}`);
    const insuranceCase:any = await response.json();
    dispatch(CasesActions.setInsuranceCase(insuranceCase))
    return fulfillWithValue('Insurance was received');
  }
);
// Get documents
export const getDocuments = asyncThunkHandler(
  `${Reducers.Presenter}/Get documents`,
  async (params:any, { dispatch, fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/documents`, params);
    const documents:any[] = await response.json();
    dispatch(DMSDocumentsActions.setDocuments(documents));
    return fulfillWithValue('Documents was received');
  }
);
// Get pages
export const getPages = asyncThunkHandler(
  `${Reducers.Presenter}/Get pages`,
  async (params:any, { dispatch, fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/pages`, params);
    const pages:IPresenterPage[] = await response.json();
    dispatch(DMSDocumentPagesActions.setPages(pages));
    dispatch(PageActions.fetchPagesSuccess(pages as any));
    return fulfillWithValue('Pages were received');
  }
);
// Get episodes
export const getEpisodes = asyncThunkHandler(
  `${Reducers.Presenter}/Get episodes`,
  async (params:any, { dispatch, fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/episodes`, params);
    const episodes:IEpisode[] = await response.json();
    dispatch(EpisodesActions.setEpisodes(episodes));
    return fulfillWithValue('Episodes was received');
  }
);
// Get settings
export const getSettings = asyncThunkHandler(
  `${Reducers.Presenter}/Get settings`,
  async () => {
    const response:Response = await $get(`${_url}/settings`);
    return response.json();
  }
);

// Export pages to PDF
export const exportPagesToPDF = asyncThunkHandler(
  `${Reducers.DMSDocumentPages}/Export pages to pdf`,
  async (pages:{ documentId:number; pageNum:number }[], { dispatch, fulfillWithValue }) => {
    const response:Response = await $post(`${_url}/pages/actions/export`, pages);

    downloadFile(response, { name: `export-pages-${new Date()}`, ext: 'pdf' });

    dispatch(AppUiNotificationsActions.addSnackbar({
      message: pageMessages.exportPagesToPDF
    }));

    return fulfillWithValue('Pages to pdf exported');
  }
);
