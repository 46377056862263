import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import es from 'dayjs/locale/es';
import en from 'dayjs/locale/en';

import { getI18n } from 'react-i18next';
import IOption from 'app/models/Option';
// eslint-disable-next-line
const locales:Record<string, any> = { en, es };
const i18n = getI18n();

dayjs.locale(i18n.language);
dayjs.extend(weekday);

i18n.on('languageChanged', function(lng) {
  dayjs.locale(lng);
})

export interface IDay {
  dateString: string;
  day: number;
  isCurrentMonth: boolean;
};

const dateStringFormat = 'YYYY-MM-DD';

export default class CalendarService {
  public static generateWeekdays():string[] {
    return new Array(7).fill(dayjs().startOf('week'))
      .map((day:Dayjs, index) => day.add(index, 'day').format('ddd'));
  }

  public static getCurrentMonthDays(year:number, month:number):IDay[] {
    const daysInMonth = dayjs(`${year}-${month}-01`).daysInMonth();
    // console.log(daysInMonth);
    return [...Array(daysInMonth)].map((_:any, index:number) => ({
      dateString: dayjs(`${year}-${month}-${index + 1}`).format(dateStringFormat),
      day: index + 1,
      isCurrentMonth: true
    }))
  };

  public static getPreviousMonthDays(year:number, month:number):IDay[] {
    const currentMonthDays = this.getCurrentMonthDays(year, month);
    const firstDay = currentMonthDays[0];

    const prevMonth = dayjs(`${year}-${month}-01`).subtract(1, 'month');
    const firstDayOfTheMonthWeekday = dayjs(firstDay.dateString).weekday();
    const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday;

    const previousMonthLastMondayDayOfMonth = dayjs(firstDay.dateString)
      .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
      .date();

    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => {
      return {
        dateString: dayjs(
          `${prevMonth.year()}-${prevMonth.month() + 1}-${
            previousMonthLastMondayDayOfMonth + index
          }`
        ).format(dateStringFormat),
        day: previousMonthLastMondayDayOfMonth + index,
        isCurrentMonth: false
      };
    });
  }

  public static getNextMonthDays(year:number, month:number):IDay[] {
    const currentMonthDays = this.getCurrentMonthDays(year, month);

    const lastDayOfTheMonthWeekday = dayjs(`${year}-${month}-${currentMonthDays.length}`).weekday();

    const nextMonth = dayjs(`${year}-${month}-01`).add(1, 'month');
    const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday;
  
    return [...Array(visibleNumberOfDaysFromNextMonth)].map((_:any, index:number) => {
      return {
        dateString: dayjs(
          `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
        ).format(dateStringFormat),
        day: index + 1,
        isCurrentMonth: false
      };
    });
  }

  public static generateMonthsOptions():any[] {
    return Array.from({ length: 12 }, (_:any, index:number) => index).map((_:any, index:number) => ({
      id: index + 1,
      name: dayjs().month(index).format('MMMM')
    }))
  }

  public static generateYearOptions(minYear:number, maxYear:number):IOption[] {
    return this.generateYears(minYear, maxYear).map((year:number) => ({ id: year, name: year.toString() }));
  }

  public static generateYears(minYear:number, maxYear:number):number[] {
    const result = [minYear];
    while ( minYear < maxYear ){
      minYear++;
      result.push(minYear);
    }
    return result;
  }
}