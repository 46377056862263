import React from 'react';
// Mui
import Chip from '@mui/material/Chip';

type Props = {
  id: number | string;
  labels: Array<string> | undefined;
  onDelete?: (index:number) => void;
};

const EpisodeLabels:React.FC<Props> = ({
  // Props
  id, labels, onDelete
}) => {
  const handleDelete = (index:number) => () => {
    if ( onDelete ) onDelete(index);
  }

  if ( !labels ) return null;
  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap'
    }}>
      {labels.map((label:string, index:number) => (
        <Chip
          key={`label-item-${id}-${index}`}
          sx={{
            borderRadius: '4px',
            marginTop: '4px',
            marginRight: '4px',
            padding: '6px 10px',
            '& .MuiChip-label': {
              padding: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px'
            },
            '& .MuiChip-deleteIcon': {
              margin: '0 0 0 6px'
            }
          }}
          label={label}
          onDelete={typeof onDelete !== 'undefined' ? handleDelete(index) : undefined}
        />
      ))}
    </div>
  )
}

export default EpisodeLabels;
