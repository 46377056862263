import { FC, Fragment, useMemo, useState } from 'react';
// Models
import ICaseAlert from 'app/models/CaseAlert';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteAlert, resolveAlert } from 'app/store/CaseAlerts/CaseAlerts.async';
// Selectors
import { selectLoading } from 'app/store/CaseAlerts/CaseAlerts.selectors';
// Mui
import { Box, Alert, AlertTitle, Tooltip } from '@mui/material';
// Icons
import {
  Check as CheckIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  alert: ICaseAlert;
}

const AlertItem:FC<Props> = ({
  // Props
  alert
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { open, toggle } = useToggle();

  const [ showDetails, setShowDetails ] = useState(false);

  const handleResolveAlert = async () => {
    try {
      dispatch(resolveAlert(alert.id));
    } catch(error){}
  }

  const handleConfirm = async () => {
    try {
      dispatch(deleteAlert(alert.id));
    } catch(error){}
  }

  const handleClick = () => {
    setShowDetails(!showDetails);
  }

  return (
    <Fragment>
      <Alert
        icon={false}
        color={alert.resolved ? 'success' : alert.severity}
        action={
          <Fragment>
            {!alert.resolved ? (
              <Tooltip title={t('dialogs.caseAlerts.resolve')}>
                <IconButton
                  name={`Resolve alert ${alert.id}`}
                  onClick={handleResolveAlert}
                ><CheckIcon /></IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title={t('labels.delete')}>
              <IconButton
                name={`Delete alert ${alert.id}`}
                onClick={toggle}
              ><DeleteIcon /></IconButton>
            </Tooltip>
          </Fragment>
        }
      >
        <AlertTitle>{alert.title}</AlertTitle>
        {showDetails ? (
          <div dangerouslySetInnerHTML={{ __html: alert.description }} />
        ) : null}
        {alert.description ? (
          <Box
            component="span"
            sx={{
              display: 'inline-block',
              mt: 2,
              fontWeight: 700,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={handleClick}
          >{showDetails ? t('dialogs.caseAlerts.hideDetails') : t('dialogs.caseAlerts.showDetails')}</Box>
        ) : null}
      </Alert>
      {open ? (
        <ConfirmationDialog
          open={open}
          onClose={toggle}
          loading={loading}
          title={t('dialogs.caseAlerts.deleteAlert')}
          content={t('dialogs.caseAlerts.deleteAlertConfirmation')}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default AlertItem;
