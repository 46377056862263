import { FC, Fragment, useEffect } from 'react'
import { useSnackbar } from 'notistack';
// Models
import ISnackbar from 'app/models/Snackbar';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Selectors
import { selectSnackbars } from 'app/store/AppUINotifications/AppUINotifications.selectors';
// Icons
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import { Button, IconButton } from 'app/components/Mui';

let displayedKeys:string[] = [];

const Snackbars:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const snackbars:ISnackbar[] | null = useAppSelector(selectSnackbars);

  const { enqueueSnackbar:handleEnqueueSnackbar, closeSnackbar:handleCloseSnackbar } = useSnackbar();

  const closeSnackbar = (key:string) => () => {
    dispatch(AppUiNotificationsActions.closeSnackbar({ key, dismissAll: Boolean(key) }));
  }
  const removeSnackbar = (key:string) => {
    dispatch(AppUiNotificationsActions.removeSnackbar(key));
  }

  const storeDisplayed = (key:string | undefined) => {
    if ( key ) displayedKeys = [...displayedKeys, key];
  };

  const removeDisplayed = (key:string) => {
    displayedKeys = displayedKeys.filter((displayedKey:string) => displayedKey !== key);
  };

  useEffect(() => {
    if ( !snackbars.length ) return;

    console.log(snackbars);

    snackbars.forEach(({ key, message, options = {}, dismissed = false, onOpen }:ISnackbar) => {
      if ( dismissed ){
        handleCloseSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if ( key && displayedKeys.includes(key) ) return;

      handleEnqueueSnackbar(message, {
        key,
        ...options,
        action: (myKey:string) => (
          <Fragment>
            {onOpen ? (
              <Button
                name="open-link"
                size="small"
                variant="contained"
                onClick={onOpen}
                sx={{ mr: 1 }}
              >Open</Button>
            ) : null}
            <IconButton
              name="Close snackbar"
              sx={{ color: 'white' }}
              onClick={closeSnackbar(myKey)}
              color="inherit"
              size="small"
            ><CloseIcon /></IconButton>
          </Fragment>
        ),
        onClose: (event, reason, myKey) => {
          if ( options.onClose ) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey:string) => {
          removeSnackbar(myKey);
          removeDisplayed(myKey);
        },
      });
      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
    // eslint-disable-next-line
  }, [snackbars]);

  return null;
}

export default Snackbars;
