import { FC, Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import IOption from 'app/models/Option';
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getAccounts } from 'app/store/Clients/Clients.async';
import { transmitInsuranceCase } from 'app/store/Cases/Cases.async';
// Selectors
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectLoading } from 'app/store/Cases/Cases.selectors';
// Mui
import { Typography } from '@mui/material';
// Dialogs
import ConfirmationDialog from './ConfirmationDialog';
// Components
import Dialog from 'app/components/Dialog';
import { Autocomplete } from 'app/components/Autocomplete';
import { Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utility
import { isRequired } from 'app/utilities/Validations';
// i18next
import { Trans, useTranslation } from 'react-i18next';

interface IFormData {
  clientAccountId: string | number;
};

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCase: IInsuranceCase;
}

const CaseReassignDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCase
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const clientsAccountsOptions:IOption[] | null = useAppSelector(selectAccountsAsOptions);
  const loading = useAppSelector(selectLoading);

  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const [ prevClientAccount, setPrevClientAccount ] = useState<IOption | null>(null);
  const [ nextClientAccount, setNextClientAccount ] = useState<IOption | null>(null);

  const { control, handleSubmit, formState: { errors }, getValues } = useForm<IFormData>({
    defaultValues: {
      clientAccountId: insuranceCase.clientAccountId
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const { clientAccountId } = data;
    const foundedPrevClientAccount = clientsAccountsOptions?.find((option:IOption) => option.id === insuranceCase.clientAccountId);
    const foundedNextClientAccount = clientsAccountsOptions?.find((option:IOption) => option.id === clientAccountId);
    setPrevClientAccount(foundedPrevClientAccount || null)
    setNextClientAccount(foundedNextClientAccount || null)
    toggleConfirmationDialog();
  });

  useEffect(() => {
    dispatch(getAccounts({}));
    // eslint-disable-next-line
  }, []);

  const handleConfirm = async () => {
    const clientAccountId = getValues('clientAccountId');
    try {
      await dispatch(transmitInsuranceCase({
        insuranceCaseId: insuranceCase.id,
        data: { clientAccountId: Number(clientAccountId) }
      }));
      toggleConfirmationDialog();
      onClose();
    } catch(error){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel reasign case dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Submit reassign case dialog"
        loading={loading}
        type="submit"
        variant="contained"
        color="primary"
      >{t('labels.reassign')}</LoadingButton>
    </Fragment>
  );

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        title={t('dialogs.caseReassign.reassignCaseToAnotherClientAccount')}
        actions={actions}
        maxWidth="sm"

        PaperProps={{
          component: 'form',
          onSubmit,
          noValidate: true
        }}
      >
        {/* Notes */}
        <Controller
          control={control} name="clientAccountId"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              label={t('labels.clientAccount')}
              options={clientsAccountsOptions || []}
              required={true}
              error={Boolean(errors.clientAccountId)}
              helperText={errors.clientAccountId ? errors.clientAccountId.message : ''}
              renderOptions={(props, option) => (
                <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
                  <Typography variant="subtitle1">{option.name}</Typography>
                  {option.props?.fullAddress ? (
                    <Typography variant="caption">{option.props.fullAddress}</Typography>
                  ) : null}
                </li>
              )}
            />
          )}
        />
      </Dialog>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          title={t('dialogs.caseReassign.caseReassignment')}
          content={
            <Fragment>
              <Typography variant="body1" pb="8px">
                <Trans
                  t={t} i18nKey="dialogs.caseReassign.caseReassignmentConfirmationHint1"
                  components={{ b: <b /> }}
                  values={{ caseName: insuranceCase.name, prevClientAccountName: prevClientAccount?.name || 'null', nextClientAccountName: nextClientAccount?.name || 'null' }}
                />
              </Typography>
              <Typography variant="body1" pb="8px">{t('dialogs.caseReassign.caseReassignmentConfirmationHint2')}</Typography>
              <Typography variant="body1">
                <Trans
                  t={t} i18nKey="dialogs.caseReassign.caseReassignmentConfirmationHint3"
                  components={{ b: <b /> }}
                  values={{ prevClientAccountName: prevClientAccount?.name || 'null', nextClientAccountName: nextClientAccount?.name || 'null' }}
                />
              </Typography>
            </Fragment>
          }
          loading={loading}
        />
      ) : null}
    </Fragment>
  )
}

export default CaseReassignDialog;