import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IReportPreset from "app/models/ReportPreset";
// Async
import { getPresets, createPreset, updatePreset, deletePreset } from './ReportPresets.async';

interface IState {
  presets: IReportPreset[] | null;
  preset: IReportPreset | null;
  filter: {
    search: string;
  };
  loading: boolean;
};

const initialState:IState = {
  presets: null,
  preset: null,
  filter: {
    search: ''
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.ReportPresets,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get presets
    builder.addCase(getPresets.pending, (state) => {
      state.presets = null;
    });
    builder.addCase(getPresets.fulfilled, (state, action:PayloadAction<IReportPreset[]>) => {
      state.presets = action.payload;
    });
    // Create preset
    builder.addCase(createPreset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPreset.fulfilled, (state, action:PayloadAction<IReportPreset>) => {
      if ( state.presets ){
        state.presets = [...state.presets, action.payload];
      }
    });
    // Update preset
    builder.addCase(updatePreset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePreset.fulfilled, (state, action:PayloadAction<IReportPreset>) => {
      if ( state.presets ){
        state.presets = state.presets.map((preset:IReportPreset) => {
          if ( preset.id === action.payload.id ) return action.payload;
          return preset;
        })
      }
    });
    // Delete preset
    builder.addCase(deletePreset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePreset.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.presets ){
        state.presets = state.presets.filter((presenter:IReportPreset) => {
          return presenter.id !== action.payload
        });
      }
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const ReportPresetsActions = slice.actions;

export default slice.reducer;
