import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import IPage, { IPresenterPage } from 'app/models/Page';
import IEpisode from 'app/models/Episode';
// Selectors
import { selectDuplicatesEntities } from 'app/store/Cases/Cases.selectors';
import { selectEpisodesEntities, selectEpisodesByPageId } from 'app/store/Episodes/Episodes.selectors';
import { selectEntities as selectBatchesEntities } from 'app/store/DMSBatches/DMSBatches.selectors';
import { selectDocumentsEntities } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Services
import PageService from 'app/services/PageService';
import { IBatch } from 'app/store/DMSBatches/DMSBatches.models';
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';

export const selectPage = (state:RootState) => state[Reducers.DMSDocumentPages].page;
export const selectPagesIds = (state:RootState) => state[Reducers.DMSDocumentPages].pages.ids;
export const selectPagesEntities = (state:RootState) => state[Reducers.DMSDocumentPages].pages.entities;
export const selectPagesFilter = (state:RootState) => state[Reducers.DMSDocumentPages].pages.filter;

export const selectSearchPagesList = (state:RootState) => state[Reducers.DMSDocumentPages].searchPages.list;
export const selectSearchPagesParams = (state:RootState) => state[Reducers.DMSDocumentPages].searchPages.params;
export const selectSearchPagesFetching = (state:RootState) => state[Reducers.DMSDocumentPages].searchPages.fetching;

export const selectStapleModeType = (state:RootState) => state[Reducers.DMSDocumentPages].stapleMode.type;
export const selectStapleModeId = (state:RootState) => state[Reducers.DMSDocumentPages].stapleMode.id;
export const selectStapleModePagesIds = (state:RootState) => state[Reducers.DMSDocumentPages].stapleMode.pagesIds;
export const selectStapleModeDisabledPagesIds = (state:RootState) => state[Reducers.DMSDocumentPages].stapleMode.disabledPagesIds;

export const selectError = (state:RootState) => state[Reducers.DMSDocumentPages].error;
export const selectLoading = (state:RootState) => state[Reducers.DMSDocumentPages].loading;

export const selectForceReload = (state:RootState) => state[Reducers.DMSDocumentPages].forceReload;

interface IEntity {
  [key:number]: Record<number, IPage | IPresenterPage>;
};

// Page
export const selectPageEntity = createSelector(
  [
    selectPagesEntities,
    (_, props:{ documentId:number, pageNum:number }) => props
  ],
  (entities:IEntity, { documentId, pageNum }) => entities[documentId][pageNum]
);

export const selectSearchPageBboxes = createSelector(
  [
    selectSearchPagesList,
    (_, props:{ documentId:number, pageNum:number }) => props
  ],
  (pages:any[], { documentId, pageNum }) => {
    if ( !pages ) return null;
    const foundedPage = pages.find((page:any) => page.documentId === documentId && page.pageNum === pageNum);
    if ( !foundedPage || !foundedPage.bboxes || !foundedPage.bboxes.length ) return null;
    return foundedPage.bboxes;
  }
);

export const selectIsPageAdditional = createSelector(
  [
    selectBatchesEntities,
    selectDocumentsEntities,
    (_, props:{ documentId:number }) => props
  ],
  ( batchesEntities:Record<string, IBatch>, documentsEntities:Record<number, IDocument>, { documentId } ) => {
    const documentEntity = documentsEntities[documentId];
    if ( !documentEntity ) return false;
    const batchEntity = batchesEntities[documentEntity.batchId];
    return batchEntity?.additional || false;
  }
);

// Default
export const selectDocumentPagesIds = createSelector(
  [
    selectPagesIds,
    selectPagesEntities,
    (_, props:{ documentId:number }) => props
  ],
  ( ids:string[] | null, entities:IEntity, { documentId } ) => {
    if ( !ids ) return null;

    const pages = Object.values(entities[documentId]) as IPage[];
    const stapleEntities = pages.reduce((acc:Record<string, number>, cur:IPage) => {
      if ( cur.staple && cur.staple.order === 1 ){
        acc[cur.staple.id] = cur.pageNum;
      }
      return acc;
    }, {});

    return pages
      .map((page:IPage) => {
        if ( page.staple ){
          const primaryPageNum = stapleEntities[page.staple.id];
          if ( primaryPageNum ){
            return { ...page, staple:{ ...page.staple, primaryPageNum }};
          }
        }
        return page;
      })
      .sort((a:IPage, b:IPage) => {
        let aPageNum = a.staple ? a.staple.primaryPageNum : a.pageNum;
        let bPageNum = b.staple ? b.staple.primaryPageNum : b.pageNum;
        if ( a.staple && b.staple && a.staple.id === b.staple.id ){
          aPageNum = a.staple.order;
          bPageNum = b.staple.order;
        }
        return aPageNum - bPageNum;
      })
      .map((page:IPage) => PageService.toPageId(page.documentId, page.pageNum))
  }
);

export const selectWorkspacePagesIds = createSelector(
  [
    selectPagesIds,
    selectPagesEntities
  ],
  ( ids:string[] | null, entities:IEntity ) => {
    if ( !ids ) return null;
    return ids.filter((pageId:string) => {
      const [ documentId, pageNum ] = PageService.fromPageId(pageId);
      const page:any = entities[documentId][pageNum];
      return page.workspaceOrder;
    });
  }
);

// ------------------------
// Document
// ------------------------
export const selectFilteredDocumentPagesIds = createSelector(
  [
    selectDuplicatesEntities,
    selectDocumentPagesIds,
    selectWorkspacePagesIds,
    selectPagesEntities,
    selectPagesFilter
  ],
  ( duplicatesEntities:Record<string, string[]>, ids:string[] | null, workspacePagesIds:string[] | null, entities:IEntity, filter:any ) => {
    if ( !ids ) return null;

    const { tags, colors, haveDuplicateInWorkspace } = filter;

    return ids
      .filter((id:string) => {
        const [ documentId, pageNum ] = PageService.fromPageId(id);
        const page = entities[documentId][pageNum] as IPage;

        const pageColors = page.colors || ['noColor'];
        const pageTags = page.tag || ['noTag'];

        const hasColors = colors.length ? colors.some((color:string) => pageColors.includes((color as any))) : true;
        const hasTags = tags.length ? tags.some((tag:string) => pageTags.includes(tag)) : true;

        return hasColors && hasTags;
      })
      .filter((id:string) => {
        if ( !haveDuplicateInWorkspace ) return true;
        if ( !workspacePagesIds || !Object.keys(duplicatesEntities).length ) return true;

        const selectedCluster = duplicatesEntities[id] || null;
        const hasDuplicateInWorksapace =
          selectedCluster
          ? selectedCluster.some((id:string) => workspacePagesIds.includes(id))
          : false
        ;

        return hasDuplicateInWorksapace;
      });
  }
)

// ------------------------
// Staple
// ------------------------

// ------------------------
// Workspace
// ------------------------
export const selectFilteredWorkspacePagesIds = createSelector(
  [
    selectEpisodesByPageId,
    selectWorkspacePagesIds,
    selectPagesEntities,
    selectPagesFilter
  ],
  (episodesByPageId:any, ids:string[] | null, entities:IEntity, filter:any) => {
    if ( !ids ) return null;

    const { tags, colors, episodeTypes, episodeLabels, episodeAuthors } = filter;

    return ids
      .filter((id:string) => {
        const [ documentId, pageNum ] = PageService.fromPageId(id);
        const page = entities[documentId][pageNum] as any;

        const pageColors = page.colors || ['noColor'];
        const pageTags = page.tag || ['noTag'];

        const hasColors = colors.length ? colors.some((color:string) => pageColors.includes((color as any))) : true;
        const hasTags = tags.length ? tags.some((tag:string) => pageTags.includes(tag)) : true;

        return hasColors && hasTags;
      })
      .filter((id:string) => {
        let hasEpisodeTypes = episodeTypes.length === 0;
        let hasEpisodeLabels = episodeLabels.length === 0;
        let hasEpisodeAuthors = episodeAuthors.length === 0;

        const pageEpisodes = episodesByPageId[id];

        if (!pageEpisodes || !pageEpisodes.length) return hasEpisodeTypes && hasEpisodeLabels && hasEpisodeAuthors;

        for ( const episode of pageEpisodes ) {
          if (hasEpisodeTypes && hasEpisodeLabels && hasEpisodeAuthors) break;

          if ( !hasEpisodeTypes ){
            if ( !episode.type ) continue;
            if ( episodeTypes.includes(episode.type) ){
              hasEpisodeTypes = true;
            }
          }

          if ( !hasEpisodeLabels ){
            if ( !episode.labels ) continue;
            for (const label of episodeLabels ) {
              if (episode.labels.includes(label)) {
                hasEpisodeLabels = true;
              }
            }
          }

          if ( !hasEpisodeAuthors ){
            if ( !episode.author ) continue;
            if ( episodeAuthors.includes(episode.author.id.toString()) ){
              hasEpisodeAuthors = true;
            }
          }
        }
        
        return hasEpisodeTypes && hasEpisodeLabels && hasEpisodeAuthors;
      })
  }
);

export const selectFilteredWorkspacePrimaryPagesIds = createSelector(
  [
    selectFilteredWorkspacePagesIds,
    selectPagesEntities
  ],
  (ids:string[] | null, entities:IEntity) => {
    if ( !ids ) return null;
    return ids.filter((id:string) => {
      const [ documentId, pageNum ] = PageService.fromPageId(id)
      const page = entities[documentId][pageNum];
      return !page.staple || page.staple.order === 1;
    });
  }
);

export const selectFilteredWorkspacePrimaryPagesIdsByPreEvent = createSelector(
  [
    selectFilteredWorkspacePrimaryPagesIds,
    selectPagesEntities,
    selectEpisodesEntities
  ],
  (ids:string[] | null, entities:IEntity, episodesEntities:any) => {
    if ( !ids ) return null;

    let result:string[] = [];

    const { idsPreEvent, idsPostEvent } = ids.reduce((acc:any, cur:string) => {
      const [ documentId, pageNum ] = PageService.fromPageId(cur);
      const page = entities[documentId][pageNum];
      if ( !page.parentEpisodeId ){
        acc.idsPostEvent.push(cur)
      } else {
        const episode = episodesEntities[page.parentEpisodeId];
        if ( episode && episode.preEvent ){
          acc.idsPreEvent.push(cur);
        } else {
          acc.idsPostEvent.push(cur);
        }
      }
      return acc;
    }, {
      idsPreEvent: [],
      idsPostEvent: []
    });

    if ( idsPreEvent.length ){
      result = ['pre-event', ...idsPreEvent];
    }
    if ( idsPostEvent.length ){
      result = [...result, 'post-event', ...idsPostEvent]
    }

    return result;
  }
);

export const selectFilteredWorkspaceTotalPagesIds = createSelector(
  [
    selectFilteredWorkspacePagesIds,
    selectFilteredWorkspacePrimaryPagesIds
  ],
  (ids:string[] | null, primaryIds:string[] | null) => {
    if ( !ids || !primaryIds ) return null;
    return { total: ids.length, totalPrimary: primaryIds.length };
  }
);
































// export const selectStaplePages = createSelector(
//   [
//     selectPagesEntities,
//     (_, props:{ documentId:string | number, stapleId:string | null }) => props
//   ],
//   (entities:any, { documentId, stapleId }) => {
//     if ( !stapleId ) return null;

//     const pageEntities = entities[documentId];

//     return Object.keys(pageEntities)
//       .filter((pageNum:string | number) => {
//         const page:IPage = pageEntities[pageNum];
//         if ( !page.staple ) return false;
//         return page.staple.id === stapleId;
//       })
//       .map((pageNum:string | number) => pageEntities[pageNum])
//   }
// )


// Used on presenter page dialog
export const selectStaplePageIds = createSelector(
  [
    selectPagesEntities,
    (_, props:{ documentId:number, stapleId:string | null }) => props
  ],
  (entities:any, { documentId, stapleId }) => {
    if ( !stapleId ) return null;

    const documentPageEntities:any = entities[documentId];

    return Object.keys(documentPageEntities).filter((key:string) => {
      const page = documentPageEntities[key];
      if ( !page.staple ) return false;
      return page.staple.id === stapleId
    }).map((key:string) => `${documentId}:${key}`);
  }
);

// Used in old code
export const selectPrimaryStaplePage = createSelector(
  [
    selectPagesEntities,
    (_, props:{ documentId:number, pageNum:number }) => props
  ],
  (entities:any, { documentId, pageNum }) => {
    if ( !documentId && !pageNum ) return null;

    const documentPageEntities = entities[documentId];
    const page = documentPageEntities[pageNum];

    if ( !page.staple ) return null;
    if ( page.staple.order === 1 ) return page;

    const stapleId = page.staple.id;
    const [ primaryStaplePageNum ] = Object.keys(documentPageEntities).filter((key:string) => {
      const page = documentPageEntities[key];
      if ( !page.staple ) return false;
      return page.staple.id === stapleId && page.staple.order === 1
    });
    return documentPageEntities[primaryStaplePageNum];
  }
)

// Used in old code
export const selectPageEpisodes = createSelector(
  [
    selectEpisodesByPageId,
    selectStaplePageIds,
    (_, props:{ documentId:number, pageNum:number, stapleId:string | null }) => props
  ],
  (episodesByPageId:any, ids:string[] | null, { documentId, pageNum, stapleId }) => {
    const pageId = `${documentId}:${pageNum}`;
    if ( !stapleId ) return episodesByPageId[pageId];
    if ( !ids ) return null;
    return ids.reduce((acc:IEpisode[], cur:string) => {
      if ( episodesByPageId[cur] ){
        acc = [...acc, ...episodesByPageId[cur]];
      }
      return acc;
    }, []);
  }
);
