import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAccountCasesParams } from 'app/store/Cases/Cases.selectors';
import { selectCollectionsAsOptions } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { FormHelperText, Typography } from '@mui/material';
// Components
import Autocomplete from 'app/components/Mui/AutocompleteV2';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';

type Props = {
  open: boolean;
  onClose: (nextCollectionIds?:string) => void;
}

const FilterPreferencesDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');

  // State
  const collectionOptions = useAppSelector(selectCollectionsAsOptions);
  const params:any = useAppSelector(selectAccountCasesParams);

  const [ collectionIds, setCollectionIds ] = useState(params['sort.priority.collectionIds'].split(',').map(Number) || []);

  const handleClose = () => onClose();

  const handleChange = (nextValue:any) => {
    setCollectionIds(nextValue && nextValue.length ? nextValue : []);
  }

  const handleClick = () => {
    onClose(collectionIds.join(','));
  }

  const actions = (
    <Fragment>
      <Button
        name="Decline check case dialog"
        onClick={handleClose}
      >Cancel</Button>
      <Button
        name="Accept check case dialog"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >Save</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title="Sort preferences"
      actions={actions}
    >
      <OutlineBlock sx={{
        mt: 2
      }} label="Most recent document">
        <FormHelperText>Select document collections to prioritize for sorting. Cases with documents in the selected collections will appear at the top.</FormHelperText>
      </OutlineBlock>
      <Autocomplete
        value={collectionIds}
        options={collectionOptions || []}
        onChange={handleChange}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
            <Typography variant="subtitle1">{option.name}</Typography>
            {option.props.description ? (
              <Typography variant="caption">{option.props.description}</Typography>
            ) : null}
          </li>
        )}
        InputProps={{
          label: t('dialogs.templateForm.documentCollections'),
          name: 'collectionIds',
        }}
        multiple={true}
        filterSelectedOptions
      />
    </Dialog>
  )
}

export default FilterPreferencesDialog;
