import { useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  className?: string;
  id?: string;
  targetId?: string;
  children: React.ReactNode;
};

const Portal:React.FC<Props> = ({
  // Props
  className, id, targetId, children
}) => {
  const element = useMemo(() => {
    const div:HTMLDivElement = document.createElement('div');
    if ( id ) div.id = id;
    div.className = className || 'relative z-40';
    return div;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const target:HTMLElement = targetId ? (document.getElementById(targetId) as HTMLElement) : document.body;
    target.appendChild(element);
    return () => {
      target.removeChild(element);
      console.log(`Portal ${id || 'custom'} is destroyed`);
    }
    // eslint-disable-next-line
  }, [element]);

  return ReactDOM.createPortal(children, element);
};

export default Portal;
