import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import IManagementAccount from 'app/models/ManagementAccount';
// Models
import { RootState } from 'app/store';
// Selectors
import { selectAccounts } from 'app/store/AccountsManagement/AccountsManagement.selectors';
// Utilities
import { isRequired, isDateValid, isStartBeforeEnd } from 'app/utilities/Validations';

export const selectInvoices = (state:RootState) => state[Reducers.BillingInvoices].invoices;
export const selectInvoicesSummary = (state:RootState) => state[Reducers.BillingInvoices].invoicesSummary;
// export const selectInvoicesTotal = (state:RootState) => state[Reducers.BillingInvoices].invoicesTotal;
export const selectParams = (state:RootState) => state[Reducers.BillingInvoices].params;
export const selectHasMore = (state:RootState) => state[Reducers.BillingInvoices].hasMore;
export const selectGettingMore = (state:RootState) => state[Reducers.BillingInvoices].gettingMore;
export const selectBatchInvoicesIds = (state:RootState) => state[Reducers.BillingInvoices].batchInvoicesIds;
export const selectLoading = (state:RootState) => state[Reducers.BillingInvoices].loading;

export const selectTotalSentInvoices = createSelector(
  [ selectInvoices ],
  ( invoices:any[] | null ) => {
    if ( !invoices ) return 0;
    return invoices.reduce((acc:number, cur:any) => {
      if ( cur.status === 'sent' ) acc = acc + 1;
      return acc;
    }, 0)
  }
);

export const selectIsInvoiceIdInBatch = createSelector(
  [
    selectBatchInvoicesIds,
    (_, props:{ invoiceId:number }) => props
  ],
  ( batchInvoicesIds:number[], { invoiceId } ) => batchInvoicesIds.includes(invoiceId)
);

export const selectBatchInvoicesAmount = createSelector(
  [
    selectInvoices,
    selectBatchInvoicesIds
  ],
  ( invoices:any[] | null, batchInvoicesIds:number[] ) => {
    if ( !invoices || !batchInvoicesIds.length ) return null;

    return invoices.reduce((acc:number, cur:any) => {
      if ( batchInvoicesIds.includes(cur.id) ){
        acc = acc + cur.amount;
      }
      return acc;
    }, 0);
  }
);

export const selectAccountFromParams = createSelector(
  [
    selectAccounts,
    selectParams
  ],
  (accounts:IManagementAccount[] | null, params:any) => {
    if ( !accounts || !params.accountId ) return undefined;
    return accounts.find((account:IManagementAccount) => account.id === params.accountId);
  }
);

export const selectStartDateValidationMessage = createSelector(
  [ selectParams ],
  ( params:any ) => {
    const { dateRangeStart, dateRangeEnd } = params;
    if ( !dateRangeStart ) return isRequired;
    const dateValidMessage = isDateValid(dateRangeStart);
    if ( typeof dateValidMessage === 'string' ) return dateValidMessage;
    if ( !isStartBeforeEnd(dateRangeStart, dateRangeEnd).validation ) return isStartBeforeEnd().message;
    return '';
  }
);

export const selectEndDateValidationMessage = createSelector(
  [ selectParams ],
  ( params:any ) => {
    const { dateRangeEnd } = params;
    if ( !dateRangeEnd ) return isRequired;
    const dateValidMessage = isDateValid(dateRangeEnd);
    if ( typeof dateValidMessage === 'string' ) return dateValidMessage;
    return '';
  }
);
