import { FC } from 'react';
// Model
import IEpisodeFact from 'app/models/EpisodeFact';
// Mui
import { Box, Chip } from '@mui/material';

type Props = {
  id: number | string;
  facts: IEpisodeFact[] | undefined;
};

const Facts:FC<Props> = ({
  // Props
  id, facts
}) => {
  if ( !facts || !facts.length ) return null;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      {facts.map((fact:IEpisodeFact, index:number) => (
        <Chip
          key={`${id} fact item ${index}`}
          sx={{
            borderRadius: '4px !important',
            py: '8px !important',
            px: '12px !important',
            '& .MuiChip-label': {
              padding: '0 !important',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px'
            }
          }}
          label={fact.data.hcpcsCode}
        />
      ))}
    </Box>
  )
}

export default Facts;