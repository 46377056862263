import { FC, Fragment } from 'react';
// Models
import { IManagerUser } from 'app/models/User';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { patchUser, unlockUser } from 'app/store/UsersManagement/UsersManagement.async';
// Mui
import { Theme, Paper, Box, Typography } from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
// 
import CompanyInviteDialog from './CompanyInviteDialog';

type Props = {
  user: IManagerUser;
}

const UsersListItem:FC<Props> = ({
  // Props
  user
}) => {
  // Dispatch
  const dispatch = useAppDispatch();

  const { open, toggle } = useToggle();
  const { Menu, MenuItem, openMenu } = useMenu();

  const handleClick = () => {
    dispatch(unlockUser(user.id));
  }

  const handleToggleUserPlatform = () => {
    dispatch(patchUser({ userId: user.id, data: { platform: !user.platform } }));
  }

  const platformLabel = user.platform ? 'non-platform' : 'platform';

  return (
    <Fragment>
      <Paper
        sx={{
          display: 'flex',
          bgcolor: (theme:Theme) => !user.enabled ? theme.palette.grey[100] : 'white',
          p: 4
        }}
        variant="outlined"
      >
        <Box
          sx={{
            flexGrow: 1,
            opacity: !user.enabled ? '0.5': 'initial',
            pr: 2
          }}
        >
          <Typography variant="subtitle1">
            {user.name}
            {user.platform ? ' (Case Chronology)' : null}
          </Typography>
          <InfoBlock label="E-mail" value={user.email} direction="row" />
          {user.account?.company ? (
            <InfoBlock label="Company" value={user.account.company} direction="row" />
          ) : null}
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`User dropdown ${user.id}`}
            aria-label="User item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            {user.platform ? (
              <MenuItem
              name={`${platformLabel} user platform ${user.id}`}
              onClick={toggle}
            >Invite to another company</MenuItem>
            ) : null}
            {user.locked ? (
              <MenuItem
                name={`Unlock user ${user.id}`}
                onClick={handleClick}
              >Unlock</MenuItem>
            ) : null}
            <MenuItem
              name={`Mark as a ${platformLabel} user ${user.id}`}
              onClick={handleToggleUserPlatform}
            >{`Mark as a ${platformLabel} user`}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {open ? (
        <CompanyInviteDialog
          open={open}
          onClose={toggle}
          user={user}
        />
      ) : null}
    </Fragment>
  )
}

export default UsersListItem;
