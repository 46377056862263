// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./UsersManagement.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $patch, $post } from 'app/utilities/HttpClient';

const _url:string = '/management/users';

// Get users
export const getUsers = asyncThunkHandler(
  `${Reducers.UsersManagement}/Get users`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Patch user
export const patchUser = asyncThunkHandler(
  `${Reducers.UsersManagement}/Patch user`,
  async ({ userId, data }:{ userId:number, data:any }, { dispatch, fulfillWithValue }) => {
    await $patch(`${_url}/${userId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return fulfillWithValue({ id:userId, ...data });
  }
);

// Unlock user
export const unlockUser = asyncThunkHandler(
  `${Reducers.UsersManagement}/Unlock user`,
  async (userId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${userId}/actions/unlock`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Unlock
    }));
    return response.json();
  }
);

// Invite user
export const inviteUser = asyncThunkHandler(
  `${Reducers.UsersManagement}/Invite user`,
  async ({ userId, data }:{ userId:number, data:any }, { dispatch }) => {
    const response:Response = await $post(`${_url}/${userId}/actions/invite`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Invite
    }));
    return response.json();
  }
);
