import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import ILabel from 'app/models/Label';
import { IPrompt } from './AIChatPrompts.models';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectPrompts = (state:RootState) => state[Reducers.AIChatPrompts].prompts;
export const selectPrompt = (state:RootState) => state[Reducers.AIChatPrompts].prompt;
export const selectFilter = (state:RootState) => state[Reducers.AIChatPrompts].filter;
export const selectLoading = (state:RootState) => state[Reducers.AIChatPrompts].loading;

export const selectSortedPrompts = createSelector(
  [ selectPrompts ],
  (prompts:IPrompt[] | null) => {
    if ( !prompts ) return null;
    return [...prompts].sort((a, b) => sortByName(a, b, 'title'))
  }
);

export const selectPromptsAsOptions = createSelector(
  [ selectSortedPrompts ],
  ( prompts:IPrompt[] | null ) => {
    if ( !prompts ) return null;
    return prompts.map((aiChatPrompt:IPrompt) => ({
      id: aiChatPrompt.id,
      name: aiChatPrompt.title,
      props: {
        content: aiChatPrompt.content
      }
    }));
  }
);

// Select filtered prompts
export const selectFilteredPrompts = createSelector(
  [
    selectFilter,
    selectSortedPrompts
  ],
  ({ search }, prompts:IPrompt[] | null) => {
    if ( !prompts ) return null;
    if ( !search ) return prompts;
    const searchToLower = search.toLowerCase();
    return prompts.filter((aiChatPrompt:IPrompt) => {
      let hasSearch = aiChatPrompt.title.toLowerCase().includes(searchToLower);
      if ( !hasSearch ){
        const filteredLabels = aiChatPrompt.labels && aiChatPrompt.labels.length
          ? aiChatPrompt.labels.filter((label:ILabel) => label.name.toLowerCase().includes(searchToLower))
          : []
        ;
        hasSearch = Boolean(filteredLabels.length);
      }
      return hasSearch;
    });
  }
);
