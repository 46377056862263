import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import CollectionsList from './CollectionsList';
import CollectionsFab from './CollectionsFab';

const CollectionsPage:FC = () => {
  const { t } = useTranslation('common');

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.adminPages.collectionsPage.collections')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <CollectionsList />
        </Container>
      </Box>
      <CollectionsFab />
    </Fragment>
  )
}

export default CollectionsPage;
