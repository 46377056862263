import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
import UserRoles from "app/types/UserRoles";
// Models
import { RootState } from "app/store";
import { IMyUser } from "app/models/User";
import IPage, { IPresenterPage } from "app/models/Page";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { DMSDocumentPagesActions } from "./DMSDocumentPages.slice";
// Selectors
import { selectMyUser } from "app/store/Users/Users.selectors";
import { selectPageEntity } from './DMSDocumentPages.selectors';
// Messages
import RecordsPagesMessages from "./DMSDocumentPages.messages";
// Utilities
import { $get, $post, $put, $patch, $delete } from 'app/utilities/HttpClient';
// Utilities
import { downloadFile } from "app/utilities/Utilities";

import PageActions from "app/store/page/page.actions";

// ToDO: start
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// End ToDO

const _url:string = '/dms/documents';

// Get document pages
export const getDocumentPages = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Get document pages`,
  async (params:any, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/pages`, params);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);

    const pages:IPage[] = await response.json();

    // ToDO: backward compatibility
    dispatch(PageActions.documentPagesRefetch(pages));
    // End ToDO

    return fulfillWithValue(pages);
  }
);

// Get pages
export const getPages = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Get pages`,
  async (params:any, { signal, rejectWithValue, dispatch, fulfillWithValue }) => {
    const response:Response = await $get(`${_url}/pages`, params, {
      signal
    });
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);

    const pages:IPage[] = await response.json();

    // ToDO: backward compatibility
    dispatch(PageActions.fetchPagesSuccess(pages));
    // End ToDO

    dispatch(DMSDocumentPagesActions.setPages(pages));

    return fulfillWithValue('Pages was fetched');
  }
);

// Get page
export const getPage = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Get page`,
  async ({ documentId, pageNum }:{ documentId:number, pageNum:number }, { getState, rejectWithValue, dispatch, fulfillWithValue }) => {
    const state = getState() as RootState;
    const myUser:IMyUser | null = selectMyUser(state);

    if ( myUser?.role === UserRoles.Client || myUser?.role === UserRoles.Presenter ){
      const page:IPresenterPage = selectPageEntity(state, { documentId, pageNum });
      dispatch(PageActions.fetchPageSuccess(page as any));
      return fulfillWithValue(page);
    }

    const response:Response = await $get(`${_url}/${documentId}/pages/${pageNum}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    return response.json();
  }
);

// Update page
export const updatePage = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Update page`,
  async ({ documentId, pageNum, data, message = RecordsPagesMessages.Update }:{ documentId:number, pageNum:number, data:any, message?:string }, { rejectWithValue, dispatch }) => {
    const response:Response = await $put(`${_url}/${documentId}/pages/${pageNum}`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(AppUiNotificationsActions.addSnackbar({ message }));
    return response.json();
  }
);

// Patch page
export const patchPage = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Patch page`,
  async ({ documentId, pageNum, data, message = RecordsPagesMessages.Update }:{ documentId:number, pageNum:number, data:any, message?:string }, { rejectWithValue, dispatch }) => {
    const response:Response = await $patch(`${_url}/${documentId}/pages/${pageNum}`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(AppUiNotificationsActions.addSnackbar({ message }));
    return response.json();
  }
);

// Patch pages
export const patchPages = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Patch pages`,
  async ({ data, message = RecordsPagesMessages.Update }:{ data:any, message?:string }, { rejectWithValue, dispatch }) => {
    const response:Response = await $patch(`${_url}/pages`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(AppUiNotificationsActions.addSnackbar({ message }));
    return response.json();
  }
);

// Delete page
export const deletePage = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Delete page`,
  async ({ documentId, pageNum }:{ documentId:number, pageNum:number }, { rejectWithValue, dispatch }) => {
    const response:Response = await $delete(`${_url}/${documentId}/pages/${pageNum}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: RecordsPagesMessages.Delete
    }));
    return response.json();
  }
);

// Rotate page
export const rotatePage = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Rotate page`,
  async ({ documentId, pageNum, data }:{ documentId:number, pageNum:number, data:any }, { rejectWithValue, dispatch }) => {
    const response:Response = await $post(`${_url}/${documentId}/pages/${pageNum}`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: RecordsPagesMessages.Rotate
    }));
    return response.json();
  }
);

// Get search pages
export const getSearchPages = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Get search pages`,
  async (data:any, { rejectWithValue, fulfillWithValue }) => {
    const response:Response = await $post(`${_url}/search`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);

    const pages = await response.json();

    Mixpanel.track(MixpanelTracks.CaseSearchExecute, {
      caseId: Number(data.insuranceCaseId),
      searchResultSize: pages.length
    });

    return fulfillWithValue(pages);
  }
);

export const exportPagesToPDF = createAsyncThunk(
  `${Reducers.DMSDocumentPages}/Export pages to pdf`,
  async ({ pages }:{ pages:{ documentId:number; pageNum:number }[] }, { rejectWithValue, fulfillWithValue }) => {
    const response:Response = await $post(`${_url}/pages/actions/export`, pages);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);

    downloadFile(response, { name: `export-pages-${new Date()}`, ext: 'pdf' });

    return fulfillWithValue('Pages to pdf exported');
  }
);
