import { FC, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { IMessage } from 'app/models/ChatAI';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectConversationMessagesArray, selectLoadingMessage } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import MessageItem from './MessageItem';
import MessageSkeleton from './MessageSkeleton';

const MessageList:FC = () => {
  const { t } = useTranslation('common');
  // State
  const messagesArray = useAppSelector(selectConversationMessagesArray);
  const loadingMessage = useAppSelector(selectLoadingMessage);

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });

  useLayoutEffect(() => {
    scrollToBottom()
    // eslint-disable-next-line
  }, [messagesArray]);

  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
      {!messagesArray ? (
        <Loader />
      ) : !messagesArray.length ? (
        <Message text={t('pages.staffPages.caseDetailsPage.noMessages')} />
      ) : (
        <Box sx={{
          width: '100%',
          maxWidth: 920,
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 4,
          p: 4
        }}>
          {messagesArray.map((messages:IMessage[], index:number) => (
            <MessageItem
              key={`message item ${index}`}
              messages={messages}
            />
          ))}
          {loadingMessage ? <MessageSkeleton relative={true} /> : null}
          <div ref={messagesEndRef} />
        </Box>
      )}
    </Box>
  )
}

export default MessageList;
