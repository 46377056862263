import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IState, IDocument } from "./DMSDocuments.models";
// Async
import { getDocuments, getDocument, createDocument, updateDocument, patchDocument, deleteDocument, processDocument, downloadDocument } from './DMSDocuments.async';
// Services
import PageService from "app/services/PageService";

const initialState:IState = {
  document: null,
  documents: {
    ids: null,
    entities: {},
    dates: {},
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.DMSDocuments,
  initialState,
  reducers: {
    setDocuments: (state, action:PayloadAction<IDocument[]>) => {
      const entities = action.payload.reduce((acc:Record<number, IDocument>, cur:any) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      const ids = Object.keys(entities).map(Number);
      state.documents.ids = state.documents.ids ? [...state.documents.ids, ...ids] : ids;
      state.documents.entities = {...state.documents.entities, ...entities};
    },

    setDocumentUpdate: (state, action:PayloadAction<IDocument>) => {
      state.documents.entities[action.payload.id] = action.payload;
    },

    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get documents
    builder.addCase(getDocuments.pending, (state) => {
      state.documents.ids = initialState.documents['ids'];
      state.documents.entities = initialState.documents['entities'];
      state.documents.dates = initialState.documents['dates'];
    });
    builder.addCase(getDocuments.fulfilled, (state, action:PayloadAction<IDocument[]>) => {
      const entities:Record<number, IDocument> = {};
      let dates:Record<string, string[]> = {};

      for ( let document of action.payload ){
        entities[document.id] = document;

        if ( !document.attributes || !document.attributes.length ) continue;

        dates = _generateDocumentsDates(dates, document);
      }

      state.documents.ids = Object.keys(entities).map(Number);
      state.documents.entities = entities;
      state.documents.dates = dates;
    });
    // Get document
    builder.addCase(getDocument.pending, (state) => {
      state.document = null;
    });
    builder.addCase(getDocument.fulfilled, (state, action:PayloadAction<IDocument>) => {
      state.document = action.payload;
    });
    // Create document
    builder.addCase(createDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDocument.fulfilled, (state, action:PayloadAction<IDocument>) => {
      const document = action.payload;
      if ( state.documents.ids ){
        state.documents.ids = [...state.documents.ids, document.id];
      }
      state.documents.entities[document.id] = document;
      state.documents.dates = _generateDocumentsDates(state.documents.dates, document);
    });
    // Update document
    builder.addCase(updateDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDocument.fulfilled, (state, action:PayloadAction<IDocument>) => {
      const document = action.payload;
      state.documents.entities[document.id] = document;
      if ( state.document && state.document.id === document.id ){
        state.document = {...state.document, ...document};
      }
    });
    // Patch document
    builder.addCase(patchDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchDocument.fulfilled, (state, action:PayloadAction<any>) => {
      const { id, ...otherData } = action.payload;
      const documentEntity = state.documents.entities[id];
      state.documents.entities[id] = {...documentEntity, ...otherData};
      if ( state.document && state.document.id === id ){
        state.document = {...state.document, ...otherData };
      }
    });
    // Delete document
    builder.addCase(deleteDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDocument.fulfilled, (state, action:PayloadAction<IDocument>) => {
      const document = action.payload;
      state.documents.entities[document.id] = document;
      if ( state.document && state.document.id === document.id ){
        state.document = document;
      }
    });
    // Process document
    builder.addCase(processDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(processDocument.fulfilled, (state, action:PayloadAction<IDocument>) => {
      state.documents.entities[action.payload.id] = action.payload;
    });
    // Download document
    builder.addCase(downloadDocument.pending, (state) => {
      state.loading = true;
    });
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  }
});

export const DMSDocumentsActions = slice.actions;

export default slice.reducer;

const _generateDocumentsDates = (dates:Record<string, string[]>, document:IDocument) => {
  if ( document.attributes && document.attributes.length ){
    for ( let attribute of document.attributes ){
      const { attribute:{ value }, pages } = attribute;
      for ( let page of pages ){
        const pageId = PageService.toPageId(document.id, page);
        const date = dates[pageId];
        if ( !date ){
          dates[pageId] = [value];
        } else {
          if ( !date.includes(value) ){
            dates[pageId] = [...date, value]
          }
        }
      }
    }
  }
  return dates;
}
