import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.terms.create'),
  Update: i18n.t('notifications.terms.update'),
  Delete: i18n.t('notifications.terms.delete'),
  Publish: i18n.t('notifications.terms.publish'),
  Archive: i18n.t('notifications.terms.archive')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.terms.create');
  Messages.Update = i18n.t('notifications.terms.update');
  Messages.Delete = i18n.t('notifications.terms.delete');
  Messages.Publish = i18n.t('notifications.terms.publish');
  Messages.Archive = i18n.t('notifications.terms.archive');
});