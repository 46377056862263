import { FC, Fragment, useState, useEffect } from 'react';
import dayjs from 'dayjs';
// 
import FullChart from './FullChart';
import DailyChart from './DailyChart';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import { useParams } from 'react-router-dom';
import CaseChronologyLogo from './CaseChronologyLogo';

let timeout:any = null;

const Timeline:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();

  const [ logoImageData, setLogoImageData ] = useState<string | undefined>(undefined);
  const [ logoImageDataWithEpisodeDate, setLogoImageDataWithEpisodeDate ] = useState<string | undefined>(undefined);

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseTimelineView, { caseId: Number(caseId) });

    return () => {
      if ( timeout ) clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, []);

  const handleDownloadJPEG = (chartImageURL:string, showEpisodeDate:boolean = false) => {
    if ( timeout ) clearTimeout(timeout);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if ( ctx ){
      const chartImagePlaceholder = new Image();
      chartImagePlaceholder.onload = () => {
        const { width, height } = chartImagePlaceholder;

        canvas.width = width;
        canvas.height = height + 136;

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height + 136);

        const imageUrl = showEpisodeDate ? logoImageDataWithEpisodeDate : logoImageData;

        if ( imageUrl ){
          const logoImage = new Image();
          logoImage.onload = () => {
            ctx.drawImage(logoImage, 0, 0)
          }
          logoImage.src = imageUrl;
        }

        const chartImage = new Image();
        chartImage.onload = () => {
          ctx.drawImage(chartImage, 0, 136, width, height);
        }
        chartImage.src = chartImageURL;

        timeout = setTimeout(() => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/jpeg', 1.0);
          a.download = `chart-${dayjs().format('YYYYMMDD')}.jpeg`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          Mixpanel.track(MixpanelTracks.CaseTimelineExported, { caseId: Number(caseId) });
        }, 1000);
      }
      chartImagePlaceholder.src = chartImageURL;
    }
  }

  return (
    <Fragment>
      <CaseChronologyLogo onImageDataGenerate={setLogoImageData} />
      <CaseChronologyLogo showEpisodeDate={true} onImageDataGenerate={setLogoImageDataWithEpisodeDate} />
      <FullChart onDownloadJPEG={handleDownloadJPEG} />
      <DailyChart onDownloadJPEG={handleDownloadJPEG} />
    </Fragment>
  )
}

export default Timeline;
