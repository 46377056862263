import React, { Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Models
import { IMyUser } from 'app/models/User';
import IConversation from 'app/models/Conversation';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createConversation, updateConversation } from 'app/store/Conversations/Conversations.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectLoading } from 'app/store/Conversations/Conversations.selectors';
// Mui
import { FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired, isMaxLength } from 'app/utilities/Validations';
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  conversation?: IConversation;
};

const ConversationFormDialog:React.FC<Props> = ({
  // Props
  open, onClose, conversation
}) => {
  const { t } = useTranslation('common');

  const label = conversation?.id ? t('labels.update') : t('labels.create');

  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState:{ errors }, watch } = useForm<IFormData>({
    defaultValues: {
      name: conversation?.name || ''
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    if ( conversation?.id ){
      asyncUpdateConversation({
        conversationId: conversation.id,
        data
      });
    } else {
      asyncCreateConversation({
        ...data,
        context: { insuranceCaseId: Number(caseId) },
        me: { name: myUser?.name }
      });
    }
  });

  const asyncCreateConversation = async (data:any) => {
    try {
      await dispatch(createConversation(data)).unwrap();
      onClose();
    } catch(err){}
  }

  const asyncUpdateConversation = async (data:any) => {
    try {
      await dispatch(updateConversation(data)).unwrap();
      onClose();
    } catch(err){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel conversation dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name={`${label} conversation dialog`}
        loading={loading}
        type="submit"
        variant="contained"
        color="primary"
      >{label}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={`${label} ${t('labels.conversation')}`}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      {/* Name */}
      <Controller
        control={control} name="name"
        rules={{
          required: isRequired,
          maxLength: isMaxLength(100)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.name')}
            required
            error={Boolean(errors.name)}
            helperText={errors.name?.message || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watch('name').length} / 100`}</FormHelperText>
              )
            }}
          />
        )}
      />
    </Dialog>
  )
}

export default ConversationFormDialog;
