import { createSelector } from '@reduxjs/toolkit';
// Models
import { RootState } from 'app/store';

export const selectStats = (state:RootState) => state.analytics.stats;
export const selectMonthlyStats = (state:RootState) => state.analytics.monthlyStats;

export const selectMonthlyStatsTotal = createSelector(
  [ selectMonthlyStats ],
  (monthlyStats:any) => {
    if ( !monthlyStats ) return null;
    return monthlyStats.reduce((acc:any, cur:any) => {
      if ( cur.numberOfInsuranceCasesCreated ){
        acc.numberOfInsuranceCases += cur.numberOfInsuranceCasesCreated;
      }
      if ( cur.numberOfDocumentPagesImported ){
        acc.numberOfDocumentPages += cur.numberOfDocumentPagesImported;
      }
      return acc;
    }, {
      numberOfInsuranceCases: 0,
      numberOfDocumentPages: 0
    })
  }
);

export const selectMonthlyStatsForChart = createSelector(
  [
    selectMonthlyStats,
    (_, props:{ legendText:string, color:string, field:'numberOfDocumentPagesImported' | 'numberOfInsuranceCasesCreated' }) => props
  ],
  (monthlyStats:any, { legendText, color, field }) => {
    const data:any = {
      type: 'column',
      showInLegend: true,
      legendText,
      dataPoints: [{ x: new Date(), y: 0 }],
      visible: true,
      color
    };
    if ( !monthlyStats || monthlyStats.length === 0 ) return data;

    data['dataPoints'] = [];

    for ( let stat of monthlyStats ){
      let [ year, month, day ] = stat.month.split('-');
      data.dataPoints.push({
        x: new Date(year, month - 1, day),
        y: stat[field]
      });
    }
    return data;
  }
);
