import React, { useEffect, useMemo } from 'react';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
// Types
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage } from '@root/models/Page';
import IAnnotation from 'app/models/Annotation';
// Actions
import AppActions from 'app/store/TODO2_app/app.actions';
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectHighlightModeEnabled } from 'app/store/TODO2_app/app.selectors';
import { selectPage, selectIsLoading, selectStapleById, selectStatus } from 'app/store/page/page.selectors';
// MaterialUi
import { Box, Tooltip } from '@mui/material';
// Icons
import {
  RotateRight as RotateRightIcon,
  RotateLeft as RotateLeftIcon
} from '@mui/icons-material';
// ToDO
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Components
import Icon from 'app/components/Icon';
import PageImage from '@root/components/PageImage';
import PageImageHighlights from 'app/components/PageImageHighlights';

import { Loader } from 'app/components/Utilities';
//
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import { IconButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { generateWorkspaceOrder } from 'app/store/page/page.service';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { RootState } from 'app/store';

type Props = {
  page: IPage;
  mainStaplePageEpisode?: any;
}

const CardPageTabsImageItem:React.FC<Props> = ({
  // Props
  page, mainStaplePageEpisode
}) => {
  const { t } = useTranslation('common');

  const { documentId, pageNum } = page;

  const manualRotationValue = useMemo(() => {
    if ( page.annotations && page.annotations.length ){
      const manualRotation = page.annotations.find((annotation:IAnnotation) => annotation.name === 'manualRotation');
      if ( manualRotation && manualRotation.value ){
        return Number(manualRotation.value);
      }
    }
    return 0;
    // eslint-disable-next-line
  }, [page.annotations]);

  const stapleId = page.staple && page.staple.order > 1 ? page.staple.id : null;

  // Dispatch
  const dispatch = useAppDispatch();
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;

  const { isArchived } = useCaseStatus();

  // State
  const trialMode = useAppSelector(selectTrialMode);
  const highlightModeEnabled = useAppSelector(selectHighlightModeEnabled);
  const currentPage:IPage | null = useAppSelector(selectPage);
  const isLoading:boolean = useAppSelector(selectIsLoading);
  const staplePages:Array<IPage> | null = useAppSelector((state:RootState) => selectStapleById(state, { stapleId }));
  const status:any = useAppSelector(selectStatus);

  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);
  const [ loading, setLoading ] = React.useState(false);

  useEffect(() => {
    if ( status !== 'Initial' ){
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [status]);

  const handleToggleHighlightMode = () => {
    dispatch(AppActions.toggleHighlightMode());
  }

  const handleRotatePage = (isRight:boolean) => async () => {
    setLoading(true);

    const rotationValue = manualRotationValue === 0 && !isRight ? 360 : manualRotationValue;
    const rotation = (rotationValue-(isRight ? -90 : 90))%360;

    dispatch(PageActions.rotatePage(page.documentId, page.pageNum, {
      version: page.version,
      rotation
    }));

    Mixpanel.track(MixpanelTracks.CasePageRotate, {
      caseId: insuranceCase.id,
      documentId,
      pageNum,
      rotationDegrees: rotation,
    });
  }

  const handleConfirm = () => {
    const mainStaplePage = staplePages ? staplePages[0] : null;
    const removeParentEpisodeId = mainStaplePageEpisode && mainStaplePageEpisode.pageNum === page.pageNum;

    if ( staplePages && staplePages.length === 2 ){
      const pagesToUpdate = staplePages.map((page:IPage, index) => {
        const pageData:any = {
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          staple: null
        }
        if ( removeParentEpisodeId && mainStaplePage?.pageNum === page.pageNum ){
          pageData['parentEpisodeId'] = null;
          pageData['workspaceOrder'] = generateWorkspaceOrder(null, {
            workspaceOrder: page.workspaceOrder,
            date: undefined,
            time: undefined,
            deleted: true,
            index
          })
        }
        if ( mainStaplePage?.pageNum !== page.pageNum ){
          pageData['workspaceOrder'] = generateWorkspaceOrder(null, {
            workspaceOrder: page.workspaceOrder,
            date: undefined,
            time: undefined,
            deleted: true,
            index
          })
        }
        return pageData;
      });
      dispatch(PageActions.patchPages(pagesToUpdate, pageMessages.removeStaple));
    } else {
      if ( removeParentEpisodeId && mainStaplePage ){
        dispatch(PageActions.patchPages([
          {
            documentId: page.documentId,
            pageNum: page.pageNum,
            version: page.version,
            staple: null,
            workspaceOrder: generateWorkspaceOrder(null, {
              workspaceOrder: mainStaplePage.workspaceOrder,
              date: undefined,
              time: undefined,
              deleted: true,
              index: 2
            })
          },{
            documentId: mainStaplePage.documentId,
            pageNum: mainStaplePage.pageNum,
            version: mainStaplePage.version,
            parentEpisodeId: null,
            workspaceOrder: generateWorkspaceOrder(null, {
              workspaceOrder: mainStaplePage.workspaceOrder,
              date: undefined,
              time: undefined,
              deleted: true,
              index: 1
            })
          }
        ], pageMessages.removePageFromStaple));
      } else {
        dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
          version: page.version,
          staple: null,
          workspaceOrder: generateWorkspaceOrder(null, {
            workspaceOrder: page.workspaceOrder,
            date: undefined,
            time: undefined,
            deleted: true,
            index: 1
          })
        }, pageMessages.removePageFromStaple));
      }
    }
  }

  const handleUnstaplePage = () => {
    if ( page.workspaceOrder ){
      setDialogOpen(true);
    } else {
      handleConfirm();
    }
  }

  const isDisabled = trialMode || isArchived;

  useEffect(() => {
    if (highlightModeEnabled && isDisabled) handleToggleHighlightMode();
    // eslint-disable-next-line
  }, [isDisabled, highlightModeEnabled]);

  return (
    <React.Fragment>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        mt: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: '4px 4px 0 0',
      }}>
        <span className="cp-pageNum">{page.pageNum}</span>
        {!trialMode && currentPage && (!currentPage.staple || currentPage.staple.order === 1) ? (
          <div style={{
            display: 'flex',
            padding: '4px',
            zIndex: 2,
            gap: '8px',
            borderRadius: '4px'
          }}>
            <Tooltip title={highlightModeEnabled ? t('dialogs.cardPage.disableHighlightMode') : t('dialogs.cardPage.enableHighlightMode')}>
              <span>
                <IconButton
                  name={highlightModeEnabled ? 'Disable highlight mode' : 'Enable highlight mode'}
                  disabled={isDisabled}
                  onClick={handleToggleHighlightMode}
                  color={highlightModeEnabled ? 'primary' : 'default'}
                  size="small"
                ><Icon icon="edit" /></IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('dialogs.cardPage.rotateClockwise')}>
              <span>
                <IconButton
                  name="Rotate right page"
                  disabled={isLoading || isDisabled}
                  onClick={handleRotatePage(true)}
                  size="small"
                ><RotateRightIcon /></IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('dialogs.cardPage.rotateCounterclockwise')}>
              <span>
                <IconButton
                  name="Rotate left page"
                  disabled={isLoading || isDisabled}
                  onClick={handleRotatePage(false)}
                  size="small"
                ><RotateLeftIcon /></IconButton>
              </span>
            </Tooltip>
            {page.staple && page.staple.order > 1 ? (
              <Tooltip title={t('dialogs.cardPage.unstaplePage')}>
                <span>
                  <IconButton
                    name="Unstaple page"
                    disabled={isDisabled}
                    onClick={handleUnstaplePage}
                    size="small"
                  ><Icon icon="staple_delete" /></IconButton>
                </span>
              </Tooltip>
            ) : null}
          </div>
        ) : null}
      </Box>
      <Box sx={{
        width: '100%',
        position: 'relative',
        border: '1px solid rgba(0,0,0,0.08)',
        borderRadius: '0 0 4px 4px',
        pt: '141.4%', // Proporional of a4 paper is 1:1.414
        overflow: 'hidden'
      }} react-page-num-dialog={page.pageNum}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0, left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(255,255,255,0.75)',
              zIndex: 99
            }}
          ><Loader /></Box>
        ) : null}
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            top: 0, left: 0,
            width: '100%',
            height: '100%',
            background: '#eee'
          }}>
            <Box sx={{ position: 'relative' }}>
              <PageImage
                documentId={page.documentId}
                pageNum={page.pageNum}
                showPageNum={false}
              />
              {!trialMode && currentPage && (!currentPage.staple || currentPage.staple.order === 1) ? (
                <PageImageHighlights
                  documentId={page.documentId}
                  pageNum={page.pageNum}
                  version={page.version}
                  highlights={page.highlights}
                  onLoading={() => setLoading(true)}
                />
                // <PageImageHighlight
                //   documentId={page.documentId}
                //   pageNum={page.pageNum}
                //   version={page.version}
                //   highlights={page.highlights}

                //   onSetLoading={() => setLoading(true)}
                // />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      {dialogOpen ? (
        <ConfirmationDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          title={t('dialogs.cardPage.keepInWorkspace')}
          content={t('dialogs.cardPage.keepInWorkspaceConfirmation')}
          loading={isLoading}
          confirmLabel="Yes"
          status={status}
          onUnconfirm={() => {
            const mainStaplePage = staplePages ? staplePages[0] : null;
            const removeParentEpisodeId = mainStaplePageEpisode && mainStaplePageEpisode.pageNum === page.pageNum;

            if ( staplePages && staplePages.length === 2 ){
              const pagesToUpdate = staplePages.map((page:IPage) => ({
                documentId: page.documentId,
                pageNum: page.pageNum,
                version: page.version,
                staple: null,
                inWorkspace: mainStaplePage?.pageNum === page.pageNum ? page.inWorkspace : false,
                workspaceOrder: mainStaplePage?.pageNum === page.pageNum ? page.workspaceOrder : null,
                parentEpisodeId: removeParentEpisodeId && mainStaplePage && mainStaplePage.pageNum === page.pageNum
                  ? null
                  : page.parentEpisodeId
              }));
              dispatch(PageActions.patchPages(pagesToUpdate, pageMessages.removeStaple));
            } else {
              if ( removeParentEpisodeId && mainStaplePage ){
                dispatch(PageActions.patchPages([
                  {
                    documentId: page.documentId,
                    pageNum: page.pageNum,
                    version: page.version,
                    staple: null,
                    inWorkspace: false,
                    workspaceOrder: null
                  },{
                    documentId: mainStaplePage.documentId,
                    pageNum: mainStaplePage.pageNum,
                    version: mainStaplePage.version,
                    parentEpisodeId: null,
                    workspaceOrder: generateWorkspaceOrder(null, {
                      workspaceOrder: mainStaplePage.workspaceOrder,
                      date: undefined,
                      time: undefined
                    })
                  }
                ], pageMessages.removePageFromStaple));
              } else {
                dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
                  version: page.version,
                  staple: null,
                  inWorkspace: false,
                  workspaceOrder: null
                }, pageMessages.removePageFromStaple));
              }
            }
          }}
          onConfirm={handleConfirm}
        />
      ) : null}
    </React.Fragment>
  )
}

export default CardPageTabsImageItem;
