import { createSelector } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
// Selectors
import { selectPreferences, selectSelectedDate, selectFilter } from 'app/store/currentUser/currentUserSelectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// ToDO: Utilities
import { getVisitColor } from 'utilities/utilities';

export const selectInsuranceCases = (state:RootState) => state[Reducers.Presenter].insuranceCases;
export const selectPresenterFilter = (state:RootState) => state[Reducers.Presenter].filter;
export const selectPresenterSettings = (state:RootState) => state[Reducers.Presenter].settings;
export const selectLoading = (state:RootState) => state[Reducers.Presenter].loading;

export const selectFilteredInsuranceCases = createSelector(
  [
    selectInsuranceCases,
    selectPresenterFilter
  ],
  (insuranceCases:any, { search }) => {
    if ( !insuranceCases ) return null;
    if ( !search ) return insuranceCases;
    const searchToLowerCase = search.toLowerCase();
    return insuranceCases.filter((insuranceCase:any) => {
      return insuranceCase.name.toLowerCase().includes(searchToLowerCase);
    });
  }
);

export const selectPresenterTags = createSelector(
  [ selectPresenterSettings ],
  (settings) => settings.pageTags || []
);

















// import { getParsedLegendsStorage } from 'new_design/store/legend/legend.service';
const STORAGE_KEY = 'chart_legend_settings';

export const getParsedLegendsStorage = ():any => {
  const storage = localStorage.getItem(STORAGE_KEY);
  return storage ? JSON.parse(storage) : {};
};

export enum LegendLabels {
  Unassigned = 'Unassigned',
  EventDate = 'Event date',
  ExaminationDate = 'Examination date',
  TrialDate = 'Trial date'
};

export const selectEpisodes = createSelector(
  [
    (state:any) => state.presentation.episodes,
  ],
  (episodes:any[] | null) => {
    if ( !episodes ) return null;
    return episodes.filter((episode:any) => {
      if ( !episode.showInTimeline ) return false;
      if ( !episode.date ) return false;
      return episode;
    });
  }
);

export const selectFilteredEpisodes = createSelector(
  [
    (state:any) => selectEpisodes(state),
    (state:any) => selectFilter(state)
  ],
  (episodes:any[] | null, { labels }:any) => {
    if ( !episodes ) return null;
    return episodes.filter((episode:any) => {
      if ( labels.length === 0 ) return true;
      if ( !episode.labels ) return false;
      return labels.some((label:string) => episode.labels.includes(label));
    })
  }
);

export const selectEpisodeLabels = createSelector(
  [
    (state:any) => selectEpisodes(state)
  ],
  (episodes:any[] | null) => {
    if ( !episodes ) return [];
    const result:string[] = [];
    for ( let episode of episodes ){
      if ( episode.labels ){
        for ( let label of episode.labels ){
          if ( !result.includes(label) ) result.push(label);
        }
      }
    }
    return result.sort();
  }
);

export const selectEpisodeLegends = createSelector(
  [
    (state:any) => state.presentation.insuranceCase,
    (state:any) => selectEpisodes(state),
    (state:any) => selectPreferences(state)
  ],
  (insuranceCase:any, episodes:any[] | null, preferences:any) => {
    const episodeTypes = episodes ? episodes.reduce((acc:any, cur:any) => {
      const type = cur.type || LegendLabels.Unassigned;
      return acc.includes(type) ? acc : [...acc, type];
    }, []).sort() : [];

    // Episode legends
    const episodeLegends = episodeTypes.reduce((acc:any, key:string) => {
      acc[key] = { key: key, label: key, color: getColorByLabelKey(key), visible: true }
      return acc;
    }, {});
    // Get storage legends
    const storageLegends = insuranceCase ? getParsedLegendsStorage()[insuranceCase.id] : {};
    // DB legends
    const dbLegends = preferences && preferences.value ? JSON.parse(preferences.value) : {};

    if ( insuranceCase ){
      if ( insuranceCase.eventDate ) episodeLegends[LegendLabels.EventDate] = {
        key: LegendLabels.EventDate,
        label: LegendLabels.EventDate,
        color: getColorByLabelKey(LegendLabels.EventDate),
        visible: true
      }
      if ( insuranceCase.examinationDate ) episodeLegends[LegendLabels.ExaminationDate] = {
        key: LegendLabels.ExaminationDate,
        label: LegendLabels.ExaminationDate,
        color: getColorByLabelKey(LegendLabels.ExaminationDate),
        visible: true
      }
      if ( insuranceCase.trialDate ) episodeLegends[LegendLabels.TrialDate] = {
        key: LegendLabels.TrialDate,
        label: LegendLabels.TrialDate,
        color: getColorByLabelKey(LegendLabels.TrialDate),
        visible: true
      }
    }

    const result:any = {};

    for ( let key in episodeLegends ){
      result[key] = episodeLegends[key];
      if ( storageLegends && storageLegends[key] ) result[key] = storageLegends[key];
      if ( dbLegends[key] ) result[key] = dbLegends[key];
    }

    return result;
  }
);

export const selectCalendarData = createSelector(
  [
    (state:any) => state.presentation.insuranceCase,
    (state:any) => selectFilteredEpisodes(state),
    (state:any) => selectEpisodeLegends(state)
  ],
  (insuranceCase:any, episodes:any[] | null, legends:any) => {
    const result:any = {};
    if ( insuranceCase ){
      if ( insuranceCase.eventDate ) result[insuranceCase.eventDate] = [{
        date: insuranceCase.eventDate,
        legend: legends[LegendLabels.EventDate]
      }]
      if ( insuranceCase.examinationDate ) result[insuranceCase.examinationDate] = [{
        date: insuranceCase.examinationDate,
        legend: legends[LegendLabels.ExaminationDate]
      }]
      if ( insuranceCase.trialDate ) result[insuranceCase.trialDate] = [{
        date: insuranceCase.trialDate,
        legend: legends[LegendLabels.TrialDate]
      }]
    }
    if ( !episodes ) return result;
    for ( let episode of episodes ){
      const nextEpisode = {...episode, legend: episode.type ? legends[episode.type] : legends[LegendLabels.Unassigned] };

      if ( !nextEpisode.legend.visible ) continue;

      if ( result[nextEpisode.date] ){
        result[nextEpisode.date] = [...result[nextEpisode.date], nextEpisode]
      } else {
        result[nextEpisode.date] = [nextEpisode]
      }
    }
    return result;
  }
);

export const selectCalendarDataByDateString = createSelector(
  [
    (state:any) => selectCalendarData(state),
    (_:any, props:{ dateString:string }) => props
  ],
  (calendarData:any, { dateString }) => calendarData[dateString]
);

export const selectSortedCalendarDates = createSelector(
  [
    selectInsuranceCase,
    (state:any) => selectEpisodes(state),
  ],
  (insuranceCase:any, episodes:any[] | null) => {
    const result:string[] = [];
    if ( insuranceCase ){
      if ( insuranceCase.eventDate ) result.push(insuranceCase.eventDate);
      if ( insuranceCase.examinationDate ) result.push(insuranceCase.examinationDate);
      if ( insuranceCase.trialDate ) result.push(insuranceCase.trialDate);
    }
    if ( !episodes ) return result;
    for ( let episode of episodes ){
      if ( !result.includes(episode.date) ) result.push(episode.date)
    }
    return result.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }
);

export const selectFullChartData = createSelector(
  [
    (state:any) => state.presentation.insuranceCase,
    (state:any) => selectFilter(state),
    (state:any) => selectFilteredEpisodes(state),
    (state:any) => selectEpisodeLegends(state),
    (state:any) => selectSortedCalendarDates(state)
  ],
  (insuranceCase:any, { showAll }:any, episodes:any[] | null, legends:any, calendarDates:string[]) => {
    const eventsByTypeKey:any = {};
    const eventsPerDate:any = {};

    if ( insuranceCase ){
      if ( insuranceCase.eventDate ){
        eventsByTypeKey[LegendLabels.EventDate] = {
          [insuranceCase.eventDate]: {
            events: [{
              key: LegendLabels.EventDate,
              date: insuranceCase.eventDate,
              time: insuranceCase.eventTime || '',
              legend: legends[LegendLabels.EventDate]
            }]
          }
        };
      }
      if ( insuranceCase.examinationDate ){
        eventsByTypeKey[LegendLabels.ExaminationDate] = {
          [insuranceCase.examinationDate]: {
            events: [{
              key: LegendLabels.ExaminationDate,
              date: insuranceCase.examinationDate,
              time: '',
              legend: legends[LegendLabels.ExaminationDate]
            }]
          }
        }
      }
      if ( insuranceCase.trialDate ){
        eventsByTypeKey[LegendLabels.TrialDate] = {
          [insuranceCase.trialDate]: {
            events: [{
              key: LegendLabels.TrialDate,
              date: insuranceCase.trialDate,
              time: '',
              legend: legends[LegendLabels.TrialDate]
            }]
          }
        }
      }
    }

    if ( episodes ){
      for ( let episode of episodes ){
        const type = episode.type ? legends[episode.type].key : legends[LegendLabels.Unassigned].key;
        const hasEventType = eventsByTypeKey[type];
        if ( !hasEventType || !hasEventType[episode.date] ){
          if ( !hasEventType ) eventsByTypeKey[type] = {};
          eventsByTypeKey[type][episode.date] = {
            events: []
          }
        }
        eventsByTypeKey[type][episode.date] = {
          events: [
            ...eventsByTypeKey[type][episode.date]['events'],
            { ...episode, legend: episode.type ? legends[episode.type] : legends[LegendLabels.Unassigned] }
          ]
        };
        eventsPerDate[episode.date] = !eventsPerDate[episode.date] ? 1 : eventsPerDate[episode.date] + 1;
      }
    }

    const maxEventsPerDate = Object.keys(eventsPerDate).reduce((acc:number, cur:string) => {
      return eventsPerDate[cur] > acc ? eventsPerDate[cur] : acc;
    }, 0);

    if ( insuranceCase && insuranceCase.id ){
      eventsByTypeKey[LegendLabels.EventDate][insuranceCase.eventDate] = {
        ...eventsByTypeKey[LegendLabels.EventDate][insuranceCase.eventDate],
        y: maxEventsPerDate + 1
      }
    }

    const viewportMinimum = dayjs(calendarDates[0]).subtract(6, 'month').format('YYYY-MM-DD');
    const viewportMaximum = dayjs(calendarDates[calendarDates.length - 1]).add(6, 'month').format('YYYY-MM-DD');

    const result:any = [];

    for ( let key in eventsByTypeKey ){
      const dataPoints:any = [];
      const legend = legends[key];

      if ( !legend.visible ) continue;

      for ( let date in eventsByTypeKey[key] ){
        const [ year, month, day ] = date.split('-');
        const events = eventsByTypeKey[key][date]['events'];
        dataPoints.push({
          x: new Date(Number(year), Number(month) - 1, Number(day)),
          y: eventsByTypeKey[key][date]['y'] || events.length,
          events
        });
      }
      result.push({
        key,
        type: 'stackedColumn',
        name: key === LegendLabels.EventDate ? `  ${legend.label}` : legend.label,
        color: legend.color,
        visible: typeof showAll !== 'undefined'
          ? showAll
          : key === LegendLabels.EventDate
        ,
        dataPoints,
        showInLegend: true
      });
    }

    return {
      fullChartData: result.sort((a:any, b:any) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        return aName === bName ? 0 : aName < bName ? -1 : 1
      }),
      initialViewportMinimum: new Date(viewportMinimum),
      initialViewportMaximum: new Date(viewportMaximum)
    };
  }
);

export const selectDailyChartData = createSelector(
  [
    (state:any) => state.presentation.insuranceCase,
    (state:any) => selectSelectedDate(state),
    (state:any) => selectCalendarData(state)
  ],
  (insuranceCase:any, selectedDate:Dayjs | null, calendaData:any) => {
    const selectedDateToString = selectedDate ? selectedDate.format('MM/DD/YYYY') : '';

    const episodes = selectedDateToString
      ? calendaData[selectedDateToString]
      : insuranceCase && insuranceCase.eventDate
        ? calendaData[insuranceCase.eventDate]
        : []
    ;

    const result:any = [{
      type: 'stackedColumn',
      dataPoints: [
        { x: new Date(), y: 0 }
      ]
    }];

    if ( episodes && episodes.length ){
      const dataPoints:any = [];
      for ( let episode of episodes ){
        if ( !episode.legend.visible ) continue;

        const [ year, month, day ] = episode.date.split('-').map(Number);
        const [ hours, minutes ] = episode.time ? episode.time.split(':').map(Number) : [];
  
        dataPoints.push({
          x: episode.time
            ? new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes))
            : new Date(Number(year), Number(month) - 1, Number(day), 0, 0)
          ,
          y: 1,
          color: episode.legend.color,
          events: [episode]
        });
      }
      result[0]['dataPoints'] = dataPoints;
    }

    return result;
  }
)


const getColorByLabelKey = (key:string):string => {
  if ( key === LegendLabels.EventDate ) return '#000000';
  if ( key === LegendLabels.ExaminationDate ) return '#28a745';
  if ( key === LegendLabels.TrialDate ) return '#6f42c1';
  return getVisitColor(key);
}
