// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './ReportSections.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/reportSections';

// Get report sections
export const getReportSections = asyncThunkHandler(
  `${Reducers.ReportSections}/Get report sections`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Get report section
export const getReportSection = asyncThunkHandler(
  `${Reducers.ReportSections}/Get report section`,
  async (reportSectionId:number) => {
    const response:Response = await $get(`${_url}/${reportSectionId}`);
    return response.json();
  }
);

// Create report section
export const createReportSection = asyncThunkHandler(
  `${Reducers.ReportSections}/Create report section`,
  async (data:any, { dispatch }) => {
    const { accountId, ...otherData } = data;
    const response:Response = await $post(_url, otherData);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }))
    return response.json();
  }
);

// Update report section
export const updateReportSection = asyncThunkHandler(
  `${Reducers.ReportSections}/Update report section`,
  async ({ reportSectionId, data }:{ reportSectionId:number, data:any }, { dispatch }) => {
    const { overrideChanges, ...otherData } = data;

    // ToDO
    if ( overrideChanges ){
      const response:Response = await $get(`${_url}/${reportSectionId}`);
      const reportSection = await response.json();
      otherData['version'] = reportSection.version;
    }

    const response:Response = await $put(`${_url}/${reportSectionId}`, otherData);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }))
    return response.json();
  }
);

// Delete report section
export const deleteReportSection = asyncThunkHandler(
  `${Reducers.ReportSections}/Delete report section`,
  async (reportSectionId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${reportSectionId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }))
    return fulfillWithValue(reportSectionId)
  }
);
