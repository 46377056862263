import config from '../../config';

import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
// Models
import { RootState } from 'app/store';
import IAnnotation from 'app/models/Annotation';
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPageEntity } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';

type Props = {
  documentId: number;
  pageNum: number;
  showPageNum?: boolean;
}

const PageImage:FC<Props> = ({
  // Props
  documentId, pageNum, showPageNum = false
}) => {
  const { insuranceCaseId, caseId } = useParams<{ insuranceCaseId:string, caseId:string }>();
  // State
  const page:IPage | IPresenterPage = useAppSelector((state:RootState) => selectPageEntity(state, { documentId, pageNum }))

  const alt = `${insuranceCaseId || caseId}_${documentId}_${pageNum}.png`;
  let src = `${config.cdnUrl}/pages/${alt}`;

  // Get rotations
  if ( page && page.annotations && page.annotations.length ){
    const manualRotation = page.annotations.find((annotation:IAnnotation) => annotation.name === 'manualRotation');
    if ( manualRotation && manualRotation.value ) src += `?rotation=${manualRotation.value}`;
  }

  return (
    <div className="relative">
      {showPageNum ? (
        <span className="cp-pageNum">{pageNum}</span>
      ) : null}
      <img style={{ maxWidth: '100%' }} src={src} alt={alt} loading="lazy" />
    </div>
  )
}

export default memo(PageImage);