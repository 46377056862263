import { Action } from 'redux';

// State
export interface IAppState {
  pagesInRow: string;
  requestedUrl: null | string;
}

// Types
export enum AppTypes {
  SetRequestedUrl = '@@app/Set requested url',
}
interface SetRequestedUrl extends Action<typeof AppTypes.SetRequestedUrl> {
  payload: { requestedUrl:string }
}

export type AppActionTypes =
  SetRequestedUrl
;
