import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { setUser } from '@sentry/react';
// Types
import UserRoles from 'app/types/UserRoles';
import BodyErrors from "app/types/BodyErrors";
// Models
import { AppDispatch } from 'app/store';
import { IMyUser } from 'app/models/User';
// Async
import { logout } from 'app/store/Auth/Auth.async';
import { resetPassword, getMyUser } from 'app/store/Users/Users.async';
import { getCurrentAccount, getCurrentAccountBilling } from 'app/store/Accounts/Accounts.async';
import { getUnseenConversations } from "app/store/Conversations/Conversations.async";
import { getMedicareCarriers } from "app/store/Medical/Medical.async";
import { getVariables } from "app/store/Templates/Templates.async";
import { getSettings } from "app/store/Presenter/Presenter.async";
import { getIntegrations } from 'app/store/Integrations/Integrations.async';
import { getCollections } from 'app/store/DMSCollections/DMSCollections.async';
import { getGeneralSettings } from 'app/store/Settings/Settings.async';
// Actions
import { AuthActions } from 'app/store/Auth/Auth.slice';
// Services
import Mixpanel from "app/services/Mixpanel.service";

const UsersMiddleware:Middleware = ({ dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => async (action:any) => {
  const { type, payload } = action;

  if ( type === resetPassword.rejected.type ){
    const { error } = payload;
    if ( error === BodyErrors.AccountRequired ){
      const { accounts, message } = payload;
      dispatch(AuthActions.setMultiAccountsData({ accounts, message }));
    }
  }

  if ( type === getMyUser.fulfilled.type ){
    const myUser = payload as IMyUser;

    const isMyUserRoleAdmin = myUser.role === UserRoles.Admin;
    const isMyUserRoleStaff = myUser.role === UserRoles.Staff;
    const isMyUserRolePresenter = myUser.role === UserRoles.Presenter;
    const isMyUserRoleAM = myUser.role === UserRoles.AccountManager;

    Mixpanel.identify(myUser.id);

    if ( isMyUserRoleAdmin || isMyUserRoleStaff || isMyUserRoleAM ){
      dispatch(getIntegrations({ service: 'quickBooks' }));
    }

    if ( isMyUserRoleAdmin || isMyUserRoleStaff ){
      dispatch(getGeneralSettings({}));
      dispatch(getCurrentAccount({}));

      if ( isMyUserRoleAdmin ){
        dispatch(getCurrentAccountBilling({}));
      }

      dispatch(getUnseenConversations({}));
      dispatch(getMedicareCarriers({}));
      dispatch(getVariables({}));
      dispatch(getCollections({}));
    }

    if ( isMyUserRolePresenter ){
      dispatch(getSettings({}));
    }

    dispatch(AuthActions.setAuthenticated(true));
    dispatch(AuthActions.setInitialField('multiFactory'));
    dispatch(AuthActions.setInitialField('multiAccounts'));

    // Set user for sentry
    setUser({ id: myUser.id });
  }
  if ( type === getMyUser.rejected.type ){
    dispatch(logout());
  }

  return next(action);
}

export default UsersMiddleware;