import { RefObject, FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selectors
import { selectCasesList, selectCasesParams, selectCasesHasMore, selectCasesGettingMore } from 'app/store/Cases/Cases.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Hooks
import useIntersectionObserver from 'app/hooks/useIntersectionObserver';
// 
import CasesListItem from './CasesListItem';
import CasesNoData from './CasesNoData';

const CasesList:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCases:IInsuranceCase[] | null = useAppSelector(selectCasesList);
  const params:any = useAppSelector(selectCasesParams);
  const hasMore:boolean = useAppSelector(selectCasesHasMore);
  const gettingMore:boolean = useAppSelector(selectCasesGettingMore);

  const [ targetRef, isIntersecting ] = useIntersectionObserver({});

  useEffect(() => {
    if ( !isIntersecting ) return;
    if ( !hasMore ) return;

    const offset = params.offset + params.limit;

    dispatch(CasesActions.setParams({ ...params, offset }));
    // eslint-disable-next-line
  }, [isIntersecting]);

  if ( !insuranceCases ) return <Loader />;
  if ( params.search && !insuranceCases.length ) return <Message text={t('pages.staffPages.casesPage.noCasesFound')} />;
  if ( !insuranceCases.length ) return <CasesNoData />;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {insuranceCases.map((insuranceCase:IInsuranceCase, index:number) => (
          <Fragment key={`case item ${insuranceCase.id}`}>
            <CasesListItem insuranceCase={insuranceCase} />
            {index === insuranceCases.length - 1 ? (
              <span ref={targetRef as RefObject<HTMLSpanElement>} />
            ) : null}
          </Fragment>
        ))}
      </Box>
      {gettingMore ? <Loader /> : null}
    </Fragment>
  )
}

export default CasesList;
