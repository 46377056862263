import Cookie from 'js-cookie';
// Services
import LocalStorageService from './LocalStorage.service';

export default class CookieService {
  private static readonly TOKEN:string = 'mdc-sso-token';
  private static readonly ACCOUNT_ID:string = 'mdc-sso-account-id';

  public static getToken():string {
    return Cookie.get(this.TOKEN) || '';
  }

  public static setCredential(clientAccountId:number):void {
    Cookie.set(this.TOKEN, LocalStorageService.getAccessToken(), {
      domain: this._getCookieDomain()
    });
    Cookie.set(this.ACCOUNT_ID, clientAccountId.toString(), {
      domain: this._getCookieDomain()
    });
  }

  public static clearCookie():void {
    const config = { domain: this._getCookieDomain() };
    Cookie.remove(this.TOKEN, config);
  }

  private static _getCookieDomain():string {
    const { hostname } = window.location;
    return `.${hostname.includes('localhost') ? 'localhost' : this._getTDL(hostname)}`;
  }

  private static _getTDL(hostname:string):string {
    const domainParts = hostname.split('.');
    if ( domainParts.length >= 2 ){
      return domainParts[domainParts.length - 2] + '.' + domainParts[domainParts.length - 1];
    } else {
      return domainParts[0];
    }
  }
}