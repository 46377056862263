import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.invoice.create'),
  Update: i18n.t('notifications.invoice.update'),
  Delete: i18n.t('notifications.invoice.delete'),
  Pay: i18n.t('notifications.invoice.pay'),
  Send: i18n.t('notifications.invoice.send'),
  Push: i18n.t('notifications.invoice.push')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.invoice.create');
  Messages.Update = i18n.t('notifications.invoice.update');
  Messages.Delete = i18n.t('notifications.invoice.delete');
  Messages.Pay = i18n.t('notifications.invoice.pay');
  Messages.Send = i18n.t('notifications.invoice.send');
  Messages.Push = i18n.t('notifications.invoice.push');
})
