import React from 'react';
import { useParams } from 'react-router-dom';
// Models
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
import { IPageDuplicate } from '@root/models/Page';
// Selectors
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';

import { selectDuplicatesList, selectLoading } from 'app/store/Cases/Cases.selectors';
// MaterialUI
import Tooltip from '@mui/material/Tooltip';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Components
import PageImage from '@root/components/PageImage';
import Icon from 'app/components/Icon';

import { updateDuplicates } from 'app/store/Cases/Cases.async';
import { IconButton } from 'app/components/Mui';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';

import MixpanelTracks from 'app/types/MixpanelTracks';
import Mixpanel from 'app/services/Mixpanel.service';
// i18next
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store';

type Props = {
  documentId: number;
  pageNum: number;
  isOriginal?: boolean;
};

const PageCompareDuplicatesItem:React.FC<Props> = ({
  // Props
  documentId, pageNum, isOriginal
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const documentEntity = useAppSelector((state:RootState) => selectDocumentEntity(state, { documentId })) as IDocument;
  const duplicates = useAppSelector(selectDuplicatesList);
  const isLoading:boolean = useAppSelector(selectLoading);

  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);

  const handleConfirm = async () => {
    if ( !duplicates ) return;

    // const duplicatesToUpdate = duplicates.map((cluster:Array<IPageDuplicate>) =>
    //   cluster.filter((page:IPageDuplicate) => {
    //     if ( page.documentId === documentId && page.pageNum === pageNum ) return false;
    //     return true;
    //   })
    // ).filter((cluster:Array<IPageDuplicate>) => cluster.length > 1);

    const duplicatesToUpdate:IPageDuplicate[][] = [];

    const notDuplicatePageIds:string[] = [];
    const duplicatedPageIds:string[] = [];

    for ( let cluster of Object.values(duplicates) ){
      const nextCluster = [];
      for ( let page of cluster ){
        if (
          page.documentId === documentId &&
          page.pageNum === pageNum
        ){
          notDuplicatePageIds.push(`${documentId}:${pageNum}`);
          continue;
        } else {
          duplicatedPageIds.push(`${page.documentId}:${page.pageNum}`);
          nextCluster.push(page);
        }
      }
      if ( nextCluster.length > 1 ){
        duplicatesToUpdate.push(nextCluster);
      }
    }

    await dispatch(updateDuplicates({
      insuranceCaseId: Number(caseId),
      data: duplicatesToUpdate,
      message: t('dialogs.pageCompare.pageWasDeletedFromDuplicates')
    })).unwrap();

    Mixpanel.track(MixpanelTracks.PageMarkedNotADUplicate, {
      caseId: Number(caseId),
      // Deleted
      notDuplicatePageIds,
      // Remaining
      duplicatedPageIds
    });
  }

  return (
    <React.Fragment>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0'
      }}>
        <div className="spacer pr-2">
          <span style={{
            display: 'block',
            color: 'rgba(0,0,0,0.87)',
            fontWeight: 500,
            marginBottom: '4px'
          }}>{documentEntity.name}</span>
          {isOriginal ? (
            <span style={{
              display: 'flex',
              alignItems: 'center'
            }}>{t('dialogs.pageCompare.original')}</span>
          ) : (
            <span style={{
              display: 'flex',
              alignItems: 'center'
            }}>&nbsp;</span>
          )}
        </div>
        {isOriginal ? (
          <div>
            <Tooltip title={t('dialogs.pageCompare.notDuplicate')}>
              <IconButton
                name="Not a duplicate"
                onClick={toggleDialog}
                size="small"
              ><Icon icon="stapled" /></IconButton>
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div style={{
        border: '1px solid rgba(0,0,0,0.08)',
        borderRadius: '4px',
        marginBottom: '8px',
        overflow: 'hidden'
      }}>
        <PageImage
          documentId={documentId}
          pageNum={pageNum}
        />
      </div>
      {dialogOpen ? (
        <ConfirmationDialog
          open={dialogOpen}
          onClose={toggleDialog}
          status={'initial'}
          loading={isLoading}
          title={t('dialogs.pageCompare.notDuplicate')}
          content={t('dialogs.pageCompare.notDuplicateConfirmation')}
          onConfirm={handleConfirm}
        />
      ) : null}
    </React.Fragment>
  )
}

export default PageCompareDuplicatesItem;
