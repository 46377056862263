import React from 'react';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectPage, selectPageStaples } from 'app/store/page/page.selectors';
// Components
import PagePdf from '@root/components/PagePdf';

type Props = {};

const CardPageTabsPdf:React.FC<Props> = () => {
  // State
  const page:IPage | null = useSelector(selectPage);
  const staplePages:Array<IPage> | null = useSelector(selectPageStaples);

  if ( !page ) return null;
  return (
    <div>
      {staplePages ? (
        staplePages.map((p:IPage) => (
          <div key={`card-page-tabs-pdf-item-${p.documentId}-${p.pageNum}`} style={{
            position: 'relative',
            border: '1px solid rgba(0,0,0,0.08)',
            borderRadius: '4px',
            marginTop: '16px',
            overflow: 'hidden'
          }}>
            <span className="cp-pageNum cp-pageNum-pdf">{p.pageNum}</span>
            <PagePdf
              documentId={p.documentId}
              pageNum={p.pageNum}
              annotations={p.annotations}
            />
          </div>
        ))
      ) : (
        <div style={{
          position: 'relative',
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: '4px',
          marginTop: '16px',
          overflow: 'hidden'
        }}>
          <PagePdf
            documentId={page.documentId}
            pageNum={page.pageNum}
            annotations={page.annotations}
          />
        </div>
      )}
    </div>
  )
}

export default CardPageTabsPdf;
