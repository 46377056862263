import { FC } from 'react';
// Models
import { IMyUser } from 'app/models/User';
import IMessage from 'app/models/Message';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Utilities
import { parseDateFromUtcToLocal } from '_utilities/datetime.utility';

type Props = {
  item: IMessage;
}

const MessagesListItem:FC<Props> = ({
  // Props
  item
}) => {
  const myUser:IMyUser | null = useAppSelector(selectMyUser);

  const classList = ['message-item'];
  const ownMessage = item.user.id === `cases.mdc.com:${myUser?.id}`;
  if ( ownMessage ) classList.push('own');
  if ( item.user.id.indexOf('cases') !== -1 ){
    classList.push('cases');
  } else {
    classList.push('clients');
  }
  return (
    <li className={classList.join(' ')}>
      {!ownMessage ? <span className="username">{item.user.name}</span> : null}
      <div className="message-item-content">
        <span className="text">{item.text}</span>
        <span className="date">{parseDateFromUtcToLocal(item.createdOn)}</span>
      </div>
    </li>
  )
}

export default MessagesListItem;
