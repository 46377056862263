import HttpUtility from '_utilities/http.utility';

export default class PageAPI {
  private static _url:string = '/dms/documents';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch pages
  public static async fetchPages(params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      searchParams.append(key, params[key]);
    });
    return PageAPI._http.get(`${PageAPI._url}/pages?${searchParams}`);
  };
  // Fetch page
  public static async fetchPage(documentId:number, pageNum:number):Promise<any> {
    return PageAPI._http.get(`${PageAPI._url}/${documentId}/pages/${pageNum}`);
  };
  // Update page
  public static async updatePage(documentId:number, pageNum:number, data:any):Promise<any> {
    return PageAPI._http.put(`${PageAPI._url}/${documentId}/pages/${pageNum}`, data);
  };
  // Patch page
  public static async patchPage(documentId:number, pageNum:number, data:any):Promise<any> {
    return PageAPI._http.patch(`${PageAPI._url}/${documentId}/pages/${pageNum}`, data);
  };
  // Rotate page
  public static async rotatePage(documentId:number, pageNum:number, data:any):Promise<any> {
    return PageAPI._http.post(`${PageAPI._url}/${documentId}/pages/${pageNum}/actions/rotate`, data);
  }
  // Delete page
  public static async deletePage(documentId:number, pageNum:number):Promise<any> {
    return PageAPI._http.delete(`${PageAPI._url}/${documentId}/pages/${pageNum}`);
  };
  // Patch pages
  public static async patchPages(pages:Array<any>):Promise<any> {
    return PageAPI._http.patch(`${PageAPI._url}/pages`, pages);
  };
  // Export pages
  public static async exportPagesToPDF(pages:Array<{ documentId:number, pageNum:number }>):Promise<any> {
    return PageAPI._http.post(`${PageAPI._url}/pages/actions/export`, pages)
      .then(response => response.blob().then(blob => {
        const filename = response.headers.get('X-Filename');
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = filename ? decodeURIComponent(filename) : 'unknown.pdf';
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }));
  }
}
