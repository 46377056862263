import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Types
import MixpanelTracks from 'app/types/MixpanelTracks';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getReportSections } from 'app/store/ReportSections/ReportSections.async';
// Actions
import { ReportSectionsActions } from 'app/store/ReportSections/ReportSections.slice';
// Serivces
import Mixpanel from 'app/services/Mixpanel.service';
// Mui
import { Box } from '@mui/material';
// 
import ReportSectionsList from './ReportSectionsList';
import ReportSectionsFab from './ReportSectionsFab';

const ReportSectionsPage:FC = () => {
  // Props
  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseReportSectionsView, { id: Number(caseId) });

    dispatch(getReportSections({ insuranceCaseId: Number(caseId) }));

    return () => {
      dispatch(ReportSectionsActions.setInitialField('reportSections'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'auto' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }} react-action="scroll">
        <ReportSectionsList />
        <ReportSectionsFab />
      </Box>
    </Box>
  )
}

export default ReportSectionsPage;
