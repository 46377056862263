import React from 'react';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {};

const CardPageDeletedPlaceholder:React.FC<Props> = () => {
  const { t } = useTranslation('common');

  return (
    <div>
      {t('labels.pageDeleted')}
    </div>
  )
}

export default CardPageDeletedPlaceholder;
