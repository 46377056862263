import { FC, Fragment, useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import IOption from 'app/models/Option';
import IBillingService from 'app/models/BillingService';
import { IBillingPackageItem } from 'app/models/BillingPackage';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectEntities, selectServicesAsOptions } from 'app/store/BillingServices/BillingServices.selectors';
// Mui
import { FormGroup, InputAdornment } from '@mui/material'
// Components
import Dialog from 'app/components/Dialog';
import { Button, Input, Select, Switch } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utility
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  serviceId: number | string;
  usageType: 'metered' | 'licensed';
  price: number;
  recurring: {
    interval: 'day' | 'week' | 'month' | 'year',
    frequency: number;
  };
  numberOfFreeOfChargeUnits: number;
  additional: boolean;
}

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (billingService:IFormData) => void;
  items: IBillingPackageItem[] | null;
  packageItem?: IBillingPackageItem;
}

const PackageItemFormDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm, items, packageItem
}) => {
  const { t } = useTranslation('common');

  const label = packageItem ? t('labels.update') : t('labels.add');
  // State
  const servicesEntities:Record<number, IBillingService> = useAppSelector(selectEntities);
  const serviceOptions:IOption[] | null = useAppSelector(selectServicesAsOptions);

  const [ service, setService ] = useState<IBillingService | null>(null);
  const [ recurring, setRecurring ] = useState(Boolean(packageItem?.recurring) || false);

  const filteredServiceOptions = useMemo(() => {
    if ( packageItem || !items || !serviceOptions ) return serviceOptions;
    const itemsServiceIds = items.map((item:IBillingPackageItem) => item.serviceId);
    return serviceOptions.filter((option:IOption) => !itemsServiceIds.includes(option.id as number));
    // eslint-disable-next-line
  }, []);

  const { control, register, handleSubmit, formState:{ errors }, watch, reset } = useForm<IFormData>({
    defaultValues: {
      serviceId: packageItem?.serviceId || '',
      usageType: packageItem?.usageType || 'metered',
      price: packageItem?.price || 0,
      recurring: {
        interval: packageItem?.recurring?.interval || 'day',
        frequency: packageItem?.recurring?.frequency || 1
      },
      numberOfFreeOfChargeUnits: packageItem?.numberOfFreeOfChargeUnits || 0,
      additional: packageItem?.additional || false,
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    onConfirm(data);
    onClose();
  });

  const watchServiceId = watch('serviceId');
  const watchUsageType = watch('usageType');

  const isUsageTypeMetered = watchUsageType === 'metered';

  useEffect(() => {
    if ( watchServiceId ){
      const serviceId = Number(watchServiceId);
      const serviceEntity = servicesEntities[serviceId];
      if ( !serviceEntity ) return;
      setService(serviceEntity);
    }
    // eslint-disable-next-line
  }, [watchServiceId]);

  useEffect(() => {
    if ( !packageItem && service ){
      reset({
        serviceId: service.id,
        price: 0,
        numberOfFreeOfChargeUnits: 0,
        additional: service.additional,
        usageType: service.unit === 'user' ? 'licensed' : 'metered',
        recurring: {
          interval: 'day',
          frequency: 1
        }
      });
    }
    // eslint-disable-next-line
  }, [service]);

  useEffect(() => {
    if ( isUsageTypeMetered ) setRecurring(false);
    // eslint-disable-next-line
  }, [isUsageTypeMetered]);

  const handleChange = (_:any, checked:boolean) => {
    setRecurring(checked);
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel package service dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <Button
        name={`${label} package service dialog`}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('labels.update')}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={`${label} ${t('pages.accountManagerPages.subscriptionPackagesPage.subscriptionPackageService')}`}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      <Controller
        control={control} name="serviceId"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.service')}
            options={filteredServiceOptions}
            error={Boolean(errors.serviceId)}
            helperText={errors.serviceId?.message || ''}
            required
            disabled={Boolean(packageItem)}
          />
        )}
      />
      <Controller
        control={control} name="usageType"
        render={({ field }) => (
          <Select
            {...field}
            label={t('pages.accountManagerPages.subscriptionPackagesPage.usageType')}
            options={[
              { id: 'metered', name: t('pages.accountManagerPages.subscriptionPackagesPage.metered') },
              { id: 'licensed', name: t('pages.accountManagerPages.subscriptionPackagesPage.licensed') },
            ]}
            required
            disabled={Boolean(packageItem)}
          />
        )}
      />
      {/* Price */}
      <Controller
        control={control} name="price"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.price')} type="number"
            error={Boolean(errors.price)}
            helperText={errors.price?.message || ''}
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
          />
        )}
      />
      {isUsageTypeMetered ? (
        <Controller
          control={control} name="numberOfFreeOfChargeUnits"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('pages.accountManagerPages.subscriptionPackagesPage.numberOfFreeOfChargeUnits')} type="number"
              error={Boolean(errors.numberOfFreeOfChargeUnits)}
              helperText={errors.numberOfFreeOfChargeUnits?.message || ''}
              required
              InputProps={{
                endAdornment: service?.unit === 'minute' ? <InputAdornment position="end">m</InputAdornment> : null
              }}
            />
          )}
        />
      ) : (
        <input {...register('numberOfFreeOfChargeUnits')} type="hidden" />
      )}
      <FormGroup>
        <Controller
          control={control} name="additional"
          render={({ field:{ onChange, ...otherField }}) => (
            <Switch
              {...otherField}
              label={t('labels.additional')}
              onChange={(_, checked:boolean) => onChange(checked)}
            />
          )}
        />
        <Switch
          label={t('pages.accountManagerPages.subscriptionPackagesPage.recurring')}
          name="recurring" value={recurring}
          disabled={isUsageTypeMetered}
          onChange={handleChange}
        />
      </FormGroup>
      {recurring && !isUsageTypeMetered ? (
        <OutlineBlock sx={{ mt: 4, pt: 2 }} label={t('pages.accountManagerPages.subscriptionPackagesPage.recurring')}>
          <Fragment>
            <Controller
              control={control} name="recurring.interval"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('pages.accountManagerPages.subscriptionPackagesPage.interval')}
                  options={[
                    { id: 'day', name: t('labels.day') },
                    { id: 'week', name: t('labels.week') },
                    { id: 'month', name: t('labels.month') },
                    { id: 'year', name: t('labels.year') }
                  ]}
                  required
                />
              )}
            />
            <Controller
              control={control} name="recurring.frequency"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('pages.accountManagerPages.subscriptionPackagesPage.frequency')} type="number"
                  error={Boolean(errors.recurring?.frequency)}
                  helperText={errors.recurring?.frequency?.message || ''}
                  required
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
      ) : null}
    </Dialog>
  )
}

export default PackageItemFormDialog;
