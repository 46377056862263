import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
import { Dayjs } from 'dayjs';
// Models
import IOption from 'app/models/Option';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiActions } from 'app/store/AppUI/AppUI.slice';
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectInvoicesSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import {
  selectParams,
  selectStartDateValidationMessage, selectEndDateValidationMessage
} from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import SidebarToggleButton from 'app/components/SidebarToggleButton';
import { Input, Select, Autocomplete, DesktopDatePicker } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const InvoicesSidebar:FC = () => {
  const { t } = useTranslation('common');

  const width = 400;
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const invoicesSidebarOpen = useAppSelector(selectInvoicesSidebarOpen)
  const accountsAsOptions:IOption[] | null = useAppSelector(selectAccountsAsOptions);
  const params = useAppSelector(selectParams);
  const startDateMessage = useAppSelector(selectStartDateValidationMessage);
  const endDateMessage = useAppSelector(selectEndDateValidationMessage);

  const [ stateParams, setStateParams ] = useState(params);

  const debounceFilter = useCallback(debounce(1000, (field:keyof typeof params, value:any) => {
    dispatch(BillingInvoicesActions.setParams({ [field]: value, offset: 0 }));
    // eslint-disable-next-line
  }), []);

  const handleChangeAccount = (value:any) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      accountId: value
    }));
    debounceFilter('accountId', value);
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateParams((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    debounceFilter((name as any), value);
  }

  const handleChangeStart = (newValue:Dayjs | null) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      dateRangeStart: newValue
    }));
    debounceFilter('dateRangeStart', newValue);
  }

  const handleChangeEnd = (newValue:Dayjs | null) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      dateRangeEnd: newValue
    }));
    debounceFilter('dateRangeEnd', newValue);
  }

  const handleToggleInvoiceSidebar = () => {
    dispatch(AppUiActions.toggleInvoicesSidebarOpen());
  }

  const statusOptions = [
    { id: 'all', name: t('labels.all') },
    { id: 'paid', name: t('labels.paid') },
    { id: 'sent', name: t('labels.outstanding') }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width,
        flexShrink: 0,
        bgcolor: 'white',
        borderRight: '1px solid rgba(0,0,0,0.08)',
        zIndex: 1,
        marginLeft: invoicesSidebarOpen ? 0 : `-${width}px`
      }}
    >
      <Box sx={{
        flexGrow: 1,
        p: 4,
        overflowY: 'auto'
      }}>
        <Autocomplete
          label={t('labels.companyAccount')} value={stateParams.accountId}
          onChange={handleChangeAccount}
          options={accountsAsOptions || []}
          renderOptions={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
              <Typography variant="subtitle1">{option.name}</Typography>
              {option.subname ? (
                <Typography variant="caption">{option.subname}</Typography>
              ) : null}
            </li>
          )}
        />
        <Input
          label={t('labels.search')} name="search" value={stateParams.search}
          placeholder={t('pages.accountManagerPages.billingInvoicesPage.searchPlaceholder')}
          onChange={handleChange}
        />
        <Select
          label={t('labels.status')} name="status" value={stateParams.status}
          onChange={handleChange}
          options={statusOptions}
        />
        <DesktopDatePicker
          label={t('labels.start')} name="dateRangeStart" value={stateParams.dateRangeStart}
          onChange={handleChangeStart}
          inputProps={{
            required: true,
            error: Boolean(startDateMessage),
            helperText: startDateMessage
          }}
        />
        <DesktopDatePicker
          label={t('labels.end')} name="dateRangeEnd" value={stateParams.dateRangeEnd}
          onChange={handleChangeEnd}
          inputProps={{
            required: true,
            error: Boolean(endDateMessage),
            helperText: endDateMessage
          }}
        />
      </Box>

      <SidebarToggleButton
        name="Billing invoices sidebar toggle button"
        open={invoicesSidebarOpen}
        onClick={handleToggleInvoiceSidebar}
      />
    </Box>
  )
}

export default InvoicesSidebar;
