import { FC, Fragment, useEffect } from 'react';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInsuranceCase } from 'app/store/Cases/Cases.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import { Button, Skeleton, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import ClientDetails from 'app/components/ClientDetails';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCaseId: number;
};

const ClientDetailsDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCaseId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);

  useEffect(() => {
    dispatch(getInsuranceCase(insuranceCaseId));
    // eslint-disable-next-line
  }, []);

  const actions = (
    <Button
      onClick={onClose}
    >{t('labels.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('labels.clientDetails')}
      actions={actions}
    >
      {insuranceCase ? (
        <ClientDetails insuranceCase={insuranceCase} />
      ) : (
        <Fragment>
          <Typography component="div" variant="body1">
            <Skeleton width="60%" />
          </Typography>
          <Typography component="div" variant="caption">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="caption">
            <Skeleton />
          </Typography>
        </Fragment>
      )}
    </Dialog>
  )
}

export default ClientDetailsDialog;