export const NO_TAG_VALUE = 'noTag';
export const NO_TAG_LABEL = 'No tag';
export const NO_COLOR_VALUE = 'noColor';

export const CARD_WIDTH = 300;
export const CARD_HEIGHT = 450;

export const SIDEBAR_WIDTH = 400;
export const ADDITIONAL_TOOLS_WIDTH = 320;

export const MAX_FILE_SIZE = 5368709120;
export const MAX_FILE_NAME_LENGTH = 200;

export const SUPPORTED_FILE_FORMATS = ['pdf', 'doc', 'docx', 'png', 'jpeg', 'zip', 'custom'];
export const SUPPORTED_PREVIEW_FILE_FORMATS = ['pdf', 'png', 'jpeg'];

export const SUPPORTED_MIME_TYPES = {
  'pdf': 'application/pdf',
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'png': 'image/png',
  'jpeg': 'image/jpeg',
  'zip': 'application/zip'
};

export const FONT_FAMILIES = ['Arial', 'Calibri', 'Courier New', 'Helvetica', 'Times New Roman']

export const FONT_SIZES = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18,
  20, 22, 24, 26, 28, 36, 48, 72
];

export const TAB_STOP_VALUES = [
  '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7'
];

export const APP_URL_LABELS = {
  'appURL': 'Case portal home',
  'appURL:case': 'Case link',
  'appURL:case:timeline': 'Case timeline link',
  'appURL:page': 'Page link',
  'appURL:episode': 'Episode link'
};

export const LANGUAGES = {
  en: 'English',
  es: 'Español',
  fr: 'Français'
};

export const PUBLIC_URLS = [
  '/login',
  '/forgot-password',
  '/reset-password',
  '/verification'
];




















// -------------------------------------
// ToDO
// -------------------------------------

export const CalendarColors = [
  '#ef5350', // red light,
  '#b71c1c', // red dark
  '#ec407a', // pink light
  '#880e4f', // pink dark
  '#ab47bc', // purple light
  '#4a148c', // purple dark
  '#5c6bc0', // indigo light
  '#1a237e', // indigo dark
  '#42a5f5', // blue light,
  '#007c91', // cyan dark
  '#00766c', // teal dark
  '#087f23', // green dark
  '#524c00', // lime dark
  '#bc5100', // yellow dark
  '#c43e00', // amber dark
  '#ff9800', // orange
  '#c63f17', // deep orange dark
  '#5f4339', // brown,
  '#707070', // grey dark
  '#34515e', // blue grey dark
];
