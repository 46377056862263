import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';
// Models
import IMessage from "app/models/Message";
// Async
import { getMessages, createMessage } from './Messages.async';

interface IState {
  messages: IMessage[] | null;
  loading: boolean;
};

const initialState:IState = {
  messages: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.Messages,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder){
    // Get messages
    builder.addCase(getMessages.pending, (state) => {
      state.messages = null;
    });
    builder.addCase(getMessages.fulfilled, (state, action:PayloadAction<IMessage[]>) => {
      state.messages = action.payload;
    });
    // Create message
    builder.addCase(createMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMessage.fulfilled, (state, action:PayloadAction<IMessage>) => {
      if ( state.messages ){
        state.messages = [...state.messages, action.payload];
      }
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false;
      }
    );
  }
});

export const MessagesActions = slice.actions;

export default slice.reducer;
