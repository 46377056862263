import React, { useEffect } from 'react';
// Models
import { IPage, IPageDuplicate } from '@root/models/Page';
// MaterialUi
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// 
import PageCompareDialogTitle from './PageCompareDialogTitle';
import PageCompareDuplicates from './PageCompareDuplicates';
import PageCompareList from './PageCompareList';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import { useParams } from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
  page?: IPage;
  pageDuplicate?: IPageDuplicate;
};

const PageCompareDialog:React.FC<Props> = ({
  // Props
  open, onClose, page = null, pageDuplicate = null
}) => {
  const { caseId } = useParams<{ caseId:string }>();

  const handleClose = (_:any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if ( reason === 'backdropClick' ) return;
    onClose();
  }

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.PagesCompareView, { caseId: Number(caseId) });
    // eslint-disable-next-line
  }, [])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
    >
      <PageCompareDialogTitle onClose={onClose} />
      <DialogContent dividers>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
          {page && pageDuplicate ? (
            <PageCompareDuplicates page={page} pageDuplicate={pageDuplicate} />
          ) : (
            <React.Fragment>
              <PageCompareList />
            </React.Fragment>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PageCompareDialog;
