import { Reducer } from 'redux';
// Types
import { AppTypes, AppActionTypes } from './app.types';
// Models
import { IState } from './app.models';
// Services
import { generatePageId } from 'app/store/page/page.service';
import AppService from './app.service';

export default class AppReducer {
  private static readonly _initialState:IState = {
    highlightModeEnabled: true,
    lastOpenedWorkspacePages: AppService.getLastOpenedWorkspacePages(),
    lastOpenedFilePages: AppService.getLastOpenedFilePages(),
  };

  public static reducer:Reducer<IState, AppActionTypes> = (
    state = AppReducer._initialState,
    action
  ) => {
    switch (action.type){
      case AppTypes.ToggleHighlightMode:
        return { ...state, highlightModeEnabled: !state.highlightModeEnabled };
      case AppTypes.SetLastOpenedWorkspacePage:
        const lastOpenedWorkspacePages = {
          ...state.lastOpenedWorkspacePages,
          [action.payload.insuranceCaseId]: generatePageId(action.payload.documentId, action.payload.pageNum)
        };
        AppService.setLastOpenedWorkspacePages(lastOpenedWorkspacePages);
        return { ...state, lastOpenedWorkspacePages };
      case AppTypes.SetLastOpenedFilePage:
        const lastOpenedFilePages = {
          ...state.lastOpenedFilePages,
          [action.payload.insuranceCaseId]: generatePageId(action.payload.documentId, action.payload.pageNum)
        };
        AppService.setLastOpenedFilePages(lastOpenedFilePages);
        return { ...state, lastOpenedFilePages };
      default:
        return state;
    }
  }
}
