import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";
// Types
import Reducers from 'app/types/Reducers';

interface IState {
  selectedDate: Dayjs | null,
  filter: {
    episodeLabels: string[];
    inWorkspace: boolean;
    showAll: boolean | null;
  };
};

const initialState:IState = {
  selectedDate: null,
  filter: {
    episodeLabels: [],
    inWorkspace: true,
    showAll: null,
  },
};

const slice = createSlice({
  name: Reducers.Calendar,
  initialState,
  reducers: {
    setSelectedDate: (state, action:PayloadAction<Dayjs | null>) => {
      state.selectedDate = action.payload;
    },
    setFilter: (state, action:PayloadAction<{ field:string, value:string[] | boolean }>) => {
      (state.filter as any)[action.payload.field] = action.payload.value
    }
  }
});

export const { setSelectedDate, setFilter } = slice.actions;

export default slice.reducer;
