import React, { FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IPage } from '@root/models/Page';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectFilteredDocumentPages } from 'app/store/page/page.selectors';
import { selectColumnValue } from 'app/store/ui/uiSelectors';
// Mui
import { Box, Button, TextField, FormHelperText } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const CARD_WIDTH = 300;

type Props = {
  listRef: any;
}

const GoToPage:React.FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation('common');

  const { documentId } = useParams<{ documentId:string }>();

  // Dispatch
  const pages:IPage[] | null = useSelector((state:any) => selectFilteredDocumentPages(state, { documentId: Number(documentId) })) || [];
  // ToDO
  const columnValue:string = useSelector(selectColumnValue);

  const [ goTo, setGoTo ] = React.useState<number | string>('');

  // Reset 'goTo' on change document
  useEffect(() => {
    setGoTo('');
    // eslint-disable-next-line
  }, [documentId])

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setGoTo(value ? Number(value) : '');
  }

  const handleSubmit = (event:FormEvent) => {
    event.preventDefault();

    if ( listRef.current && pages ){
      const pageNum = Number(goTo);
      const foundedPageIndex = pages.findIndex((page:any) => page.pageNum === pageNum);
      if ( typeof foundedPageIndex !== 'undefined' ){
        const width = listRef.current.props.width;
        const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
        const rowIndex = Math.floor(foundedPageIndex/itemsPerRow)
        listRef.current.scrollToItem(rowIndex, 'center');
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
      onSubmit={handleSubmit}
      component="form"
    >
      <TextField
        aria-label="Go to input"
        sx={{
          width: 128
        }}
        value={goTo}
        onChange={handleChange}
        margin="none"
        size="small"
        placeholder="1"
        InputProps={{
          sx: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            pr: 0,
            height: 36.5
          },
          endAdornment: (
            <FormHelperText
              sx={{ p: 0, mt: 0, fontSize: 16, whiteSpace: 'nowrap' }}
            >{`/ ${pages?.length || 0}`}</FormHelperText>
          )
        }}
      />
      <Button
        sx={{
          whiteSpace: 'nowrap',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }}
        type="submit"
        disabled={goTo && (Number(goTo) > 0 && Number(goTo) <= pages.length) ? false : true}
        color="primary"
        variant="contained"
      >{t('labels.goToPage')}</Button>
    </Box>
  )
}

export default GoToPage;
