import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
// Models
import IInsuranceCase from 'app/models/Case';
// Mui
import {
  Divider, Box, Paper,
  Tooltip, Typography
} from '@mui/material';
// Components
import { InfoBlock } from 'app/components/Utilities';
import Labels from 'app/components/Labels';
import Icon from 'app/components/Icon';
// 
import CaseListDropdown from './CasesListDropdown';
import useFormattedDate from 'app/hooks/useFormattedDate';
import useLongDateTme from 'app/hooks/useLongDateTime';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  insuranceCase: IInsuranceCase;
}

const CasesListItem:FC<Props> = ({
  // Props
  insuranceCase
}) => {
  const { t } = useTranslation('common');

  const { url } = useRouteMatch<{ url:string }>();

  const formattedDateOfBirth = useFormattedDate(insuranceCase.claimant?.dateOfBirth);
  const longCreatedOn = useLongDateTme({
    dateValue: insuranceCase.createdOn,
    displayTime: true,
    local: true
  });
  const longExamindationDate = useLongDateTme({
    dateValue: insuranceCase.examinationDate,
    local: true
  });
  const longBatchCreatedOn = useLongDateTme({
    dateValue: insuranceCase.documentUploadedOn,
    displayTime: true,
    local: true
  })

  const status =
    insuranceCase.processing?.status === 'pending'
    ? t('pages.staffPages.casesPage.processing')
    : insuranceCase.processing?.status === 'processing'
      ? insuranceCase.processing?.phase === 'ocr'
        ? t('pages.staffPages.casesPage.processing')
        : t('pages.staffPages.casesPage.finalizing')
      : ''
  ;

  return (
    <Paper sx={{ p: 4 }} variant="outlined">
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4, mb: 2 }}>
        <Typography sx={{
          flexGrow: 1,
          lineHeight: 1.2,
          whiteSpace: 'pre-wrap',
          '& > a': {
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
          }
        }} variant="h5">
          {insuranceCase.status === CaseStatuses.Pending || insuranceCase.status === CaseStatuses.Rejected ? (
            insuranceCase.name
          ) : (
            <Link to={`${url}/${insuranceCase.id}/documents`}>{insuranceCase.name}</Link>
          )}
        </Typography>
        {status ? (
          <Typography sx={{ mt: 1.5 }} variant="body2">{status}</Typography>
        ) : null}
        <CaseListDropdown insuranceCase={insuranceCase} />
      </Box>
      {insuranceCase.rejectComment ? (
        <Typography sx={{ mb: 2 }} variant="body2" color="GrayText">{insuranceCase.rejectComment}</Typography>
      ) : null}
      <Box sx={{ display: 'flex', gap: 4, mb: insuranceCase?.labels?.length ? 4 : 0 }}>
        {formattedDateOfBirth ? (
          <Tooltip title={t('labels.claimantDateOfBirth')}>
            <Box display="flex" alignItems="center">
              <Icon sx={{ mr: 2 }} icon="event" />
              <Typography variant="subtitle2">{t('labels.dob')}&nbsp;{formattedDateOfBirth}</Typography>
            </Box>
          </Tooltip>
        ) : null}
        {insuranceCase.team && insuranceCase.team.name ? (
          <Tooltip title={t('pages.staffPages.casesPage.casePortalTeam')}>
            <Box display="flex" alignItems="center">
              <Icon sx={{ mr: 2 }} icon="team" />
              <Typography variant="subtitle2">{insuranceCase.team.name}</Typography>
            </Box>
          </Tooltip>
        ) : null}
        {insuranceCase.claimNumber ? (
          <Tooltip title={t('labels.claimNumber')}>
            <Box display="flex" alignItems="center">
              <Icon sx={{ mr: 2 }} icon="case_number" />
              <Typography variant="subtitle2">{insuranceCase.claimNumber}</Typography>
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      <Labels id={insuranceCase.id} labels={insuranceCase.labels} />
      <Divider sx={{ borderColor: 'rgba(33,33,33,0.08)', my: 4 }} />
      <Box sx={{ display: 'flex', gap: 4 }}>
        {longCreatedOn ? (
          <InfoBlock label={t('labels.createdAt')} value={longCreatedOn} />
        ) : null}
        {longExamindationDate ? (
          <InfoBlock label={t('labels.examinationDate')} value={longExamindationDate} />
        ) : null}
        {longBatchCreatedOn ? (
          <InfoBlock label={t('pages.staffPages.casesPage.lastMaterialUploadedAt')} value={longBatchCreatedOn} />
        ) : null}
      </Box>
    </Paper>
  )
}

export default CasesListItem;