import { useCallback, useState, useEffect } from 'react';

type AsyncCopyTextToClipboardFn = (text:string, copyAsHtml?:boolean) => Promise<boolean>;

const useCopyToClipboard = (): [AsyncCopyTextToClipboardFn, boolean] => {
  const [ copied, setCopied ] = useState<boolean>(false);

  let timeoutId: ReturnType<typeof setTimeout>;

  useEffect(() => {
    return () => {
      if ( timeoutId ) clearTimeout(timeoutId);
    };
    // ToDO: For some reason, throw an error
    // Variable 'timeoutId' is used before being assigned.
    // @ts-ignore
  }, [timeoutId]);

  const resetStatus = () => {
    timeoutId = setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const fallbackCopyTextToClipboardFn = (htmlText:string):boolean => {
    // Create a temporary element to hold the HTML content
    const tempElement = document.createElement('div');
    tempElement.style.whiteSpace = 'pre-line';
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px'; // Move it off-screen

    // Set the HTML content
    tempElement.innerHTML = htmlText;
    document.body.appendChild(tempElement);

    // Select the content
    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    try {
      document.execCommand('copy');
      document.body.removeChild(tempElement);
      setCopied(true);
      resetStatus();
      return true;
    } catch (error) {
      console.warn('Fallback: Oops, unable to copy', error);
      document.body.removeChild(tempElement);
      return false;
    }
  };

  const asyncCopyTextToClipboardFn:AsyncCopyTextToClipboardFn = useCallback(async (text, copyAsHtml = true) => {
    if ( !navigator.clipboard || !copyAsHtml ){
      console.warn('Clipboard not supported, using fallback method');
      return fallbackCopyTextToClipboardFn(text);
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      resetStatus();
      return true;
    } catch (error) {
      console.warn('Copy failed, using fallback method', error);
      return fallbackCopyTextToClipboardFn(text);
    }
    // eslint-disable-next-line
  }, []);

  return [ asyncCopyTextToClipboardFn, copied ];
};

export default useCopyToClipboard;
