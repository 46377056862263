import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IBillingPackage from "app/models/BillingPackage";
// Async
import { getPackages, getPackage, createPackage, updatePackage, deletePackage } from './BillingPackages.async';

interface IState {
  packages: IBillingPackage[] | null;
  package: IBillingPackage | null;
  loading: boolean;
};

const initialState:IState = {
  packages: null,
  package: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.BillingPackages,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder) {
    // Get packages
    builder.addCase(getPackages.pending, (state) => {
      state.packages = null
    });
    builder.addCase(getPackages.fulfilled, (state, action:PayloadAction<IBillingPackage[]>) => {
      state.packages = action.payload;
    });
    // Get package
    builder.addCase(getPackage.pending, (state) => {
      state.package = null
    });
    builder.addCase(getPackage.fulfilled, (state, action:PayloadAction<IBillingPackage>) => {
      state.package = action.payload;
    });
    // Create package
    builder.addCase(createPackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPackage.fulfilled, (state, action:PayloadAction<IBillingPackage>) => {
      if ( state.packages ){
        state.packages.push(action.payload);
      }
    })
    // Update package
    builder.addCase(updatePackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePackage.fulfilled, (state, action:PayloadAction<IBillingPackage>) => {
      if ( state.packages ){
        state.packages = state.packages.map((pkg:IBillingPackage) => {
          if ( pkg.id === action.payload.id ) return action.payload;
          return pkg;
        })
      }
    })
    // Delete package
    builder.addCase(deletePackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePackage.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.packages ){
        state.packages = state.packages.filter((pkg:IBillingPackage) => pkg.id !== action.payload)
      }
    })

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const BillingPackagesActions = slice.actions;

export default slice.reducer;
