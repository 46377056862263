// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $delete } from 'app/utilities/HttpClient';

const _url:string = '/labellingtool/annotations';

// Get annotations
export const getAnnotations = asyncThunkHandler(
  `${Reducers.LabellingToolAnnotations}/Get annotations`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Create annotation
export const createAnnotation = asyncThunkHandler(
  `${Reducers.LabellingToolAnnotations}/Create annotation`,
  async (data:any) => {
    const response:Response = await $post(_url, data);
    return response.json();
  }
);

// Delete annotation
export const deleteAnnotation = asyncThunkHandler(
  `${Reducers.LabellingToolAnnotations}/Delete annotation`,
  async (annotationId:number, { fulfillWithValue }) => {
    await $delete(`${_url}/${annotationId}`);
    return fulfillWithValue(annotationId);
  }
);
