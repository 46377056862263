import { FC } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAllPages } from 'app/store/page/page.selectors';
// Components
import { Loader } from 'app/components/Utilities';
// ToDO
import TODODocumentPageDetails from './TODO_DocumentPageDetails';

const DocumentPageDetailsPage:FC = () => {
  const pages = useAppSelector((state:any) => selectAllPages(state));

  if ( !pages ) return <Loader />
  return <TODODocumentPageDetails />
}

export default DocumentPageDetailsPage;
