import React from 'react';
// Store
import { useSelector } from 'react-redux';
// Selector
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import {
  Box,
  DialogTitle,
  IconButton
} from '@mui/material';
// Icons
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import CaseInfo from 'app/components/CaseInfo';
//
import PageCompareActions from './PageCompareActions';

type Props = {
  onClose: () => void;
}

const PageCompareDialogTitle:React.FC<Props> = ({
  // Props
  onClose
}) => {
  // State
  const insuranceCase:any = useSelector(selectInsuranceCase);

  if ( !insuranceCase ) return null;
  return (
    <DialogTitle sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 4
    }}>
      <Box sx={{ flexGrow: 1 }}>
        <CaseInfo />
      </Box>
      <PageCompareActions />
      <IconButton
        onClick={onClose}
      ><CloseIcon /></IconButton>
    </DialogTitle>
  )
};

export default PageCompareDialogTitle;
