
// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./ReportPresets.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/reportPresets';

// Get presets
export const getPresets = asyncThunkHandler(
  `${Reducers.ReportPresets}/Get presets`,
  async (params:any = {}) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

// Create preset
export const createPreset = asyncThunkHandler(
  `${Reducers.ReportPresets}/Create preset`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

// Update preset
export const updatePreset = asyncThunkHandler(
  `${Reducers.ReportPresets}/Update preset`,
  async ({ presetId, data }:{ presetId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${presetId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

// Delete preset
export const deletePreset = asyncThunkHandler(
  `${Reducers.ReportPresets}/Delete preset`,
  async (presetId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${presetId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(presetId);
  }
);
