import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Types
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Async
import { createMessage } from 'app/store/Messages/Messages.async';
// Selectors
import { selectInsuranceCase } from "app/store/Cases/Cases.selectors";
// Services
import Mixpanel from "app/services/Mixpanel.service";

const MessagesMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type } = action;

  if ( type === createMessage.fulfilled.type ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    Mixpanel.track(MixpanelTracks.CaseMessagesSendMessageAction, { id: insuranceCase.id });
  }

  return next(action);
}

export default MessagesMiddleware;
