import { FC, ReactNode } from 'react';
// Mui
import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps
} from '@mui/lab';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = MuiLoadingButtonProps & {
  name: string;
  children: ReactNode;
}

const Button:FC<Props> = ({
  // Props
  name, children, ...otherProps
}) => {
  return (
    <MuiLoadingButton
      {...otherProps}
      {...generateAttrForTesting('action', 'button', name)}
    >{children}</MuiLoadingButton>
  )
}

export default Button;
