export enum TemplateType {
  Text = 'text',
  Data = 'data'
};
export enum TemplateDataType {
  Document = 'document',
  Episode = 'episode'
}
export enum TemplateLayout {
  Chart = 'chart',
  Table = 'table',
  Custom = 'custom'
}

export type WizardFormNextStep = 'document:table' | 'episode:chart' | 'episode:table' | 'episode:custom' | null;
