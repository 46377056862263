import { RefObject, FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IBillingInvoice from 'app/models/BillingInvoice';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectGettingMore, selectHasMore, selectInvoices, selectParams } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Components
import { Message, Loader } from 'app/components/Utilities';
// Hooks
import useIntersectionObserver from 'app/hooks/useIntersectionObserver';
// 
import InvoicesListItem from './InvoicesListItem';

const BillingInvoicesList:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const invoices:IBillingInvoice[] | null = useAppSelector(selectInvoices);
  const params:any = useAppSelector(selectParams);
  const hasMore:boolean = useAppSelector(selectHasMore);
  const gettingMore:boolean = useAppSelector(selectGettingMore);

  const [ targetRef, isIntersecting ] = useIntersectionObserver({});

  useEffect(() => {
    if ( !isIntersecting ) return;
    if ( !hasMore ) return;

    const offset = params.offset + params.limit;

    dispatch(BillingInvoicesActions.setParams({ offset }));
    // eslint-disable-next-line
  }, [isIntersecting]);

  if ( !invoices ) return <Loader />;
  if ( !invoices.length ) return <Message text={t('pages.adminPages.billingInvoicesPage.noBillingInvoices')} />;
  return (
    <Fragment>
      {invoices.map((invoice:IBillingInvoice, index:number) => (
        <Fragment key={`Billing invoice item ${invoice.id}`}>
          <InvoicesListItem invoice={invoice} />
          {index === invoices.length - 1 ? (
            <span ref={targetRef as RefObject<HTMLSpanElement>} />
          ) : null}
        </Fragment>
      ))}
      {gettingMore ? <Loader /> : null}
    </Fragment>
  );
}

export default BillingInvoicesList;
