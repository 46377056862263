// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get } from 'app/utilities/HttpClient';

const _url:string = '/med';

export const getPhysicianFeeSchedules = asyncThunkHandler(
  `${Reducers.Medical}/Get physician fee schedules`,
  async (params:any) => {
    const response:Response = await $get(`${_url}/medicare/physicianFeeSchedules`, params);
    return response.json();
  }
);

export const getMedicareCarriers = asyncThunkHandler(
  `${Reducers.Medical}/Get medicare carriers`,
  async () => {
    const response:Response = await $get(`${_url}/medicare/carriers`);
    return response.json();
  }
);

export const getVaRates = asyncThunkHandler(
  `${Reducers.Medical}/Get va rates`,
  async (params:any) => {
    const response:Response = await $get(`${_url}/vaRates`, params);
    return response.json();
  }
);

export const getHcpcsCode = asyncThunkHandler(
  `${Reducers.Medical}/Get hcpcs code`,
  async (code:string) => {
    const response:Response = await $get(`${_url}/hcpcsCodes/${code}`);
    return response.json();
  }
);
