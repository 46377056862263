import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
// Models
import ITemplate from 'app/models/Template';
// Mui
import { Box, Paper, Typography, Tooltip } from '@mui/material';
// Icons
import { Add as AddIcon } from '@mui/icons-material';
// Dialogs
import TemplateFormDialog from 'app/dialogs/TemplateFormDialog';
// Components
import TemplateTypeInfo from 'app/components/TemplateTypeInfo';
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  template: ITemplate;
};

const GlobalTemplatesListItem:FC<Props> = ({
  // Props
  template
}) => {
  const { t } = useTranslation('common');
  // Params
  const { caseId } = useParams<{ caseId:string | undefined }>();

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Paper
        sx={{
          display: 'flex',
          p: 4
        }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h6">{template.name}</Typography>
          <TemplateTypeInfo
            type={template.type}
            dataType={template.dataType}
            layout={template.layout}
          />
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <Tooltip title={t('pages.staffPages.caseDetailsPage.addTemplateToCase')}>
            <IconButton
              name={`Add template ${template.id} to case`}
              aria-label="Add template to case button"
              onClick={toggle}
            ><AddIcon /></IconButton>
          </Tooltip>
        </Box>
      </Paper>
      {open ? (
        <TemplateFormDialog
          open={open}
          onClose={toggle}
          globalTemplateId={template.id}
          templateId={template.id}
          caseId={Number(caseId)}
        />
      ) : null}
    </Fragment>
  )
}

export default GlobalTemplatesListItem;
