import { Reducer } from 'redux';
import { IAppState, AppTypes, AppActionTypes } from './app.types';

export default class AppReducer {
  private static readonly _initialState:IAppState = {
    pagesInRow: 'auto',
    requestedUrl: null,
  };

  public static reducer:Reducer<IAppState, AppActionTypes> = (
    state = AppReducer._initialState,
    action
  ) => {
    switch (action.type){
      case AppTypes.SetRequestedUrl:
        return { ...state, requestedUrl: action.payload.requestedUrl };
      default:
        return state;
    }
  }
}