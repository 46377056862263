import { FC } from 'react';
import { useLocation } from 'react-router-dom';
// Models
import { IPage } from '@root/models/Page';
// Mui
import { Tooltip } from '@mui/material';
// Icons
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  RemoveCircle as RemoveCircleIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
import { selectIsPageIdInSelected } from 'app/store/AppUI/AppUI.selectors';
import PageService from 'app/services/PageService';
import { RootState } from 'app/store';
import { AppUiActions } from 'app/store/AppUI/AppUI.slice';

type Props = {
  page: IPage;
}

const CardPageActionsCheck:FC<Props> = ({
  // Props
  page
}) => {
  const { pathname } = useLocation<{ pathname:string }>();

  const pageId = PageService.toPageId(page.documentId, page.pageNum);

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const isPageIdInSelected = useAppSelector((state:RootState) => selectIsPageIdInSelected(state, {
    pageId
  }));

  const handleClick = () => {
    if ( isPageIdInSelected ){
      dispatch(AppUiActions.removePageIdFromSelected(pageId));
    } else {
      dispatch(AppUiActions.addPageIdToSelected(pageId));
    }
  }

  const actionIsHidden = !pathname.includes('/search') || (page.staple && page.staple.order !== 1);

  if ( actionIsHidden ) return null;
  return (
    <Tooltip title={isPageIdInSelected ? 'Unselect page' : 'Select page'}>
      <IconButton
        sx={{ ml: 'auto' }}
        name={isPageIdInSelected ? 'Unselect page' : 'Select page'}
        onClick={handleClick}
        color={isPageIdInSelected ? 'primary' : 'default'}
        size="small"
      >{isPageIdInSelected
        ? <RemoveCircleIcon color="primary" />
        : <CheckCircleOutlineIcon />
      }</IconButton>
    </Tooltip>
  )
}

export default CardPageActionsCheck;
