import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Models
import { AppDispatch } from 'app/store';
import { IBatch } from 'app/store/DMSBatches/DMSBatches.models';
// Async
import { createBatch } from 'app/store/DMSBatches/DMSBatches.async';
// Actions
import { DMSDocumentsActions } from 'app/store/DMSDocuments/DMSDocuments.slice';

const DMSBatchesMiddleware:Middleware = ({ dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type } = action;

  if ( type === createBatch.fulfilled.type ){
    const batch = action.payload as IBatch;
    if ( batch.documents ) dispatch(DMSDocumentsActions.setDocuments(batch.documents))
  }

  return next(action);
}

export default DMSBatchesMiddleware;
