import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Models
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPage } from 'app/store/DMSDocumentPages/DMSDocumentPages.async';
// Actions
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
// Selectors
import { selectGridResizerWidth } from 'app/store/AppUI/AppUI.selectors';
import { selectPage } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import DocumentName from 'app/components/DocumentName';
import GridResizer from 'app/components/GridResizer';
import CardPageTabs from 'app/components/CardPageTabs';
// import CardPageColors from 'app/components/CardPageColors';
import PageEpisodes from 'app/components/PageEpisodes';
import ColorPicker from 'app/components/ColorPicker';
import { Loader } from 'app/components/Utilities';

const DocumentPageDetailsPage:FC = () => {
  const { documentId, pageNum } = useParams<{ documentId:string, pageNum:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const gridResizerWidth:number = useAppSelector(selectGridResizerWidth);
  const page:IPage | IPresenterPage | null = useAppSelector(selectPage);

  useEffect(() => {
    dispatch(getPage({ documentId: Number(documentId), pageNum: Number(pageNum) }));
    return () => {
      dispatch(DMSDocumentPagesActions.setInitialField('page'));
    }
    // eslint-disable-next-line
  }, []);

  if ( !page ) return <Loader />;
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 4, overflow: 'hidden' }}>
      <Box sx={{ borderBottom: '1px solid rgba(0,0,0,0.08)', pb: 4, mb: 4 }}>
        <DocumentName documentId={page.documentId} />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', gap: 4, position: 'relative', overflow: 'hidden' }}>
        <Box sx={{ width: `${gridResizerWidth}%`, display: 'flex', overflow: 'hidden' }}>
          <CardPageTabs />
        </Box>
        <GridResizer />
        <Box sx={{ width: `${100 - gridResizerWidth}%`, overflowY: 'auto' }}>
          {page.colors?.length ? (
            <Box sx={{ mb: 4 }}>
              <ColorPicker
                id="Presenter page dialog"
                activeColors={page.colors || []}
              />
            </Box>
          ) : null}
          <PageEpisodes />
        </Box>
      </Box>
    </Box>
  )
}

export default DocumentPageDetailsPage;
