import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getAccounts } from 'app/store/AccountsManagement/AccountsManagement.async';
import { getServices } from 'app/store/BillingServices/BillingServices.async';
import { getOrders } from 'app/store/BillingOrders/BillingOrders.async';
// Selectors
import { selectParams } from 'app/store/BillingOrders/BillingOrders.selectors';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import OrdersFilter from './OrdersFilter';
import OrdersList from './OrdersList';
// i18next
import { useTranslation } from 'react-i18next';

const BillingOrdersPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const params:any = useAppSelector(selectParams);

  useEffect(() => {
    dispatch(getAccounts({}));
    dispatch(getServices({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getOrders(params));
    // eslint-disable-next-line
  }, [params]);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('labels.orders')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="sm">
          <OrdersFilter />
          <OrdersList />
        </Container>
      </Box>
    </Fragment>
  )
}

export default BillingOrdersPage;
