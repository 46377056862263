import { FC } from 'react';
// Models
import ICaseAlert from 'app/models/CaseAlert';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSortedAlerts } from 'app/store/CaseAlerts/CaseAlerts.selectors';
// Mui
import { Stack } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
import { Message } from 'app/components/Utilities';
// 
import AlertItem from './AlertItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
}

const CaseAlertsDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  // State
  const alerts:ICaseAlert[] | null = useAppSelector(selectSortedAlerts);

  const actions = (
    <Button
      name="Close case alerts dialog"
      onClick={onClose}
    >{t('labels.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      title={t('dialogs.caseAlerts.caseAlerts')}
      actions={actions}
    >
      {alerts?.length ? (
        <Stack spacing={4}>
          {alerts.map((alert:ICaseAlert) => (
            <AlertItem
              key={`Alert item ${alert.id}`}
              alert={alert}
            />
          ))}
        </Stack>
      ) : (
        <Message text={t('dialogs.caseAlerts.noAlerts')} />
      )}
    </Dialog>
  )
}

export default CaseAlertsDialog;
