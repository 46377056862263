import { FC, Fragment, useState } from 'react';
import dayjs from 'dayjs';
// Mui
import { alpha, Box } from '@mui/material';
// 
import CalendarPopover from './CalendarPopover';

type Props = {
  episode: any;
}

const CalendarDayEpisode:FC<Props> = ({
  // Props
  episode
}) => {
  const [ hours, minutes ] = episode.time ? episode.time.split(':') : [];

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
  
  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <Box
        sx={{
          bgcolor: alpha(episode.legend.color, 0.12),
          borderLeft: `4px solid ${episode.legend.color}`,
          borderRadius: 1,
          fontSize: '12px',
          fontWeight: 500,
          px: 1,
          py: 1/2,

          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          '& + div': { mt: 1 },
          '&:hover': {
            bgcolor: alpha(episode.legend.color, 0.24),
            cursor: 'pointer'
          }
        }}
        onClick={handleOpen}
      >
        {episode.time ? `${dayjs().hour(hours).minute(minutes).format('h:mm a')} ` : null}
        {episode.legend.label}
      </Box>
      {open ? (
        <CalendarPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          episode={episode}
        />
      ) : null}
    </Fragment>
  )
}

export default CalendarDayEpisode;
