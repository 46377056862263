import { forwardRef, useState, useEffect } from 'react';
// Models
import IOption from 'app/models/Option';
// Mui
import {
  AutocompleteChangeReason,
  Chip,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps
} from '@mui/material';

type Value = string | number | (string | number)[] | undefined | null;

type Props<T> = Omit<
  MuiAutocompleteProps<
    T, boolean, boolean, any
  >,
  'value' | 'onChange' | 'renderInput'
> & {
  value: Value;
  onChange: (nextValue: Value, reason:AutocompleteChangeReason) => void;
  options: T[] | null;
  InputProps?: MuiTextFieldProps;
};

const Autocomplete = forwardRef<any, Props<any>>(({
  // Props
  value, onChange, options, InputProps = {},
  renderOption = (props, option) => (
    <li {...props} key={option.id}>{option.name}</li>
  ),
  renderTags = (tagValue, getTagProps) => tagValue.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      key={option.id}
      style={{ maxWidth: '160px' }}
      label={option.name}
      title={option.name}
      size={InputProps.size || 'medium'}
    />
  )),
  ...otherProps
}, ref) => {
  const initialOption = otherProps.multiple ? [] : null;

  const [ selectedOption, setSelectedOption ] = useState<IOption | IOption[] | null>(initialOption);

  useEffect(() => {
    return () => {
      setSelectedOption(initialOption);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !options ) return;

    if ( !value ){
      setSelectedOption(initialOption);
    } else {
      if ( Array.isArray(value) ){
        const filteredOptions = options.filter((option:IOption) => value.includes(option.id));
        setSelectedOption(filteredOptions);
      } else {
        const foundedOption:IOption | undefined = options.find((option:IOption) => option.id === value);
        setSelectedOption(foundedOption || null);
      }
    }
    // eslint-disable-next-line
  }, [value, options]);

  const handleChange = (_:any, nextValue:IOption | IOption[] | null, reason:AutocompleteChangeReason) => {
    setSelectedOption(nextValue || initialOption);

    onChange(
      nextValue
        ? Array.isArray(nextValue)
          ? nextValue.length ? nextValue.map((option) => option.id) : []
          : nextValue.id
        : initialOption
      ,
      reason
    );
  };

  return (
    <MuiAutocomplete
      {...otherProps}
      ref={ref}
      value={selectedOption}
      options={options || []}
      onChange={handleChange}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          {...InputProps}
          fullWidth
          margin={InputProps.margin || 'normal'}
          disabled={!options || InputProps.disabled}
        />
      )}
      renderTags={renderTags}
      renderOption={renderOption}
      fullWidth={otherProps.fullWidth || true}
      getOptionLabel={(option:IOption) => option.name}
    />
  );
})

export default Autocomplete;
