import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
// Models
import { AppDispatch } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Async
import { createReport } from 'app/store/CaseReports/CaseReports.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Services
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

const CaseReportsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type } = action;

  if ( type === createReport.pending.type ){
    const state = getState()
    const insuranceCase = selectInsuranceCase(state) as IInsuranceCase;

    Mixpanel.track(MixpanelTracks.CaseReportCreated, { caseId: insuranceCase.id })
  }

  return next(action);
}

export default CaseReportsMiddleware;
