import { FC, useMemo } from 'react';
// Mui
import { SxProps } from '@mui/material';
// Icons
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClick: () => void;
  name: string;
  position?: 'left' | 'right';
  styles?: SxProps;
}

const SidebarToggleButton:FC<Props> = ({
  // Props
  open, onClick, name, position = 'right', styles = {}
}) => {
  const sxPosition = useMemo(() => {
    if ( position === 'left' ){
      return {
        left: 0,
        borderLeft: `1px solid rgba(0,0,0,0.08)`,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginLeft: -8
      };
    }
    return {
      right: 0,
      borderRight: `1px solid rgba(0,0,0,0.08)`,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginRight: -8,
    };
  }, [position]);

  const positionOpenIcon = position === 'right' ? <ChevronRightIcon /> : <ChevronLeftIcon />;
  const positionCloseIcon = position === 'right' ? <ChevronLeftIcon /> : <ChevronRightIcon />;

  return (
    <IconButton
      name={name}
      aria-label="Toggle sidebar button"
      sx={{
        ...sxPosition,
        position: 'absolute',
        bottom: 0,
        bgcolor: 'white',
        borderTop: `1px solid rgba(0,0,0,0.08)`,
        borderBottom: `1px solid rgba(0,0,0,0.08)`,
        mb: 2,
        zIndex: 2,
        ...styles
      }}
      onClick={onClick}
      size="small"
    >{open ? positionCloseIcon : positionOpenIcon}</IconButton>
  )
}

export default SidebarToggleButton;
