import Logo from 'assets/images/logo.svg';

import { FC } from 'react';
import { push } from 'connected-react-router'
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Box, Divider, Tooltip } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

const Brand:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);

  const handleGoTo = () => {
    if ( !myUser || trialMode ) return;

    const linkTo = myUser.role === UserRoles.Admin || myUser.role === UserRoles.Staff
      ? '/admin/cases'
      : myUser.role === UserRoles.Presenter || myUser.role === UserRoles.Client
        ? '/presenter/cases'
        : '/am/accounts'
    ;
    dispatch(push(linkTo));
  }

  return (
    <Tooltip title={!myUser || trialMode ? '' : t('components.brand.home')}>
      <Box
        sx={{
          cursor: !myUser || trialMode ? 'default' : 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={handleGoTo}
      >
        <img src={Logo} alt={`Case Chronology - ${t('components.brand.casePortal')}`} />
        <Divider
          sx={{
            height: 40,
            mx: 4
          }}
          orientation="vertical"
        />
        <Box
          sx={{
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
            letterSpacing: '0.15px'
          }}
          component="span"
        >{t('components.brand.casePortal')}</Box>
      </Box>
    </Tooltip>
  );
}

export default Brand;
