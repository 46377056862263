import { FC, Fragment } from 'react';
// Models
import { RootState } from 'app/store';
import IBillingService from 'app/models/BillingService';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteService } from 'app/store/BillingServices/BillingServices.async';
// Selectors
import { selectServiceEntity, selectLoading } from 'app/store/BillingServices/BillingServices.selectors';
// Mui
import {
  Theme, Divider,
  Box, Paper, Typography
} from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import ServiceFormDialog from './ServiceForm.dialog';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  serviceId: number;
}

const ServicesListItem:FC<Props> = ({
  // Props
  serviceId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const service:IBillingService = useAppSelector((state:RootState) => selectServiceEntity(state, { serviceId }));
  const loading:boolean = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openServiceFormDialog, toggle:toggleServiceFormDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deleteService(service.id));
  }

  return (
    <Fragment>
      <Paper sx={{ display: 'flex', p: 4, '& + &': { mt: 4 } }} variant="outlined">
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography variant="subtitle1">{service.name}</Typography>
          <Typography variant="body2" color="CaptionText">{service.description}</Typography>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', gap: 4 }}>
            <InfoBlock label={t('pages.accountManagerPages.billingServicesPage.unit')} value={service.unit} />
            <InfoBlock label={t('labels.additional')} value={service.additional ? t('labels.yes') : t('labels.no')} />
          </Box>
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Service dropdown ${service.id}`}
            aria-label="Service item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`Edit ${service.id}`}
              onClick={toggleServiceFormDialog}
            >{t('labels.edit')}</MenuItem>
            {service.additional ? (
              <MenuItem
                sx={{ color: (theme:Theme) => theme.palette.error.main }}
                name={`Delete ${service.id}`}
                onClick={toggleConfirmationDialog}
              >{t('labels.delete')}</MenuItem>
            ) : null}
          </Menu>
        </Box>
      </Paper>
      {openServiceFormDialog ? (
        <ServiceFormDialog
          open={openServiceFormDialog}
          onClose={toggleServiceFormDialog}
          service={service}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          title={t('pages.accountManagerPages.billingServicesPage.deleteService')}
          content={(
            <Trans
              t={t} i18nKey="pages.accountManagerPages.billingServicesPage.deleteServiceConfirmation"
              components={{ strong: <strong /> }}
              values={{ serviceName: service.name }}
            />
          )}
          loading={loading}
        />
      ) : null}
    </Fragment>
  )
}

export default ServicesListItem;
