import i18n from "i18n";

export const pageMessages = {
  updatePage: i18n.t('notifications.page.updatePage'),
  deletePage: i18n.t('notifications.page.deletePage'),
  rotatePage: i18n.t('notifications.page.rotatePage'),

  updatePageTag: i18n.t('notifications.page.updatePageTag'),
  removePageTag: i18n.t('notifications.page.removePageTag'),

  updatePageColor: i18n.t('notifications.page.updatePageColor'),
  removePageColor: i18n.t('notifications.page.removePageColor'),

  addPageToWorkspace: i18n.t('notifications.page.addPageToWorkspace'),
  removePageFromWorkspace: i18n.t('notifications.page.removePageFromWorkspace'),

  addHighlightToPage: i18n.t('notifications.page.addHighlightToPage'),
  deleteHighlightFromPage: i18n.t('notifications.page.deleteHighlightFromPage'),

  removePageFromStaple: i18n.t('notifications.page.removePageFromStaple'),

  exportPagesToPDF: i18n.t('notifications.page.exportPagesToPDF'),

  createStaple: i18n.t('notifications.page.createStaple'),
  updateStaple: i18n.t('notifications.page.updateStaple'),
  removeStaple: i18n.t('notifications.page.removeStaple')
};

i18n.on('languageChanged', () => {
  pageMessages.updatePage = i18n.t('notifications.page.updatePage');
  pageMessages.deletePage = i18n.t('notifications.page.deletePage');
  pageMessages.rotatePage = i18n.t('notifications.page.rotatePage');
  pageMessages.updatePageTag = i18n.t('notifications.page.updatePageTag');
  pageMessages.removePageTag = i18n.t('notifications.page.removePageTag');
  pageMessages.updatePageColor = i18n.t('notifications.page.updatePageColor');
  pageMessages.removePageColor = i18n.t('notifications.page.removePageColor');
  pageMessages.addPageToWorkspace = i18n.t('notifications.page.addPageToWorkspace');
  pageMessages.removePageFromWorkspace = i18n.t('notifications.page.removePageFromWorkspace');
  pageMessages.addHighlightToPage = i18n.t('notifications.page.addHighlightToPage');
  pageMessages.deleteHighlightFromPage = i18n.t('notifications.page.deleteHighlightFromPage');
  pageMessages.removePageFromStaple = i18n.t('notifications.page.removePageFromStaple');
  pageMessages.exportPagesToPDF = i18n.t('notifications.page.exportPagesToPDF');
  pageMessages.createStaple = i18n.t('notifications.page.createStaple');
  pageMessages.updateStaple = i18n.t('notifications.page.updateStaple');
  pageMessages.removeStaple = i18n.t('notifications.page.removeStaple');
})
