import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Async
import { getCaseShares, createCaseShare, acceptCaseShare } from './CaseShares.async';

interface ICaseShare {
  id: number;
  status: 'waiting' | 'copying' | 'completed';
  createdOn: string;
  notificationEmail: string;
}

interface IState {
  caseShares: ICaseShare[] | null;
  loading: boolean;
};

const initialState:IState = {
  caseShares: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.CaseAssignments,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers(builder) {
    // Get case shares
    builder.addCase(getCaseShares.pending, (state) => {
      state.caseShares = null;
    });
    builder.addCase(getCaseShares.fulfilled, (state, action:PayloadAction<ICaseShare[]>) => {
      state.caseShares = action.payload;
    });
    // Create case share
    builder.addCase(createCaseShare.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCaseShare.fulfilled, (state, action:PayloadAction<ICaseShare>) => {
      if ( state.caseShares ){
        state.caseShares = [...state.caseShares, action.payload];
      }
    });
    // Get assignments
    builder.addCase(acceptCaseShare.pending, (state) => {
      state.loading = true;
    })
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const CaseAssignmentsActions = slice.actions;

export default slice.reducer;
