import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import ITeam from "app/models/Team";
// Async
import { getTeam, getTeams, createTeam, updateTeam, deleteTeam } from './Teams.async';

interface IState {
  team: ITeam | null;
  teams: ITeam[] | null;
  filter: {
    search: string;
  };
  loading: boolean;
}

const initialState:IState = {
  team: null,
  teams: null,
  filter: {
    search: ''
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.Teams,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get team
    builder.addCase(getTeam.pending, (state) => {
      state.team = null;
    });
    builder.addCase(getTeam.fulfilled, (state, action:PayloadAction<ITeam>) => {
      state.team = action.payload;
    });
    // Get teams
    builder.addCase(getTeams.pending, (state) => {
      state.teams = null;
    });
    builder.addCase(getTeams.fulfilled, (state, action:PayloadAction<ITeam[]>) => {
      state.teams = action.payload;
    });
    // Create team
    builder.addCase(createTeam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTeam.fulfilled, (state, action:PayloadAction<ITeam>) => {
      if ( state.teams ){
        state.teams.push(action.payload);
      }
    });
    // Update team
    builder.addCase(updateTeam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTeam.fulfilled, (state, action:PayloadAction<ITeam>) => {
      if ( state.teams ){
        state.teams = state.teams.map((team:ITeam) => {
          if ( team.id === action.payload.id ) return action.payload;
          return team;
        })
      }
    });
    // Delete team
    builder.addCase(deleteTeam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTeam.fulfilled, (state, action:{ payload:number }) => {
      if ( state.teams ){
        state.teams = state.teams.filter((team:ITeam) => team.id !== action.payload);
      }
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const TeamsActions = slice.actions;

export default slice.reducer;