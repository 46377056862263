import { FC, useEffect } from 'react';
// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Actions
import { setInitial } from 'app/store/ui/uiSlice';
// Selectors
import { selectCompare } from 'app/store/ui/uiSelectors';
// Mui
import { Box, Button } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import InsuranceCaseInfo from 'app/components/CaseInfo';
import { Message } from 'app/components/Utilities';
// 
import ComparePage from './ComparePage';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CompareDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const compare:string[] = useAppSelector(selectCompare);

  const handleClick = () => {
    dispatch(setInitial('compare'));
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      title={<InsuranceCaseInfo />}
    >
      {!compare.length ? (
        <Message text={t('labels.noPagesInCompare')} />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}>
          <Box sx={{ pb: 4, textAlign: 'right' }}>
            <Button
              onClick={handleClick}
              variant="outlined"
              color="error"
              size="small"
            >{t('labels.clearAll')}</Button>
          </Box>
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            overflowX: 'auto'
          }}>
            {compare.map((pageId:string) => (
              <ComparePage
                key={`compare-item-${pageId}`}
                pageId={pageId}
              />
            ))}
          </Box>
        </Box>
      )}
    </Dialog>
  )
}

export default CompareDialog;
