import { createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

// type CustomThunkAPI<Returned, ThunkArg> = {
//   dispatch: AppDispatch;
//   getState: () => RootState;
//   requestId: string;
//   signal: AbortSignal;
//   rejectWithValue: (error: any) => { error: any, arg: ThunkArg, requestId: string };
//   fulfillWithValue: (data: Returned, arg?: ThunkArg, requestId?: string) => { data: Returned, arg: ThunkArg, requestId: string };
// };

const asyncThunkHandler = <Returned, ThunkArg>(
  typePrefix: string,
  asyncThunkCreator: (payload:ThunkArg, thunkAPI:any) => Promise<Returned>
): AsyncThunk<Returned, ThunkArg, {}> => {
  return createAsyncThunk<Returned, ThunkArg, {}>(
    typePrefix,
    async (args:ThunkArg, thunkAPI) => {
      try {
        return await asyncThunkCreator(args, thunkAPI);
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
};

export default asyncThunkHandler;
