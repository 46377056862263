import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { PresenterActions } from 'app/store/Presenter/Presenter.slice';
// Selectors
import { selectInsuranceCases, selectPresenterFilter } from 'app/store/Presenter/Presenter.selectors';
// Mui
import { Box, TextField } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const InsuranceCasesFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCases:any = useAppSelector(selectInsuranceCases);
  const filter = useAppSelector(selectPresenterFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const debounceFilter = useCallback(debounce(1000, (field:keyof typeof filter, value:string) => {
    dispatch(PresenterActions.setFilter({ field, value }));
    // eslint-disable-next-line
  }), []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({...prevState, [name]: value}));
    debounceFilter('search', value);
  }

  if ( !insuranceCases || insuranceCases.length === 0 ) return null;
  return (
    <Box marginBottom={4}>
      <TextField
        label={t('pages.presenterPages.insuranceCasesPage.searchByName')} id="search" name="search" value={stateFilter.search}
        onChange={handleChange}
        fullWidth
        size="small"
      />
    </Box>
  )
}

export default InsuranceCasesFilter;
