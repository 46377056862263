enum Features {
  HcpcsCodes = 'hcpcsCodes',
  PageClassification = 'pageClassification',
  DatasetHPL_CA = 'dataset.healthcare_practitioner_licensure.ca',
  DatasetHPL_FL = 'dataset.healthcare_practitioner_licensure.fl',
  DatasetHPL_WA = 'dataset.healthcare_practitioner_licensure.wa',
  PatientPortal = 'patientPortal',
  DocumentReprocessing = 'documentReprocessing',
  LegacyReportTemplates = 'legacyReportTemplates',
  Invoicing = 'invoicing',
  AIChat = 'aiChat',
  AIChatPrompts = 'aiChat:prompts'
}

export default Features;

export type DatasetHPLFeatures = Features.DatasetHPL_CA | Features.DatasetHPL_FL | Features.DatasetHPL_WA;
