import { FC, useState, useEffect, Fragment } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import dayjs from 'dayjs';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { setSelectedDate } from 'app/store/currentUser/currentUserSlice';
// Async
import { getMyPreferences } from 'app/store/currentUser/currentUserAsync';
// Selectors
import { selectPreferences } from 'app/store/currentUser/currentUserSelectors';
import { selectAllPages } from 'app/store/page/page.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Mui
import {
  Box,
  Paper, Tabs, Tab
} from '@mui/material';
// Componnets
import { Loader } from 'app/components/Utilities';
// 
import Calendar from './Calendar';
import Timeline from './Timeline';
import LegendsList from './LegendsList';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

const CalendarPage:FC = () => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryTab = searchParams.get('tab') || 'calendar';
  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const preferences = useSelector(selectPreferences);
  // ToDO
  const insuranceCase = useSelector(selectInsuranceCase);
  const pages = useSelector(selectAllPages);
  const episodes = useSelector(selectEpisodesAll);

  const [ tab, setTab ] = useState(queryTab);

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseCalendarView, { id: Number(caseId) });

    dispatch(getMyPreferences(`case:${caseId}.chart.legend`));
    if ( insuranceCase && insuranceCase.eventDate ){
      dispatch(setSelectedDate(dayjs(insuranceCase.eventDate)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTab(queryTab);
    // eslint-disable-next-line
  }, [queryTab]);

  const handleChange = (_:any, nextTab:string) => {
    dispatch(push({ search: `tab=${nextTab}` }));
  }

  if ( !preferences || !pages || !episodes ) return <Loader />;
  return (
    <Fragment>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto', p: 2, height: '100%' }}>
        <Paper variant="outlined" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value="calendar" label={t('labels.calendar')} />
            <Tab value="timeline" label={t('labels.timeline')} />
            <Tab style={{ display: trialMode ? 'none' : 'flex' }} value="legends" label={t('labels.legends')} />
          </Tabs>
        </Paper>
        {tab === 'calendar' ? <Calendar /> : null}
        {tab === 'timeline' ? <Timeline /> : null}
        {tab === 'legends' ? <LegendsList /> : null}
      </Box>
    </Fragment>
  )
}

export default CalendarPage;
