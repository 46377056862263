// Types
import Reducers from "app/types/Reducers";
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from './ReportSectionTemplates.messages';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/reportSectionTemplates';

// Get report sections
export const getReportSectionTemplate = asyncThunkHandler(
  `${Reducers.ReportSectionTemplates}/Get report section templates`,
  async () => {
    const response:Response = await $get(_url);
    return response.json();
  }
);

// Create report section template
export const createReportSectionTemplate = asyncThunkHandler(
  `${Reducers.ReportSectionTemplates}/Create report section template`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }))
    return response.json();
  }
);

// Update report section
export const updateReportSectionTemplate = asyncThunkHandler(
  `${Reducers.ReportSectionTemplates}/Update report section templae`,
  async ({ reportSectionTemplateId, data }:{ reportSectionTemplateId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${reportSectionTemplateId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }))
    return response.json();
  }
);

// Delete report section
export const deleteReportSectionTemplate = asyncThunkHandler(
  `${Reducers.ReportSectionTemplates}/Delete report section template`,
  async (reportSectionTemplateId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${reportSectionTemplateId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }))
    return fulfillWithValue(reportSectionTemplateId)
  }
);
