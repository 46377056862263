import { FC, memo, useRef, useState, useMemo, useEffect } from 'react';
import { Dayjs } from 'dayjs';
// Canvas
import CanvasJSReact from 'app/libraries/canvasjs.react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { setSelectedDate } from 'app/store/calendar/calendarSlice';
// Selectors
import { selectSelectedDate, selectDailyChartData } from 'app/store/calendar/calendarSelectors';
// Mui
import { Box, Button, Tooltip, Typography } from '@mui/material';
// Components
import { DesktopDatePicker } from 'app/components/Mui';
// 
import ChartPopover from './ChartPopover';
// i18next
import { useTranslation } from 'react-i18next';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
  initialOptions: any;
}

const DailyChart:FC<Props> = ({
  // Props
  initialOptions
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const selectedDate = useAppSelector(selectSelectedDate);
  const dailyChartData:any = useAppSelector(selectDailyChartData);

  const canvasRef = useRef<any>(null);

  const [ selectedBar, setSelectedBar ] = useState(null);

  const handleOpenBar = (event:any) => {
    setSelectedBar(event);
  }
  const handleCloseBar = () => setSelectedBar(null);

  const data = useMemo(() => {
    return dailyChartData.map((data:any) => ({
      ...data,
      click: (event:any) => handleOpenBar(event)
    }))
  }, [dailyChartData]);

  const [ options, setOptions ] = useState(initialOptions);

  useEffect(() => {
    setOptions((prevState:any) => ({
      ...prevState,
      data
    }));
    // eslint-disable-next-line
  }, [data]);

  const handleClick = (isNext:boolean) => {
    if ( !selectedDate || !selectedDate.isValid() ) return;

    const nextDate = isNext
      ? selectedDate.add(1, 'day')
      : selectedDate.subtract(1, 'day')
    ;
    dispatch(setSelectedDate(nextDate));
  }

  const handleChange = (nextDate:Dayjs | null) => {
    if ( nextDate && !nextDate.isValid() ) return;

    dispatch(setSelectedDate(nextDate));
  }

  const barOpen = Boolean(selectedBar);

  return (
    <Box sx={{ position: 'relative', paddingTop: '32px' }}>
      <Typography variant="h6">{t('labels.dailyTimeline')}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px 0' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }} style={{ gap: '8px' }}>
          <Tooltip title={t('labels.previousDay')}>
            <Button
              onClick={() => handleClick(false)}
              color="primary"
              variant="outlined"
            >{t('labels.prev')}</Button>
          </Tooltip>
          <DesktopDatePicker
            label={t('labels.date')}
            name="date"
            value={selectedDate}
            onChange={handleChange}
            inputProps={{
              size: 'small',
              margin: 'none'
            }}
          />
          <Tooltip title={t('labels.nextDay')}>
            <Button
              onClick={() => handleClick(true)}
              color="primary"
              variant="outlined"
            >{t('labels.next')}</Button>
          </Tooltip>
        </Box>
        <Box></Box>
      </Box>
      <CanvasJSChart ref={canvasRef} options={options} />
      {barOpen ? (
        <ChartPopover
          selectedBar={selectedBar}
          onClose={handleCloseBar}
        />
      ) : null}
    </Box>
  )
}

export default memo(DailyChart);


