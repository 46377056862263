import { FC } from 'react';
// Models
import IClientAccount from 'app/models/ClientAccount';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredAccounts } from 'app/store/Clients/Clients.selectors';
// Mui
import { Box } from '@mui/material';
// Componenst
import { Loader, Message } from 'app/components/Utilities';
// 
import AccountsListItem from './AccountsListItem';
// i18next
import { useTranslation } from 'react-i18next';

const AccountsList:FC = () => {
  const { t } = useTranslation('common');
  // State
  const accounts:IClientAccount[] | null = useAppSelector(selectFilteredAccounts);

  if ( !accounts ) return <Loader />;
  if ( !accounts.length ) return <Message text={t('pages.staffPages.clientAccountsPage.noClients')} />;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {accounts.map((account:IClientAccount) => (
        <AccountsListItem
          key={`Client account item ${account.id}`}
          account={account}
        />
      ))}
    </Box>
  )
}

export default AccountsList;
