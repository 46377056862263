import { FC, memo, useRef, useState, useMemo, useEffect } from 'react';
import { Dayjs } from 'dayjs';
// Canvas
import CanvasJSReact from 'app/libraries/canvasjs.react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { setSelectedDate } from 'app/store/currentUser/currentUserSlice';
// Selectors
import { selectSelectedDate, selectDailyChartData } from 'app/store/currentUser/currentUserSelectors';
// Mui
import { Box, Button, Typography, Tooltip, IconButton } from '@mui/material';
// Icons
import { Download as DownloadIcon } from '@mui/icons-material';
// Components
import { DesktopDatePicker } from 'app/components/Mui';
// 
import ChartPopover from './ChartPopover';
import useMenu from 'app/hooks/useMenu';
import { useAppSelector } from 'app/hooks/useStore';
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
  onDownloadJPEG: (chartImageURL:string, daily:boolean) => void;
}

const DailyChart:FC<Props> = ({
  // Props
  onDownloadJPEG
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useDispatch();
  // State
  const currentAccount = useAppSelector(selectCurrentAccount);
  const selectedDate = useSelector(selectSelectedDate);
  const dailyChartData = useSelector(selectDailyChartData);
  // ToDO
  const trialMode = useSelector(selectTrialMode);

  const { Menu, MenuItem, openMenu } = useMenu();

  const canvasRef = useRef<any>(null);

  const [ selectedBar, setSelectedBar ] = useState(null);

  const handleOpenBar = (event:any) => {
    setSelectedBar(event);
  }
  const handleCloseBar = () => setSelectedBar(null);

  const data = useMemo(() => {
    return dailyChartData.map((data:any) => ({
      ...data,
      click: (event:any) => handleOpenBar(event)
    }))
  }, [dailyChartData]);

  const timeFormat = currentAccount?.settings.timeFormat
    ? currentAccount?.settings.timeFormat.includes('a')
      ? 'hh:mm tt'
      : 'HH:mm'
    : 'hh:mm tt'
  ;

  useEffect(() => {
    setOptions({
      ...initialOptions,
      axisX:{
        valueFormatString: timeFormat
      },
      data
    });
    // eslint-disable-next-line
  }, [data]);

  const [ options, setOptions ] = useState({});

  const handleClick = (isNext:boolean) => {
    if ( !selectedDate || !selectedDate.isValid() ) return;

    const nextDate = isNext
      ? selectedDate.add(1, 'day')
      : selectedDate.subtract(1, 'day')
    ;

    dispatch(setSelectedDate(nextDate));
  }

  const handleChange = (nextDate:Dayjs | null) => {
    if ( nextDate && !nextDate.isValid() ) return;
    dispatch(setSelectedDate(nextDate));
  }

  const handleDownloadJPEG = () => {
    if ( canvasRef.current ){
      const imageURL = canvasRef.current.chart.canvas.toDataURL('image/jpeg', 1.0);
      onDownloadJPEG(imageURL, true);
    }
  }

  const barOpen = Boolean(selectedBar);

  return (
    <Box sx={{ position: 'relative', pt: 8 }}>
      <Typography variant="h6">{t('labels.dailyTimeline')}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title={t('labels.previousDay')}>
            <Button
              onClick={() => handleClick(false)}
              color="primary"
              variant="outlined"
              size="small"
            >{t('labels.prev')}</Button>
          </Tooltip>
          <DesktopDatePicker
            label={t('labels.date')}
            name="date"
            value={selectedDate}
            onChange={handleChange}
            inputProps={{
              size: 'small',
              margin: 'none'
            }}
          />
          <Tooltip title={t('labels.nextDay')}>
            <Button
              onClick={() => handleClick(true)}
              color="primary"
              variant="outlined"
              size="small"
            >{t('labels.next')}</Button>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {!trialMode ? (
            <Box>
              <Tooltip title={t('labels.download')}>
                <IconButton
                  onClick={openMenu}
                ><DownloadIcon /></IconButton>
              </Tooltip>
              <Menu>
                <MenuItem name="Download as JPEG" onClick={handleDownloadJPEG}>{t('pages.adminPages.calendarPage.chartAsJpeg')}</MenuItem>
              </Menu>
            </Box>
          ) : null}
        </Box>
      </Box>
      <CanvasJSChart ref={canvasRef} options={options} />
      {barOpen ? (
        <ChartPopover
          selectedBar={selectedBar}
          onClose={handleCloseBar}
        />
      ) : null}
    </Box>
  )
}

export default memo(DailyChart);

const dataPointWidth = 5;
const initialOptions = {
  toggleVisible: false,
  zoomOutVisible: false,
  dataPointWidth,
  toolTip: {
    enabled: false,
  },
  axisY: {
    interval: 1,
    includeZero: true,
    tickColor: '#d3d3d3',
    gridColor: '#d3d3d3'
  },
}
