
import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import Features from 'app/types/Features';
// Models
import { RootState } from 'app/store';
import { ICurrentAccount } from 'app/models/Account';
import ISubscription from 'app/models/Subscription';

export const selectCurrentAccount = (state:RootState) => state[Reducers.Accounts].currentAccount;
export const selectCurrentAccountBilling = (state:RootState) => state[Reducers.Accounts].currentAccountBilling;
export const selectLoading = (state:RootState) => state[Reducers.Accounts].loading;

export const selectHcpcsCodesEnabled = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.features ) return false;
    return currentAccount.features.includes(Features.HcpcsCodes);
  }
);

export const selectInvoicingEnabled = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.features ) return false;
    return currentAccount.features.includes(Features.Invoicing);
  }
);

export const selectPatientPortalEnabled = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.features ) return false;
    return currentAccount.features.includes(Features.PatientPortal);
  }
);

export const selectDocumentReprocessingEnabled = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.features ) return false;
    return currentAccount.features.includes(Features.DocumentReprocessing);
  }
);

export const selectLegacyReportTemplatesEnabled = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.features ) return false;
    return currentAccount.features.includes(Features.LegacyReportTemplates);
  }
);

export const selectAIChatEnabled = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.features ) return false;
    return currentAccount.features.includes(Features.AIChat);
  }
);

export const selectAIChatPromptsEnabled = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.features ) return false;
    return currentAccount.features.includes(Features.AIChatPrompts);
  }
);

export const selectSubscriptionItemWithCptCodesService = createSelector(
  [ selectCurrentAccountBilling ],
  ( currentAccountBilling:ISubscription | null ) => {
    if ( !currentAccountBilling ) return null;
    if ( !currentAccountBilling.subscription || !currentAccountBilling.subscription.items ) return null;
    let subscriptionItem = null;
    for ( let item of currentAccountBilling.subscription.items ){
      if ( item.service && item.service.code === 'cptCodesAccess' ){
        subscriptionItem = item;
        break;
      }
    }
    return subscriptionItem;
  }
);

export const selectTagsAsOptions = createSelector(
  [ selectCurrentAccount ],
  ( currentAccount:ICurrentAccount | null ) => {
    if ( !currentAccount || !currentAccount.tags ) return null;
    return currentAccount.tags.map((tag:string) => ({
      id: tag,
      name: tag
    }))
  }
)
