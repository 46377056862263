import React from 'react';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectPrevStaplePage, selectNextStaplePage } from 'app/store/page/page.selectors';
// MaterialUI
// Core
import IconButton from '@mui/material/IconButton';
// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type Props = {
  page: IPage;
  isFilesPage: boolean;
};

const PageCardBodyStapleArrows:React.FC<Props> = ({
  // Props
  page, isFilesPage
}) => {
  const stapleId = page.staple && page.staple.id ? page.staple.id : null;
  // Dispatch
  const dispatch = useDispatch();
  // State
  const prevStaplePage:IPage | null = useSelector((state:any) => selectPrevStaplePage(state, {
    stapleId,
    pageNum: page.pageNum
  }));
  const nextStaplePage:IPage | null = useSelector((state:any) => selectNextStaplePage(state, {
    stapleId,
    pageNum: page.pageNum
  }));

  const handleSwapStaplePageWithPrev = () => {
    if ( prevStaplePage ){
      dispatch(PageActions.patchPages([
        {
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          staple: {
            ...page.staple,
            order: prevStaplePage.staple?.order
          }
        },{
          documentId: prevStaplePage.documentId,
          pageNum: prevStaplePage.pageNum,
          version: prevStaplePage.version,
          staple: {
            ...prevStaplePage.staple,
            order: page.staple?.order
          }
        }
      ]));
    }
  }
  const handleSwapStaplePageWithNext = () => {
    if ( nextStaplePage ){
      dispatch(PageActions.patchPages([
        {
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          staple: {
            ...page.staple,
            order: nextStaplePage.staple?.order
          }
        },{
          documentId: nextStaplePage.documentId,
          pageNum: nextStaplePage.pageNum,
          version: nextStaplePage.version,
          staple: {
            ...nextStaplePage.staple,
            order: page.staple?.order
          }
        }
      ]));
    }
  }

  return (
    <React.Fragment>
      <div className="card-direction left">
        {(isFilesPage && prevStaplePage && prevStaplePage.staple && prevStaplePage.staple.order !== 1) ? (
          <IconButton
            className="card-direction-button"
            color="primary"
            size="small"
            onClick={handleSwapStaplePageWithPrev}
          ><ChevronLeftIcon /></IconButton>
        ) : null}
      </div>
      <div className="card-direction right">
        {(isFilesPage && nextStaplePage && page.staple && page.staple.order !== 1) ? (
          <IconButton
            className="card-direction-button"
            color="primary"
            size="small"
            onClick={handleSwapStaplePageWithNext}
          ><ChevronRightIcon /></IconButton>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default PageCardBodyStapleArrows;
