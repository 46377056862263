import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { replace } from 'connected-react-router';
// Types
import CaseStatuses from 'app/types/CaseStatuses';
import UserRoles from 'app/types/UserRoles';
import Reducers from 'app/types/Reducers';
// Models
import { IMyUser } from 'app/models/User';
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiActions } from 'app/store/AppUI/AppUI.slice';
import { AppUiDialogActions } from 'app/store/AppUIDialog/AppUIDialog.slice';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectTotalUnresolvedAlerts } from 'app/store/CaseAlerts/CaseAlerts.selectors';
// Mui
import { Badge, Box, Chip, Divider } from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Dialogs
import DocumentTemplateDialog from './DocumentTemplateDialog'
import DocumentFormDialog from 'app/dialogs/DocumentFormDialog';
import BatchCreateFormDialog from './BatchCreateFormDialog';
import CaseDetailsDialog from 'app/dialogs/CaseDetailsDialog';
import ReportFormDialog from 'app/dialogs/ReportFormDialog';
import CaseShareAccessDialog from 'app/dialogs/CaseShareAccessDialog';
import CaseDuplicateFormDialog from 'app/dialogs/CaseDuplicateFormDialog';
import CaseAlertsDialog from 'app/dialogs/CaseAlertsDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
// i18next
import { useTranslation } from 'react-i18next';

const CaseDetailsDropdown:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const insuranceCase:IInsuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const totalUnresolvedAlerts = useAppSelector(selectTotalUnresolvedAlerts);

  // ToDO
  const items = useAppSelector((state:any) => {
    const currentBilling = state[Reducers.Accounts].currentAccountBilling;
    if ( !currentBilling || !currentBilling.subscription ) return null;
    return currentBilling.subscription.items.filter((item:any) => item.additional)
  });

  const { Menu, MenuItem, openMenu } = useMenu();

  const { open:openDocumentTemplateDialog, toggle:toggleDocumentTemplateDialog } = useToggle();
  const { open:openBatchCreateDialog, toggle:toggleBatchCreateDialog } = useToggle();
  const { open:openDetailsDialog, toggle:toggleDetailsDialog } = useToggle();
  const { open:openReportDialog, toggle:toggleReportDialog } = useToggle();
  const { open:openShareAccessDialog, toggle:toggleShareAccessDialog } = useToggle();
  const { open:openDuplicateFormDialog, toggle:toggleDuplicateFormDialog } = useToggle();
  const { open:openAlertsDialog, toggle:toggleAlertsDialog } = useToggle();

  const [ content, setContent ] = useState<string | undefined>(undefined);

  const open = content !== undefined;
  const handleClose = () => {
    setContent(undefined);
  }

  const handleOpenLabelsDialog = () => {
    dispatch(AppUiDialogActions.showDialog({
      dialogName: 'CaseLabelsDialog',
      dialogProps: {
        insuranceCaseId: insuranceCase.id || 1,
        labels: insuranceCase.labels || [],
        version: insuranceCase.version
      }
    }));
  }

  const handleOpenNotesDialog = () => {
    dispatch(AppUiDialogActions.showDialog({
      dialogName: 'CaseNotesDialog',
      dialogProps: {}
    }));
  }

  const toggleTrialMode = () => {
    if ( !insuranceCase ) return;

    const { pathname } = window.location;
    if (
      pathname.indexOf(`/admin/cases/${insuranceCase.id}/workspace`) === -1 &&
      pathname.indexOf(`/admin/cases/${insuranceCase.id}/calendar`) === -1
    ){
      dispatch(replace(`/admin/cases/${insuranceCase.id}/workspace`));
    }
    dispatch(AppUiActions.toggleTrialMode());
  }

  const caseStatusOpen = insuranceCase?.status === CaseStatuses.Open;
  const caseStatusArchived = insuranceCase?.status === CaseStatuses.Archived;

  const noProcessingOrReady = !insuranceCase?.processing || insuranceCase.processing.status === 'ready';

  const isMyUserRoleAdmin = myUser?.role === UserRoles.Admin;
  const isMyUserRoleStaff = myUser?.role === UserRoles.Staff;

  const duplicateVisible = caseStatusOpen;
  const documentsExportsVisible = isMyUserRoleAdmin;

  const isStatusArchived = insuranceCase?.status === CaseStatuses.Archived;

  const documentsExportsEnabled = (caseStatusOpen || caseStatusArchived) && noProcessingOrReady;

  if ( !insuranceCase || trialMode ) return null
  return (
    <Box sx={{ pl: 2 }}>
      <IconButton
        name={`Case dropdown ${insuranceCase.id}`}
        aria-label="Case item dropdown button"
        onClick={openMenu}
      >
        <Badge
          aria-label="Case item dropdown button"
          badgeContent={totalUnresolvedAlerts}
          color="error"
        ><MoreVertIcon /></Badge>
      </IconButton>
      <Menu>
        <MenuItem
          name={`Create document ${insuranceCase.id}`}
          onClick={toggleDocumentTemplateDialog}
        >Create document</MenuItem>
        <MenuItem
          name={`Create batch ${insuranceCase.id}`}
          onClick={toggleBatchCreateDialog}
        >{t('pages.staffPages.caseDetailsPage.uploadDocuments')}</MenuItem>
        <Divider />
        <MenuItem
          name={`Case details ${insuranceCase.id}`}
          onClick={toggleDetailsDialog} 
        >{t('labels.caseDetails')}</MenuItem>
        <MenuItem
          name={`Case labels ${insuranceCase.id}`}
          disabled={caseStatusArchived}
          onClick={handleOpenLabelsDialog}
        >{t('labels.labels')}</MenuItem>
        <MenuItem
          name={`Case notes ${insuranceCase.id}`}
          onClick={handleOpenNotesDialog}
        >{t('labels.notes')}</MenuItem>
        {isMyUserRoleAdmin ? (
          <MenuItem
            name={`Case shares ${insuranceCase.id}`}
            component={Link}
            to={`/admin/cases/${insuranceCase.id}/case-shares`}
          >{t('pages.staffPages.caseDetailsPage.caseShares')}</MenuItem>
        ) : null}
        {documentsExportsVisible ? (
          <MenuItem
            name={`Documents exports ${insuranceCase.id}`}
            component={Link}
            to={`/admin/cases/${insuranceCase.id}/documents-exports`}
            disabled={!documentsExportsEnabled}
          >{t('pages.staffPages.caseDetailsPage.documentsExports')}</MenuItem>
        ) : null}
        {isMyUserRoleAdmin && items ? (
          <MenuItem
            name={`Orders ${insuranceCase.id}`}
            component={Link}
            to={`/admin/cases/${insuranceCase.id}/orders`}
          >{t('labels.orders')}</MenuItem>
        ) : null}
        <MenuItem
          name={`Audit log ${insuranceCase.id}`}
          component={Link}
          to={`/admin/cases/${insuranceCase.id}/audit-log`}
        >{t('pages.staffPages.caseDetailsPage.auditLog')}</MenuItem>
        <MenuItem
          key={`Case alerts ${insuranceCase.id}`}
          name={`Case alerts ${insuranceCase.id}`}
          onClick={toggleAlertsDialog}
        >
          <Box sx={{ flexGrow: 1 }}>{t('pages.staffPages.caseDetailsPage.alerts')}</Box>
          {totalUnresolvedAlerts ? (
            <Chip
              label={totalUnresolvedAlerts}
              color="error"
              size="small"
            />
          ) : null}
        </MenuItem>
        <Divider />
        {legacyReportTemplatesEnabled ? (
          <MenuItem
            name={`Create report ${insuranceCase.id}`}
            onClick={toggleReportDialog}
            disabled={isStatusArchived}
          >{t('pages.staffPages.caseDetailsPage.createReport')}</MenuItem>
        ) : null}
        {isMyUserRoleAdmin || isMyUserRoleStaff ? (
          <MenuItem
            name={`Share access ${insuranceCase.id}`}
            onClick={toggleShareAccessDialog}
          >{t('pages.staffPages.caseDetailsPage.shareAccess')}</MenuItem>
        ) : null}
        {duplicateVisible ? (
          <MenuItem
            name={`Duplicate case ${insuranceCase.id}`}
            onClick={toggleDuplicateFormDialog}
          >{t('labels.duplicate')}</MenuItem>
        ) : null}
        <Divider />
        <MenuItem
          name={`Trial mode ${insuranceCase.id}`}
          onClick={toggleTrialMode}
        >{t('pages.staffPages.caseDetailsPage.trialMode')}</MenuItem>
      </Menu>
      {/* Dialogs */}
      {openDocumentTemplateDialog ? (
        <DocumentTemplateDialog
          open={openDocumentTemplateDialog}
          onClose={toggleDocumentTemplateDialog}
          onConfirm={(nextContent) => {
            setContent(nextContent);
          }}
        />
      ) : null}
      {open ? (
        <DocumentFormDialog
          open={open}
          onClose={handleClose}
          content={content}
        />
      ) : null}
      {openBatchCreateDialog ? (
        <BatchCreateFormDialog
          open={openBatchCreateDialog}
          onClose={toggleBatchCreateDialog}
        />
      ) : null}
      {openDetailsDialog ? (
        <CaseDetailsDialog
          open={openDetailsDialog}
          onClose={toggleDetailsDialog}
          disabled={isStatusArchived}
        />
      ) : null}
      {openReportDialog ? (
        <ReportFormDialog
          open={openReportDialog}
          onClose={toggleReportDialog}
          insuranceCaseId={insuranceCase.id}
        />
      ): null}
      {openShareAccessDialog ? (
        <CaseShareAccessDialog
          open={openShareAccessDialog}
          onClose={toggleShareAccessDialog}
          insuranceCaseId={insuranceCase.id}
          clientAccountId={insuranceCase.clientAccountId}
        />
      ) : null}
      {openDuplicateFormDialog ? (
        <CaseDuplicateFormDialog
          open={openDuplicateFormDialog}
          onClose={toggleDuplicateFormDialog}
          caseId={insuranceCase.id}
        />
      ) : null}
      {openAlertsDialog ? (
        <CaseAlertsDialog
          open={openAlertsDialog}
          onClose={toggleAlertsDialog}
        />
      ) : null}
    </Box>
  )
}

export default CaseDetailsDropdown;
