import config from '../../config';

import { FC } from 'react';
import { useParams } from 'react-router-dom';
// Models
import { RootState } from 'app/store';
import IAnnotation from 'app/models/Annotation';
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPageEntity } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';

type Props = {
  documentId: number;
  pageNum: number;
}

const PagePdf:FC<Props> = ({
  // Props
  documentId, pageNum
}) => {
  const { insuranceCaseId } = useParams<{ insuranceCaseId:string }>();
  // State
  const page:IPage | IPresenterPage = useAppSelector((state:RootState) => selectPageEntity(state, { documentId, pageNum }));

  const alt = `${insuranceCaseId}_${documentId}_${pageNum}.pdf`;
  let src = `${config.cdnUrl}/pages/${alt}`;

  // Get rotations
  if ( page.annotations && page.annotations.length ){
    const manualRotation = page.annotations.find((annotation:IAnnotation) => annotation.name === 'manualRotation');
    if ( manualRotation && manualRotation.value ) src += `?rotation=${manualRotation.value}`;
  }

  return (
    <embed
      src={src}
      type="application/pdf"
      style={{
        width: '100%',
        height: '100vh'
      }}
    />
  )
}

export default PagePdf;
