import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Mui
import { Box, Container } from '@mui/material';
// 
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// 
import SharesList from './SharesList';
import SharesFab from './SharesFab';

const CaseSharesPage:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseSharesView, { caseId: Number(caseId) });
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
      <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
        <SharesList />
      </Container>
      <SharesFab />
    </Box>
  )
}

export default CaseSharesPage;
