import { FC, Fragment } from 'react';
// Models
import { IDocumentExport } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.models';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectDocumentsExports } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.selectors';
// Components
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
// 
import DocumentExportItem from './DocumentExportItem';

const DocumentsExportsList:FC = () => {
  const { t } = useTranslation('common');
  // State
  const documentsExports:IDocumentExport[] | null = useAppSelector(selectDocumentsExports);

  if ( !documentsExports ) return <Loader />;
  if ( !documentsExports.length ) return <Message text={t('pages.adminPages.exportMaterialsPage.noRecords')} />;
  return (
    <Fragment>
      {documentsExports.map((documentExport:IDocumentExport) => (
        <DocumentExportItem
          key={`Record export item ${documentExport.id}`}
          documentExport={documentExport}
        />
      ))}
    </Fragment>
  )
}

export default DocumentsExportsList;
