import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Models
import { RootState } from 'app/store';
import IAnnotation from 'app/models/Annotation';
import { IDocumentLink } from 'app/store/DMSDocuments/DMSDocuments.models';
// Async
import { downloadDocument, processDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Selectors
import { selectCollectionEntity } from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Toolbar, Tooltip, Typography } from '@mui/material';
// Icons
import { Archive as ArchiveIcon } from '@mui/icons-material';
// Compoentns
import DocumentName from 'app/components/DocumentName';
import { IconButton, Button } from 'app/components/Mui';
import { InfoBlock, MessageError } from 'app/components/Utilities';
// Constants
import { SUPPORTED_PREVIEW_FILE_FORMATS } from 'app/App.constants';
// i18next
import { useTranslation } from 'react-i18next';

const DocumentPDFPage:FC = () => {
  const { t } = useTranslation('common');

  const { documentId } = useParams<{ caseId:string; documentId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const documentEntity = useAppSelector((state:RootState) => selectDocumentEntity(state, {
    documentId: Number(documentId)
  }));
  const collectionEntity = useAppSelector((state:RootState) => selectCollectionEntity(state, {
    collectionId: documentEntity.collectionId
  }));

  const links = useMemo(() => {
    if ( !documentEntity.links ) return {};
    return documentEntity.links.reduce((acc:Record<string, IDocumentLink>, cur:IDocumentLink) => {
      acc[cur.rel] = cur;
      return acc;
    }, {});
  }, [documentEntity.links]);

  const handleClick = () => {
    if ( !links.download ) return;

    dispatch(downloadDocument({
      url: links.download.href,
      documentName: documentEntity.name
    }));
  }

  const handleProcessDocument = () => {
    dispatch(processDocument(documentEntity.id));
  }

  const href = (links.preview && links.preview.href) || '';
  const isProcessHintAndActionShown = collectionEntity?.applyOcr && documentEntity.processing?.status !== 'processing';

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Toolbar sx={{ py: 2, height: 'initial' }}>
        <Box sx={{ flexGrow: 1 }}>
          {isProcessHintAndActionShown ? (
            <Typography variant="caption" color="red">Document should be processed first</Typography>
          ) : null}
          <DocumentName />
          {documentEntity.annotations && documentEntity.annotations.length ?
            documentEntity.annotations.map((annotation:IAnnotation, index) => (
              <InfoBlock
                key={`annotation item ${index}`}
                label={annotation.name}
                value={annotation.value || 'N/A'}
                direction="row"
              />
            ))
          : null}
        </Box>
        {isProcessHintAndActionShown ? (
          <Button
            sx={{ mr: 2 }}
            name="Process docuemnt"
            onClick={handleProcessDocument}
            variant="outlined"
            size="small"
          >Process</Button>
        ) : null}
        <Tooltip title={t('labels.download')}>
          <IconButton
            name={`Download ${documentEntity.id}`}
            onClick={handleClick}
          ><ArchiveIcon /></IconButton>
        </Tooltip>
      </Toolbar>
      {SUPPORTED_PREVIEW_FILE_FORMATS.includes(documentEntity.fileFormat) ? (
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <embed
            src={href}
            type="application/pdf"
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </Box>
      ) : (
        <MessageError
          title={t('pages.staffPages.caseDetailsPage.previewNotAvailable')}
          text=""
          status={500}
        />
      )}
    </Box>
  )
}

export default DocumentPDFPage;
