import React, { Fragment, useState } from 'react';
// Models
import { IMyUser } from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getConversationCases } from 'app/store/Cases/Cases.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectConversationCases } from 'app/store/Cases/Cases.selectors';
import { selectTotalUnseenMessages } from 'app/store/Conversations/Conversations.selectors';
// Mui
import {
  ClickAwayListener,
  Badge, Paper, Popper
} from '@mui/material';
// Icon
import { Notifications as NotificationsIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// 
import ConversationList from './ConversationList';
import UserRoles from 'app/types/UserRoles';

const UnseenConversations = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const conversationCases = useAppSelector(selectConversationCases);
  const totalUnseenMessages = useAppSelector(selectTotalUnseenMessages);

  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    if ( !conversationCases || !conversationCases.length ){
      dispatch(getConversationCases({}));
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  const isMyRoleAdminOrStaff = myUser?.role === UserRoles.Admin || myUser?.role === UserRoles.Staff;

  if ( !isMyRoleAdminOrStaff ) return null;
  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClose}>
        <IconButton
          name="Toggle unseen conversation"
          onClick={handleClick}
          aria-label="Unseen conversation"
        >
          <Badge aria-label="Unseen conversation" badgeContent={totalUnseenMessages} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </ClickAwayListener>
      <Popper
        open={open}
        anchorEl={anchorEl}
        sx={{ zIndex: 1 }}
        placement="bottom-end"
      >
        <Paper sx={{ minWidth: '400px', maxWidth: '600px' }}>
          <ConversationList />
        </Paper>
      </Popper>
    </Fragment>
  )
}

export default UnseenConversations;
