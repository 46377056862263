import { FC, useEffect } from 'react';
import { push } from 'connected-react-router';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Selectors
import { selectEpisodeLegends } from 'app/store/currentUser/currentUserSelectors';
// Mui
import { Container, Paper } from '@mui/material';
// 
import LegendsListItem from './LegendsListItem';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import { useParams } from 'react-router-dom';
import { Message } from 'app/components/Utilities';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

const LegendsList:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const legends = useSelector(selectEpisodeLegends);
  const trialMode = useSelector(selectTrialMode);

  useEffect(() => {
    if ( trialMode ) dispatch(push({ search: `tab=calendar` }));

    Mixpanel.track(MixpanelTracks.CaseTimelineLegendView, { caseId: Number(caseId) });
    // eslint-disable-next-line
  }, [trialMode]);

  if ( !legends || !Object.keys(legends).length ) return <Message text="No legend(s)" />;
  return (
    <Container maxWidth="sm" style={{ paddingTop: '16px' }}>
      <Paper variant="outlined">
        {Object.keys(legends).map((key:string) => (
          <LegendsListItem
            key={`legend-item-${key}`}
            legend={legends[key]}
          />
        ))}
      </Paper>
    </Container>
  )
}

export default LegendsList;
