// Types
import Reducers from "app/types/Reducers";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $post } from 'app/utilities/HttpClient';

const _url:string = '/content';

// Set cloudfront cookies
export const setCloudfrontCookies = asyncThunkHandler(
  `${Reducers.Content}/Set cloudfront cookies`,
  async (data:any, { signal, fulfillWithValue,  }) => {
    await $post(`${_url}/actions/setCloudfrontCookies`, data, {
      credentials: 'include',
      signal
    });
    return fulfillWithValue('Cloudfront cookies was setted');
  }
);

export const generatePresignedUrls = asyncThunkHandler(
  `${Reducers.Content}/Generate presigned urls`,
  async (data:any) => {
    const response:Response = await $post(`${_url}/uploads/presignedUrls`, data);
    return response.json();
  }
);
