import { ChangeEvent, FC, Fragment, useState } from 'react';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteInsuranceCase } from 'app/store/Cases/Cases.async';
// Selectors
import { selectLoading } from 'app/store/Cases/Cases.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCase: IInsuranceCase;
}

const CaseDeleteDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCase
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const [ value, setValue ] = useState('');

  const handleClick = async () => {
    try {
      await dispatch(deleteInsuranceCase(insuranceCase.id)).unwrap();
    } catch(error){}
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const isSameCaseName = insuranceCase.name === value;

  const actions = (
    <Fragment>
      <Button
        name="Cance delete case dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Submit delete case dialog"
        disabled={!isSameCaseName}
        onClick={handleClick}
        loading={loading}
        variant="contained"
        color="error"
      >{t('labels.delete')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.staffPages.casesPage.caseDeleteDialogTitle')}
      actions={actions}
    >
      <Typography sx={{ whiteSpace: 'pre-wrap' }}>
        <Trans
          t={t}
          i18nKey="pages.staffPages.casesPage.caseDeleteDialogText1"
          components={{ b: <b /> }}
          values={{ caseName: insuranceCase.name }}
        />
      </Typography>
      <Typography sx={{ whiteSpace: 'pre-wrap' }}>
        <Trans
          t={t}
          i18nKey="pages.staffPages.casesPage.caseDeleteDialogText2"
          components={{ b: <b /> }}
          values={{ caseName: insuranceCase.name }}
        />
      </Typography>
      <Input
        label={t('labels.caseName')} name="caseName" id="caseName" value={value}
        onChange={handleChange}
      />
    </Dialog>
  )
}

export default CaseDeleteDialog;