import { FC } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
// Mui
import { Box } from '@mui/material';
// Components
import { Message } from 'app/components/Utilities';
// Pages
import MessagesView from 'app/pages/Staff/CaseDetailsPage/MessagesView';
// i18next
import { useTranslation } from 'react-i18next';

const ConversationsRouting:FC = () => {
  const { t } = useTranslation('common');

  const { path } = useRouteMatch<{ path:string }>();
  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Switch>
        <Route exact path={path} component={() => <Message text={t('pages.staffPages.caseDetailsPage.chooseConversation')} />} />
        <Route path={`${path}/:conversationId`} component={() => <MessagesView />} />
      </Switch>
    </Box>
  )
}

export default ConversationsRouting;
