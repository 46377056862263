import { FC } from 'react';
// Models
import ITemplate from 'app/models/Template';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredTemplates } from 'app/store/Templates/Templates.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import TemplatesListItem from './TemplatesListItem';
// i18next
import { useTranslation } from 'react-i18next';

const TemplatesList:FC = () => {
  const { t } = useTranslation('common');

  const templates:ITemplate[] | null = useAppSelector(selectFilteredTemplates);

  if ( !templates ) return <Loader />;
  if ( !templates.length ) return <Message text={t('pages.staffPages.caseDetailsPage.noTemplates')} />;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {templates.map((template:ITemplate) => (
        <TemplatesListItem
          key={`Template item ${template.id}`}
          template={template}
        />
      ))}
    </Box>
  )
}

export default TemplatesList;
