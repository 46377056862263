import { useEffect, useRef, useState } from 'react';

type IntersectionObserverOptions = {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
};

type IntersectionObserverResult = [React.RefObject<Element>, boolean];

function useIntersectionObserver(
  options: IntersectionObserverOptions
): IntersectionObserverResult {
  const targetRef = useRef<Element>(null);

  const [ isIntersecting, setIsIntersecting ] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if ( targetRef.current ) observer.observe(targetRef.current);

    return () => {
      if ( targetRef.current ) observer.unobserve(targetRef.current);
    };
  }, [options]);

  return [targetRef, isIntersecting];
}

export default useIntersectionObserver;