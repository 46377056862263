import NoData from 'app/assets/no-data.jpeg';

import { FC } from 'react';
// Mui
import { Typography, Box } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const CasesNoData:FC = () => {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4, paddingTop: 10 }}>
      <Box
        sx={{
          width: '200px',
          height: '200px',
          objectFit: 'cover',
          borderRadius: '50%',
        }}
        component="img"
        src={NoData}
        alt={t('labels.noCases')}
      />
      <Typography variant="h5">{t('labels.noCases')}</Typography>
    </Box>
  );
}

export default CasesNoData;
