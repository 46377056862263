import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import ILabel from 'app/models/Label';
import { IBatch, IFileError } from './DMSBatches.models';
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Selectors
import {
  selectEntities as selectCollectionEntities
} from 'app/store/DMSCollections/DMSCollections.selectors';
import {
  selectDocumentsIds,
  selectDocumentsEntities
} from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Utilities
import { sortByCreationDate } from 'app/utilities/SortBy';

export const selectIds = (state:RootState) => state[Reducers.DMSBatches].ids;
export const selectEntities = (state:RootState) => state[Reducers.DMSBatches].entities;
export const selectFilter = (state:RootState) => state[Reducers.DMSBatches].filter;
export const selectLoading = (state:RootState) => state[Reducers.DMSBatches].loading;
// ToDO
export const selectFilesErrors = (state:RootState) => state[Reducers.DMSBatches].filesErrors;

export const selectBatchEntity = createSelector(
  [
    selectEntities,
    (_, props:{ batchId:string }) => props
  ],
  (entities:any, { batchId }) => entities[batchId]
);

export const selectBatches = createSelector(
  [
    selectIds,
    selectEntities
  ],
  ( ids:string[] | null, entities:Record<string, IBatch>) => {
    if ( !ids ) return null;

    const batches:IBatch[] = [...ids].map((id:string) => entities[id]);

    return batches.sort((a:IBatch, b:IBatch) => sortByCreationDate(a, b, 'asc'))
  }
);

export const selectFileError = createSelector(
  [
    selectFilesErrors,
    (_, props:{ index:number }) => props
  ],
  (filesErrors:(IFileError | null)[] | null, { index }) => {
    if ( !filesErrors ) return null;
    return filesErrors[index];
  }
);

export const selectFilteredBatches = createSelector(
  [
    selectDocumentsIds,
    selectDocumentsEntities,
    selectFilter,
    selectBatches
  ],
  ( documentIds:number[] | null, documentEntities:Record<number, IDocument>, { search }, batches:IBatch[] | null ) => {
    if ( !batches || !documentIds ) return null;

    const skipEmptyBatches = batches.filter((batch:IBatch) => batch.documentIds.length);

    if ( !search ) return skipEmptyBatches;

    const searchToLowerCase = search.toLocaleLowerCase();

    return skipEmptyBatches
      .map((batch:IBatch) => {
        const documentIds = batch.documentIds.filter((id:number) => {
          const documentEntity = documentEntities[id];

          let hasSearch = documentEntity.name.toLowerCase().includes(searchToLowerCase);

          if ( !hasSearch ){
            const filteredLabels = documentEntity.labels && documentEntity.labels.length
              ? documentEntity.labels.filter((label:ILabel) => label.name.toLowerCase().includes(searchToLowerCase))
              : []
            ;
            hasSearch = Boolean(filteredLabels.length);
          }

          return hasSearch;
        });
        return { ...batch, documentIds };
      })
      .filter((batch:IBatch) => batch.documentIds.length)
  }
);

export const selectFilteredBatchesByCollection = createSelector(
  [
    selectDocumentsEntities,
    selectFilter,
    selectFilteredBatches
  ],
  (documentEntities:Record<number, IDocument>, { collectionId }, batches:IBatch[] | null ) => {
    if ( !batches ) return null;

    const noCollectionId = typeof collectionId === 'undefined' || collectionId === 0;

    if ( noCollectionId ) return batches;

    return batches
      .map((batch:IBatch) => {
        const documentIds = batch.documentIds.filter((id:number) => {
          const documentEntity = documentEntities[id];

          const hasCollection = noCollectionId ? true : documentEntity.collectionId === collectionId;

          return hasCollection;
        });
        return { ...batch, documentIds };
      })
      .filter((batch:IBatch) => batch.documentIds.length)
  }
);

export const selectBatchTotalPages = createSelector(
  [
    selectDocumentsEntities,
    (_, props:{ documentIds:number[] }) => props
  ],
  (documentEntities:Record<number, IDocument>, { documentIds }) => {
    return documentIds.reduce((acc:number, cur:number) => {
      const documentEntity = documentEntities[cur];
      return documentEntity.numberOfPages ? acc + documentEntity.numberOfPages : acc;
    }, 0);
  }
);

export const selectCollectionTotalDocuments = createSelector(
  [
    selectDocumentsEntities,
    selectFilteredBatches
  ],
  ( documentEntities:Record<number, IDocument>, batches:IBatch[] | null ) => {
    if ( !batches ) return null;

    const result:Record<number, number> = {}
    for ( let batch of batches ){
      if ( !batch.documentIds.length ) continue;
      for ( let documentId of batch.documentIds ){
        const documentEntity = documentEntities[documentId];
        if ( result[documentEntity.collectionId] ){
          result[documentEntity.collectionId] = result[documentEntity.collectionId] + 1;
        } else {
          result[documentEntity.collectionId] = 1;
        }
      }
    }
    return result;
  }
);

export const selectCollectionTotalPages = createSelector(
  [
    selectCollectionEntities,
    selectDocumentsEntities,
    selectFilter,
    selectFilteredBatchesByCollection
  ],
  (collectionEntities:Record<number,ICollection>, documentEntities:Record<number, IDocument>, { collectionId }, batches:IBatch[] | null) => {
    if ( !batches ) return 0;

    if ( collectionId ){
      const collectionEntity = collectionEntities[collectionId];
      if ( collectionEntity && !collectionEntity.applyOcr ) return null;
    }

    return batches.reduce((acc:number, cur:IBatch) => {
      if ( cur.documentIds && cur.documentIds.length ){
        const total = cur.documentIds.reduce((a:number, c:number) => {
          const documentEntity = documentEntities[c];
          if ( !documentEntity.deleted && documentEntity.ocred && documentEntity.numberOfPages ){
            a = a + documentEntity.numberOfPages;
          }
          return a;
        }, 0);
        acc = acc + total
      }
      return acc;
    }, 0);
  }
);

// ToDO: Will be removed once page store will be reworked
export const selectBatchesWithDocuments = createSelector(
  [
    selectIds,
    selectEntities
  ],
  (ids:string[] | null, entities:Record<string, IBatch>) => {
    if ( !ids ) return [];
    return ids
      .map((id:string) => entities[id])
      .filter((batch:IBatch) => batch.documents && batch.documents.length)
    ;
  }
)
