import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IBillingOrder from 'app/models/BillingOrder';
// Async
import { getOrders, createOrder, updateOrder, completeOrder, cancelOrder } from './BillingOrders.async';

interface IState {
  orders: IBillingOrder[] | null;
  ordersTotal: number;
  params: {
    limit: number;
    offset: number,
    accountId: string;
    status: string | 'open' | 'completed' | 'cancelled'
  };
  loading: boolean;
};

const initialState:IState = {
  orders: null,
  ordersTotal: 0,
  params: {
    limit: 100,
    offset: 0,
    accountId: '',
    status: ''
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.BillingOrders,
  initialState,
  reducers: {
    setParams: (state, action:PayloadAction<any>) => {
      const params = action.payload;
      state.params = Object.keys(state.params).reduce((acc:any, cur:any) => {
        acc[cur] = (state.params as any)[cur];
        if ( typeof params[cur] !== 'undefined' ) acc[cur] = params[cur];
        return acc;
      }, {});
    }
  },
  extraReducers(builder) {
    // Get orders
    builder.addCase(getOrders.pending, (state) => {
      state.orders = null;
      state.ordersTotal = 0;
    });
    builder.addCase(getOrders.fulfilled, (state, action:PayloadAction<{ data:IBillingOrder[], total:number }>) => {
      const { data, total } = action.payload;
      state.orders = data;
      state.ordersTotal = total;
    });

    // Create order
    builder.addCase(createOrder.pending, (state) => {
      state.loading = true
    });
    builder.addCase(createOrder.fulfilled, (state, action:PayloadAction<IBillingOrder>) => {
      if ( state.orders ){
        state.orders = [...state.orders, action.payload];
        state.ordersTotal = state.ordersTotal + 1;
      }
    });

    // Update order
    builder.addCase(updateOrder.pending, (state) => {
      state.loading = true
    });
    builder.addCase(updateOrder.fulfilled, (state, action:PayloadAction<IBillingOrder>) => {
      if ( state.orders ){
        state.orders = state.orders.map((order:IBillingOrder) => {
          if ( order.id === action.payload.id ) return {...order, ...action.payload};
          return order;
        });
      }
    });

    // Complete order
    builder.addCase(completeOrder.pending, (state) => {
      
    });
    builder.addCase(completeOrder.fulfilled, (state, action:PayloadAction<IBillingOrder>) => {
      if ( state.orders ){
        state.orders = state.orders.map((order:IBillingOrder) => {
          if ( order.id === action.payload.id ) return {...order, ...action.payload};
          return order;
        })
      }
    });
    // Cancel order
    builder.addCase(cancelOrder.pending, (state) => {
      
    });
    builder.addCase(cancelOrder.fulfilled, (state, action:PayloadAction<IBillingOrder>) => {
      if ( state.orders ){
        state.orders = state.orders.map((order:IBillingOrder) => {
          if ( order.id === action.payload.id ) return {...order, ...action.payload};
          return order;
        })
      }
    });

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  },
});

export const BillingOrdersActions = slice.actions;

export default slice.reducer;
