import { useMemo } from "react";
// Types
import UserRoles from "app/types/UserRoles";
// Store
import { useAppSelector } from "./useStore";
// Selectors
import { selectRequestedUrl } from "app/store/AppUI/AppUI.selectors";
import { selectMyUser } from "app/store/Users/Users.selectors";
import { selectAuthenticated } from "app/store/Auth/Auth.selectors";

const useRedirectPath = () => {
  // State
  const requestedUrl = useAppSelector(selectRequestedUrl);
  const authenticated = useAppSelector(selectAuthenticated);
  const myUser = useAppSelector(selectMyUser);
  // ToDO: For some reason this selector fire multiple reloads
  // const pendingTermsAndConditions = useAppSelector((state:RootState) => selectPendingTermsAndConditionsByScope(state, {
  //   scope: 'general'
  // }));

  const myUserRolePath = useMemo(() => {
    if ( !myUser ) return null;
    if ( [UserRoles.Admin, UserRoles.Staff].includes(myUser.role) ) return '/admin';
    if ( [UserRoles.Presenter, UserRoles.Client].includes(myUser.role) ) return '/presenter';
    return `/${myUser.role}`;
    // eslint-disable-next-line
  }, [myUser]);

  const termsAndConditionsPath = useMemo(() => {
    if ( !myUser || myUser.role === UserRoles.AccountManager ) return null;
    if ( !myUser.pendingTermsAndConditions || !myUser.pendingTermsAndConditions.length ) return null;
    const generalTermsAndConditions = myUser.pendingTermsAndConditions.filter((t:any) => t.scope === 'general');
    return generalTermsAndConditions.length ? '/terms-and-conditions' : null;
  }, [myUser]);

  const redirectPath = useMemo(() => {
    if ( authenticated ){
      if ( termsAndConditionsPath ) return termsAndConditionsPath;
      if ( myUserRolePath ){
        if ( requestedUrl && requestedUrl.includes(myUserRolePath) ) return requestedUrl;
        return myUserRolePath;
      }
      return myUserRolePath;
    }
    return '/login';
    // eslint-disable-next-line
  }, [myUserRolePath, termsAndConditionsPath, requestedUrl]);

  return [ myUserRolePath, termsAndConditionsPath, redirectPath ];
}

export default useRedirectPath;
