import { FC, useLayoutEffect, useRef } from 'react';
// Mui
import { Box } from '@mui/material';
// Layouts
import { FilesToolbar, FilesFilter, AdditionalTools } from 'app/layouts/FilesTools';
// 
import FilesList from './FilesList';
import FilesStapleFab from './FilesStapleFab';
import FilesScroll from './FilesScroll';
import { useParams } from 'react-router-dom';

const DocumentPagesPage:FC = () => {
  const { documentId } = useParams<{ documentId:string }>();

  const listRef = useRef<any>();

  useLayoutEffect(() => {
    const containerElem = document.querySelector('.scrollListContainer');
    if ( !containerElem ) return;
    containerElem.scrollTop = 0;
    // eslint-disable-next-line
  }, [documentId]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <FilesToolbar listRef={listRef} />
          <FilesFilter />
          <FilesList listRef={listRef} />
        </Box>
        <AdditionalTools listRef={listRef} />
      </Box>
      <FilesScroll listRef={listRef} />
      <FilesStapleFab />
    </Box>
  )
}

export default DocumentPagesPage;
