import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getServices } from 'app/store/BillingServices/BillingServices.async';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import ServicesList from './ServicesList';
import ServicesFab from './ServicesFab';
// i18next
import { useTranslation } from 'react-i18next';

const BillingOrdersPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getServices({}));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('labels.services')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <ServicesList />
        </Container>
      </Box>
      <ServicesFab />
    </Fragment>
  )
}

export default BillingOrdersPage;
