import { FC } from 'react';
// Models
import { IBillingOrderLine } from 'app/models/BillingOrder';
// Mui
import { TableRow, TableCell } from '@mui/material';

type Props = {
  line: IBillingOrderLine
};

const OrderLineItem:FC<Props> = ({
  // Props
  line
}) => {
  return (
    <TableRow>
      <TableCell>{line.service.name}</TableCell>
      <TableCell>{line.service.description || '-'}</TableCell>
      <TableCell>{line.quantity}</TableCell>
    </TableRow>
  )
}

export default OrderLineItem;
