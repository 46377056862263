// Types
import Reducers from 'app/types/Reducers';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $delete } from 'app/utilities/HttpClient';

const _url:string = '/presenterAssignments';

export const getAssignments = asyncThunkHandler(
  `${Reducers.CaseAssignments}/Get assignments`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const createAssignment = asyncThunkHandler(
  `${Reducers.CaseAssignments}/Create assignment`,
  async (data:any) => {
    const response:Response = await $post(_url, data);
    return response.json();
  }
);

export const deleteAssignment = asyncThunkHandler(
  `${Reducers.CaseAssignments}/Delete assignment`,
  async (data:any, { fulfillWithValue }) => {
    await $delete(_url, data);
    const [ userId ] = data.userIds;
    return fulfillWithValue(userId);
  }
);
