import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import UserPermissions from 'app/types/Permissions';
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser, selectMyUserHasPermission } from 'app/store/Users/Users.selectors';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import InvoiceFormDialog from './InvoiceFormDialog';

const InvoicesFab = () => {
  const { t } = useTranslation('common');

  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const myUserInvoiceCreateEnabled = useAppSelector((state) => selectMyUserHasPermission(state, {
    permission: UserPermissions.InvoiceCreate
  }));

  const { open, toggle } = useToggle();

  if ( myUser.role === UserRoles.Staff && !myUserInvoiceCreateEnabled ) return null;
  return (
    <Fragment>
      <Fab
        aria-label="Create invoice"
        name="Create invoice"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggle}
        color="primary"
        variant="extended"
      >{t('pages.adminPages.caseDetailsPage.createInvoice')}</Fab>
      {open ? (
        <InvoiceFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default InvoicesFab;
