import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import { sortByName } from 'app/utilities/SortBy';

export const selectReportSections = (state:RootState) => state[Reducers.ReportSections].reportSections;
export const selectReportSection = (state:RootState) => state[Reducers.ReportSections].reportSection;
export const selectLoading = (state:RootState) => state[Reducers.ReportSections].loading;

export const selectSortedReportSections = createSelector(
  [ selectReportSections ],
  ( reportSections:any[] | null ) => {
    if ( !reportSections ) return null;
    return [...reportSections].sort((a, b) => sortByName(a, b, 'name'))
  }
)
