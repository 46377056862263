import config from '../config';
import { matchPath } from 'react-router-dom';
import RequestMethods from 'app/types/RequestMethods';
import LocalStorageService from 'app/services/LocalStorage.service';
import IdempotencyKeyService from 'app/services/IdempotencyKey.service';
import { uuidv4 } from 'app/utilities/Utilities';

interface HttpResponse<T> extends Response {
  parseBody?: T
}

export default class HttpUtility {
  public async get<T>(
    path:string,
    args:RequestInit = { method: RequestMethods.Get }
  ):Promise<HttpResponse<T>> {
    return await this._http<T>(path, args);
  }

  public async post<T>(
    path:string,
    body?:any,
    args:RequestInit = {
      method: RequestMethods.Post,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<HttpResponse<T>> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this._http<T>(path, args);
  }

  public async put<T>(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Put,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<HttpResponse<T>> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this._http<T>(path, args);
  }

  public async patch<T>(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Patch,
      body: JSON.stringify(body)
    }
  ):Promise<HttpResponse<T>> {
    args['headers'] = { 'Content-Type': 'application/json' };
    return await this._http<T>(path, args);
  }

  public async delete<T>(
    path:string,
    body?:any,
    args:RequestInit = { method: RequestMethods.Delete }
  ):Promise<HttpResponse<T>> {
    if ( body ){
      args['headers'] = { 'Content-Type': 'application/json' };
      args['body'] = JSON.stringify(body);
    }
    return await this._http<T>(path, args);
  }

  private async _http<T>(path:string, args:RequestInit = {}):Promise<HttpResponse<T>> {
    const accessToken = await this._getToken();

    const key = uuidv4();

    IdempotencyKeyService.addKey(key);

    args.headers = {
      ...args.headers,
      'Idempotency-Key': key
    };

    if ( path.indexOf('/auth/login') === -1 ){
      args.headers = {
        ...args.headers,
        Authorization: `${LocalStorageService.getAccessTokenType()} ${accessToken}`
      };
    }
    const requestUrl = path.indexOf('/api') !== -1 ? path : `${config.apiUrl}${path}`;
    const request:RequestInfo = new Request(requestUrl, args);
    const response:HttpResponse<T> = await fetch(request);
    try {
      response.parseBody = await response.clone().json();
    } catch(error){}
    if ( !response.ok ){
      console.log(response);
      throw {...response.parseBody, status: response.status};
    }
    return response;
  }

  private async _getToken():Promise<string | null> {
    if ( !LocalStorageService.getAccessToken() || LocalStorageService.getAccessToken() === 'undefined' ) return null;
    if (
      LocalStorageService.isAccessTokenExpired() &&
      LocalStorageService.getRefreshToken()
    ){
      console.log('Token expired');
      const response = await fetch(`${config.apiUrl}/auth/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          grantType: 'refreshToken',
          refreshToken: LocalStorageService.getRefreshToken()
        })
      });
      const data = await response.json();
      LocalStorageService.setAuthCredential(data);

      const match:any = matchPath(window.location.pathname, { path: '/admin/cases/:caseId' });
      if ( match && match.params && match.params.caseId ){
        await fetch(`${config.apiUrl}/content/actions/setCloudfrontCookies`, {
          method: 'POST',
          headers: {
            'Authorization': `${LocalStorageService.getAccessTokenType()} ${data.accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ insuranceCaseId: match.params.caseId }),
          credentials: 'include'
        });
      }
    }
    return LocalStorageService.getAccessToken();
  }
}