import { FC, Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTemplateById } from 'app/store/Templates/Templates.selectors';
// Mui
import { Box, Typography, Tooltip } from '@mui/material';
// Icons
import {
  Add as AddIcon,
  VisibilityOutlined as VisibilityOutlinedIcon
} from '@mui/icons-material';
// Dialogs
import TemplatePreviewDialog from '../TemplatePreviewDialog';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  index: number;
  templateId: number;
}

const AvailableListItem:FC<Props> = ({
  // Props
  index, templateId
}) => {
  const { t } = useTranslation('common');
  // State
  const template = useAppSelector((state:RootState) => selectTemplateById(state, {
    templateId
  }));

  const { caseId, onUpdateItem } = useReportPresetContext()

  const { open, toggle } = useToggle();

  const handleClick = () => {
    onUpdateItem('add', templateId);
  }

  if ( !template ) return null;
  return (
    <Fragment>
      <Draggable draggableId={`${templateId}`} index={index}>
        {(provided) => (
          <Box
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'white',
              borderTop: '1px solid rgba(0,0,0,0.08)',
              py: 2,
              px: 6,
              '&:last-child': {
                borderBottom: '1px solid rgba(0,0,0,0.08)'
              }
            }}
            style={{
              ...provided.draggableProps.style,
            }}
          >
            <Box sx={{ flexGrow: 1, pr: 2 }}>
              <Typography variant="body1">{template.name}</Typography>
            </Box>
            <Box sx={{ whiteSpace: 'nowrap', mr: -1 }}>
              {caseId ? (
                <Tooltip title={t('labels.preview')}>
                  <IconButton
                    name={`Preview template ${template.id}`}
                    aria-label="Preview template"
                    onClick={toggle}
                    size="small"
                  ><VisibilityOutlinedIcon /></IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title={t('labels.add')}>
                <IconButton
                  aria-label="Add"
                  name={`Add template ${templateId}`}
                  onClick={handleClick}
                  size="small"
                  edge="end"
                ><AddIcon /></IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Draggable>
      {open ? (
        <TemplatePreviewDialog
          open={open}
          onClose={toggle}
          templateIds={[templateId]}
        />
      ) : null}
    </Fragment>
  )
}

export default AvailableListItem;
