import React from 'react';
// Types
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectPageStaples, selectIsLoading } from 'app/store/page/page.selectors';
// Components
import ColorPicker from 'app/components/ColorPicker';
import { Box } from '@mui/material';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import IInsuranceCase from 'app/models/Case';

type Props = {
  page: IPage;
};

const CardPageColors:React.FC<Props> = ({
  // Props
  page
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const insuranceCase = useSelector(selectInsuranceCase) as IInsuranceCase;
  const staplePages:Array<IPage> | null = useSelector(selectPageStaples)
  const loading = useSelector(selectIsLoading);

  const handleColorChange = (nextColor:string) => {
    if ( loading ) return;

    const prevColors = page.colors || [];
    const colors = prevColors.includes(nextColor) ? prevColors.filter((c:any) => c !== nextColor) : [...prevColors, nextColor];
    const message = colors.length > 0 ? pageMessages.updatePageColor : pageMessages.removePageColor;
    if ( staplePages && page.staple && page.staple.order === 1 ){
      const pagesToUpdate = staplePages.map((page:IPage) => ({
        caseId: insuranceCase.id,
        documentId: page.documentId,
        pageNum: page.pageNum,
        version: page.version,
        colors
      }));
      dispatch(PageActions.patchPages(pagesToUpdate, message));
    } else {
      dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
        version: page.version,
        colors
      }, message));
    }
  }

  if ( trialMode ) return (
    page.colors && page.colors.length > 0 ? (
      <Box sx={{ mb: 4 }}>
        <ColorPicker
          id="page-form"
          activeColors={page.colors}
        />
      </Box>
    ) : null
  );
  return (
    <Box sx={{ mb: 4 }}>
      <ColorPicker
        id="page-form"
        activeColors={page.colors}
        onClick={handleColorChange}
      />
    </Box>
  );
}

export default CardPageColors;
