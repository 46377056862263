import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLoading } from 'app/store/Templates/Templates.selectors';
// Mui
import { Box } from '@mui/material'
// Components
import TemplateTypeInfo from 'app/components/TemplateTypeInfo';
import { Input, Button, Switch, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  globalTemplateId?: number;
  templateId?: number;
  onClose: () => void;
}

const DialogTitle:FC<Props> = ({
  // Props
  globalTemplateId, templateId, onClose
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();

  const label = globalTemplateId ? t('labels.add') : templateId ? t('labels.save') : t('labels.create');
  // State
  const loading = useAppSelector(selectLoading);

  const { control, formState: { errors }, watch } = useFormContext();

  const watchType = watch('type');
  const watchDataType = watch('dataType');
  const watchLayout = watch('layout');

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      borderBottom: '1px solid rgba(0,0,0,0.08)',
      px: 6,
      py: 4
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 4
      }}>
        <Controller
          control={control} name="name"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              sx={{ width: 351 }}
              placeholder={t('labels.templateName')}
              required
              error={Boolean(errors.name)}
              helperText={(errors.name?.message as string) || ''}
              margin="none"
              size="small"
              fullWidth={false}
            />
          )}
        />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          height: 40,
          bgcolor: 'rgba(33, 33, 33, 0.08)',
          borderRadius: 1,
          px: 4
        }}>
          <TemplateTypeInfo type={watchType} dataType={watchDataType} layout={watchLayout} />
        </Box>
        {!caseId ? (
          <Controller
            control={control} name="copyToNewCases"
            render={({ field:{ onChange, ...otherField } }) => (
              <Switch
                {...otherField}
                label={t('labels.copyToNewCases')}
                onChange={(_, checked:boolean) => onChange(checked)}
              />
            )}
          />
        ) : null}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}>
        <Button
          name="Cancel template form dialog"
          onClick={onClose}
          variant="outlined"
        >{t('labels.close')}</Button>
        <LoadingButton
          name={`${label} template form dialog`}
          type="submit"
          loading={loading}
          variant="contained"
        >{label}</LoadingButton>
      </Box>
    </Box>
  )
}

export default DialogTitle;
