import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';

export const selectAssignments = (state:RootState) => state[Reducers.CaseAssignments].assignments;
export const selectLoading = (state:RootState) => state[Reducers.CaseAssignments].loading;

export const selectUserIsAssigned = createSelector(
  [
    selectAssignments,
    (_, props:{ userId:number }) => props
  ],
  (assignments:any, { userId }) => {
    if ( !assignments ) return false;
    return assignments.some((assign:any) => assign.userId === userId);
  }
);
