import config from '../../../../config';

import { FC, Fragment, useState } from 'react';
import dayjs from 'dayjs';
// Models
import { RootState } from 'app/store';
import IBillingInvoice from 'app/models/BillingInvoice';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { exportInvoicesToPDF } from 'app/store/BillingInvoices/BillingInvoices.async';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectCompanyNameByAccountId } from 'app/store/Clients/Clients.selectors';
import { selectIsInvoiceIdInBatch } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Tooltip, Typography, Paper } from '@mui/material';
// Icons
import { Receipt as ReceiptIcon } from '@mui/icons-material';
// Components
import { LoadingButton, Button, Checkbox } from 'app/components/Mui';
import Icon from 'app/components/Icon';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useLongDateTme from 'app/hooks/useLongDateTime';
// 
import ACHPaymentFormDialog from './ACHPaymentFormDialog';
import CreditCardPaymentFormDialog from './CreditCardPaymentFormDialog';
import InvoicesListItemLines from './InvoicesListItemLines';
import InvoicesListItemTransactions from './InvoicesListItemTransactions';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  invoice: IBillingInvoice;
};

const InvoicesListItem:FC<Props> = ({
  // Props
  invoice
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const isInvoiceIdInBatch = useAppSelector((state:RootState) => selectIsInvoiceIdInBatch(state, {
    invoiceId: invoice.id
  }));
  const companyName = useAppSelector((state:RootState) => selectCompanyNameByAccountId(state, {
    accountId: invoice.insuranceCase?.clientAccountId
  }));

  const { open:openACHPaymentFormDialog, toggle:toggleACHPaymentFormDialog } = useToggle();
  const { open:openCreditCardPaymentFormDialog, toggle:toggleCreditCardPaymentFormDialog } = useToggle();

  const longDueDate = useLongDateTme({
    dateValue: invoice.dueDate,
    local: true
  });

  const [ stateLoading, setStateLoading ] = useState(false);

  const handleChange = () => {
    dispatch(BillingInvoicesActions.toggleBatchInvoiceId(invoice.id));
  }

  const handleExportInvoiceToPDF = async () => {
    setStateLoading(true)
    try {
      await dispatch(exportInvoicesToPDF([invoice.id])).unwrap();
    } catch(error){} finally {
      setStateLoading(false)
    }
  }

  const dueDateIsInPast = invoice.status === 'sent' && invoice.dueDate
    ? dayjs(invoice.dueDate).isBefore(dayjs(), 'day')
    : false
  ;

  const statusLabels:Record<string, string> = {
    'sent': t('labels.outstanding'),
    'paid': t('labels.paid')
  }

  return (
    <Fragment>
      <Paper
        sx={{ p: 4, '& + &': { mt: 4 } }}
        variant="outlined"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          {invoice.description ? (
            <Typography variant="subtitle1">{invoice.description}</Typography>
          ) : null}
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="body2"
            sx={{ textTransform: 'capitalize' }}
          >
            {statusLabels[invoice.status]}
            {dueDateIsInPast ? (
              <Box component="span" color="red">{` (${t('pages.adminPages.billingInvoicesPage.overdue')})`}</Box>
            ) : null}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 4 }}>
          {longDueDate ? (
            <Tooltip title={t('labels.dueDate')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon icon="event" />
                <Typography variant="subtitle2">{longDueDate}</Typography>
              </Box>
            </Tooltip>
          ) : null}
          {companyName ? (
            <Tooltip title={t('labels.clientName')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon icon="team" />
                <Typography variant="subtitle2">{companyName}</Typography>
              </Box>
            </Tooltip>
          ) : null}
          {invoice.insuranceCase && invoice.insuranceCase.claimNumber  ? (
            <Tooltip title={t('labels.claimNumber')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon icon="case_number" />
                <Typography variant="subtitle2">{invoice.insuranceCase.claimNumber}</Typography>
              </Box>
            </Tooltip>
          ) : null}
          {invoice.invoiceNumber  ? (
            <Tooltip title={t('labels.invoiceNumber')}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <ReceiptIcon />
                <Typography variant="body2">{invoice.invoiceNumber}</Typography>
              </Box>
            </Tooltip>
          ) : null}
        </Box>

        <InvoicesListItemTransactions
          invoiceId={invoice.id}
          transactions={invoice.transactions}
        />
        <InvoicesListItemLines 
          invoiceId={invoice.id}
          invoiceDiscountAmount={invoice.discountAmount}
          lines={invoice.lines}
        />
        {invoice.status !== 'paid' ? (
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 4,
            pt: 4
          }}>
            <Checkbox
              value={isInvoiceIdInBatch}
              label={isInvoiceIdInBatch ? t('pages.adminPages.billingInvoicesPage.removeFrom') : t('pages.adminPages.billingInvoicesPage.addToBulk')}
              name={isInvoiceIdInBatch ? 'Remove from' : `Add to bulk ${invoice.id}`}
              onChange={handleChange}
              size="small"
            />
            <Box sx={{ flexGrow: 1 }} />
            {config.domain === '.com' ? (
              <Button
                name={`ACH payment ${invoice.id}`}
                onClick={toggleACHPaymentFormDialog}
                variant="contained"
                color="primary"
                size="small"
              >{t('pages.adminPages.billingInvoicesPage.achPayment')}</Button>
            ) : null}
            <Button
              name={`Pay with credit card ${invoice.id}`}
              onClick={toggleCreditCardPaymentFormDialog}
              variant="contained"
              color="primary"
              size="small"
            >{t('pages.adminPages.billingInvoicesPage.payWithCreditCard')}</Button>
            <LoadingButton
              name={`PDF Export ${invoice.id}`}
              onClick={handleExportInvoiceToPDF}
              variant="contained"
              color="primary"
              size="small"
              loading={stateLoading}
            >{t('pages.adminPages.billingInvoicesPage.pdfExport')}</LoadingButton>
          </Box>
        ) : null}
      </Paper>
      {openACHPaymentFormDialog ? (
        <ACHPaymentFormDialog
          open={openACHPaymentFormDialog}
          onClose={toggleACHPaymentFormDialog}
          invoiceId={invoice.id}
        />
      ) : null}
      {openCreditCardPaymentFormDialog ? (
        <CreditCardPaymentFormDialog
          open={openCreditCardPaymentFormDialog}
          onClose={toggleCreditCardPaymentFormDialog}
          invoiceId={invoice.id}
        />
      ) : null}
    </Fragment>
  )
}

export default InvoicesListItem;

