import { FC } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { updateAdditionalTools } from 'app/store/ui/uiSlice';
// Selectors
import { selectAdditionalToolsShown, selectAdditionalToolsHidden } from 'app/store/ui/uiSelectors';
// Mui
import {
  Paper, Box, Toolbar, IconButton, Typography, Tooltip,
  List, ListItem, ListItemText, ListSubheader
} from '@mui/material';
// Icons
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
// i18next
import { useTranslation } from 'react-i18next';
import AdditionalToolsNames from 'app/types/AdditionalToolsNames';

type Props = {
  onClose: () => void;
};

const ToolsSettings:FC<Props> = ({
  // Props
  onClose
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const toolsShown = useAppSelector((state:RootState) => selectAdditionalToolsShown(state, { toolsType: 'files' }));
  const toolsHidden = useAppSelector((state:RootState) => selectAdditionalToolsHidden(state, { toolsType: 'files' }));

  const handleClick = (toolName:AdditionalToolsNames) => () => {
    dispatch(updateAdditionalTools({ toolsType: 'files', toolName }))
  }

  return (
    <Paper
      sx={{
        position: 'absolute',
        top: 0, left: 0,
        width: 360,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderTop: 'none',
        borderBottom: 'none'
      }}
      variant="outlined"
    >
      <Toolbar sx={{ gap: 4 }}>
        <Typography sx={{ flexGrow: 1 }}>{t('labels.toolsSettings')}</Typography>
        <Tooltip title={t('labels.close')}>
          <IconButton
            onClick={onClose}
          ><CloseIcon /></IconButton>
        </Tooltip>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {toolsShown.length ? (
          <List
            subheader={
              <ListSubheader
                sx={{
                  bgcolor: '#F5F5F7',
                  color: 'rgab(0,0,0,0.68)',
                  fontSize: 10,
                  fontWeight: 500,
                  letterSpacing: '1.5px',
                  textTransform: 'uppercase'
                }}
              >{t('labels.shown')}</ListSubheader>
            }
          >
            {toolsShown.map((tool:AdditionalToolsNames) => (
              <ListItem
                key={tool}
                secondaryAction={
                  <IconButton
                    onClick={handleClick(tool)}
                  ><VisibilityIcon /></IconButton>
                }
              ><ListItemText sx={{ textTransform: 'capitalize' }} primary={toNormalLabel(tool)} /></ListItem>
            ))}
          </List>
        ) : null}
        {toolsHidden.length ? (
          <List
            subheader={
              <ListSubheader
                sx={{
                  bgcolor: '#F5F5F7',
                  color: 'rgab(0,0,0,0.68)',
                  fontSize: 10,
                  fontWeight: 500,
                  letterSpacing: '1.5px',
                  textTransform: 'uppercase'
                }}
              >{t('labels.hidden')}</ListSubheader>
            }
          >
            {toolsHidden.map((tool:AdditionalToolsNames) => (
              <ListItem
                key={tool}
                secondaryAction={
                  <IconButton
                    onClick={handleClick(tool)}
                  ><VisibilityOffIcon /></IconButton>
                }
                ><ListItemText sx={{ textTransform: 'capitalize' }} primary={toNormalLabel(tool)} /></ListItem>
            ))}
          </List>
        ) : null}
      </Box>
    </Paper>
  )
}

export default ToolsSettings;

const toNormalLabel = (label:string) => {
  return label.split('_').join(' ');
}
