import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IEpisode from "app/models/Episode";
// Async
import {
  getDocumentEpisodes,
  getEpisodes,
  createEpisode, updateEpisode, patchEpisode,
  deleteEpisode, deleteEpisodes
} from "./Episodes.async";

interface IState {
  episodes: {
    ids: number[] | null;
    entities: Record<number, IEpisode>;
    total: number;
  },
  params: {
    authorId: number | null;
    insuranceCaseId: number | null;
    fields: string;
    sort: string;
    limit: number;
    offset: number;
    type: string;
    'page.inWorkspace': string;
  };
  hasMore: boolean;
  gettingMore: boolean;
  loading: boolean;

  selectedEpisodeId: number | null;

  // ToDO: once `createAsyncThunk` wrapper will be created
  // status code will be available inside component
  statusCode: number | null;
  statusMessage: string | null;
};

const initialState:IState = {
  episodes: {
    ids: null,
    entities: {},
    total: 0
  },
  params: {
    authorId: null,
    insuranceCaseId: null,
    fields: 'insuranceCase',
    sort: 'insuranceCase.id,documentId,pageNum',
    limit: 20,
    offset: 0,
    type: '',
    'page.inWorkspace': 'all'
  },
  hasMore: false,
  gettingMore: false,
  loading: false,

  selectedEpisodeId: null,

  statusCode: null,
  statusMessage: null
};

const slice = createSlice({
  name: Reducers.Episodes,
  initialState,
  reducers: {
    setEpisodes: (state, action:PayloadAction<IEpisode[]>) => {
      const entities = action.payload.reduce((acc:Record<number, IEpisode>, cur:IEpisode) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      state.episodes.ids = Object.keys(entities).map(Number);
      state.episodes.entities = entities;
    },

    setSelectedEpisodeId: (state:IState, action:PayloadAction<number | null>) => {
      state.selectedEpisodeId = action.payload;
    },

    setStatusError: (state:IState, action:PayloadAction<{ statusCode:number | null, statusMessage:string | null }>) => {
      state.statusCode = action.payload.statusCode;
      state.statusMessage = action.payload.statusMessage;
    },

    // ToDO
    createEpisodeWithSocket: (state:IState, action:PayloadAction<IEpisode>) => {
      if ( state.episodes.ids ){
        state.episodes.ids = [...state.episodes.ids, action.payload.id];
      }
      state.episodes.entities[action.payload.id] = action.payload;
    },
    updateEpisodesWithSocket: (state:IState, action:PayloadAction<IEpisode[]>) => {
      for ( let episode of action.payload ){
        state.episodes.entities[episode.id] = episode;
      }
    },
    deleteEpisodesWithSocket: (state:IState, action:PayloadAction<number[]>) => {
      if ( state.episodes.ids ){
        state.episodes.ids = state.episodes.ids.filter((id:number) => !action.payload.includes(id));
      }
    },
    // End ToDO
    setParams: (state, action:PayloadAction<any>) => {
      const params = action.payload;
      state.params = Object.keys(state.params).reduce((acc:any, cur:any) => {
        acc[cur] = (state.params as any)[cur];
        if ( typeof params[cur] !== 'undefined' ) acc[cur] = params[cur];
        return acc;
      }, {});
    },

    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get document episodes
    builder.addCase(getDocumentEpisodes.fulfilled, (state, action:PayloadAction<{ total:number, data:IEpisode[] }>) => {
      const { data = [] } = action.payload;
      const entities = data.reduce((acc:Record<number, IEpisode>, cur:IEpisode) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      state.episodes.entities = {...state.episodes.entities, ...entities};
    });
    // Get episodes
    builder.addCase(getEpisodes.pending, (state, action:any) => {
      const { offset } = action.meta.arg;
      if ( offset === 0 ){
        state.episodes = initialState.episodes
      } else {
        state.params.offset = offset;
        state.gettingMore = true;
      }
    });
    builder.addCase(getEpisodes.fulfilled, (state, action:PayloadAction<{ total:number, data:IEpisode[] }>) => {
      const { total, data = [] } = action.payload;
      const entities = data.reduce((acc:Record<number, IEpisode>, cur:IEpisode) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      const ids = Object.keys(entities).map(Number);
      state.episodes.ids = state.episodes.ids ? [...state.episodes.ids, ...ids] : ids;
      state.episodes.entities = {...state.episodes.entities, ...entities};
      state.episodes.total = total;
      state.hasMore = ids.length === state.params.limit;
      state.gettingMore = false;
    });
    // Create episode
    builder.addCase(createEpisode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createEpisode.fulfilled, (state, action:PayloadAction<IEpisode>) => {
      if ( state.episodes.ids ){
        state.episodes.ids = [...state.episodes.ids, action.payload.id];
        state.episodes.entities[action.payload.id] = action.payload;
      }
    });
    // Update episode
    builder.addCase(updateEpisode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateEpisode.fulfilled, (state, action:PayloadAction<IEpisode>) => {
      if ( state.episodes.ids ){
        state.episodes.entities[action.payload.id] = action.payload;
      }
    });
    // Patch episode
    builder.addCase(patchEpisode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchEpisode.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.episodes.ids ){
        const episode = state.episodes.entities[action.payload.id];
        state.episodes.entities[action.payload.id] = {...episode, ...action.payload};
      }
    });
    // Delete episode
    builder.addCase(deleteEpisode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEpisode.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.episodes.ids ){
        state.episodes.ids = state.episodes.ids.filter((id:number) => id !== action.payload);
        // ToDO: Should be reworked as new Map() or new Set()
        const entities = {...state.episodes.entities};
        delete entities[action.payload];
        state.episodes.entities = entities;
      }
    });
    // Delete episodes
    builder.addCase(deleteEpisodes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEpisodes.fulfilled, (state, action:PayloadAction<number[]>) => {
      if ( state.episodes.ids ){
        state.episodes.ids = state.episodes.ids.filter((id:number) => !action.payload.includes(id));
        // ToDO: Should be reworked as new Map() or new Set()
        const entities = {...state.episodes.entities};
        for ( let id of action.payload ){
          delete entities[id];
        }
        state.episodes.entities = entities;
      }
    });

    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  }
});

export const EpisodesActions = slice.actions;

export default slice.reducer;
