import React from 'react';
import { useParams } from 'react-router-dom';
// Models
import { IPageDuplicate } from '@root/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { updateDuplicates } from 'app/store/Cases/Cases.async';
// Selectors
import { selectDuplicatesList, selectLoading } from 'app/store/Cases/Cases.selectors';
// MaterialUI
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Components
import PageCard from '@root/components/PageCard';
import useCaseStatus from 'app/hooks/useCaseStatus';
import { selectColumnValue } from 'app/store/ui/uiSelectors';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// i18next
import { useTranslation } from 'react-i18next';

const MAX_PAGES = 6;

type Props = {
  clusterKey: string;
  cluster: Array<IPageDuplicate>;
};

const DuplicatesClusterList:React.FC<Props> = ({
  // Props
  clusterKey, cluster
}) => {
  const { t } = useTranslation('common');

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();

  // State
  const columnValue:string = useAppSelector(selectColumnValue);
  const duplicates = useAppSelector(selectDuplicatesList);
  const loading:boolean = useAppSelector(selectLoading);

  const { isArchived } = useCaseStatus();
  
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);
  const [ showMore, setShowMore ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);
  const toggleShowMore = () => setShowMore((prevState:boolean) => !prevState);

  React.useEffect(() => {
    setShowMore(cluster.length <= MAX_PAGES);
    // eslint-disable-next-line
  }, [cluster.length]);


  const handleConfirm = async () => {
    if ( !duplicates ) return;
    try {
      const { [clusterKey]:currentCluster, ...duplicatesToUpdateMap } = duplicates;

      const duplicatesToUpdate = Object.values(duplicatesToUpdateMap);

      await dispatch(updateDuplicates({
        insuranceCaseId: Number(caseId),
        data: duplicatesToUpdate,
        message: t('pages.adminPages.duplicatesPage.clusterWasDeletedFromDuplicates')
      })).unwrap();
      
      Mixpanel.track(MixpanelTracks.ClusterMarkedNotADuplicate, {
        caseId: Number(caseId),
        notDuplicatePageIds: cluster.map((page:any) => `${page.documentId}:${page.pageNum}`)
      });

      toggleDialog();
    } catch(error){}
  };

  const xs:any = columnValue === 'auto' ? 12 : 12/Number(columnValue);
  const sm:any = columnValue === 'auto' ? 6 : 12/Number(columnValue);
  const md:any = columnValue === 'auto' ? 4 : 12/Number(columnValue);
  const lg:any = columnValue === 'auto' ? 3 : 12/Number(columnValue);
  const xl:any = columnValue === 'auto' ? 2 : 12/Number(columnValue);

  const width = window.innerWidth;

  const renderCluster = () => {
    return showMore ? cluster : cluster.slice(0, MAX_PAGES);
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right', paddingBottom: '16px' }}>
        {cluster.length > MAX_PAGES ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={toggleShowMore}
          >{showMore ? t('labels.showLess') : t('labels.showMore')}</Button>
        ) : null}
        {!isArchived ? (
          <Button
            style={{ marginLeft: '16px' }}
            color="error"
            size="small"
            onClick={toggleDialog}
          >{t('pages.adminPages.duplicatesPage.clusterNotDuplicate')}</Button>
        ) : null}
      </div>
      <Grid container spacing={2}>
        {renderCluster().map((page:IPageDuplicate) => (
          <Grid
            item
            key={`duplicate-cluster-item-${page.documentId}-${page.pageNum}`}
            className="card-grid-item"
            xs={xs} sm={sm} md={md} lg={lg} xl={xl}
          >
            <PageCard
              documentId={page.documentId}
              pageNum={page.pageNum}
              height={columnValue === 'auto' ? 450 : (width/Number(columnValue)*3/2) - 16}
            />
          </Grid>
        ))}
      </Grid>
      {dialogOpen ? (
        <ConfirmationDialog
          open={dialogOpen}
          onClose={toggleDialog}
          loading={loading}
          status={'initial'}
          title={t('pages.adminPages.duplicatesPage.clusterNotDuplicate')}
          content={t('pages.adminPages.duplicatesPage.clusterNotDuplicateConfirmation')}
          onConfirm={handleConfirm}
        />
      ) : null}
    </React.Fragment>
  )
}

export default React.memo(DuplicatesClusterList);
