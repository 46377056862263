import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
// Types
import Reducers from "app/types/Reducers";
// Reducers
import uiSlice from './ui/uiSlice';
import AppUiReducer from './AppUI/AppUI.slice';
import AppUINotificationsReduce from "./AppUINotifications/AppUINotifications.slice";
import AppUiDialogReducer from './AppUIDialog/AppUIDialog.slice';
import AppUIStatusReducer from './AppUIStatus/AppUIStatus.slice';
import AuthReducer from './Auth/Auth.slice';
import currentUserSlice from './currentUser/currentUserSlice';
import AccountsManagementReducer from './AccountsManagement/AccountsManagement.slice';
import BillingInvoicesReducer from './BillingInvoices/BillingInvoices.slice';
import BillingPackagesReducer from './BillingPackages/BillingPackages.slice';
import BillingOrdersReducer from './BillingOrders/BillingOrders.slice';
import BillingChargesReducer from "./BillingCharges/BillingCharges.slice";
import AnalyticsReducer from './Analytics/Analytics.slice';
import PresenterReducer from './Presenter/Presenter.slice';
import EpisodesReducer from './Episodes/Episodes.slice';
import EpisodeFactsReducer from './EpisodeFacts/EpisodeFacts.slice';
import MedicalRducer from './Medical/Medical.slice';
import ContentReducer from './Content/Content.slice';
import DMSDocumentsReducer from './DMSDocuments/DMSDocuments.slice';
import DMSBatchesReducer from './DMSBatches/DMSBatches.slice';
import CasesReducer from './Cases/Cases.slice';
import BillingServicesReducer from './BillingServices/BillingServices.slice';
import calendarSlice from './calendar/calendarSlice';
import ClientsReducer from './Clients/Clients.slice';
import TeamsReducer from './Teams/Teams.slice';
import ExportTemplatesReducer from './ExportTemplates/ExportTemplates.slice';
import CaseReportsReducer from './CaseReports/CaseReports.slice';
import DMSDocumentPagesReducer from './DMSDocumentPages/DMSDocumentPages.slice';
import CaseAssignmentsReducer from './CaseAssignments/CaseAssignments.slice';
import CaseSharesReducer from './CaseShares/CaseShares.slice';
import CaseAlertsReducer from './CaseAlerts/CaseAlerts.slice';
import UsersReducer from './Users/Users.slice';
import AuditReducer from './Audit/Audit.slice';
import ConversationsReducer from './Conversations/Conversations.slice';
import MessagesReducer from './Messages/Messages.slice';
import AccountsReducer from './Accounts/Accounts.slice';
import LabellingToolJobsReducer from "./LabellingToolJobs/LabellingToolJobs.slice";
import LabellingToolAnnotationsReducer from "./LabellingToolAnnotations/LabellingToolAnnotations.slice";
import TemplatesReducer from './Templates/Templates.slice';
import EpisodeAuthorsReducer from "./EpisodeAuthors/EpisodeAuthors.slice";
import ReportPresetsReducer from "./ReportPresets/ReportPresets.slice";
import SettingsReducer from "./Settings/Settings.slice";
import UsersManagementReducer from "./UsersManagement/UsersManagement.slice";
import IntegrationsReducer from "./Integrations/Integrations.slice";
import ReportSectionsReducer from "./ReportSections/ReportSections.slice";
import ReportSectionTemplatesReducer from "./ReportSectionTemplates/ReportSectionTemplates.slice";
import DMSDocumentsExportsReducer from "./DMSDocumentsExports/DMSDocumentsExports.slice";
import AIChatReducer from './AIChat/AIChat.slice';
import DMSCollectionsReducer from "./DMSCollections/DMSCollections.slice";
import DMSUploadsReducer from "./DMSUploads/DMSUploads.slice";
import RequestsReducer from "./Requests/Requests.slice";
import TermsConditionsReducer from './Terms/Terms.slice';
import InvoicesReducer from './Invoices/Invoices.slice';
import AIChatPromptsReducer from './AIChatPrompts/AIChatPrompts.slice';
// Api
import CasesApi from './Cases/Cases.api';
import DMSDocumentsApi from './DMSDocuments/DMSDocuments.api';
// ToDO
import AppReducer from 'app/store/TODO2_app/app.reducer';
import PageReducer from 'app/store/page/page.reducer';
import OLD_AppReducer from 'app/store/TODO1_app/app.reducer';
// End ToDO

const rootReducer = (history:History) => combineReducers({
  [Reducers.Ui]: uiSlice,
  [Reducers.AppUi]: AppUiReducer,
  [Reducers.AppUiNotifications]: AppUINotificationsReduce,
  [Reducers.AppUIDialog]: AppUiDialogReducer,
  [Reducers.AppUIStatus]: AppUIStatusReducer,
  [Reducers.Auth]: AuthReducer,
  [Reducers.CurrentUser]: currentUserSlice,
  [Reducers.AccountsManagement]: AccountsManagementReducer,
  [Reducers.BillingInvoices]: BillingInvoicesReducer,
  [Reducers.BillingPackages]: BillingPackagesReducer,
  [Reducers.BillingCharges]: BillingChargesReducer,
  [Reducers.Analytics]: AnalyticsReducer,
  [Reducers.Presenter]: PresenterReducer,
  [Reducers.Episodes]: EpisodesReducer,
  [Reducers.EpisodeFacts]: EpisodeFactsReducer,
  [Reducers.Content]: ContentReducer,
  [Reducers.Cases]: CasesReducer,
  [Reducers.CaseAssignments]: CaseAssignmentsReducer,
  [Reducers.CaseAlerts]: CaseAlertsReducer,
  [Reducers.Calendar]: calendarSlice,
  [Reducers.Clients]: ClientsReducer,
  [Reducers.Teams]: TeamsReducer,
  [Reducers.ExportTemplates]: ExportTemplatesReducer,
  [Reducers.CaseReports]: CaseReportsReducer,
  [Reducers.CaseShares]: CaseSharesReducer,
  [Reducers.BillingServices]: BillingServicesReducer,
  [Reducers.BillingOrders]: BillingOrdersReducer,
  [Reducers.Medical]: MedicalRducer,
  [Reducers.Users]: UsersReducer,
  [Reducers.Audit]: AuditReducer,
  [Reducers.Conversations]: ConversationsReducer,
  [Reducers.Messages]: MessagesReducer,
  [Reducers.Accounts]: AccountsReducer,
  [Reducers.LabellingToolJobs]: LabellingToolJobsReducer,
  [Reducers.LabellingToolAnnotations]: LabellingToolAnnotationsReducer,
  [Reducers.Templates]: TemplatesReducer,
  [Reducers.EpisodeAuthors]: EpisodeAuthorsReducer,
  [Reducers.ReportPresets]: ReportPresetsReducer,
  [Reducers.Settings]: SettingsReducer,
  [Reducers.UsersManagement]: UsersManagementReducer,
  [Reducers.Integrations]: IntegrationsReducer,
  [Reducers.ReportSections]: ReportSectionsReducer,
  [Reducers.ReportSectionTemplates]: ReportSectionTemplatesReducer,
  [Reducers.AIChat]: AIChatReducer,
  [Reducers.AIChatPrompts]: AIChatPromptsReducer,
  [Reducers.DMSCollections]: DMSCollectionsReducer,
  [Reducers.DMSBatches]: DMSBatchesReducer,
  [Reducers.DMSDocuments]: DMSDocumentsReducer,
  [Reducers.DMSDocumentPages]: DMSDocumentPagesReducer,
  [Reducers.DMSDocumentsExports]: DMSDocumentsExportsReducer,
  [Reducers.DMSUploads]: DMSUploadsReducer,
  [Reducers.Requests]: RequestsReducer,
  [Reducers.Terms]: TermsConditionsReducer,
  [Reducers.Invoices]: InvoicesReducer,

  [CasesApi.reducerPath]: CasesApi.reducer,
  [DMSDocumentsApi.reducerPath]: DMSDocumentsApi.reducer,

  // ToDO
  v3_app: AppReducer.reducer,
  app: OLD_AppReducer.reducer,
  page: PageReducer.reducer,
  router: connectRouter(history)
  // End ToDO
});

export default rootReducer;
