import { FC, Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
// i18next
import { Trans, useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteReportSection } from 'app/store/ReportSections/ReportSections.async';
// Selectors
import { selectLoading } from 'app/store/ReportSections/ReportSections.selectors';
// Mui
import {
  Theme,
  Divider,
  ListItem, ListItemText, ListItemSecondaryAction
 } from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Compoents
import { IconButton } from 'app/components/Mui';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import ReportSectionFormDialog from './ReportSectionFormDialog';

type Props = {
  reportSection: any;
}

const ReportSectionItem:FC<Props> = ({
  // Props
  reportSection
}) => {
  const { t } = useTranslation('common');

  const { pathname } = useLocation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { isArchived } = useCaseStatus();
  const { Menu, MenuItem, openMenu } = useMenu();

  const { open, toggle } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deleteReportSection(reportSection.id));
  }

  return (
    <Fragment>
      <ListItem>
        <ListItemText primary={reportSection.name} />
        <ListItemSecondaryAction>
          <IconButton
            name={`Report section dropdown ${reportSection.id}`}
            aria-label="Report section item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {/* Menu */}
      <Menu>
        <MenuItem
          name={`Preview report section ${reportSection.id}`}
          component={Link}
          to={`${pathname}/${reportSection.id}`}
          // @ts-ignore
          target="_blank"
        >{t('labels.preview')}</MenuItem>
        <MenuItem
          name={`Edit report section ${reportSection.id}`}
          disabled={isArchived}
          onClick={toggle}
        >{t('labels.edit')}</MenuItem>
        <Divider />
        <MenuItem
          name={`Delete report section ${reportSection.id}`}
          sx={{ color: (theme:Theme) => theme.palette.error.main }}
          disabled={isArchived}
          onClick={toggleConfirmationDialog}
        >{t('labels.delete')}</MenuItem>
      </Menu>
      {/* Form dialog */}
      {open ? (
        <ReportSectionFormDialog
          open={open}
          onClose={toggle}
          reportSectionId={reportSection.id}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.adminPages.caseDetailsPage.deleteReportSection')}
          content={(
            <Trans
              t={t} i18nKey="pages.adminPages.caseDetailsPage.deleteReportSectionConfirmation"
              components={{ strong: <strong /> }}
              values={{ name: reportSection.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default ReportSectionItem;
