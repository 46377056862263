import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.caseReports.create'),
  Update: i18n.t('notifications.caseReports.update'),
  Delete: i18n.t('notifications.caseReports.delete'),
  Share: i18n.t('notifications.caseReports.share')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.caseReports.create');
  Messages.Update = i18n.t('notifications.caseReports.update');
  Messages.Delete = i18n.t('notifications.caseReports.delete');
  Messages.Share = i18n.t('notifications.caseReports.share');
});