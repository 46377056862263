import React from 'react';
// Models
import { IPage } from '@root/models/Page';
// State
import { useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectIsLoading, selectStatus } from 'app/store/page/page.selectors';
// Mui
import { Box } from '@mui/material';
// Dialog
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Components
import { Button } from 'app/components/Mui';
import useCaseStatus from 'app/hooks/useCaseStatus';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';
import useToggle from 'app/hooks/useToggle';
import { useAppDispatch } from 'app/hooks/useStore';

type Props = {
  page: IPage;
}

const CardPageDelete:React.FC<Props> = ({
  // Props
  page
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const status:any = useSelector(selectStatus);
  const isLoading:boolean = useSelector(selectIsLoading);

  const { isArchived } = useCaseStatus();

  const { open, toggle } = useToggle();

  const handleConfirm = () => {
    if ( page.staple && page.staple.order === 1 ){

    } else {
      dispatch(PageActions.deletePage(page.documentId, page.pageNum));
    }
    // () => dispatch(DocumentAsync.deletePage(page.documentId, page.pageNum, page.staple))
  }

  if ( trialMode || (page.staple && page.staple.order > 1) || isArchived) return null;
  return (
    <React.Fragment>
      <Box sx={{ py: 4 }}>
        <Button
          name={`Delete page permanently ${page.documentId}-${page.pageNum}`}
          onClick={toggle}
          color="error"
          fullWidth
        >{t('dialogs.cardPage.deletePagePermanently')}</Button>
      </Box>
      {open ? (
        <ConfirmationDialog
          open={open}
          onClose={toggle}
          status={status}
          title={t('dialogs.cardPage.deletePagePermanently')}
          loading={isLoading}
          content={t('dialogs.cardPage.deletePagePermanentlyConfirmation')}
          onConfirm={handleConfirm}
          confirmLabel={t('dialogs.cardPage.deletePage')}
        />
      ) : null}
    </React.Fragment>
  )
}

export default CardPageDelete;
