import Quill from "quill";

const Embed = Quill.import('blots/embed');

const Parchment = Quill.import('parchment');
class SpanVariableElement extends Parchment.Embed {
  static blotName = 'spanVariable';
  static tagName = 'var';

  static create(value:string) {
    const node = super.create();
    node.className = 'ql-variable';
    node.textContent = value;
    return node;
  }

  static value(node:any) {
    return node.textContent;
  }
}

class SpanTabElement extends Embed {
  static blotName = 'spanTab';
  static tagName = 'span';
  static className = 'ql-tab'

  static create() {
    return super.create() as HTMLElement;
  }
}

Quill.register(SpanVariableElement);
Quill.register(SpanTabElement);
