import config from '../../config';

import React from 'react';
// Models
import IAnnotation from 'app/models/Annotation';

import { Box } from '@mui/material';

// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';

type Props = {
  documentId: number;
  pageNum: number;
  annotations?: Array<IAnnotation>;
};

const PagePdf:React.FC<Props> = ({
  // Props
  documentId, pageNum, annotations
}) => {
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;

  let src = `${config.cdnUrl}/pages/${insuranceCase.id}_${documentId}_${pageNum}.pdf`;
  // Get rotations
  if ( annotations && annotations.length ){
    const manualRotation = annotations.find((annotation:IAnnotation) => annotation.name === 'manualRotation');
    if ( manualRotation && manualRotation.value ) src += `?rotation=${manualRotation.value}`;
  }

  return (
    <Box sx={{
      width: '100%',
      position: 'relative',
      pt: '141.4%', // Proporional of a4 paper is 1:1.414
    }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <embed
          className='card-page-pdf'
          src={src}
          type="application/pdf"
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </Box>
    </Box>
  )
}

export default PagePdf;
