import { FC } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUiActions } from 'app/store/AppUI/AppUI.slice';
// Selectors
import { selectAIChatSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
// Components
import SidebarToggleButton from 'app/components/SidebarToggleButton';

const AIChatSidebarToggle:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const open = useAppSelector(selectAIChatSidebarOpen);

  const handleClick = () => {
    dispatch(AppUiActions.toggleAIChatSidebar());
  }

  return (
    <SidebarToggleButton
      name="Files sidebar toggle button"
      open={open}
      onClick={handleClick}
      styles={{
        mb: 18
      }}
    />
  )
}

export default AIChatSidebarToggle;
