import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { MuiOtpInput } from 'mui-one-time-password-input';
// Types
import GrantTypes from 'app/types/GrantTypes';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Asycn
import { login } from 'app/store/Auth/Auth.async';
// Selectors
import { selectMultiFactoryData, selectLoading } from 'app/store/Auth/Auth.selectors';
// Mui
import {
  Theme,
  Box, Container, Paper,
  Typography, FormHelperText
} from '@mui/material';
// Components
import Brand from 'app/components/Brand';
import LanguagePicker from 'app/components/LanguagePicker';
import { Button, LoadingButton } from 'app/components/Mui';
// i18next
import { Trans, useTranslation } from 'react-i18next';

interface IFormData {
  grantType: GrantTypes;
  code: string;
}

const VerificationPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const multiFactoryData = useAppSelector(selectMultiFactoryData);

  const { email, phone, channel, challengeId, username, password, accountId } = multiFactoryData;

  const loading = useAppSelector(selectLoading);

  const { register, control, handleSubmit, formState:{ errors } } = useForm<IFormData>({
    defaultValues: {
      grantType: GrantTypes.Mfa,
      code: ''
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const nextData:any = {
      ...data,
      challengeId,
      username,
      password
    };
    if ( accountId ) nextData['accountId'] = accountId;
    dispatch(login(nextData));
  });

  return (
    <Container sx={{ pt: 8 }} maxWidth="xs">
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Brand />
      </Box>
      <Paper
        sx={{ p: 8 }}
        variant="outlined"
        component="form"
        onSubmit={onSubmit}
        noValidate
      >
        <Typography sx={{ mb: 4 }} display="block">
          <Trans
            t={t}
            i18nKey={channel === 'email' ? 'pages.verificationPage.titleWithEmail' : 'pages.verificationPage.titleWithPhone'}
            values={{ email, phone }}
          />
        </Typography>
        {/* Hidden field */}
        <input {...register('grantType') as any} type="hidden" value={GrantTypes.Mfa} />
        {/* Code */}
        <Controller
          control={control} name="code"
          rules={{ validate: (value:string) => value.length === 6 }}
          render={({ field }) => (
            <Box>
              <MuiOtpInput
                {...field}
                sx={{
                  gap: 2,
                  mb: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme:Theme) => errors.code ? theme.palette.error.main : '#cacaca'
                  }
                }}
                length={6}
                margin="normal"
              />
              {errors.code ? (
                <FormHelperText error>{t('pages.verificationPage.codeNotValid')}</FormHelperText>
              ) : null}
            </Box>
          )}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
          <LoadingButton
            name="Verify form"
            loading={loading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >{t('pages.verificationPage.verify')}</LoadingButton>
          <Button
            name="Go to login"
            component={Link}
            to="/"
            fullWidth
          >{t('pages.verificationPage.goToLogin')}</Button>
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <LanguagePicker />
      </Box>
    </Container>
  )
}

export default VerificationPage;
