import { FC, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getConversation } from 'app/store/Conversations/Conversations.async';
import { getMessages } from 'app/store/Messages/Messages.async';
// Mui
import { Box } from '@mui/material';
// 
import MessagesToolbar from './MessagesToolbar';
import MessagesList from './MessagesList';
import MessagesForm from './MessagesForm';

const MessagesView:FC = () => {
  const { conversationId } = useParams<{ conversationId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    const conversationIdToNum = Number(conversationId);
    dispatch(getConversation(conversationIdToNum));
    dispatch(getMessages({
      conversationId: conversationIdToNum,
      limit: 1000
    }));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <MessagesToolbar />
      <Box sx={{ flexGrow: 1, bgcolor: 'rgba(0,0,0,0.08)', overflowY: 'auto' }}>
        <MessagesList />
      </Box>
      <MessagesForm />
    </Fragment>
  )
}

export default MessagesView;
