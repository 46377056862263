import { FC, Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTemplateById } from 'app/store/Templates/Templates.selectors';
// Mui
import { Paper, Box, Typography, Tooltip } from '@mui/material';
// Icons
import {
  DragIndicator as DragIndicatorIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Close as CloseIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import TemplateTypeInfo from 'app/components/TemplateTypeInfo';
// Dialogs
import TemplatePreviewDialog from '../TemplatePreviewDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  index: number;
  templateId: number;
}

const SelectedListItem:FC<Props> = ({
  // Props
  index, templateId
}) => {
  const { t } = useTranslation('common');
  // State
  const template = useAppSelector((state:RootState) => selectTemplateById(state, {
    templateId
  }));

  const { caseId, onUpdateItem } = useReportPresetContext()

  const { open, toggle } = useToggle();

  const handleClick = () => {
    onUpdateItem('remove', templateId);
  }

  if ( !template ) return null;
  return (
    <Fragment>
      <Draggable draggableId={`${templateId}`} index={index}>
        {(provided) => (
          <Paper
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            sx={{
              display: 'flex',
              p: 4
            }}
            style={{
              ...provided.draggableProps.style,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
              <DragIndicatorIcon />
            </Box>
            <Box sx={{ flexGrow: 1, pr: 2 }}>
              <Typography sx={{ mb: 2 }} variant="h6">{template.name}</Typography>
              <TemplateTypeInfo
                type={template.type}
                dataType={template.dataType}
                layout={template.layout}
              />
            </Box>
            <Box sx={{ mt: -2, mr: -2 }}>
              {caseId ? (
                <Tooltip title={t('labels.preview')}>
                  <IconButton
                    name={`Preview template ${template.id}`}
                    aria-label="Preview template"
                    onClick={toggle}
                  ><VisibilityOutlinedIcon /></IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title={t('labels.remove')}>
                <IconButton
                  name={`Remove template ${template.id}`}
                  aria-label="Remove template"
                  onClick={handleClick}
                ><CloseIcon /></IconButton>
              </Tooltip>
            </Box>
          </Paper>
        )}
      </Draggable>
      {open ? (
        <TemplatePreviewDialog
          open={open}
          onClose={toggle}
          templateIds={[templateId]}
        />
      ) : null}
    </Fragment>
  )
}

export default SelectedListItem;
