import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IState, { IPrompt } from "./AIChatPrompts.models";
// Async
import { getPrompts, getPrompt, createPrompt, updatePrompt, deletePrompt } from './AIChatPrompts.async';

const initialState:IState = {
  prompts: null,
  prompt: null,
  filter: {
    search: ''
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.AIChatPrompts,
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get teams
    builder.addCase(getPrompts.pending, (state) => {
      state.prompts = null;
    });
    builder.addCase(getPrompts.fulfilled, (state, action:PayloadAction<IPrompt[]>) => {
      state.prompts = action.payload;
    });
    // Get team
    builder.addCase(getPrompt.pending, (state) => {
      state.prompt = null;
    });
    builder.addCase(getPrompt.fulfilled, (state, action:PayloadAction<IPrompt>) => {
      state.prompt = action.payload;
    });
    // Create team
    builder.addCase(createPrompt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPrompt.fulfilled, (state, action:PayloadAction<IPrompt>) => {
      if ( state.prompts ){
        state.prompts = [...state.prompts, action.payload];
      }
    });
    // Update prompt
    builder.addCase(updatePrompt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePrompt.fulfilled, (state, action:PayloadAction<IPrompt>) => {
      if ( state.prompts ){
        state.prompts = state.prompts.map((aiChatPrompt:IPrompt) => {
          if ( aiChatPrompt.id === action.payload.id ) return action.payload;
          return aiChatPrompt;
        })
      }
    });
    // Delete prompt
    builder.addCase(deletePrompt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePrompt.fulfilled, (state, action:PayloadAction<string>) => {
      if ( state.prompts ){
        state.prompts = state.prompts.filter((aiChatPrompt:IPrompt) => aiChatPrompt.id !== action.payload);
      }
    });
    // Match
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const AIChatPromptsActions = slice.actions;

export default slice.reducer;
