import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { IManagerUser } from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// ASync
import { inviteUser } from 'app/store/UsersManagement/UsersManagement.async';
// Selectors
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectLoading } from 'app/store/UsersManagement/UsersManagement.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import Autocomplete from 'app/components/Mui/AutocompleteV2';

type Props = {
  open: boolean;
  onClose: () => void;
  user: IManagerUser;
}

const CompanyInviteDialog:FC<Props> = ({
  // Props
  open, onClose, user
}) => {
  const { t } = useTranslation('common');

  // Dispathc
  const dispatch = useAppDispatch();
  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const loading = useAppSelector(selectLoading);

  const [ selectedAccountId, setSelectedAccountId ] = useState<number | null>(null);

  const handleChange = (accountId:any) => {
    setSelectedAccountId(accountId);
  }

  const handleClick = async () => {
    if ( !selectedAccountId ) return;

    try {
      await dispatch(inviteUser({ userId: user.id, data: { accountId: selectedAccountId } })).unwrap();
      onClose();
    } catch(error){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel company invite dialog"
        onClick={onClose}
      >{t('labels.close')}</Button>
      <LoadingButton
        name="Submit company invite dialog"
        disabled={!selectedAccountId}
        loading={loading}
        onClick={handleClick}
        variant="contained"
        color="primary"
      >Invite</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Invite to another company"
      actions={actions}
    >
      <Autocomplete
        value={selectedAccountId}
        onChange={handleChange}
        options={accountOptions || []}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.id}>
            <Typography variant="subtitle1">{option.name}</Typography>
            {option.props?.address ? (
              <Typography variant="caption">{option.props.address}</Typography>
            ) : null}
          </li>
        )}
        InputProps={{
          label: 'Company accounts'
        }}
      />
    </Dialog>
  )
}

export default CompanyInviteDialog;
