import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import ICaseAlert from "app/models/CaseAlert";
// Async
import {
  getAlerts, resolveAlert, deleteAlert
} from './CaseAlerts.async';

interface IState {
  alerts: ICaseAlert[] | null;
  loading: boolean;
};

const initialState:IState = {
  alerts: null,
  loading: false
};

const slice = createSlice({
  name: Reducers.CaseAlerts,
  initialState,
  reducers: {
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Get alerts
    builder.addCase(getAlerts.pending, (state) => {
      state.alerts = null;
    })
    builder.addCase(getAlerts.fulfilled, (state, action:PayloadAction<ICaseAlert[]>) => {
      state.alerts = action.payload;
    })
    // Resolve alert
    builder.addCase(resolveAlert.pending, (state) => {
      state.loading = true
    });
    builder.addCase(resolveAlert.fulfilled, (state, action:PayloadAction<ICaseAlert>) => {
      if ( state.alerts ){
        state.alerts = state.alerts.map((alert:ICaseAlert) => {
          if ( alert.id === action.payload.id ) return action.payload;
          return alert;
        });
      }
    });
    // Delete alert
    builder.addCase(deleteAlert.pending, (state) => {
      state.loading = true
    });
    builder.addCase(deleteAlert.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.alerts ){
        state.alerts = state.alerts.filter((alert:ICaseAlert) => alert.id !== action.payload);
      }
    });
    // Matcher
    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  }
});

export const CaseAlertsActions = slice.actions;

export default slice.reducer;