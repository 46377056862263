import { IPage } from "@root/models/Page";

class PageFactory implements IPage {
  documentId: number = 0;
  pageNum: number = 0;
  version: number = 0;
  annotations?: IPage['annotations'] = [];
  tag?: string = '';
  staple?: IPage['staple'] = undefined;
  colors?: IPage['colors'] = [];
  inWorkspace?: boolean = false;
  workspaceOrder?: string = '';
  parentEpisodeId?: number = 0;
  deleted: boolean = false;
  highlights?: IPage['highlights'] = [];

  createPage(data:Partial<IPage>): IPage {
    return {
      documentId: data.documentId || this.documentId,
      pageNum: data.pageNum || this.pageNum,
      version: data.version || this.version,
      annotations: data.annotations || this.annotations,
      tag: data.tag || this.tag,
      staple: data.staple || this.staple,
      colors: data.colors || this.colors,
      inWorkspace: data.inWorkspace || this.inWorkspace,
      workspaceOrder: data.workspaceOrder || this.workspaceOrder,
      parentEpisodeId: data.parentEpisodeId || this.parentEpisodeId,
      deleted: data.deleted || this.deleted,
      highlights: data.highlights || this.highlights,
    };
  }
}

const factory = new PageFactory();
export default factory;
