import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Types
import UserPermissions from 'app/types/Permissions';
// Models
import { RootState } from 'app/store';
import IEpisode from 'app/models/Episode';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
// Selectors
import { selectMyUserHasPermission } from 'app/store/Users/Users.selectors';
import { selectPageEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Async
import { getFacts } from 'app/store/EpisodeFacts/EpisodeFacts.async';
// Mui
import { Box } from '@mui/material';
// Context
import PageEpisodesProvider from 'app/context/PageEpisodes.context';
// ToDO
import { IPage } from '@root/models/Page';
import { selectPage } from 'app/store/page/page.selectors';
// End: ToDO
// 
import EpisodeCreateButton from './EpisodeCreateButton';
import EpisodeList from './EpisodeList';
// Utilities
import { getSearchParam } from 'app/utilities/Utilities';

const PageEpisodes:FC = () => {
  const { search } = useLocation<{ search:string }>();
  const episodeId = getSearchParam(search, 'episodeId');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUserCPTEnabled = useAppSelector((state:RootState) => selectMyUserHasPermission(state, {
    permission: UserPermissions.HcpcsCodes
  }));
  // ToDO
  const page = useAppSelector((state) => selectPage(state as any)) as IPage;

  // End: ToDO
  const episodes = useAppSelector((state) => selectPageEpisodesAll(state, {
    documentId: page?.documentId,
    pageNum: page?.pageNum,
    stapleId: page?.staple && page?.staple.order === 1 ? page.staple.id : undefined,
    parentEpisodeId: page?.parentEpisodeId
  })) as IEpisode[] | null;

  useEffect(() => {
    dispatch(EpisodesActions.setSelectedEpisodeId(
      episodeId
      ? Number(episodeId)
      : (page?.parentEpisodeId || null)
    ))

    if ( !myUserCPTEnabled || !episodes || !episodes.length ) return;

    const episodeIds = episodes.map((episode:IEpisode) => episode.id).join(',');

    dispatch(getFacts({ episodeIds }));
    // eslint-disable-next-line
  }, []);

  return (
    <PageEpisodesProvider episodes={episodes || []}>
      <Box sx={{ position: 'relative' }}>
        <EpisodeCreateButton />
        <EpisodeList />
      </Box>
    </PageEpisodesProvider>
  )
}

export default PageEpisodes;
