import { FC, Fragment } from 'react';
// Models
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLoading } from 'app/store/DMSCollections/DMSCollections.selectors';
// Async
import { deleteCollection } from 'app/store/DMSCollections/DMSCollections.async';
// Mui
import {
  Theme, Paper, Box,
  Typography
} from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import CollectionFormDialog from './CollectionFormDialog';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  collection: ICollection;
}

const CollectionItem:FC<Props> = ({
  // Props
  collection
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openEditDialog, toggle:toggleEditDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deleteCollection(collection.id));
  }

  return (
    <Fragment>
      <Paper
        sx={{ display: 'flex', p: 4 }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }} >
          <Typography variant="subtitle1">{collection.name}</Typography>
          {collection.description ? (
            <Typography color="CaptionText" variant="body2">{collection.description}</Typography>
          ) : null}
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Collection dropdown ${collection.id}`}
            aria-label="Collection dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`Edit collection ${collection.id}`}
              onClick={toggleEditDialog}
            >{t('labels.edit')}</MenuItem>
            <MenuItem
              name={`Delete collection ${collection.id}`}
              sx={{
                color: (theme:Theme) => theme.palette.error.main
              }}
              onClick={toggleConfirmationDialog}
            >{t('labels.delete')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {/* Dialogs */}
      {openEditDialog ? (
        <CollectionFormDialog
          open={openEditDialog}
          onClose={toggleEditDialog}
          collection={collection}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.adminPages.collectionsPage.deleteCollection')}
          content={t('pages.adminPages.collectionsPage.deleteCollectionConfirmation', { collectionName: collection.name })}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default CollectionItem;
